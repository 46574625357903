import { TableFilter } from "@/components/table/TableFilter"
import { TableSort } from "@/components/table/TableSort"
import { Checkbox } from "@/components/ui/checkbox"
import {
	type DocumentRating,
	type UnprocessedDocument,
	UnprocessedDocumentType,
} from "@/types"
import {
	type ColumnDef,
	type ColumnFiltersState,
	type Row,
	type SortingState,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table"
import type React from "react"
import { useMemo, useState } from "react"
import UnprocessedDocumentListItem from "./UnprocessedDocumentListItem"

// TODO: split this component and combine with document metadata sorting logic

/**
 * Safely parses a date value.
 * Returns a Date if valid, or null otherwise.
 */
const safeParseDate = (dateString: any): Date | null => {
	if (!dateString) return null
	const date = new Date(dateString)
	return Number.isNaN(date.getTime()) ? null : date
}

/**
 * Extracts a date from a patent document for a given field.
 */
const getDocumentDate = (
	document: UnprocessedDocument,
	field: "publication_date" | "priority_date",
): Date | null => {
	if (
		document.type === UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION ||
		document.type === UnprocessedDocumentType.UNPUBLISHED_PATENT
	) {
		const selectedKindCode =
			document.selectedKindCode ||
			document.patentDetails?.selectedKindCode ||
			(document.patentDetails?.details
				? Object.keys(document.patentDetails.details)[0]
				: undefined)
		if (!selectedKindCode) {
			return null
		}
		const patentDetail = document.patentDetails?.details?.[selectedKindCode]
		if (!patentDetail) {
			return null
		}
		if (field === "publication_date" && patentDetail.publicationDate) {
			const date = safeParseDate(patentDetail.publicationDate)
			if (!date) return null
			return date
		}
		if (field === "priority_date" && patentDetail.originalPriorityDate) {
			const date = safeParseDate(patentDetail.originalPriorityDate)
			if (!date) return null
			return date
		}
	}
	return null
}

/**
 * Extracts a patent-specific field value from a document.
 */
const getPatentField = (
	document: UnprocessedDocument,
	field: string,
): string | null => {
	if (
		document.type === UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION ||
		document.type === UnprocessedDocumentType.UNPUBLISHED_PATENT
	) {
		const selectedKindCode =
			document.selectedKindCode ||
			document.patentDetails?.selectedKindCode ||
			(document.patentDetails?.details
				? Object.keys(document.patentDetails.details)[0]
				: undefined)
		if (!selectedKindCode) {
			return null
		}
		const patentDetail = document.patentDetails?.details?.[selectedKindCode]
		if (!patentDetail) {
			return null
		}
		const value = patentDetail[field]
		if (Array.isArray(value)) {
			return value.join(", ")
		}
		return value || null
	}
	return null
}

// --- Sorting and Filtering Helper Functions ---

const dateSortingFn = (
	rowA: Row<UnprocessedDocument>,
	rowB: Row<UnprocessedDocument>,
	columnId: string,
): number => {
	const aDate = (rowA.getValue(columnId) as Date | null)?.getTime() || 0
	const bDate = (rowB.getValue(columnId) as Date | null)?.getTime() || 0
	return aDate - bDate
}

const stringSortingFn = (
	rowA: Row<UnprocessedDocument>,
	rowB: Row<UnprocessedDocument>,
	columnId: string,
): number => {
	const aVal = (rowA.getValue(columnId) as string) || ""
	const bVal = (rowB.getValue(columnId) as string) || ""
	return aVal.localeCompare(bVal)
}

const intSortingFn = (
	rowA: Row<UnprocessedDocument>,
	rowB: Row<UnprocessedDocument>,
	columnId: string,
): number => {
	const aVal = Number(rowA.getValue(columnId)) || 0
	const bVal = Number(rowB.getValue(columnId)) || 0
	return aVal - bVal
}

// Generic filter that checks if the cell's string value is included in the filter array.
const stringInSetFilter = (
	row: Row<UnprocessedDocument>,
	columnId: string,
	filterValue: string[],
): boolean => {
	const val = row.getValue(columnId) as string
	return Boolean(val) && filterValue.includes(val)
}

// --- Column Definitions Extraction ---

const getColumns = (
	_documents: UnprocessedDocument[],
): ColumnDef<UnprocessedDocument, any>[] => {
	const dateColumns: ColumnDef<UnprocessedDocument, any>[] = [
		{
			id: "publication_date",
			header: "Publication Date",
			accessorFn: (doc: UnprocessedDocument) => getDocumentDate(doc, "publication_date"),
			sortingFn: dateSortingFn,
			filterFn: (
				row: Row<UnprocessedDocument>,
				columnId: string,
				filterValue: string[],
			): boolean => {
				const date = row.getValue(columnId) as Date | null
				return date ? filterValue.includes(date.getFullYear().toString()) : false
			},
		},
		{
			id: "priority_date",
			header: "Priority Date",
			accessorFn: (doc: UnprocessedDocument) => getDocumentDate(doc, "priority_date"),
			sortingFn: dateSortingFn,
			filterFn: (
				row: Row<UnprocessedDocument>,
				columnId: string,
				filterValue: string[],
			): boolean => {
				const date = row.getValue(columnId) as Date | null
				return date ? filterValue.includes(date.getFullYear().toString()) : false
			},
		},
	]

	const patentColumns: ColumnDef<UnprocessedDocument, any>[] = [
		{
			id: "number",
			header: "Patent Number",
			accessorFn: (doc: UnprocessedDocument) => getPatentField(doc, "number"),
			sortingFn: stringSortingFn,
		},
		{
			id: "inventors",
			header: "Inventors",
			accessorFn: (doc: UnprocessedDocument) => getPatentField(doc, "inventors"),
			sortingFn: stringSortingFn,
		},
		{
			id: "assignee",
			header: "Assignee",
			accessorFn: (doc: UnprocessedDocument) => getPatentField(doc, "assignee"),
			sortingFn: stringSortingFn,
		},
		{
			id: "prefix",
			header: "Prefix",
			accessorFn: (doc: UnprocessedDocument) => getPatentField(doc, "prefix"),
			sortingFn: stringSortingFn,
			filterFn: stringInSetFilter,
		},
		{
			id: "cpc_codes",
			header: "CPC Codes",
			accessorFn: (doc: UnprocessedDocument) => getPatentField(doc, "cpc_codes"),
			sortingFn: stringSortingFn,
			filterFn: stringInSetFilter,
		},
		{
			id: "title",
			header: "Title",
			accessorFn: (doc: UnprocessedDocument) => getPatentField(doc, "title"),
			sortingFn: stringSortingFn,
		},
		{
			id: "patent_type",
			header: "Patent Type",
			accessorFn: (doc: UnprocessedDocument) => getPatentField(doc, "patent_type"),
			sortingFn: stringSortingFn,
		},
		{
			id: "rank",
			header: "Rank",
			accessorFn: (doc: UnprocessedDocument) => getPatentField(doc, "rank"),
			sortingFn: intSortingFn,
		},
	]

	const productColumns: ColumnDef<UnprocessedDocument, string | null>[] = [
		{
			id: "product_name",
			header: "Product Name",
			accessorFn: (doc: UnprocessedDocument) => doc.productDetails?.productName,
			sortingFn: stringSortingFn,
		},
		{
			id: "product_url",
			header: "Product URL",
			accessorFn: (doc: UnprocessedDocument) => doc.productDetails?.productUrl,
			sortingFn: stringSortingFn,
		},
		{
			id: "seller",
			header: "Seller",
			accessorFn: (doc: UnprocessedDocument) => doc.productDetails?.seller,
			sortingFn: stringSortingFn,
		},
	]

	// Return appropriate columns based on document types in the list
	const hasPatents = _documents.some(
		(doc) =>
			doc.type === UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION ||
			doc.type === UnprocessedDocumentType.UNPUBLISHED_PATENT,
	)
	const hasProducts = _documents.some(
		(doc) => doc.type === UnprocessedDocumentType.PRODUCT,
	)

	if (hasProducts) {
		return productColumns
	}

	if (hasPatents) {
		return [...dateColumns, ...patentColumns]
	}

	return []
}

// --- Main Component ---

interface UnprocessedDocumentsListProps {
	documents: UnprocessedDocument[]
	onRemove: (documentId: string) => void
	updateSelectedKindCode: (documentId: string, kindCode: string) => void
	selectedDocuments?: string[] // Array of selected document IDs
	onSelectionChange?: (documentId: string, isSelected: boolean) => void // Callback for selection changes
	showActions?: boolean
	hideRemoveButton?: boolean
	showRating?: boolean
	updateRating?: (documentId: string, rating: DocumentRating) => void
	children?: React.ReactNode
	inSearchPage?: boolean
}

const UnprocessedDocumentsList: React.FC<UnprocessedDocumentsListProps> = ({
	documents,
	onRemove,
	updateSelectedKindCode,
	selectedDocuments = [],
	onSelectionChange,
	showActions = false,
	hideRemoveButton = false,
	showRating = false,
	updateRating,
	children,
	inSearchPage = false,
}) => {
	const showSelection = onSelectionChange !== undefined

	const columns = useMemo(() => getColumns(documents), [documents])

	// Generate publication and priority years only if there is at least one date.
	const publicationYears = useMemo(() => {
		const years = new Set<string>()
		for (const doc of documents) {
			const date = getDocumentDate(doc, "publication_date")
			if (date) {
				years.add(date.getFullYear().toString())
			}
		}
		return Array.from(years).sort()
	}, [documents])

	const priorityYears = useMemo(() => {
		const years = new Set<string>()
		for (const doc of documents) {
			const date = getDocumentDate(doc, "priority_date")
			if (date) {
				years.add(date.getFullYear().toString())
			}
		}
		return Array.from(years).sort()
	}, [documents])

	// Helper to generate filter options for a patent field.
	const generateFilterOptions = (field: string, label: string) => {
		const valuesSet = new Set<string>()
		for (const doc of documents) {
			if (
				doc.type === UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION ||
				doc.type === UnprocessedDocumentType.UNPUBLISHED_PATENT
			) {
				const selectedKindCode =
					doc.selectedKindCode ||
					doc.patentDetails?.selectedKindCode ||
					(doc.patentDetails?.details
						? Object.keys(doc.patentDetails.details)[0]
						: undefined)
				if (!selectedKindCode) return
				const patentDetail = doc.patentDetails?.details?.[selectedKindCode]
				if (!patentDetail) return
				let value = patentDetail[field]
				if (Array.isArray(value)) {
					value = value.join(", ")
				}
				if (value) valuesSet.add(String(value))
			}
		}
		const uniqueValues = Array.from(valuesSet).sort((a, b) => a.localeCompare(b))
		return uniqueValues.length > 0
			? {
					label,
					values: uniqueValues.map((val) => ({ value: val, label: val })),
				}
			: null
	}

	// Only include a filter if at least one document has a value.
	const filters = {
		...(publicationYears.length > 0 && {
			publication_date: {
				label: "Publication Date",
				values: publicationYears.map((year) => ({ value: year, label: year })),
			},
		}),
		...(priorityYears.length > 0 && {
			priority_date: {
				label: "Priority Date",
				values: priorityYears.map((year) => ({ value: year, label: year })),
			},
		}),
		...(generateFilterOptions("prefix", "Prefix") && {
			prefix: generateFilterOptions("prefix", "Prefix"),
		}),
		...(generateFilterOptions("cpc_codes", "CPC Codes") && {
			cpc_codes: generateFilterOptions("cpc_codes", "CPC Codes"),
		}),
	}

	// Table state: sorting and column filtering.
	const [sorting, setSorting] = useState<SortingState>([])
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

	const table = useReactTable({
		data: documents,
		columns,
		state: {
			sorting,
			columnFilters,
		},
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
	})

	const rows = table.getRowModel().rows

	return (
		<div className="flex flex-col">
			{/* Sorting and Filtering Controls aligned with the "Add Patent" button */}
			{showActions && (
				<div className="flex justify-between items-center mb-2">
					<div className="">
						<TableSort table={table} multiSorting={sorting} setMultiSorting={setSorting} />
						<TableFilter table={table} filters={filters} />
					</div>
					{children}
				</div>
			)}
			{/* Documents List */}
			<div
				className={`overflow-y-auto ${inSearchPage ? "h-[calc(100vh-200px)]" : "max-h-[calc(85vh-200px)]"} space-y-2`}
			>
				{rows.length === 0 ? (
					<div className="p-4 text-center text-gray-500">No documents found.</div>
				) : (
					rows.map((row) => (
						<div key={row.original.id} className="flex items-center gap-2">
							{showSelection && (
								<Checkbox
									checked={selectedDocuments.includes(row.original.id)}
									onCheckedChange={(checked) =>
										onSelectionChange?.(row.original.id, checked === true)
									}
									className="ml-2"
								/>
							)}
							<div className="flex-1">
								<UnprocessedDocumentListItem
									document={row.original}
									onRemove={onRemove}
									updateSelectedKindCode={updateSelectedKindCode}
									hideRemoveButton={hideRemoveButton}
									showRating={showRating}
									updateRating={updateRating}
								/>
							</div>
						</div>
					))
				)}
			</div>
		</div>
	)
}

export default UnprocessedDocumentsList
