/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import { AdminPageLayout, InvoicesTable } from "@/features/admin/components"
import { PageName } from "@/types/pages"
import type { FC } from "react"
import { useSuperAdminBilling } from "./hooks/useSuperAdminBilling"

/**
 * @description Super admin billing page component for managing organization invoices
 */
const SuperAdminBillingPage: FC = () => {
	const { invoices, isLoading } = useSuperAdminBilling()

	return (
		<AdminPageLayout isSuperAdmin page={PageName.SuperAdminBilling}>
			<div className="space-y-8 mt-2">
				{isLoading ? <Loader /> : <InvoicesTable invoices={invoices ?? []} />}
			</div>
		</AdminPageLayout>
	)
}

export default SuperAdminBillingPage
