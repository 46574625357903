import { TableTags } from "@/components/table/TableTags"
import { Badge } from "@/components/ui/badge"
import {} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { Separator } from "@/components/ui/separator"
import { useSharing } from "@/hooks"
import type { ProjectDocumentMetadata } from "@/types"
import { DocumentRole, DocumentType } from "@/types/documents"
import { formatTimestampInLocal } from "@/utils/dateUtils"
import { useState } from "react"
import ExternalUrlDisplay from "../info/externalUrlDisplay"
import { DocumentNamePill } from "./DocumentNamePill"
import PatentNumberDisplay from "./patents/PatentNumberDisplay"

export interface DocumentListItemContentProps {
	document: ProjectDocumentMetadata
	selected?: boolean
	onSelect?: (id: string) => void
	onTagUpdate?: (documentIds: string[], newTags: string[]) => void
	availableTags?: string[]
	expanded?: boolean
	onSaveName?: (nickname: string) => void
	onSaveNotes?: (notes: string) => void
	isEditingNotes?: boolean
	isEditingNickname?: boolean
	onClick?: () => void
}

export const DocumentListItemContent = (props: DocumentListItemContentProps) => {
	const {
		document,
		onTagUpdate,
		availableTags,
		onSaveName,
		onSaveNotes,
		expanded,
		isEditingNotes,
		isEditingNickname,
		onClick,
	} = props
	const { getUserEmailFromId } = useSharing()

	const [editedNickname, setEditedNickname] = useState(document.nickname)
	const [editedNotes, setEditedNotes] = useState(document.notes)

	return (
		<div className="flex flex-col w-full">
			<div onClick={onClick} className="flex flex-col cursor-pointer">
				<div className="flex flex-row items-center justify-between gap-3 mb-2">
					<div className="flex items-center gap-2 w-full min-w-0 overflow-hidden">
						{isEditingNickname ? (
							<Input
								value={editedNickname}
								onChange={(e) => setEditedNickname(e.target.value)}
								onBlur={() => onSaveName?.(editedNickname)}
								onKeyDown={(e) => {
									if (e.key === "Enter") onSaveName?.(editedNickname)
								}}
								className="text-md font-semibold flex-none"
								onClick={(e) => e.stopPropagation()}
								autoFocus
							/>
						) : (
							<div className="text-md font-semibold cursor-pointer whitespace-nowrap flex-none">
								{document.nickname || "—"}
							</div>
						)}

						{document.title !== document.nickname && (
							<p
								className="
								text-muted-foreground
								flex-1
								min-w-0
								overflow-hidden
								text-ellipsis
								whitespace-nowrap
							"
								title={document.title}
							>
								{document.title}
							</p>
						)}

						{document.type === DocumentType.PATENT && document.patent?.number && (
							<div className="flex-none">
								<PatentNumberDisplay number={document.patent.number} size="sm" />
							</div>
						)}
					</div>

					{/* <div className="flex items-center gap-2 flex-none">
						{document.type && (
							document.type === DocumentType.PATENT
								? <Badge variant="outline">Patent</Badge>
								: <Badge variant="outline">Non-Patent Literature</Badge>
						)}
					</div> */}
				</div>

				{/* Document Information Row */}
				<div className="flex flex-wrap gap-x-6 gap-y-2">
					{(document.patent?.publicationDate || document.npl?.publicationDate) && (
						<div className="flex items-center gap-2">
							<span className="text-muted-foreground">Publication Date</span>
							<span className="text-foreground">
								{formatTimestampInLocal(
									document.patent?.publicationDate || document.npl?.publicationDate,
								)}
							</span>
						</div>
					)}
					{document.patent && (
						<>
							{document.patent?.originalPriorityDate && (
								<div className="flex items-center gap-2">
									<span className="text-muted-foreground">Priority Date</span>
									<span className="text-foreground">
										{formatTimestampInLocal(document.patent?.originalPriorityDate)}
									</span>
								</div>
							)}
							{document.patent?.inventors && (
								<div className="flex items-center gap-2">
									<span className="text-muted-foreground">Inventor</span>
									<span className="text-foreground ">
										{document.patent?.inventors[0] || "—"}
									</span>
								</div>
							)}
							{document.patent?.assignee && (
								<div className="flex items-center gap-2">
									<span className="text-muted-foreground">Assignee</span>
									<span className="text-foreground ">{document.patent?.assignee}</span>
								</div>
							)}
						</>
					)}
					{document.npl?.authors && (
						<div className="flex items-center gap-2">
							<span className="text-muted-foreground">Author</span>
							<span className="text-foreground ">
								{document.npl?.authors.join(", ") || "—"}
							</span>
						</div>
					)}
					{document?.product && (
						<>
							{document.product?.productId && (
								<div className="flex items-center gap-2">
									<span className="text-muted-foreground">Specifications</span>
									<span className="text-foreground ">{document.product?.productId}</span>
								</div>
							)}
							{document.product?.productUrl && (
								<div className="flex items-center gap-2">
									<ExternalUrlDisplay
										url={document.product?.productUrl}
										label="Product Overview"
										size="xs"
									/>
								</div>
							)}
							{document.product?.specificationUrl && (
								<div className="flex items-center gap-2">
									<ExternalUrlDisplay
										url={document.product?.specificationUrl}
										label="Specification"
										size="xs"
									/>
								</div>
							)}
							{document.product?.seller && (
								<div className="flex items-center gap-2">
									<span className="text-muted-foreground">Seller</span>
									<span className="text-foreground ">{document.product?.seller}</span>
								</div>
							)}

							<div className="flex items-center gap-2">
								{/* <span className="text-muted-foreground">Licensed</span> */}
								<Badge variant="outline">
									{document.product?.licensed ? "Licensed" : "Not Licensed"}
								</Badge>
							</div>
						</>
					)}
				</div>
			</div>
			{expanded && (
				<div className="animate-fade-in py-2 ">
					{/* Notes */}
					{/* {(document.notes || isEditingNotes) && ( */}
					<Separator />
					<div className="flex flex-row justify-between mt-2">
						{isEditingNotes || !document.notes ? (
							<Input
								value={editedNotes}
								onChange={(e) => setEditedNotes(e.target.value)}
								onBlur={onSaveNotes ? () => onSaveNotes(editedNotes) : undefined}
								onKeyDown={(e) => {
									if (e.key === "Enter" && onSaveNotes) onSaveNotes(editedNotes)
								}}
								autoFocus
								className="text-gray-700 max-w-md"
								placeholder="Add notes..."
								onClick={(e) => e.stopPropagation()}
							/>
						) : (
							<p className="text-gray-700 cursor-pointer">{document.notes || "—"}</p>
						)}

						<TableTags
							tagOptions={availableTags || []}
							selectedTags={document.tags || []}
							onApply={(newTags) => {
								onTagUpdate?.([document.id], newTags)
							}}
						>
							<div className="flex flex-wrap gap-1" onClick={(e) => e.stopPropagation()}>
								{document?.tags &&
								Array.isArray(document?.tags) &&
								document?.tags?.length > 0 ? (
									document.tags.map((tag) => (
										<Badge variant="outline" key={tag} className="whitespace-nowrap">
											{tag}
										</Badge>
									))
								) : (
									<div className="text-muted-foreground">No tags</div>
								)}
							</div>
						</TableTags>

						{/* Metadata (e.g. added by / on) */}
						{document.createdBy && document.createdAt && (
							<div className="text-gray-500 italic text-xs">
								Added by {getUserEmailFromId(document.createdBy)} on{" "}
								{formatTimestampInLocal(document.createdAt)}
							</div>
						)}
					</div>
					{(document?.subjectIds?.length > 0 || document?.priorArtIds?.length > 0) && (
						<div className="text-gray-500 italic text-xs flex flex-col flex-wrap gap-2 mt-2">
							{document.documentRole === DocumentRole.SUBJECT && "Charted documents"}
							{(document.documentRole === DocumentRole.PRIOR_ART ||
								document.documentRole === DocumentRole.INFRINGEMENT) &&
								"Subjects charted against"}
							<div className="flex flex-wrap gap-2">
								{document?.subjectIds?.length > 0 &&
									document.subjectIds.map((subjectId) => (
										<DocumentNamePill key={subjectId} documentId={subjectId} />
									))}
								{document?.priorArtIds?.length > 0 &&
									document.priorArtIds.map((priorArtId) => (
										<DocumentNamePill key={priorArtId} documentId={priorArtId} />
									))}
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	)
}
