export interface Variable {
	id: string
	label: string
	variants?: { id: string; label: string }[]
}

export type VariableInputType =
	| "citation"
	| "figure"
	| "cellBoilerplate"
	| "title"
	| "introduction"

export enum VariableType {
	citation = "citation",
	figure = "figure",
	cellBoilerplate = "cellBoilerplate",
	title = "title",
	introduction = "introduction",
}

// Variable dictionaries
export const CITATION_VARIABLES: Variable[] = [
	{ id: "paragraphs", label: "Paragraph(s)" },
	{ id: "pages", label: "Page Number(s)" },
	{ id: "columns_lines", label: "Column/Line Location" },
	{ id: "document_nickname", label: "Document Nickname" },
	{ id: "document_inventor", label: "Document Inventor" },
]

export const FIGURE_VARIABLES: Variable[] = [
	{ id: "figure_number", label: "Figure Number" },
	{ id: "figure_index", label: "Index" },
]

export const CHART_DOCUMENT_VARIABLES: Variable[] = [
	{ id: "subject_number", label: "Subject Number" },
	{ id: "subject_nickname", label: "Subject Nickname" },
	{ id: "subject_publication_date", label: "Subject Publication Date" },
	{
		id: "document_number",
		label: "Document Number",
		variants: [
			{ id: "document_number_1", label: "Document 1 Number" },
			{ id: "document_number_2", label: "Document 2 Number" },
			{ id: "document_number_1_and_2", label: "Document 1 and 2 Number" },
		],
	},
	{
		id: "document_nickname",
		label: "Document Nickname",
		variants: [
			{ id: "document_nickname_1", label: "Document 1 Nickname" },
			{ id: "document_nickname_2", label: "Document 2 Nickname" },
			{ id: "document_nickname_1_and_2", label: "Document 1 and 2 Nickname" },
		],
	},
	{
		id: "document_publication_date",
		label: "Document Publication Date",
		variants: [
			{ id: "document_publication_date_1", label: "Document 1 Publication Date" },
			{ id: "document_publication_date_2", label: "Document 2 Publication Date" },
			{
				id: "document_publication_date_1_and_2",
				label: "Document 1 and 2 Publication Date",
			},
		],
	},
]

export const CELL_BOILERPLATE_VARIABLES: Variable[] = [
	{ id: "limitation_label", label: "Limitation Label" },
	{ id: "limitation_text", label: "Limitation Text" },
	...CHART_DOCUMENT_VARIABLES,
]

export const VARIABLES_BY_TYPE: Record<VariableInputType, Variable[]> = {
	citation: CITATION_VARIABLES,
	figure: FIGURE_VARIABLES,
	cellBoilerplate: CELL_BOILERPLATE_VARIABLES,
	title: CHART_DOCUMENT_VARIABLES,
	introduction: CHART_DOCUMENT_VARIABLES,
}
