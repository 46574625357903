import type { components } from "schema"
import type { KeysToCamelCase } from "./utils"

export type ChatMetadata = KeysToCamelCase<components["schemas"]["ChatMetadata"]>
export type Chat = KeysToCamelCase<components["schemas"]["Chat"]>

export type ChatSessionStatusSchema = components["schemas"]["ChatSessionStatus"]
export const ChatStatus = {
	ACTIVE: "ACTIVE",
	ARCHIVED: "ARCHIVED",
} as const satisfies Record<string, ChatSessionStatusSchema>

export type DialogueRoleSchema = components["schemas"]["DialogueRole"]
export const DialogueRole = {
	ASSISTANT: "ASSISTANT",
	USER: "USER",
} as const satisfies Record<string, DialogueRoleSchema>

export type DialogueMessageSchema = KeysToCamelCase<
	components["schemas"]["DialogueResponse"]
>
export type DialogueResponse = DialogueMessageSchema & {
	loading?: boolean
}

export type DirectiveTask = KeysToCamelCase<components["schemas"]["DirectiveTask"]>
export type ToolTask = KeysToCamelCase<components["schemas"]["ToolTask"]>

export type Parameter = KeysToCamelCase<components["schemas"]["Parameter"]>
