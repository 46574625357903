import { chatApi } from "@/api"
import { useChatContext } from "@/context/ChatContext"
import { useProjectContext } from "@/context/ProjectContext"
import { useProjectSubjectDocuments } from "@/features/documents/hooks/useProjectSubjectDocuments"
import type { DialogueResponse } from "@/types"
import { DialogueRole } from "@/types/chat"
import { QUERY_KEYS, invalidateChatQueries } from "@/utils"
import { useQueryClient } from "@tanstack/react-query"
import { DateTime } from "luxon"
import { useRef, useState } from "react"

export function useChatLegacy() {
	const { projectId } = useProjectContext()
	const { subjectDocuments } = useProjectSubjectDocuments()
	const { activeChatId, setActiveChatId } = useChatContext()
	const [messages, setMessages] = useState<DialogueResponse[]>([])
	const [isAIResponding, setIsAIResponding] = useState(false)
	const abortControllerRef = useRef<AbortController | null>(null)
	const queryClient = useQueryClient()

	const handleStopStreaming = () => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort()
			setIsAIResponding(false)
			setMessages((prevMessages) => {
				const lastMessage = prevMessages[prevMessages.length - 1]
				if (lastMessage?.role === DialogueRole.ASSISTANT && lastMessage.loading) {
					let messageContent = ""
					if ("content" in lastMessage.task) {
						messageContent = lastMessage.task.content as string
					}

					return [
						...prevMessages.slice(0, -1),
						{
							...lastMessage,
							loading: false,
							task: {
								...lastMessage.task,
								content: `${messageContent} [Stopped]`,
							},
						},
					]
				}
				return prevMessages
			})
		}
	}

	const sendMessage = async (message: string, selectedDocumentIds: string[]) => {
		if (message.trim()) {
			abortControllerRef.current = new AbortController()
			const signal = abortControllerRef.current.signal

			const subjectId = subjectDocuments[0].id

			try {
				const payload = {
					query: message,
					project_id: projectId,
					subject_id: subjectId,
					document_ids: selectedDocumentIds,
					...(activeChatId && activeChatId !== "new" ? { session_id: activeChatId } : {}),
				}

				const userDs = (DateTime.now().toMillis() + 1).toString()
				setMessages((prev) => [
					...prev,
					{
						id: `optimistic-user-message-${userDs}`,
						role: DialogueRole.USER,
						loading: false,
						createdAt: DateTime.now().toISO(),
						task: {
							directiveName: "prompt_assistant",
							parameters: [],
							content: message,
						},
					},
				])

				const assistantDs = (DateTime.now().toMillis() + 1).toString()
				setMessages((prev) => [
					...prev,
					{
						id: `optimistic-assistant-message-${assistantDs}`,
						role: DialogueRole.ASSISTANT,
						loading: true,
						createdAt: DateTime.now().toISO(),
						task: {
							directiveName: "prompt_user",
							parameters: [],
							content: "",
						},
					},
				])
				setIsAIResponding(true)

				await chatApi.streamChatResponse(
					payload,
					signal,
					(newSessionId) => {
						setActiveChatId(newSessionId)
						// Invalidate after getting new session ID
						queryClient.invalidateQueries({
							queryKey: QUERY_KEYS.project.chat.metadata(projectId),
						})
					},
					(text) => {
						setMessages((prev) => {
							const newMessages = [...prev]
							const lastMessage = newMessages[newMessages.length - 1]
							if (lastMessage.role === DialogueRole.ASSISTANT) {
								if ("content" in lastMessage.task) {
									lastMessage.task.content = text
								}
							}
							return newMessages
						})
					},
					() => {
						setMessages((prev) => {
							const newMessages = [...prev]
							const lastMessage = newMessages[newMessages.length - 1]
							if (lastMessage.role === DialogueRole.ASSISTANT) {
								lastMessage.loading = false
							}
							return newMessages
						})
						invalidateChatQueries(queryClient, projectId, activeChatId)
					},
				)
			} catch (error: any) {
				if (error.name !== "AbortError") {
					console.error("Error fetching response:", error)
				}
			} finally {
				setIsAIResponding(false)
			}
		}
	}

	return {
		messages,
		setMessages,
		isAIResponding,
		sendMessage,
		handleStopStreaming,
	}
}

export default useChatLegacy
