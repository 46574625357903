import { api } from "@/api"
import { useProjectContext } from "@/context/ProjectContext"
import { useApi } from "@/hooks"
import { QUERY_KEYS } from "@/utils/query/keys"
import { useMutation, useQueryClient } from "@tanstack/react-query"

const useGroups = () => {
	const queryClient = useQueryClient()
	const { projectId } = useProjectContext()
	const { handleError } = useApi()

	/**
	 * @description creates a new group of limitations
	 * @param {string} projectId - The id of the project
	 * @param {string} limitationIds - The ids of the limitations to add to the group
	 * @param {string} nickname - The nickname of the group (optional)
	 */
	const createGroupMutation = useMutation({
		mutationFn: async ({
			subjectId,
			limitationIds,
			number,
			nickname,
		}: {
			subjectId: string
			limitationIds: string[]
			number: number
			nickname?: string
		}) => {
			const response = await api.createGroup(subjectId, limitationIds, number, nickname)
			return response
		},
		onError: (error) => {
			handleError(error, "Error creating group")
		},
		onSuccess: (_data, variables) => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.subject.claimLimitationGroups(
					projectId,
					variables.subjectId,
				),
			})
		},
	})

	/**
	 * @description updates a group of limitations
	 * @param {string} groupId - The id of the group to update
	 * @param {string[]} limitationIds - The ids of the limitations to add to the group
	 * @param {string} nickname - The nickname of the group (optional)
	 */
	const updateGroupMutation = useMutation({
		mutationFn: async ({
			subjectId,
			groupId,
			limitationIds,
			number,
			nickname,
		}: {
			subjectId: string
			groupId: string
			limitationIds?: string[]
			number?: number
			nickname?: string
		}) => {
			const response = await api.updateGroup(groupId, limitationIds, number, nickname)
			return response
		},
		onError: (error) => {
			handleError(error, "Error updating group")
		},
		onSuccess: (_data, variables) => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.subject.claimLimitationGroups(
					projectId,
					variables.subjectId,
				),
			})
		},
	})

	/**
	 * @description deletes a group of limitations
	 * @param {string} groupId - The id of the group to delete
	 */
	const deleteGroupMutation = useMutation({
		mutationFn: async ({
			subjectId,
			groupId,
		}: {
			subjectId: string
			groupId: string
		}) => {
			const response = await api.deleteGroup(groupId)
			return response
		},
		onError: (error) => {
			handleError(error, "Error deleting group")
		},
		onSuccess: (_data, variables) => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.subject.claimLimitationGroups(
					projectId,
					variables.subjectId,
				),
			})
		},
	})

	return {
		createGroup: createGroupMutation.mutate,
		isCreatingGroup: createGroupMutation.isPending,

		updateGroup: updateGroupMutation.mutate,
		isUpdatingGroup: updateGroupMutation.isPending,

		deleteGroup: deleteGroupMutation.mutate,
		isDeletingGroup: deleteGroupMutation.isPending,
	}
}

export default useGroups
