export enum SearchMode {
	MODE_NPL = "NPL",
	MODE_PRODUCT = "PRODUCT",
	MODE_PATENT = "PATENT",
}

// Add type for search options
export interface SearchOptions {
	documentId?: string
	isPatch103?: boolean
	isNPL?: boolean
	isProduct?: boolean
	retryCount?: number
}

// Base interface for all search requests
export interface BaseSearchRequest {
	project_id: string
	subject_id: string
	max_results?: number
	dedupe_family_id?: boolean
	to_date?: string
	from_date?: string
}
