export const jsonToArray = (value: any): string[] => {
	if (Array.isArray(value)) {
		return value
	}
	if (typeof value === "string") {
		try {
			const parsed = JSON.parse(value)
			if (Array.isArray(parsed)) {
				return parsed
			}
		} catch (e) {
			// If it's a string but not JSON, split by comma and clean up
			return value
				.replace(/[\[\]"']/g, "") // Remove brackets and quotes
				.split(",")
				.map((s) => s.trim())
				.filter((s) => s.length > 0)
		}
	}
	return []
}
