export const DEFAULT_QUERY_OPTIONS = {
	staleTime: Number(process.env.REACT_APP_QUERY_STALE_TIME) || 60000, // 1 minute
	gcTime: Number(process.env.REACT_APP_QUERY_CACHE_TIME) || 60000, // 1 minute
	refetchOnWindowFocus:
		process.env.REACT_APP_QUERY_REFETCH_ON_WINDOW_FOCUS === "true" || false,
} as const

// Used for large queries (invalidity, full documents)
export const FAST_GC_QUERY_OPTIONS = {
	staleTime: 60000, // 1 minute
	gcTime: 10000, // 10 seconds
	refetchOnWindowFocus: false,
} as const

export const LONG_STALE_TIME_QUERY_OPTIONS = {
	staleTime: 300000, // 5 minutes
	gcTime: 300000, // 5 minutes
	refetchOnWindowFocus: false,
} as const
