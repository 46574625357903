import { api } from "@/api"
import { useProjectContext } from "@/context/ProjectContext"
import type { DocumentStatus, ProjectDocumentMetadata } from "@/types"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import { type QueryClient, useMutation, useQueryClient } from "@tanstack/react-query"

/* -------------------------
   Types
------------------------- */
type BasePayload = {
	documentId: string
}

type UpdateDocumentsPayload = BasePayload & {
	status?: DocumentStatus
	title?: string
	priorityDate?: string
}

/* -------------------------
   Helper Functions
------------------------- */
function handleOptimisticUpdate(
	queryClient: QueryClient,
	projectId: string | undefined,
	documentIds: string[],
	updateFn: (ref: ProjectDocumentMetadata) => ProjectDocumentMetadata,
) {
	const projectQueryKey = projectId
		? QUERY_KEYS.project.documents.priorArt(projectId)
		: []

	const previousProjectReferences =
		queryClient.getQueryData<ProjectDocumentMetadata[]>(projectQueryKey)

	// Project references
	queryClient.setQueryData<ProjectDocumentMetadata[] | undefined>(
		projectQueryKey,
		(oldData) => {
			if (!oldData) return oldData
			return oldData.map((doc) =>
				documentIds.includes(doc.id ?? "") ? updateFn(doc) : doc,
			)
		},
	)

	return { previousProjectReferences }
}

function handleOptimisticRollback(
	queryClient: QueryClient,
	projectId: string | undefined,
	previousProjectReferences?: ProjectDocumentMetadata[],
) {
	if (projectId && previousProjectReferences) {
		queryClient.setQueryData(
			QUERY_KEYS.project.documents.priorArt(projectId),
			previousProjectReferences,
		)
	}
}

/* -------------------------
   Hooks
------------------------- */
export const useUpdateDocuments = () => {
	const queryClient = useQueryClient()
	const { projectId } = useProjectContext()

	// 1) Single mutation for updating documents (status, title, etc.)
	const updateDocument = useMutation({
		mutationKey: MUTATION_KEYS.document.update(),
		mutationFn: async ({ documentId, ...fields }: UpdateDocumentsPayload) => {
			return api.updateDocument(documentId, {
				...(fields.title && { title: fields.title }),
				...(fields.priorityDate && { priority_date: fields.priorityDate }),
			})
		},
		onMutate: async ({ documentId, ...fields }) => {
			return handleOptimisticUpdate(queryClient, projectId, [documentId], (ref) => ({
				...ref,
				...fields,
			}))
		},
		onError: (_error, _variables, context) => {
			handleOptimisticRollback(queryClient, projectId, context?.previousProjectReferences)
		},
		onSuccess: (_error, variables) => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.document.full(projectId, variables.documentId),
			})
		},
	})

	return {
		updateDocument: updateDocument.mutate,
	}
}

export default useUpdateDocuments
