import { Button } from "@/components/ui/button"
import IconButton from "@/components/ui/icon-button"
import { Input } from "@/components/ui/input"
import { useChatContext } from "@/context/ChatContext"
import { useProjectContext } from "@/context/ProjectContext"
import { useChat } from "@/hooks"
import { formatChatTimestamp } from "@/utils/dateUtils"
import { Archive, Bot, ChevronLeft, Maximize, Pencil, X } from "lucide-react" // Added Maximize2 icon
import { useEffect, useRef, useState } from "react"

interface PanelChatHeaderProps {
	onClose: () => void
}

export const PanelChatHeader = ({ onClose }: PanelChatHeaderProps) => {
	const { activeChatId, activeChatMetadata, setActiveChatId, setIsExpandedChatOpen } =
		useChatContext()
	const { projectId } = useProjectContext()
	const { updateChatSession, updateChatPending } = useChat()
	const [isEditing, setIsEditing] = useState(false)
	const [nameInput, setNameInput] = useState(activeChatMetadata?.name || "")

	const handleBack = () => {
		setActiveChatId(null)
	}

	const handleStartEdit = () => {
		setNameInput(activeChatMetadata?.name || "")
		setIsEditing(true)
	}

	const handleSaveName = () => {
		if (activeChatId && activeChatId !== "new" && nameInput.trim()) {
			updateChatSession({ chatId: activeChatId, name: nameInput.trim() })
		}
		setIsEditing(false)
	}

	const handleExpand = () => {
		setIsExpandedChatOpen(true)
	}

	const handleArchive = () => {
		if (activeChatId && activeChatId !== "new") {
			updateChatSession({ chatId: activeChatId, status: "ARCHIVED" })
			setActiveChatId(null)
		}
	}

	const getActiveTitle = () => {
		if (activeChatId === "new") return "New Chat"
		return (
			activeChatMetadata?.name ||
			(activeChatMetadata?.updatedAt
				? `Chat at ${formatChatTimestamp(activeChatMetadata.updatedAt)}`
				: activeChatMetadata?.createdAt
					? `Chat at ${formatChatTimestamp(activeChatMetadata.createdAt)}`
					: "Chat with Andy")
		)
	}

	const title = activeChatId ? getActiveTitle() : "Chat with Andy"

	const TitleDisplay = () => {
		const ref = useRef<HTMLHeadingElement>(null)
		const [shouldTruncate, setShouldTruncate] = useState(false)

		useEffect(() => {
			if (ref.current && !activeChatId) {
				const isOverflowing = ref.current.scrollWidth > ref.current.clientWidth
				setShouldTruncate(isOverflowing)
			}
		}, [activeChatId])

		return (
			<h3 ref={ref} className="font-medium text-sm leading-tight truncate">
				{!activeChatId && shouldTruncate ? "Chat" : title}
			</h3>
		)
	}

	return (
		<div className="flex items-center h-12 px-2 border-b">
			{activeChatId ? (
				<IconButton
					variant="ghost"
					size="xsIcon"
					onClick={handleBack}
					icon={<ChevronLeft />}
				/>
			) : (
				<div className="h-8 w-8 flex items-center justify-center">
					<Bot className="h-5 w-5 text-muted-foreground" />
				</div>
			)}
			<div className="flex-1 min-h-[32px] flex items-center mx-1 min-w-0">
				{isEditing && activeChatId && activeChatId !== "new" ? (
					<form
						onSubmit={(e) => {
							e.preventDefault()
							handleSaveName()
						}}
						className="flex items-center w-full"
					>
						<Input
							value={nameInput}
							onChange={(e) => setNameInput(e.target.value)}
							onBlur={handleSaveName}
							autoFocus
							placeholder={
								activeChatMetadata?.name ||
								`Chat at ${formatChatTimestamp(activeChatMetadata?.updatedAt ?? activeChatMetadata?.createdAt)}`
							}
							className="h-7 text-sm mt-[15px] border-0 bg-transparent focus-visible:ring-0 p-0 font-medium shadow-none"
						/>
					</form>
				) : (
					<div className="flex items-center gap-1 group min-w-0">
						<TitleDisplay />
						{activeChatId && activeChatId !== "new" && (
							<Button
								variant="ghost"
								size="icon"
								className="h-6 w-6 hover:bg-background/20 opacity-0 group-hover:opacity-100 transition-opacity shrink-0"
								onClick={() => {
									setNameInput(activeChatMetadata?.name || "")
									setIsEditing(true)
								}}
							>
								<Pencil className="h-3 w-3" />
							</Button>
						)}
					</div>
				)}
			</div>
			<div className="flex items-center gap-0.5">
				{activeChatId && activeChatId !== "new" && (
					<IconButton
						variant="ghost"
						size="xsIcon"
						onClick={handleArchive}
						icon={<Archive />}
					/>
				)}

				<IconButton
					variant="ghost"
					size="xsIcon"
					onClick={handleExpand}
					icon={<Maximize />}
				/>

				<IconButton variant="ghost" size="xsIcon" onClick={onClose} icon={<X />} />
			</div>
		</div>
	)
}
