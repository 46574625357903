import { NoAdminAccessMessage } from "@/features/admin/components"
import { useUserStore } from "@/store"
import { RoleType } from "@/types"
import { useMemo } from "react"
import { Outlet } from "react-router-dom"

function AdminRoute() {
	const { role } = useUserStore()

	const isAdmin = useMemo(
		() => role === RoleType.ADMIN || role === RoleType.OWNER,
		[role],
	)

	// If the user isn't an admin, render the fallback
	if (!isAdmin) {
		return <NoAdminAccessMessage />
	}

	// If user is admin, render the nested routes using Outlet
	return <Outlet />
}

export default AdminRoute
