import { cn } from "@/lib/utils"
import type { InvaliditySummaryColumn, InvaliditySummaryEntry } from "@/types"
import type React from "react"

// Props for one document column.
export interface ChartSummaryProps {
	chartId: string
	chartName: string
	chartData: InvaliditySummaryColumn
	summaryChartRowHeightCollapsed?: number
	isExpanded?: boolean
	handleSelect?: (chartId: string, claimLimitationId: string | null) => void
	style?: React.CSSProperties
}

const ChartSummary: React.FC<ChartSummaryProps> = ({
	chartId,
	chartName,
	chartData,
	summaryChartRowHeightCollapsed,
	isExpanded,
	handleSelect,
	style,
}) => {
	const renderCell = (entry: InvaliditySummaryEntry, limitationId: string) => {
		const cellColor = entry.color ? entry.color.toString() : "#D3D3D3"

		return (
			<div
				key={limitationId}
				className="px-0.75 bg-background"
				style={{
					minWidth: "75px",
					flex: "1",
					margin: "0",
					padding: "0 2px",
				}}
			>
				<div
					className={cn(
						"w-full h-full flex items-center justify-center cursor-pointer transition-colors duration-200",
						isExpanded && "rounded m-1",
					)}
					style={{
						backgroundColor: cellColor,
						height: isExpanded ? "25px" : `${summaryChartRowHeightCollapsed}px`,
					}}
					onClick={() => handleSelect(chartId, isExpanded ? limitationId : null)}
				/>
			</div>
		)
	}

	return (
		<div className="flex flex-col relative" style={style}>
			{/* Document column header row – sticky on the top during vertical scrolling */}
			<div
				className="sticky top-0 z-20 bg-background cursor-pointer w-full"
				style={{ minWidth: "75px", padding: "0 2px", boxSizing: "border-box" }}
				onClick={() => handleSelect(chartId, null)}
			>
				<div className="flex flex-col items-center justify-center pt-1 pb-2 w-full">
					<span className="text-xs truncate w-full text-center">{chartName}</span>
				</div>
			</div>

			{/* Render cells for each row */}
			{Object.entries(chartData?.rows ?? {}).map(([limitationId, entry]) =>
				renderCell(entry, limitationId),
			)}
		</div>
	)
}

export default ChartSummary
