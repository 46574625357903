import { api } from "@/api"
import { useProjectContext } from "@/context/ProjectContext"
import type { UserMetadata } from "@/types"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import {
	handleOptimisticUpdate,
	revertOptimisticUpdates,
} from "@/utils/query/optimisticUpdates"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useCallback, useMemo } from "react"

/**
 * @description Hook for handling project sharing operations
 */
const useSharing = () => {
	const { projectId } = useProjectContext()
	const queryClient = useQueryClient()

	const invalidateSharingQueries = () => {
		queryClient.invalidateQueries({
			queryKey: QUERY_KEYS.project.sharing.sharedUsers(projectId),
		})
		queryClient.invalidateQueries({
			queryKey: QUERY_KEYS.project.sharing.shareableUsers(projectId),
		})
	}

	// Query for getting current project users
	const projectUsers = useQuery({
		queryKey: QUERY_KEYS.project.sharing.sharedUsers(projectId),
		queryFn: () => api.getProjectUsers(projectId),
		enabled: !!projectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	// Query for getting users that can be shared with
	const shareableUsers = useQuery({
		queryKey: projectId ? QUERY_KEYS.project.sharing.shareableUsers(projectId) : [],
		queryFn: () => api.getShareableUsers(projectId),
		enabled: !!projectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	// Add user mutation
	const addUsersMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.users.add(),
		mutationFn: ({ userIds }: { userIds: string[] }) =>
			api.addUsersToProject(projectId, userIds),
		onMutate: async ({ userIds }) => {
			const queryKeys = [
				QUERY_KEYS.project.sharing.sharedUsers(projectId),
				QUERY_KEYS.project.sharing.shareableUsers(projectId),
			]

			const context = await handleOptimisticUpdate({
				queryClient,
				queryKeys,
				updateFn: (oldData: UserMetadata[] | undefined) => {
					if (!oldData) return oldData

					if (queryKeys[0].includes("sharedUsers")) {
						return [
							...oldData,
							...((shareableUsers.data as UserMetadata[] | undefined)?.filter(
								(u) => u.id !== undefined && userIds.includes(u.id),
							) ?? []),
						]
					}
					return oldData.filter((u: any) => !userIds.includes(u.id))
				},
			})

			return context
		},
		onError: (_, __, context) => {
			if (context) revertOptimisticUpdates(queryClient, context)
		},
		onSuccess: () => {
			invalidateSharingQueries()
		},
	})

	// Remove user mutation
	const removeUsersMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.users.remove(),
		mutationFn: ({ userIds }: { userIds: string[] }) =>
			api.removeUsersFromProject(projectId, userIds),
		onMutate: async ({ userIds }) => {
			const queryKeys = [
				QUERY_KEYS.project.sharing.sharedUsers(projectId),
				QUERY_KEYS.project.sharing.shareableUsers(projectId),
			]

			const context = await handleOptimisticUpdate({
				queryClient,
				queryKeys,
				updateFn: (oldData: UserMetadata[] | undefined) => {
					if (!oldData) return oldData

					// If we're updating shared users
					if (queryKeys[0].includes("sharedUsers")) {
						return oldData.filter((u: any) => !userIds.includes(u.id))
					}
					// If we're updating shareable users
					return [
						...oldData,
						...((shareableUsers.data as UserMetadata[] | undefined)?.filter(
							(u) => u.id !== undefined && userIds.includes(u.id),
						) ?? []),
					]
				},
			})

			return context
		},
		onError: (_, __, context) => {
			if (context) revertOptimisticUpdates(queryClient, context)
		},
		onSuccess: () => {
			invalidateSharingQueries()
		},
	})

	// User id: email mapping used for citation removed by user
	const orgUserIdsToEmailMapping = useMemo(() => {
		const combinedUsers = [...(projectUsers.data ?? []), ...(shareableUsers.data ?? [])]
		return combinedUsers.reduce(
			(mapping, user: UserMetadata) => {
				const userId = user?.id
				if (userId && !mapping[userId]) {
					mapping[userId] = user?.email ?? ""
				}
				return mapping
			},
			{} as Record<string, string>,
		)
	}, [projectUsers.data, shareableUsers.data])

	const getUserEmailFromId = useCallback(
		(userId: string) => {
			return orgUserIdsToEmailMapping[userId]
		},
		[orgUserIdsToEmailMapping],
	)

	return {
		// Query data
		projectUsers: projectUsers.data,
		shareableUsers: shareableUsers.data,

		getUserEmailFromId,

		// Loading states
		isLoading: {
			projectUsers: projectUsers.isLoading,
			shareableUsers: shareableUsers.isLoading,
			addUser: addUsersMutation.isPending,
			removeUser: removeUsersMutation.isPending,
		},

		// Error states
		isError: {
			projectUsers: projectUsers.isError,
			shareableUsers: shareableUsers.isError,
			addUser: addUsersMutation.isError,
			removeUser: removeUsersMutation.isError,
		},

		// Mutation functions
		addUsers: addUsersMutation.mutate,
		removeUsers: removeUsersMutation.mutate,

		// Refetch functions
		refetch: {
			projectUsers: projectUsers.refetch,
			shareableUsers: shareableUsers.refetch,
		},
	}
}

export default useSharing
