import type {
	CollapsedClaim,
	DeleteProjectRequest,
	GetProjectListRequest,
	Group,
	UpdateProjectDetailsRequest,
	UpdateSettingsRequest,
} from "@/types"
import type { DocumentRole, ProjectDocumentMetadata } from "@/types/documents"
import type {
	ProjectMetadata,
	ProjectSettings,
	ProjectSettingsUpdate,
	ProjectUsage,
} from "@/types/project"
import { toSnakeCase } from "@/utils/dataUtils"
import { apiClient } from "./client"

export const projectApi = {
	async getProjectMetadata(projectId: string): Promise<ProjectMetadata> {
		const response = await apiClient.get("get_project_metadata", {
			project_id: projectId,
		})
		return response as ProjectMetadata
	},

	async getProjectUsage(projectId: string): Promise<ProjectUsage> {
		const response = await apiClient.get("get_project_usage", {
			project_id: projectId,
		})
		return response as ProjectUsage
	},

	async getProjectDocumentMetadata(
		projectId: string,
		documentRoles: DocumentRole[],
		documentIds?: string[],
		excludeAllAndy?: boolean,
	): Promise<ProjectDocumentMetadata[]> {
		return await apiClient.get("get_project_document_metadata", {
			project_id: projectId,
			document_roles: documentRoles,
			...(documentIds ? { document_ids: documentIds } : {}),
			...(excludeAllAndy !== null ? { exclude_all_andy: excludeAllAndy } : {}), // Specifically null so falses still get sent to BE
		})
	},

	async deleteProject(projectId: string) {
		const payload: DeleteProjectRequest = {
			project_id: projectId,
		}
		return await apiClient.post("post_delete_project", payload)
	},

	async updateProjectDetails(projectId: string, options: any) {
		const payload: UpdateProjectDetailsRequest = {
			project_id: projectId,
			...options,
		}
		return await apiClient.post("post_update_project_details", payload)
	},

	async updateProjectSettings(projectId: string, settings: ProjectSettingsUpdate) {
		const payload: UpdateSettingsRequest = {
			...toSnakeCase(settings),
			project_id: projectId,
		}

		return await apiClient.post("post_update_project_settings", payload)
	},

	async getProjectSettings(projectId: string): Promise<ProjectSettings> {
		return await apiClient.get("get_project_settings", {
			project_id: projectId,
		})
	},

	async getSubjectClaims(
		subjectId: string,
		projectId: string,
	): Promise<CollapsedClaim[]> {
		return await apiClient.get("get_subject_claims", {
			subject_id: subjectId,
			project_id: projectId,
		})
	},

	async getClaimLimitationGroups(subjectId: string): Promise<Group[]> {
		return await apiClient.get("get_claim_limitation_groups", {
			subject_id: subjectId,
		})
	},

	async getProjectList(isArchived: boolean): Promise<ProjectMetadata[]> {
		const params: GetProjectListRequest = {
			include_archived: isArchived,
			include_active: !isArchived, // TODO: update later
		}
		return await apiClient.get("get_project_list", params)
	},
}
