/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import { Alert, AlertDescription } from "@/components/ui/alert"
import { AutosizeTextarea } from "@/components/ui/autosize-textarea"
import { Button } from "@/components/ui/button"
import { usePatentDetails, useProjectMetadata } from "@/hooks"
import type { PatentNumberOption } from "@/types"
import { isLikelyPatentNumber, parseCommaSeparatedString } from "@/utils"
import type React from "react"
import { useState } from "react"

interface PatentNumberInputProps {
	/**
	 * Maximum number of patents allowed to input.
	 */
	maxPatents?: number
	/**
	 * Placeholder text for the textarea.
	 */
	placeholder?: string
	/**
	 * Text for the submit button.
	 */
	submitButtonText?: string
	/**
	 * Optional additional validation function.
	 */
	validatePatentNumber?: (number: string) => boolean
	/**
	 * Callback function to send the fetched patent details.
	 */
	onDetails: (patentDetails: PatentNumberOption[]) => void
	/**
	 * Callback function to handle the cancel button.
	 */
	onCancel?: () => void
	/**
	 * Callback function to handle the error.
	 */
	onError?: (error: string) => void
	/**
	 * Whether to only show the input error.
	 */
	onlyShowInputError?: boolean
	/**
	 * Callback function when user confirms proceeding despite budget exceeded.
	 */
	setIsBudgetExceeded?: (isBudgetExceeded: boolean) => void
	/**
	 * Amount of budget exceeded.
	 */
	setBudgetExceededAmount?: (budgetExceededAmount: number) => void
}

/**
 * A generalized component for patent number input, fetching details, displaying results, and confirming selection.
 */
const PatentNumberInput: React.FC<PatentNumberInputProps> = ({
	maxPatents = 100,
	placeholder = "e.g., US10952930, EP1095293A1",
	submitButtonText = "Find Patents",
	validatePatentNumber,
	onDetails,
	onCancel,
	onlyShowInputError = false,
	setIsBudgetExceeded,
	setBudgetExceededAmount,
}) => {
	const [inputText, setInputText] = useState<string>("")
	const [inputError, setInputError] = useState<string>("")
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const { getPatentDetails } = usePatentDetails()
	const { amountToBeSpentOverBudget, willBudgetBeExceeded } = useProjectMetadata()

	const handleSubmit = async () => {
		const patentNumbers = parseCommaSeparatedString(inputText).filter(
			(num) => num.trim() !== "",
		)

		// Check for maximum limit
		if (patentNumbers.length > maxPatents) {
			setInputError(`You can input up to ${maxPatents} patent numbers.`)
			return
		}

		// Validate patent numbers
		const validPatentNumbers: string[] = []
		const invalidPatentNumbers: string[] = []
		for (const patentNumber of patentNumbers) {
			const isValid =
				isLikelyPatentNumber(patentNumber) &&
				(validatePatentNumber ? validatePatentNumber(patentNumber) : true)
			if (isValid) {
				validPatentNumbers.push(patentNumber)
			} else {
				invalidPatentNumbers.push(patentNumber)
			}
		}

		if (invalidPatentNumbers.length > 0) {
			setInputError(
				`The following patent numbers are invalid: ${invalidPatentNumbers.join(", ")}.`,
			)
			return
		}

		if (validPatentNumbers.length === 0) {
			setInputError("No valid patent numbers were input.")
			return
		}

		// Fetch patent details
		setIsLoading(true)
		try {
			const response = await getPatentDetails(validPatentNumbers, false)

			const successfulItems = response.filter(
				(item: PatentNumberOption) => item.error === null,
			)
			const failedItems = response.filter(
				(item: PatentNumberOption) => item.error !== null,
			)

			// Check if the budget will be exceeded
			const budgetExceeded = willBudgetBeExceeded
				? willBudgetBeExceeded(successfulItems.length)
				: false
			setIsBudgetExceeded?.(budgetExceeded)
			if (budgetExceeded) {
				setBudgetExceededAmount?.(amountToBeSpentOverBudget(successfulItems.length))
			}

			if (failedItems.length > 0) {
				const failedNumbers = failedItems.map((item) => item.number)
				setInputError(
					`The following patent numbers were not found: ${failedNumbers.join(", ")}.`,
				)
			} else {
				setInputError("")
			}

			if (successfulItems.length > 0) {
				onDetails(successfulItems)
				setInputText("")
			} else {
				setInputError("No valid patent numbers were found.")
			}
		} catch (error) {
			console.error("Error fetching patent details:", error)
			setInputError("An error occurred while fetching patent details.")
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<div className="space-y-4">
			{isLoading ? (
				<Loader message="Fetching patent details..." />
			) : onlyShowInputError ? (
				<>
					{inputError && (
						<Alert variant="destructive">
							<AlertDescription>{inputError}</AlertDescription>
						</Alert>
					)}
				</>
			) : (
				<>
					<p className="text-sm text-gray-500">
						Enter a comma-separated list of published patents and applications numbers. A
						country prefix is required.
					</p>
					<AutosizeTextarea
						value={inputText}
						onChange={(e) => setInputText(e.target.value)}
						placeholder={placeholder}
					/>
					{inputError && (
						<Alert variant="destructive">
							<AlertDescription>{inputError}</AlertDescription>
						</Alert>
					)}

					<div className="flex justify-end gap-2">
						{onCancel && (
							<Button variant="outline" onClick={onCancel}>
								Cancel
							</Button>
						)}
						<Button onClick={handleSubmit} disabled={inputText.trim() === ""}>
							{submitButtonText}
						</Button>
					</div>
				</>
			)}
		</div>
	)
}

export default PatentNumberInput
