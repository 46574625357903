import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import { CitationFormat } from "@/types"
import type React from "react"
interface DocumentChunkLocationFormatSelectProps {
	citationFormat: CitationFormat
	onFormatChange: (format: CitationFormat) => void
	isPatentApplication?: boolean
}

export const DocumentChunkLocationFormatSelect: React.FC<
	DocumentChunkLocationFormatSelectProps
> = ({ citationFormat, onFormatChange, isPatentApplication }) => {
	// Prevent clicks from bubbling up and closing the modal.
	const stopPropagation = (e: React.MouseEvent) => {
		e.stopPropagation()
	}

	return (
		<Select value={citationFormat} onValueChange={onFormatChange}>
			<SelectTrigger className="w-[180px] h-9" onMouseDown={stopPropagation}>
				<SelectValue placeholder="Citation format" />
			</SelectTrigger>
			<SelectContent>
				<SelectItem value={CitationFormat.FULL}>Full citation</SelectItem>
				<SelectItem value={CitationFormat.PAGES}>Pages</SelectItem>
				<SelectItem value={CitationFormat.PARAGRAPHS}>Paragraphs</SelectItem>
				{!isPatentApplication && (
					<SelectItem value={CitationFormat.COLUMN_LINE}>Column:Line</SelectItem>
				)}
			</SelectContent>
		</Select>
	)
}

export default DocumentChunkLocationFormatSelect
