"use client"

import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
	items: {
		title: string
	}[]
	selectedItem: string
	setSelectedItem: (item: string) => void
}

export function SettingsSidebarNav({
	selectedItem,
	setSelectedItem,
	className,
	items,
	...props
}: SidebarNavProps) {
	return (
		<nav
			className={cn("flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1", className)}
			{...props}
		>
			{items.map((item) => (
				<Button
					key={item.title}
					variant="ghost"
					className={cn(
						selectedItem === item.title
							? "bg-muted hover:bg-muted"
							: "hover:bg-transparent hover:underline",
						"justify-start",
					)}
					onClick={() => setSelectedItem(item.title)}
				>
					{item.title}
				</Button>
			))}
		</nav>
	)
}
