/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { api } from "@/api"
import { LONG_STALE_TIME_QUERY_OPTIONS, QUERY_KEYS } from "@/utils/query"
import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"
import type { FilterOptions } from "../components/UsageFilterSelects"

/**
 * useUserSessions is a hook that fetches user sessions data for either a super admin or an admin.
 *
 * @param isSuperAdmin - Whether the user is a super admin.
 * @param orgId - The organization id.
 * @param filters - The filters object containing timeframe and users.
 */
const useUserSessions = (
	isSuperAdmin: boolean,
	orgId: string,
	filters?: FilterOptions,
) => {
	const queryKey = isSuperAdmin
		? QUERY_KEYS.superAdmin.sessions
		: QUERY_KEYS.admin.sessions(orgId)

	const queryFn = () => {
		if (isSuperAdmin) {
			return api.getSuperAdminUserSessions(orgId)
		}
		return api.getAdminUserSessions(orgId)
	}

	const sessionData = useQuery({
		queryKey: queryKey,
		queryFn: queryFn,
		enabled: !!orgId,
		...LONG_STALE_TIME_QUERY_OPTIONS,
	})

	const filteredSessionData = useMemo(() => {
		if (!sessionData.data) return []

		let filteredData = sessionData.data

		// Apply date range filtering if a date range filter is provided.
		if (filters?.dateRange) {
			filteredData = filteredData.filter((session) => {
				if (!session.date) return false
				const sessionDate = new Date(session.date)
				return sessionDate >= filters.dateRange.from && sessionDate <= filters.dateRange.to
			})
		}

		// Apply user filtering if any users are selected.
		if (filters?.users && filters.users.length > 0) {
			filteredData = filteredData.filter((session) =>
				filters.users.includes(session.email || "Unknown"),
			)
		}

		return filteredData
	}, [sessionData.data, filters])

	return {
		sessionData: filteredSessionData,
		isLoading: sessionData.isLoading,
		isFetching: sessionData.isFetching,
	}
}

export { useUserSessions }
