import { Loader } from "@/components"
import { DocumentListItem } from "@/components/documents/DocumentListItem"
import {
	Table,
	TableBody,
	TableHeader,
	TableRow,
	TableWrapper,
} from "@/components/ui/table"
import { useAppStateStore } from "@/store"
import type { ProjectDocumentMetadata } from "@/types"
import type { DocumentRole, UnprocessedDocumentType } from "@/types/documents"
import { useCallback, useMemo, useState } from "react"
import { useAllProjectDocuments } from "../hooks/useAllProjectDocuments"
import useDeleteAndArchiveDocuments from "../hooks/useDeleteAndArchiveDocuments"
import { useDocumentsTable } from "../hooks/useDocumentsTable"
import { useUpdateDocumentMetadata } from "../hooks/useUpdateDocumentMetadata"
import { DocumentRoleListHeader } from "./DocumentRoleListHeader"

export interface DocumentRoleListProps {
	role: DocumentRole
	label: string
	documents: ProjectDocumentMetadata[]
	isLoading: boolean
	onAddDocument: (type: UnprocessedDocumentType, role: DocumentRole) => void
}

export function DocumentRoleList({
	role,
	label,
	documents,
	isLoading,
	onAddDocument,
}: DocumentRoleListProps) {
	const { chartedIds, subjectIds } = useAllProjectDocuments()
	const { table, filters } = useDocumentsTable(documents, role, subjectIds, chartedIds)

	const { updateDocumentMetadata } = useUpdateDocumentMetadata()
	const { addErrorMessage } = useAppStateStore()
	const { deleteDocuments, archiveDocuments } = useDeleteAndArchiveDocuments()
	const [selectedDocuments, setSelectedDocuments] = useState<ProjectDocumentMetadata[]>(
		[],
	)

	const tagOptions = useMemo(() => {
		return Array.from(
			new Set(documents.flatMap((doc) => (Array.isArray(doc.tags) ? doc.tags : []))),
		)
	}, [documents])

	const selectedTags = useMemo(() => {
		return Array.from(
			new Set(
				selectedDocuments.flatMap((doc) => (Array.isArray(doc.tags) ? doc.tags : [])),
			),
		)
	}, [selectedDocuments])
	// Toggle selection when a document is clicked.
	const handleSelectDocument = useCallback(
		(id: string) => {
			const doc = documents.find((d) => d.id === id)
			if (!doc) return

			setSelectedDocuments((prev) => {
				if (prev.some((item) => item.id === id)) {
					// Document already selected; remove it.
					return prev.filter((item) => item.id !== id)
				}
				// Add the document to the selection.
				return [...prev, doc]
			})
		},
		[documents],
	)

	const handleTagUpdate = useCallback(
		async (selectedTags: string[]) => {
			try {
				const documentIds = selectedDocuments.map((row) => row.id)
				if (selectedTags && documentIds) {
					await updateDocumentMetadata({
						documentIds,
						documentRole: role,
						updatePayload: {
							setTags: selectedTags,
						},
					})
				}
			} catch (_error) {
				addErrorMessage("Failed to update tags")
			} finally {
				setSelectedDocuments([])
			}
		},
		[updateDocumentMetadata, addErrorMessage, selectedDocuments, role],
	)

	const handleArchive = useCallback(
		async (documentIds: string[], removed: boolean) => {
			if (documentIds) {
				await archiveDocuments({
					documentIds,
					role,
					removed,
				})
			}
		},
		[archiveDocuments, role],
	)

	const handleDelete = useCallback(
		async (documentIds: string[]) => {
			if (documentIds) {
				await deleteDocuments({
					documentIds,
					role,
				})
			}
		},
		[deleteDocuments, role],
	)

	return (
		<TableWrapper className="m-3 relative isolate">
			<Table className="table-fixed w-full min-w-0">
				<TableHeader sticky>
					<DocumentRoleListHeader
						role={role}
						label={label}
						isLoading={isLoading}
						documentsCount={documents.length}
						selectedDocumentsCount={selectedDocuments.length}
						tagOptions={tagOptions}
						selectedTags={selectedTags}
						onAddDocumentClick={onAddDocument}
						onTagUpdate={handleTagUpdate}
						onArchive={() => {
							handleArchive(
								selectedDocuments.map((d) => d.id),
								true,
							)
							setSelectedDocuments([])
						}}
						onDelete={() => {
							handleDelete(selectedDocuments.map((d) => d.id))
							setSelectedDocuments([])
						}}
						table={table}
						filters={filters}
					/>
				</TableHeader>
				<TableBody>
					{isLoading ? (
						<div className="flex justify-center items-center">
							<Loader message="Loading..." />
						</div>
					) : (
						<>
							{table.getRowModel().rows.map((row, index) => {
								const document = row.original
								return (
									<TableRow key={document.id ?? index}>
										<DocumentListItem
											key={document.id ?? index}
											document={document}
											onTagUpdate={handleTagUpdate}
											onSelect={handleSelectDocument}
											availableTags={tagOptions}
											onDelete={handleDelete}
											onArchive={handleArchive}
											selected={selectedDocuments.some((doc) => doc.id === document.id)}
										/>
									</TableRow>
								)
							})}
						</>
					)}
				</TableBody>
			</Table>
		</TableWrapper>
	)
}
