import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useProjectContext } from "@/context/ProjectContext"
import { useDeleteAndArchiveDocuments } from "@/features/documents/hooks/useDeleteAndArchiveDocuments"
import { useUpdateDocumentMetadata } from "@/features/documents/hooks/useUpdateDocumentMetadata"
import { useAppStateStore } from "@/store"
import type { ProjectDocumentMetadata } from "@/types"
import { MoreVertical } from "lucide-react"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { DocumentListItemContent } from "./DocumentListContent"
export interface DocumentListItemProps {
	document: ProjectDocumentMetadata
	selected?: boolean
	onSelect?: (id: string) => void
	onTagUpdate?: (selectedTags: string[]) => void
	availableTags?: string[]
	onDelete?: (documentIds: string[]) => void
	onArchive?: (documentIds: string[], removed: boolean) => void
}

export const DocumentListItem = (props: DocumentListItemProps) => {
	const {
		document,
		selected,
		onSelect,
		onTagUpdate,
		availableTags,
		onDelete,
		onArchive,
	} = props
	const navigate = useNavigate()
	const { projectId } = useProjectContext()
	const { deleteDocuments } = useDeleteAndArchiveDocuments()
	const [expanded, setExpanded] = useState(false)
	const toggleExpand = () => {
		setExpanded((prev) => !prev)
	}

	// State for editing the document nickname.
	const [isEditingNickname, setIsEditingNickname] = useState(false)
	const { updateDocumentMetadata } = useUpdateDocumentMetadata()

	// State for editing the document notes.
	const [isEditingNotes, setIsEditingNotes] = useState(false)
	const { addErrorMessage } = useAppStateStore()

	const handleSaveName = useCallback(
		async (editedNickname: string) => {
			try {
				setIsEditingNickname(false)
				await updateDocumentMetadata({
					documentIds: [document.id],
					documentRole: document.documentRole,
					updatePayload: {
						nickname: editedNickname,
					},
				})
			} catch (error) {
				addErrorMessage(typeof error === "string" ? error : "Failed to update name")
			}
		},
		[updateDocumentMetadata, addErrorMessage, document],
	)

	const handleTagUpdate = useCallback(
		async (documentIds: string[], newTags: string[]) => {
			await updateDocumentMetadata({
				documentIds: documentIds,
				documentRole: document.documentRole,
				updatePayload: {
					setTags: newTags,
				},
			})
		},
		[updateDocumentMetadata, document],
	)

	const handleSaveNote = useCallback(
		async (editedNotes: string) => {
			try {
				setIsEditingNotes(false)
				await updateDocumentMetadata({
					documentIds: [document.id],
					documentRole: document.documentRole,
					updatePayload: {
						notes: editedNotes,
					},
				})
			} catch (_error) {
				addErrorMessage("Failed to save note")
			}
		},
		[updateDocumentMetadata, addErrorMessage, document],
	)

	const handleNavigate = () => {
		navigate(`/project/${projectId}/documents/${document.id}`)
	}

	const handleSelect = () => {
		onSelect?.(document.id)
	}

	return (
		<div className="flex items-start p-3 w-full min-w-0 overflow-hidden">
			<Checkbox
				checked={selected}
				onCheckedChange={handleSelect}
				aria-label={selected ? "Selected" : "Select document"}
				className="mt-1"
			/>

			{/* Content */}
			<div className="ml-4 flex-1 min-w-0 overflow-hidden">
				<DocumentListItemContent
					document={document}
					expanded={expanded}
					isEditingNickname={isEditingNickname}
					isEditingNotes={isEditingNotes}
					onSaveName={handleSaveName}
					onSaveNotes={handleSaveNote}
					onTagUpdate={handleTagUpdate}
					availableTags={availableTags}
					onClick={handleNavigate}
				/>
			</div>

			{/* Actions */}
			<div className="ml-2 flex items-start gap-2">
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button
							variant="ghost"
							size="icon"
							className="h-8 w-8 rounded-full text-gray-500 hover:bg-gray-100 hover:text-gray-700"
							aria-label="More options"
						>
							<MoreVertical className="h-4 w-4" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end" className="w-48">
						<DropdownMenuItem onClick={() => toggleExpand()}>
							{expanded ? "Collapse" : "Expand"}
						</DropdownMenuItem>
						<DropdownMenuItem onClick={() => setIsEditingNickname(true)}>
							Rename
						</DropdownMenuItem>
						<DropdownMenuItem
							onClick={() => {
								// Ensure the expanded content is visible before editing notes.
								if (!expanded) setExpanded(true)
								setIsEditingNotes(true)
							}}
						>
							Edit notes
						</DropdownMenuItem>
						{/* <DropdownMenuItem onClick={() => onArchive?.([document.id], true)}>
							Archive
						</DropdownMenuItem> */}
						<DropdownMenuItem onClick={() => onDelete?.([document.id])}>
							Delete
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
		</div>
	)
}
