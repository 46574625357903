/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { cn } from "@/lib/utils"
import { ChartColor } from "@/types"
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons"
import React, { useCallback, useState } from "react"

interface ColorSelectProps {
	colors: string[]
	selectedColors: string[]
	setSelectedColors: (selectedColors: string[]) => void
	disabled?: boolean
}

/**
 * @description Chart color select
 * @param {string[]} colors - The colors to select from.
 * @param {string[]} selectedColors - The selected colors.
 * @param {function} setSelectedColors - Function to set the selected colors.
 * @param {boolean} disabled - Whether the color select is disabled.
 */
const ColorSelect: React.FC<ColorSelectProps> = ({
	colors = [],
	selectedColors,
	setSelectedColors,
	disabled = false,
}) => {
	const [open, setOpen] = useState(false)

	const handleColorToggle = useCallback(
		(color: string) => {
			setSelectedColors(
				selectedColors.includes(color)
					? selectedColors.filter((c) => c !== color)
					: [...selectedColors, color],
			)
		},
		[selectedColors, setSelectedColors],
	)

	const handleSelectAll = useCallback(() => {
		setSelectedColors(selectedColors.length === colors.length ? [] : [...colors])
	}, [colors, selectedColors.length, setSelectedColors])

	// Handle popover close - if no colors selected, select all
	const handleOpenChange = (isOpen: boolean) => {
		if (!isOpen && selectedColors.length === 0) {
			setSelectedColors([...colors])
		}
		setOpen(isOpen)
	}

	return (
		<Popover open={open} onOpenChange={handleOpenChange}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className={cn("w-[200px] justify-between", disabled && "opacity-50")}
					disabled={disabled}
				>
					<div className="flex items-center space-x-1">
						{selectedColors.length > 0 ? (
							selectedColors.map((color) => (
								<div
									key={color}
									className="h-4 w-4 rounded-full"
									style={{ backgroundColor: color }}
								/>
							))
						) : (
							<span>Select colors</span>
						)}
					</div>
					<ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent forceInline className="w-[200px] p-0">
				<Button
					variant="ghost"
					className="w-full justify-start font-bold"
					onClick={handleSelectAll}
				>
					<div className="flex items-center">
						{selectedColors.length === colors.length && (
							<CheckIcon className="mr-2 h-4 w-4" />
						)}
						<span>Select All</span>
					</div>
				</Button>
				{colors.map((color) => (
					<Button
						key={color}
						variant="ghost"
						className="w-full justify-start"
						onClick={() => handleColorToggle(color)}
					>
						<div className="flex items-center">
							{selectedColors.includes(color) && <CheckIcon className="mr-2 h-4 w-4" />}
							<div className="h-4 w-4 rounded-full mr-2" style={{ backgroundColor: color }} />
							<span>
								{color === ChartColor.GREEN
									? "Green"
									: color === ChartColor.RED
										? "Red"
										: color === ChartColor.YELLOW
											? "Yellow"
											: color}
							</span>
						</div>
					</Button>
				))}
			</PopoverContent>
		</Popover>
	)
}

export default React.memo(ColorSelect)
