import { api } from "@/api"
import { QUERY_KEYS } from "@/utils"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"
import { useCallback } from "react"
import { useParams } from "react-router-dom"

export function useProjectMetadata() {
	const { projectId } = useParams()

	const projectMetadataQuery = useQuery({
		queryKey: QUERY_KEYS.project.metadata(projectId),
		queryFn: () => api.getProjectMetadata(projectId as string),
		enabled: !!projectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	const projectUsageQuery = useQuery({
		queryKey: QUERY_KEYS.project.usage(projectId),
		queryFn: () => api.getProjectUsage(projectId as string),
		enabled: !!projectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	const amountToBeSpentOverBudget = useCallback(
		(numDocumentsToAdd: number) => {
			const newSpend =
				projectUsageQuery.data?.documentProcessFee *
					projectUsageQuery.data?.documentsProcessed +
				numDocumentsToAdd * projectUsageQuery.data?.documentProcessFee
			return newSpend - projectMetadataQuery.data?.budget
		},
		[projectUsageQuery.data, projectMetadataQuery.data],
	)

	const willBudgetBeExceeded = useCallback(
		(numDocumentsToAdd: number) => {
			const currentSpend =
				projectUsageQuery.data?.documentProcessFee *
				projectUsageQuery.data?.documentsProcessed
			const totalSpend =
				currentSpend + numDocumentsToAdd * projectUsageQuery.data?.documentProcessFee
			return totalSpend > projectMetadataQuery.data?.budget
		},
		[projectUsageQuery.data, projectMetadataQuery.data],
	)

	return {
		projectMetadata: projectMetadataQuery.data,
		isLoading: projectMetadataQuery.isLoading,
		isFetching: projectMetadataQuery.isFetching,
		isError: projectMetadataQuery.isError,
		refetchProjectMetadata: projectMetadataQuery.refetch,
		subjectId: projectMetadataQuery.data?.subject?.id ?? "",
		projectCreatedBy: projectMetadataQuery.data?.createdBy,
		projectName: projectMetadataQuery.data?.name,
		projectType: projectMetadataQuery.data?.type,
		projectBudget: projectMetadataQuery.data?.budget,
		projectUsage: projectUsageQuery.data,
		willBudgetBeExceeded,
		amountToBeSpentOverBudget,
	}
}

export default useProjectMetadata
