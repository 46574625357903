import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import { type NodeViewProps, NodeViewWrapper } from "@tiptap/react"
import { Bold, Italic, Underline } from "lucide-react"
import { useMemo } from "react"

const VariablePill: React.FC<NodeViewProps> = (props) => {
	const { editor, node, getPos, selected } = props
	const { id, label } = node.attrs
	const pos = getPos()

	// Find the marks applied to the node itself (for styling the button states)
	const marks = useMemo(() => {
		if (!editor.state.doc.nodeAt(pos)) return []
		return editor.state.doc.nodeAt(pos)?.marks ?? []
	}, [editor.state.selection, pos])

	return (
		<NodeViewWrapper
			as="span"
			className={cn(
				"relative inline-block rounded-md px-2 py-0.5 mx-0.5 text-sm font-medium cursor-pointer group",
				selected
					? "bg-blue-100 dark:bg-blue-900 border border-blue-500"
					: "bg-gray-100 dark:bg-gray-700",
				"mt-2",
				marks.some((mark) => mark.type.name === "bold") && "font-bold",
				marks.some((mark) => mark.type.name === "italic") && "italic",
				marks.some((mark) => mark.type.name === "underline") && "underline",
			)}
			data-variable-id={id}
			data-variable-label={label}
			draggable="true"
			data-drag-handle
		>
			{label}
			{/* Formatting menu */}
			<div
				className={cn(
					"absolute -top-7 left-1/2 -translate-x-1/2 transition-opacity duration-200 bg-white dark:bg-gray-800 rounded-md shadow-lg border border-gray-200 dark:border-gray-700 p-1 flex gap-1",
					selected ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none",
				)}
			>
				<Button
					variant="ghost"
					size="sm"
					className={cn(
						"h-5 w-5 p-0",
						marks.some((mark) => mark.type.name === "bold") && "bg-gray-100 dark:bg-gray-700",
					)}
					onClick={() => {
						editor.chain().focus().setNodeSelection(pos).toggleBold().run()
					}}
				>
					<Bold className="h-2.5 w-2.5" />
				</Button>
				<Button
					variant="ghost"
					size="sm"
					className={cn(
						"h-5 w-5 p-0",
						marks.some((mark) => mark.type.name === "italic") &&
							"bg-gray-100 dark:bg-gray-700",
					)}
					onClick={() => {
						editor.chain().focus().setNodeSelection(pos).toggleItalic().run()
					}}
				>
					<Italic className="h-2.5 w-2.5" />
				</Button>
				<Button
					variant="ghost"
					size="sm"
					className={cn(
						"h-5 w-5 p-0",
						marks.some((mark) => mark.type.name === "underline") &&
							"bg-gray-100 dark:bg-gray-700",
					)}
					onClick={() => {
						editor.chain().focus().setNodeSelection(pos).toggleUnderline().run()
					}}
				>
					<Underline className="h-2.5 w-2.5" />
				</Button>
			</div>
		</NodeViewWrapper>
	)
}

export default VariablePill
