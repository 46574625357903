import { api } from "@/api"
import { useProjectContext } from "@/context/ProjectContext"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import type { ClaimStrength } from "@/types"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"

/**
 * @description Hook for handling references
 */
const useClaimStrength = (subjectId: string) => {
	const { projectId } = useProjectContext()
	const { data, isLoading, error } = useQuery({
		queryKey: QUERY_KEYS.project.subject.claimStrengths(projectId, subjectId),
		queryFn: async (): Promise<ClaimStrength[]> => {
			return await api.getClaimStrengths(projectId, subjectId)
		},
		...DEFAULT_QUERY_OPTIONS,
		enabled: !!projectId && !!subjectId,
	})

	return { claimStrengths: data, isLoading, error }
}

export default useClaimStrength
