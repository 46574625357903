/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { CountryCodeSelect, InfoPopover, ItemTags } from "@/components"
import DualDocumentSelect from "@/components/documents/DualDocumentSelect"
import { CheckboxWithText } from "@/components/ui/checkbox"
import { DateRangePicker } from "@/components/ui/date-range-picker"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItemWithLabel } from "@/components/ui/radio-group"
import { PatchClaimsSearchSection } from "@/features/documents/components/PatchClaimsSearchSection"
import { ClaimTypes } from "@/types"
import type { SearchFilters } from "@/types"
import type { ProjectDocumentMetadata } from "@/types/documents"
import { SUPPORTED_JURISDICTIONS } from "@/types/project"
import { SearchMode } from "@/types/search-references"
import type React from "react"
import SiteListManager from "./SiteListManager"

/**
 * @description Prior art search filters
 */
interface SearchFiltersProps {
	disabled: boolean
	filters: SearchFilters
	updateFilter: (
		key: keyof SearchFilters,
		value: SearchFilters[keyof SearchFilters],
	) => void
	hideSelectedClaimsOption?: boolean
	// Add props for document selection (for 103 search)
	projectDocuments?: ProjectDocumentMetadata[]
	selectedDocumentId?: string
	onDocumentSelect?: (docId: string) => void
	searchMode?: SearchMode
}

const SearchFiltersComponent: React.FC<SearchFiltersProps> = ({
	disabled,
	filters,
	updateFilter,
	hideSelectedClaimsOption = false,
	projectDocuments = [],
	selectedDocumentId = "",
	onDocumentSelect,
	searchMode = SearchMode.MODE_PATENT,
}) => {
	const {
		assignees,
		toDate,
		fromDate,
		cpcCodes,
		countryCodes,
		maxResults,
		dedupeFamilyId,
		selectedClaims,
		unselectedClaims,
		claimType,
		whitelistedSites = [],
		blacklistedSites = [],
	} = filters

	const handleDocumentSelect = (docId: string) => {
		// If selecting a document and currently using SELECTED_CLAIMS, switch to INDEPENDENT_CLAIMS
		if (docId && claimType === ClaimTypes.SELECTED_CLAIMS) {
			updateFilter("claimType", ClaimTypes.INDEPENDENT_CLAIMS)
		}
		// Call the original onDocumentSelect handler
		if (onDocumentSelect) {
			onDocumentSelect(docId)
		}
	}

	const assigneeContent =
		"Assignee names require an exact match. For comprehensive results, enter all valid versions of an assignee name (e.g., 'Google', 'Google Inc.', 'Google, Inc.', etc.)"

	const renderItemTags = (
		label: string,
		items: string[],
		setItems: (items: string[]) => void,
		type: string,
		infoContent?: React.ReactNode,
	) => (
		<div className="space-y-1">
			<div className="flex items-center space-x-1">
				<Label className="text-sm mr-1">{label}</Label>
				{infoContent && <InfoPopover className="ml-1 mb-2" content={assigneeContent} />}
			</div>
			<ItemTags
				items={items}
				setItems={setItems}
				type={type as "cpc" | "assignee"}
				disabled={disabled}
			/>
		</div>
	)

	const renderNPLFilters = () => (
		<div className="space-y-6">
			<div className="flex flex-wrap gap-4">
				<div>
					<DateRangePicker
						fromDate={fromDate}
						toDate={toDate}
						onFromDateChange={(date) => updateFilter("fromDate", date)}
						onToDateChange={(date) => updateFilter("toDate", date)}
						label="Priority Date"
						disabled={disabled}
					/>
				</div>

				<div className="space-y-1">
					<Label className="text-sm">Max Results</Label>
					<Input
						type="number"
						placeholder="Optional"
						value={maxResults}
						onChange={(e) => {
							const value = Number.parseInt(e.target.value, 10)
							if (!Number.isNaN(value)) {
								updateFilter("maxResults", value)
							}
						}}
						disabled={disabled}
						className="h-10"
					/>
				</div>
			</div>
		</div>
	)

	const renderProductFilters = () => {
		return (
			<div className="space-y-6">
				<div className="flex flex-wrap gap-4">
					<div>
						<DateRangePicker
							fromDate={fromDate}
							toDate={toDate}
							onFromDateChange={(date) => updateFilter("fromDate", date)}
							onToDateChange={(date) => updateFilter("toDate", date)}
							label="Priority Date"
							disabled={disabled}
						/>
					</div>
				</div>

				<SiteListManager
					whitelistedSites={whitelistedSites}
					blacklistedSites={blacklistedSites}
					onWhitelistedSitesChange={(sites) => updateFilter("whitelistedSites", sites)}
					onBlacklistedSitesChange={(sites) => updateFilter("blacklistedSites", sites)}
					disabled={disabled}
				/>
			</div>
		)
	}

	const renderPatentFilters = () => (
		<div className="space-y-6">
			{/* Deduplicate by, Max Results, Priority Date, Patent Offices */}
			<div className="flex flex-wrap gap-4">
				<div className="space-y-1">
					<div className="flex items-center space-x-2">
						<Label className="text-sm">Patent Offices</Label>
					</div>
					<CountryCodeSelect
						countryCodes={SUPPORTED_JURISDICTIONS}
						selectedCountryCodes={countryCodes}
						setSelectedCountryCodes={(codes) => updateFilter("countryCodes", codes)}
						disabled={disabled}
					/>
				</div>

				<div>
					<DateRangePicker
						fromDate={fromDate}
						toDate={toDate}
						onFromDateChange={(date) => updateFilter("fromDate", date)}
						onToDateChange={(date) => updateFilter("toDate", date)}
						label="Priority Date"
						disabled={disabled}
					/>
				</div>

				<div className="space-y-1">
					<Label className="text-sm">Max Results</Label>
					<Input
						type="number"
						placeholder="Optional"
						value={maxResults}
						onChange={(e) => {
							const value = Number.parseInt(e.target.value, 10)
							if (!Number.isNaN(value)) {
								updateFilter("maxResults", value)
							}
						}}
						disabled={disabled}
						className="h-10"
					/>
				</div>

				{dedupeFamilyId !== undefined && (
					<div className="space-y-4">
						<div className="flex flex-col space-y-1">
							<Label className="text-sm">Deduplicate By</Label>
							<CheckboxWithText
								id="Family ID"
								checked={dedupeFamilyId}
								onCheckedChange={(checked) => {
									updateFilter("dedupeFamilyId", checked as boolean)
								}}
								label="Family ID"
							/>
						</div>
					</div>
				)}
			</div>

			{/* Assignees and CPC Codes */}
			<div className="flex gap-4 w-full">
				<div className="flex-1">
					{renderItemTags(
						"Assignees",
						assignees,
						(items) => updateFilter("assignees", items),
						"assignee",
						assigneeContent,
					)}
				</div>
				<div className="flex-1">
					{renderItemTags(
						"CPC Codes",
						cpcCodes,
						(items) => updateFilter("cpcCodes", items),
						"cpc",
					)}
				</div>
			</div>

			<div className="grid grid-cols-2">
				{/* Claims section */}
				<div className="flex-1">
					<div className="space-y-1">
						<div className="flex items-center space-x-2">
							<Label className="text-sm">Relevant Claims</Label>
						</div>
						<RadioGroup
							className="flex flex-row gap-4"
							value={claimType}
							onValueChange={(value) => updateFilter("claimType", value as ClaimTypes)}
						>
							<RadioGroupItemWithLabel value={ClaimTypes.ALL_CLAIMS} label="All" />
							<RadioGroupItemWithLabel value={ClaimTypes.ASSERTED_CLAIMS} label="Asserted" />
							<RadioGroupItemWithLabel
								value={ClaimTypes.INDEPENDENT_CLAIMS}
								label="Independent"
							/>
							{!hideSelectedClaimsOption && (
								<RadioGroupItemWithLabel value={ClaimTypes.SELECTED_CLAIMS} label="Selected" />
							)}
						</RadioGroup>
					</div>
					{claimType === ClaimTypes.SELECTED_CLAIMS && (
						<PatchClaimsSearchSection
							isVisible
							unselectedClaims={unselectedClaims}
							selectedClaims={selectedClaims}
							setSelectedClaims={(claims) => updateFilter("selectedClaims", claims)}
							setUnselectedClaims={(claims) => updateFilter("unselectedClaims", claims)}
						/>
					)}
				</div>

				{/* Document Selection for 103*/}
				{projectDocuments.length > 0 && (
					<div className=" max-w-sm">
						<Label className="text-sm">Target Patent</Label>
						<DualDocumentSelect
							documents={projectDocuments}
							selectedDocumentIds={selectedDocumentId ? [selectedDocumentId] : []}
							handleDocumentSelect={(ids) =>
								handleDocumentSelect(ids.length > 0 ? ids[0] : "")
							}
							showOnlyOne={true}
							label="Search for a complementary 103 patent"
						/>
					</div>
				)}
			</div>
		</div>
	)

	switch (searchMode) {
		case SearchMode.MODE_NPL:
			return renderNPLFilters()
		case SearchMode.MODE_PRODUCT:
			return renderProductFilters()
		case SearchMode.MODE_PATENT:
			return renderPatentFilters()
		default:
			return renderPatentFilters()
	}
}

export default SearchFiltersComponent
