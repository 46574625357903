/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
"use client"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { SidebarTrigger, useSidebar } from "@/components/ui/sidebar"
import { useProjectMetadata } from "@/hooks/project/useProjectMetadata"
import useRenameProject from "@/hooks/project/useRenameProject"
import { Pencil } from "lucide-react"
import { useEffect, useRef, useState } from "react"
/**
 * @description App sidebar component
 */
export function ProjectSidebarHeader() {
	const { projectName } = useProjectMetadata()
	const { open } = useSidebar()
	const { renameProject } = useRenameProject()

	const [isEditingProjectName, setIsEditingProjectName] = useState(false)
	const [projectNameInput, setProjectNameInput] = useState(projectName)
	const inputRef = useRef<HTMLInputElement>(null)
	const [isHovered, setIsHovered] = useState(false)

	useEffect(() => {
		setProjectNameInput(projectName)
	}, [projectName])

	useEffect(() => {
		// Focus the input only when the edit form div is active
		if (isEditingProjectName && inputRef.current) {
			inputRef.current.focus()
		}
	}, [isEditingProjectName])

	const handleSaveProjectName = () => {
		if (projectNameInput.trim()) {
			renameProject({ name: projectNameInput })
		}
		setIsEditingProjectName(false)
	}

	const handleEditProjectName = () => {
		setProjectNameInput(projectName)
		setIsEditingProjectName(true)
	}

	return (
		<div className="flex items-center p-2 justify-between">
			{open && (
				<div className="flex-1 overflow-hidden mr-2">
					{isEditingProjectName ? (
						<form
							onSubmit={(e) => {
								e.preventDefault()
								handleSaveProjectName()
							}}
							className="flex items-center m-0 p-0"
						>
							<Input
								ref={inputRef}
								value={projectNameInput}
								onChange={(e) => setProjectNameInput(e.target.value)}
								onBlur={handleSaveProjectName}
								placeholder="Project Name"
								className="text-base font-medium border-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-foreground dark:text-foreground"
							/>
						</form>
					) : (
						<div
							className="inline-flex items-center gap-1 max-w-full"
							onMouseEnter={() => setIsHovered(true)}
							onMouseLeave={() => setIsHovered(false)}
						>
							<Label className="mb-0 truncate max-w-full">{projectName}</Label>
							{isHovered && (
								<Button
									variant="ghost"
									size="icon"
									onClick={handleEditProjectName}
									className="h-6 w-6 hover:bg-background/20 transition-opacity shrink-0"
								>
									<Pencil className="h-3 w-3" />
								</Button>
							)}
						</div>
					)}
				</div>
			)}
			<SidebarTrigger className="shrink-0" />
		</div>
	)
}
