import { DialogueRole } from "@/types/chat"

interface MessageBubbleProps {
	role: (typeof DialogueRole)[keyof typeof DialogueRole]
	content: string | null
}

export const ChatMessageBubble: React.FC<MessageBubbleProps> = ({ role, content }) => {
	return (
		<div
			className={`flex ${role === DialogueRole.USER ? "justify-end" : "justify-start"}`}
		>
			<div
				className={`max-w-[80%] rounded-lg px-4 py-2 ${
					role === DialogueRole.USER ? "bg-[#c0d4fc] text-black" : "bg-muted"
				}`}
			>
				{content ?? ""}
			</div>
		</div>
	)
}
