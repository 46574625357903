import { api } from "@/api"
import { DEFAULT_QUERY_OPTIONS, QUERY_KEYS } from "@/utils/query"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useQuery } from "@tanstack/react-query"

/**
 * useAdminOverview is a hook that fetches admin overview data for a given organization.
 *
 * @param orgId - The organization id.
 */
const useAdminOverview = (orgId: string) => {
	const adminOverview = useQuery({
		queryKey: QUERY_KEYS.admin.overview(orgId),
		queryFn: () => api.getAdminOverviewForOrg(orgId),
		enabled: !!orgId,
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		adminOverview: adminOverview.data,
		isLoading: adminOverview.isLoading,
		isFetching: adminOverview.isFetching,
	}
}

export { useAdminOverview }
