import type { QueryClient } from "@tanstack/react-query"
import { toCamelCase } from "../dataUtils"

interface OptimisticUpdateConfig<T = any> {
	queryClient: QueryClient
	// Update the type to include readonly tuples
	queryKeys: (readonly any[] | any[])[]
	// Update function that receives the old data and returns new data
	updateFn: (oldData: T | undefined) => T | undefined
}

interface OptimisticContext {
	snapshots: Map<string, any>
}

/**
 * Helper function to handle optimistic updates across multiple queries
 * Returns a context object containing snapshots for reverting if needed
 */
export const handleOptimisticUpdate = async <T>({
	queryClient,
	queryKeys,
	updateFn,
}: OptimisticUpdateConfig<T>): Promise<OptimisticContext> => {
	const snapshots = new Map()

	// Cancel any in-flight queries
	await Promise.all(queryKeys.map((key) => queryClient.cancelQueries({ queryKey: key })))

	// Take snapshots and update each query
	for (const queryKey of queryKeys) {
		// Store snapshot of current data
		const currentData = queryClient.getQueryData(queryKey)
		snapshots.set(queryKey.join("."), currentData)

		// Update the query data
		queryClient.setQueryData(queryKey, (oldData: T | undefined) =>
			toCamelCase(updateFn(oldData)),
		)
	}

	return { snapshots }
}

/**
 * Helper function to revert optimistic updates using stored snapshots
 */
export const revertOptimisticUpdates = (
	queryClient: QueryClient,
	context: OptimisticContext,
) => {
	context.snapshots.forEach((snapshot, queryKeyString) => {
		const queryKey = queryKeyString.split(".")
		queryClient.setQueryData(queryKey, snapshot)
	})
}
