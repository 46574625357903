import { Badge } from "@/components/ui/badge"
import {
	Table,
	TableBody,
	TableCellCondensed,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import type { AdminProjectSummary } from "@/types/admin"
import { formatTimestamp } from "@/utils/dateUtils"
import { ChevronDown, ChevronRight } from "lucide-react"
import React, { useState } from "react"

/**
 * ProjectsUsageTable renders a hierarchical view with two possible levels:
 * ProjectSummary > AdminDocumentSummary
 */
function ProjectsUsageTable({
	projects,
}: {
	projects: AdminProjectSummary[]
}) {
	const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set())
	const [expandedDocuments, setExpandedDocuments] = useState<Set<string>>(new Set())

	const toggleRow = (id: string) => {
		setExpandedRows((prev) => {
			const next = new Set(prev)
			if (next.has(id)) {
				next.delete(id)
			} else {
				next.add(id)
			}
			return next
		})
	}

	const toggleDocuments = (id: string) => {
		setExpandedDocuments((prev) => {
			const next = new Set(prev)
			if (next.has(id)) {
				next.delete(id)
			} else {
				next.add(id)
			}
			return next
		})
	}

	const isPortfolio = (item: any): boolean => {
		return item.type === "Portfolio" || "children" in item
	}

	const isProject = (item: any): boolean => {
		return !isPortfolio(item)
	}

	const hasDocuments = (item: any): boolean => {
		// Only check for documents on projects, not portfolios
		return isProject(item) && item.documents && item.documents.length > 0
	}

	// Render each row based on its type and level
	const renderRow = (item: AdminProjectSummary, level = 0) => {
		const isExpanded = expandedRows.has(item.id)
		const isDocumentsExpanded = expandedDocuments.has(item.id)

		// Check if this project has documents
		const projectHasDocuments = hasDocuments(item)

		return (
			<React.Fragment key={item.id}>
				{/* Main row for either portfolio or project */}
				<TableRow>
					<TableCellCondensed>
						<span className={level > 0 ? "pl-4" : ""}>{item.name || "N/A"}</span>
					</TableCellCondensed>
					<TableCellCondensed>
						<Badge variant="outline">{item.type || "N/A"}</Badge>
					</TableCellCondensed>
					<TableCellCondensed>{item.createdBy || "N/A"}</TableCellCondensed>
					<TableCellCondensed>{formatTimestamp(item.createdAt)}</TableCellCondensed>
					<TableCellCondensed>{formatTimestamp(item.updatedAt)}</TableCellCondensed>
					<TableCellCondensed>{item.documentsProcessed ?? "-"}</TableCellCondensed>
					<TableCellCondensed>{item.balance ?? "$0.00"}</TableCellCondensed>
					<TableCellCondensed className="text-right">
						{projectHasDocuments && (
							<button
								type="button"
								onClick={() => toggleDocuments(item.id)}
								className="focus:outline-none inline-flex"
								title="Toggle documents"
							>
								{isExpanded ? (
									<ChevronDown className="h-4 w-4" />
								) : (
									<ChevronRight className="h-4 w-4" />
								)}
							</button>
						)}
					</TableCellCondensed>
				</TableRow>

				{/* If this is a standalone project with expanded documents */}
				{isDocumentsExpanded && hasDocuments(item) && (
					<TableRow>
						<TableCellCondensed colSpan={9} className="bg-gray-50 p-0">
							<div className="pl-8">
								<Table>
									<TableHeader>
										<TableRow>
											<TableHead>Document Name</TableHead>
											<TableHead>Type</TableHead>
											<TableHead>Role</TableHead>
											<TableHead>Created</TableHead>
											<TableHead>Creator</TableHead>
											<TableHead>Status</TableHead>
											<TableHead>Balance</TableHead>
											<TableHead colSpan={2} />
										</TableRow>
									</TableHeader>
									<TableBody>
										{isProject(item) &&
										(item as AdminProjectSummary).documents &&
										(item as AdminProjectSummary).documents.length > 0 ? (
											(item as AdminProjectSummary).documents.map((doc) => (
												<TableRow key={doc.id}>
													<TableCellCondensed>{doc.nickname || "N/A"}</TableCellCondensed>
													<TableCellCondensed>
														<Badge variant="outline">{doc.type || "N/A"}</Badge>
													</TableCellCondensed>
													<TableCellCondensed>
														<Badge variant="outline">{doc.role || "N/A"}</Badge>
													</TableCellCondensed>
													<TableCellCondensed>{formatTimestamp(doc.createdAt)}</TableCellCondensed>
													<TableCellCondensed>{doc.createdBy || "N/A"}</TableCellCondensed>
													<TableCellCondensed>{doc.balance || "$0.00"}</TableCellCondensed>
													<TableCellCondensed colSpan={2} />
												</TableRow>
											))
										) : (
											<TableRow>
												<TableCellCondensed colSpan={9} className="text-center text-gray-500">
													No documents available
												</TableCellCondensed>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</div>
						</TableCellCondensed>
					</TableRow>
				)}
			</React.Fragment>
		)
	}

	if (projects?.length === 0) {
		return <div className="text-center text-sm text-gray-500">No projects found</div>
	}

	return (
		<Table>
			<TableHeader>
				<TableRow>
					<TableHead>Name</TableHead>
					<TableHead>Type</TableHead>
					<TableHead>Creator</TableHead>
					<TableHead>Created</TableHead>
					<TableHead>Active</TableHead>
					<TableHead>Processed</TableHead>
					<TableHead>Exported</TableHead>
					<TableHead>Balance</TableHead>
					<TableHead />
				</TableRow>
			</TableHeader>
			<TableBody>{projects.map((item) => renderRow(item))}</TableBody>
		</Table>
	)
}

export default ProjectsUsageTable
