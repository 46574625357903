/*
 * Copyright AndAI, Inc. 2025. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc.
 * and is protected as a trade secret.
 */

import { ErrorMessage, Loader, ProjectPage } from "@/components"
import { Table } from "@/components/ui/table"
import { useProjectContext } from "@/context/ProjectContext"
import { PageName } from "@/types/pages"
import type React from "react"
import useProjectChartsMatrix from "./hooks/queries/useProjectChartsMatrix"
import ProjectChartsMatrixBody from "./matrix/ProjectChartsMatrixBody"
import ProjectChartsMatrixHeader from "./matrix/ProjectChartsMatrixHeader"

const ProjectChartsMatrixPage: React.FC = () => {
	const { projectId } = useProjectContext()
	const { projectChartsMatrix, projectChartsMatrixLoading, projectChartsMatrixError } =
		useProjectChartsMatrix(projectId)

	// Show loader while loading and handle errors
	if (projectChartsMatrixLoading) {
		return (
			<ProjectPage pageName={PageName.ProjectCharts}>
				<div className="w-full">
					<Loader />
				</div>
			</ProjectPage>
		)
	}

	if (projectChartsMatrixError) {
		return (
			<ProjectPage pageName={PageName.ProjectCharts}>
				<div className="w-full">
					<ErrorMessage />
				</div>
			</ProjectPage>
		)
	}

	if (!projectChartsMatrix) return null

	// Check if cells is empty for every subject in the matrix.
	const noChartsExist = projectChartsMatrix.every(
		(subject) => subject.cells.length === 0,
	)
	if (noChartsExist) {
		return (
			<ProjectPage pageName={PageName.ProjectCharts}>
				<div className="w-full p-4 text-center">This project has no charts.</div>
			</ProjectPage>
		)
	}

	const singleRow = projectChartsMatrix[0].cells

	// Subject width is 15% of the total width
	// Chart width is the remaining 85% of the total width divided by the number of charts.
	// This is dynamically handled in ProjectChartsMatrixRow.tsx
	const subjectWidthPercent = 15

	return (
		<ProjectPage pageName={PageName.ProjectCharts}>
			<div className="w-full">
				<div className="border border-border rounded-md m-2">
					<Table>
						<ProjectChartsMatrixHeader colWidthPercent={subjectWidthPercent} />
						<ProjectChartsMatrixBody colWidthPercent={subjectWidthPercent} />
					</Table>
				</div>
			</div>
		</ProjectPage>
	)
}

export default ProjectChartsMatrixPage
