import { SettingsSection } from "@/components"
import VariableInput from "@/components/input/VariableInput"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItemWithLabel } from "@/components/ui/radio-group"
import { FigurePlacement } from "@/types"
import { VariableType } from "@/utils/variables"
import type React from "react"
import { useEffect, useState } from "react"

interface ExportSettingsProps {
	exportSettings: {
		headerFormat: string
		footerFormat: string
		middleFormat: string
		wordClaimChartTitleFormat: string
		wordIntroductionLanguage: string
		isCitationBefore: boolean
		usPatentCitationFormat: string
		usApplicationCitationFormat: string
		uploadedDocumentCitationFormat: string
		nonUsPatentCitationFormat: string
		figurePlacement: FigurePlacement
	}
	onFieldChange: (field: string, value: any) => void
}

const ExportSettings: React.FC<ExportSettingsProps> = ({
	exportSettings,
	onFieldChange,
}) => {
	// Maintain a local state for editing the settings.
	const [localSettings, setLocalSettings] = useState(exportSettings)

	// Sync local state if the exportSettings prop changes.
	useEffect(() => {
		setLocalSettings(exportSettings)
	}, [exportSettings])

	// Function to update the local settings state.
	const updateLocalSettings = (
		updates: Partial<ExportSettingsProps["exportSettings"]>,
	) => {
		setLocalSettings((prev) => ({ ...prev, ...updates }))
	}

	// Call the provided onFieldChange handler with the current value.
	const saveField = (field: keyof ExportSettingsProps["exportSettings"]) => {
		onFieldChange(field, localSettings[field])
	}

	return (
		<>
			{/* Invalidity cell boilerplate */}
			<SettingsSection
				label="Cell boilerplate"
				description="Language displayed as the header and footer of each cell in app and exports"
			>
				<div className="flex flex-col gap-4">
					<VariableInput
						label="Header"
						value={localSettings.headerFormat}
						onChange={(newVal: string) => updateLocalSettings({ headerFormat: newVal })}
						onBlur={() => saveField("headerFormat")}
						type={VariableType.cellBoilerplate}
						placeholder="e.g., {{limitation_label}}"
						multiline={true}
					/>
					<VariableInput
						label="Middle"
						value={localSettings.middleFormat}
						onChange={(newVal: string) => updateLocalSettings({ middleFormat: newVal })}
						onBlur={() => saveField("middleFormat")}
						type={VariableType.cellBoilerplate}
						placeholder="e.g., {{limitation_label}}"
						multiline={true}
					/>
					<VariableInput
						label="Footer"
						value={localSettings.footerFormat}
						onChange={(newVal: string) => updateLocalSettings({ footerFormat: newVal })}
						onBlur={() => saveField("footerFormat")}
						type={VariableType.cellBoilerplate}
						placeholder="e.g., {{limitation_label}}"
						multiline={true}
					/>
				</div>
			</SettingsSection>

			{/* Word invalidity claim chart title */}
			<SettingsSection
				label="Word invalidity claim chart title"
				description="Format for the title displayed at the top of the invalidity claim chart documents"
			>
				<div className="space-y-2">
					<VariableInput
						label=""
						value={localSettings.wordClaimChartTitleFormat}
						onChange={(newVal: string) =>
							updateLocalSettings({ wordClaimChartTitleFormat: newVal })
						}
						onBlur={() => saveField("wordClaimChartTitleFormat")}
						type={VariableType.title}
						placeholder={`e.g., Invalidity of {{subject_number}} ("{{subject_nickname}}") in view of {{document_number_1}}`}
						multiline={true}
					/>
				</div>
			</SettingsSection>

			{/* Word export introduction */}
			<SettingsSection
				label="Word export introduction"
				description="Language displayed at the beginning of the Word export above the claim charts"
			>
				<VariableInput
					label=""
					value={localSettings.wordIntroductionLanguage}
					onChange={(newVal: string) =>
						updateLocalSettings({ wordIntroductionLanguage: newVal })
					}
					onBlur={() => saveField("wordIntroductionLanguage")}
					type={VariableType.introduction}
					multiline={true}
				/>
			</SettingsSection>

			{/* Citation export settings */}
			<SettingsSection
				label="Citation export settings"
				description="Citation position and format in the exported charts"
			>
				<div className="space-y-4">
					<div>
						<Label className="text-sm">Position</Label>
						<RadioGroup
							value={localSettings.isCitationBefore ? "start" : "end"}
							onValueChange={(value: string) => {
								updateLocalSettings({ isCitationBefore: value === "start" })
								saveField("isCitationBefore")
							}}
							className="flex gap-4 mt-2"
						>
							<RadioGroupItemWithLabel value="start" id="start" label="Before language" />
							<RadioGroupItemWithLabel value="end" id="end" label="After language" />
						</RadioGroup>
					</div>

					<div className="grid grid-cols-2 gap-4">
						<VariableInput
							label="US Patent Format"
							value={localSettings.usPatentCitationFormat}
							onChange={(newVal: string) =>
								updateLocalSettings({ usPatentCitationFormat: newVal })
							}
							onBlur={() => saveField("usPatentCitationFormat")}
							type={VariableType.citation}
							placeholder="e.g., {{document_nickname}} at {{columns_lines}}"
							multiline={true}
						/>

						<VariableInput
							label="US Patent Application Format"
							value={localSettings.usApplicationCitationFormat}
							onChange={(newVal: string) =>
								updateLocalSettings({ usApplicationCitationFormat: newVal })
							}
							onBlur={() => saveField("usApplicationCitationFormat")}
							type={VariableType.citation}
							placeholder="e.g., {{document_nickname}} at [{{paragraphs}}]"
							multiline={true}
						/>

						<VariableInput
							label="Uploaded Document Format"
							value={localSettings.uploadedDocumentCitationFormat}
							onChange={(newVal: string) =>
								updateLocalSettings({ uploadedDocumentCitationFormat: newVal })
							}
							onBlur={() => saveField("uploadedDocumentCitationFormat")}
							type={VariableType.citation}
							placeholder="e.g., {{document_nickname}} at p. {{pages}}"
							multiline={true}
						/>

						<VariableInput
							label="Non-US Patent Format"
							value={localSettings.nonUsPatentCitationFormat}
							onChange={(newVal: string) =>
								updateLocalSettings({ nonUsPatentCitationFormat: newVal })
							}
							onBlur={() => saveField("nonUsPatentCitationFormat")}
							type={VariableType.citation}
							placeholder="e.g., {{document_nickname}} at [{{paragraphs}}]"
							multiline={true}
						/>
					</div>
				</div>
			</SettingsSection>

			{/* Figure export settings */}
			<SettingsSection
				label="Figure export settings"
				description="Figure position in the exported charts"
			>
				<div>
					<Label className="text-sm">Position</Label>
					<RadioGroup
						value={localSettings.figurePlacement}
						onValueChange={(value: string) => {
							updateLocalSettings({ figurePlacement: value as FigurePlacement })
							saveField("figurePlacement")
						}}
						className="flex gap-4"
					>
						<RadioGroupItemWithLabel
							value={FigurePlacement.GROUPED}
							id={FigurePlacement.GROUPED}
							label="Grouped for all element citations"
						/>
						<RadioGroupItemWithLabel
							value={FigurePlacement.EMBEDDED}
							id={FigurePlacement.EMBEDDED}
							label="Embedded with each citation"
						/>
						<RadioGroupItemWithLabel
							value={FigurePlacement.OMITTED}
							id={FigurePlacement.OMITTED}
							label="Omitted from export"
						/>
					</RadioGroup>
				</div>
			</SettingsSection>
		</>
	)
}

export default ExportSettings
