import { ClaimCitationComponent } from "@/features/charts/components/ClaimCitation"
import type { Claim } from "@/types"
import type { DocumentChunk } from "@/types"
import { CitationType } from "@/types/invalidity"
import type React from "react"
import { useChartsContext } from "../../context/ChartsContext"
import DocumentChunkCitation from "../DocumentChunkCitation"
import { InvalidityTableCitation } from "../table-mode/InvalidityTableCitation"

interface PotentialCitationProps {
	documentId: string
	type: CitationType
	data: DocumentChunk | Claim
	onAddCitation: (data: DocumentChunk | Claim) => void
	showFigures?: boolean
	showTimestamp?: boolean
	showVerify?: boolean
	showRemove?: boolean
	showFeedback?: boolean
	showColor?: boolean
	searchQuery?: string // highlight if KEYWORD
}

export const PotentialCitation: React.FC<PotentialCitationProps> = ({
	documentId,
	type,
	data,
	onAddCitation,
	showFigures = false,
	showVerify = true,
	showRemove = true,
	showFeedback = true,
	showColor = true,
	searchQuery,
}) => {
	const { activeLimitationId, activeLimitationInvalidityData } = useChartsContext()

	// Only treat it as a citation if it matches the ID and has removed === false
	const citation = activeLimitationId
		? (
				activeLimitationInvalidityData?.[activeLimitationId]?.citations[documentId] || []
			).find(
				(c: any) =>
					(type === "chunk"
						? c.documentChunkId === data.id
						: c.claimCitationId === data.id) && !c.removed,
			)
		: undefined

	if (citation) {
		return (
			<InvalidityTableCitation
				citation={citation}
				showColor={showColor}
				showRemove={showRemove}
				showVerify={showVerify}
				showFeedback={showFeedback}
			/>
		)
	}

	if (type === CitationType.CHUNK) {
		return (
			<DocumentChunkCitation
				chunk={data as DocumentChunk}
				showFigures={showFigures}
				activeLimitationId={activeLimitationId}
				onAddCitation={() => onAddCitation(data)}
				exactMatchText={searchQuery} // pass in for highlighting
			/>
		)
	}

	// else claim
	return (
		<ClaimCitationComponent
			citation={data as Claim}
			activeLimitationId={activeLimitationId}
			onAddCitation={() => onAddCitation(data)}
			exactMatchText={searchQuery} // pass in for highlighting
		/>
	)
}

export default PotentialCitation
