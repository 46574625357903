import { TableBody } from "@/components/ui/table"
import { useProjectContext } from "@/context/ProjectContext"
import { useProjectChartsMatrix } from "@/features/charts/hooks/queries/useProjectChartsMatrix"
import type React from "react"
import ProjectChartsMatrixRowComponent from "./ProjectChartsMatrixRow"

const ProjectChartsMatrixBody: React.FC<{ colWidthPercent: number }> = ({
	colWidthPercent,
}) => {
	const { projectId } = useProjectContext()
	const { projectChartsMatrix } = useProjectChartsMatrix(projectId)

	return (
		<TableBody>
			{projectChartsMatrix.map((row) => (
				<ProjectChartsMatrixRowComponent
					key={row.subject}
					row={row}
					colWidthPercent={colWidthPercent}
				/>
			))}
		</TableBody>
	)
}

export default ProjectChartsMatrixBody
