import { ClaimLimitationLabelPill } from "@/components/claims/ClaimLimitationLabelPill"
import GroupSelect from "@/components/claims/GroupSelect"
import { CondensedParagraph } from "@/components/ui/typography"
import useChartOptionsStore from "@/features/charts/hooks/chartOptionsStore"
import { useClaimInvalidityData } from "@/features/charts/hooks/queries/useClaimInvalidityData"
import { useDocumentNaming } from "@/hooks"
import { ChartMode, type ChunkToLimitation, type CollapsedLimitation } from "@/types"
import { parseHtml } from "@/utils"
import { mergeRefs } from "@/utils/mergeRefs"
import { formatChunkToLimitationCitation } from "@/utils/projectUtils"
import { useCallback, useMemo } from "react"
import type React from "react"
import { useInView } from "react-intersection-observer"
import { useParams } from "react-router-dom"
import { useChartsContext } from "../../context/ChartsContext"

interface ClaimInvalidityCardProps {
	limitation: CollapsedLimitation
	projectId: string
	documents: string[]
}

export function ClaimInvalidityCard({
	limitation,
	projectId,
	documents,
}: ClaimInvalidityCardProps) {
	// Pull all chart-related state and callbacks from ChartsContext
	const {
		activeLimitationId,
		updateActiveLimitation,
		scrollToParagraph,
		setActiveChunkId,
		setLastSeenLimitationId,
		isCombination,
		selectedChartMode,
		selectedClaimRef, // Bring in the ref via context
	} = useChartsContext()

	const { invalidityChartId, subjectId } = useParams()
	const { getDocumentName } = useDocumentNaming()

	// Chart options settings
	const { showCitationsInClaim, showCommentaryInClaim } = useChartOptionsStore()

	const groups = limitation.limitations[0].limitationGroups?.map((group) => group.group)

	// Observe whether this card is on-screen
	const { ref: inViewRef, inView } = useInView({
		rootMargin: "200px",
		triggerOnce: false,
		onChange: (isInView) => {
			// If we scrolled into view and it's not already the 'active' limitation, update lastSeenLimitationId.
			if (isInView && activeLimitationId !== limitation.id) {
				setLastSeenLimitationId(limitation.id)
			}
		},
	})

	const { limitationInvalidityData, isLoading } = useClaimInvalidityData(
		invalidityChartId,
		subjectId,
		limitation.id,
		{
			projectId,
			documents,
			colors: [],
			enabled: inView && showCitationsInClaim,
		},
	)

	const limitationData = useMemo(() => {
		return limitationInvalidityData?.[limitation.id]
	}, [limitationInvalidityData, limitation.id])

	const handleCitationClick = useCallback(
		(citation: ChunkToLimitation) => {
			if (!scrollToParagraph) return
			const chunkId = citation.documentChunk?.id || citation.claimCitation?.id
			scrollToParagraph(chunkId)
		},
		[scrollToParagraph],
	)

	const handleCitationPillClick = useCallback(
		(citation: ChunkToLimitation) => {
			return (e: React.MouseEvent<HTMLSpanElement>) => {
				e.stopPropagation()
				if (selectedChartMode === ChartMode.DOCUMENT) {
					updateActiveLimitation(limitation.id)
					setActiveChunkId(citation?.documentChunk?.id)
					handleCitationClick(citation)
				}
			}
		},
		[
			handleCitationClick,
			limitation.id,
			setActiveChunkId,
			updateActiveLimitation,
			selectedChartMode,
		],
	)

	// Determine if this claim card is currently active.
	const isSelected = activeLimitationId === limitation.id

	return (
		<div
			// If active, merge the inView observer ref with selectedClaimRef so scroll helpers work correctly.
			ref={isSelected ? mergeRefs(inViewRef, selectedClaimRef) : inViewRef}
			onClick={() => {
				if (!isSelected) {
					updateActiveLimitation(limitation.id)
				}
			}}
			className={`w-full px-4 py-3 mb-2 border rounded-lg cursor-pointer hover:bg-accent ${
				isSelected ? "border-blue-500 bg-accent" : ""
			}`}
		>
			<div className="flex flex-col">
				{groups && groups.length > 0 && <GroupSelect groups={groups} />}
				<CondensedParagraph>
					<ClaimLimitationLabelPill
						label={limitation.label}
						id={limitation.id}
						className="mr-2"
					/>
					{parseHtml(limitation.limitations.map((lim) => lim.html).join(" "))}
				</CondensedParagraph>

				{/* Only show commentary if enabled */}
				{showCommentaryInClaim && limitation.limitations.some((lim) => lim.commentary) && (
					<div className="mt-2 text-sm text-gray-600 italic">
						{parseHtml(limitation.limitations.map((lim) => lim.commentary).join(" "))}
					</div>
				)}
			</div>

			{/* Only show citations if enabled */}
			{showCitationsInClaim && (
				<>
					{isLoading && (
						<div className="text-sm text-gray-500 mt-2">Loading citations...</div>
					)}

					{!isLoading &&
						limitationData &&
						Object.entries(limitationData.citations).map(([docId, citations]) => (
							<div key={docId} className="mt-2 rounded-md p-2">
								{isCombination && (
									<div className="text-xs font-bold mb-2">{getDocumentName(docId)}</div>
								)}
								<div className="flex flex-wrap gap-2">
									{Array.isArray(citations) &&
										citations.map((citation, index) => {
											if (citation.removed) return null
											const citationColor = citation.color ?? "#ddd"
											const formattedCitation = formatChunkToLimitationCitation(citation)
											return (
												<span
													key={`${docId}-${citation?.id}-${index}`}
													className="px-2 py-1 text-xs rounded-full text-black cursor-pointer"
													style={{ backgroundColor: citationColor }}
													onClick={handleCitationPillClick(citation)}
												>
													{formattedCitation}
												</span>
											)
										})}
								</div>
							</div>
						))}
				</>
			)}
		</div>
	)
}
