/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
"use client"

import {
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
	useSidebar,
} from "@/components/ui/sidebar"
import { Spinner } from "@/components/ui/spinner"
import { useChatContext } from "@/context/ChatContext"
import { SHOW_AGENT_CHAT_FLAG } from "@/gating/gating"
import { useChat, useChatNotifications } from "@/hooks"
import { getMostRecentChat } from "@/utils/messageUtils"
import { ArrowLeft, Bot } from "lucide-react"
import { useNavigate } from "react-router-dom"
/**
 * @description App sidebar component
 */
export function ProjectSidebarFooter() {
	const { open } = useSidebar()
	const navigate = useNavigate()
	const { isAndyResponding, isPanelChatOpen, setIsPanelChatOpen, setActiveChatId } =
		useChatContext()
	const { unreadMessagesCount } = useChatNotifications()
	const { chatsMetadata } = useChat()

	const handleChatClick = () => {
		const mostRecentChat = getMostRecentChat(chatsMetadata)

		setIsPanelChatOpen(!isPanelChatOpen)
		if (!isPanelChatOpen) {
			setActiveChatId(mostRecentChat?.id ?? "new")
		} else {
			setActiveChatId(null)
		}
	}

	return (
		<SidebarMenu>
			<SidebarMenuItem>
				<SidebarMenuButton onClick={() => navigate("/home")} tooltip="Home">
					<ArrowLeft />
					<span>Back to Home</span>
				</SidebarMenuButton>
			</SidebarMenuItem>
			{SHOW_AGENT_CHAT_FLAG && (
				<SidebarMenuItem>
					<SidebarMenuButton
						onClick={handleChatClick}
						tooltip="Chat with Andy"
						data-state={isPanelChatOpen ? "active" : "inactive"}
						className={isPanelChatOpen ? "bg-accent" : ""}
						isActive={location.pathname.includes("/chat")}
					>
						{open &&
							(isAndyResponding ? (
								<span className="absolute right-3 flex h-5 w-5 items-center justify-center">
									<Spinner className="h-5 w-5" />
								</span>
							) : (
								unreadMessagesCount > 0 && (
									<span className="absolute right-3 flex h-5 w-5 items-center justify-center rounded-full bg-primary text-primary-foreground">
										{unreadMessagesCount}
									</span>
								)
							))}
						<Bot className="h-5 w-5" />
						<span>Chat with Andy</span>
					</SidebarMenuButton>
				</SidebarMenuItem>
			)}
		</SidebarMenu>
	)
}
