import { DocumentChunkComponent } from "@/components/documents/sections/body/DocumentChunk"
import useChartOptionsStore from "@/features/charts/hooks/chartOptionsStore"
import type { ChunkToLimitation } from "@/types"
import { BlockType, ChartMode } from "@/types"
import { useEffect, useRef } from "react"
import type React from "react"
import { useChartsContext } from "../../context/ChartsContext"
import ClaimComponent from "../ClaimComponent"
import { FigureComponent } from "../FigureComponent"
import InvalidityCitationActions from "./InvalidityCitationActions"
interface InvalidityTableCitationProps {
	citation: ChunkToLimitation
	showVerify?: boolean
	showRemove?: boolean
	showFeedback?: boolean
	showColor?: boolean
}

export const InvalidityTableCitation: React.FC<InvalidityTableCitationProps> = ({
	citation,
	showVerify = true,
	showRemove = true,
	showFeedback = true,
	showColor = true,
}) => {
	const { activeLimitationId, selectedChartMode } = useChartsContext()
	const containerRef = useRef<HTMLDivElement>(null)
	const { showFeedbackButtons, showReferencedFigures, showCitationReasons } =
		useChartOptionsStore()

	useEffect(() => {
		if (
			activeLimitationId === citation.claimLimitationId &&
			selectedChartMode === ChartMode.TABLE &&
			containerRef.current
		) {
			containerRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			})
		}
	}, [activeLimitationId, citation.claimLimitationId, selectedChartMode])

	const isFigure = citation.documentChunk?.type === BlockType.FIGURE

	return (
		<div ref={containerRef} className="my-4">
			<InvalidityCitationActions
				citation={citation}
				showColor={showColor}
				showRemove={showRemove}
				showVerify={showVerify}
				showFeedback={showFeedback && showFeedbackButtons && !isFigure}
			>
				{isFigure ? (
					<FigureComponent chunk={citation.documentChunk} />
				) : citation.documentChunk ? (
					<DocumentChunkComponent
						chunk={citation.documentChunk}
						showFigures={showReferencedFigures}
						reason={citation.reason}
						showCitationReasons={showCitationReasons}
					/>
				) : (
					<ClaimComponent
						claim={citation.claimCitation}
						reason={citation.reason}
						showCitationReasons={showCitationReasons}
					/>
				)}
			</InvalidityCitationActions>
		</div>
	)
}

export default InvalidityTableCitation
