import PatentMetadataCard from "@/components/documents/PatentMetadataCard"
import { CheckIndicatorType } from "@/components/documents/PatentMetadataLayout"
import { Button } from "@/components/ui/button"
import { useProjectContext } from "@/context/ProjectContext"
import { ANDY_LOCAL_UUID } from "@/gating/gating"
import type { ProjectDocumentMetadata } from "@/types"
import { useNavigate } from "react-router-dom"

interface ChatRelevantDocumentsListProps {
	documents: ProjectDocumentMetadata[]
	selectedDocuments: Record<string, boolean>
	onDocumentSelected: (documentId: string, selected: boolean) => void
	onApprove: () => Promise<void>
	isApproving: boolean
	bottomRef?: React.RefObject<HTMLDivElement>
}

export const ChatRelevantDocumentsList: React.FC<ChatRelevantDocumentsListProps> = ({
	documents,
	selectedDocuments,
	onDocumentSelected,
	onApprove,
	isApproving,
	bottomRef,
}) => {
	const { projectId } = useProjectContext()
	const navigate = useNavigate()

	const hasSelectedDocuments = Object.values(selectedDocuments).some((value) => value)
	const selectedCount = Object.values(selectedDocuments).filter(Boolean).length

	return (
		<div className="mt-2 space-y-4 transition-all">
			{documents.map((doc) => {
				const isAlreadyApproved = doc.createdBy && doc.createdBy !== ANDY_LOCAL_UUID
				return (
					<div key={doc.id} className="border rounded-lg p-3 bg-background shadow-sm">
						<PatentMetadataCard
							projectDocument={doc}
							showCheckbox={true}
							defaultChecked={isAlreadyApproved || selectedDocuments[doc.id] || false}
							onCheckedChange={(checked) => {
								if (!isAlreadyApproved) {
									onDocumentSelected(doc.id, checked)
								}
							}}
							checkIndicatorType={
								isAlreadyApproved ? CheckIndicatorType.BOOKMARK : CheckIndicatorType.CHECKBOX
							}
							onClick={() => {
								navigate(`/project/${projectId}/charts`)
							}}
						/>
					</div>
				)
			})}

			{hasSelectedDocuments && (
				<div className="flex justify-end mt-3">
					<Button
						onClick={onApprove}
						disabled={isApproving}
						className="bg-green-600 hover:bg-green-700 text-white"
					>
						{isApproving
							? "Approving..."
							: `Approve document${selectedCount !== 1 ? "s" : ""}`}
					</Button>
				</div>
			)}

			{bottomRef && <div ref={bottomRef} className="h-1" />}
		</div>
	)
}
