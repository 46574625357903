import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import { ExternalLinkIcon } from "@radix-ui/react-icons"
import type React from "react"

interface ExternalUrlDisplayProps {
	url: string
	label: string
	size?: "xs" | "sm" | "md"
}

const ExternalUrlDisplay: React.FC<ExternalUrlDisplayProps> = ({
	url,
	label,
	size = "sm",
}) => {
	return (
		<div
			className={cn(
				"flex items-center gap-1 bg-accent border border-border text-foreground-accent whitespace-nowrap px-1 py-0.75 pl-3 rounded-full h-10",
				size === "sm" && "h-7",
				size === "xs" && "h-6",
			)}
			onClick={(e) => {
				e.preventDefault()
				window.open(url, "_blank")
			}}
		>
			<span className={cn(size === "md" && "text-md", size === "xs" && "text-xs")}>
				{label}
			</span>
			<Button
				variant="ghost"
				size="icon"
				asChild
				className="text-foreground-accent hover:bg-primary-foreground/10 flex-shrink-0"
			>
				<a href={url} target="_blank" rel="noopener noreferrer">
					<ExternalLinkIcon className="h-4 w-4" />
				</a>
			</Button>
		</div>
	)
}

export default ExternalUrlDisplay
