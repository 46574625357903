import { DocumentBody, ErrorMessage, Loader } from "@/components"
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable"
import { ScrollArea } from "@/components/ui/scroll-area"
import { H4 } from "@/components/ui/typography"
import { useChatContext } from "@/context/ChatContext"
import { DocumentContextProvider } from "@/context/DocumentContext"
import { useProjectContext } from "@/context/ProjectContext"
import useDocumentFigures from "@/features/documents/hooks/useDocumentFigures"
import { useFullDocument } from "@/hooks"
import { cn } from "@/lib/utils"
import {
	type Claim,
	type CollapsedClaim,
	type DocumentChunk,
	DocumentRole,
	DocumentType,
} from "@/types"
import { sortClaims } from "@/utils/claimUtils"
import type React from "react"
import { useCallback, useMemo, useRef, useState } from "react"
import ClaimList from "../claims/ClaimList"
import { FigureViewer } from "../images/FigureViewer"
import PdfViewer from "../pdf/PdfDisplay"
import ProductMetadata from "./ProductMetadata"
import PatentAbstract from "./patents/PatentAbstract"
import PatentMetadata from "./patents/PatentMetadata"
import PatentNumberDisplay from "./patents/PatentNumberDisplay"
import DocumentDates from "./sections/DocumentDates"
import Figures from "./sections/Figures"
interface DocumentDetailLayoutProps {
	documentId: string
	isEditing?: boolean
	isPDFVisible?: boolean
	onTogglePDF?: () => void
	claims?: Claim[] | CollapsedClaim[]
	citationText?: string
	selectedDocumentChunkId?: string
	highlightedRef?: React.RefObject<HTMLDivElement | HTMLTableRowElement>
	inSidePanel?: boolean
}

const DocumentDetailLayout: React.FC<DocumentDetailLayoutProps> = ({
	documentId,
	isEditing = false,
	isPDFVisible = false,
	onTogglePDF = () => {},
	claims,
	citationText,
	selectedDocumentChunkId,
	highlightedRef,
	inSidePanel = false,
}) => {
	const { document, isLoading, isError } = useFullDocument(documentId)
	const { figureChunks, updateFigure } = useDocumentFigures(document?.id ?? "")
	const { documentViewerDocumentId } = useProjectContext()
	const { isPanelChatOpen } = useChatContext()

	const isPatent = useMemo(() => {
		return document?.type === DocumentType.PATENT
	}, [document?.type])

	const [leftPanelSize, setLeftPanelSize] = useState<number>(50)

	const [showFigureViewer, setShowFigureViewer] = useState<boolean>(false)
	const [selectedFigureChunk, setSelectedFigureChunk] = useState<DocumentChunk | null>(
		null,
	)

	const selectedChunkRef = useRef<HTMLDivElement>(null)

	const effectiveClaims = useMemo(() => {
		if (isPatent && claims) {
			return sortClaims(claims)
		}
		return sortClaims(document?.patent?.claims || [])
	}, [isPatent, claims, document?.patent?.claims])

	const documentType = document?.type
	const pdfUrl = useMemo(() => {
		return (
			(documentType === DocumentType.PATENT && document?.patent?.pdfUrl) ||
			document?.url ||
			undefined
		)
	}, [documentType, document, document?.patent?.pdfUrl])

	const isSubjectDocument = useMemo(() => {
		return document?.documentRole === DocumentRole.SUBJECT
	}, [document])

	const isClaimHighlighted = useMemo(() => {
		if (document?.type !== DocumentType.PATENT) {
			return false
		}
		return effectiveClaims?.some((claim) => {
			const claimText = Object.values(claim)[0] as string
			return claimText?.slice(0, 75) === citationText?.slice(0, 75)
		})
	}, [document, effectiveClaims, citationText])

	const [showChartableOnly, setShowChartableOnly] = useState(false)
	const toggleShowChartableOnly = useCallback(() => {
		setShowChartableOnly((prev) => !prev)
	}, [])

	if (!document) {
		return <Loader />
	}

	const handleFigureReferenceSelect = (figureChunk: DocumentChunk) => {
		setShowFigureViewer(true)
		setSelectedFigureChunk(figureChunk)
	}

	// --- Render helper functions ---
	const renderDocumentHeader = () => (
		<div className="flex justify-between w-full mt-2">
			{document.title ? <H4 className="mb-4 break-words">{document.title}</H4> : null}
			{document.patent?.number && <PatentNumberDisplay number={document.patent.number} />}
		</div>
	)
	const renderDocumentContent = (includeMetadata = false) => (
		<>
			{renderDocumentHeader()}
			{includeMetadata && renderMetadata()}
			{document.type === DocumentType.PATENT && (
				<PatentAbstract patent={document.patent} />
			)}
			<Figures documentId={document.id} showTitle={true} editMode={isEditing} />
			<DocumentBody
				document={document}
				body={document.body}
				figureChunks={figureChunks || []}
				citationText={citationText}
				editMode={isEditing}
				onFigureReferenceSelect={handleFigureReferenceSelect}
				selectedChunkRef={selectedChunkRef}
				showChartableOnly={showChartableOnly}
				onToggleChartableOnly={toggleShowChartableOnly}
			/>
		</>
	)

	const renderMetadata = () => (
		<div className="flex flex-col gap-2 mb-4">
			{document.patent && <PatentMetadata patent={document.patent} />}
			{document.product && <ProductMetadata product={document.product} />}
			<DocumentDates
				publicationDate={document.patent?.publicationDate || document.npl?.publicationDate}
				priorityDate={
					document?.priorityDate ?? document.patent?.originalPriorityDate ?? null
				}
				isEditing={isEditing}
				documentId={document.id}
			/>
		</div>
	)

	const renderClaims = () => (
		<>
			{document.type === DocumentType.PATENT && effectiveClaims?.length > 0 && (
				<div className="mb-12">
					<ClaimList
						claims={effectiveClaims}
						startCollapsed={false}
						editingBool={true}
						highlightedRef={
							isClaimHighlighted
								? (highlightedRef as React.RefObject<HTMLTableRowElement>)
								: undefined
						}
						patentId={document.id}
						isSubject={isSubjectDocument}
					/>
				</div>
			)}
		</>
	)

	const renderMetadataAndClaims = () => (
		<>
			{renderMetadata()}
			{renderClaims()}
		</>
	)

	const renderLeftPanel = () => (
		<ScrollArea className="px-3 h-full">{renderDocumentContent()}</ScrollArea>
	)

	const renderRightPanel = () => (
		<div>
			{showFigureViewer ? (
				<FigureViewer
					selectedFigure={selectedFigureChunk}
					documentId={document.id}
					figureChunks={figureChunks}
					updateFigureChunk={updateFigure}
					onHide={() => setShowFigureViewer(false)}
				/>
			) : isPDFVisible ? (
				<PdfViewer
					documentId={document.id}
					url={pdfUrl}
					documentChunks={document.body}
					onHidePdf={onTogglePDF}
					documentType={documentType}
				/>
			) : (
				<ScrollArea className="px-3 mt-2 h-full">{renderMetadataAndClaims()}</ScrollArea>
			)}
		</div>
	)

	const renderSinglePanel = () => (
		<ScrollArea className="px-3 h-full">
			{renderDocumentContent(true)}
			<div className="mb-4">{renderClaims()}</div>
		</ScrollArea>
	)

	if (isLoading) {
		return <Loader />
	}

	if (isError) {
		return <ErrorMessage />
	}

	// --- Force single panel mode if in side panel ---
	if (inSidePanel || documentViewerDocumentId || isPanelChatOpen) {
		return (
			<DocumentContextProvider
				documentIds={[document.id]}
				defaultSelectedChunkId={selectedDocumentChunkId}
			>
				<div className="flex flex-col h-full w-full">
					{isPDFVisible ? (
						<PdfViewer
							documentId={document.id}
							url={pdfUrl}
							documentChunks={document.body}
							onHidePdf={onTogglePDF}
							documentType={documentType}
						/>
					) : (
						renderSinglePanel()
					)}
				</div>
			</DocumentContextProvider>
		)
	}

	// --- Final return ---
	return (
		<DocumentContextProvider
			documentIds={[document.id]}
			defaultSelectedChunkId={selectedDocumentChunkId}
		>
			<div className={cn("hidden lg:block overflow-hidden")}>
				<ResizablePanelGroup direction="horizontal" className="h-full">
					<ResizablePanel defaultSize={leftPanelSize} onResize={setLeftPanelSize}>
						{isPDFVisible ? renderSinglePanel() : renderLeftPanel()}
					</ResizablePanel>

					<ResizableHandle withHandle />

					<ResizablePanel defaultSize={100 - leftPanelSize}>
						{renderRightPanel()}
					</ResizablePanel>
				</ResizablePanelGroup>
			</div>

			{/* Small screen layout */}
			<div className="lg:hidden overflow-hidden w-full">
				{isPDFVisible ? (
					<PdfViewer
						documentId={document.id}
						url={pdfUrl}
						documentChunks={document.body}
						onHidePdf={onTogglePDF}
						documentType={documentType}
					/>
				) : (
					<>{renderSinglePanel()}</>
				)}
			</div>
		</DocumentContextProvider>
	)
}

export default DocumentDetailLayout
