import type React from "react"
import { createContext, useContext, useState } from "react"
import { PanelChatProvider } from "./ChatContext"

export interface ProjectContextType {
	projectId: string
	subjectId: string | null // can be null if not available
	documentViewerDocumentId: string | null
	setDocumentViewerDocumentId: (documentId: string) => void
	documentViewerChunkId: string | null
	setDocumentViewerChunkId: (chunkId: string) => void
	documentViewerCitationText: string | null
	setDocumentViewerCitationText: (citationText: string) => void
}

const ProjectContext = createContext<ProjectContextType | undefined>(undefined)

export function useProjectContext(): ProjectContextType {
	const context = useContext(ProjectContext)
	if (!context) {
		throw new Error("useProjectContext must be used within a ProjectProvider")
	}
	return context
}

interface ProjectProviderProps {
	projectId: string
	subjectId?: string | null
	children: React.ReactNode
}

export function ProjectProvider({
	projectId,
	subjectId = null,
	children,
}: ProjectProviderProps) {
	if (projectId === null) {
		throw new Error("projectId must be provided")
	}

	const [documentViewerDocumentId, setDocumentViewerDocumentId] = useState<
		string | null
	>(null)
	const [documentViewerChunkId, setDocumentViewerChunkId] = useState<string | null>(null)
	const [documentViewerCitationText, setDocumentViewerCitationText] = useState<
		string | null
	>(null)

	return (
		<ProjectContext.Provider
			value={{
				projectId,
				subjectId,
				documentViewerDocumentId,
				setDocumentViewerDocumentId,
				documentViewerChunkId,
				setDocumentViewerChunkId,
				documentViewerCitationText,
				setDocumentViewerCitationText,
			}}
		>
			<PanelChatProvider>{children}</PanelChatProvider>
		</ProjectContext.Provider>
	)
}
