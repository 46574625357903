/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { api } from "@/api"
import { useApi } from "@/hooks"
import { useAppStateStore } from "@/store"
import type { Organization } from "@/types"
import {
	DEFAULT_QUERY_OPTIONS,
	MUTATION_KEYS,
	QUERY_KEYS,
	invalidateSuperAdminQueries,
} from "@/utils/query"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

/**
 * useSuperAdminOrganizations is a hook that fetches super admin organizations and
 * provides mutations for creating, updating, and deleting organizations.
 *
 * @returns An object with the super admin organizations data, as well as loading flags.
 */
const useSuperAdminOrganizations = () => {
	const { handleError } = useApi()
	const { addErrorMessage, addSuccessMessage } = useAppStateStore()
	const queryClient = useQueryClient()

	// Queries
	const superAdminOrganizations = useQuery({
		queryKey: QUERY_KEYS.superAdmin.organizations,
		queryFn: () => api.getSuperAdminOrganizations(),
		...DEFAULT_QUERY_OPTIONS,
	})

	// Organization Mutations
	const createOrganizationMutation = useMutation({
		mutationKey: MUTATION_KEYS.organization.create(),
		mutationFn: ({ org }: { org: Organization }) => api.createOrganization(org),
		onSuccess: () => {
			addSuccessMessage("Organization created successfully")
			invalidateSuperAdminQueries(queryClient)
		},
		onError: (error) => {
			addErrorMessage("Error creating organization")
			handleError(error, "Error creating organization")
		},
	})

	const updateOrganizationMutation = useMutation({
		mutationKey: MUTATION_KEYS.organization.update(),
		mutationFn: ({
			organizationId,
			newOrg,
		}: {
			organizationId: string
			newOrg: Organization
		}) => api.updateOrganization(organizationId, newOrg),
		onSuccess: (_, variables) => {
			addSuccessMessage("Organization updated successfully")
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.superAdmin.organizationDetails(variables.organizationId),
			})
		},
		onError: (error, variables) => {
			addErrorMessage("Error updating organization")
			handleError(error, `Error updating organization: ${variables.organizationId}`)
		},
	})

	// Organization Mutations
	const deleteOrganizationMutation = useMutation({
		mutationKey: MUTATION_KEYS.organization.delete(),
		mutationFn: ({ organizationId }: { organizationId: string }) =>
			api.deleteOrganization(organizationId, ""),
		onSuccess: () => {
			invalidateSuperAdminQueries(queryClient)
		},
		onError: (error) => handleError(error, "Error deleting organization"),
	})

	return {
		// Query data
		organizations: superAdminOrganizations.data,
		isLoading: superAdminOrganizations.isLoading,
		isFetching: superAdminOrganizations.isFetching,

		// Organization mutations
		createOrganization: createOrganizationMutation.mutate,
		updateOrganization: updateOrganizationMutation.mutate,
		deleteOrganization: deleteOrganizationMutation.mutate,

		isDeletePending: deleteOrganizationMutation.isPending,
		isUpdatePending: updateOrganizationMutation.isPending,
		isCreatePending: createOrganizationMutation.isPending,
	}
}

export { useSuperAdminOrganizations }
