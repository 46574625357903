/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import type { components } from "schema"
import type { KeysToCamelCase } from "./utils"

export enum RoleType {
	ADMIN = "Admin",
	OWNER = "Owner",
	MEMBER = "Member",
}

export type User = KeysToCamelCase<components["schemas"]["UserDetails"]>
export type UserMetadata = KeysToCamelCase<components["schemas"]["UserMetadata"]>
export type Organization = KeysToCamelCase<components["schemas"]["Organization"]>

export type AdminOverview = KeysToCamelCase<components["schemas"]["AdminOverview"]>

export type OrganizationPlan = KeysToCamelCase<
	components["schemas"]["OrganizationPlan"]
>
export type OrganizationSummary = KeysToCamelCase<
	components["schemas"]["OrganizationSummary"]
>

export type AdminProjectSummary = KeysToCamelCase<
	components["schemas"]["AdminProjectSummary"]
>

export type AdminDocumentSummary = KeysToCamelCase<
	components["schemas"]["AdminDocumentSummary"]
>
export type AdminUserUsageByDay = KeysToCamelCase<
	components["schemas"]["AdminUserUsageByDay"]
>

// Billing
export type BillingCycleSummary = KeysToCamelCase<
	components["schemas"]["BillingCycleSummary"]
>
export type StripeInvoice = KeysToCamelCase<components["schemas"]["StripeInvoice"]>
export type StripeInvoiceLine = KeysToCamelCase<
	components["schemas"]["StripeInvoiceLine"]
>

export interface AdminBillingData {
	invoices: StripeInvoice[]
	plan: OrganizationPlan
	cycleSummary: BillingCycleSummary
}

export type AdminUsage = {
	projects: AdminProjectSummary[]
	documents: AdminDocumentSummary[]
}

export interface AdminBaseChartData {
	count: number
}

export interface AdminChartDataDate extends AdminBaseChartData {
	date: string
	breakdown: Record<string, number>
}

// Interface for user grouping
export interface AdminChartDataUser extends AdminBaseChartData {
	user: string
	breakdown?: Record<string, number>
}

// New interface for organization grouping
export interface AdminChartDataOrganization extends AdminBaseChartData {
	organization: string
	breakdown?: Record<string, number>
}

export interface AdminChartDataPortfolioProject {
	date: string
	user: string
	organization: string
	portfolioCount: number
	projectCount: number
	portfolioBreakdown: Record<string, number>
	projectBreakdown: Record<string, number>
}

// Union type for all chart data types
export type AdminChartData =
	| AdminChartDataDate
	| AdminChartDataUser
	| AdminChartDataOrganization
