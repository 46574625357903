import { cn } from "@/lib/utils"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { ThumbsUpIcon } from "lucide-react"
import type React from "react"

interface RecommendedPillProps {
	content: string | React.ReactNode
	className?: string
}

/**
 * @description Info pill component that renders a static box
 *
 * @param {object} content - The content of the pill
 */
const RecommendedPill: React.FC<RecommendedPillProps> = ({ content, className }) => {
	return (
		<div
			className={cn(
				className,
				"flex flex-row gap-[8px] border !border-gray-300 items-center px-[8px] py-[5px] rounded-[8px]",
			)}
		>
			<ThumbsUpIcon className="h-4 w-4 text-blue-500" />
			<span className="text-sm text-muted-foreground">
				{typeof content === "string" ? content : content}
			</span>
		</div>
	)
}

export default RecommendedPill
