import type { AdminOverview } from "@/types"
import { Building2Icon, DollarSign, File, Folder, Users } from "lucide-react"
import AdminDetailCard from "../components/AdminDetailCard"

/**
 * @description Overview detail cards for superadmin and admin pages
 */
function OverviewCards({
	overview,
	isSuperAdmin,
}: { overview: AdminOverview; isSuperAdmin: boolean }) {
	return (
		<div className="mt-2 flex flex-row gap-4">
			{isSuperAdmin && (
				<>
					<AdminDetailCard
						title="Total Balance"
						value={overview?.totalBalance.toString()}
						icon={<DollarSign className="h-4 w-4" />}
					/>

					<AdminDetailCard
						title="Active Organizations"
						value={overview?.totalOrganizations.toString()}
						icon={<Building2Icon className="h-4 w-4" />}
					/>
				</>
			)}
			<AdminDetailCard
				title="Active Users"
				value={overview?.totalUsers.toString()}
				icon={<Users className="h-4 w-4" />}
			/>
			<AdminDetailCard
				title="Projects Created"
				value={overview?.totalProjects.toString()}
				icon={<Folder className="h-4 w-4" />}
			/>
			<AdminDetailCard
				title="Documents Processed"
				value={overview?.totalDocumentsAdded.toString()}
				icon={<File className="h-4 w-4" />}
			/>
		</div>
	)
}

export default OverviewCards
