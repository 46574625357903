/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { AlertTriangle } from "lucide-react"
import type React from "react"
import styled from "styled-components"

interface WarningPillProps {
	content: string | React.ReactNode
	className?: string
}

const WarningBox = styled.div`
	display: inline-flex;
	align-items: center;
	gap: 8px;
	padding: 5px 8px;
	border-radius: 8px;
	border: 1px solid #d1d5db;
`

/**
 * @description Info pill component that renders a static box
 *
 * @param {object} content - The content of the pill
 */
const WarningPill: React.FC<WarningPillProps> = ({ content, className }) => {
	return (
		<WarningBox className={className}>
			<AlertTriangle className="h-4 w-4 text-yellow-500" />
			<span className="text-sm text-muted-foreground">
				{typeof content === "string" ? content : content}
			</span>
		</WarningBox>
	)
}

export default WarningPill
