/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, ProjectPage } from "@/components"
import { useChatContext } from "@/context/ChatContext"
import { useProjectContext } from "@/context/ProjectContext"
import { ChatRow } from "@/features/chat/components/PanelChatRow"
import { useAllProjectDocuments } from "@/features/documents/hooks/useAllProjectDocuments"
import { useChat } from "@/hooks"
import { PageName } from "@/types/pages"
import { ChevronLeft, ChevronRight } from "lucide-react"
import type React from "react"
import { ChatThread } from "./ChatThread"

const ChatPage: React.FC = () => {
	const { projectId } = useProjectContext()
	const { setActiveChatId, isChatListCollapsed, setIsChatListCollapsed } =
		useChatContext()
	const { chatsMetadata, chatsMetadataLoading } = useChat()
	const { allDocumentsMetadata, isLoading } = useAllProjectDocuments()

	const queriesLoading = chatsMetadataLoading

	return (
		<ProjectPage pageName={PageName.Chat}>
			{queriesLoading ? (
				<div className="flex justify-center items-center flex-col mt-12">
					<Loader message="Loading..." />
				</div>
			) : (
				<div className="flex h-full">
					{isChatListCollapsed ? (
						<div
							className="w-6 border-r relative hover:bg-gray-100 cursor-pointer group"
							onClick={() => setIsChatListCollapsed(false)}
						>
							{/* More visible expand button when collapsed */}
							<div className="absolute inset-0 flex items-center justify-center">
								<button
									type="button"
									className="h-4 w-4 flex items-center justify-center focus:outline-none z-10"
									aria-label="Expand chat list"
								>
									<ChevronRight className="h-8 w-8" />
								</button>
							</div>
						</div>
					) : (
						<>
							{/* Chat rows container */}
							<div className="w-1/4 max-w-[360px] min-w-[160px] overflow-y-auto">
								<div className="space-y-2 mt-2" onScroll={(e) => e.stopPropagation()}>
									{chatsMetadata && chatsMetadata.length > 0 ? (
										chatsMetadata.map((chat) => (
											<ChatRow
												key={chat.id}
												chatMetadata={chat}
												onClick={(e) => {
													e.stopPropagation()
													setActiveChatId(chat.id)
												}}
											/>
										))
									) : (
										<div className="flex flex-col items-center justify-center h-30 p-6 mx-4 mt-4 rounded-lg ">
											<p className="text-sm font-medium text-muted-foreground">No chats yet</p>
											<p className="text-xs text-muted-foreground/70 mt-1 text-center">
												Start a new conversation below
											</p>
										</div>
									)}
								</div>
							</div>

							{/* Wider hover area for the divider */}
							<div className="relative">
								{/* Actual divider */}
								<div className="w-1 border-r h-full" />

								{/* Invisible wider hover area */}
								<div
									className="absolute top-0 left-[-12px] w-36 h-full group cursor-pointer"
									onClick={() => setIsChatListCollapsed(true)}
								>
									{/* Collapse button that only appears on hover */}
									<button
										type="button"
										className="absolute top-1/2 left-5 -translate-y-1/2 h-4 w-4 flex items-center justify-center focus:outline-none z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-75"
										aria-label="Collapse chat list"
									>
										<ChevronLeft className="h-8 w-8" />
									</button>
								</div>
							</div>
						</>
					)}

					{/* Right Column: Active Chat Screen */}
					<div className="flex-1 flex flex-col">
						<ChatThread onClose={() => {}} />
					</div>
				</div>
			)}
		</ProjectPage>
	)
}

export default ChatPage
