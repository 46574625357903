import type { ProjectDocumentMetadata } from "@/types"
import { CheckIndicatorType, PatentMetadataLayout } from "./PatentMetadataLayout"

interface PatentMetadataCardProps {
	projectDocument: ProjectDocumentMetadata
	updateSelectedKindCode?: (documentId: string, kindCode: string) => void
	showCheckbox?: boolean
	defaultChecked?: boolean
	onCheckedChange?: (checked: boolean) => void
	checkIndicatorType?: CheckIndicatorType
	onClick?: () => void
}

/**
 * A wrapper component that displays ProjectDocumentMetadata using PatentMetadataLayout
 *
 * @param {PatentMetadataCardProps} props - The properties for the component.
 */
const PatentMetadataCard: React.FC<PatentMetadataCardProps> = ({
	projectDocument,
	updateSelectedKindCode = () => {},
	showCheckbox = false,
	defaultChecked = false,
	onCheckedChange = () => {},
	checkIndicatorType = CheckIndicatorType.CHECKBOX,
	onClick,
}) => {
	// Safety check that we have patent data
	if (!projectDocument.patent) {
		return <></>
	}

	const patentData = projectDocument.patent

	return (
		<div
			className={`flex items-start gap-4 ${onClick ? "cursor-pointer" : ""}`}
			onClick={(e) => {
				// Only trigger onClick if the click wasn't on a button or interactive element
				if (
					onClick &&
					e.target instanceof Element &&
					!e.target.closest("button") &&
					!e.target.closest("a") &&
					!e.target.closest('input[type="checkbox"]')
				) {
					e.stopPropagation()
					onClick()
				}
			}}
		>
			<PatentMetadataLayout
				title={projectDocument.title || ""}
				number={patentData.number}
				prefix={patentData.prefix}
				priorityDate={patentData.originalPriorityDate}
				publicationDate={patentData.publicationDate}
				inventors={patentData.inventors}
				assignee={patentData.assignee}
				documentId={projectDocument.id}
				onKindCodeChange={() => {}}
				showCheckbox={showCheckbox}
				defaultChecked={defaultChecked}
				onCheckedChange={onCheckedChange}
				checkIndicatorType={checkIndicatorType}
			/>
		</div>
	)
}

export default PatentMetadataCard
