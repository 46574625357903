import { Loader } from "@/components"
import DocumentNamePill from "@/components/documents/DocumentNamePill"
import DocumentSearchInput from "@/components/search/DocumentSearchInput"
import { useDocumentContext } from "@/context/DocumentContext"
import { useChunkToLimitationUpdates } from "@/features/charts/hooks/mutations/useChunkToLimitationUpdates"
import useDocumentFigures from "@/features/documents/hooks/useDocumentFigures"
import { useFullDocument } from "@/hooks"
import { BlockType, ChartColor, type DocumentChunk } from "@/types"
import { SearchMode, SearchOrder } from "@/types"
import type { ClaimCitation } from "@/types/invalidity"
import { CitationType } from "@/types/invalidity"
import type React from "react"
import { useMemo } from "react"
import { useChartsContext } from "../../context/ChartsContext"
import PotentialCitation from "./PotentialCitation"

interface DocumentPanelProps {
	documentId: string
}

const DocumentPanel: React.FC<DocumentPanelProps> = ({ documentId }) => {
	const {
		activeChunkId,
		selectedDocumentChunkRef,
		selectedDocumentSection,
		activeLimitationId,
		activeLimitationInvalidityData,
		isCombination,
		selectedGroupId,
		groupsToLimitationIds,
	} = useChartsContext()

	const { document, isLoading: isDocLoading } = useFullDocument(documentId)
	const { figureChunks } = useDocumentFigures(documentId)

	/*
	|---------------------------------------------------------------------
	| Document Search (Centralized state)
	|---------------------------------------------------------------------
	*/
	const {
		searchQuery,
		searchResultIds,
		searchLoading,
		searchError,
		searchMode,
		searchOrderBy,
	} = useDocumentContext()

	/*
	|---------------------------------------------------------------------
	| Filtered Document Chunks (Updated)
	|---------------------------------------------------------------------
	*/
	const filteredDocChunks = useMemo(() => {
		const chunks = (document?.body ?? []).filter((chunk: DocumentChunk) => {
			if (!chunk) return false
			if (
				chunk.type === BlockType.CAPTION ||
				chunk.type === BlockType.FIGURE ||
				chunk.type === BlockType.UNKNOWN
			) {
				return false
			}

			if (chunk.text.trim() === "") return false

			// Only filter by docSearchResultIds if it exists and is non-empty.
			if (!searchResultIds || searchResultIds.size === 0) return true
			return searchResultIds.has(chunk.id)
		})

		return chunks
	}, [document, searchResultIds])

	/*
	|---------------------------------------------------------------------
	| Claims
	|---------------------------------------------------------------------
	*/
	const claims = useMemo(() => document?.patent?.claims ?? [], [document])

	/*
	|---------------------------------------------------------------------
	| Filtered Claims
	|---------------------------------------------------------------------
	*/
	const filteredClaims = useMemo(() => {
		return claims.filter((c: ClaimCitation) => {
			if (!searchResultIds || searchResultIds.size === 0) return true
			return searchResultIds.has(c.id)
		})
	}, [claims, searchResultIds])

	/*
	|---------------------------------------------------------------------
	| Citation Update Logic
	|---------------------------------------------------------------------
	*/
	const { addCitation, updateCitation } = useChunkToLimitationUpdates()

	const handleAddChunkCitation = async (chunk: DocumentChunk) => {
		if (!activeLimitationId) return

		const existingCitations =
			activeLimitationInvalidityData?.[activeLimitationId]?.[documentId] || []

		const existingCitation = existingCitations.find(
			(c: any) => c.documentChunkId === chunk.id,
		)

		if (existingCitation?.removed) {
			await updateCitation({
				claimLimitationIds: selectedGroupId
					? groupsToLimitationIds[selectedGroupId]
					: [activeLimitationId],
				documentChunkId: existingCitation.documentChunkId,
				claimCitationId: existingCitation.claimCitationId,
				options: { removed: false, color: ChartColor.GREEN },
			})
		} else if (!existingCitation) {
			await addCitation({
				documentChunkId: chunk.id,
				claimLimitationIds: selectedGroupId
					? groupsToLimitationIds[selectedGroupId]
					: [activeLimitationId],
				documentId: documentId,
				color: ChartColor.GREEN,
			})
		}
	}

	const handleAddClaimCitation = async (claim: ClaimCitation) => {
		if (!activeLimitationId) return

		const existingCitations =
			activeLimitationInvalidityData?.[activeLimitationId]?.[documentId] || []

		const existingCitation = existingCitations.find(
			(c: any) => c.claimCitationId === claim.id,
		)

		if (existingCitation?.removed) {
			await updateCitation({
				claimLimitationIds: selectedGroupId
					? groupsToLimitationIds[selectedGroupId]
					: [activeLimitationId],
				documentChunkId: existingCitation.documentChunkId,
				claimCitationId: existingCitation.claimCitationId,
				options: { removed: false, color: ChartColor.GREEN },
			})
		} else if (!existingCitation) {
			await addCitation({
				claimCitationId: claim.id,
				documentId: documentId,
				claimLimitationIds: selectedGroupId
					? groupsToLimitationIds[selectedGroupId]
					: [activeLimitationId],
				color: ChartColor.GREEN,
			})
		}
	}

	/*
	|---------------------------------------------------------------------
	| Combined Document Items
	|---------------------------------------------------------------------
	*/
	const combinedDocItems = useMemo(() => {
		// Map filtered document chunks (body) to a unified item structure.
		const bodyItems = filteredDocChunks.map((chunk) => ({
			id: chunk.id,
			type: "body" as const,
			data: chunk,
		}))

		// Map filtered claims similarly.
		const claimItems = filteredClaims.map((claim) => ({
			id: claim.id,
			type: "claim" as const,
			data: claim,
		}))

		// Combine both arrays.
		const combined = [...claimItems, ...bodyItems]

		// For semantic search, sort based on docSearchResultIds order
		if (
			searchMode === SearchMode.SEMANTIC &&
			searchOrderBy === SearchOrder.SCORE &&
			searchResultIds
		) {
			const indexMap = new Map(Array.from(searchResultIds).map((id, index) => [id, index]))
			combined.sort((a, b) => {
				const indexA = indexMap.get(a.id) ?? Number.POSITIVE_INFINITY
				const indexB = indexMap.get(b.id) ?? Number.POSITIVE_INFINITY
				return indexA - indexB
			})
		}

		return combined
	}, [filteredDocChunks, filteredClaims, searchMode, searchOrderBy, searchResultIds])

	/*
	|---------------------------------------------------------------------
	| Render: Body / Figures / Claims
	|---------------------------------------------------------------------
	*/
	const renderBodySection = () => {
		if (filteredDocChunks.length === 0) {
			return <p className="text-center text-muted">No results found</p>
		}

		return filteredDocChunks.map((chunk: DocumentChunk) => (
			<div
				key={chunk.id}
				className="mb-2"
				ref={chunk.id === activeChunkId ? selectedDocumentChunkRef : undefined}
			>
				<PotentialCitation
					documentId={documentId}
					type={CitationType.CHUNK}
					data={chunk}
					onAddCitation={handleAddChunkCitation}
					showFigures={false}
					searchQuery={searchMode === SearchMode.KEYWORD ? searchQuery : ""}
				/>
			</div>
		))
	}

	const renderFiguresSection = () => {
		if (figureChunks.length === 0) {
			return <p className="text-center text-muted">No figures found</p>
		}

		return figureChunks.map((figure: DocumentChunk) => (
			<div
				key={figure.id}
				className="mb-2"
				ref={figure.id === activeChunkId ? selectedDocumentChunkRef : undefined}
			>
				<PotentialCitation
					documentId={documentId}
					type={CitationType.CHUNK}
					data={figure}
					onAddCitation={handleAddChunkCitation}
					showFigures={true}
					searchQuery={searchQuery}
				/>
			</div>
		))
	}

	const renderClaimsSection = () => {
		if (filteredClaims.length === 0) {
			return <p className="text-center text-muted">No claims found</p>
		}

		return filteredClaims.map((claim: ClaimCitation) => (
			<div
				key={claim.id}
				className="mb-2"
				ref={claim.id === activeChunkId ? selectedDocumentChunkRef : undefined}
			>
				<PotentialCitation
					documentId={documentId}
					type={CitationType.CLAIM}
					data={claim}
					onAddCitation={handleAddClaimCitation}
					searchQuery={searchQuery}
				/>
			</div>
		))
	}

	const renderCombinedDocItems = () => {
		if (combinedDocItems.length === 0) {
			return <p className="text-center text-muted">No results found</p>
		}

		return combinedDocItems.map((item) => {
			return (
				<div
					key={item.id}
					className="mb-2"
					ref={item.id === activeChunkId ? selectedDocumentChunkRef : undefined}
				>
					<PotentialCitation
						documentId={documentId}
						type={item.type === "body" ? CitationType.CHUNK : CitationType.CLAIM}
						data={item.data}
						onAddCitation={
							item.type === "body" ? handleAddChunkCitation : handleAddClaimCitation
						}
						showFigures={false}
						searchQuery={searchQuery}
					/>
				</div>
			)
		})
	}

	/*
	|---------------------------------------------------------------------
	| Render: Main content (based on selectedDocumentSection)
	|---------------------------------------------------------------------
	|
	*/
	const renderSection = () => {
		// If there's a non-empty search query, render the combined document items
		if (searchQuery?.trim()) {
			return <div className="px-4">{renderCombinedDocItems()}</div>
		}

		// Otherwise, select which section to render based on selectedDocumentSection.
		switch (selectedDocumentSection) {
			case "body":
				return <div className="px-4">{renderBodySection()}</div>
			case "figures":
				return <div className="px-4">{renderFiguresSection()}</div>
			case "claims":
				return <div className="px-4">{renderClaimsSection()}</div>
			default:
				return (
					<div className="px-4 space-y-4">
						{renderClaimsSection()}
						{renderBodySection()}
						{renderFiguresSection()}
					</div>
				)
		}
	}

	/*
	|---------------------------------------------------------------------
	| Render: Search Bar (header)
	|---------------------------------------------------------------------
	*/
	const renderSearchBar = () => (
		<div className="sticky top-0 bg-background border-b h-12 flex items-center px-3 gap-2 z-10 w-full">
			{isCombination && <DocumentNamePill doc={{ id: documentId, name: "" }} size="sm" />}
			<div className="flex-1">
				<DocumentSearchInput showStatusInComponent={false} />
			</div>
		</div>
	)

	/*
	|---------------------------------------------------------------------
	| Final Return (Updated)
	|---------------------------------------------------------------------
	*/
	if (isDocLoading) {
		return (
			<div className="flex justify-center items-center h-full">
				<Loader />
			</div>
		)
	}

	return (
		<div className="flex flex-col h-full">
			{renderSearchBar()}
			<div className="flex-1 overflow-auto">
				{/* Document content below the fixed header */}
				{searchQuery && !searchLoading && searchResultIds && (
					<div className="mt-1 text-sm text-muted">
						{searchResultIds.size} result{searchResultIds.size !== 1 ? "s" : ""} found
					</div>
				)}
				{searchLoading && <Loader message="Searching..." />}
				{searchError && (
					<span className="mt-1 text-sm text-red-500">Error searching document</span>
				)}
				{renderSection()}
			</div>
		</div>
	)
}

export default DocumentPanel
