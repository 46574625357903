import {
	type DocumentChunk,
	type FormattedInvalidityAnalysis,
	type Generate102AnalysisRequest,
	type Generate103AnalysisRequest,
	type Generate112AnalysisRequest,
	type GenerateInvalidityAnalysisResponse,
	type GeneratePriorArtSummariesRequest,
	type Get102AnalysisRequest,
	type Get103AnalysisRequest,
	type Get112AnalysisRequest,
	type GetPitchSummaryExportRequest,
	type GetPitchSummaryExportResponse,
	InvalidityType,
	type UpdateContentionAnalysisRequest,
} from "@/types"
import { apiClient } from "./client"

export const coverApi = {
	async getCoverSummaries(subjectId: string) {
		const payload = {
			subject_id: subjectId,
		}
		return await apiClient.get("get_cover_summaries", payload)
	},

	async updateCoverSummaries(
		subjectId: string,
		documentId: string,
		documentCover: string,
	) {
		const payload = {
			subject_id: subjectId,
			document_id: documentId,
			document_cover: documentCover,
		}
		return await apiClient.post("post_update_cover_summaries", payload)
	},

	async getCoverExport(
		subjectId: string,
		selectedDocumentIds: string[],
		selectedAnalysisTypes: InvalidityType[],
	): Promise<Map<string, string>> {
		const payload = {
			subject_id: subjectId,
			selected_document_ids: selectedDocumentIds,
			selected_analysis_types: selectedAnalysisTypes,
		}

		return await apiClient.get("get_cover_export", payload)
	},

	async getPitchSummaryExport(
		projectId: string,
		plaintiffName: string,
		defendantName: string,
		defendantProduct: string,
		bragStatementPersonal: string,
		bragStatementFirm: string,
		closingStatement: string,
	): Promise<GetPitchSummaryExportResponse> {
		const payload: GetPitchSummaryExportRequest = {
			project_id: projectId,
			plaintiff_name: plaintiffName,
			defendant_name: defendantName,
			defendant_product: defendantProduct,
			brag_statement_personal: bragStatementPersonal,
			brag_statement_firm: bragStatementFirm,
			closing_statement: closingStatement,
		}

		return await apiClient.get("get_pitch_summary_export", payload)
	},

	async getDocumentChunksByIds(documentChunkIds: string[]): Promise<DocumentChunk[]> {
		const payload = {
			document_chunk_ids: documentChunkIds,
		}
		return await apiClient.get("get_document_chunks_by_ids", payload)
	},

	generatePriorArtSummaries: async (subjectId: string, documentIds: string[]) => {
		const payload: GeneratePriorArtSummariesRequest = {
			document_ids: documentIds,
			subject_id: subjectId,
		}
		const response = await apiClient.post("post_generate_prior_art_summaries", payload)
		return response
	},

	generate102Analysis: async (
		subjectId: string,
		chartIds: string[],
	): Promise<GenerateInvalidityAnalysisResponse> => {
		const payload: Generate102AnalysisRequest = {
			subject_id: subjectId,
			chart_ids: chartIds,
		}
		const response: GenerateInvalidityAnalysisResponse = await apiClient.post(
			"post_generate_102_analysis",
			payload,
		)
		return response
	},

	generate103Analysis: async (
		subjectId: string,
		chartIds: string[],
	): Promise<GenerateInvalidityAnalysisResponse> => {
		const payload: Generate103AnalysisRequest = {
			subject_id: subjectId,
			chart_ids: chartIds,
		}
		const response: GenerateInvalidityAnalysisResponse = await apiClient.post(
			"post_generate_103_analysis",
			payload,
		)
		return response
	},

	generate112Analysis: async (
		subjectId: string,
	): Promise<GenerateInvalidityAnalysisResponse> => {
		const payload: Generate112AnalysisRequest = {
			subject_id: subjectId,
		}
		const response: GenerateInvalidityAnalysisResponse = await apiClient.post(
			"post_generate_112_analysis",
			payload,
		)
		return response
	},

	get102Analysis: async (subjectId: string): Promise<FormattedInvalidityAnalysis[]> => {
		const payload: Get102AnalysisRequest = {
			subject_id: subjectId,
		}
		return await apiClient.get("get_102_analysis", payload)
	},

	get103Analysis: async (subjectId: string): Promise<FormattedInvalidityAnalysis[]> => {
		const payload: Get103AnalysisRequest = {
			subject_id: subjectId,
		}
		return await apiClient.get("get_103_analysis", payload)
	},

	get112Analysis: async (subjectId: string): Promise<FormattedInvalidityAnalysis[]> => {
		const payload: Get112AnalysisRequest = {
			subject_id: subjectId,
		}
		return await apiClient.get("get_112_analysis", payload)
	},

	update102Analysis: async (chartId: string, analysis: string) => {
		const payload: UpdateContentionAnalysisRequest = {
			contention_type: InvalidityType.ANTICIPATION,
			chart_id: chartId,
			analysis: analysis,
		}
		return await apiClient.post("post_update_contention_analysis", payload)
	},

	update103Analysis: async (chartId: string, analysis: string) => {
		const payload: UpdateContentionAnalysisRequest = {
			contention_type: InvalidityType.OBVIOUSNESS,
			chart_id: chartId,
			analysis: analysis,
		}
		return await apiClient.post("post_update_contention_analysis", payload)
	},

	update112Analysis: async (chartId: string, analysis: string) => {
		const payload: UpdateContentionAnalysisRequest = {
			contention_type: InvalidityType.SPECIFICATION,
			chart_id: chartId,
			analysis: analysis,
		}
		return await apiClient.post("post_update_contention_analysis", payload)
	},
}
