import { CaretDownIcon } from "@radix-ui/react-icons"

interface DocumentToggleButtonProps {
	isLoading: boolean
	showDocuments: boolean
	hasDocuments: boolean
	onToggle: () => void
}

export const DocumentToggleButton: React.FC<DocumentToggleButtonProps> = ({
	isLoading,
	showDocuments,
	hasDocuments,
	onToggle,
}) => {
	return (
		<button
			type="button"
			className={`flex items-center gap-2 px-3 py-1.5 text-sm rounded border ${
				isLoading ? "opacity-70 cursor-wait" : "hover:bg-gray-100"
			}`}
			onClick={onToggle}
			disabled={isLoading}
		>
			{isLoading ? (
				<>
					<div className="w-4 h-4 border-2 border-gray-400 border-t-transparent rounded-full animate-spin" />
					<span>Loading...</span>
				</>
			) : (
				<>
					<span>{showDocuments ? "Hide Documents" : "Show Documents"}</span>
					<CaretDownIcon
						className={`w-4 h-4 transition-transform ${showDocuments ? "rotate-180" : ""}`}
					/>
				</>
			)}
		</button>
	)
}
