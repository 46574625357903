/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import {} from "@/components/ui/breadcrumb"
import { Button } from "@/components/ui/button"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { H4 } from "@/components/ui/typography"
import {
	AdminPageLayout,
	BillingDetailsCard,
	InvoicesTable,
	UsersTable,
} from "@/features/admin/components"
import { DeleteOrganizationModal } from "@/features/admin/superadmin/components"
import { PageName } from "@/types/pages"
import { useState } from "react"
import { useParams } from "react-router-dom"
import AdminUsageCard from "../components/AdminUsageCard"
import OverviewCards from "../components/OverviewCards"
import { useAdminBilling } from "../hooks/useAdminBilling"
import { useAdminOverview } from "../hooks/useAdminOverview"
import { useAdminUsers } from "../hooks/useAdminUsers"
import OrganizationDetails from "./components/OrganizationDetails"
import { useOrganizationDetails } from "./hooks/useOrganizationDetails"
import { useSuperAdminOrganizations } from "./hooks/useSuperAdminOrganizations"
/**
 * @description Super admin page component
 */
function SuperAdminOrganizationDetailsPage() {
	const { organizationId } = useParams()
	const { adminOverview, isLoading: isLoadingOverview } =
		useAdminOverview(organizationId)

	const { organizationDetails } = useOrganizationDetails(organizationId)
	const { updateOrganization } = useSuperAdminOrganizations()
	const { adminUsers, isLoading: isLoadingUsers } = useAdminUsers(organizationId)
	const { adminBilling, isLoading: isLoadingBilling } = useAdminBilling(organizationId)

	const [showRemoveOrgModal, setShowRemoveOrgModal] = useState(false)
	const [activeTab, setActiveTab] = useState("overview")

	return (
		<AdminPageLayout isSuperAdmin={true} page={PageName.SuperAdminOverview}>
			<div className="space-y-8 mt-2">
				{isLoadingOverview || isLoadingUsers || isLoadingBilling ? (
					<Loader />
				) : (
					<Tabs
						defaultValue="overview"
						value={activeTab}
						onValueChange={setActiveTab}
						className="w-full"
					>
						<div className="space-y-4">
							<div className="flex justify-between">
								<div className="flex items-center gap-4">
									<H4 className="mb-0 p-0">{organizationDetails?.name}</H4>
								</div>

								<TabsList className="grid w-[500px] grid-cols-4">
									<TabsTrigger value="overview">Overview</TabsTrigger>
									<TabsTrigger value="details">Details</TabsTrigger>
									<TabsTrigger value="users">Users</TabsTrigger>
									<TabsTrigger value="billing">Billing</TabsTrigger>
								</TabsList>
								<div className="flex gap-2">
									<Button
										variant="destructive"
										onClick={() => setShowRemoveOrgModal(true)}
										disabled={isLoadingOverview}
									>
										Delete
									</Button>
								</div>
							</div>

							<TabsContent value="overview" className="mt-6">
								<div className="flex flex-col gap-4">
									<OverviewCards overview={adminOverview} isSuperAdmin={false} />
									<AdminUsageCard isSuperAdmin={false} />
								</div>
							</TabsContent>

							<TabsContent value="details" className="mt-6">
								<OrganizationDetails
									organization={organizationDetails}
									onSave={(organizationId, newOrg) =>
										updateOrganization({ organizationId, newOrg })
									}
								/>
							</TabsContent>

							<TabsContent value="users" className="mt-6">
								{adminUsers && (
									<UsersTable orgName={""} orgId={organizationId} users={adminUsers} />
								)}
							</TabsContent>

							<TabsContent value="billing" className="mt-6">
								<div className="flex gap-3">
									<BillingDetailsCard overview={adminOverview} />
								</div>
								{adminBilling?.invoices?.length > 0 && (
									<InvoicesTable invoices={adminBilling.invoices} />
								)}
							</TabsContent>
						</div>
					</Tabs>
				)}

				<DeleteOrganizationModal
					open={showRemoveOrgModal}
					onClose={() => setShowRemoveOrgModal(false)}
					orgId={organizationId}
					orgName={""}
				/>
			</div>
		</AdminPageLayout>
	)
}

export default SuperAdminOrganizationDetailsPage
