import { Button } from "@/components/ui/button"
import { useChatContext } from "@/context/ChatContext"
import { useChat } from "@/hooks"
import { Pause } from "lucide-react"

export const ChatFooterLoadTab = () => {
	const { thinkingString } = useChat()
	const { isAndyResponding, setIsAndyResponding } = useChatContext()
	return isAndyResponding ? (
		<div className="flex items-center justify-between mb-0 mx-2">
			<div className="bg-[#7fb1f9] text-xs py-1 px-2 rounded-t-md flex items-center gap-1 w-full border border-b-0">
				<div className="flex items-center justify-between w-full">
					<div className="flex items-center gap-1">
						<span className="text-black font-medium">
							{thinkingString}
							<span className="inline-flex ml-0.5">
								<span
									className="animate-bounce"
									style={{ animationDuration: "1s", animationDelay: "0s" }}
								>
									.
								</span>
								<span
									className="animate-bounce"
									style={{ animationDuration: "1s", animationDelay: "0.2s" }}
								>
									.
								</span>
								<span
									className="animate-bounce"
									style={{ animationDuration: "1s", animationDelay: "0.4s" }}
								>
									.
								</span>
							</span>
						</span>
					</div>
					<Button
						variant="ghost"
						size="sm"
						className="h-5 px-2 py-0 text-xs text-black hover:text-[#03253a]"
						onClick={() => {}}
					>
						<Pause className="h-4 w-4" /> Pause
					</Button>
				</div>
			</div>
		</div>
	) : null
}
