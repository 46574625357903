import { DocumentNamePill } from "@/components/documents/DocumentNamePill"
import { DataTable } from "@/components/table/DataTable"
import { TableTags } from "@/components/table/TableTags"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { ClickableCell } from "@/components/ui/table"
import TooltipButton from "@/components/ui/tooltip-button"
import { useDocumentNaming } from "@/hooks"
import type { InvalidityChart } from "@/types/invalidity"
import type { ColumnDef } from "@tanstack/react-table"
import { ArchiveIcon, DownloadIcon, MoreVertical } from "lucide-react"
import { DateTime } from "luxon"
import type React from "react"
import {
	type ChangeEvent,
	type KeyboardEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react"
import { useInvalidityChartUpdates } from "../hooks/mutations/useInvalidityChartUpdates"

interface ProjectChartsTableProps {
	projectCharts: InvalidityChart[]
	height?: string
	onNewChart: () => void
	selectChart?: (chartId: string, claimLimitationId?: string) => void
	isArchived?: boolean
	onShowArchivedCharts?: () => void
	onShowExportModal?: () => void
}

/**
 * A refactored ProjectChartsTable which aligns in style/behavior with DocumentMetadataTable:
 * - Bulk row selection with an action bar for archiving or adding tags.
 * - Row-level dropdown for rename, edit notes, tagging, archive.
 * - Editable "Name" and "Notes" in-line.
 * - Filters to narrow charts by tags or by documents.
 */
const ProjectChartsTable: React.FC<ProjectChartsTableProps> = ({
	projectCharts,
	// height,
	onNewChart,
	selectChart,
	isArchived = false,
	onShowArchivedCharts,
	onShowExportModal,
}) => {
	const { updateInvalidityChart } = useInvalidityChartUpdates()
	const { getDocumentName } = useDocumentNaming()
	// Track editing state for name, notes, etc.
	const [editingNameId, setEditingNameId] = useState<string>("")
	const [editingNotesId, setEditingNotesId] = useState<string>("")
	const [newName, setNewName] = useState<string>("")
	const [newNotes, setNewNotes] = useState<string>("")
	// For top-level tag addition
	const [allTags, setAllTags] = useState<string[]>([])

	// Build table data
	const tableData: InvalidityChart[] = useMemo(
		() => (projectCharts ? projectCharts : []),
		[projectCharts],
	)

	useEffect(() => {
		const tags = new Set<string>()
		// Loop through all charts, checking for an array of tags
		for (const chart of tableData) {
			if (Array.isArray(chart.tags)) {
				for (const tag of chart.tags) {
					tags.add(tag)
				}
			}
		}
		setAllTags(Array.from(tags))
	}, [tableData])

	// Build a filters object.
	const filters = useMemo(
		() => ({
			tags: {
				label: "Tags",
				values: allTags.map((tag) => ({ label: tag, value: tag })),
			},
			// The column for document IDs uses the accessorKey "documentIds".
			documentIds: {
				label: "Documents",
				values: Array.from(new Set(tableData.flatMap((chart) => chart.documentIds))).map(
					(docId) => ({ label: getDocumentName(docId), value: docId }),
				),
			},

			// The column for document IDs uses the accessorKey "documentIds".
			subjectId: {
				label: "Subject",
				values: Array.from(new Set(tableData.flatMap((chart) => chart.subjectId))).map(
					(subjectId) => ({ label: getDocumentName(subjectId), value: subjectId }),
				),
			},
		}),
		[allTags, tableData, getDocumentName],
	)

	/**
	 * -----------------------------
	 * Single-item update handlers
	 * -----------------------------
	 */
	const handleSaveName = async (chart: InvalidityChart, name: string) => {
		await updateInvalidityChart({
			chartId: chart.id,
			options: {
				name: name,
			},
		})
		setEditingNameId("")
		setNewName("")
	}

	const handleSaveNotes = async (chart: InvalidityChart, notes: string) => {
		await updateInvalidityChart({
			chartId: chart.id,
			options: {
				notes: notes,
			},
		})
		setEditingNotesId("")
		setNewNotes("")
	}

	const handleArchive = async (chart: InvalidityChart) => {
		await updateInvalidityChart({
			chartId: chart.id,
			options: {
				active: false,
			},
		})
	}

	const handleUnarchive = async (chart: InvalidityChart) => {
		await updateInvalidityChart({
			chartId: chart.id,
			options: {
				active: true,
			},
		})
	}

	/**
	 * -----------------------------
	 * Bulk selection and updates
	 * -----------------------------
	 */

	const handleTagUpdate = useCallback(
		async (
			selectedRows: InvalidityChart[],
			_addTags: string[] | null,
			setTags: string[] | null,
		) => {
			if (!setTags || setTags.length === 0) return
			try {
				if (setTags) {
					const chartIds = selectedRows.map((row) => row.id)
					await updateInvalidityChart({
						chartId: chartIds[0],
						options: {
							tags: setTags,
						},
					})
				}
			} catch (_error) {
				console.error("Failed to update tags")
			}
		},
		[updateInvalidityChart],
	)

	const handleRowClick = useCallback(
		(chart: InvalidityChart) => {
			if (selectChart) {
				selectChart(chart.id)
			}
		},
		[selectChart],
	)

	/**
	 * -----------------------------
	 * Table column definitions
	 * -----------------------------
	 */
	// const checkboxColumn: ColumnDef<InvalidityChart> = {
	// 	id: "select",
	// 	header: ({ table }) => (
	// 		<Checkbox
	// 			checked={table.getIsAllPageRowsSelected()}
	// 			onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
	// 			aria-label="Select all"
	// 		/>
	// 	),
	// 	cell: ({ row }) => (
	// 		<Checkbox
	// 			checked={row.getIsSelected()}
	// 			onCheckedChange={(value) => row.toggleSelected(!!value)}
	// 			aria-label="Select row"
	// 		/>
	// 	),
	// 	enableSorting: false,
	// 	enableHiding: false,
	// }

	const columns: ColumnDef<InvalidityChart>[] = []

	// if (!isArchived) {
	// 	columns.push(checkboxColumn)
	// }

	columns.push({
		id: "name",
		accessorKey: "name",
		header: "Name",
		cell: ({ row }) => {
			const chart = row.original
			const isEditing = editingNameId === chart.id
			return (
				<div className="w-full min-w-[6rem]">
					{isEditing ? (
						<Input
							value={newName}
							autoFocus
							onChange={(e: ChangeEvent<HTMLInputElement>) => setNewName(e.target.value)}
							onBlur={() => handleSaveName(chart, newName)}
							onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
								if (e.key === "Enter") handleSaveName(chart, newName)
							}}
						/>
					) : (
						<ClickableCell onClick={() => handleRowClick(chart)}>
							<span
								className="cursor-pointer"
								onClick={(e) => {
									e.stopPropagation()
								}}
								onDoubleClick={() => {
									setEditingNameId(chart.id)
									setNewName(chart.name)
								}}
							>
								{chart.name || "—"}
							</span>
						</ClickableCell>
					)}
				</div>
			)
		},
	})

	if (!isArchived) {
		columns.push({
			id: "subjectId",
			accessorKey: "subjectId",
			header: "Subject",
			cell: ({ row }) => {
				const chart = row.original
				return (
					<div className="flex flex-wrap gap-2">
						<DocumentNamePill
							key={chart.subjectId}
							doc={{ id: chart.subjectId, name: "" }}
							size="sm"
						/>
					</div>
				)
			},
		})

		columns.push({
			id: "documentIds",
			accessorKey: "documentIds",
			header: "Documents",
			cell: ({ row }) => {
				const chart = row.original
				return (
					<div className="flex flex-wrap gap-2">
						{chart.documentIds.map((documentId) => (
							<DocumentNamePill
								key={documentId}
								doc={{ id: documentId, name: "" }}
								size="sm"
							/>
						))}
					</div>
				)
			},
		})
	}

	columns.push({
		id: "tags",
		accessorKey: "tags",
		header: "Tags",
		cell: ({ row }) => {
			const chart = row.original
			const chartTags = chart.tags || []
			if (isArchived) {
				// Render static tags when archived
				return (
					<div className="w-full h-full">
						{chartTags.length > 0 ? (
							<div className="flex flex-wrap gap-1">
								{chartTags.map((tag) => (
									<Badge variant="outline" key={tag} className="whitespace-nowrap">
										{tag}
									</Badge>
								))}
							</div>
						) : (
							<div className="w-full items-center justify-center">—</div>
						)}
					</div>
				)
			}
			// Allow editing when not archived
			return (
				<TableTags
					tagOptions={allTags}
					selectedTags={chartTags}
					onApply={(newTags) => {
						handleTagUpdate([chart], null, newTags)
					}}
				>
					<div className="w-full h-full">
						{chartTags.length > 0 ? (
							<div className="flex flex-wrap gap-1">
								{chartTags.map((tag) => (
									<Badge variant="outline" key={tag} className="whitespace-nowrap">
										{tag}
									</Badge>
								))}
							</div>
						) : (
							<div className="w-full items-center justify-center">—</div>
						)}
					</div>
				</TableTags>
			)
		},
	})

	columns.push(
		{
			id: "createdAt",
			accessorKey: "createdAt",
			header: "Created",
			enableSorting: true,
			cell: ({ row }) => {
				const chart = row.original
				return chart.createdAt
					? DateTime.fromISO(chart.createdAt).toFormat("MM/dd/yyyy")
					: "—"
			},
		},
		{
			id: "updatedAt",
			accessorKey: "updatedAt",
			header: "Last Updated",
			cell: ({ row }) => {
				const chart = row.original
				return chart.updatedAt
					? DateTime.fromISO(chart.updatedAt).toFormat("MM/dd/yyyy")
					: "—"
			},
		},
		{
			id: "notes",
			accessorKey: "notes",
			header: "Notes",
			cell: ({ row }) => {
				const chart = row.original
				if (isArchived) {
					// Render a simple non-clickable notes view when archived
					return (
						<div className="w-full min-w-[6rem]">
							<span>{chart.notes || "—"}</span>
						</div>
					)
				}
				const isEditing = editingNotesId === chart.id
				return (
					<div className="w-full min-w-[6rem]">
						{isEditing ? (
							<Input
								value={newNotes}
								autoFocus
								onChange={(e: ChangeEvent<HTMLInputElement>) => setNewNotes(e.target.value)}
								onBlur={() => handleSaveNotes(chart, newNotes)}
								onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
									if (e.key === "Enter") handleSaveNotes(chart, newNotes)
								}}
							/>
						) : (
							<ClickableCell onClick={() => handleRowClick(chart)}>
								<span
									className="cursor-pointer"
									onClick={(e) => {
										e.stopPropagation()
									}}
									onDoubleClick={() => {
										setEditingNotesId(chart.id)
										setNewNotes(chart.notes || "")
									}}
								>
									{chart.notes || "—"}
								</span>
							</ClickableCell>
						)}
					</div>
				)
			},
		},
	)

	if (!isArchived) {
		columns.push({
			id: "actions",
			header: "",
			cell: ({ row }) => {
				const chart = row.original
				return (
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button
								variant="ghost"
								className="h-8 w-8 p-0 clickable-element"
								onClick={(e) => e.stopPropagation()}
							>
								<MoreVertical className="h-4 w-4" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="end">
							<DropdownMenuItem
								onClick={(e) => {
									e.stopPropagation()
									setEditingNameId(chart.id)
									setNewName(chart.name)
								}}
							>
								Rename
							</DropdownMenuItem>
							<DropdownMenuItem
								onClick={(e) => {
									e.stopPropagation()
									setEditingNotesId(chart.id)
									setNewNotes(chart.notes || "")
								}}
							>
								Edit notes
							</DropdownMenuItem>
							<DropdownMenuItem
								onClick={(e) => {
									e.stopPropagation()
									handleArchive(chart)
								}}
							>
								Archive
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				)
			},
		})
	} else {
		columns.push({
			id: "active",
			header: "",
			cell: ({ row }) => {
				const chart = row.original
				return (
					<Button
						variant="default"
						onClick={(e) => {
							e.stopPropagation()
							handleUnarchive(chart)
						}}
						size="sm"
					>
						Unarchive
					</Button>
				)
			},
		})
	}

	return (
		<DataTable
			columns={columns}
			data={tableData}
			height={"calc(100vh - 300px)"}
			showActions={!isArchived}
			enableRowDelete={false}
			showTag={true}
			onTagApply={handleTagUpdate}
			onRowClick={handleRowClick}
			initialFilters={filters}
		>
			{onShowArchivedCharts && (
				<TooltipButton
					tooltipText="View Archive"
					size="smIcon"
					variant="outline"
					onClick={onShowArchivedCharts}
					icon={<ArchiveIcon className="h-4 w-4" />}
				/>
			)}
			{onShowExportModal && (
				<TooltipButton
					tooltipText="Export Charts"
					size="smIcon"
					variant="outline"
					onClick={onShowExportModal}
					icon={<DownloadIcon className="h-4 w-4" />}
				/>
			)}

			<Button size="sm" onClick={onNewChart}>
				+ New Chart
			</Button>
		</DataTable>
	)
}

export default ProjectChartsTable
