/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { OpenInGooglePatentButton } from "@/components"
import { DataTable } from "@/components/table/DataTable"
import { TableTags } from "@/components/table/TableTags"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { Spinner } from "@/components/ui/spinner"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { useProjectContext } from "@/context/ProjectContext"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import useSharing from "@/hooks/useSharing"
import { useAreProcessesPending } from "@/store"
import {
	DocumentType,
	type ProjectDocumentMetadata,
	conflictingReferenceProcesses,
} from "@/types"
import { formatPatentNumber } from "@/utils/projectUtils"
import type { ColumnDef, Row } from "@tanstack/react-table"
import he from "he"
import { MoreVertical } from "lucide-react"
import { DateTime } from "luxon"
import type React from "react"
import { type ChangeEvent, type KeyboardEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

type CustomColumnDef<TData, TValue = unknown> = ColumnDef<TData, TValue> & {
	hidden?: boolean
}

type FilterOption = { label: string; value: string }
type Filters = {
	type?: { label: string; values: FilterOption[] }
	prefix?: { label: string; values: FilterOption[] }
	tags?: { label: string; values: FilterOption[] }
}

interface DocumentMetadataTableProps {
	documents: ProjectDocumentMetadata[]
	onDeleteRow?: (document: ProjectDocumentMetadata) => void
	onRowSelection?: (document: ProjectDocumentMetadata[]) => void

	height?: string
	children?: React.ReactNode
	enableRowDelete?: boolean
	isDeleteSelectedLoading?: boolean
	onReprocessSelected?: (
		references: ProjectDocumentMetadata[],
		isReprocess: boolean,
	) => void
	onDismissSelected?: (documents: ProjectDocumentMetadata[]) => void
	onDeleteSelected?: (selectedDocuments: ProjectDocumentMetadata[]) => void
	onSaveName?: (
		document: ProjectDocumentMetadata,
		newName: string,
		isNickname: boolean,
	) => void
	onSaveNote?: (document: ProjectDocumentMetadata, newNote: string) => void
	onTagUpdate?: (
		selectedRows: ProjectDocumentMetadata[],
		addTags: string[],
		deleteTags: string[],
	) => void
	onSelectProject?: (projectId: string) => void
	onRowClick?: (document: ProjectDocumentMetadata) => void
	isReference?: boolean
	isSearch?: boolean
	title?: string
	isProcessing?: boolean
}

const DocumentMetadataTable: React.FC<DocumentMetadataTableProps> = ({
	documents,
	onDeleteRow,
	onRowSelection,
	height,
	children,
	enableRowDelete,
	onDeleteSelected,
	isSearch,
	isDeleteSelectedLoading = false,
	onReprocessSelected,
	onDismissSelected,
	onTagUpdate,
	onSelectProject,
	onSaveName,
	onSaveNote,
	onRowClick,
	isReference = false,
	title,
	isProcessing,
}) => {
	const { getDocumentName } = useDocumentNaming()
	const { getUserEmailFromId } = useSharing()
	const navigate = useNavigate()
	const { projectId } = useProjectContext()
	const checkboxStart = !isSearch
	const enableRowActions = !isSearch
	const showTableActions = true
	const enableRename = !isSearch
	const showReferenceEditCols = isReference && !isSearch
	const showName = !isSearch

	const handleRowSelection = (selectedRows: ProjectDocumentMetadata[]) => {
		if (onRowSelection) {
			onRowSelection(selectedRows)
		}
	}

	const isReferenceDisabled = useAreProcessesPending(
		conflictingReferenceProcesses,
		projectId || null,
	)

	const [editingNoteRefId, setEditingNoteRefId] = useState<string>("")
	const [editingNameRefId, setEditingNameRefId] = useState<string>("")
	const [noteContent, setNoteContent] = useState<string>("")
	const [newName, setNewName] = useState<string>("")

	const handleRemoveRow = (reference: ProjectDocumentMetadata) => {
		if (reference && onDeleteRow) {
			onDeleteRow(reference)
		}
	}

	const handleEditNotes = (document: ProjectDocumentMetadata) => {
		if (document) {
			setEditingNoteRefId(document.id)
			setNoteContent(document.notes || "")
		}
	}

	const handleSaveNote = async (document: ProjectDocumentMetadata, newNote: string) => {
		await onSaveNote?.(document, newNote)
		setEditingNoteRefId("")
		setNoteContent("")
	}

	const handleSaveName = async (
		document: ProjectDocumentMetadata,
		newName: string,
		isNickname: boolean,
	) => {
		await onSaveName?.(document, newName, isNickname)
		setEditingNameRefId("")
		setNewName("")
	}

	const handleViewDetails = async (reference: ProjectDocumentMetadata) => {
		navigate(`/project/${projectId}/references/${reference.id}`)
	}

	const [allTags, setAllTags] = useState<string[]>([])

	useEffect(() => {
		const tags = new Set<string>()
		for (const ref of documents) {
			if (Array.isArray(ref.tags)) {
				for (const tag of ref.tags) {
					tags.add(tag)
				}
			}
		}
		setAllTags(Array.from(tags))
	}, [documents])

	const handleEditName = (document: ProjectDocumentMetadata) => {
		setEditingNameRefId(document.id)
		setNewName(document.nickname || getDocumentName(document.id)) // Initialize with existing nickname
	}

	// Sort functions for title and name
	const _multiLingualSort = (
		rowA: Row<ProjectDocumentMetadata>,
		rowB: Row<ProjectDocumentMetadata>,
		columnId: string,
	) => {
		const collator = new Intl.Collator(["ko", "ja", "zh", "en"], {
			sensitivity: "base",
			caseFirst: "false",
		})

		if (columnId === "title") {
			const valueA = (rowA.original?.title || "") as string
			const valueB = (rowB.original?.title || "") as string
			return collator.compare(valueA, valueB)
		}

		const valueA = rowA.getValue(columnId) as string
		const valueB = rowB.getValue(columnId) as string
		return collator.compare(valueA, valueB)
	}

	const nicknameSort = (
		rowA: Row<ProjectDocumentMetadata>,
		rowB: Row<ProjectDocumentMetadata>,
	) => {
		const collator = new Intl.Collator(["ko", "ja", "zh", "en"], {
			sensitivity: "base",
			caseFirst: "false",
		})

		const valueA = getDocumentName(rowA.original.id)
		const valueB = getDocumentName(rowB.original.id)

		return collator.compare(valueA, valueB)
	}

	const checkboxColumn = {
		id: "select",
		header: ({ table }) => (
			<Checkbox
				checked={table.getIsAllPageRowsSelected()}
				onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
				aria-label="Select all"
			/>
		),
		cell: ({ row }) => (
			<Checkbox
				checked={row.getIsSelected()}
				onCheckedChange={(value) => row.toggleSelected(!!value)}
				aria-label="Select row"
			/>
		),
		enableSorting: false,
		enableHiding: false,
	}

	// const statusTooltipContent = (
	// 	<>
	// 		<p className="text-sm text-muted-foreground">
	// 			<b>Processed</b>: No action needed.
	// 		</p>
	// 		<p className="text-sm text-muted-foreground">
	// 			<b>Reprocess</b>: Click "Resolve Statuses" to reprocess with updated algorithms.
	// 		</p>
	// 		<p className="text-sm text-muted-foreground">
	// 			<b>Rechart</b>: Click "Resolve Statuses" to rechart with updated algorithms or
	// 			context.
	// 		</p>
	// 		<p className="text-sm text-muted-foreground">
	// 			<b>Reupload</b>: Delete and reupload the document.
	// 		</p>
	// 	</>
	// )

	const [editingTitleRefId, setEditingTitleRefId] = useState<string>("")
	const [titleContent, setTitleContent] = useState<string>("")

	const _handleEditTitle = (document: ProjectDocumentMetadata) => {
		if (document) {
			setEditingTitleRefId(document.id)
			setTitleContent(he.decode(document.title || ""))
		}
	}

	const handleSaveTitle = async (
		document: ProjectDocumentMetadata,
		newTitle: string,
	) => {
		await onSaveName?.(document, newTitle, false)
		setEditingTitleRefId("")
		setTitleContent("")
	}

	// Build up the columns
	const columns: CustomColumnDef<ProjectDocumentMetadata>[] = [
		...(enableRowActions && isReference && checkboxStart ? [checkboxColumn] : []),

		// The new "status" column that uses aggregated logic:
		...(showReferenceEditCols
			? [
					{
						id: "status",
						accessorKey: "status",
						header: () => (
							<div className="flex items-center gap-2">
								Status
								{/* <InfoPopover content={statusTooltipContent} /> */}
							</div>
						),
						cell: ({ row }) => {
							const document = row.original
							// Grab the aggregated or single-project "status"

							// We'll unify color/text based on the aggregated outcome:
							const statusColor = "bg-gray-500"
							const statusText = "—"
							const tooltipText = ""
							const showSpinner = false

							// const { aggregated, singleProjectStatus } = getDisplayStatus(projectId, document)
							// const effectiveStatus = projectId ? singleProjectStatus : aggregated
							// switch (effectiveStatus) {
							// 	case StatusType.SUCCESS:
							// 		statusColor = "bg-green-500"
							// 		statusText = "Processed"
							// 		tooltipText = "No further action is needed."
							// 		break
							// 	case StatusType.PROCESSING:
							// 	case StatusType.CHARTING:
							// 	case StatusType.UPLOADED:
							// 		statusColor = "bg-blue-500"
							// 		statusText = "Processing"
							// 		showSpinner = true
							// 		tooltipText = projectId
							// 			? "Document is still being processed or is in queue."
							// 			: "At least one project is still processing or queued."
							// 		break
							// 	case StatusType.WARNING:
							// 		statusColor = "bg-yellow-500"
							// 		statusText = "Warning"
							// 		tooltipText = projectId
							// 			? "There is at least one project that has a Reprocess / Rechart / Reupload / Error status."
							// 			: "At least one project has reprocess/rechart/reupload/error, but not all are errors."
							// 		break
							// 	case StatusType.ERROR:
							// 		statusColor = "bg-red-500"
							// 		statusText = "Error"
							// 		tooltipText = projectId
							// 			? "All relevant projects encountered an error (DB_ERROR)."
							// 			: "All relevant projects have encountered an error."
							// 		break
							// 	case StatusType.UNKNOWN:
							// 		statusText = "Retrieving status..."
							// 		tooltipText = "Retrieving status..."
							// 		break
							// 	default:
							// 		tooltipText = "Retrieving status..."
							// }

							return (
								<Tooltip>
									<TooltipTrigger>
										<Badge variant="outline" className="flex items-center gap-2">
											{showSpinner ? (
												<Spinner className="h-3 w-3" />
											) : (
												<div className={`w-2 h-2 rounded-full ${statusColor}`} />
											)}
											{statusText}
										</Badge>
									</TooltipTrigger>
									<TooltipContent>{tooltipText}</TooltipContent>
								</Tooltip>
							)
						},
					},
				]
			: []),

		// ...(showSubjects
		// 	? [
		// 			{
		// 				id: "subjects",
		// 				accessorKey: "subjects",
		// 				header: "Subjects",
		// 				cell: ({ row }) => {
		// 					const document: ProjectDocumentMetadata = row.original

		// 					// // const projectIds = document.projectIds
		// 					// if (!projectIds?.length) return "—"

		// 					// // Check if all portfolio projects are included
		// 					// const isAllProjects =
		// 					// 	portfolioProjects?.length === projectIds.length &&
		// 					// 	portfolioProjects?.every((project) => projectIds.includes(project.id))

		// 					// if (isAllProjects) {
		// 					// 	return (
		// 					// 		<Badge variant="outline" className="mr-1 mb-1 cursor-pointer">
		// 					// 			All
		// 					// 		</Badge>
		// 					// 	)
		// 					// }

		// 					return (
		// 						<div className="flex flex-wrap gap-1">
		// 							{portfolioProjects
		// 								?.filter((project) => projectIds.includes(project.id))
		// 								.map((project) => (
		// 									<Badge
		// 										key={project.id}
		// 										variant="outline"
		// 										className="cursor-pointer whitespace-nowrap"
		// 										onClick={() => onSelectProject?.(project.id)}
		// 									>
		// 										{project.name}
		// 									</Badge>
		// 								))}
		// 						</div>
		// 					)
		// 				},
		// 			},
		// 		]
		// 	: []),
		...(showName
			? [
					{
						id: "nickname",
						accessorKey: "nickname",
						header: "Name",
						sortingFn: nicknameSort,
						enableSorting: true,
						cell: ({ row }) => {
							const document: ProjectDocumentMetadata = row.original
							return (
								<>
									{enableRename ? (
										<div className="w-full min-w-[6rem]">
											{" "}
											{/* Increased minimum width */}
											{editingNameRefId === document.id ? (
												<Input
													value={newName}
													onChange={(e: ChangeEvent<HTMLInputElement>) => setNewName(e.target.value)}
													onBlur={() => handleSaveName(document, newName, true)}
													onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
														if (e.key === "Enter") handleSaveName(document, newName, true)
													}}
													autoFocus
												/>
											) : (
												<span
													onClick={() => isReference && handleEditName(document)}
													className={isReference ? "cursor-pointer" : ""}
												>
													{getDocumentName(document.id) || "—"}
												</span>
											)}
										</div>
									) : (
										<div className="min-w-[12rem]">{getDocumentName(document.id) || "—"}</div>
									)}
								</>
							)
						},
					},
				]
			: []),

		...(showReferenceEditCols
			? [
					{
						id: "tags",
						accessorKey: "tags",
						header: "Tags",
						cell: ({ row }) => {
							const document: ProjectDocumentMetadata = row.original
							const tags = Array.isArray(document.tags) ? document.tags : []

							return (
								<TableTags
									tagOptions={allTags}
									selectedTags={tags}
									onApply={(newTags) => {
										onTagUpdate(
											[document],
											null, // add tags
											newTags, // setTags
										)
									}}
								>
									<div className="w-full h-full">
										{tags.length > 0 ? (
											<div className="flex flex-wrap gap-1">
												{tags.map((tag) => (
													<Badge variant="outline" key={tag} className="whitespace-nowrap">
														{tag}
													</Badge>
												))}
											</div>
										) : (
											<div className="w-full h-full items-center justify-center">—</div>
										)}
									</div>
								</TableTags>
							)
						},
					},
				]
			: []),
		{
			id: "number",
			accessorKey: "number",
			header: "Number",
			cell: ({ row }) => {
				const document = row.original

				return document.patent?.number ? (
					<div>
						{formatPatentNumber(document.patent.number)}
						<OpenInGooglePatentButton sourceNumber={document.patent.number} />
					</div>
				) : (
					"—"
				)
			},
		},

		{
			id: "title",
			accessorKey: "title",
			header: "Title",
			// sortingFn: multiLingualSort,
			cell: ({ row }) => {
				const document: ProjectDocumentMetadata = row.original
				return (
					<div
						onClick={() => {
							// if (isReference && document.type !== "PATENT") {
							//   handleEditTitle(document);
							// } else {
							onRowClick?.(document)
							// }
						}}
						className="w-96 max-w-xl overflow-hidden text-ellipsis"
					>
						{editingTitleRefId === document.id ? (
							<Input
								value={titleContent}
								onChange={(e: ChangeEvent<HTMLInputElement>) => setTitleContent(e.target.value)}
								onBlur={() => handleSaveTitle(document, titleContent)}
								onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
									if (e.key === "Enter") handleSaveTitle(document, titleContent)
								}}
								className="w-full"
								autoFocus
							/>
						) : (
							<span title={he.decode(document.title || "—")}>
								{he.decode(document.title || "—")}
							</span>
						)}
					</div>
				)
			},
			// enableSorting: true,
		},
		// {
		// 	id: "createdAt",
		// 	accessorKey: "createdAt",
		// 	header: "Created",
		// 	enableSorting: true,
		// 	cell: ({ row }) => {
		// 		const document: ProjectDocumentMetadata = row.original
		// 		return document.createdAt && document.document?.type !== DocumentType.PATENT
		// 			? DateTime.fromISO(document.createdAt, { zone: "utc" }).toFormat("MM/dd/yyyy")
		// 			: "—"
		// 	},
		// },
		{
			id: "publicationDate",
			accessorKey: "publicationDate",
			header: "Publication",
			enableSorting: true,
			cell: ({ row }) => {
				const document: ProjectDocumentMetadata = row.original
				return document.patent?.publicationDate &&
					document.patent?.publicationDate !== document.createdAt
					? DateTime.fromISO(document.patent?.publicationDate, {
							zone: "utc",
						}).toFormat("MM/dd/yyyy")
					: "—"
			},
		},
		{
			id: "priorityDate",
			accessorKey: "priorityDate",
			header: "Priority",
			enableSorting: true,
			hidden: false,
			cell: ({ row }) => {
				const document: ProjectDocumentMetadata = row.original
				return document.patent?.originalPriorityDate || document.patent?.filingDate
					? DateTime.fromISO(
							document.patent?.originalPriorityDate || document.patent?.filingDate,
							{
								zone: "utc",
							},
						).toFormat("MM/dd/yyyy")
					: "—"
			},
			sortingFn: (rowA, rowB) => {
				const dateA =
					rowA.original.patent?.originalPriorityDate ||
					rowA.original.patent?.filingDate ||
					""
				const dateB =
					rowB.original.patent?.originalPriorityDate ||
					rowB.original.patent?.filingDate ||
					""
				return dateA.localeCompare(dateB)
			},
		},
		// {
		//   id: "inventors",
		//   accessorKey: "inventors",
		//   header: "Inventors",
		//   cell: ({ row }) => {
		//     const document: ProjectDocumentMetadata = row.original
		//     const inventors = document.document?.patent?.inventors;
		//     if (!Array.isArray(inventors) || inventors.length === 0) {
		//       return "—";
		//     }
		//     return (
		//       <Tooltip>
		//         <TooltipTrigger className="text-left">
		//           {inventors[0]}
		//           {inventors.length > 1 && `, +${inventors.length - 1}`}
		//         </TooltipTrigger>
		//         <TooltipContent>{inventors.join(", ")}</TooltipContent>
		//       </Tooltip>
		//     );
		//   },
		// },
		// {
		//   id: "assignee",
		//   accessorKey: "assignee",
		//   header: "Assignee",
		//   cell: ({ row }) => row.original.assignee || "—",
		// },
		{
			id: "type",
			accessorKey: "type",
			header: "Type",
			cell: ({ row }) => {
				const document: ProjectDocumentMetadata = row.original
				return document.type === DocumentType.PATENT ? "Patent" : "Non-Patent"
			},
			enableHiding: true,
			enableSorting: !isSearch,
			hidden: true,
		},
		{
			id: "createdBy",
			accessorKey: "createdBy",
			header: "Created By",
			cell: ({ row }) => {
				const document: ProjectDocumentMetadata = row.original
				return getUserEmailFromId(document.createdBy) || "—"
			},
		},

		// {
		//   id: "prefix",
		//   accessorKey: "prefix",
		//   header: "Country",
		//   cell: ({ row }) => {
		//     const document = row.original;
		//     return document.prefix || "—";
		//   },
		//   enableHiding: true,
		//   enableSorting: true,
		//   hidden: true,
		// },
	]

	if (showReferenceEditCols) {
		columns.push({
			id: "note",
			accessorKey: "note",
			header: "Notes",
			// enableSorting: true,
			// sortingFn: multiLingualSort,
			cell: ({ row }) => {
				const document = row.original
				return (
					<div onClick={() => handleEditNotes(document)}>
						{editingNoteRefId === document.id ? (
							<Input
								value={noteContent}
								onChange={(e: ChangeEvent<HTMLInputElement>) => setNoteContent(e.target.value)}
								onBlur={() => handleSaveNote(document, noteContent)}
								onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
									if (e.key === "Enter") handleSaveNote(document, noteContent)
								}}
								className="w-full"
								autoFocus
							/>
						) : (
							<span>{document.notes || "—"}</span>
						)}
					</div>
				)
			},
		})
	}

	if (!checkboxStart) {
		columns.push(checkboxColumn)
	}

	if (enableRowActions) {
		columns.push({
			id: "actions",
			enableHiding: false,
			cell: ({ row }) => {
				const document = row.original
				return (
					<div className="clickable-element" onClick={(e) => e.stopPropagation()}>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button variant="ghost" className="h-8 w-8 p-0">
									<MoreVertical className="h-4 w-4" />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="end">
								<DropdownMenuItem onClick={() => handleViewDetails(document)}>
									View details
								</DropdownMenuItem>

								<DropdownMenuItem onClick={() => handleEditName(document)}>
									Rename
								</DropdownMenuItem>

								{showReferenceEditCols && (
									<DropdownMenuItem onClick={() => handleEditNotes(document)}>
										Edit notes
									</DropdownMenuItem>
								)}
								{handleRemoveRow && (
									<DropdownMenuItem
										onClick={() => handleRemoveRow(document)}
										disabled={isReferenceDisabled}
									>
										Remove
									</DropdownMenuItem>
								)}
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				)
			},
		})
	}

	const filters: Filters = {
		// prefix: {
		//   label: "Country",
		//   values: Array.from(
		//     new Set(references?.map((ref) => ref.prefix).filter(Boolean)),
		//   ).map((prefix) => ({
		//     label: prefix,
		//     value: prefix,
		//   })),
		// },

		...(isSearch || !isReference
			? {}
			: {
					tags: {
						label: "Tags",
						values: documents
							.flatMap((ref) => ref.tags || [])
							.filter(Boolean)
							.map((tag) => ({ label: tag, value: tag })),
					},
					type: {
						label: "Type",
						values: [
							{ label: "Patent", value: DocumentType.PATENT },
							{ label: "Non-Patent", value: DocumentType.NPL },
						],
					},
				}),
	}

	return (
		<>
			<DataTable
				columns={columns}
				data={documents}
				onRowSelection={handleRowSelection}
				height={height}
				showActions={showTableActions}
				onDeleteSelected={onDeleteSelected}
				initialFilters={filters}
				enableRowDelete={enableRowDelete}
				onTagApply={onTagUpdate}
				showTag={true}
				showReprocess={true}
				showRechart={true}
				isModal={isSearch}
				isDeleteSelectedLoading={isDeleteSelectedLoading}
				onReprocessSelected={onReprocessSelected}
				onRowClick={onRowClick}
				onDismissSelected={onDismissSelected}
				title={title}
			>
				{children}
			</DataTable>
		</>
	)
}

export default DocumentMetadataTable
