import { chartsApi } from "@/api/chartsApi"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"

export function useProjectChartsMatrix(projectId: string) {
	const {
		data: projectChartsMatrix,
		isLoading: projectChartsMatrixLoading,
		isError: projectChartsMatrixError,
	} = useQuery({
		queryKey: QUERY_KEYS.project.charts.matrix(projectId),
		queryFn: async () => {
			if (!projectId) return null
			return chartsApi.getProjectChartsMatrix(projectId)
		},
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		projectChartsMatrix,
		projectChartsMatrixLoading,
		projectChartsMatrixError,
	}
}

export default useProjectChartsMatrix
