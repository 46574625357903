import RecommendedPill from "@/components/info/RecommendedPill"
import WarningPill from "@/components/info/WarningPill"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { formatPatentNumber } from "@/utils"
import { formatInventorName } from "@/utils/projectUtils"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import { BookmarkCheck } from "lucide-react"
import React, { useEffect, useRef, useState } from "react"
import OpenInGooglePatentButton from "./patents/OpenInGooglePatentButton"
import { MetadataItem } from "./patents/PatentMetadataItem"

export enum CheckIndicatorType {
	CHECKBOX = "checkbox",
	BOOKMARK = "bookmark",
}

interface PatentMetadataLayoutProps {
	title: string
	number: string
	prefix?: string
	priorityDate?: string
	publicationDate?: string
	inventors?: string[]
	assignee?: string
	isNew?: boolean
	hasWarning?: boolean
	warningMessage?: string
	kindCodes?: string[]
	selectedKindCode?: string
	onKindCodeChange?: (kindCode: string) => void
	documentId?: string
	showCheckbox?: boolean
	defaultChecked?: boolean
	onCheckedChange?: (checked: boolean) => void
	checkIndicatorType?: CheckIndicatorType
}

export const PatentMetadataLayout: React.FC<PatentMetadataLayoutProps> = ({
	title,
	number,
	prefix = "",
	priorityDate,
	publicationDate,
	inventors = [],
	assignee,
	isNew = false,
	hasWarning = false,
	warningMessage = "",
	kindCodes = [],
	selectedKindCode = "",
	onKindCodeChange = () => {},
	documentId,
	showCheckbox = false,
	defaultChecked = false,
	onCheckedChange = () => {},
	checkIndicatorType = CheckIndicatorType.CHECKBOX,
}) => {
	const truncatedTitle = title.length > 30 ? `${title.substring(0, 30)}...` : title
	const containerRef = useRef<HTMLDivElement>(null)
	const [isCompact, setIsCompact] = useState(false)

	// Detect container width and update isCompact state
	useEffect(() => {
		if (!containerRef.current) return

		const checkWidth = () => {
			if (containerRef.current) {
				// Consider containers smaller than 400px as "compact"
				setIsCompact(containerRef.current.offsetWidth < 400)
			}
		}
		checkWidth()

		// Set up resize observer to check when container changes size
		const resizeObserver = new ResizeObserver(checkWidth)
		resizeObserver.observe(containerRef.current)

		return () => {
			if (containerRef.current) {
				resizeObserver.unobserve(containerRef.current)
			}
		}
	}, [])

	const metadataItems = []
	metadataItems.push(<p key="patent-number">{formatPatentNumber(number)}</p>)

	if (kindCodes.length > 0) {
		metadataItems.push(
			<div key="kind-code">
				{kindCodes.length === 1 ? (
					<span>{selectedKindCode}</span>
				) : (
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button
								variant="ghost"
								size="sm"
								className="h-auto p-0 font-normal text-gray-500 hover:text-gray-900"
							>
								{selectedKindCode}
								<ChevronDownIcon className="ml-1 h-4 w-4" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent>
							{kindCodes.map((kindCode) => (
								<DropdownMenuItem
									key={kindCode}
									onClick={() => documentId && onKindCodeChange(kindCode)}
								>
									{kindCode}
								</DropdownMenuItem>
							))}
						</DropdownMenuContent>
					</DropdownMenu>
				)}
			</div>,
		)
	}

	if (priorityDate) {
		metadataItems.push(
			<MetadataItem
				key="priority-date"
				label="Priority Date"
				value={new Date(priorityDate).toLocaleDateString()}
				isCompact={isCompact}
			/>,
		)
	}

	if (publicationDate) {
		metadataItems.push(
			<MetadataItem
				key="publication-date"
				label="Publication Date"
				value={new Date(publicationDate).toLocaleDateString()}
				isCompact={isCompact}
			/>,
		)
	}

	if (inventors && inventors.length > 0) {
		metadataItems.push(
			<MetadataItem
				key="inventors"
				label="Inventor"
				value={formatInventorName(inventors)}
				isCompact={isCompact}
			/>,
		)
	}

	if (assignee) {
		metadataItems.push(
			<MetadataItem
				key="assignee"
				label="Assignee"
				value={assignee}
				isCompact={isCompact}
			/>,
		)
	}

	return (
		<div ref={containerRef} className="flex items-start gap-4">
			{showCheckbox && (
				<div className="relative flex items-center self-stretch">
					{checkIndicatorType === CheckIndicatorType.CHECKBOX ? (
						<Checkbox
							checked={defaultChecked}
							onCheckedChange={onCheckedChange}
							className="h-4 w-4"
						/>
					) : (
						<BookmarkCheck className="h-4 w-4 text-black" aria-label="Already approved" />
					)}
				</div>
			)}

			<div className="space-y-1 w-full">
				<div className="flex items-start flex-wrap gap-2">
					<p className="text-sm font-semibold">{isCompact ? truncatedTitle : title}</p>
					<div className="flex flex-wrap gap-2 items-center">
						<OpenInGooglePatentButton sourceNumber={number} prefix={prefix || ""} />
						{isNew && <RecommendedPill content="Recommended from feedback" />}
						{hasWarning && <WarningPill content={warningMessage} />}
					</div>
				</div>

				<div className="flex flex-col gap-2 text-sm">
					<div className="flex items-center flex-wrap gap-x-2 gap-y-1">
						{isCompact
							? // In compact mode, render items directly without separators
								metadataItems
							: // In non-compact mode, add separator dots between items
								metadataItems.map((item, index) => (
									<React.Fragment key={`item-${index}`}>
										{item}
										{index < metadataItems.length - 1 && (
											<span className="text-secondary">•</span>
										)}
									</React.Fragment>
								))}
					</div>
				</div>
			</div>
		</div>
	)
}
