/**
 * Z-index scale for consistent stacking contexts across the application.
 * Each level represents a distinct UI layer, making it easy to understand
 * and maintain stacking relationships.
 */
export const Z_INDEX = {
	// Base layers
	base: 0,
	content: 1,

	// Navigation and structure
	sticky: 10,
	header: 20,

	// Interactive elements
	tooltip: 30,
	dropdown: 40,
	select: 45, // Nested dropdowns should appear above regular dropdowns

	// Overlay elements
	modal: 50,
	toast: 60,

	// Top level elements
	popover: 70,
} as const

// Type for z-index values
export type ZIndexValue = (typeof Z_INDEX)[keyof typeof Z_INDEX]
