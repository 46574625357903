import { Button } from "@/components/ui/button"
import { DocumentRating } from "@/types"
import { ThumbsDownIcon, ThumbsUpIcon } from "lucide-react"
import type React from "react"
import { useEffect, useState } from "react"

interface DocumentRatingControlProps {
	documentRating: DocumentRating
	onDocumentRatingChange: (newDocumentRating: DocumentRating) => void
}

const DocumentRatingControl: React.FC<DocumentRatingControlProps> = ({
	documentRating,
	onDocumentRatingChange,
}) => {
	const [_documentRating, setDocumentRating] = useState(documentRating)

	useEffect(() => {
		onDocumentRatingChange(_documentRating)
	}, [_documentRating])

	return (
		<div className="flex flex-row gap-0">
			<Button
				variant="ghost"
				size="icon"
				className="h-6 w-10"
				onClick={() =>
					_documentRating === DocumentRating.VERY_POSITIVE
						? setDocumentRating(null)
						: setDocumentRating(DocumentRating.VERY_POSITIVE)
				}
			>
				<ThumbsUpIcon
					className={
						_documentRating === DocumentRating.VERY_POSITIVE
							? "h-4 w-4 text-blue-500"
							: "h-4 w-4"
					}
				/>
				<ThumbsUpIcon
					className={
						_documentRating === DocumentRating.VERY_POSITIVE
							? "h-4 w-4 text-blue-500"
							: "h-4 w-4"
					}
				/>
			</Button>
			<Button
				variant="ghost"
				size="icon"
				className="h-6 w-6"
				onClick={() =>
					_documentRating === DocumentRating.POSITIVE
						? setDocumentRating(null)
						: setDocumentRating(DocumentRating.POSITIVE)
				}
			>
				<ThumbsUpIcon
					className={
						_documentRating === DocumentRating.POSITIVE ? "h-4 w-4 text-blue-500" : "h-4 w-4"
					}
				/>
			</Button>
			<Button
				variant="ghost"
				size="icon"
				className="h-6 w-6 text-blue"
				onClick={() =>
					_documentRating === DocumentRating.NEGATIVE
						? setDocumentRating(null)
						: setDocumentRating(DocumentRating.NEGATIVE)
				}
			>
				<ThumbsDownIcon
					className={
						_documentRating === DocumentRating.NEGATIVE ? "h-4 w-4 text-red-500" : "h-4 w-4"
					}
				/>
			</Button>
		</div>
	)
}

export default DocumentRatingControl
