"use client"

import { subDays, subYears } from "date-fns"
import * as React from "react"
import type { DateRange } from "react-day-picker"

import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { cn } from "@/lib/utils"
import { CalendarIcon } from "lucide-react"

const presets = [
	{
		label: "All Time",
		value: "all",
		dateRange: null,
	},
	{
		label: "Last 24 Hours",
		value: "24h",
		dateRange: {
			from: subDays(new Date(), 1),
			to: new Date(),
		},
	},
	{
		label: "Last 7 Days",
		value: "7d",
		dateRange: {
			from: subDays(new Date(), 7),
			to: new Date(),
		},
	},
	{
		label: "Last 14 Days",
		value: "14d",
		dateRange: {
			from: subDays(new Date(), 14),
			to: new Date(),
		},
	},
	{
		label: "Last 30 Days",
		value: "30d",
		dateRange: {
			from: subDays(new Date(), 30),
			to: new Date(),
		},
	},
	{
		label: "Last 90 Days",
		value: "90d",
		dateRange: {
			from: subDays(new Date(), 90),
			to: new Date(),
		},
	},
	{
		label: "Last Year",
		value: "1y",
		dateRange: {
			from: subYears(new Date(), 1),
			to: new Date(),
		},
	},
]

export function DateRangeFilter({
	onDateRangeChange,
}: {
	onDateRangeChange: (dateRange: DateRange | null) => void
}) {
	const [date, setDate] = React.useState<DateRange | null>(null)
	const [open, setOpen] = React.useState(false)
	const [selectedPreset, setSelectedPreset] = React.useState<string>("all")

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					id="date"
					variant={"outline"}
					className={cn(
						"w-[200px] justify-start text-left font-normal",
						!date && "text-muted-foreground",
					)}
				>
					<CalendarIcon className="w-4 h-4 mr-3" />
					{presets.find((p) => p.value === selectedPreset)?.label || "All Time"}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0" align="start">
				<div className="flex">
					<div className="flex flex-col gap-1 p-3 border-r">
						{presets.map((preset) => (
							<Button
								key={preset.value}
								variant={selectedPreset === preset.value ? "default" : "ghost"}
								className="justify-start"
								onClick={() => {
									setDate(preset.dateRange)
									setSelectedPreset(preset.value)
									onDateRangeChange(preset.dateRange)
									setOpen(false)
								}}
							>
								{preset.label}
							</Button>
						))}
					</div>
				</div>
			</PopoverContent>
		</Popover>
	)
}

export default DateRangeFilter
