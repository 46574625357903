import { TableSort, TableTags } from "@/components"
import { TableFilter } from "@/components/table/TableFilter"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Spinner } from "@/components/ui/spinner"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import TooltipButton from "@/components/ui/tooltip-button"
import { TableHeaderText } from "@/components/ui/typography"
import {
	DocumentRole,
	type ProjectDocumentMetadata,
	UnprocessedDocumentType,
} from "@/types"
import type { FilterData } from "@/types/table"
import type { Table } from "@tanstack/react-table"
import { PlusIcon, TrashIcon } from "lucide-react"
import { useState } from "react"

interface DocumentRoleListHeaderProps {
	role: DocumentRole
	label: string
	isLoading: boolean
	documentsCount: number
	selectedDocumentsCount: number
	tagOptions: string[]
	selectedTags: string[]
	onAddDocumentClick?: (type: UnprocessedDocumentType, role: DocumentRole) => void
	onTagUpdate: (selectedTags: string[]) => void
	onArchive: () => void
	onDelete: () => void
	table: Table<ProjectDocumentMetadata>
	filters: Record<string, FilterData>
}

export function DocumentRoleListHeader(props: DocumentRoleListHeaderProps) {
	const {
		role,
		label,
		isLoading,
		documentsCount,
		selectedDocumentsCount,
		tagOptions,
		selectedTags,
		onAddDocumentClick,
		onTagUpdate,
		onArchive,
		onDelete,
		table,
		filters,
	} = props

	const [dropdownOpen, setDropdownOpen] = useState(false)

	return (
		<div className="flex items-center justify-between px-3 py-1">
			<div className="flex items-center gap-2">
				<TableHeaderText>{label}</TableHeaderText>

				<div className="flex items-center">
					<DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
						<DropdownMenuTrigger asChild>
							<TooltipButton
								tooltipText="Add Documents"
								icon={<PlusIcon className="h-4 w-4" />}
								onClick={() => setDropdownOpen(true)}
								variant="ghost"
							/>
						</DropdownMenuTrigger>
						<DropdownMenuContent>
							{role !== DocumentRole.INFRINGEMENT && (
								<DropdownMenuItem
									onClick={() => {
										onAddDocumentClick?.(
											UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION,
											role,
										)
										setDropdownOpen(false)
									}}
								>
									Add published patents/applications
								</DropdownMenuItem>
							)}
							{role === DocumentRole.INFRINGEMENT && (
								<DropdownMenuItem
									onClick={() => {
										onAddDocumentClick?.(UnprocessedDocumentType.PRODUCT, role)
										setDropdownOpen(false)
									}}
								>
									Add product
								</DropdownMenuItem>
							)}
							{role !== DocumentRole.INFRINGEMENT && role !== DocumentRole.SUBJECT && (
								<DropdownMenuItem
									onClick={() => {
										onAddDocumentClick?.(UnprocessedDocumentType.NPL, role)
										setDropdownOpen(false)
									}}
								>
									Upload other documents
								</DropdownMenuItem>
							)}
						</DropdownMenuContent>
					</DropdownMenu>

					{/* {(role === DocumentRole.PRIOR_ART ||
						role === DocumentRole.INFRINGEMENT) && (
						<TooltipButton
							tooltipText="Search"
							icon={<SearchIcon className="h-4 w-4" />}
							onClick={() => onSearchReferencesClick?.(role)}
							variant="ghost"
						/>
					)} */}

					{selectedDocumentsCount > 0 && (
						<div className="flex items-center rounded-md border border-input h-8">
							<div className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium h-8 px-4 py-2 text-pr">
								<span>{selectedDocumentsCount} selected</span>
							</div>

							<>
								<TableTags
									tagOptions={tagOptions}
									selectedTags={selectedTags}
									onApply={onTagUpdate}
								/>

								{/* <Tooltip>
									<TooltipTrigger>
										{isLoading ? (
											<div className="h-8 w-8 flex items-center justify-center bg-blue-500 text-white transition-colors cursor-pointer border-l border-input  dark:border-white">
												<Spinner className="h-4 w-4 text-white" />
											</div>
										) : (
											<div
												className="h-8 w-8 flex items-center justify-center hover:bg-blue-500 hover:text-white transition-colors cursor-pointer border-l border-input  dark:border-white"
												onClick={onArchive}
												onKeyDown={(e) => {
													if (e.key === "Enter" || e.key === " ") {
														onArchive()
													}
												}}
												role="button"
												tabIndex={0}
											>
												<ArchiveIcon className="h-4 w-4" />
											</div>
										)}
									</TooltipTrigger>
									<TooltipContent>Archive</TooltipContent>
								</Tooltip> */}
								<Tooltip>
									<TooltipTrigger>
										{isLoading ? (
											<div className="h-8 w-8 flex items-center justify-center bg-red-500 text-white transition-colors cursor-pointer border-l border-input  border-rounded-md dark:border-white">
												<Spinner className="h-4 w-4 text-white" />
											</div>
										) : (
											<div
												className="h-8 w-8 flex items-center justify-center hover:bg-red-500 hover:text-white transition-colors cursor-pointer border-l border-input border-rounded-md dark:border-white"
												onClick={onDelete}
												onKeyDown={(e) => {
													if (e.key === "Enter" || e.key === " ") {
														onDelete()
													}
												}}
												role="button"
												tabIndex={0}
											>
												<TrashIcon className="h-4 w-4" />
											</div>
										)}
									</TooltipTrigger>
									<TooltipContent>Delete</TooltipContent>
								</Tooltip>
							</>
						</div>
					)}
				</div>
			</div>

			{/* Right Side: Filter and Sorting */}
			<div className="flex items-center">
				{documentsCount > 0 && (
					<div className="flex space-x-1">
						<TableFilter table={table} filters={filters} />
						<TableSort
							table={table}
							multiSorting={table.getState().sorting}
							setMultiSorting={table.setSorting}
						/>
					</div>
				)}
			</div>
		</div>
	)
}
