import { Loader } from "@/components"
import ClaimSelect from "@/components/claims/ClaimMultiSelect"
import DocumentNamePill from "@/components/documents/DocumentNamePill"
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable"
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle"
import TooltipButton from "@/components/ui/tooltip-button"
import { useProjectContext } from "@/context/ProjectContext"
import { useProjectPriorArtDocuments } from "@/features/documents/hooks/useProjectPriorArtDocuments"
import { useClaims } from "@/hooks"
import { cn } from "@/lib/utils"
import { useAreProcessesPending, useProjectStore } from "@/store"
import { ChartMode, conflictingChartProcesses } from "@/types"
import { DocumentType } from "@/types"
import { AlignVerticalSpaceAround, ChevronLeft } from "lucide-react"
import type React from "react"
import { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { PruneInvalidityColorModal } from "./components"
import ChartOptionsDropdown from "./components/ChartOptionsDropdown"
import ClaimInvalidityCardList from "./components/claim/ClaimInvalidityCardList"
import VerticalClaimNavigation from "./components/claim/VerticalClaimNavigation"
import InvalidityDocumentMode from "./components/document-mode/InvalidityDocumentMode"
import EditCellBoilerplateModal from "./components/modals/EditCellBoilerplateModal"
import DocumentSectionSelect from "./components/selects/DocumentSectionSelect"
import InvalidityTableMode from "./components/table-mode/InvalidityTableMode"
import { useChartsContext } from "./context/ChartsContext"

const InvalidityChartsPageContent: React.FC = () => {
	const navigate = useNavigate()
	// const { projectSubject } = useProjec	tSubject()
	const { priorArtDocuments, isLoading: isPriorArtLoading } =
		useProjectPriorArtDocuments()
	const { projectId } = useProjectContext()
	const { updateActiveLimitation, subjectId } = useChartsContext()
	const { subjectClaims } = useClaims(subjectId)
	const { showAssertedClaims } = useProjectStore()
	const isActionDisabled = useAreProcessesPending(conflictingChartProcesses, projectId)

	// Retrieve any state passed via navigate
	const location = useLocation()
	const { claimLimitationIdFromSummary } =
		(location.state as {
			claimLimitationIdFromSummary?: string
		}) || {}

	const [showPruneColorModal, setShowPruneColorModal] = useState(false)
	const [claimPanelSize, setClaimPanelSize] = useState(40)
	const [showEditHeaderFooterModal, setShowEditHeaderFooterModal] = useState(false)

	useEffect(() => {
		if (claimLimitationIdFromSummary) {
			updateActiveLimitation(claimLimitationIdFromSummary)
		}
	}, [claimLimitationIdFromSummary, updateActiveLimitation])

	const {
		filterLimitations,
		selectedChartMode,
		setFilterLimitations,
		setSelectedChartMode,
		chartData,
		isCombination,
	} = useChartsContext()

	const handleClaimSelect = useCallback(
		(claimIds: string[]) => {
			setFilterLimitations(claimIds)
		},
		[setFilterLimitations],
	)

	// Auto-select asserted claims if 'showAssertedClaims' is true
	useEffect(() => {
		if (showAssertedClaims && subjectClaims && subjectClaims.length > 0) {
			setFilterLimitations(subjectClaims.map((claim) => claim.id))
		} else if (showAssertedClaims) {
			setFilterLimitations([])
		}
	}, [showAssertedClaims, subjectClaims, setFilterLimitations])

	if (!subjectClaims) {
		return (
			<div className="flex justify-center items-center flex-col mt-12">
				<Loader message="Loading project data..." />
			</div>
		)
	}

	// ---------------------------
	// HEADERS (Claim + Document)
	// ---------------------------
	const InvalidityClaimHeader = () => (
		<div className="sticky top-0 bg-background border-b h-12 flex items-center px-3 gap-2 z-10 justify-between">
			<div className="flex items-center gap-3">
				<TooltipButton
					icon={<ChevronLeft className="h-4 w-4" />}
					tooltipText="Back"
					onClick={() => navigate(-1)}
					variant="ghost"
					size="icon"
				/>

				<DocumentNamePill documentId={chartData?.subjectId} size="sm" />
			</div>
			<div className="flex items-center gap-2">
				<ClaimSelect
					claims={subjectClaims}
					onClaimSelect={handleClaimSelect}
					className="h-7"
					filterLimitations={filterLimitations}
				/>
				<ChartOptionsDropdown isClaim={true} />
			</div>
		</div>
	)

	const InvalidityDocumentHeader = () => (
		<div className="sticky top-0 bg-background border-b h-12 flex items-center px-3 gap-2 justify-between z-10">
			<div className="flex items-center gap-2">
				{(!isCombination ||
					(isCombination && selectedChartMode !== ChartMode.DOCUMENT)) && (
					<div className="flex gap-2">
						{chartData?.documentIds.map((documentId) => (
							<DocumentNamePill
								key={documentId}
								doc={{ id: documentId, name: "" }}
								size="sm"
							/>
						))}
					</div>
				)}
			</div>
			<div className="flex items-center gap-2">
				{selectedChartMode === ChartMode.DOCUMENT && (
					<DocumentSectionSelect
						documentType={DocumentType.PATENT}
						disabled={isActionDisabled}
					/>
				)}
				<div className="flex items-center gap-2">
					<ToggleGroup
						type="single"
						value={selectedChartMode}
						onValueChange={(value) => value && setSelectedChartMode(value as ChartMode)}
						className="h-8"
					>
						<ToggleGroupItem
							className={cn(
								"flex-1",
								selectedChartMode === ChartMode.TABLE
									? "bg-tertiary text-primary-foreground"
									: "text-muted-foreground",
							)}
							value={ChartMode.TABLE}
							size="sm"
						>
							Table
						</ToggleGroupItem>
						<ToggleGroupItem
							className={cn(
								"flex-1",
								selectedChartMode === ChartMode.DOCUMENT
									? "bg-tertiary text-primary-foreground"
									: "text-muted-foreground",
							)}
							value={ChartMode.DOCUMENT}
							size="sm"
						>
							Document
						</ToggleGroupItem>
					</ToggleGroup>
					<TooltipButton
						icon={<AlignVerticalSpaceAround className="h-4 w-4" />}
						tooltipText="Edit header/footer"
						onClick={() => setShowEditHeaderFooterModal(true)}
						disabled={isActionDisabled}
						srText="Edit header/footer"
						size="icon"
						variant="ghost"
					/>
					<ChartOptionsDropdown isClaim={false} />
				</div>
			</div>
		</div>
	)

	return (
		<div className="relative flex flex-col h-[calc(100vh)]">
			<ResizablePanelGroup direction="horizontal" className="w-full flex-1">
				{/* CLAIMS PANEL */}
				<ResizablePanel
					defaultSize={claimPanelSize}
					onResize={setClaimPanelSize}
					className="flex flex-col h-full overflow-hidden"
				>
					<InvalidityClaimHeader />
					<div className="flex-1 overflow-auto">
						<div className="flex flex-row h-full w-full">
							<div className="w-24 flex-shrink-0 h-full">
								{chartData?.documentIds.length > 0 && <VerticalClaimNavigation />}
							</div>
							<ClaimInvalidityCardList
								projectId={projectId}
								documentIds={chartData?.documentIds}
							/>
						</div>
					</div>
				</ResizablePanel>

				<ResizableHandle withHandle />

				{/* DOCUMENT(S) PANEL */}
				<ResizablePanel
					defaultSize={100 - claimPanelSize}
					className="flex flex-col h-full overflow-hidden"
				>
					<InvalidityDocumentHeader />
					<div className="flex-1 overflow-auto">
						{selectedChartMode === ChartMode.DOCUMENT ? (
							<InvalidityDocumentMode />
						) : (
							<InvalidityTableMode />
						)}
					</div>
				</ResizablePanel>
			</ResizablePanelGroup>

			<PruneInvalidityColorModal
				open={showPruneColorModal}
				onOpenChange={setShowPruneColorModal}
				documents={priorArtDocuments}
			/>
			<EditCellBoilerplateModal
				isOpen={showEditHeaderFooterModal}
				onClose={() => setShowEditHeaderFooterModal(false)}
				applyToAllLimitations={true}
				cellHeader=""
				cellFooter=""
				cellMiddle=""
			/>
		</div>
	)
}

export default InvalidityChartsPageContent
