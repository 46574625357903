"use client"

import type React from "react"
import { Bar, BarChart, CartesianGrid, LabelList, XAxis, YAxis } from "recharts"

import {} from "@/components/ui/card"
import { ChartContainer, ChartTooltip } from "@/components/ui/chart"

export interface BaseBarChartProps<T> {
	data: T[]
	xKey: keyof T
	yKeys: (keyof T)[]
	/**
	 * Colors used to fill the bars.
	 */
	barColors?: string[]
	xTickFormatter?: (value: any, index: number) => string
	yTickFormatter?: (value: any) => string
	/**
	 * The chart container's height in pixels.
	 */
	chartHeight?: number
	/**
	 * Margin around the chart. Defaults to 12 on each side.
	 */
	margin?: { top?: number; right?: number; bottom?: number; left?: number }
	/**
	 * Custom tooltip content. If provided, will override the default tooltip.
	 */
	tooltipContent?: React.ReactElement | ((props: any) => React.ReactNode)
	/**
	 * Whether or not to display labels on bars.
	 */
	showLabels?: boolean
	/**
	 * Additional properties for the label list.
	 */
	labelProps?: Omit<React.ComponentProps<typeof LabelList>, "dataKey">
	chartConfig: any
}

export function BaseBarChart<T>({
	data,
	xKey,
	yKeys,
	barColors = ["var(--color-desktop)"],
	xTickFormatter,
	yTickFormatter,
	chartHeight = 250,
	margin = { top: 12, right: 12, bottom: 12, left: 12 },
	tooltipContent,
	showLabels = false,
	labelProps,
	chartConfig,
}: BaseBarChartProps<T>) {
	// Default props for the label list. These can be overridden via `labelProps`
	const defaultLabelProps = {
		position: "top" as const,
		offset: 12,
		className: "fill-foreground",
		fontSize: 12,
	}

	return (
		<ChartContainer
			config={chartConfig}
			className="w-full"
			style={{ height: chartHeight }}
		>
			<BarChart data={data} margin={margin}>
				<CartesianGrid vertical={false} />
				<XAxis
					dataKey={xKey as string}
					tickFormatter={xTickFormatter}
					tickLine={false}
					axisLine={false}
					tickMargin={8}
					minTickGap={32}
				/>
				<YAxis
					tickFormatter={yTickFormatter}
					tickLine={false}
					axisLine={false}
					tickMargin={8}
				/>
				{tooltipContent ? <ChartTooltip content={tooltipContent} /> : <ChartTooltip />}
				{yKeys.map((yKey, index) => (
					<Bar
						key={yKey as string}
						dataKey={yKey as string}
						fill={barColors[index] || barColors[0]}
						radius={8}
					>
						{showLabels && <LabelList {...defaultLabelProps} {...labelProps} />}
					</Bar>
				))}
			</BarChart>
		</ChartContainer>
	)
}

export default BaseBarChart
