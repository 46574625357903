"use client"

import { cn } from "@/lib/utils"
import { Check } from "lucide-react"
import type { HTMLAttributes } from "react"

export enum PhaseProgress {
	PLANNED = "planned",
	LOADING = "loading",
	COMPLETE = "complete",
}

interface PhaseProgressIconProps extends HTMLAttributes<HTMLDivElement> {
	state: PhaseProgress
	size?: "sm" | "md" | "lg"
	className?: string
}

const sizeClasses = {
	sm: "w-3 h-3",
	md: "w-6 h-6",
	lg: "w-8 h-8",
}

const checkSizeClasses = {
	sm: "w-3 h-3",
	md: "w-6 h-6",
	lg: "w-8 h-8",
}

export function PhaseProgressIcon({
	state,
	size = "sm",
	className,
	...props
}: PhaseProgressIconProps) {
	const baseClasses = cn(
		"rounded-full border-2 aspect-square",
		sizeClasses[size],
		className,
	)

	const baseStyles = {
		borderColor: "#03253a",
	}

	// Planned state
	if (state === "planned") {
		return <div className={baseClasses} style={baseStyles} {...props} />
	}

	// Loading state
	if (state === "loading") {
		return (
			<div
				className={cn(baseClasses, "animate-spin")}
				style={{
					...baseStyles,
					borderTopColor: "transparent",
				}}
				{...props}
			/>
		)
	}

	// Completed state
	return (
		<div
			className={cn(baseClasses, "flex items-center justify-center bg-white")}
			style={{
				...baseStyles,
				borderTopColor: "#03253a", // Explicitly set all borders
				borderRightColor: "#03253a", // to override any previous
				borderBottomColor: "#03253a", // transparent states
				borderLeftColor: "#03253a",
			}}
			{...props}
		>
			<Check className={cn("text-[#03253a]", checkSizeClasses[size])} strokeWidth={3} />
		</div>
	)
}
