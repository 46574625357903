/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { ErrorMessage, Loader } from "@/components"
import { useProjectContext } from "@/context/ProjectContext"
import useAllProjectDocuments from "@/features/documents/hooks/useAllProjectDocuments"
import { useSemanticSearch } from "@/hooks"
import { SearchMode } from "@/types"
import type { CitationSearchResult } from "@/types/invalidity"
import { useState } from "react"
import SearchDocumentsHeader from "./SearchDocumentsHeader"
import SearchResultsTable from "./SearchResultsTable"
/**
 * @description Renders the project search page
 */
const SearchDocumentsComponent = () => {
	const { projectId } = useProjectContext()
	const { semanticSearchDocuments } = useSemanticSearch()
	const { allDocumentsMetadata } = useAllProjectDocuments()

	const [showError, setShowError] = useState(false)
	const [searchQuery, setSearchQuery] = useState("")
	const [sentSearchQuery, setSentSearchQuery] = useState("")
	const [showSpinner, setShowSpinner] = useState(false)
	const [searchResults, setSearchResults] = useState<CitationSearchResult[] | null>(null)
	const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>(
		allDocumentsMetadata?.map((doc) => doc.id) || [],
	)
	const [searchMode, setSearchMode] = useState<SearchMode>(SearchMode.SEMANTIC)

	const handleDocumentChange = (selectedIds: string[]) => {
		setSelectedDocumentIds(selectedIds)
	}

	// Handles the semantic search
	const handleSemanticSearch = async () => {
		setSearchResults(null)
		setShowError(false)

		if (selectedDocumentIds?.length === 0) {
			setSelectedDocumentIds(allDocumentsMetadata?.map((doc) => doc.id) || []) // Set default docs in project mode
		}

		const trimmedSearchQuery = searchQuery.trim()
		setSentSearchQuery(trimmedSearchQuery)

		if (trimmedSearchQuery !== "" && trimmedSearchQuery !== null) {
			setShowSpinner(true)
			try {
				const response = await semanticSearchDocuments(
					projectId,
					trimmedSearchQuery,
					selectedDocumentIds,
					searchMode,
				)
				setShowSpinner(false)
				setShowError(false)
				setSearchResults(response)
			} catch (_error) {
				setShowError(true)
				setShowSpinner(false)
			}
		}
	}

	return (
		<>
			<div className="sticky top-0 bg-background z-10">
				<SearchDocumentsHeader
					searchQuery={searchQuery || ""}
					setSearchQuery={setSearchQuery}
					search={handleSemanticSearch}
					searchMode={searchMode}
					setSearchMode={setSearchMode}
					handleDocumentChange={handleDocumentChange}
					disabled={false}
					documents={allDocumentsMetadata}
				/>
				{showSpinner && <Loader message={"Searching the documents..."} />}
				{showError && <ErrorMessage />}
			</div>
			{searchResults && (
				<SearchResultsTable
					searchResults={searchResults}
					sentSearchQuery={sentSearchQuery}
					height="h-[calc(100vh-125px)]"
				/>
			)}
		</>
	)
}
export default SearchDocumentsComponent
