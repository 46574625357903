import LimitationGroupModal from "@/components/claims/LimitationGroupModal"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible"
import { Label } from "@/components/ui/label"
import { Table, TableBody } from "@/components/ui/table"
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import type { Claim, CollapsedClaim } from "@/types"
import {
	ChevronDownIcon,
	ChevronUpIcon,
	Pencil1Icon,
	PlusIcon,
} from "@radix-ui/react-icons"
import { Group } from "lucide-react"
import type React from "react"
import { useRef, useState } from "react"
import ClaimRow from "./ClaimRow"
import UpdateClaimsModal from "./UpdateClaimsModal"

interface ClaimListProps {
	editingBool?: boolean
	startCollapsed?: boolean
	claims: Claim[] | CollapsedClaim[]
	highlightedRef?: React.MutableRefObject<HTMLTableRowElement | null>
	citationText?: string
	patentId: string
	isSubject: boolean
}

const ClaimList: React.FC<ClaimListProps> = ({
	editingBool,
	startCollapsed,
	claims,
	highlightedRef,
	citationText,
	patentId,
	isSubject,
}) => {
	const sectionTitle = "Claims"
	const [isOpen, setIsOpen] = useState(!startCollapsed)
	const [showEditModal, setShowEditModal] = useState(false)
	const listRef = useRef<HTMLDivElement>(null)
	const [showLimitationGroupModal, setShowLimitationGroupModal] = useState(false)

	const handleEditButton = () => {
		setShowEditModal(true)
	}

	const handleLimitationGroupButton = () => {
		setShowLimitationGroupModal(true)
	}

	return (
		<div className="border rounded-lg mt-3 p-2 w-full dark:border-gray-800" ref={listRef}>
			{claims?.length === 0 ? (
				<div className="flex items-center justify-between gap-2 pl-2">
					<Label className="mb-0">{sectionTitle}</Label>
					{editingBool && (
						<Button variant="ghost" size="icon" onClick={handleEditButton} aria-label="add">
							<PlusIcon className="h-4 w-4" />
						</Button>
					)}
				</div>
			) : (
				<Collapsible open={isOpen} onOpenChange={setIsOpen}>
					<div className="flex items-center justify-between">
						<div className="flex items-center gap-2 pl-2">
							<Label className="mb-0">{sectionTitle}</Label>
							{editingBool && (
								<TooltipProvider>
									<Tooltip>
										<TooltipTrigger asChild>
											<Button
												variant="ghost"
												size="icon"
												onClick={handleEditButton}
												aria-label="edit"
											>
												<Pencil1Icon className="h-4 w-4" />
											</Button>
										</TooltipTrigger>
										<TooltipContent>
											<p>Edit Claims</p>
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							)}
							{isSubject && (
								<TooltipProvider>
									<Tooltip>
										<TooltipTrigger asChild>
											<Button
												variant="ghost"
												size="icon"
												onClick={handleLimitationGroupButton}
												aria-label="group"
											>
												<Group className="h-4 w-4" />
											</Button>
										</TooltipTrigger>
										<TooltipContent>
											<p>Group Limitations</p>
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							)}
						</div>
						<CollapsibleTrigger asChild>
							<Button variant="ghost" size="icon">
								{isOpen ? (
									<ChevronUpIcon className="h-4 w-4" />
								) : (
									<ChevronDownIcon className="h-4 w-4" />
								)}
							</Button>
						</CollapsibleTrigger>
					</div>
					<CollapsibleContent>
						<Table>
							<TableBody>
								{claims.map((claim) => {
									if (!claim) return null

									// Optional highlight check
									const isHighlighted =
										citationText &&
										claim.limitations?.[0]?.text.slice(0, 75) === citationText.slice(0, 75)

									return (
										<ClaimRow
											key={claim.id}
											claim={claim}
											editingBool={editingBool}
											isHighlighted={isHighlighted}
											highlightedRef={isHighlighted ? highlightedRef : null}
											isSubject={isSubject}
										/>
									)
								})}
							</TableBody>
						</Table>
					</CollapsibleContent>
				</Collapsible>
			)}
			<UpdateClaimsModal
				open={showEditModal}
				onClose={() => setShowEditModal(false)}
				patentId={patentId}
				initialClaims={claims}
				isSubject={isSubject}
			/>
			<LimitationGroupModal
				open={showLimitationGroupModal}
				onClose={() => setShowLimitationGroupModal(false)}
				subjectId={patentId}
			/>
		</div>
	)
}

export default ClaimList
