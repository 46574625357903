"use client"
import { VariableMenuItems } from "@/components/input/VariableMenu"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import useVariables from "@/hooks/useVariables"
import { cn } from "@/lib/utils"
import type { CollapsedLimitation } from "@/types/claim"
import type { InvalidityChart } from "@/types/invalidity"
import type { Variable, VariableInputType } from "@/utils/variables"
import {
	CELL_BOILERPLATE_VARIABLES,
	CITATION_VARIABLES,
	FIGURE_VARIABLES,
} from "@/utils/variables"
import Underline from "@tiptap/extension-underline"
import { EditorContent, useEditor } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import {
	Bold as BoldIcon,
	Heading,
	Italic,
	List,
	ListOrdered,
	Redo,
	Underline as UnderlineIcon,
	Undo,
} from "lucide-react"
import { TextSelection } from "prosemirror-state"
import * as React from "react"
import { forwardRef, useImperativeHandle, useMemo } from "react"
import { VariableNode } from "./VariableNode"

// Assuming ChartData and Limitation types are defined elsewhere, otherwise use 'any'
// import type { ChartData, Limitation } from 'path/to/your/types';

const ALL_VARIABLES: Variable[] = [
	...CITATION_VARIABLES,
	...FIGURE_VARIABLES,
	...CELL_BOILERPLATE_VARIABLES,
]

const ZWSP = "\u200B" // Define ZWSP here as well

// Helper to find a matching variable/variant label given a token.
function getLabelForVariable(key: string): string | null {
	for (const variable of ALL_VARIABLES) {
		if (variable.variants) {
			const foundVariant = variable.variants.find((variant) => variant.id === key)
			if (foundVariant) {
				return foundVariant.label
			}
		}
		if (variable.id === key) {
			return variable.label
		}
	}
	return null
}

// Get toolbar configuration based on input type
const getToolbarConfig = (type: VariableInputType) => {
	const commonTools = {
		bold: true,
		italic: true,
		underline: true,
		history: true,
	}

	switch (type) {
		case "citation":
			return {
				...commonTools,
				heading: false,
				lists: false,
			}
		case "introduction":
		case "title":
			return {
				...commonTools,
				heading: true,
				lists: true,
			}
		case "cellBoilerplate":
			return {
				...commonTools,
				heading: false,
				lists: false,
			}
		default:
			return {
				...commonTools,
				heading: false,
				lists: false,
			}
	}
}

// Editor configuration
const getEditorConfig = (props: {
	value: string
	onChange: (value: string) => void
	placeholder?: string
	className?: string
	onBlur?: () => void
	type: VariableInputType
}) => {
	const toolbarConfig = getToolbarConfig(props.type)

	return {
		extensions: [
			StarterKit.configure({
				heading: toolbarConfig.heading ? { levels: [1, 2, 3] } : false,
				paragraph: {},
				history: toolbarConfig.history ? {} : false,
				horizontalRule: false,
				dropcursor: false,
				gapcursor: false,
				blockquote: false,
				bold: toolbarConfig.bold ? {} : false,
				italic: toolbarConfig.italic ? {} : false,
				bulletList: toolbarConfig.lists ? {} : false,
				orderedList: toolbarConfig.lists ? {} : false,
				listItem: toolbarConfig.lists ? {} : false,
				strike: false,
				code: false,
				codeBlock: false,
			}),
			Underline,
			VariableNode,
		],
		content: props.value,
		onUpdate: ({ editor }) => {
			// Convert variable nodes to template syntax for backend processing
			const content = editor.getHTML()
			const processedVariables = content.replace(
				/<span[^>]*data-variable-id="([^"]*)"[^>]*data-variable-label="([^"]*)"[^>]*><\/span>/g,
				(_, id) => `{{${id}}}`,
			)
			// Remove Zero-Width Spaces before passing to onChange
			const finalContent = processedVariables.replace(/\u200B/g, "")
			props.onChange(finalContent)
		},
		onBlur: props.onBlur,
		editable: true,
		injectCSS: false,
		editorProps: {
			attributes: {
				class: cn(
					"prose prose-sm focus:outline-none min-h-[100px] px-3 py-2",
					props.className,
				),
			},
			handlePaste: (view, event) => {
				// Let TipTap handle HTML content
				if (event.clipboardData?.types.includes("text/html")) {
					return false
				}

				// Handle plain text with line breaks
				const text = event.clipboardData?.getData("text/plain")
				if (text) {
					event.preventDefault()

					// Split text by line breaks and create paragraphs
					const lines = text.split(/\r?\n/)
					const content = lines.map((line) => ({
						type: "paragraph",
						content: line ? [{ type: "text", text: line }] : [],
					}))

					// Insert content preserving line breaks
					const { tr } = view.state
					const fragment = view.state.schema.nodeFromJSON({
						type: "doc",
						content,
					})
					view.dispatch(tr.replaceSelection(fragment))
					return true
				}

				return false
			},
			// ADDED handleKeyDown logic here
			// This keydown handler is customized to handle the deletion of VariableNodes
			// We have a cursor bug in the TipTap editor where it automatically creates a <br> tag to ensure
			// the cursor can be rendered by the browser. As a result, we use ZWSP as a dummy character.
			handleKeyDown: (view, event) => {
				const { state, dispatch } = view
				const { selection, doc } = state // Schema not needed directly here

				// Only handle single cursor selections
				if (!selection.empty || !(selection instanceof TextSelection)) {
					return false
				}

				const { $from } = selection // Cursor position

				if (event.key === "Backspace") {
					const posBefore = $from.pos - 1
					if (posBefore < 0) {
						return false
					}

					const textBeforeCursor = doc.textBetween(posBefore, $from.pos, "\0", "\0")

					if (textBeforeCursor === ZWSP) {
						const posBeforeZWSP = posBefore - 1
						if (posBeforeZWSP < 0) {
							return false
						}

						const nodeBeforeZWSP = doc.nodeAt(posBeforeZWSP)

						if (nodeBeforeZWSP && nodeBeforeZWSP.type.name === "variable") {
							const from = posBeforeZWSP
							const to = $from.pos
							const tr = state.tr.delete(from, to)
							dispatch(tr)
							return true // Prevent default Backspace
						}
					}
				}

				if (event.key === "Delete") {
					const posBefore = $from.pos - 1
					if (posBefore < 0) {
						return false
					}

					const nodeBeforeCursor = posBefore >= 0 ? doc.nodeAt(posBefore) : null

					if (nodeBeforeCursor && nodeBeforeCursor.type.name === "variable") {
						const posAfter = $from.pos
						if (posAfter >= doc.content.size) {
							return false
						}

						const textAfterCursor = doc.textBetween(posAfter, posAfter + 1, "\0", "\0")

						if (textAfterCursor === ZWSP) {
							const from = posBefore
							const to = posAfter + 1
							const tr = state.tr.delete(from, to)
							dispatch(tr)
							return true // Prevent default Delete
						}
					}
				}

				return false // Allow default behavior
			},
		},
	}
}

export interface RichVariableInputProps {
	value: string
	onChange: (value: string) => void
	placeholder?: string
	className?: string
	previewReplacements?: Record<string, string>
	onBlur?: () => void
	type?: VariableInputType
	enableVariableMenu?: boolean
	chartData?: InvalidityChart
	claimLimitation?: CollapsedLimitation
}

export interface RichVariableInputRef {
	insertVariable: (variable: Variable) => void
	insertVariableVariant: (variable: Variable, variantId: string) => void
}

const RichVariableInput = forwardRef<RichVariableInputRef, RichVariableInputProps>(
	(
		{
			value,
			onChange,
			placeholder,
			className,
			previewReplacements,
			onBlur,
			type,
			enableVariableMenu = true,
			chartData,
			claimLimitation,
		},
		ref,
	) => {
		const containerRef = React.useRef<HTMLDivElement>(null)
		const [menuOpen, setMenuOpen] = React.useState(false)
		const [savedSelection, setSavedSelection] = React.useState<number | null>(null)

		const format = useVariables({
			documentIds: chartData?.documentIds,
			subjectId: chartData?.subjectId,
			claimLimitation: claimLimitation,
		})

		// Pre-process the initial value to convert {{id}} to spans
		const processedInitialValue = useMemo(() => {
			if (!value) return ""
			// Regex to find {{id}} allowing for spaces, capturing the id
			return value.replace(/{{\s*([^}\s]+)\s*}}/g, (match, id) => {
				// Get the display label for this id
				let label = format(`{{${id}}}`)

				// Apply fallback logic similar to insertVariable
				if (label === `{{${id}}}`) {
					// Check if format returned the input
					label = getLabelForVariable(id) || id
				}
				if (!label) {
					// Check if format returned empty or nullish
					label = getLabelForVariable(id) || id
				}

				// Return the span representation AND the ZWSP
				// Ensure attributes are properly quoted
				return `<span data-variable-id="${id}" data-variable-label="${label}"></span>${ZWSP}`
			})
		}, [value, format]) // Rerun only if initial value or format function changes

		const editor = useEditor(
			getEditorConfig({
				value: processedInitialValue,
				onChange,
				placeholder,
				className,
				onBlur,
				type,
			}),
		)

		const insertVariable = (id: string, position?: number) => {
			if (!editor) return
			const chain = editor.chain().focus()

			if (position !== undefined) {
				chain.command(({ tr }) => {
					const pos = tr.doc.resolve(position)
					tr.setSelection(TextSelection.create(tr.doc, pos.pos))
					return true
				})
			}

			let label = format(`{{${id}}}`)
			// Fallback value if the returned value is the input value
			if (label === `{{${id}}}`) {
				label = getLabelForVariable(id) || id
			}
			// Fallback if formatting returns empty string
			if (!label) {
				label = getLabelForVariable(id) || id
			}

			// Create the variable node content
			const nodeContent = {
				type: "variable", // Assuming 'variable' is the registered node name
				attrs: { id, label },
			}

			// Define the Zero-Width Space character
			const zeroWidthSpace = "\u200B"
			const zwspNode = { type: "text", text: zeroWidthSpace }

			// Insert the variable node AND the ZWSP using insertContent
			chain.insertContent([nodeContent, zwspNode]).run()
		}

		const toolbarConfig = getToolbarConfig(type)

		const handleMenuOpenChange = (open: boolean) => {
			if (open && editor) {
				setSavedSelection(editor.state.selection.from)
			}
			setMenuOpen(open)
		}

		const handleVariableSelect = (variable: Variable, variantId?: string) => {
			if (!editor) return

			const token = variantId || variable.id
			if (!token) return

			insertVariable(token, savedSelection ?? undefined)

			setSavedSelection(null)
			setMenuOpen(false)
		}

		useImperativeHandle(ref, () => ({
			insertVariable: (variable: Variable) => {
				insertVariable(variable.id)
			},
			insertVariableVariant: (variable: Variable, variantId: string) => {
				insertVariable(variantId)
			},
		}))

		React.useEffect(() => {
			const handleClickOutside = (e: MouseEvent) => {
				const target = e.target as HTMLElement
				if (containerRef.current && !containerRef.current.contains(target)) {
					setMenuOpen(false)
				}
			}
			document.addEventListener("mousedown", handleClickOutside)
			return () => document.removeEventListener("mousedown", handleClickOutside)
		}, [])

		return (
			<div
				ref={containerRef}
				className={cn(
					"relative h-[200px] w-full rounded-md border border-input bg-background text-sm",
					className,
				)}
			>
				<div className="flex items-center gap-1 border-b px-2 py-1 bg-background sticky top-0 z-10">
					{toolbarConfig.heading && (
						<>
							<DropdownMenu>
								<DropdownMenuTrigger asChild>
									<Button
										variant="ghost"
										size="sm"
										className={cn(
											"h-8 px-2 gap-1",
											(editor?.isActive("heading", { level: 1 }) ||
												editor?.isActive("heading", { level: 2 }) ||
												editor?.isActive("heading", { level: 3 })) &&
												"bg-muted",
										)}
									>
										<Heading className="h-4 w-4" />
										<span>
											{editor?.isActive("heading", { level: 1 })
												? "H1"
												: editor?.isActive("heading", { level: 2 })
													? "H2"
													: editor?.isActive("heading", { level: 3 })
														? "H3"
														: "Heading"}
										</span>
									</Button>
								</DropdownMenuTrigger>
								<DropdownMenuContent>
									<DropdownMenuItem
										onClick={() => editor?.chain().focus().toggleHeading({ level: 1 }).run()}
										className={cn(editor?.isActive("heading", { level: 1 }) && "bg-muted")}
									>
										<h1 className="text-xl font-bold">Heading 1</h1>
									</DropdownMenuItem>
									<DropdownMenuItem
										onClick={() => editor?.chain().focus().toggleHeading({ level: 2 }).run()}
										className={cn(editor?.isActive("heading", { level: 2 }) && "bg-muted")}
									>
										<h2 className="text-lg font-bold">Heading 2</h2>
									</DropdownMenuItem>
									<DropdownMenuItem
										onClick={() => editor?.chain().focus().toggleHeading({ level: 3 }).run()}
										className={cn(editor?.isActive("heading", { level: 3 }) && "bg-muted")}
									>
										<h3 className="text-base font-bold">Heading 3</h3>
									</DropdownMenuItem>
									<DropdownMenuItem
										onClick={() => editor?.chain().focus().setParagraph().run()}
										className={cn(!editor?.isActive("heading") && "bg-muted")}
									>
										<p>Paragraph</p>
									</DropdownMenuItem>
								</DropdownMenuContent>
							</DropdownMenu>
							<div className="h-4 w-px bg-border" />
						</>
					)}
					{toolbarConfig.bold && (
						<Button
							variant="ghost"
							size="sm"
							onClick={() => editor?.chain().focus().toggleBold().run()}
							className={cn("h-8 w-8 p-0", editor?.isActive("bold") && "bg-muted")}
						>
							<BoldIcon className="h-4 w-4" />
						</Button>
					)}
					{toolbarConfig.italic && (
						<Button
							variant="ghost"
							size="sm"
							onClick={() => editor?.chain().focus().toggleItalic().run()}
							className={cn("h-8 w-8 p-0", editor?.isActive("italic") && "bg-muted")}
						>
							<Italic className="h-4 w-4" />
						</Button>
					)}
					{toolbarConfig.underline && (
						<Button
							variant="ghost"
							size="sm"
							onClick={() => editor?.chain().focus().toggleUnderline().run()}
							className={cn("h-8 w-8 p-0", editor?.isActive("underline") && "bg-muted")}
						>
							<UnderlineIcon className="h-4 w-4" />
						</Button>
					)}
					{toolbarConfig.lists && (
						<>
							<Button
								variant="ghost"
								size="sm"
								onClick={() => editor?.chain().focus().toggleBulletList().run()}
								className={cn("h-8 w-8 p-0", editor?.isActive("bulletList") && "bg-muted")}
							>
								<List className="h-4 w-4" />
							</Button>
							<Button
								variant="ghost"
								size="sm"
								onClick={() => editor?.chain().focus().toggleOrderedList().run()}
								className={cn("h-8 w-8 p-0", editor?.isActive("orderedList") && "bg-muted")}
							>
								<ListOrdered className="h-4 w-4" />
							</Button>
						</>
					)}
					{toolbarConfig.history && (
						<>
							<div className="h-4 w-px bg-border" />
							<Button
								variant="ghost"
								size="sm"
								onClick={() => editor?.chain().focus().undo().run()}
								disabled={!editor?.can().undo()}
								className="h-8 w-8 p-0"
							>
								<Undo className="h-4 w-4" />
							</Button>
							<Button
								variant="ghost"
								size="sm"
								onClick={() => editor?.chain().focus().redo().run()}
								disabled={!editor?.can().redo()}
								className="h-8 w-8 p-0"
							>
								<Redo className="h-4 w-4" />
							</Button>
						</>
					)}
				</div>
				<div className="h-[calc(200px-40px)] overflow-y-auto">
					<div className="px-3 py-2">
						<EditorContent
							editor={editor}
							className={cn(
								"outline-none prose prose-sm max-w-none",
								"[&_h1]:text-2xl [&_h1]:font-bold [&_h1]:mb-4",
								"[&_h2]:text-xl [&_h2]:font-bold [&_h2]:mb-3",
								"[&_h3]:text-lg [&_h3]:font-bold [&_h3]:mb-2",
								"[&_p]:mb-2",
								"[&_ul]:list-disc [&_ul]:ml-4",
								"[&_ol]:list-decimal [&_ol]:ml-4",
								"[&_blockquote]:border-l-2 [&_blockquote]:border-gray-300 [&_blockquote]:pl-4 [&_blockquote]:italic",
							)}
						/>
					</div>
				</div>
				{enableVariableMenu && (
					<DropdownMenu open={menuOpen} onOpenChange={handleMenuOpenChange}>
						{menuOpen && (
							<DropdownMenuContent className="z-50">
								<VariableMenuItems
									variables={ALL_VARIABLES}
									onSelect={handleVariableSelect}
									onSelectVariant={handleVariableSelect}
								/>
							</DropdownMenuContent>
						)}
					</DropdownMenu>
				)}
			</div>
		)
	},
)

RichVariableInput.displayName = "RichVariableInput"

export default RichVariableInput
