/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import PatentNumberInput from "@/components/documents/patents/PatentNumberInput"
import { useUnprocessedDocumentsContext } from "@/context/UnprocessedDocumentsContext"
import { BaseDocumentModal } from "@/features/documents/components/BaseDocumentModal"
import {
	type DocumentRole,
	type PatentNumberOption,
	UnprocessedDocumentType,
} from "@/types"
import type React from "react"
interface AddPatentsModalProps {
	open: boolean
	handleClose: () => void
	documentRole: DocumentRole
}

/**
 * @description Modal for adding references by number
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} handleClose - Function to close the modal
 */
const AddPatentsModal: React.FC<AddPatentsModalProps> = ({
	open,
	handleClose,
	documentRole,
}) => {
	// Use the custom hook for handling unprocessed documents
	const { unprocessedDocuments, addUnprocessedDocuments, resetAll } =
		useUnprocessedDocumentsContext()

	// Reset the modal state on close, including resetting project selection to default
	const handleResetModal = () => {
		resetAll()
		handleClose()
	}

	const handleAddDocuments = (patentDetails: PatentNumberOption[]) => {
		addUnprocessedDocuments(
			documentRole,
			UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION,
			{
				patentDetails,
			},
		)
	}

	return (
		<BaseDocumentModal
			open={open}
			onClose={handleClose}
			title="Add Patents"
			documentRole={documentRole}
			documentType={UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION}
			isAddDisabled={unprocessedDocuments.length <= 0}
		>
			<PatentNumberInput
				onDetails={handleAddDocuments}
				onCancel={handleResetModal}
				onlyShowInputError={unprocessedDocuments.length > 0}
			/>
		</BaseDocumentModal>
	)
}

export default AddPatentsModal
