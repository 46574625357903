/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
"use client"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import {
	SidebarGroup,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
	SidebarMenuSub,
	SidebarMenuSubButton,
	SidebarMenuSubItem,
	SidebarMenuSubLabel,
	useSidebar,
} from "@/components/ui/sidebar"
import useProjectInfringementDocuments from "@/features/documents/hooks/useProjectInfringementDocuments"
import { useProjectPriorArtDocuments } from "@/features/documents/hooks/useProjectPriorArtDocuments"
import { useProjectSubjectDocuments } from "@/features/documents/hooks/useProjectSubjectDocuments"
import { useDocumentNaming } from "@/hooks"
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@radix-ui/react-collapsible"
import { ChevronRight, Settings2, Table2 } from "lucide-react"
import { File } from "lucide-react"
import { useState } from "react"
import type React from "react"
import { useNavigate } from "react-router-dom"
import { useLocation, useParams } from "react-router-dom"

interface NavItem {
	title: string
	url: string
	icon?: React.ElementType
	items?: NavItem[]
	documentGroups?: {
		label: string
		items: NavItem[]
	}[]
	isExpanded?: boolean
	setIsExpanded?: (isExpanded: boolean) => void
}

/**
 * Recursively check whether a navigation item is active
 * by comparing its url (or its children's url) with the current path.
 */
function checkActive(item: NavItem, currentPath: string): boolean {
	if (item.url === currentPath) return true
	if (item.items?.some((child) => checkActive(child, currentPath))) return true
	if (
		item.documentGroups?.some((group) =>
			group.items.some((child) => checkActive(child, currentPath)),
		)
	)
		return true
	return false
}

const SubMenu = ({
	item,
	currentPath,
	navigate,
}: { item: NavItem; currentPath: string; navigate: (url: string) => void }) => {
	return (
		<SidebarMenu>
			<SidebarMenuSub>
				{item.items?.map((subItem) => {
					const subActive = checkActive(subItem, currentPath)
					return (
						<SidebarMenuSubItem key={subItem.title}>
							<SidebarMenuSubButton
								asChild
								onClick={() => {
									navigate(subItem.url)
								}}
								isActive={subActive}
							>
								<span className="min-w-0 truncate">{subItem.title}</span>
							</SidebarMenuSubButton>
						</SidebarMenuSubItem>
					)
				})}
				{item.documentGroups?.map(
					(group) =>
						group.items.length > 0 && (
							<div key={group.label}>
								<SidebarMenuSubLabel>{group.label}</SidebarMenuSubLabel>
								{group.items.map((docItem) => {
									const docActive = checkActive(docItem, currentPath)
									return (
										<SidebarMenuSubItem key={docItem.title} className={"ml-3"}>
											<SidebarMenuSubButton
												asChild
												onClick={() => {
													navigate(docItem.url)
												}}
												isActive={docActive}
											>
												<span className="min-w-0 truncate">{docItem.title}</span>
											</SidebarMenuSubButton>
										</SidebarMenuSubItem>
									)
								})}
							</div>
						),
				)}
			</SidebarMenuSub>
		</SidebarMenu>
	)
}

const NavItem = ({
	item,
	currentPath,
	navigate,
}: { item: NavItem; currentPath: string; navigate: (url: string) => void }) => {
	const { open } = useSidebar()

	// Determine if the current path is active for this item
	const active = checkActive(item, currentPath)
	// Determine if the item has any subitems or non-empty document groups
	const hasSubItems =
		(item?.items && item.items.length > 0) ||
		item?.documentGroups?.some((group) => group.items.length > 0)
	const [isPopoverOpen, setIsPopoverOpen] = useState(false)

	if (hasSubItems && open) {
		return (
			<Collapsible
				key={item.title}
				asChild
				open={!!item.isExpanded}
				onOpenChange={(expanded) => item.setIsExpanded?.(expanded)}
				className="group/collapsible"
			>
				<SidebarMenuItem>
					<CollapsibleTrigger asChild>
						<div className="flex items-center justify-between w-full">
							<SidebarMenuButton
								asChild
								tooltip={item.title}
								onClick={() => {
									navigate(item.url)
								}}
								isActive={active}
							>
								<span className="flex items-center w-full">
									{item.icon && <item.icon className="h-5 w-5" />}
									<span>{item.title}</span>
									<span className="h-5 w-5 ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90">
										<ChevronRight className="h-5 w-5" />
									</span>
								</span>
							</SidebarMenuButton>
						</div>
					</CollapsibleTrigger>
					<CollapsibleContent>
						<SubMenu item={item} currentPath={currentPath} navigate={navigate} />
					</CollapsibleContent>
				</SidebarMenuItem>
			</Collapsible>
		)
	}

	// When the sidebar is collapsed and the nav item has nested items,
	// display the sub-menu in a popover on hover.
	if (!open && hasSubItems) {
		return (
			<Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
				<PopoverTrigger asChild>
					<SidebarMenuButton
						onMouseEnter={() => setIsPopoverOpen(true)}
						onMouseLeave={() => setIsPopoverOpen(false)}
						onClick={() => {
							navigate(item.url)
						}}
						isActive={active}
					>
						<span className="flex items-center">
							{item.icon && <item.icon className="nav-icon" />}
						</span>
					</SidebarMenuButton>
				</PopoverTrigger>
				<PopoverContent
					side="right"
					align="start"
					className="nav-popover-content p-2 sidebar-background"
					onMouseEnter={() => setIsPopoverOpen(true)}
					onMouseLeave={() => setIsPopoverOpen(false)}
				>
					<SidebarMenuSubLabel>
						<span className="flex items-center">
							<span>{item.title}</span>
						</span>
					</SidebarMenuSubLabel>
					<SubMenu item={item} currentPath={currentPath} navigate={navigate} />
				</PopoverContent>
			</Popover>
		)
	}

	return (
		<SidebarMenuItem key={item.title}>
			<SidebarMenuButton
				asChild
				tooltip={item.title}
				onClick={() => {
					navigate(item.url)
				}}
				isActive={active}
			>
				<span className="flex items-center">
					{item.icon && <item.icon />}
					<span className="min-w-0 truncate">{item.title}</span>
				</span>
			</SidebarMenuButton>
		</SidebarMenuItem>
	)
}

export function ProjectSidebarContent() {
	const navigate = useNavigate()
	const location = useLocation()
	const currentPath = location.pathname

	const { priorArtDocuments } = useProjectPriorArtDocuments()
	const { subjectDocuments } = useProjectSubjectDocuments()
	const { infringementDocuments } = useProjectInfringementDocuments()
	const { projectId } = useParams()
	const { getDocumentName } = useDocumentNaming()
	const basePath = `/project/${projectId}`

	const [isDocumentsExpanded, setIsDocumentsExpanded] = useState(true)
	const [isChartsExpanded, setIsChartsExpanded] = useState(true)

	const projectSidebarItems = [
		{
			title: "Documents",
			url: `${basePath}/documents`,
			icon: File,
			isActive: true,
			items: [
				{
					title: "Search",
					url: `${basePath}/documents/search`,
				},
				{
					title: "Explore",
					url: `${basePath}/documents/explore`,
				},
			],
			documentGroups: [
				{
					label: "Subjects",
					items: subjectDocuments.map((document) => ({
						title: getDocumentName(document.id),
						url: `${basePath}/documents/${document.id}`,
					})),
				},
				{
					label: "Prior Art",
					items: priorArtDocuments.map((document) => ({
						title: getDocumentName(document.id),
						url: `${basePath}/documents/${document.id}`,
					})),
				},
				{
					label: "Products",
					items: infringementDocuments.map((document) => ({
						title: getDocumentName(document.id),
						url: `${basePath}/documents/${document.id}`,
					})),
				},
			],
			isExpanded: isDocumentsExpanded,
			setIsExpanded: setIsDocumentsExpanded,
		},
		{
			title: "Analysis",
			url: `${basePath}/charts`,
			icon: Table2,
			documentGroups: [
				{
					label: "Subjects",
					items: subjectDocuments.map((document) => ({
						title: getDocumentName(document.id),
						url: `${basePath}/charts/${document.id}`,
					})),
				},
			],
			isExpanded: isChartsExpanded,
			setIsExpanded: setIsChartsExpanded,
		},
		{
			title: "Settings",
			url: `${basePath}/settings`,
			icon: Settings2,
		},
	]

	return (
		<SidebarGroup>
			<SidebarMenu>
				{projectSidebarItems.map((item) => {
					return (
						<NavItem
							key={item.title}
							item={item}
							currentPath={currentPath}
							navigate={navigate}
						/>
					)
				})}
			</SidebarMenu>
		</SidebarGroup>
	)
}
