import { api } from "@/api"
import { useProjectContext } from "@/context/ProjectContext"
import { useAppStateStore } from "@/store"
import type { ProjectSettings, ProjectSettingsUpdate } from "@/types"
import { invalidateSettingsRelatedQueries } from "@/utils/query/invalidation"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import {
	handleOptimisticUpdate,
	revertOptimisticUpdates,
} from "@/utils/query/optimisticUpdates"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQueryClient } from "@tanstack/react-query"
import { useMutation, useQuery } from "@tanstack/react-query"
/**
 * Hook to get and update project settings with grouped state management.
 *
 * Grouped settings:
 * - Budget (localBudget)
 * - Claim settings (defaultToAssertedClaims, collapsedClaimDepth, claimNumberStyle)
 * - Export settings (combined group for boilerplate, citation, word and figure settings)
 */
export function useProjectSettings() {
	const queryClient = useQueryClient()
	const { addErrorMessage, addSuccessMessage } = useAppStateStore()
	const { projectId } = useProjectContext()

	// Fetch settings with React Query
	const {
		data: settings,
		isLoading,
		error,
	} = useQuery<ProjectSettings>({
		queryKey: QUERY_KEYS.project.settings(projectId),
		queryFn: () => api.getProjectSettings(projectId),
		enabled: !!projectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	// Mutation for updating the project settings on the API.
	// Notice that we now expect an object containing { newSettings, budget }
	const updateProjectSettingsMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.settings.update(),
		mutationFn: (newSettings: ProjectSettingsUpdate) => {
			return api.updateProjectSettings(projectId, newSettings)
		},
		onMutate: async (newSettings: ProjectSettingsUpdate) => {
			return handleOptimisticUpdate({
				queryClient,
				queryKeys: [QUERY_KEYS.project.settings(projectId)],
				updateFn: () => newSettings,
			})
		},
		onError: (_err, _variables, context) => {
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
			addErrorMessage("Error updating project settings")
		},
		onSuccess: () => {
			invalidateSettingsRelatedQueries(queryClient, projectId)
		},
	})

	// Call this function to save the new settings.
	// It now accepts the full new settings object and the new budget.
	async function saveSettings(newSettings: ProjectSettingsUpdate) {
		await updateProjectSettingsMutation.mutateAsync(newSettings)
	}

	return {
		isLoading,
		error: error || undefined,
		settings,
		saveSettings,
	}
}

export default useProjectSettings
