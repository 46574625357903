/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DragAndDropFileArea } from "@/components"
import { useUnprocessedDocumentsContext } from "@/context/UnprocessedDocumentsContext"
import { BaseDocumentModal } from "@/features/documents/components/BaseDocumentModal"
import { useFileHandler } from "@/hooks"
import { DocumentRole, UnprocessedDocumentType } from "@/types/documents"
import type React from "react"
import { useMemo } from "react"

interface AddDocumentsModalProps {
	open: boolean
	handleClose: () => void
	title?: string
	buttonLabel?: string
	showProcessSettings?: boolean
	maxFiles?: number
	documentRole?: DocumentRole
	isProcessing?: boolean
}

/**
 * @description Upload files modal
 */
const AddDocumentsModal: React.FC<AddDocumentsModalProps> = ({
	open,
	handleClose,
	maxFiles = 10,
	documentRole = DocumentRole.PRIOR_ART,
}) => {
	const { handleFileChange, resetAll } = useFileHandler({ maxFiles: maxFiles })
	const {
		addUnprocessedDocuments,
		resetAll: resetUnprocessedDocuments,
		unprocessedDocuments,
	} = useUnprocessedDocumentsContext()

	// Close modal and reset state.
	const handleCloseClick = () => {
		resetAll()
		resetUnprocessedDocuments()
		handleClose()
	}

	const handleFileChangeInternal = (inputFiles: File[]) => {
		const validFiles = handleFileChange(inputFiles)
		addUnprocessedDocuments(
			documentRole,
			documentRole === DocumentRole.INFRINGEMENT
				? UnprocessedDocumentType.PRODUCT
				: UnprocessedDocumentType.NPL,
			{
				files: validFiles,
			},
		)
	}

	const isDragAndDropDisabled = useMemo(
		() => unprocessedDocuments.length >= maxFiles,
		[unprocessedDocuments.length, maxFiles],
	)

	return (
		<BaseDocumentModal
			open={open}
			onClose={handleCloseClick}
			documentRole={documentRole}
			documentType={UnprocessedDocumentType.NPL}
			isAddDisabled={unprocessedDocuments.length <= 0}
		>
			<DragAndDropFileArea
				key={"add-documents-modal"}
				handleFiles={handleFileChangeInternal}
				supportedFileTypes={[".pdf", ".txt"]}
				maxFiles={maxFiles}
				disabled={isDragAndDropDisabled}
			/>
		</BaseDocumentModal>
	)
}

export default AddDocumentsModal
