import type {
	GetPatch103ReferencesRequest,
	NPLSearchRequest,
	PatentRecommendationRequest,
	PatentSearchRequest,
	ProductSearchRequest,
} from "@/types"
import { apiClient } from "./client"

export const searchApi = {
	async search(
		projectId: string,
		subjectId: string,
		options: Partial<PatentSearchRequest> = {},
	) {
		const payload: PatentSearchRequest = {
			project_id: projectId,
			subject_id: subjectId,
			max_results: 10,
			limitation_ids: [],
			keywords: [],
			claim_type: "Independent Claims",
			dedupe_family_id: true,
			search_strategy: "Regular",
			...options,
		}
		return await apiClient.get("get_prior_art_search", payload)
	},
	async recommend(
		projectId: string,
		subjectId: string,
		options: Partial<PatentRecommendationRequest> = {},
	) {
		const payload: PatentRecommendationRequest = {
			project_id: projectId,
			subject_id: subjectId,
			max_results: 10,
			dedupe_by_family_id: true,
			...options,
		}
		return await apiClient.get("get_prior_art_recommendations", payload)
	},

	async getPatch103References(
		projectId: string,
		subjectId: string,
		options: Partial<GetPatch103ReferencesRequest> = {},
	) {
		const payload: GetPatch103ReferencesRequest = {
			project_id: projectId,
			subject_id: subjectId,
			document_id: "",
			max_results: 10,
			dedupe_family_id: true,
			search_strategy: "Regular",
			...options,
		}
		return await apiClient.get("get_patch_103_references", payload)
	},

	async getNPLSearch(
		projectId: string,
		subjectId: string,
		options: Partial<NPLSearchRequest> = {},
	) {
		const payload: NPLSearchRequest = {
			subject_id: subjectId,
			...options,
		}
		return await apiClient.post("npl_search", payload)
	},

	async getProductSearch(
		projectId: string,
		subjectId: string,
		options: Partial<ProductSearchRequest> = {},
	) {
		const payload: ProductSearchRequest = {
			subject_id: subjectId,
			whitelisted_sites: [],
			blacklisted_sites: [],
			example_products: [],
			...options,
		}
		return await apiClient.post("product_search", payload)
	},
}
