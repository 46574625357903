export enum PageName {
	// Project pages
	ProjectDocuments = "PROJECT_DOCUMENTS",
	ProjectCharts = "PROJECT_CHARTS",
	ProjectSettings = "PROJECT_SETTINGS",

	AdminOverview = "ADMIN_OVERVIEW",
	AdminBilling = "ADMIN_BILLING",
	AdminUsers = "ADMIN_USERS",

	SuperAdminOverview = "SUPER_ADMIN_OVERVIEW",
	SuperAdminBilling = "SUPER_ADMIN_BILLING",
	SuperAdminUsers = "SUPER_ADMIN_USERS",

	Chat = "CHAT",
	Search = "SEARCH",

	Home = "HOME",
	ProjectCreate = "PROJECT_CREATE",
	Documents = "DOCUMENTS",
	Unauthorized = "UNAUTHORIZED",
	NotFound = "NOT_FOUND",
}

// Display names mapping
export const PAGE_DISPLAY_NAMES: Record<PageName, string> = {
	[PageName.ProjectDocuments]: "Documents",
	[PageName.ProjectCharts]: "Charts",
	[PageName.ProjectSettings]: "Settings",
	[PageName.Chat]: "Chat",
	[PageName.Search]: "Search",

	[PageName.AdminOverview]: "Admin - Overview",
	[PageName.AdminBilling]: "Admin - Billing",
	[PageName.AdminUsers]: "Admin - Users",
	[PageName.SuperAdminOverview]: "Super Admin - Overview",
	[PageName.SuperAdminBilling]: "Super Admin - Billing",
	[PageName.SuperAdminUsers]: "Super Admin - Users",

	[PageName.Home]: "Home",
	[PageName.ProjectCreate]: "New Project",
	[PageName.Documents]: "Documents",
	[PageName.Unauthorized]: "Unauthorized",
	[PageName.NotFound]: "Not Found",
} as const

// Type helpers
export type Pages =
	| PageName.ProjectDocuments
	| PageName.ProjectCharts
	| PageName.ProjectSettings
	| PageName.Chat
	| PageName.Search
	| PageName.AdminOverview
	| PageName.AdminBilling
	| PageName.AdminUsers
	| PageName.SuperAdminOverview
	| PageName.SuperAdminBilling
	| PageName.SuperAdminUsers
	| PageName.Home
	| PageName.ProjectCreate
	| PageName.Documents
	| PageName.Unauthorized
	| PageName.NotFound

// Mapping (list) of all project pages.
// Feel free to adjust this list if additional pages should be considered project pages.
export const PROJECT_PAGES: readonly Pages[] = [
	PageName.ProjectDocuments,
	PageName.ProjectCharts,
	PageName.ProjectSettings,
	PageName.ProjectCreate,
] as const
