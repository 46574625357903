import type { Claim } from "@/types"
import DOMPurify from "dompurify"
import type React from "react"
import { useCallback } from "react"
import { CondensedParagraph } from "../../../components/ui/typography"

interface ClaimComponentProps {
	claim: Claim
	exactMatchText?: string
	reason?: string
	showCitationReasons?: boolean
}

export const ClaimComponent: React.FC<ClaimComponentProps> = ({
	claim,
	exactMatchText,
	reason,
	showCitationReasons = false,
}) => {
	const renderReason = useCallback(() => {
		if (!reason) return null
		return (
			<div className="mt-2 text-sm text-gray-600">
				<CondensedParagraph className="italic">{reason}</CondensedParagraph>
			</div>
		)
	}, [reason])

	const renderCitationLocation = useCallback(() => {
		if (!claim.number) return null
		return (
			<div className="w-[75px] shrink-0 text-sm text-gray-500 whitespace-pre-line">
				Claim {claim.number}
			</div>
		)
	}, [claim.number])

	const renderLimitations = useCallback(() => {
		return claim.limitations.map((limitation, index) => {
			const indentLevel = limitation.label?.split(".").length - 1 || 0
			const indentClass =
				indentLevel === 1
					? "ml-8"
					: indentLevel === 2
						? "ml-16"
						: indentLevel === 3
							? "ml-24"
							: indentLevel >= 4
								? "ml-32"
								: ""

			// sanitize first
			const sanitizedHtml = DOMPurify.sanitize(limitation.html)
			// then highlight if searchQuery
			const finalHtml = exactMatchText
				? highlightHtml(sanitizedHtml, exactMatchText)
				: sanitizedHtml

			return (
				<div key={limitation.id ?? index} className={`mt-1 ${indentClass}`}>
					<div
						// biome-ignore lint/security/noDangerouslySetInnerHtml: we sanitize below
						dangerouslySetInnerHTML={{
							__html: finalHtml,
						}}
					/>
				</div>
			)
		})
	}, [claim.limitations, exactMatchText])

	if (!claim) return null

	// naive highlight
	const highlightHtml = (html: string, query: string): string => {
		const escaped = query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
		const regex = new RegExp(`(${escaped})`, "gi")
		return html.replace(regex, `<span class="bg-yellow-200">$1</span>`) // or your color of choice
	}

	return (
		<div className="group flex items-center hover:cursor-pointer hover:bg-accent/50 mb-2">
			<div className="flex flex-col items-start">
				{renderCitationLocation()}
				<div className="flex flex-col items-start gap-2">
					<CondensedParagraph>{renderLimitations()}</CondensedParagraph>
					{showCitationReasons && renderReason()}
				</div>
			</div>
		</div>
	)
}

export default ClaimComponent
