import { ErrorMessage } from "@/components"
import { Loader } from "@/components"
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import {} from "@/components/ui/toggle"
import { H4 } from "@/components/ui/typography"
import { cn } from "@/lib/utils"
import { useUserStore } from "@/store"
import type { OrganizationSummary } from "@/types"
import { subDays } from "date-fns"
import { ChartColumn, ChartSpline, Table } from "lucide-react"
import { useState } from "react"
import { useAdminUsage } from "../hooks/useAdminUsage"
import { useAdminUsers } from "../hooks/useAdminUsers"
import DocumentsUsageChart from "./DocumentsUsageChart"
import DocumentsUsageTable from "./DocumentsUsageTable"
import ProjectsUsageChart from "./ProjectsUsageChart"
import ProjectsUsageTable from "./ProjectsUsageTable"
import type { FilterOptions } from "./UsageFilterSelects"
import UsageFilterSelects from "./UsageFilterSelects"
import UserSessionsChart from "./UserSessionsChart"
import UserSessionsTable from "./UserSessionsTable"

function AdminUsageCard({
	isSuperAdmin = false,
	adminOrganizations,
}: {
	isSuperAdmin: boolean
	adminOrganizations?: OrganizationSummary[]
}) {
	const { organizationId } = useUserStore()

	// Define default filter state (note: usageType can be "document" or "user")
	const defaultFilter: FilterOptions = {
		dateRange: { from: subDays(new Date(), 30), to: new Date() },
		users: [],
		organization: "",
	}
	const { adminUsers } = useAdminUsers(organizationId)
	const [filters, setFilters] = useState<FilterOptions>(defaultFilter)
	const [viewMode, setViewMode] = useState<"bar" | "line" | "table">("bar")
	const [groupBy, setGroupBy] = useState<"date" | "user" | "organization">("date")

	const { documents, projects, isLoadingUsage, isError } = useAdminUsage(
		isSuperAdmin,
		organizationId,
		filters,
	)

	return (
		<Card>
			<CardHeader className="flex flex-row gap-2 justify-between">
				<H4>Usage</H4>
			</CardHeader>
			<CardContent>
				{/* Note the onValueChange to capture the active tab */}
				<Tabs defaultValue="projects" className="w-full">
					<div className="flex flex-row gap-4 justify-between mb-4">
						<TabsList>
							<TabsTrigger value="projects">Projects</TabsTrigger>
							<TabsTrigger value="documents">Documents</TabsTrigger>
							<TabsTrigger value="users">Users</TabsTrigger>
						</TabsList>

						<div className="flex items-center gap-2">
							{viewMode === "bar" ||
								(viewMode === "table" && (
									<Select
										value={groupBy}
										onValueChange={(value) => setGroupBy(value as "date" | "user")}
									>
										<SelectTrigger className="w-40 h-10">
											<SelectValue placeholder="Group by" />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="date">Group by Date</SelectItem>
											<SelectItem value="user">Group by User</SelectItem>
											{isSuperAdmin && <SelectItem value="organization">Group by Org</SelectItem>}
										</SelectContent>
									</Select>
								))}
							<div className="inline-flex h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground">
								<button
									className={cn(
										"inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
										viewMode === "bar" ? "bg-background text-foreground shadow-sm" : "",
									)}
									type="button"
									onClick={() => setViewMode("bar")}
								>
									<ChartColumn className="w-4 h-4" />
								</button>
								<button
									className={cn(
										"inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
										viewMode === "line" ? "bg-background text-foreground shadow-sm" : "",
									)}
									type="button"
									onClick={() => setViewMode("line")}
								>
									<ChartSpline className="w-4 h-4" />
								</button>
								<button
									className={cn(
										"inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
										viewMode === "table" ? "bg-background text-foreground shadow-sm" : "",
									)}
									type="button"
									onClick={() => setViewMode("table")}
								>
									<Table className="w-4 h-4" />
								</button>
							</div>
							{/* Only show the Group By select when in the Users tab and in bar chart mode */}

							<UsageFilterSelects
								onFiltersChange={setFilters}
								users={adminUsers?.map((user) => user.email)}
								organizations={adminOrganizations?.map((org) => org.name)}
								isSuperAdmin={isSuperAdmin}
							/>
						</div>
					</div>
					<TabsContent value="projects">
						{isLoadingUsage ? (
							<Loader />
						) : isError ? (
							<ErrorMessage />
						) : (
							<div>
								{viewMode === "table" ? (
									<ProjectsUsageTable projects={projects} />
								) : (
									<ProjectsUsageChart
										projects={projects}
										isBarChart={viewMode === "bar"}
										groupBy={groupBy}
									/>
								)}
							</div>
						)}
					</TabsContent>
					<TabsContent value="documents">
						{isLoadingUsage ? (
							<Loader />
						) : isError ? (
							<ErrorMessage />
						) : (
							<div className="flex flex-col gap-4">
								{viewMode === "table" ? (
									<DocumentsUsageTable documents={documents} groupBy={groupBy} />
								) : (
									<DocumentsUsageChart
										documents={documents}
										isBarChart={viewMode === "bar"}
										groupBy={groupBy}
									/>
								)}
							</div>
						)}
					</TabsContent>
					<TabsContent value="users">
						<div className="flex flex-col gap-4">
							{viewMode === "table" ? (
								<UserSessionsTable
									organizationId={organizationId}
									filters={filters}
									isSuperAdmin={isSuperAdmin}
								/>
							) : (
								<UserSessionsChart
									isSuperAdmin={isSuperAdmin}
									organizationId={organizationId}
									filters={filters}
									isBarChart={viewMode === "bar"}
									groupBy={groupBy}
								/>
							)}
						</div>
					</TabsContent>
				</Tabs>
			</CardContent>
		</Card>
	)
}

export default AdminUsageCard
