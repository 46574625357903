import type React from "react"
/**
 * Helper to merge multiple refs into one.
 * @param refs - The refs to merge.
 * @returns A ref callback that sets the refs.
 */
export function mergeRefs<T>(
	...refs: (React.Ref<T> | undefined)[]
): React.RefCallback<T> {
	return (node: T) => {
		for (const ref of refs) {
			if (typeof ref === "function") {
				ref(node)
			} else if (ref && typeof ref === "object") {
				// @ts-ignore
				ref.current = node
			}
		}
	}
}
