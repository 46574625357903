/*
 * Copyright AndAI, Inc. 2025. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc.
 * and is protected as a trade secret.
 */

import { Loader } from "@/components"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useProjectContext } from "@/context/ProjectContext"
import { useClaims, useDocumentNaming } from "@/hooks"
import { PAGE_DISPLAY_NAMES, PageName } from "@/types/pages"
import type React from "react"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import ProjectPage from "../../components/projects/ProjectPage"
import { useProjectInfringementDocuments } from "../documents/hooks/useProjectInfringementDocuments"
import { useProjectPriorArtDocuments } from "../documents/hooks/useProjectPriorArtDocuments"
import ProjectChartsTable from "./components/ProjectChartsTable"
import ChartsContentionAnalysis from "./components/contention/ChartsContentionAnalysis"
import ArchivedChartsModal from "./components/modals/ArchivedChartsModal"
import ExportChartsModal from "./components/modals/ExportChartsModal"
import NewChartModal from "./components/modals/NewChartModal"
import { ChartSummaries } from "./components/summary/ChartSummaries"
import useProjectChartsData from "./hooks/queries/useProjectChartsData"
/**
 * @description Renders the invalidity table page
 */
const InvalidityChartsOverviewPage: React.FC = () => {
	const navigate = useNavigate()
	const { projectId, subjectId } = useProjectContext()
	const { getDocumentName } = useDocumentNaming()
	const { claimLimitations } = useClaims(subjectId)
	const { activeCharts, archivedCharts } = useProjectChartsData(projectId, subjectId)
	const { priorArtDocuments } = useProjectPriorArtDocuments()
	const { infringementDocuments } = useProjectInfringementDocuments()

	const [showExportModal, setShowExportModal] = useState<boolean>(false)
	const [showNewChartModal, setShowNewChartModal] = useState(false)
	const [showArchivedChartsModal, setShowArchivedChartsModal] = useState(false)

	const selectChart = useCallback(
		(chartId: string, claimLimitationId?: string) => {
			navigate(`/project/${projectId}/charts/${subjectId}/${chartId}`, {
				state: { claimLimitationIdFromSummary: claimLimitationId },
			})
		},
		[navigate, projectId, subjectId],
	)

	// Show loader while project data is being fetched
	if (!claimLimitations || !activeCharts) {
		return (
			<ProjectPage pageName={PageName.ProjectCharts}>
				<div className="flex justify-center items-center flex-col mt-12">
					<Loader message="Loading project data..." />
				</div>
			</ProjectPage>
		)
	}

	return (
		<ProjectPage
			pageName={`${PAGE_DISPLAY_NAMES[PageName.ProjectCharts]} - ${getDocumentName(subjectId)}`}
		>
			<div className="relative flex flex-col h-[calc(100vh)]">
				<Tabs defaultValue="charts">
					<div className="border-b border-border h-[45px]">
						<div className="flex h-full items-center w-full px-2">
							<Label className="px-3 mb-0 whitespace-nowrap">
								Analysis for {getDocumentName(subjectId)}
							</Label>
							<TabsList className="h-8">
								<TabsTrigger className="h-6" value="charts">
									Charts
								</TabsTrigger>
								<TabsTrigger className="h-6" value="contention">
									Contentions
								</TabsTrigger>
							</TabsList>
						</div>
					</div>

					<TabsContent value="charts" className="px-2">
						{activeCharts.length === 0 ? (
							<div>
								<div className="w-full p-4 text-center">This project has no charts.</div>
								{(priorArtDocuments && priorArtDocuments.length > 0) ||
									(infringementDocuments && infringementDocuments.length > 0 && (
										<Button onClick={() => setShowNewChartModal(true)}>Create a new chart</Button>
									))}
							</div>
						) : (
							<>
								<div className="sticky top-0 z-10 bg-background">
									<ChartSummaries
										projectCharts={activeCharts}
										handleSelect={selectChart}
										claimLimitations={claimLimitations}
									/>
								</div>
								<ProjectChartsTable
									projectCharts={activeCharts}
									onNewChart={() => setShowNewChartModal(true)}
									onShowArchivedCharts={
										archivedCharts?.length > 0
											? () => setShowArchivedChartsModal(true)
											: undefined
									}
									onShowExportModal={() => setShowExportModal(true)}
									selectChart={selectChart}
								/>
							</>
						)}
					</TabsContent>
					<TabsContent value="contention">
						<ChartsContentionAnalysis />
					</TabsContent>
				</Tabs>
			</div>

			<ExportChartsModal
				open={showExportModal}
				handleClose={() => setShowExportModal(false)}
				activeCharts={activeCharts}
			/>
			<NewChartModal
				open={showNewChartModal}
				handleClose={() => setShowNewChartModal(false)}
			/>
			<ArchivedChartsModal
				archivedCharts={archivedCharts}
				open={showArchivedChartsModal}
				handleClose={() => setShowArchivedChartsModal(false)}
			/>
		</ProjectPage>
	)
}

export default InvalidityChartsOverviewPage
