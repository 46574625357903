/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

import type { DocumentRole } from "@/types/documents"
import type { Bbox } from "./invalidity"

export enum StatusType {
	ERROR = "error",
	SUCCESS = "success",
	UPLOADING = "uploading",
	UPLOADED = "uploaded",
	PROCESSING = "processing",
	WARNING = "warning",
	INFO = "info",
	CHARTING = "charting",
	CONTEXT = "generating context",
	UNKNOWN = "unknown",
}

export enum S3Operation {
	GET = "get_object",
	PUT = "put_object",
}

export interface AlertMessage {
	key: string
	message: string
	severity: StatusType
}

export interface ApiResponse<T = any> {
	success: boolean
	data?: T
	message?: string
	error?: any
	status?: number
	detail?: string
}

export enum EnvironmentTypes {
	PROD = "PROD",
	TEST = "TEST",
	DEV = "DEV",
}

export interface LoadingGroup {
	key: string // id
	name: string
	processType: string
	messages: LoadingGroupItem[]
	// status: StatusType;
}

export interface LoadingGroupItem {
	name: string
	status: StatusType
	detail?: string
	displayName?: string
}

// Standalone loading message
export interface LoadingMessage {
	key: string
	message: string
}

export interface Process {
	id: string
	type: string
	status: "pending" | "completed" | "error"
	projectIds?: string[]
}

export enum ProcessType {
	ADD_PRIOR_ART = "add prior art",
	GENERATE_CONTEXT = "generate context",
	CREATE_PROJECT = "create project",
	RECHART = "rechart",
	REPROCESS = "reprocess",
	ADD_SUBJECT = "add subject",
	DOWNLOAD_CHART = "download chart",
	ADD_OFFICE_ACTION = "add office action",
	ADD_INFRINGEMENT = "add infringement",
}

export const conflictingChartProcesses = [
	ProcessType.DOWNLOAD_CHART,
	ProcessType.ADD_PRIOR_ART,
	ProcessType.RECHART,
	ProcessType.REPROCESS,
	ProcessType.GENERATE_CONTEXT,
]
export const conflictingContextProcesses = [
	ProcessType.GENERATE_CONTEXT,
	ProcessType.RECHART,
	ProcessType.REPROCESS,
]

export const conflictingReferenceProcesses = [
	ProcessType.ADD_PRIOR_ART,
	ProcessType.RECHART,
	ProcessType.REPROCESS,
]

export enum CitationPolicy {
	OVERWRITE = "overwrite",
	APPEND = "append",
	VERIFIED = "verified",
}

export enum DocumentToProcessType {
	OFFICE_ACTION = "OFFICE_ACTION",
	STANDARD = "STANDARD",
	PATENT = "PATENT",
	NPL = "NPL",
}

export interface UnpublishedPatentEntry {
	claims?: string[]
	abstract?: string
	body?: string[]
	title: string
}

export interface ProcessSettings {
	reparse: boolean
	chart: boolean
	claims_in_charting: boolean
	citation_policy: CitationPolicy
}

export interface DocumentToProcess {
	doc_type: DocumentToProcessType
	doc_role: DocumentRole
	publication_number?: string
	unpublished_patent?: UnpublishedPatentEntry
	s3_key?: string
	file_name?: string
	settings?: ProcessSettings
}

// Update DocumentChunk type to include the properties we need
export interface DocumentChunk {
	id: string
	text: string
	bboxes: string | Bbox | (string | Bbox)[]
	location: string
	type?: "text" | "figure" | "equation" | "table" | "code" // Add type property
	isChartable?: boolean // Add chartable property
}

export enum ExportType {
	PITCH_SUMMARY = "pitch_summary",
	COVER_PLEADING = "cover_pleading",
}
