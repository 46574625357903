import type { DocumentRole } from "@/types"
import { DocumentRole as DocumentRoleEnum } from "@/types"
import type { QueryClient, QueryKey } from "@tanstack/react-query"
import { PARTIAL_INVALIDITY_KEYS, QUERY_KEYS } from "./keys"

/**
 * Invalidates multiple queries based on the provided query keys.
 * Use this utility when you have a collection of queries whose associated data
 * needs recalculating or refetching (e.g., changes in user info, project data, etc.).
 *
 * @param queryClient - The React Query client instance
 * @param queryKeys - An array of queries (keys) to invalidate
 * @param exact - Whether the query key match needs to be exact
 */
export const invalidateMultipleQueries = (
	queryClient: QueryClient,
	queryKeys: unknown[],
	exact = false,
) => {
	for (const queryKey of queryKeys) {
		queryClient.invalidateQueries({ queryKey, exact })
	}
}

/* -------------------------------------------------------------------------- */
/*                       Admin/Superadmin related queries                     */
/* -------------------------------------------------------------------------- */

export const invalidateUserRelatedQueries = (
	queryClient: QueryClient,
	orgId?: string,
) => {
	invalidateMultipleQueries(queryClient, [
		QUERY_KEYS.admin.users(orgId),
		QUERY_KEYS.admin.overview(orgId),
	])
}

export const invalidateOrgRelatedQueries = (
	queryClient: QueryClient,
	orgId: string,
) => {
	invalidateMultipleQueries(queryClient, [
		QUERY_KEYS.admin.overview(orgId),
		QUERY_KEYS.admin.usage(orgId),
		QUERY_KEYS.admin.billing(orgId),
		QUERY_KEYS.admin.sessions(orgId),
	])
}

export const invalidateSuperAdminQueries = (queryClient: QueryClient) => {
	invalidateMultipleQueries(queryClient, [
		QUERY_KEYS.superAdmin.overview,
		QUERY_KEYS.superAdmin.usage,
		QUERY_KEYS.superAdmin.billing,
		QUERY_KEYS.superAdmin.organizations,
	])
}

/* -------------------------------------------------------------------------- */
/*                       User project related queries                         */
/* -------------------------------------------------------------------------- */

export const invalidateUserProjectRelatedQueries = (queryClient: QueryClient) => {
	const list = [QUERY_KEYS.projects.active(), QUERY_KEYS.projects.archived()]

	invalidateMultipleQueries(queryClient, list)
}

/* -------------------------------------------------------------------------- */
/*                       Project related queries                              */
/* -------------------------------------------------------------------------- */

export const invalidateSubjectChartRelatedQueries = (
	queryClient: QueryClient,
	projectId: string,
	subjectId: string,
	chartId: string,
	limitationIds?: string[],
) => {
	const queryKeys: QueryKey[] = [
		QUERY_KEYS.project.charts.chartSummaryData(projectId, subjectId, chartId),
		QUERY_KEYS.project.charts.chartSummaryData(projectId, subjectId, null),
		QUERY_KEYS.project.subject.claimStrengths(projectId, subjectId),
		QUERY_KEYS.project.charts.matrix(projectId),
	]
	if (limitationIds) {
		for (const limitationId of limitationIds) {
			queryKeys.push(
				QUERY_KEYS.project.charts.limitationChartData(
					projectId,
					subjectId,
					chartId,
					limitationId,
				),
			)
		}
	}

	invalidateMultipleQueries(queryClient, queryKeys)
}

export const invalidateSubjectChartsOverviewData = (
	queryClient: QueryClient,
	projectId: string,
	subjectId: string,
) => {
	invalidateMultipleQueries(queryClient, [
		QUERY_KEYS.project.charts.matrix(projectId),
		QUERY_KEYS.project.charts.active(projectId, subjectId),
		QUERY_KEYS.project.charts.inactive(projectId, subjectId),
		QUERY_KEYS.project.charts.chartSummaryData(projectId, subjectId),
		QUERY_KEYS.project.charts.suggestedCombinationCharts(projectId, subjectId),
	])
}

export const invalidateDocumentRelatedQueries = (
	queryClient: QueryClient,
	documentIds: string[],
	documentRole: DocumentRole,
	projectId: string | null,
) => {
	const queryKeys: QueryKey[] = []
	queryKeys.push(getDocumentRoleQueryKey(documentRole, projectId))

	for (const documentId of documentIds) {
		queryKeys.push(QUERY_KEYS.project.document.full(projectId, documentId))
	}

	invalidateMultipleQueries(queryClient, queryKeys)
}

export const invalidateDocumentChunkRelatedQueries = (
	queryClient: QueryClient,
	documentId: string,
	projectId: string,
) => {
	invalidateProjectChartsData(queryClient, projectId)

	queryClient.invalidateQueries({
		queryKey: QUERY_KEYS.project.document.full(projectId, documentId),
	})
}

export const invalidateSettingsRelatedQueries = (
	queryClient: QueryClient,
	projectId: string,
) => {
	invalidateMultipleQueries(queryClient, [
		QUERY_KEYS.project.settings(projectId),
		QUERY_KEYS.project.metadata(projectId),
	])
}

export const invalidateClaimRelatedQueries = (
	queryClient: QueryClient,
	projectId: string,
	documentId: string,
) => {
	invalidateMultipleQueries(queryClient, [
		QUERY_KEYS.project.subject.claims(projectId, documentId),
		QUERY_KEYS.project.subject.claimLimitationGroups(projectId, documentId),
		QUERY_KEYS.project.subject.claimStrengths(projectId, documentId),
		QUERY_KEYS.project.document.full(projectId, documentId),
	])
	invalidateProjectChartsData(queryClient, projectId)
}

export const invalidateChatQueries = (
	queryClient: QueryClient,
	projectId: string | null,
	chatId?: string,
) => {
	// Always invalidate the metadata list
	invalidateMultipleQueries(queryClient, [QUERY_KEYS.project.chat.metadata(projectId)])

	// If we have a specific chat ID, invalidate that chat's data too
	if (chatId) {
		invalidateMultipleQueries(queryClient, [QUERY_KEYS.project.chat.full(chatId)])
	}
}

export const getDocumentRoleQueryKey = (
	documentRole: DocumentRole,
	projectId: string,
) => {
	if (documentRole === DocumentRoleEnum.SUBJECT) {
		return QUERY_KEYS.project.documents.subject(projectId)
	}
	if (documentRole === DocumentRoleEnum.PRIOR_ART) {
		return QUERY_KEYS.project.documents.priorArt(projectId)
	}
	if (documentRole === DocumentRoleEnum.CONTEXT) {
		return QUERY_KEYS.project.documents.context(projectId)
	}
	if (documentRole === DocumentRoleEnum.OFFICE_ACTION) {
		return QUERY_KEYS.project.documents.officeActions(projectId)
	}
	if (documentRole === DocumentRoleEnum.INFRINGEMENT) {
		return QUERY_KEYS.project.documents.infringement(projectId)
	}
}

const invalidateProjectChartsData = (queryClient: QueryClient, projectId: string) => {
	queryClient.invalidateQueries({
		queryKey: PARTIAL_INVALIDITY_KEYS.projectChartsData(projectId),
		exact: false,
	})
}
export const invalidateProjectDocuments = (
	queryClient: QueryClient,
	projectId: string,
) => {
	queryClient.invalidateQueries({
		queryKey: PARTIAL_INVALIDITY_KEYS.projectDocuments(projectId),
		exact: false,
	})
}
