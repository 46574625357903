/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import UniversalSidebar from "@/components/sidebar/NonProjectSidebarContent"
import { Button } from "@/components/ui/button"
import { H4 } from "@/components/ui/typography"
import usePlatformUtils from "@/hooks/usePlatformUtils"
import { MainContentBox, PageContainer } from "@/styled"
import { useAuthInfo } from "@propelauth/react"
import type React from "react"
import { useNavigate } from "react-router-dom"

/**
 * @description Renders the page not found page
 */
const PageNotFound: React.FC = () => {
	const { usePageTitle } = usePlatformUtils()
	usePageTitle("Page Not Found")

	const { isLoggedIn } = useAuthInfo()
	const navigate = useNavigate()

	return (
		<PageContainer>
			<div>
				{isLoggedIn && <UniversalSidebar />}
				<MainContentBox>
					<div className="flex flex-col items-center justify-center">
						<H4>The page you're looking for can't be found.</H4>

						{isLoggedIn ? (
							<Button onClick={() => navigate("/home")} aria-label="go home">
								Go Home
							</Button>
						) : (
							<Button onClick={() => navigate("/signin")} aria-label="sign in">
								Sign In
							</Button>
						)}
					</div>
				</MainContentBox>
			</div>
		</PageContainer>
	)
}

export default PageNotFound
