/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { SidebarPage } from "@/components"
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable"
import { useChatContext } from "@/context/ChatContext"
import { useProjectContext } from "@/context/ProjectContext"
import { PanelChat } from "@/features/chat/PanelChat"
import { usePlatformUtils, useProjectMetadata } from "@/hooks"
import { PAGE_DISPLAY_NAMES, type Pages } from "@/types/pages"
import type React from "react"
import { useMemo } from "react"
import type { ReactNode } from "react"

import DocumentContent from "../documents/DocumentContent"

/**
 * @description The project page component
 * @param {ReactNode} children - The children of the component
 */
interface ProjectPageProps {
	pageName: Pages | string
	children: ReactNode
}

const ProjectPage: React.FC<ProjectPageProps> = ({ children, pageName }) => {
	const { usePageTitle } = usePlatformUtils()
	const { projectName } = useProjectMetadata()
	const { documentViewerDocumentId, documentViewerCitationText } = useProjectContext()
	const { isPanelChatOpen, setIsPanelChatOpen } = useChatContext()

	usePageTitle(
		projectName
			? `${projectName} - ${PAGE_DISPLAY_NAMES[pageName]}`
			: PAGE_DISPLAY_NAMES[pageName] || pageName,
	)

	// Use useMemo to ensure that the children container remains stable
	const childrenContainer = useMemo(() => {
		return <div className="flex flex-col w-full h-full">{children}</div>
	}, [children])

	// Use useMemo to ensure that the document content component remains stable
	const documentContent = useMemo(() => {
		if (!documentViewerDocumentId) return null
		return (
			<DocumentContent
				documentId={documentViewerDocumentId}
				citationText={documentViewerCitationText}
				inSidePanel={true}
			/>
		)
	}, [documentViewerDocumentId, documentViewerCitationText])

	// Use useMemo to ensure that the panel chat component remains stable
	const panelChat = useMemo(() => {
		if (!isPanelChatOpen) return null
		return <PanelChat asPanel={true} />
	}, [isPanelChatOpen])

	return (
		<SidebarPage pageName={pageName}>
			{/* Always render a ResizablePanelGroup, but conditionally show panels */}
			<ResizablePanelGroup className="flex h-full w-full" direction="horizontal">
				{/* Always render the main content panel */}
				<ResizablePanel
					defaultSize={documentViewerDocumentId ? 50 : isPanelChatOpen ? 70 : 100}
					minSize={30}
				>
					{childrenContainer}
				</ResizablePanel>

				{/* Conditionally render document viewer panel */}
				{documentViewerDocumentId && (
					<>
						<ResizableHandle withHandle orientation="vertical" />
						<ResizablePanel defaultSize={50} minSize={30}>
							{documentContent}
						</ResizablePanel>
					</>
				)}

				{/* Conditionally render chat panel */}
				{!documentViewerDocumentId && isPanelChatOpen && (
					<>
						<ResizableHandle withHandle orientation="vertical" />
						<ResizablePanel
							defaultSize={30}
							minSize={15}
							onResize={(size) => {
								if (size < 15) {
									setIsPanelChatOpen(false)
								}
							}}
						>
							{panelChat}
						</ResizablePanel>
					</>
				)}
			</ResizablePanelGroup>
		</SidebarPage>
	)
}

export default ProjectPage
