/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { api } from "@/api"
import { useProjectContext } from "@/context/ProjectContext"
import { useProjectChartsData } from "@/features/charts/hooks/queries/useProjectChartsData"
import useAllProjectDocuments from "@/features/documents/hooks/useAllProjectDocuments"
import { useProjectMetadata } from "@/hooks"
import useApi from "@/hooks/useApi"
import useFileUtils from "@/hooks/useFileUtils"
import { useAppStateStore } from "@/store"
import { ExportType } from "@/types"
import type { ApiResponse, InvalidityChart, InvalidityType } from "@/types"
import type { SubjectToDocument } from "@/types/documents"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

/**
 * @description Hook for handling cover pleading (summary, section 112, etc.) operations
 * @returns {object} - The cover pleading operations
 */
const useCoverPleading = () => {
	const queryClient = useQueryClient()
	const { handleError } = useApi()
	const { addErrorMessage } = useAppStateStore()
	const { projectName } = useProjectMetadata()
	const { subjectId, projectId } = useProjectContext()
	const { activeCharts } = useProjectChartsData(projectId, subjectId)
	const { priorArtIds } = useAllProjectDocuments()
	const { downloadFile } = useFileUtils()

	const updateCoverSummariesMutation = useMutation({
		mutationFn: ({
			documentId,
			documentCover,
		}: {
			documentId: string
			documentCover: string
		}) => api.updateCoverSummaries(subjectId, documentId, documentCover),
		onError: (error) => {
			console.error("Error updating cover summaries:", error)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.cover.summaries(projectId, subjectId),
			})
		},
	})

	const update102AnalysisMutation = useMutation({
		mutationFn: ({
			chartId,
			analysis,
		}: {
			chartId: string
			analysis: string
		}) => api.update102Analysis(chartId, analysis),
		onError: (error) => {
			console.error("Error updating 102 analysis:", error)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.cover.analysis102(projectId, subjectId),
			})
		},
	})

	const update103AnalysisMutation = useMutation({
		mutationFn: ({
			chartId,
			analysis,
		}: {
			chartId: string
			analysis: string
		}) => api.update103Analysis(chartId, analysis),
		onError: (error) => {
			console.error("Error updating 103 analysis:", error)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.cover.analysis103(projectId, subjectId),
			})
		},
	})

	const update112AnalysisMutation = useMutation({
		mutationFn: ({
			chartId,
			analysis,
		}: {
			chartId: string
			analysis: string
		}) => api.update112Analysis(chartId, analysis),
		onError: (error) => {
			console.error("Error updating 112 analysis:", error)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.cover.analysis112(projectId, subjectId),
			})
		},
	})

	// Helper functions to filter charts by type
	const getSingleReferenceCharts = (): InvalidityChart[] => {
		if (!activeCharts) return []
		return activeCharts.filter((chart) => {
			// TODO: fix, hacky way to ensure all prior art this should be done on BE
			const isAllPriorArt = chart.documentIds?.every((docId) =>
				priorArtIds.includes(docId),
			)
			// Filter for charts with exactly one document, excluding self charts
			return (
				chart.active &&
				chart.documentIds?.length === 1 &&
				!chart.tags?.includes("Self") &&
				isAllPriorArt
			)
		})
	}

	const getCombinationCharts = (): InvalidityChart[] => {
		if (!activeCharts) return []
		return activeCharts.filter((chart) => {
			// TODO: fix, hacky way to ensure all prior art this should be done on BE
			const isAllPriorArt = chart.documentIds?.every((docId) =>
				priorArtIds.includes(docId),
			)
			// Filter for charts with multiple documents and all docs are PRIOR ART; furthermore, ignore charts with the "Self" tag if present.
			return (
				chart.active &&
				chart.documentIds?.length > 1 &&
				(!chart.tags || !chart.tags.includes("Self")) &&
				isAllPriorArt
			)
		})
	}

	const getSelfCharts = (): InvalidityChart[] => {
		if (!activeCharts) return []
		return activeCharts.filter((chart) => {
			// Filter for charts with Self tag
			return chart.active && chart.tags?.includes("Self")
		})
	}

	const getCoverExport = async (
		subjectId: string,
		selectedDocumentIds: string[],
		selectedAnalysisTypes: InvalidityType[],
	): Promise<ApiResponse> => {
		try {
			const response: any = await api.getCoverExport(
				subjectId,
				selectedDocumentIds,
				selectedAnalysisTypes,
			)

			if (response.url) {
				await downloadFile(response.url, projectName, ExportType.COVER_PLEADING)
			} else {
				if (process.env.NODE_ENV !== "production") {
					console.error("No download URL found")
				}
				addErrorMessage("Error downloading file. Try again later.")
				return
			}

			return { success: true, data: response as any, status: 200 }
		} catch (error) {
			addErrorMessage("Error downloading file. Try again later.")
			return handleError(error, "Error fetching project summary export")
		}
	}

	const getPitchSummaryExport = async (
		plaintiffName: string,
		defendantName: string,
		defendantProduct: string,
		bragStatementPersonal: string,
		bragStatementFirm: string,
		closingStatement: string,
	): Promise<ApiResponse> => {
		try {
			const response: any = await api.getPitchSummaryExport(
				projectId,
				plaintiffName,
				defendantName,
				defendantProduct,
				bragStatementPersonal,
				bragStatementFirm,
				closingStatement,
			)

			if (response.url) {
				await downloadFile(response.url, projectName, ExportType.PITCH_SUMMARY)
			} else {
				if (process.env.NODE_ENV !== "production") {
					console.error("No memo URL found")
				}
				addErrorMessage("Error generating pitch summary memo. Try again later.")
				return
			}

			return { success: true, data: response as any, status: 200 }
		} catch (error) {
			addErrorMessage("Error generating pitch summary memo. Try again later.")
			return handleError(error, "Error generating pitch summary memo")
		}
	}

	const generateSummariesMutation = useMutation({
		mutationKey: MUTATION_KEYS.cover.generateSummaries(),
		mutationFn: async ({
			documentIds,
		}: {
			documentIds: string[]
		}) => {
			// Process references in chunks of 10
			for (let i = 0; i < documentIds.length; i += 10) {
				const chunk = documentIds.slice(i, i + 10)
				await api.generatePriorArtSummaries(subjectId, chunk)
			}
		},
		onError: (error) => {
			console.error("Error generating reference summaries:", error)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.documents.priorArt(projectId),
			})
		},
	})

	const generate102AnalysisMutation = useMutation({
		mutationKey: MUTATION_KEYS.cover.generate102Analysis(),
		mutationFn: async ({
			chartIds,
		}: {
			chartIds: string[]
		}): Promise<boolean> => {
			const generationStatuses = await api.generate102Analysis(subjectId, chartIds)
			return generationStatuses.statuses.every((status) => status.success)
		},
		onError: (error) => {
			console.error("Error generating 102 analysis:", error)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.cover.analysis102(projectId, subjectId),
			})
		},
	})

	const generate103AnalysisMutation = useMutation({
		mutationKey: MUTATION_KEYS.cover.generate103Analysis(),
		mutationFn: async ({
			chartIds,
		}: {
			chartIds: string[]
		}): Promise<boolean> => {
			const generationStatuses = await api.generate103Analysis(subjectId, chartIds)
			return generationStatuses.statuses.every((status) => status.success)
		},
		onError: (error) => {
			console.error("Error generating 103 analysis:", error)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.cover.analysis103(projectId, subjectId),
			})
		},
	})

	const generate112AnalysisMutation = useMutation({
		mutationKey: MUTATION_KEYS.cover.generate112Analysis(),
		mutationFn: async (): Promise<boolean> => {
			const generationStatuses = await api.generate112Analysis(subjectId)
			return generationStatuses.statuses.every((status) => status.success)
		},
		onError: (error) => {
			console.error("Error generating 112 analysis:", error)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.cover.analysis112(projectId, subjectId),
			})
		},
	})

	const CoverSummariesQuery = useQuery({
		queryKey: QUERY_KEYS.project.cover.summaries(projectId, subjectId),
		queryFn: () => api.getCoverSummaries(subjectId),
		enabled: !!subjectId && !!projectId,
	})

	const AnticipationAnalysisQuery = useQuery({
		queryKey: QUERY_KEYS.project.cover.analysis102(projectId, subjectId),
		queryFn: () => api.get102Analysis(subjectId),
		enabled: !!subjectId && !!projectId,
	})

	const ObviousnessAnalysisQuery = useQuery({
		queryKey: QUERY_KEYS.project.cover.analysis103(projectId, subjectId),
		queryFn: () => api.get103Analysis(subjectId),
		enabled: !!subjectId && !!projectId,
	})

	const SpecificationAnalysisQuery = useQuery({
		queryKey: QUERY_KEYS.project.cover.analysis112(projectId, subjectId),
		queryFn: () => api.get112Analysis(subjectId),
		enabled: !!subjectId && !!projectId,
	})

	return {
		getCoverExport,
		getPitchSummaryExport,
		generateSummaries: generateSummariesMutation.mutate,
		isGeneratingSummaries: generateSummariesMutation.isPending,
		generate102Analysis: generate102AnalysisMutation.mutateAsync,
		isGenerating102Analysis: generate102AnalysisMutation.isPending,
		generate103Analysis: generate103AnalysisMutation.mutateAsync,
		isGenerating103Analysis: generate103AnalysisMutation.isPending,
		generate112Analysis: generate112AnalysisMutation.mutateAsync,
		isGenerating112Analysis: generate112AnalysisMutation.isPending,
		update102Analysis: update102AnalysisMutation.mutate,
		isUpdating102Analysis: update102AnalysisMutation.isPending,
		update103Analysis: update103AnalysisMutation.mutate,
		isUpdating103Analysis: update103AnalysisMutation.isPending,
		update112Analysis: update112AnalysisMutation.mutate,
		isUpdating112Analysis: update112AnalysisMutation.isPending,
		anticipationAnalysis: AnticipationAnalysisQuery.data,
		obviousnessAnalysis: ObviousnessAnalysisQuery.data,
		specificationAnalysis: SpecificationAnalysisQuery.data,
		updateCoverSummaries: updateCoverSummariesMutation.mutate,
		isUpdatingCoverSummaries: updateCoverSummariesMutation.isPending,
		getSingleReferenceCharts,
		getCombinationCharts,
		getSelfCharts,
		coverSummaries: CoverSummariesQuery.data as SubjectToDocument[],
	}
}

export default useCoverPleading
