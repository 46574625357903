"use client"

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
} from "@/components/ui/dropdown-menu"
import type { Variable } from "@/utils/variables"
import type React from "react"

export interface VariableMenuProps {
	variables: Variable[]
	onSelect: (variable: Variable) => void
	onSelectVariant?: (variable: Variable, variantId: string) => void
	documentIds?: string[]
	style?: React.CSSProperties
}

/**
 * VariableMenuItems renders just the inner list of variable items.
 */
export const VariableMenuItems: React.FC<Omit<VariableMenuProps, "style">> = ({
	variables,
	onSelect,
	onSelectVariant,
	documentIds,
}) => {
	return (
		<>
			{variables.map((variable) => {
				// For variables that have variants, always show the submenu
				if (variable.variants && variable.variants.length > 0) {
					return (
						<DropdownMenuSub key={variable.id}>
							<DropdownMenuSubTrigger>{variable.label}</DropdownMenuSubTrigger>
							<DropdownMenuSubContent>
								{variable.variants.map((variant) => (
									<DropdownMenuItem
										key={variant.id}
										onClick={() => {
											if (onSelectVariant) {
												// Create a modified variable with the variant's label
												const variantVariable = {
													...variable,
													id: variant.id,
													label: variant.label,
												}
												onSelectVariant(variantVariable, variant.id)
											}
										}}
									>
										{variant.label}
									</DropdownMenuItem>
								))}
							</DropdownMenuSubContent>
						</DropdownMenuSub>
					)
				}

				// Variables without variants are rendered normally
				return (
					<DropdownMenuItem key={variable.id} onClick={() => onSelect(variable)}>
						{variable.label}
					</DropdownMenuItem>
				)
			})}
		</>
	)
}

/**
 * VariableMenu is the fully wrapped dropdown menu using our shared items.
 */
export const VariableMenu: React.FC<VariableMenuProps> = ({
	variables,
	onSelect,
	onSelectVariant,
	style,
}) => {
	return (
		<DropdownMenu open>
			<DropdownMenuContent style={style} className="z-10">
				<VariableMenuItems
					variables={variables}
					onSelect={onSelect}
					onSelectVariant={onSelectVariant}
				/>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}

export default VariableMenu
