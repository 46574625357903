import { ErrorMessage, Loader } from "@/components"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import TooltipButton from "@/components/ui/tooltip-button"
import { DocumentContextProvider } from "@/context/DocumentContext"
import { useProjectContext } from "@/context/ProjectContext"
import { useUpdateDocumentMetadata } from "@/features/documents/hooks/useUpdateDocumentMetadata"
import { useDocumentContextData, useFullDocument } from "@/hooks"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import { useAppStateStore } from "@/store"
import { type DocumentContext, DocumentType } from "@/types"
import { ScrollArea } from "@radix-ui/react-scroll-area"
import { ChevronLeft, Maximize, XIcon } from "lucide-react"
import type React from "react"
import { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import DocumentContextComponent from "./DocumentContextComponent"
import DocumentDetailLayout from "./DocumentDetailLayout"

interface DocumentContentProps {
	documentId: string
	startDisplayPDF?: boolean
	onChangePDFVisibility?: (visible: boolean) => void
	inSidePanel?: boolean
	citationText?: string
}

const defaultContextData = {
	expertKnowledge: {
		fieldDescription: "",
		requiredEducation: "",
		yearsExperience: 0,
		technicalSkills: [],
	},
	termsAndDefinitions: [] as { term: string; definition: string }[],
	importantFacts: [] as {
		category: "Technical" | "Scope" | "Implementation" | "None"
		description: string
	}[],
	summary: "",
	searchContext: {
		queryInstructions: [],
		filterSteps: [],
	},
}

const DocumentContent: React.FC<DocumentContentProps> = ({
	documentId,
	startDisplayPDF = false,
	onChangePDFVisibility,
	inSidePanel = false,
	citationText,
}) => {
	const navigate = useNavigate()
	const { getDocumentName } = useDocumentNaming()
	const { document, isLoading, isError } = useFullDocument(documentId)
	const { updateDocumentMetadata } = useUpdateDocumentMetadata()
	const { documentViewerChunkId } = useProjectContext()
	const { context, updateDocumentContext } = useDocumentContextData(documentId)
	const { addSuccessMessage, addErrorMessage } = useAppStateStore()

	const isPatent = useMemo(() => {
		return document?.type === DocumentType.PATENT
	}, [document?.type])

	const [showPdf, setShowPdf] = useState<boolean>(() => {
		// Always show PDF for non-patent docs, else respect startDisplayPDF
		return !inSidePanel && (!isPatent || startDisplayPDF)
	})

	const [isEditing, setIsEditing] = useState<boolean>(false)
	const [isRenamingName, setIsRenamingName] = useState(false)
	const [newName, setNewName] = useState(getDocumentName(documentId) || "")
	const contextData = useMemo(() => {
		return context || defaultContextData
	}, [context])
	const [editedContextData, setEditedContextData] = useState<DocumentContext | null>(
		null,
	)
	const [activeTab, setActiveTab] = useState("details")

	// Handler to save the new name.
	const handleSaveName = async () => {
		if (newName.trim() !== "" && newName !== getDocumentName(document.id)) {
			await updateDocumentMetadata({
				documentIds: [document.id],
				documentRole: document.documentRole,
				updatePayload: { nickname: newName },
			})
		}
		setIsRenamingName(false)
	}

	// Get the setters and projectId from the Project Context.
	const {
		projectId,
		setDocumentViewerDocumentId,
		setDocumentViewerChunkId,
		setDocumentViewerCitationText,
	} = useProjectContext()

	// Helper to clear the document viewer info held in Project Context.
	const clearProjectContext = () => {
		setDocumentViewerDocumentId(null)
		setDocumentViewerChunkId(null)
		setDocumentViewerCitationText(null)
	}

	// Collapse — simply clears the project context.
	const handleCollapse = () => {
		clearProjectContext()
	}

	// Expand — clears the project context and navigates to the project document page.
	const handleExpand = () => {
		clearProjectContext()
		navigate(`/project/${projectId}/documents/${documentId}`)
	}

	const handleBack = () => {
		navigate(`/project/${projectId}/documents`)
	}

	if (!document) {
		return <Loader />
	}

	function handleTogglePDF() {
		const toggleValue = !showPdf
		setShowPdf(toggleValue)
		if (onChangePDFVisibility) {
			onChangePDFVisibility(toggleValue)
		}
	}

	const handleEditSaveButton = () => {
		setIsEditing(!isEditing)
	}

	const handleResetContextButton = () => {
		setEditedContextData(null)
		setIsEditing(!isEditing)
	}

	const handleSaveContextButton = () => {
		handleSaveContext()
		setIsEditing(!isEditing)
	}

	const handleSaveContext = async () => {
		try {
			if (editedContextData) {
				await updateDocumentContext(documentId, editedContextData)
				setEditedContextData(null)
				addSuccessMessage("Document context saved successfully")
			}
		} catch (error) {
			console.error("Error saving document context:", error)
			addErrorMessage("Failed to save document context. Please try again.")
		}
	}

	const currentContextData = editedContextData || contextData

	if (isLoading) {
		return <Loader />
	}

	if (isError) {
		return <ErrorMessage />
	}

	return (
		<DocumentContextProvider
			documentIds={[document.id]}
			defaultSelectedChunkId={documentViewerChunkId}
		>
			<div className="h-full w-full overflow-hidden">
				<Tabs
					defaultValue="details"
					value={activeTab}
					onValueChange={(value) => setActiveTab(value)}
				>
					<div className="border-b border-border h-[45px]">
						<div className="flex h-full justify-between items-center w-full px-2">
							<div className="flex items-center gap-2">
								{!inSidePanel && (
									<TooltipButton
										tooltipText="Back"
										variant="ghost"
										size="xsIcon"
										onClick={handleBack}
										icon={<ChevronLeft size={20} />}
									/>
								)}

								{/* Renamable nickname in header.
                On click, the span toggles the input to allow renaming. */}
								{document.title &&
									(isRenamingName ? (
										<div className="w-full min-w-[6rem]">
											<Input
												value={newName}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													setNewName(e.target.value)
												}
												onBlur={handleSaveName}
												onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
													if (e.key === "Enter") handleSaveName()
												}}
												autoFocus
											/>
										</div>
									) : (
										<Tooltip>
											<TooltipTrigger>
												<Label
													onClick={() => setIsRenamingName(true)}
													className="mb-0  cursor-pointer whitespace-nowrap"
												>
													{getDocumentName(document.id) || "—"}
												</Label>
											</TooltipTrigger>
											<TooltipContent>Click to rename</TooltipContent>
										</Tooltip>
									))}
								<TabsList className="h-8">
									<TabsTrigger className="h-6" value="details">
										Details
									</TabsTrigger>
									<TabsTrigger className="h-6" value="context">
										Context
									</TabsTrigger>
								</TabsList>
							</div>

							<div className="flex flex-row gap-2">
								{activeTab === "details" && (
									<>
										<Button variant="outline" size="sm" onClick={handleEditSaveButton}>
											{isEditing ? "Done" : "Edit"}
										</Button>
										<Button variant="outline" size="sm" onClick={handleTogglePDF}>
											{showPdf ? "Hide PDF" : "View PDF"}
										</Button>
									</>
								)}
								{activeTab === "context" && (
									<div className="space-x-2">
										{isEditing && (
											<>
												<Button
													onClick={handleResetContextButton}
													variant="outline"
													size="sm"
													className="h-9"
												>
													Reset
												</Button>
												<Button onClick={handleSaveContextButton} size="sm" className="h-9">
													Save
												</Button>
											</>
										)}
										{!isEditing && (
											<Button
												onClick={handleEditSaveButton}
												variant="outline"
												size="sm"
												className="h-9"
											>
												Edit
											</Button>
										)}
									</div>
								)}
								{inSidePanel && (
									<>
										<TooltipButton
											tooltipText="Expand"
											variant="ghost"
											size="xsIcon"
											onClick={handleExpand}
											icon={<Maximize size={20} />}
										/>

										<TooltipButton
											tooltipText="Collapse"
											variant="ghost"
											size="xsIcon"
											onClick={handleCollapse}
											icon={<XIcon size={20} />}
										/>
									</>
								)}
							</div>
						</div>
					</div>

					<ScrollArea className="h-[calc(100vh-45px)]">
						<TabsContent value="details">
							<DocumentDetailLayout
								documentId={document.id}
								isEditing={isEditing}
								isPDFVisible={showPdf}
								onTogglePDF={handleTogglePDF}
								citationText={citationText}
								inSidePanel={inSidePanel}
							/>
						</TabsContent>
						<TabsContent value="context">
							<DocumentContextComponent
								context={currentContextData}
								isEditing={isEditing}
								setContextData={setEditedContextData}
							/>
						</TabsContent>
					</ScrollArea>
				</Tabs>
			</div>
		</DocumentContextProvider>
	)
}

export default DocumentContent
