import { Button } from "@/components/ui/button"
import { CheckboxWithText } from "@/components/ui/checkbox"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import { LoadingButton } from "@/components/ui/loading-button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { InvalidityType } from "@/types/invalidity"
import type React from "react"
import { useState } from "react"

export interface CoverPleadingExportSettingsModalProps {
	open: boolean
	onClose: () => void
	documents: Array<{
		documentId: string
		name: string
	}>
	onExport: (
		selectedDocumentIds: string[],
		selectedAnalysisTypes: InvalidityType[],
	) => Promise<void>
	isExporting: boolean
}

const ANALYSIS_TYPES = [
	{ label: "Anticipation (§ 102) Analysis", value: InvalidityType.ANTICIPATION },
	{ label: "Obviousness (§ 103) Analysis", value: InvalidityType.OBVIOUSNESS },
	{ label: "Specification (§ 112) Analysis", value: InvalidityType.SPECIFICATION },
]

const CoverPleadingExportSettingsModal: React.FC<
	CoverPleadingExportSettingsModalProps
> = ({ open, onClose, documents, onExport, isExporting }) => {
	// Default to all documents and all analysis types selected
	const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([])
	const [selectedAnalysisTypes, setSelectedAnalysisTypes] = useState<InvalidityType[]>(
		ANALYSIS_TYPES.map((type) => type.value),
	)

	const handleDocumentToggle = (documentId: string) => {
		setSelectedDocumentIds((prev) =>
			prev.includes(documentId)
				? prev.filter((id) => id !== documentId)
				: [...prev, documentId],
		)
	}

	const handleAnalysisTypeToggle = (type: InvalidityType) => {
		setSelectedAnalysisTypes((prev) =>
			prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type],
		)
	}

	const handleSelectAll = () => {
		setSelectedDocumentIds(documents.map((doc) => doc.documentId))
	}

	const handleSelectNone = () => {
		setSelectedDocumentIds([])
	}

	const handleExport = async () => {
		await onExport(selectedDocumentIds, selectedAnalysisTypes)
	}

	return (
		<Dialog open={open} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Export Cover Pleading</DialogTitle>
					<DialogDescription>
						Select which documents and analysis types to include in your export.
					</DialogDescription>
				</DialogHeader>

				<div className="flex flex-col gap-6 py-4">
					<div>
						<div className="flex justify-between items-center mb-2">
							<Label className="text-base font-semibold">Documents</Label>
							<div className="space-x-2">
								<Button variant="outline" size="sm" onClick={handleSelectAll}>
									Select All
								</Button>
								<Button variant="outline" size="sm" onClick={handleSelectNone}>
									Select None
								</Button>
							</div>
						</div>
						<ScrollArea className="h-[200px] border rounded-md p-4">
							<div className="space-y-2">
								{documents.map((doc) => (
									<div key={doc.documentId} className="flex items-center space-x-2">
										<CheckboxWithText
											id={`doc-${doc.documentId}`}
											checked={selectedDocumentIds.includes(doc.documentId)}
											onCheckedChange={() => handleDocumentToggle(doc.documentId)}
											label={doc.name}
										/>
									</div>
								))}
							</div>
						</ScrollArea>
					</div>

					<div>
						<Label className="text-base font-semibold">Analysis Types</Label>
						<div className="space-y-2 mt-2">
							{ANALYSIS_TYPES.map((type) => (
								<div key={type.value} className="flex items-center space-x-2">
									<CheckboxWithText
										id={`type-${type.value}`}
										checked={selectedAnalysisTypes.includes(type.value)}
										onCheckedChange={() => handleAnalysisTypeToggle(type.value)}
										label={type.label}
									/>
								</div>
							))}
						</div>
					</div>
				</div>

				<DialogFooter>
					<Button variant="outline" onClick={onClose} disabled={isExporting}>
						Cancel
					</Button>
					<LoadingButton
						loading={isExporting}
						onClick={handleExport}
						disabled={
							isExporting ||
							selectedDocumentIds.length === 0 ||
							selectedAnalysisTypes.length === 0
						}
					>
						{isExporting ? "Exporting..." : "Export"}
					</LoadingButton>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default CoverPleadingExportSettingsModal
