interface TableActionIndicatorProps {
	number: number
}

/**
 * TableActionIndicator component for displaying the number of filter or sort actions applied.
 */
export function TableActionIndicator({ number }: TableActionIndicatorProps) {
	return (
		<span className="absolute -top-0.5 -right-0.5 flex h-3.5 w-3.5 items-center justify-center rounded-full bg-primary text-background text-[9px]">
			{number}
		</span>
	)
}
