import { useLocation } from "react-router-dom"

export function useNavigationState() {
	const location = useLocation()

	return {
		// Home
		isHomePage: location.pathname.includes("/home"),

		// Admin sections
		isAdminPage: location.pathname.includes("/admin"),
		isSuperAdminPage: location.pathname.includes("/superadmin"),

		// Project related
		isCreateProjectPage: location.pathname.includes("/create"),
		isProjectPage: location.pathname.includes("/project"),

		// Chat
		isExpandedChatPage: location.pathname.includes("/chat"),
	}
}

export default useNavigationState
