import { Button } from "@/components/ui/button"
import { LoadingButton } from "@/components/ui/loading-button"
import { Separator } from "@/components/ui/separator"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { cn } from "@/lib/utils"
import type React from "react"

// Enum for the tab keys
export enum CoverTabKey {
	SUMMARIES = "Document Summaries",
	ANTICIPATION = "§ 102",
	OBVIOUSNESS = "§ 103",
	SPECIFICATION = "§ 112",
}

// Props to receive currentTab and callbacks
interface CoverNavigationTabsProps {
	currentTab: CoverTabKey
	onTabChange: (tab: CoverTabKey) => void
	onExportClick?: () => void
	onExportPitchSummary?: () => void
	exportDisabled?: boolean
	onGenerateClick?: () => void
	isGenerating?: boolean
	generateButtonText?: string
	hideGenerateButton?: boolean
}

const tabs = [
	CoverTabKey.SUMMARIES,
	CoverTabKey.ANTICIPATION,
	CoverTabKey.OBVIOUSNESS,
	CoverTabKey.SPECIFICATION,
]

// Updated CoverNavigationTabs component that uses the Tabs primitives
const CoverNavigationTabs: React.FC<CoverNavigationTabsProps> = ({
	currentTab,
	onTabChange,
	onExportClick,
	onExportPitchSummary,
	exportDisabled = false,
	onGenerateClick,
	isGenerating = false,
	generateButtonText = "",
	hideGenerateButton = false,
}) => {
	return (
		<div className="relative w-full">
			<Tabs
				value={currentTab}
				onValueChange={(val) => onTabChange(val as CoverTabKey)}
				className="w-full"
			>
				<div className="flex justify-between">
					<TabsList className="flex h-8">
						{tabs.map((tab) => (
							<TabsTrigger key={tab} value={tab} className={cn("px-4 py-2 font-medium h-6")}>
								{tab}
							</TabsTrigger>
						))}
					</TabsList>
					<div className="flex gap-2 items-center">
						{onGenerateClick && !hideGenerateButton && (
							<LoadingButton
								onClick={onGenerateClick}
								loading={isGenerating}
								disabled={isGenerating}
								size="sm"
								className="h-8 whitespace-nowrap min-w-[160px] px-3"
								variant="outline"
							>
								{isGenerating
									? "Generating..."
									: currentTab === CoverTabKey.SUMMARIES
										? `Generate ${generateButtonText}`
										: `Generate All ${generateButtonText}`}
							</LoadingButton>
						)}
						{onExportPitchSummary && (
							<Button
								onClick={onExportPitchSummary}
								disabled={exportDisabled}
								size="sm"
								className="h-8"
							>
								Export Pitch Summary
							</Button>
						)}
						{onExportClick && (
							<Button
								onClick={onExportClick}
								disabled={exportDisabled}
								size="sm"
								className="h-8"
							>
								Export Cover Pleading
							</Button>
						)}
					</div>
				</div>
			</Tabs>
			<Separator className="my-1" />
		</div>
	)
}

export default CoverNavigationTabs
