import { chartsApi } from "@/api"
import { useProjectContext } from "@/context/ProjectContext"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"

const useSuggested103Combinations = () => {
	const { projectId, subjectId } = useProjectContext()

	const getSuggested103CombinationCharts = useQuery({
		queryKey: QUERY_KEYS.project.charts.suggestedCombinationCharts(projectId, subjectId),
		queryFn: () => chartsApi.getSuggested103CombinationCharts(subjectId),
		enabled: !!subjectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	const suggested103CombinationCharts = useMemo(() => {
		return getSuggested103CombinationCharts.data
	}, [getSuggested103CombinationCharts.data])

	return {
		suggested103CombinationCharts,
	}
}

export default useSuggested103Combinations
