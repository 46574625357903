// PortfolioMatrixRowComponent.tsx
import { TableCellCondensed, TableRow } from "@/components/ui/table"
import { useProjectContext } from "@/context/ProjectContext"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import { cn } from "@/lib/utils"
import type { ProjectMatrixRow } from "@/types/project"
import type React from "react"
import { useNavigate } from "react-router-dom"
interface ProjectChartsMatrixRowProps {
	row: ProjectMatrixRow
	colWidthPercent: number
}

const ProjectChartsMatrixRowComponent: React.FC<ProjectChartsMatrixRowProps> = ({
	row,
}) => {
	const { getDocumentName } = useDocumentNaming()
	const { projectId } = useProjectContext()
	const navigate = useNavigate()

	const handleCellClick = async (subjectId: string, chartId: string) => {
		if (chartId) {
			navigate(`/project/${projectId}/charts/${subjectId}/${chartId}`)
		}
	}

	const handleRowClick = () => {
		navigate(`/project/${projectId}/charts/${row.subject}`)
	}

	return (
		<TableRow>
			<TableCellCondensed
				className="sticky left-0 bg-background font-medium whitespace-nowrap"
				style={{ width: "15%" }}
			>
				<div className="cursor-pointer" onClick={() => handleRowClick()}>
					{getDocumentName(row.subject)}
				</div>
			</TableCellCondensed>
			{row.cells.map((cell, _) => (
				<TableCellCondensed
					key={`cell-${cell.chartId}`}
					className={cn("p-2 shrink-0", cell.chartId && "cursor-pointer")}
					style={{ width: `${(100 - 15) / row.cells.length}%`, minWidth: "10px" }}
					onClick={() => handleCellClick(row.subject, cell.chartId)}
				>
					<div
						className="h-7 w-full mx-auto rounded"
						style={{
							background: buildLinearGradient(cell.counts),
						}}
					/>
				</TableCellCondensed>
			))}
		</TableRow>
	)
}

// Build a left-to-right gradient for the distribution
function buildLinearGradient(counts: Record<string, number>): string {
	const total = Object.values(counts).reduce((acc, c) => acc + c, 0)
	if (total === 0) {
		// if truly no color, return grey or transparent
		return "#D3D3D3"
	}

	const gradientParts: string[] = []
	let currentPercent = 0

	// We want the slice order: GREEN, YELLOW, RED, GREY.  If your keysonl
	// are in arbitrary order, define a stable ordering so the slices come out
	// left->right in the desired sequence:
	const colorOrder = ["#C1E1A6", "#FFDE7D", "#F5A19B", "#D3D3D3"]

	for (const color of colorOrder) {
		const count = counts[color] || 0
		if (count > 0) {
			const fraction = (count / total) * 100
			const nextPercent = currentPercent + fraction
			gradientParts.push(`${color} ${currentPercent}%, ${color} ${nextPercent}%`)
			currentPercent = nextPercent
		}
	}

	return `linear-gradient(to right, ${gradientParts.join(", ")})`
}

export default ProjectChartsMatrixRowComponent
