import { api } from "@/api"
import { Button } from "@/components/ui/button"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { H4 } from "@/components/ui/typography"
import { useUserStore } from "@/store"
import type { AdminOverview } from "@/types"
import { formatCurrency } from "@/utils"
import { Pencil1Icon } from "@radix-ui/react-icons"
import { Check } from "lucide-react"
import type React from "react"
import { useState } from "react"

// Overview details card (updated)
export const BillingDetailsCard: React.FC<{ overview: AdminOverview }> = ({
	overview,
}) => {
	const { organizationId } = useUserStore()
	const { totalBalance, defaultBudget } = overview

	// Use local state for budget and editing mode
	const [budget, setBudget] = useState<string>(
		defaultBudget ? defaultBudget.toString() : "",
	)
	const [isSaving, setIsSaving] = useState<boolean>(false)
	const [isEditing, setIsEditing] = useState<boolean>(false)

	const handleSaveBudget = async () => {
		setIsSaving(true)
		try {
			await api.updateOrganization(organizationId, { defaultBudget: Number(budget) })
			setIsEditing(false)
		} catch (error) {
			console.error("Error updating default budget", error)
		} finally {
			setIsSaving(false)
		}
	}

	// Render the defaultBudget field with conditional edit mode
	const defaultBudgetDisplay = isEditing ? (
		<div className="flex items-center gap-2">
			<Input
				type="text"
				value={budget}
				onChange={(e) => setBudget(e.target.value)}
				className="w-24"
			/>
			<Button onClick={handleSaveBudget} disabled={isSaving} size="icon">
				{isSaving ? "..." : <Check size={16} />}
			</Button>
		</div>
	) : (
		<div className="flex items-center gap-2">
			<span>{formatCurrency(Number(budget))}</span>
			<Button variant="ghost" onClick={() => setIsEditing(true)} size="icon">
				<Pencil1Icon className="w-4 h-4" />
			</Button>
		</div>
	)

	const formattedStatistics = {
		totalBalance: formatCurrency(totalBalance),
		defaultBudget: <div className="flex items-center gap-2">{defaultBudgetDisplay}</div>,
	}

	const fields = [
		{ key: "totalBalance", label: "Balance" },
		{ key: "defaultBudget", label: "Default Project Budget" },
	]

	// Return early if overview is not provided
	if (!overview) return null

	return (
		<Card className="w-full">
			<CardHeader>
				<H4>Billing Overview</H4>
			</CardHeader>
			<CardContent>
				<div className="grid grid-cols-[repeat(auto-fit,minmax(100px,1fr))] gap-2">
					{fields.map(({ key, label }) =>
						formattedStatistics[key] !== null && formattedStatistics[key] !== "null" ? (
							<div key={key} className="flex flex-col items-start">
								<dt className="text-sm font-medium text-muted-foreground">{label}</dt>
								<dd className="text-lg font-semibold">{formattedStatistics[key]}</dd>
							</div>
						) : null,
					)}
				</div>
			</CardContent>
		</Card>
	)
}
