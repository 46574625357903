"use client"

import * as React from "react"
import {} from "recharts"

import { Loader } from "@/components"
import BaseBarChart from "@/components/charts/BaseBarChart"
import BaseLineChart from "@/components/charts/BaseLineChart"
import {} from "@/components/ui/chart"
import type {
	AdminChartData,
	AdminChartDataDate,
	AdminChartDataOrganization,
	AdminChartDataUser,
} from "@/types/admin"
import { formatTimestamp } from "@/utils/dateUtils"
import { useUserSessions } from "../hooks/useUserSessions"
import type { FilterOptions } from "./UsageFilterSelects"

// Chart configuration for the hours line.
const chartConfig = {
	hours: {
		label: "Hours",
		color: "#7FB2FF",
	},
}

// A custom tooltip that renders information based on how the data was grouped.
// If grouped by date, it displays the formatted date and a user breakdown.
// If grouped by user, it simply shows the user and total hours.
// If grouped by organization, it shows the organization and total hours.
const CustomTooltip = ({ active, payload, label }: any) => {
	if (active && payload && payload.length) {
		const data = payload[0].payload
		// Grouped by date: display date with breakdown.
		if (data.date) {
			return (
				<div className="bg-background border p-2 shadow">
					<p className="font-bold">
						{new Date(label).toLocaleDateString("en-US", {
							month: "short",
							day: "numeric",
							year: "numeric",
						})}
					</p>
					<p className="mt-1">Total Hours: {data.count.toFixed(1)}</p>
					{data.breakdown && (
						<ul className="mt-1 space-y-0.5 max-h-[200px] overflow-y-auto">
							{Object.entries(data.breakdown)
								.sort(([, hoursA]: any, [, hoursB]: any) => hoursB - hoursA)
								.map(([user, minutes]: [string, number]) => (
									<li key={user}>
										<span className="font-medium">{user}</span>: {(minutes / 60).toFixed(1)} h
									</li>
								))}
						</ul>
					)}
				</div>
			)
		}
		// Grouped by user: display user and total hours.
		if (data.user) {
			return (
				<div className="bg-background border p-2 shadow">
					<p className="font-bold">{data.user}</p>
					<p className="mt-1">Total Hours: {data.count.toFixed(1)}</p>
				</div>
			)
		}
		// Grouped by organization: display organization and total hours
		if (data.organization) {
			return (
				<div className="bg-background border p-2 shadow">
					<p className="font-bold">{data.organization}</p>
					<p className="mt-1">Total Hours: {data.count.toFixed(1)}</p>
				</div>
			)
		}
	}
	return null
}

export function UserSessionsChart({
	isSuperAdmin,
	organizationId,
	filters,
	isBarChart = false,
	groupBy = "date",
}: {
	isSuperAdmin: boolean
	organizationId: string
	filters: FilterOptions
	isBarChart?: boolean
	groupBy?: "date" | "user" | "organization"
}) {
	const { sessionData, isLoading, isFetching } = useUserSessions(
		isSuperAdmin,
		organizationId,
		filters,
	)

	// In line chart mode we always group by date.
	const effectiveGroupBy = isBarChart ? groupBy : "date"

	const chartData = React.useMemo(() => {
		// Group by date: each bar represents one day with a breakdown by user.
		if (effectiveGroupBy === "date") {
			const groups: Record<string, AdminChartDataDate> = {}

			for (const session of sessionData || []) {
				if (!session.date) continue

				const dateObj =
					typeof session.date === "string"
						? new Date(session.date)
						: (session.date as any) instanceof Date
							? (session.date as Date)
							: new Date(String(session.date))

				const dateKey = formatTimestamp(dateObj.toISOString())

				if (!groups[dateKey]) {
					groups[dateKey] = { date: dateKey, count: 0, breakdown: {} }
				}

				// Convert session minutes to hours.
				groups[dateKey].count += session.minutes / 60

				// Use the session name or email as the key.
				const userKey = session.name || session.email || "Unknown"
				groups[dateKey].breakdown[userKey] =
					(groups[dateKey].breakdown[userKey] || 0) + session.minutes
			}

			return Object.values(groups)
				.map((group) => ({ ...group, count: Number(group.count.toFixed(1)) }))
				.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
		}

		// Group by user: each bar will represent a specific user's total sessions.
		if (effectiveGroupBy === "user") {
			const groups: Record<string, AdminChartDataUser> = {}

			for (const session of sessionData || []) {
				const userKey = session.name || session.email || "Unknown"
				if (!groups[userKey]) {
					groups[userKey] = { user: userKey, count: 0, breakdown: {} }
				}

				groups[userKey].count += session.minutes / 60
			}

			return Object.values(groups)
				.map((group) => ({ ...group, count: Number(group.count.toFixed(1)) }))
				.sort((a, b) => a.count - b.count)
		}

		// Group by organization: each bar represents an organization's total sessions
		if (effectiveGroupBy === "organization") {
			const groups: Record<string, AdminChartDataOrganization> = {}

			for (const session of sessionData || []) {
				const orgKey = session.organizationName || "Unknown"
				if (!groups[orgKey]) {
					groups[orgKey] = { organization: orgKey, count: 0, breakdown: {} }
				}

				groups[orgKey].count += session.minutes / 60

				// Add user breakdown within organization
				const userKey = session.name || session.email || "Unknown"
				groups[orgKey].breakdown[userKey] =
					(groups[orgKey].breakdown[userKey] || 0) + session.minutes
			}

			return Object.values(groups)
				.map((group) => ({ ...group, count: Number(group.count.toFixed(1)) }))
				.sort((a, b) => a.organization.localeCompare(b.organization))
		}

		return []
	}, [sessionData, effectiveGroupBy])

	if (isLoading || isFetching) {
		return <Loader />
	}

	if (isBarChart) {
		return (
			<BaseBarChart<AdminChartData>
				data={chartData as AdminChartData[]}
				xKey={effectiveGroupBy as keyof AdminChartData}
				yKeys={["count"]}
				barColors={[chartConfig.hours.color]}
				chartHeight={250}
				margin={{ top: 12, right: 12, bottom: 12, left: 12 }}
				tooltipContent={<CustomTooltip />}
				chartConfig={chartConfig}
				showLabels={true}
			/>
		)
	}

	return (
		<BaseLineChart
			data={chartData as AdminChartData[]}
			xKey={effectiveGroupBy as keyof AdminChartData}
			yKeys={["count"] as (keyof AdminChartData)[]}
			lineColors={[chartConfig.hours.color]}
			chartHeight={250}
			margin={{ top: 12, right: 12, bottom: 12, left: 12 }}
			tooltipContent={<CustomTooltip />}
			xTickFormatter={(value) => {
				const date = new Date(value)
				return date.toLocaleDateString("en-US", {
					month: "short",
					day: "numeric",
				})
			}}
			yTickFormatter={(value) => `${value.toFixed(1)} h`}
			chartConfig={chartConfig}
		/>
	)
}

export default UserSessionsChart
