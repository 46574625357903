/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import { AdminPageLayout, UsersTable } from "@/features/admin/components"
import { useUserStore } from "@/store"
import { PageName } from "@/types/pages"
import { useAdminUsers } from "../hooks/useAdminUsers"

/**
 * @description Admin page component displaying organization users.
 */
function AdminUsersPage() {
	const { organizationId, organizationName } = useUserStore()
	const { adminUsers, isLoading } = useAdminUsers(organizationId)

	return (
		<AdminPageLayout isSuperAdmin={false} page={PageName.AdminUsers}>
			<div className="mt-2">
				{isLoading ? (
					<Loader />
				) : !adminUsers ? (
					<p className="text-destructive">Error fetching users.</p>
				) : (
					<UsersTable orgName={organizationName} orgId={organizationId} users={adminUsers} />
				)}
			</div>
		</AdminPageLayout>
	)
}

export default AdminUsersPage
