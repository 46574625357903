import { chartsApi } from "@/api/chartsApi"
import { QUERY_KEYS } from "@/utils/query/keys"
import { useQuery } from "@tanstack/react-query"

interface UseClaimInvalidityDataOptions {
	projectId: string
	documents: string[]
	colors: string[]
	enabled?: boolean
}

export function useClaimInvalidityData(
	chartId: string,
	subjectId: string,
	limitationId: string,
	{ projectId, colors, enabled }: UseClaimInvalidityDataOptions,
) {
	const {
		data: limitationInvalidityData,
		isLoading,
		isError,
	} = useQuery({
		queryKey: QUERY_KEYS.project.charts.limitationChartData(
			projectId,
			subjectId,
			chartId,
			limitationId,
			// colors,
		),
		queryFn: async () => {
			if (!projectId || !limitationId) return null
			return chartsApi.getChartData(projectId, subjectId, [limitationId], chartId, colors)
		},
		enabled: enabled && !!projectId && !!chartId && !!subjectId && !!limitationId,
		gcTime: 1000 * 10, // Don't keep stale data in cache 10 seconds
		staleTime: 1000 * 60, // 1 minute
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	})

	return {
		limitationInvalidityData,
		isLoading,
		isError,
	}
}

export default useClaimInvalidityData
