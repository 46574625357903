import {
	Table,
	TableBody,
	TableCellCondensed,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import { cn } from "@/lib/utils"
import { BlockType, type DocumentChunk } from "@/types"
import { normalizeTextToMatch } from "@/utils/normalizeTextToMatch"
import type React from "react"
import { parseFigureReferences } from "./parseFigureReferences"

interface ParseHtmlOptions {
	TableComponent?: React.ComponentType<any>
	TableHeaderComponent?: React.ComponentType<any>
	TableBodyComponent?: React.ComponentType<any>
	TableRowComponent?: React.ComponentType<any>
	TableHeadComponent?: React.ComponentType<any>
	TableCellComponent?: React.ComponentType<any>
}

/**
 * Converts HTML string content into React elements
 * @param {string} html - The HTML string to parse
 * @param {ParseHtmlOptions} options - Optional component overrides for table elements
 * @param {string} [citationText] - Text to highlight (first occurrence only)
 * @param {DocumentChunk[]} [figureChunks] - If provided, figure references (e.g. "FIG. 1") will be parsed and made clickable
 * @param {(figureChunk: DocumentChunk) => void} [handleFigureReferenceClick] - Callback for figure reference click
 * @param {BlockType} [chunkType] - If passing header/section block type, we apply a semibold style
 * @param {boolean} [isDarkMode] - If true, applies a slightly different style for figure references
 * @param {(quote: string) => void} [onQuoteClick] - If provided, text inside quotes (e.g., "some text") is highlighted and clickable
 * @returns {React.ReactNode} - The parsed content as React elements
 */
export const parseHtml = (
	html: string,
	options: ParseHtmlOptions = {},
	citationText?: string,
	figureChunks?: DocumentChunk[],
	handleFigureReferenceClick?: (figureChunk: DocumentChunk) => void,
	chunkType?: BlockType,
	isDarkMode?: boolean,
	onQuoteClick?: (quote: string) => void,
): React.ReactNode => {
	const {
		TableComponent = Table,
		TableHeaderComponent = TableHeader,
		TableBodyComponent = TableBody,
		TableRowComponent = TableRow,
		TableHeadComponent = TableHead,
		TableCellComponent = TableCellCondensed,
	} = options

	const parser = new DOMParser()
	const content = parser.parseFromString(html, "text/html")

	/**
	 * Parses text for figure references and optionally highlights citation text. If
	 * onQuoteClick is provided, makes quoted text clickable.
	 */
	const highlightText = (text: string): React.ReactNode => {
		if (!text.trim()) return text

		let currentSegments: React.ReactNode[] = [text]

		// 1) Parse out figure references if figureChunks is provided
		if (figureChunks?.length) {
			currentSegments = currentSegments.flatMap((segment) => {
				if (typeof segment === "string") {
					return parseFigureReferences(
						segment,
						figureChunks,
						handleFigureReferenceClick,
						isDarkMode,
					)
				}
				return segment
			})
		}

		// 2) If citationText is provided, highlight the first occurrence
		if (citationText) {
			const normalizedSearch = normalizeTextToMatch(citationText)

			const splitOnCitation = (node: React.ReactNode): React.ReactNode[] => {
				if (typeof node !== "string") return [node]

				const textValue = node
				const normalizedText = normalizeTextToMatch(textValue)
				const index = normalizedText.indexOf(normalizedSearch)
				if (index === -1) {
					return [textValue]
				}
				const searchLength = citationText.length

				let originalIndex = 0
				let normalizedIndex = 0
				let highlightStart = -1
				while (originalIndex < textValue.length) {
					const char = textValue[originalIndex]
					const normalizedChar = normalizeTextToMatch(char)
					if (normalizedChar) {
						if (normalizedIndex === index) {
							highlightStart = originalIndex
							break
						}
						normalizedIndex++
					}
					originalIndex++
				}

				const highlightEnd = highlightStart + searchLength
				return [
					textValue.slice(0, highlightStart),
					<span key={textValue} className="bg-blue-100">
						{textValue.slice(highlightStart, highlightEnd)}
					</span>,
					textValue.slice(highlightEnd),
				]
			}

			currentSegments = currentSegments.flatMap((node) => splitOnCitation(node))
		}

		// 3) If onQuoteClick is provided, parse out quoted text and wrap in a clickable span
		if (onQuoteClick) {
			const parseQuotes = (node: React.ReactNode): React.ReactNode[] => {
				if (typeof node !== "string") return [node]

				const textValue = node
				const quoteRegex = /"([^"]+)"/g
				let lastIndex = 0
				const resultNodes: React.ReactNode[] = []

				let match = quoteRegex.exec(textValue)
				while (match !== null) {
					const [fullMatch, innerQuote] = match
					const start = match.index
					const end = start + fullMatch.length

					// Push any preceding text
					if (start > lastIndex) {
						resultNodes.push(textValue.slice(lastIndex, start))
					}

					// Make the quoted text a clickable span
					resultNodes.push(
						<span
							key={crypto.randomUUID()}
							className="cursor-pointer hover:bg-blue-100 dark:hover:bg-accent"
							onClick={() => onQuoteClick?.(innerQuote)}
						>
							{fullMatch}
						</span>,
					)

					lastIndex = end
					match = quoteRegex.exec(textValue)
				}

				// Push remaining text (if any)
				if (lastIndex < textValue.length) {
					resultNodes.push(textValue.slice(lastIndex))
				}

				return resultNodes
			}

			currentSegments = currentSegments.flatMap(parseQuotes)
		}

		// If there's only one segment and it's just a string, return as string
		// Otherwise wrap in fragment so we preserve multiple nodes
		return currentSegments.length === 1 && typeof currentSegments[0] === "string" ? (
			currentSegments[0]
		) : (
			<>{currentSegments}</>
		)
	}

	const processNode = (node: Node): React.ReactNode => {
		// TEXT_NODE
		if (node.nodeType === Node.TEXT_NODE) {
			if (!node.textContent) return ""

			// For text nodes, preserve whitespace and newlines
			return (
				<span
					key={crypto.randomUUID()}
					className={cn(
						"whitespace-pre-wrap",
						chunkType === BlockType.HEADER || chunkType === BlockType.SECTION_HEADER
							? "font-semibold"
							: "",
					)}
				>
					{highlightText(node.textContent)}
				</span>
			)
		}

		// FORMATTING ELEMENTS
		if (node instanceof HTMLElement) {
			const children = Array.from(node.childNodes).map(processNode)
			const tagName = node.tagName.toLowerCase()

			// Handle text formatting tags
			if (tagName === "b" || tagName === "strong") {
				return (
					<strong key={crypto.randomUUID()} className="whitespace-pre-wrap">
						{children}
					</strong>
				)
			}
			if (tagName === "i" || tagName === "em") {
				return (
					<em key={crypto.randomUUID()} className="whitespace-pre-wrap">
						{children}
					</em>
				)
			}
			if (tagName === "u") {
				return (
					<u key={crypto.randomUUID()} className="whitespace-pre-wrap">
						{children}
					</u>
				)
			}

			// Handle paragraph tags
			if (tagName === "p") {
				return (
					<p key={crypto.randomUUID()} className="whitespace-pre-wrap mb-2">
						{children}
					</p>
				)
			}

			// Handle other HTML elements
			return (
				<div key={crypto.randomUUID()} className="whitespace-pre-wrap">
					{children}
				</div>
			)
		}

		// TABLE ELEMENT
		if (node instanceof HTMLTableElement) {
			const rows = Array.from(node.querySelectorAll("tr"))
			const hasHeader = rows[0]?.querySelectorAll("th").length > 0

			return (
				<TableComponent key={crypto.randomUUID()} className="my-4 border">
					{hasHeader && (
						<TableHeaderComponent>
							<TableRowComponent>
								{Array.from(rows[0].querySelectorAll("th")).map((cell, i) => (
									<TableHeadComponent key={i}>{cell.textContent}</TableHeadComponent>
								))}
							</TableRowComponent>
						</TableHeaderComponent>
					)}
					<TableBodyComponent>
						{rows.slice(hasHeader ? 1 : 0).map((row, i) => (
							<TableRowComponent key={i}>
								{Array.from(row.querySelectorAll("td")).map((cell, j) => (
									<TableCellComponent key={j}>{cell.textContent}</TableCellComponent>
								))}
							</TableRowComponent>
						))}
					</TableBodyComponent>
				</TableComponent>
			)
		}

		return null
	}

	return processNode(content.body)
}
