/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { SidebarPage } from "@/components"
import { ScrollArea } from "@/components/ui/scroll-area"
import type { Pages } from "@/types/pages"
import type React from "react"
import type { ReactNode } from "react"
import AdminHeader from "./AdminHeader"

/**
 * @description The project page component
 * @param {ReactNode} children - The children of the component
 */
interface AdminPageLayoutProps {
	children: ReactNode
	isSuperAdmin: boolean
	page: Pages
}

const AdminPageLayout: React.FC<AdminPageLayoutProps> = ({
	children,
	isSuperAdmin,
	page,
}) => {
	return (
		<SidebarPage pageName={page}>
			<AdminHeader isSuperAdmin={isSuperAdmin} />
			<ScrollArea className="px-3 h-[calc(100vh-55px)]">{children}</ScrollArea>
		</SidebarPage>
	)
}

export default AdminPageLayout
