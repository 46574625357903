/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { AddDocumentsModal, AddPatentsModal } from "@/components"
import { ScrollArea } from "@/components/ui/scroll-area"
import { UnprocessedDocumentsProvider } from "@/context/UnprocessedDocumentsContext"
import { useProjectContextDocuments } from "@/features/documents/hooks/useProjectContextDocuments"
import { useProjectInfringementDocuments } from "@/features/documents/hooks/useProjectInfringementDocuments"
import { useProjectPriorArtDocuments } from "@/features/documents/hooks/useProjectPriorArtDocuments"
import { useProjectSubjectDocuments } from "@/features/documents/hooks/useProjectSubjectDocuments"
import { DocumentRole } from "@/types"
import { UnprocessedDocumentType } from "@/types/documents"
import { PageName } from "@/types/pages"
import type React from "react"
import { useCallback, useState } from "react"
import ProjectPage from "../../components/projects/ProjectPage"
import { AddProductModal } from "./components/AddProductModal"
import { DocumentRoleList } from "./components/DocumentRoleList"

/**
 * @description Project documents page
 */
const DocumentsPage: React.FC = () => {
	const { priorArtDocuments, isLoading: isPriorArtLoading } =
		useProjectPriorArtDocuments()
	const { contextDocuments, isLoading: isContextLoading } = useProjectContextDocuments()
	const { subjectDocuments, isLoading: isSubjectLoading } = useProjectSubjectDocuments()
	const { infringementDocuments, isLoading: isInfringementLoading } =
		useProjectInfringementDocuments()

	const [typeToAdd, setTypeToAdd] = useState<UnprocessedDocumentType | null>(null)
	const [roleToAdd, setRoleToAdd] = useState<DocumentRole | null>(null)

	const handleOpenAddModal = useCallback(
		(type: UnprocessedDocumentType, role: DocumentRole) => {
			setTypeToAdd(type)
			setRoleToAdd(role)
		},
		[],
	)

	const handleCloseAddModal = useCallback(() => {
		setTypeToAdd(null)
		setRoleToAdd(null)
	}, [])

	const roleLists = [
		{
			role: DocumentRole.SUBJECT,
			documents: subjectDocuments,
			isLoading: isSubjectLoading,
			label: "Subjects",
		},
		{
			role: DocumentRole.PRIOR_ART,
			documents: priorArtDocuments,
			isLoading: isPriorArtLoading,
			label: "Prior Art",
		},
		{
			role: DocumentRole.INFRINGEMENT,
			documents: infringementDocuments,
			isLoading: isInfringementLoading,
			label: "Products",
		},
		{
			role: DocumentRole.CONTEXT,
			documents: contextDocuments,
			isLoading: isContextLoading,
			label: "Context",
		},
	]

	return (
		<ProjectPage pageName={PageName.ProjectDocuments}>
			<ScrollArea className="w-full">
				{roleLists.map((roleList) => (
					<DocumentRoleList
						key={roleList.role}
						role={roleList.role}
						label={roleList.label}
						documents={roleList.documents}
						isLoading={roleList.isLoading}
						onAddDocument={handleOpenAddModal}
					/>
				))}
			</ScrollArea>
			<UnprocessedDocumentsProvider>
				<AddPatentsModal
					open={typeToAdd === UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION}
					handleClose={handleCloseAddModal}
					documentRole={roleToAdd}
				/>
				<AddDocumentsModal
					open={typeToAdd === UnprocessedDocumentType.NPL}
					handleClose={handleCloseAddModal}
					documentRole={roleToAdd}
				/>
				<AddProductModal
					open={typeToAdd === UnprocessedDocumentType.PRODUCT}
					handleClose={handleCloseAddModal}
					documentRole={roleToAdd}
				/>
			</UnprocessedDocumentsProvider>
		</ProjectPage>
	)
}

export default DocumentsPage
