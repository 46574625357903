import { BaseTable, type Column } from "@/components/table/BaseTable"
import { Badge } from "@/components/ui/badge"
import {
	Table,
	TableBody,
	TableCellCondensed,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import type { AdminDocumentSummary } from "@/types"
import { formatTimestamp } from "@/utils/dateUtils"
import { ChevronDown, ChevronRight } from "lucide-react"
import React from "react"
import { useMemo, useState } from "react"

/**
 * Standard columns used for a document row.
 */
const columns: Column<AdminDocumentSummary>[] = [
	{
		header: "Document Name",
		accessor: "nickname",
		render: (value) => value || "N/A",
	},
	{
		header: "Type",
		accessor: "type",
		render: (value) => <Badge variant="outline">{value || "N/A"}</Badge>,
	},
	{
		header: "Role",
		accessor: "role",
		render: (value) => <Badge variant="outline">{value || "N/A"}</Badge>,
	},
	{
		header: "Created",
		accessor: "createdAt",
		render: (value) => formatTimestamp(value),
	},
	{
		header: "Creator",
		accessor: "createdBy",
		render: (value) => value || "N/A",
	},
	{
		header: "Balance",
		accessor: "balance",
		render: (value) => value || "$0.00",
	},
]

interface DocumentsUsageTableProps {
	documents: AdminDocumentSummary[]

	groupBy?: "date" | "user" | "organization"
}

export function DocumentsUsageTable({
	documents,
	groupBy = "date",
}: DocumentsUsageTableProps) {
	// Local state for toggling expanded/collapsed groups.
	const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({})

	// Group by user.
	const groupedData = useMemo(() => {
		return documents.reduce(
			(groups, doc) => {
				const userKey = doc.createdBy || "Unknown"
				if (!groups[userKey]) {
					groups[userKey] = {
						user: userKey,
						docCount: 0,
						totalBalance: 0,
						documents: [] as AdminDocumentSummary[],
					}
				}
				groups[userKey].docCount++
				let balanceValue = 0
				if (typeof doc.balance === "number") {
					balanceValue = doc.balance
				} else if (doc.balance) {
					balanceValue =
						Number.parseFloat(doc.balance.toString().replace(/[^0-9.-]+/g, "")) || 0
				}
				groups[userKey].totalBalance += balanceValue
				groups[userKey].documents.push(doc)
				return groups
			},
			{} as Record<
				string,
				{
					user: string
					docCount: number
					totalBalance: number
					documents: AdminDocumentSummary[]
				}
			>,
		)
	}, [documents])

	const groupedRows = Object.values(groupedData)

	const toggleGroup = (user: string) => {
		setExpandedGroups((prev) => ({ ...prev, [user]: !prev[user] }))
	}

	// For grouping by date, use the standard BaseTable.
	if (groupBy === "date") {
		return <BaseTable columns={columns} data={documents} />
	}

	return (
		<Table>
			<TableHeader>
				<TableRow>
					<TableHead>Document Name</TableHead>
					<TableHead>Type</TableHead>
					<TableHead>Role</TableHead>
					<TableHead>Created</TableHead>
					<TableHead>Creator</TableHead>
					<TableHead>Status</TableHead>
					<TableHead>Balance</TableHead>
				</TableRow>
			</TableHeader>
			<TableBody>
				{groupedRows.map((group) => (
					<React.Fragment key={group.user}>
						{/* Parent (group) row */}
						<TableRow
							className="bg-gray-100 cursor-pointer font-medium"
							onClick={() => toggleGroup(group.user)}
						>
							<TableCellCondensed colSpan={7}>
								{expandedGroups[group.user] ? (
									<ChevronDown className="inline mr-1 h-4 w-4" />
								) : (
									<ChevronRight className="inline mr-1 h-4 w-4" />
								)}
								{group.user} — {group.docCount} Document
								{group.docCount !== 1 && "s"}, Total Balance:{" "}
								{group.totalBalance.toLocaleString("en-US", {
									style: "currency",
									currency: "USD",
								})}
							</TableCellCondensed>
						</TableRow>
						{/* Child document rows for this group */}
						{expandedGroups[group.user] &&
							group.documents.map((doc) => (
								<TableRow key={doc.id}>
									{columns.map((col) => (
										<TableCellCondensed key={col.accessor as string}>
											{col.render
												? col.render(
														typeof col.accessor === "function"
															? col.accessor(doc)
															: (doc as any)[col.accessor],
														doc,
													)
												: typeof col.accessor === "function"
													? col.accessor(doc)
													: (doc as any)[col.accessor]}
										</TableCellCondensed>
									))}
								</TableRow>
							))}
					</React.Fragment>
				))}
			</TableBody>
		</Table>
	)
}

export default DocumentsUsageTable
