"use client"

import BaseBarChart from "@/components/charts/BaseBarChart"
import BaseLineChart from "@/components/charts/BaseLineChart"
import { ChartContainer } from "@/components/ui/chart"
import type { AdminDocumentSummary } from "@/types"
import type {
	AdminChartData,
	AdminChartDataOrganization,
	AdminChartDataUser,
} from "@/types/admin"
import * as React from "react"

// Helper function to convert a Date to a YYYY-MM-DD string.
function formatDate(date: Date): string {
	const year = date.getFullYear()
	const month = (date.getMonth() + 1).toString().padStart(2, "0")
	const day = date.getDate().toString().padStart(2, "0")
	return `${year}-${month}-${day}`
}

// Chart configuration for the document count line.
const chartConfig = {
	count: {
		label: "Documents",
		color: "#7FB2FF",
	},
}

// A custom tooltip that renders the date (or user) with total document count.
const CustomTooltip = ({ active, payload, label }: any) => {
	if (active && payload && payload.length) {
		const data = payload[0].payload
		if (data.date) {
			return (
				<div className="bg-background border p-2 shadow">
					<p className="font-bold">
						{new Date(label).toLocaleDateString("en-US", {
							month: "short",
							day: "numeric",
							year: "numeric",
						})}
					</p>
					<p className="mt-1">Total Documents: {data.count}</p>
				</div>
			)
		}
		if (data.user) {
			return (
				<div className="bg-background border p-2 shadow">
					<p className="font-bold">{data.user}</p>
					<p className="mt-1">Total Documents: {data.count}</p>
				</div>
			)
		}
		if (data.organization) {
			return (
				<div className="bg-background border p-2 shadow">
					<p className="font-bold">{data.organization}</p>
					<p className="mt-1">Total Documents: {data.count}</p>
				</div>
			)
		}
	}
	return null
}

export function DocumentsUsageChart({
	documents,
	isBarChart = false,
	groupBy = "date",
}: {
	documents: AdminDocumentSummary[]
	isBarChart?: boolean
	groupBy?: "date" | "user" | "organization"
}) {
	const effectiveGroupBy = isBarChart ? groupBy : "date"

	const chartData = React.useMemo(() => {
		if (effectiveGroupBy === "date") {
			const groups: Record<
				string,
				{ date: string; count: number; breakdown: Record<string, number> }
			> = {}

			for (const doc of documents) {
				if (!doc.createdAt) continue
				const dateKey = formatDate(new Date(doc.createdAt))
				if (!groups[dateKey]) {
					groups[dateKey] = { date: dateKey, count: 0, breakdown: {} }
				}
				groups[dateKey].count++
				const user = doc.createdBy || "Unknown"
				groups[dateKey].breakdown[user] = (groups[dateKey].breakdown[user] || 0) + 1
			}

			// Convert the grouped results into an array and sort by date.
			return Object.values(groups).sort(
				(a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
			)
		}
		// Group by user
		if (effectiveGroupBy === "user") {
			const groups: Record<string, AdminChartDataUser> = {}

			for (const doc of documents) {
				const userKey = doc.createdBy || "Unknown"
				if (!groups[userKey]) {
					groups[userKey] = { user: userKey, count: 0 }
				}
				groups[userKey].count++
			}
			return Object.values(groups).sort((a, b) => a.user.localeCompare(b.user))
		}
		// Group by organization
		if (effectiveGroupBy === "organization") {
			const groups: Record<string, AdminChartDataOrganization> = {}

			for (const doc of documents) {
				const orgKey = doc.organizationName || "Unknown"
				if (!groups[orgKey]) {
					groups[orgKey] = { organization: orgKey, count: 0 }
				}
				groups[orgKey].count++
			}
			return Object.values(groups).sort((a, b) =>
				a.organization.localeCompare(b.organization),
			)
		}
		return []
	}, [documents, effectiveGroupBy])

	if (isBarChart) {
		return (
			<BaseBarChart
				data={chartData as AdminChartData[]}
				xKey={effectiveGroupBy as keyof AdminChartData}
				yKeys={["count"]}
				barColors={[chartConfig.count.color]}
				chartHeight={250}
				margin={{ top: 20, right: 12, bottom: 20, left: 12 }}
				tooltipContent={<CustomTooltip />}
				chartConfig={chartConfig}
				showLabels={true}
				yTickFormatter={(value: number) => Math.round(value).toString()}
			/>
		)
	}

	return (
		<ChartContainer config={chartConfig} className="aspect-auto h-[250px] w-full">
			<BaseLineChart
				data={chartData as AdminChartData[]}
				xKey={effectiveGroupBy as keyof AdminChartData}
				yKeys={["count"]}
				lineColors={[chartConfig.count.color]}
				chartHeight={250}
				margin={{ top: 20, right: 12, bottom: 20, left: 12 }}
				xTickFormatter={(value) => {
					if (effectiveGroupBy === "date") {
						const date = new Date(value)
						return date.toLocaleDateString("en-US", {
							month: "short",
							day: "numeric",
						})
					}
					return value
				}}
				tooltipContent={<CustomTooltip />}
				chartConfig={chartConfig}
				yTickFormatter={(value: number) => Math.round(value).toString()}
			/>
		</ChartContainer>
	)
}

export default DocumentsUsageChart
