/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import {
	Table,
	TableBody,
	TableCellCondensed,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import { H4 } from "@/components/ui/typography"
import type { StripeInvoice } from "@/types"
import { formatCurrency, formatDateTime } from "@/utils"
import { ChevronDown, ChevronUp } from "lucide-react"
import type React from "react"
import { useState } from "react"

const Row: React.FC<{ invoice: StripeInvoice }> = ({ invoice }) => {
	const [open, setOpen] = useState(false)

	return (
		<>
			<TableRow>
				<TableCellCondensed>{invoice.id}</TableCellCondensed>
				<TableCellCondensed>{invoice.description}</TableCellCondensed>
				<TableCellCondensed>{formatCurrency(invoice.amountDue, true)}</TableCellCondensed>
				<TableCellCondensed>
					<Badge variant="outline">{invoice.status}</Badge>
				</TableCellCondensed>
				<TableCellCondensed>{invoice.clientMatter || "-"}</TableCellCondensed>
				<TableCellCondensed>
					{invoice.status === "open" && (
						<Button variant="outline" size="sm">
							Pay Now
						</Button>
					)}
				</TableCellCondensed>
				<TableCellCondensed>
					<Button variant="ghost" size="sm" onClick={() => setOpen(!open)}>
						{open ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
					</Button>
				</TableCellCondensed>
			</TableRow>
			{open && (
				<TableRow>
					<TableCellCondensed colSpan={9} className="p-0">
						<Card className="m-2 ">
							<CardHeader>
								<H4>Details</H4>
							</CardHeader>
							<CardContent>
								<Table>
									<TableHeader>
										<TableRow>
											<TableHead>Description</TableHead>
											<TableHead>Client Matter ID</TableHead>
											<TableHead>Quantity</TableHead>
											<TableHead>Unit Amount</TableHead>
											<TableHead>Currency</TableHead>
											<TableHead>Period</TableHead>
											<TableHead>Total</TableHead>
										</TableRow>
									</TableHeader>
									<TableBody>
										{invoice.lines.map((line, index) => (
											<TableRow key={index}>
												<TableCellCondensed>{line.description}</TableCellCondensed>
												<TableCellCondensed>{line.clientMatterId || "-"}</TableCellCondensed>
												<TableCellCondensed>{line.quantity}</TableCellCondensed>
												<TableCellCondensed>
													{formatCurrency(line.unitAmount, true)}
												</TableCellCondensed>
												<TableCellCondensed>{line.currency}</TableCellCondensed>
												<TableCellCondensed>
													{line.period
														? `${formatDateTime(line.period.start as Date)} - ${formatDateTime(
																line.period.end as Date,
															)}`
														: "-"}
												</TableCellCondensed>
												<TableCellCondensed>{formatCurrency(line.amount, true)}</TableCellCondensed>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</CardContent>
						</Card>
					</TableCellCondensed>
				</TableRow>
			)}
		</>
	)
}

const InvoicesTable: React.FC<{ invoices: StripeInvoice[] }> = ({ invoices }) => {
	return (
		<Card>
			<CardHeader>
				<div className="flex items-center gap-3 ">
					<H4>Invoices</H4>
				</div>
			</CardHeader>
			<CardContent>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead>ID</TableHead>
							<TableHead>Description</TableHead>
							<TableHead>Total</TableHead>
							<TableHead>Status</TableHead>
							<TableHead>Client Matter ID</TableHead>
							<TableHead />
							<TableHead />
						</TableRow>
					</TableHeader>
					<TableBody>
						{invoices.map((invoice) => (
							<Row key={invoice.id} invoice={invoice} />
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	)
}

export default InvoicesTable
