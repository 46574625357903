
import type { ProjectDocumentMetadata } from "@/types"
import type { DialogueResponse } from "@/types/chat"
import {
	getContentFromMessage,
	isCreatePlanDirective,
	isPromptUserDirectiveWithRelevantIds,
} from "@/utils/messageUtils"
import { ChatMessageBubble } from "./ChatMessageBubble"

import { PlanPhasesDisplay } from "./PlanPhasesResponse"
import { PromptUserDocuments } from "./PromptUserDocuments"
interface DialogueMessageRendererProps {
	message: DialogueResponse
	index: number
	messages: DialogueResponse[]
	isLastPromptUser: boolean
	fetchDocumentsMetadata: (ids: string[]) => Promise<ProjectDocumentMetadata[]>
	bottomRef?: React.RefObject<HTMLDivElement>
}

export const DialogueMessageRenderer: React.FC<DialogueMessageRendererProps> = ({
	message,
	index,
	messages,
	isLastPromptUser,
	fetchDocumentsMetadata,
	bottomRef,
}) => {
	const content = getContentFromMessage(message)


	if (
		(message.task &&
			"directiveName" in message.task &&
			!["create_plan", "prompt_user", "prompt_assistant"].includes(
				message.task.directiveName,
			)) ||
		!content ||
		content.trim() === ""
	) {
		return null
	}

	if (isCreatePlanDirective(message)) {
		return <PlanPhasesDisplay message={message} messages={messages} index={index} />
	}

	const isPromptUserWithIds = isPromptUserDirectiveWithRelevantIds(message)

	return (
		<>
			<ChatMessageBubble role={message.role} content={getContentFromMessage(message)} />
			{isPromptUserWithIds && (
				<PromptUserDocuments
					message={message}
					isLastPromptUser={isLastPromptUser}
					fetchDocumentsMetadata={fetchDocumentsMetadata}
					bottomRef={bottomRef}
				/>
			)}
		</>
	)
}
