import type { DocumentMetadata, DocumentRole } from "@/types/documents"
import { XIcon } from "lucide-react"
import { CheckIcon } from "lucide-react"
import { ChevronDownIcon } from "lucide-react"
import { useState } from "react"
import type React from "react"
import { Button } from "../ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover"
import { ScrollArea } from "../ui/scroll-area"
interface MultiDocumentSelectProps {
	selectedDocumentIds: string[]
	documents: DocumentMetadata[]
	onChange: (selected: string[]) => void
	disabled?: boolean
	label?: string
	documentRole?: DocumentRole
}

const MultiDocumentSelect: React.FC<MultiDocumentSelectProps> = ({
	selectedDocumentIds,
	documents,
	onChange,
	disabled,
	label,
	documentRole,
}) => {
	const [open, setOpen] = useState(false)

	const allDocumentsSelected = () => selectedDocumentIds.length === documents.length

	const handleDocumentToggle = (id: string) => {
		onChange(
			selectedDocumentIds.includes(id)
				? selectedDocumentIds.filter((item) => item !== id)
				: [...selectedDocumentIds, id],
		)
	}

	const handleSelectAll = () => {
		onChange(allDocumentsSelected() ? [] : documents.map((d) => d.id))
	}

	const handleDeselectAll = () => {
		onChange([])
	}

	const getDisplayText = (id: string) => {
		const document = documents.find((d) => d.id === id)
		return document?.nickname || id
	}
	return (
		<Popover
			open={open}
			onOpenChange={(isOpen) => (isOpen ? setOpen(true) : setOpen(false))}
		>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					aria-expanded={open}
					className="justify-between w-full"
					disabled={disabled}
				>
					<div className="flex items-center space-x-1 truncate">
						{allDocumentsSelected() ? (
							<span>
								{`All ${documentRole || ""} Documents`} ({selectedDocumentIds.length})
							</span>
						) : selectedDocumentIds.length > 0 ? (
							<>
								{selectedDocumentIds.slice(0, 5).map((id) => (
									<span key={id} className="text-secondary-foreground px-2 rounded border">
										{getDisplayText(id)}
									</span>
								))}
								{selectedDocumentIds.length > 5 && (
									<span className="text-secondary-foreground">
										+{selectedDocumentIds.length - 5} more
									</span>
								)}
							</>
						) : (
							<span>Select Documents</span>
						)}
					</div>
					<ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="p-0 min-w-[350px]" align="start">
				<ScrollArea className="h-[300px]">
					<div className="p-1">
						<Button
							variant="ghost"
							className="w-full justify-start"
							onClick={handleSelectAll}
						>
							<div className="flex items-center">
								<CheckIcon
									className={`mr-2 h-4 w-4 ${
										selectedDocumentIds.length === documents.length ? "opacity-100" : "opacity-0"
									}`}
								/>
								<span>Select All</span>
							</div>
						</Button>
						<Button
							variant="ghost"
							className="w-full justify-start"
							onClick={handleDeselectAll}
						>
							<div className="flex items-center">
								<XIcon
									className={`mr-2 h-4 w-4 ${
										selectedDocumentIds.length > 0 ? "opacity-100" : "opacity-0"
									}`}
								/>
								<span>Deselect All</span>
							</div>
						</Button>
						{documents.map((document) => (
							<Button
								key={document.id}
								variant="ghost"
								className="w-full justify-start"
								onClick={() => handleDocumentToggle(document.id)}
							>
								<div className="flex items-center">
									<CheckIcon
										className={`mr-2 h-4 w-4 ${
											selectedDocumentIds.includes(document.id) ? "opacity-100" : "opacity-0"
										}`}
									/>
									<span>{getDisplayText(document.id)}</span>
								</div>
							</Button>
						))}
					</div>
				</ScrollArea>
			</PopoverContent>
		</Popover>
	)
}

export default MultiDocumentSelect
