import type React from "react"
interface MetadataItemProps {
	label: string
	value: string
	isCompact?: boolean
}

export const MetadataItem: React.FC<MetadataItemProps> = ({
	label,
	value,
	isCompact = false,
}) => {
	// Function to truncate value in compact mode
	const displayValue =
		isCompact && value.length > 25 ? `${value.substring(0, 25)}...` : value

	return (
		<div className="flex items-center gap-1">
			<span className="text-secondary">{label}:</span>
			<span
				className={`text-tertiary ${isCompact ? "truncate max-w-[150px]" : ""}`}
				title={value}
			>
				{" "}
				{displayValue}
			</span>
		</div>
	)
}
