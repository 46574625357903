import { api } from "@/api"
import { DEFAULT_QUERY_OPTIONS, QUERY_KEYS } from "@/utils/query"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useQuery } from "@tanstack/react-query"

const useAdminBilling = (orgId?: string) => {
	// Queries
	const adminBilling = useQuery({
		queryKey: QUERY_KEYS.admin.billing(orgId),
		queryFn: () => api.getAdminBillingForOrg(orgId),
		enabled: !!orgId,
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		// Query data
		adminBilling: adminBilling.data,
		isLoading: adminBilling.isLoading,
	}
}

export { useAdminBilling }
