import { searchApi } from "@/api/searchApi"
import { useUnprocessedDocumentsContext } from "@/context/UnprocessedDocumentsContext"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi } from "@/hooks"
import { useAppStateStore } from "@/store"
import { ClaimTypes } from "@/types"
import type { ApiResponse, ClaimStrength, PatentNumberOption } from "@/types"
import type { SearchFilters } from "@/types"
import { DocumentRole, UnprocessedDocumentType } from "@/types"
import { convertToUtcDateString } from "@/utils/dateUtils"
import { useState } from "react"
import { useProjectSubjectDocuments } from "../../documents/hooks/useProjectSubjectDocuments"

export const useSearchFilters = (initialFilters?: Partial<SearchFilters>) => {
	const { subjectDocuments } = useProjectSubjectDocuments()

	// Get initial toDate from priority
	const getSubjectPriorityDate = (subjectId: string): Date | null => {
		const subjectDocument = subjectDocuments.find((doc) => doc.id === subjectId)
		if (!subjectDocument) return null

		const priorityDate =
			subjectDocument?.priorityDate || subjectDocument.patent?.originalPriorityDate
		if (priorityDate) {
			const parsedDate = new Date(priorityDate)
			if (!Number.isNaN(parsedDate.getTime())) {
				return parsedDate
			}
		}

		if (priorityDate) {
			return new Date(priorityDate)
		}

		return null
	}

	const getDefaultFilters = (): SearchFilters => ({
		fromDate: null,
		toDate: getSubjectPriorityDate(subjectDocuments[0]?.id),
		cpcCodes: [],
		countryCodes: [],
		assignees: [],
		types: [],
		statuses: [],
		maxResults: 25,
		dedupeFamilyId: true,
		selectedClaims: [],
		unselectedClaims: [],
		claimType: ClaimTypes.INDEPENDENT_CLAIMS,
		subjectId: subjectDocuments[0]?.id || "",
		whitelistedSites: [],
		blacklistedSites: [],
	})

	const [filters, setFilters] = useState<SearchFilters>({
		...getDefaultFilters(),
		...initialFilters,
	})

	const updateFilter = <K extends keyof SearchFilters>(
		key: K,
		value: SearchFilters[K],
	) => {
		setFilters((prev) => {
			const newFilters = { ...prev, [key]: value }
			return newFilters
		})

		if (key === "subjectId") {
			setFilters((prev) => {
				const newFilters = {
					...prev,
					toDate: getSubjectPriorityDate(value as string),
				}
				return newFilters
			})
		}
	}

	const resetFilters = () => {
		setFilters(getDefaultFilters())
	}

	// Add helper functions for claim management
	const updateSelectedClaims = (claims: ClaimStrength[]) => {
		updateFilter("selectedClaims", claims)
	}

	const updateUnselectedClaims = (claims: ClaimStrength[]) => {
		updateFilter("unselectedClaims", claims)
	}

	return {
		filters,
		updateFilter,
		setFilters,
		resetFilters,
		updateSelectedClaims,
		updateUnselectedClaims,
	}
}

/**
 * @description A custom hook for prior art searching
 */
const usePriorArtSearch = () => {
	const { handleError } = useApi()
	const { addUnprocessedDocuments, resetNewDocuments } = useUnprocessedDocumentsContext()
	const { addErrorMessage } = useAppStateStore()
	const [isLoading, setIsLoading] = useState(false)
	const [isRecommendationLoading, setIsRecommendationLoading] = useState(false)
	const [isError, setIsError] = useState(false)

	// Constants for retry logic
	const MAX_RETRIES = 2
	const RETRY_DELAY = 5000

	const resetSearch = () => {
		setIsLoading(false)
		setIsRecommendationLoading(false)
		setIsError(false)
	}

	const searchPriorArt = async (
		projectId: string,
		subjectId: string,
		searchPayload: {
			max_results?: number
			to_date?: string
			from_date?: string
			types?: string[]
			statuses?: string[]
			cpc_codes?: string[]
			country_codes?: string[]
			assignees?: string[]
			dedupe_family_id?: boolean
			limitation_ids?: string[]
			claim_type?: ClaimTypes
		} = {},
	): Promise<ApiResponse<PatentNumberOption[]>> => {
		try {
			const priorArt = await searchApi.search(projectId, subjectId, searchPayload)
			const parsedPriorArt = (priorArt as PatentNumberOption[]).map((patent) => {
				const prefix = patent.number.match(/^[A-Z]+/)?.[0] || ""
				return { ...patent, number: patent.number.replace(/-/g, ""), prefix }
			})
			return { success: true, data: parsedPriorArt }
		} catch (error) {
			console.error("Error searching for prior art:", error)
			return handleError(error, "Error searching for prior art")
		}
	}

	const searchPatch103References = async (
		projectId: string,
		subjectId: string,
		documentId: string,
		searchPayload: {
			max_results?: number
			to_date?: string
			from_date?: string
			cpc_codes?: string[]
			country_codes?: string[]
			assignees?: string[]
			dedupe_family_id?: boolean
			search_strategy?: "Regular" | "Advanced"
		} = {},
	): Promise<ApiResponse<PatentNumberOption[]>> => {
		try {
			const options = {
				document_id: documentId,
				...searchPayload,
			}
			const priorArt = await searchApi.getPatch103References(projectId, subjectId, options)
			const parsedPriorArt = (priorArt as PatentNumberOption[]).map((patent) => {
				const prefix = patent.number.match(/^[A-Z]+/)?.[0] || ""
				return { ...patent, number: patent.number.replace(/-/g, ""), prefix }
			})
			return { success: true, data: parsedPriorArt }
		} catch (error) {
			console.error("Error searching for patch 103 references:", error)
			return handleError(error, "Error searching for patch 103 references")
		}
	}

	const handleSearch = async (
		projectId: string,
		subjectId: string,
		filters: SearchFilters,
		options: {
			documentId?: string
			isPatch103?: boolean
			isNPL?: boolean
			isProduct?: boolean
			retryCount?: number
		} = {},
	) => {
		const {
			documentId,
			isPatch103 = false,
			isNPL = false,
			isProduct = false,
			retryCount = 0,
		} = options

		setIsLoading(true)
		setIsError(false)

		const payload = {
			max_results: filters.maxResults || 10,
			dedupe_family_id: filters.dedupeFamilyId,
			...(filters.toDate || filters.fromDate
				? {
						to_date: filters.toDate ? convertToUtcDateString(filters.toDate) : null,
						from_date: filters.fromDate ? convertToUtcDateString(filters.fromDate) : null,
					}
				: {}),
			...(filters.cpcCodes.length > 0 && { cpc_codes: filters.cpcCodes }),
			...(filters.countryCodes.length > 0 && {
				country_codes: filters.countryCodes,
			}),
			...(filters.assignees.length > 0 && { assignees: filters.assignees }),
			search_strategy: "Regular" as const,
		}
		// Add fields specific to regular search
		if (!isPatch103) {
			Object.assign(payload, {
				...(filters.types.length > 0 && { types: filters.types }),
				...(filters.statuses.length > 0 && { statuses: filters.statuses }),
				claim_type: filters.claimType,
				...(filters.selectedClaims.length > 0 && {
					limitation_ids: filters.selectedClaims.map((item) => item.id),
				}),
			})
		}

		try {
			let priorArt: ApiResponse<PatentNumberOption[]>
			if (isPatch103) {
				if (!documentId) {
					throw new Error("Document ID is required for Patch 103 search")
				}
				priorArt = await searchPatch103References(projectId, subjectId, documentId, payload)
			} else {
				priorArt = await searchPriorArt(projectId, subjectId, payload)
			}

			if (Array.isArray(priorArt.data) && priorArt.data.length > 0) {
				addUnprocessedDocuments(
					DocumentRole.PRIOR_ART,
					UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION,
					{
						patentDetails: priorArt.data,
					},
				)
				setIsLoading(false)
			} else {
				if (retryCount < MAX_RETRIES) {
					await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY))
					return handleSearch(projectId, subjectId, filters, {
						documentId,
						isPatch103,
						isNPL,
						isProduct,
						retryCount: retryCount + 1,
					})
				}
				addErrorMessage("Search timed out. Please try again.")
				setIsLoading(false)
				setIsError(true)
			}
		} catch (_error) {
			if (retryCount < MAX_RETRIES) {
				await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY))
				return handleSearch(projectId, subjectId, filters, {
					documentId,
					isPatch103,
					isNPL,
					isProduct,
					retryCount: retryCount + 1,
				})
			}
			setIsLoading(false)
			setIsError(true)
		}
	}

	const recommendPriorArt = async (
		projectId: string,
		subjectId: string,
		recommendPayload: {
			very_positive_publication_numbers?: string[]
			positive_publication_numbers?: string[]
			negative_publication_numbers?: string[]
			max_results?: number
			to_date?: string
			from_date?: string
			types?: string[]
			statuses?: string[]
			cpc_codes?: string[]
			country_codes?: string[]
			assignees?: string[]
			dedupe_family_id?: boolean
		} = {},
	): Promise<ApiResponse<PatentNumberOption[]>> => {
		try {
			const recommendations = await searchApi.recommend(
				projectId,
				subjectId,
				recommendPayload,
			)
			const parsedRecommendations = (recommendations as PatentNumberOption[]).map(
				(patent) => {
					const prefix = patent.number.match(/^[A-Z]+/)?.[0] || ""
					return { ...patent, number: patent.number.replace(/-/g, ""), prefix }
				},
			)
			return { success: true, data: parsedRecommendations }
		} catch (error) {
			console.error("Error recommending prior art:", error)
			return handleError(error, "Error recommending prior art")
		}
	}

	const handleRecommend = async (
		projectId: string,
		subjectId: string,
		very_positive_publication_numbers: string[],
		positive_publication_numbers: string[],
		negative_publication_numbers: string[],
		excluded_publication_numbers: string[],
		filters: SearchFilters,
		max_results = 5,
		retryCount = 0,
	) => {
		setIsRecommendationLoading(true)
		setIsError(false)
		resetNewDocuments()

		const payload = {
			very_positive_publication_numbers: very_positive_publication_numbers,
			positive_publication_numbers: positive_publication_numbers,
			negative_publication_numbers: negative_publication_numbers,
			excluded_publication_numbers: excluded_publication_numbers,
			max_results: max_results,
			dedupe_by_family_id: filters.dedupeFamilyId,
			...(filters.toDate || filters.fromDate
				? {
						to_date: filters.toDate ? convertToUtcDateString(filters.toDate) : undefined,
						from_date: filters.fromDate
							? convertToUtcDateString(filters.fromDate)
							: undefined,
					}
				: {}),
			...(filters.types.length > 0 && { types: filters.types }),
			...(filters.statuses.length > 0 && { statuses: filters.statuses }),
			...(filters.cpcCodes.length > 0 && { cpc_codes: filters.cpcCodes }),
			...(filters.countryCodes.length > 0 && {
				country_codes: filters.countryCodes,
			}),
			...(filters.assignees.length > 0 && { assignees: filters.assignees }),
		}

		try {
			const recommendations = await recommendPriorArt(projectId, subjectId, payload)
			if (Array.isArray(recommendations.data) && recommendations.data.length > 0) {
				addUnprocessedDocuments(
					DocumentRole.PRIOR_ART,
					UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION,
					{
						patentDetails: recommendations.data,
					},
					true,
				)
				setIsRecommendationLoading(false)
			} else {
				if (retryCount < MAX_RETRIES) {
					await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY))
					return handleRecommend(
						projectId,
						subjectId,
						very_positive_publication_numbers,
						positive_publication_numbers,
						negative_publication_numbers,
						excluded_publication_numbers,
						filters,
						retryCount + 1,
					)
				}
				addErrorMessage("Recommend timed out. Please try again.")
				setIsRecommendationLoading(false)
				setIsError(true)
			}
		} catch (_error) {
			if (retryCount < MAX_RETRIES) {
				await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY))
				return handleRecommend(
					projectId,
					subjectId,
					very_positive_publication_numbers,
					positive_publication_numbers,
					negative_publication_numbers,
					excluded_publication_numbers,
					filters,
					retryCount + 1,
				)
			}
			setIsRecommendationLoading(false)
			setIsError(true)
		}
	}

	return {
		searchPriorArt,
		searchPatch103References,
		handleSearch,
		recommendPriorArt,
		handleRecommend,
		isLoading,
		isRecommendationLoading,
		isError,
		resetSearch,
	}
}

export default usePriorArtSearch
