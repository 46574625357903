import type { ProjectType } from "@/types"
import type {
	DocumentToProcess,
	ProcessSettings,
	ProcessingChartStatus,
} from "@/types/documents"
import type {
	CreateProjectRequest,
	ProcessContextRequest,
	ProcessInfringementRequest,
	ProcessPriorArtRequest,
	ProcessSubjectsRequest,
} from "@/types/request"
import { apiClient } from "./client"

export const processApi = {
	async processSubjectDocuments(
		projectId: string,
		documents: DocumentToProcess[],
		settings: ProcessSettings,
		chartAgainstSelf: boolean,
		priorArtIds: string[] | null,
		infringementIds: string[] | null,
	): Promise<ProcessingChartStatus[]> {
		const payload: ProcessSubjectsRequest = {
			project_id: projectId,
			documents: documents,
			settings: settings,
			prior_art_ids_subset: priorArtIds,
			infringement_ids_subset: infringementIds,
			chart_against_self: chartAgainstSelf,
		}
		return await apiClient.post("process_subject", payload)
	},

	async processPriorArtDocuments(
		projectId: string,
		documents: DocumentToProcess[],
		settings: ProcessSettings,
		subjectIdsSubset: string[],
	): Promise<ProcessingChartStatus[]> {
		const payload: ProcessPriorArtRequest = {
			project_id: projectId,
			documents: documents,
			settings: settings,
			subject_ids_subset: subjectIdsSubset,
		}
		return await apiClient.post("process_prior_art", payload)
	},

	async processInfringementDocuments(
		projectId: string,
		documents: DocumentToProcess[],
		settings: ProcessSettings,
		subjectIdsSubset: string[] | null,
	): Promise<ProcessingChartStatus[]> {
		const payload: ProcessInfringementRequest = {
			project_id: projectId,
			documents: documents,
			settings: settings,
			subject_ids_subset: subjectIdsSubset,
		}
		return await apiClient.post("process_infringement", payload)
	},

	async processContextDocuments(
		projectId: string,
		documents: DocumentToProcess[],
		settings: ProcessSettings,
	): Promise<ProcessingChartStatus[]> {
		const payload: ProcessContextRequest = {
			project_id: projectId,
			documents: documents,
			settings: settings,
		}
		return await apiClient.post("process_context", payload)
	},

	async createProject(
		name: string,
		type: ProjectType,
		client_number: string,
		documents: DocumentToProcess[],
		default_budget: number | null,
		chart_against_self: boolean,
	) {
		const payload: CreateProjectRequest = {
			name: name,
			type: type,
			client_number: client_number,
			subjects_to_process: documents,
			default_budget: default_budget,
			chart_against_self: chart_against_self,
		}
		return await apiClient.post("post_create_project", payload)
	},
}
