import { DocumentNamePill } from "@/components/documents/DocumentNamePill"
import { TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { useProjectContext } from "@/context/ProjectContext"
import { useProjectChartsMatrix } from "@/features/charts/hooks/queries/useProjectChartsMatrix"
import type React from "react"

const ProjectChartsMatrixHeader: React.FC<{ colWidthPercent: number }> = ({
	colWidthPercent,
}) => {
	const { projectId } = useProjectContext()
	const { projectChartsMatrix } = useProjectChartsMatrix(projectId)
	if (!projectChartsMatrix?.length) return null

	// Use the first row's cells for column headings:
	const singleRow = projectChartsMatrix[0].cells

	return (
		<TableHeader className="sticky top-0 z-10 bg-background">
			<TableRow>
				<TableHead
					className="sticky left-0 z-20 bg-background w-[100px]"
					style={{ width: "100px" }}
				/>
				{singleRow.map((cell, _) => (
					<TableHead
						key={`col-${cell.chartId}`}
						className="relative h-auto min-w-[10px] pr-4"
						style={{ width: `${colWidthPercent}%` }}
					>
						<div className="flex flex-wrap gap-2">
							{cell.documents.map((docId) => (
								<DocumentNamePill key={docId} documentId={docId} size="sm" />
							))}
						</div>
					</TableHead>
				))}
			</TableRow>
		</TableHeader>
	)
}

export default ProjectChartsMatrixHeader
