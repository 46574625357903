import { api } from "@/api"
import { useProjectContext } from "@/context/ProjectContext"
import { DocumentRole, type ProcessType, type ProcessingChartStatus } from "@/types"
import type { DocumentToProcess, ProcessSettings } from "@/types/documents"
import { getDocumentRoleQueryKey } from "@/utils/query/invalidation"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export type ProcessMutationInput = {
	documentRole: DocumentRole
	processType: ProcessType
	documentsToProcess: DocumentToProcess[] // Already converted documents
	settings: ProcessSettings
	chartAgainstSelf: boolean
	subjectIds?: string[]
	priorArtIds?: string[]
	infringementIds?: string[]
}

const useProcessMutation = () => {
	const { projectId } = useProjectContext()
	const queryClient = useQueryClient()

	const mutation = useMutation<ProcessingChartStatus[], unknown, ProcessMutationInput>({
		mutationFn: async (variables: ProcessMutationInput) => {
			const {
				documentRole,
				documentsToProcess,
				settings,
				chartAgainstSelf,
				subjectIds,
				priorArtIds,
				infringementIds,
			} = variables

			let response: ProcessingChartStatus[] = []

			// Delegate to the appropriate API function based on documentRole.
			switch (documentRole) {
				case DocumentRole.SUBJECT:
					response = await api.processSubjectDocuments(
						projectId,
						documentsToProcess,
						settings,
						chartAgainstSelf,
						priorArtIds,
						infringementIds,
					)
					break
				case DocumentRole.PRIOR_ART:
					response = await api.processPriorArtDocuments(
						projectId,
						documentsToProcess,
						settings,
						subjectIds,
					)
					break
				case DocumentRole.INFRINGEMENT:
					response = await api.processInfringementDocuments(
						projectId,
						documentsToProcess,
						settings,
						subjectIds,
					)
					break
				case DocumentRole.CONTEXT:
					response = await api.processContextDocuments(
						projectId,
						documentsToProcess,
						settings,
					)
					break
				default:
					throw new Error("Unsupported document role")
			}

			return response
		},

		onSuccess: (data, variables) => {
			// Invalidate document role query on success
			const queryKeyToInvalidate = getDocumentRoleQueryKey(
				variables.documentRole,
				projectId,
			)
			queryClient.invalidateQueries({ queryKey: queryKeyToInvalidate, exact: true })
		},
	})

	return mutation
}

export default useProcessMutation
