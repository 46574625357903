import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import type React from "react"

interface AdminDetailCardProps {
	title: string
	value: string
	icon: React.ReactNode
}

const AdminDetailCard: React.FC<AdminDetailCardProps> = ({ title, value, icon }) => {
	return (
		<Card className="flex-1">
			<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
				<CardTitle className="text-sm font-medium">{title}</CardTitle>
				{icon && <div className="text-2xl text-muted-foreground">{icon}</div>}
			</CardHeader>
			<CardContent>
				<div className="text-2xl font-bold">{value}</div>
			</CardContent>
		</Card>
	)
}

export default AdminDetailCard
