/*
 * Copyright AndAI, Inc. 2025. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc.
 * and is protected as a trade secret.
 */

import { Loader, ProjectPage } from "@/components"
import { useDocumentNaming } from "@/hooks"
import { PAGE_DISPLAY_NAMES, PageName } from "@/types/pages"
import type React from "react"
import { useParams } from "react-router-dom"
import InvalidityChartPageContent from "./InvalidityChartPageContent"
import { ChartsContextProvider } from "./context/ChartsContext"

const InvalidityChartsPage: React.FC = () => {
	const { projectId, subjectId, invalidityChartId } = useParams()
	const { getDocumentName } = useDocumentNaming()
	return (
		<ProjectPage
			pageName={`${PAGE_DISPLAY_NAMES[PageName.ProjectCharts]} - ${getDocumentName(subjectId)}`}
		>
			<ChartsContextProvider>
				{!invalidityChartId || !subjectId || !projectId ? (
					<Loader />
				) : (
					<InvalidityChartPageContent />
				)}
			</ChartsContextProvider>
		</ProjectPage>
	)
}

export default InvalidityChartsPage
