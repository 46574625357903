/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import { DocumentNamePill } from "@/components/documents/DocumentNamePill"
import TooltipButton from "@/components/ui/tooltip-button"
import { useProjectContext } from "@/context/ProjectContext"
import { useFeedbackData } from "@/features/charts/hooks/useFeedbackData"
import type { ChunkToLimitation } from "@/types"
import { CopyPlusIcon, SearchIcon } from "lucide-react"
import { ChevronDown, ChevronRight } from "lucide-react"
import type React from "react"
import { useState } from "react"
import { useChartsContext } from "../../context/ChartsContext"
import useChunkToLimitationUpdates from "../../hooks/mutations/useChunkToLimitationUpdates"
import CopyCitationsModal from "../modals/CopyCitationsModal"
import InvalidityTableCitation from "./InvalidityTableCitation"
interface InvalidityTableDocumentCitationGroupProps {
	documentId: string
	claimLimitationId: string
	claimLimitationLabel: string
	citations: ChunkToLimitation[]
	isLoading?: boolean
}

/**
 * @description Div-based citation cell
 */
const InvalidityTableDocumentCitationGroup: React.FC<
	InvalidityTableDocumentCitationGroupProps
> = ({
	documentId,
	claimLimitationId,
	claimLimitationLabel,
	citations = [],
	isLoading = false,
}) => {
	const { projectId } = useProjectContext()
	const { findMoreCitationsForLimitation } = useChunkToLimitationUpdates()
	const { positiveFeedbackData, negativeFeedbackData } = useFeedbackData(
		projectId,
		documentId,
		claimLimitationId,
	)
	const { isCombination } = useChartsContext()

	const [isInvalidityLoading, setIsInvalidityLoading] = useState(false)
	const showNoMoreCitations = false
	const [showCopyCitationsModal, setShowCopyCitationsModal] = useState(false)
	const [isExpanded, setIsExpanded] = useState(true)

	const shownCitations = citations.filter((citation) => !citation.removed)

	const findMoreCitations = async () => {
		setIsInvalidityLoading(true)
		try {
			const positiveExamples = positiveFeedbackData
				.map((citation) => citation.documentChunk?.text)
				.filter((text): text is string => Boolean(text))
			const negativeExamples = negativeFeedbackData
				.map((citation) => citation.documentChunk?.text)
				.filter((text): text is string => Boolean(text))
			await findMoreCitationsForLimitation({
				claimLimitationId,
				documentId,
				positiveExamples,
				negativeExamples,
			})
		} finally {
			setIsInvalidityLoading(false)
		}
	}

	return (
		<div
			className={`w-full px-4 py-3 cursor-pointer bg-background ${
				isExpanded ? "rounded-lg" : "rounded-none border-y"
			}`}
		>
			{/* Only show the document name pill and find more button if this is a combination, else show search in parent */}
			{isCombination && (
				<div className="flex items-center justify-between">
					<div className="flex items-center gap-2">
						<DocumentNamePill doc={{ id: documentId, name: "" }} size="sm" />
						<TooltipButton
							icon={<SearchIcon className="h-4 w-4" />}
							tooltipText="Find more"
							onClick={() => findMoreCitations()}
							disabled={isInvalidityLoading || showNoMoreCitations}
							srText="Find more citations"
							size="xsIcon"
							variant="ghost"
						/>
						<TooltipButton
							icon={<CopyPlusIcon className="h-4 w-4" />}
							tooltipText="Copy evidence"
							onClick={() => setShowCopyCitationsModal(true)}
							disabled={isInvalidityLoading}
							srText="Copy evidence from another claim"
							size="xsIcon"
							variant="ghost"
						/>
					</div>

					<div
						className="flex items-center hover:text-primary"
						onClick={() => setIsExpanded(!isExpanded)}
					>
						{isExpanded ? (
							<ChevronDown className="h-4 w-4" />
						) : (
							<ChevronRight className="h-4 w-4" />
						)}
					</div>
				</div>
			)}

			{isExpanded && (
				<>
					{showNoMoreCitations && (
						<div className="mb-3 text-center">
							<p className="text-sm text-muted-foreground">
								No more citations found. Provide feedback to find more.
							</p>
						</div>
					)}
					{isInvalidityLoading && (
						<div className="mb-2">
							<Loader message="Retrieving invalidity..." />
						</div>
					)}

					{isLoading && <Loader />}
					{!isLoading &&
						shownCitations.map((citation, citationIndex) => (
							<InvalidityTableCitation
								key={`${1}-${documentId}-${claimLimitationId}-${citationIndex}`}
								citation={citation}
							/>
						))}
				</>
			)}

			<CopyCitationsModal
				open={showCopyCitationsModal}
				handleClose={() => setShowCopyCitationsModal(false)}
				documentId={documentId}
				claimLimitationId={claimLimitationId}
				claimLimitationLabel={claimLimitationLabel}
			/>
		</div>
	)
}

export default InvalidityTableDocumentCitationGroup
