import { SettingsSection } from "@/components"
import ClaimSelect from "@/components/claims/ClaimMultiSelect"
import VariableInput from "@/components/input/VariableInput"
import { Alert, AlertDescription } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import { FancyMultiSelect } from "@/components/ui/multiselect"
import { RadioGroup, RadioGroupItemWithLabel } from "@/components/ui/radio-group"
import { useProjectContext } from "@/context/ProjectContext"
import ColorSelect from "@/features/charts/components/selects/ColorSelect"
import { useClaims, useProjectMetadata, useProjectSettings } from "@/hooks"
import { useAppStateStore } from "@/store"
import { ChartColor, FigurePlacement, type InvalidityChart } from "@/types"
import { nanoid } from "nanoid"
import type React from "react"
import { useState } from "react"
import useInvalidityChartExport from "../../hooks/useInvalidityChartExport"
interface ExportChartsModalProps {
	open: boolean
	handleClose: () => void
	activeCharts: InvalidityChart[]
}

/**
 * @description Exports charts to a Microsoft Word or Excel file
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 */
const ExportChartsModal: React.FC<ExportChartsModalProps> = ({
	open,
	handleClose,
	activeCharts,
}) => {
	const { subjectId } = useProjectContext()
	const { getInvalidityChartExport } = useInvalidityChartExport()
	const { projectName } = useProjectMetadata()
	const { subjectClaims } = useClaims(subjectId)
	const { settings } = useProjectSettings()

	// Global state from store
	const { addErrorMessage, addLoadingMessage, removeLoadingMessage } = useAppStateStore()

	// Local state
	const [wordChecked, setWordChecked] = useState(true)
	const [excelChecked, _setExcelChecked] = useState(false)
	const [selectedColors, setSelectedColors] = useState<string[]>([
		ChartColor.GREEN,
		ChartColor.YELLOW,
		ChartColor.RED,
	])
	const [includeSummaryCharts, setIncludeSummaryCharts] = useState(false)
	const [includeClaims, setIncludeClaims] = useState(true)
	const [alertVisible, setAlertVisible] = useState(false)
	const [alertMessage, setAlertMessage] = useState("")
	const [introductionLanguage, setIntroductionLanguage] = useState(
		settings?.wordIntroductionLanguage ?? null,
	)
	const [selectedClaims, setSelectedClaims] = useState([])
	const [chartIds, setChartIds] = useState([])
	const [patentCitationFormat, setPatentCitationFormat] = useState(
		settings?.usPatentCitationFormat ?? null,
	)
	const [applicationCitationFormat, setApplicationCitationFormat] = useState(
		settings?.usApplicationCitationFormat ?? null,
	)
	const [documentCitationFormat, setDocumentCitationFormat] = useState(
		settings?.uploadedDocumentCitationFormat ?? null,
	)
	const [foreignDocumentCitationFormat, setForeignDocumentCitationFormat] = useState(
		settings?.nonUsPatentCitationFormat ?? null,
	)
	const [figureFormat, _setFigureFormat] = useState(null)
	const [titleFormat, setTitleFormat] = useState(
		settings?.wordClaimChartTitleFormat ?? null,
	)
	const [isCitationBefore, setIsCitationBefore] = useState<boolean>(
		settings?.isCitationBefore ?? true,
	)
	const [figurePlacement, setFigurePlacement] = useState<FigurePlacement>(
		FigurePlacement.GROUPED,
	)

	const handleClaimSelect = (selectedClaims: string[]) => {
		setSelectedClaims(selectedClaims)
	}

	// Add resetForm function to reset all state values
	const resetForm = () => {
		setWordChecked(true)
		setSelectedColors([ChartColor.GREEN, ChartColor.YELLOW, ChartColor.RED])
		setIncludeSummaryCharts(false)
		setIncludeClaims(true)
		setAlertVisible(false)
		setAlertMessage("")
		setIntroductionLanguage(settings?.wordIntroductionLanguage ?? "")
		setSelectedClaims([])
		setChartIds([])
		setPatentCitationFormat(
			settings?.usPatentCitationFormat ?? "{{document_nickname}} at {{columns_lines}}",
		)
		setApplicationCitationFormat(
			settings?.usApplicationCitationFormat ?? "{{document_nickname}} at [{{paragraphs}}]",
		)
		setDocumentCitationFormat(
			settings?.uploadedDocumentCitationFormat ?? "{{document_nickname}} at p. {{pages}}",
		)
		setForeignDocumentCitationFormat(
			settings?.nonUsPatentCitationFormat ?? "{{document_nickname}} at [{{paragraphs}}]",
		)
		setTitleFormat(
			settings?.wordClaimChartTitleFormat ??
				'Invalidity of {{subject_number}} ("{{subject_nickname}}") in view of {{document_number_1}}',
		)
		setIsCitationBefore(settings?.isCitationBefore ?? true)
		setFigurePlacement(FigurePlacement.GROUPED)
	}

	// Modify handleClose to reset form
	const handleModalClose = () => {
		resetForm()
		handleClose()
	}

	/**
	 * @description Exports charts to a Microsoft Word file
	 * @param {boolean} includeFeatures - whether to include feature charts
	 * @param {boolean} includeClaims - whether to include claim charts
	 * @param {boolean} includeSummary - whether to include summary charts
	 */
	const exportProject = async (
		finalColors: string[],
		finalClaims: string[],
		exportType: string,
		finalChartIds: string[],
	) => {
		const response = await getInvalidityChartExport(
			subjectId,
			includeClaims,
			includeSummaryCharts,
			introductionLanguage,
			finalChartIds,
			finalColors,
			finalClaims,
			exportType,
			isCitationBefore,
			patentCitationFormat,
			applicationCitationFormat,
			documentCitationFormat,
			foreignDocumentCitationFormat,
			figureFormat,
			figurePlacement,
			titleFormat,
		)
		if (!response.success) {
			addErrorMessage("Error downloading file. Try again later.")
			return
		}
		if (!response.data) {
			if (process.env.NODE_ENV !== "production") {
				console.error("No download URL found")
			}
			addErrorMessage("Error downloading file. Try again later.")
			return
		}
	}

	// Modify handleDownloadClick to reset form after successful submission
	const handleDownloadClick = async () => {
		// Validate user input on required selections
		const fileFormatSelected = wordChecked || excelChecked
		const chartOptionsSelected = includeSummaryCharts || includeClaims
		if (!fileFormatSelected && !chartOptionsSelected) {
			setAlertMessage("Please select at least one file format and chart option.")
			setAlertVisible(true)
			return
		}
		if (!fileFormatSelected && chartOptionsSelected) {
			setAlertMessage("Please select at least one file format.")
			setAlertVisible(true)
			return
		}
		if (fileFormatSelected && !chartOptionsSelected) {
			setAlertMessage("Please select at least one chart option.")
			setAlertVisible(true)
			return
		}
		if (chartIds.length === 0) {
			setAlertMessage("Please select at least one chart.")
			setAlertVisible(true)
			return
		}
		setAlertVisible(false)
		const key = nanoid()
		addLoadingMessage(`Downloading charts for ${projectName}...`, key)
		handleModalClose()

		const finalColors =
			selectedColors?.length === 0 || selectedColors?.length === 3 ? [] : selectedColors

		let finalClaims = []
		if (
			includeClaims &&
			selectedClaims?.length > 0 &&
			selectedClaims?.length !== subjectClaims?.length
		) {
			finalClaims = selectedClaims
		}

		let exportType = ""
		if (wordChecked && excelChecked) {
			exportType = "both"
		} else if (wordChecked) {
			exportType = "word"
		} else if (excelChecked) {
			exportType = "excel"
		}

		await exportProject(finalColors, finalClaims, exportType, chartIds)
		removeLoadingMessage(key)
	}

	return (
		<Dialog open={open} onOpenChange={handleModalClose}>
			<DialogContent className="sm:max-w-[1200px] flex flex-col max-h-[90vh]">
				<DialogHeader>
					<DialogTitle>Download Charts</DialogTitle>
				</DialogHeader>

				<div className="grid gap-4 py-2 overflow-y-auto flex-1">
					{/* <div className="space-y-2">
						<SettingsSection label="Chart types *" description="">
							<div className="flex flex-wrap gap-4">
								<div className="flex items-center space-x-2">
									<div className="flex items-center space-x-2">
										<CheckboxWithText
											id="claims"
											checked={includeClaims}
											onCheckedChange={(checked) => setIncludeClaims(checked as boolean)}
											label="Claim Charts"
										/>
									</div>
								</div>
							</div>
						</SettingsSection>
					</div> */}

					<div className="space-y-2">
						<SettingsSection
							label="Chart options *"
							description="Select the references, colors, and elements to include in the export"
						>
							<div className="flex flex-wrap gap-2 flex-col">
								<div className="flex gap-2">
									<FancyMultiSelect
										items={activeCharts.map((chart) => ({
											value: chart.id,
											label: chart.name,
										}))}
										selected={chartIds}
										onChange={setChartIds}
										optionType="charts"
									/>
									{includeClaims && subjectClaims?.length > 0 && (
										<ClaimSelect
											claims={subjectClaims}
											onClaimSelect={handleClaimSelect}
											abbreviated={false}
										/>
									)}
									<ColorSelect
										colors={[ChartColor.GREEN, ChartColor.YELLOW, ChartColor.RED]}
										selectedColors={selectedColors}
										setSelectedColors={setSelectedColors}
									/>
								</div>
							</div>
						</SettingsSection>
					</div>

					{/* <div className="space-y-2">
						<SettingsSection label="File format *" description="">
							<div className="flex flex-wrap gap-4">
								<div className="flex items-center space-x-2">
									<CheckboxWithText
										id="word"
										checked={wordChecked}
										onCheckedChange={(checked) => setWordChecked(checked as boolean)}
										label="Microsoft Word (.docx)"
									/>
								</div>
								<div className="flex items-center space-x-2">
                  <CheckboxWithText
                    id="excel"
                    checked={excelChecked}
                    onCheckedChange={(checked) =>
                      setExcelChecked(checked as boolean)
                    }
                    label="Microsoft Excel (.xlsx)"
                  />
                </div>
							</div>
						</SettingsSection>
					</div> */}

					{wordChecked && (
						<div>
							<SettingsSection
								label="Word invalidity claim chart title"
								description="Format for the title displayed at the top of the invalidity claim chart documents"
							>
								<div className="space-y-2">
									<VariableInput
										label=""
										value={titleFormat}
										onChange={setTitleFormat}
										type="title"
										placeholder='e.g., Invalidity of {{subject_number}} ("{{subject_nickname}}")\nin view of {{document_number_1}}'
										multiline={true}
									/>
								</div>
							</SettingsSection>
							<SettingsSection
								label="Word introduction language"
								description="Language displayed at the beginning of the Word export above the claim charts"
							>
								<div className="space-y-2 mt-2">
									<VariableInput
										label=""
										value={introductionLanguage}
										onChange={setIntroductionLanguage}
										type="introduction"
										multiline={true}
									/>
								</div>
							</SettingsSection>
						</div>
					)}

					<div className="space-y-4">
						<SettingsSection label="Citation settings" description="">
							<div className="space-y-4">
								{wordChecked && (
									<div>
										<Label className="text-sm">Position</Label>
										<RadioGroup
											value={isCitationBefore ? "start" : "end"}
											onValueChange={(value) => setIsCitationBefore(value === "start")}
											className="flex gap-4 mt-2"
										>
											<RadioGroupItemWithLabel value="start" id="start" label="Before language" />
											<RadioGroupItemWithLabel value="end" id="end" label="After language" />
										</RadioGroup>
									</div>
								)}

								<div className="grid grid-cols-2 gap-4">
									<VariableInput
										label="US Patent Format"
										value={patentCitationFormat}
										onChange={setPatentCitationFormat}
										type="citation"
										placeholder="e.g., {{document_nickname}} at {{columns_lines}}"
										multiline={true}
									/>

									<VariableInput
										label="US Patent Application Format"
										value={applicationCitationFormat}
										onChange={setApplicationCitationFormat}
										type="citation"
										placeholder="e.g., {{document_nickname}} at [{{paragraphs}}]"
										multiline={true}
									/>

									<VariableInput
										label="Uploaded Document Format"
										value={documentCitationFormat}
										onChange={setDocumentCitationFormat}
										type="citation"
										placeholder="e.g., {{document_nickname}} at p. {{pages}}"
										multiline={true}
									/>

									<VariableInput
										label="Non-US Patent Format"
										value={foreignDocumentCitationFormat}
										onChange={setForeignDocumentCitationFormat}
										type="citation"
										placeholder="e.g., {{document_nickname}} at [{{paragraphs}}]"
										multiline={true}
									/>
								</div>
							</div>
						</SettingsSection>

						{/* Figure Settings */}
						{wordChecked && (
							<SettingsSection
								label="Referenced figure settings"
								description="Position of figures in the exported charts. This does not apply to figure citations you have added directly from the document."
							>
								<div className="flex gap-8 items-start">
									<div>
										<Label className="text-sm">Referenced Figure Placement</Label>
										<RadioGroup
											value={figurePlacement}
											onValueChange={(value) => {
												setFigurePlacement(value as FigurePlacement)
											}}
											className="flex gap-4 mt-2"
										>
											<div className="flex items-center space-x-2">
												<RadioGroupItemWithLabel
													value={FigurePlacement.GROUPED}
													id={FigurePlacement.GROUPED}
													label="Grouped for all element citations"
												/>
											</div>
											<div className="flex items-center space-x-2">
												<RadioGroupItemWithLabel
													value={FigurePlacement.EMBEDDED}
													id={FigurePlacement.EMBEDDED}
													label="Embedded with each citation"
												/>
											</div>
											<div className="flex items-center space-x-2">
												<RadioGroupItemWithLabel
													value={FigurePlacement.OMITTED}
													id={FigurePlacement.OMITTED}
													label="Omitted from export"
												/>
											</div>
										</RadioGroup>
									</div>
								</div>
							</SettingsSection>
						)}
					</div>
				</div>

				{alertVisible && (
					<Alert variant="destructive" className="mt-2">
						<AlertDescription>{alertMessage}</AlertDescription>
					</Alert>
				)}

				<DialogFooter className="mt-2 pt-2">
					<div className="flex flex-row justify-between gap-3">
						<div className="flex items-center gap-2">
							<Button variant="outline" onClick={handleModalClose}>
								Cancel
							</Button>
							<Button
								onClick={handleDownloadClick}
								disabled={
									(!wordChecked && !excelChecked) || (!includeSummaryCharts && !includeClaims)
								}
							>
								Download
							</Button>
						</div>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default ExportChartsModal
