/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
"use client"

import { ChevronsUpDown, CircleHelp, LogOut, MoonIcon, SunIcon } from "lucide-react"

import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useIntercom } from "@/hooks/integrations/useIntercom"
import { useTheme } from "@/styled/ThemeProvider"
import { capitalizeFirstLetter } from "@/utils/formatStrings"
import { useLogoutFunction } from "@propelauth/react"
import { useAuthInfo } from "@propelauth/react"
import { useEffect } from "react"
import { SidebarMenuButton } from "../ui/sidebar"

/**
 * @description Nav user component for the sidebar
 */
export function NavUser() {
	const logout = useLogoutFunction()
	const authInfo = useAuthInfo()
	const { theme, setTheme } = useTheme()
	const { hideLauncher } = useIntercom()

	// Get the raw names (defaulting to name parts from email if no first name)
	const rawFirstName =
		authInfo.user?.firstName || authInfo.user?.email.split("@")[0] || ""
	const rawLastName = authInfo.user?.lastName || ""

	// Capitalize the first letters
	const firstName = capitalizeFirstLetter(rawFirstName)
	const lastName = capitalizeFirstLetter(rawLastName)
	const email = authInfo.user?.email || ""

	const handleLogout = async () => {
		await logout(false) // Log out without redirecting
	}

	const handleDarkMode = () => {
		setTheme(theme === "light" ? "dark" : "light")
	}

	const handleIntercomClick = () => {
		const w = window as any
		if (typeof w.Intercom === "function") {
			w.Intercom("show")
		}
	}

	useEffect(() => {
		hideLauncher()
		// If needed, you can re-show or clean up Intercom here
		return () => {}
	}, [hideLauncher])

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<SidebarMenuButton
					size="lg"
					className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
				>
					<Avatar className="h-8 w-8 rounded-lg">
						{/* <AvatarImage src={authInfo.user?.avatar} alt={authInfo.user?.name} /> */}
						<AvatarFallback className="rounded-lg">
							{firstName.charAt(0).toUpperCase()}
							{lastName.charAt(0).toUpperCase()}
						</AvatarFallback>
					</Avatar>
					<div className="grid flex-1 text-left text-sm leading-tight">
						<span className="truncate font-semibold">{`${firstName} ${lastName}`}</span>
						<span className="truncate text-xs">{email}</span>
					</div>
					<ChevronsUpDown className="ml-auto size-4" />
				</SidebarMenuButton>
			</DropdownMenuTrigger>
			<DropdownMenuContent
				className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
				side={"right"}
				align="end"
				sideOffset={4}
			>
				<DropdownMenuLabel className="p-0 font-normal">
					<div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
						<Avatar className="h-8 w-8 rounded-lg">
							<AvatarFallback className="rounded-lg">
								{firstName.charAt(0).toUpperCase()}
								{lastName.charAt(0).toUpperCase()}
							</AvatarFallback>
						</Avatar>
						<div className="grid flex-1 text-left text-sm leading-tight">
							<span className="truncate font-semibold">{`${firstName} ${lastName}`}</span>
							<span className="truncate text-xs">{email}</span>
						</div>
					</div>
				</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuItem onClick={handleIntercomClick}>
					<CircleHelp className="mr-2 size-4" />
					Support
				</DropdownMenuItem>
				<DropdownMenuItem onClick={handleDarkMode}>
					{theme === "light" ? (
						<MoonIcon className="mr-2 size-4" />
					) : (
						<SunIcon className="mr-2 size-4" />
					)}
					{theme === "light" ? "Dark mode" : "Light mode"}
				</DropdownMenuItem>
				<DropdownMenuItem onClick={handleLogout}>
					<LogOut className="mr-2 size-4" />
					Log out
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
