/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { LoadingButton } from "@/components/ui/loading-button"
import type React from "react"
import { useAdminUsers } from "../../hooks/useAdminUsers"

// --------------------------------------------------
// ActivateUserModal
// --------------------------------------------------
interface ActivateUserModalProps {
	userId: string
	userEmail: string
	open: boolean
	onClose: () => void
	orgId: string
}

export const ActivateUserModal: React.FC<ActivateUserModalProps> = ({
	userId,
	userEmail,
	open,
	onClose,
	orgId,
}) => {
	const { enableUser, isLoading, isEnablePending } = useAdminUsers(orgId)

	const handleActivate = () => {
		enableUser(userId, {
			onSuccess: () => onClose(),
		})
	}

	const isLoadingState = isLoading || isEnablePending

	return (
		<Dialog open={open} onOpenChange={onClose}>
			<DialogContent className="max-w-md">
				<DialogHeader>
					<DialogTitle>Activate user {userEmail}?</DialogTitle>
				</DialogHeader>
				<DialogFooter>
					<Button variant="outline" onClick={onClose} disabled={isLoadingState}>
						Cancel
					</Button>
					<LoadingButton
						onClick={handleActivate}
						loading={isLoadingState}
						disabled={isLoadingState}
					>
						{isLoadingState ? "Activating..." : "Activate"}
					</LoadingButton>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

// --------------------------------------------------
// DeactivateUserModal
// --------------------------------------------------
interface DeactivateUserModalProps {
	userId: string
	userEmail: string
	open: boolean
	onClose: () => void
	orgId: string
}

export const DeactivateUserModal: React.FC<DeactivateUserModalProps> = ({
	userId,
	userEmail,
	open,
	onClose,
	orgId,
}) => {
	const { disableUser, isLoading, isDisablePending } = useAdminUsers(orgId)

	const handleDeactivate = () => {
		disableUser(userId, {
			onSuccess: () => onClose(),
		})
	}

	const isLoadingState = isLoading || isDisablePending

	return (
		<Dialog open={open} onOpenChange={onClose}>
			<DialogContent className="max-w-md">
				<DialogHeader>
					<DialogTitle>Deactivate user {userEmail}?</DialogTitle>
					<DialogDescription>
						The user will immediately lose access to their account.
					</DialogDescription>
				</DialogHeader>
				<DialogFooter>
					<Button variant="outline" onClick={onClose} disabled={isLoadingState}>
						Cancel
					</Button>
					<LoadingButton
						variant="destructive"
						onClick={handleDeactivate}
						loading={isLoadingState}
					>
						{isLoadingState ? "Deactivating..." : "Deactivate"}
					</LoadingButton>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

// --------------------------------------------------
// DeleteUserModal
// --------------------------------------------------
interface DeleteUserModalProps {
	userEmail: string
	userId: string
	orgId: string
	open: boolean
	onClose: () => void
}

export const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
	userEmail,
	userId,
	orgId,
	open,
	onClose,
}) => {
	const { deleteUser, isLoading, isDeletePending } = useAdminUsers(orgId)

	const handleDelete = () => {
		if (!userEmail) return
		deleteUser(
			{ userId: userId, orgId },
			{
				onSuccess: () => onClose(),
			},
		)
	}

	const isLoadingState = isLoading || isDeletePending

	return (
		<Dialog open={open} onOpenChange={onClose}>
			<DialogContent className="max-w-md">
				<DialogHeader>
					<DialogTitle>Delete {userEmail}?</DialogTitle>
					<DialogDescription>
						This user will be deleted from the organization. This action cannot be undone.
					</DialogDescription>
				</DialogHeader>
				<DialogFooter>
					<Button variant="outline" onClick={onClose} disabled={isLoadingState}>
						Cancel
					</Button>
					<LoadingButton
						variant="destructive"
						onClick={handleDelete}
						loading={isLoadingState}
					>
						{isLoadingState ? "Deleting..." : "Delete"}
					</LoadingButton>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
