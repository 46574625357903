import { useDocumentNaming } from "@/hooks"
import type { CollapsedLimitation } from "@/types"
import { formatPatentNumber } from "@/utils"
import { convertToUtcDateString } from "@/utils/dateUtils"
import { cleanDocumentChunkText } from "@/utils/formatStrings"
import { useMemo } from "react"
import useDocumentInfo from "./useDocumentInfo"

export interface VariablesParams {
	documentIds: string[]
	subjectId: string
	claimLimitation: CollapsedLimitation
}

function useVariables({
	documentIds,
	claimLimitation,
	subjectId,
}: VariablesParams): (template: string) => string {
	const { getDocumentName } = useDocumentNaming()
	const { getDocumentField } = useDocumentInfo()

	const replacements = useMemo(() => {
		if (!documentIds || !subjectId) {
			return {}
		}

		const documentName1 = getDocumentName(documentIds[0] ?? "")
		const documentNumber1 = formatPatentNumber(
			getDocumentField(documentIds[0] ?? "", "number"),
		)
		const documentPublicationDate1 = convertToUtcDateString(
			getDocumentField(documentIds[0] ?? "", "publicationDate"),
		)
		const documentInventor1 =
			getDocumentField(documentIds[0] ?? "", "inventors")?.[0] ?? ""

		const subjectName = getDocumentName(subjectId)
		const subjectNumber = formatPatentNumber(getDocumentField(subjectId, "number"))
		const subjectPriorityDate = convertToUtcDateString(
			getDocumentField(subjectId, "priorityDate"),
		)
		const subjectPublicationDate = convertToUtcDateString(
			getDocumentField(subjectId, "publicationDate"),
		)

		let documentName2 = ""
		let documentNumber2 = ""
		let documentPublicationDate2 = ""
		let documentName1And2 = ""
		let documentNumber1And2 = ""
		let documentPublicationDate1And2 = ""

		if (documentIds[1]) {
			documentName2 = getDocumentName(documentIds[1])
			documentNumber2 = formatPatentNumber(getDocumentField(documentIds[1], "number"))
			documentPublicationDate2 = convertToUtcDateString(
				getDocumentField(documentIds[1], "publicationDate"),
			)

			documentName1And2 = `${documentName1} and ${documentName2}`
			documentNumber1And2 = `${documentNumber1} and ${documentNumber2}`
			documentPublicationDate1And2 = `${documentPublicationDate1} and ${documentPublicationDate2}`
		}

		const limitationText = cleanDocumentChunkText(
			(claimLimitation?.limitations ?? [])
				.map((limitation) => limitation?.text ?? "")
				.join(" "),
		)

		return {
			limitation_text: limitationText,
			limitation_label: claimLimitation?.label ?? "",
			document_nickname_1: documentName1,
			document_nickname_2: documentName2,
			document_nickname_1_and_2: documentName1And2,
			document_number_1: documentNumber1,
			document_number_2: documentNumber2,
			document_number_1_and_2: documentNumber1And2,
			document_publication_date_1: documentPublicationDate1,
			document_publication_date_2: documentPublicationDate2,
			document_publication_date_1_and_2: documentPublicationDate1And2,
			subject_nickname: subjectName,
			subject_number: subjectNumber,
			subject_priority_date: subjectPriorityDate,
			subject_publication_date: subjectPublicationDate,
			inventor: documentInventor1,
		}
	}, [documentIds, subjectId, claimLimitation, getDocumentName, getDocumentField])

	// Return template with replacements
	return useMemo(() => {
		return (template: string) =>
			Object.entries(replacements).reduce((formatted, [key, value]) => {
				return formatted.replaceAll(`{{${key}}}`, value)
			}, template)
	}, [replacements])
}

export default useVariables
