/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import {
	Table,
	TableBody,
	TableCellCondensed,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import { cn } from "@/lib/utils"
import {
	type ColumnDef,
	type ColumnFiltersState,
	type FilterFn,
	type Row,
	type RowSelectionState,
	type SortingState,
	type VisibilityState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table"
import { ArrowDownIcon, ArrowUpIcon } from "lucide-react"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { TableActionBar } from "./TableActionBar"
type CustomColumnDef<TData, TValue = unknown> = ColumnDef<TData, TValue> & {
	hidden?: boolean
}

interface DataTableProps<TData, _TValue> {
	columns: CustomColumnDef<TData>[]
	data: TData[]
	onRowSelection?: (selectedRows: TData[]) => void
	children?: React.ReactNode
	height?: string
	showActions?: boolean
	renderRowSubComponent?: (props: {
		row: Row<TData>
		rows: Row<TData>[]
	}) => React.ReactNode
	showFilter?: boolean
	showSort?: boolean
	showSearch?: boolean
	showColumns?: boolean
	onDeleteSelected?: (selectedRows: TData[]) => void
	isDeleteSelectedLoading?: boolean
	initialFilters?: Record<
		string,
		{ label: string; values: { label: string; value: string }[] }
	>
	enableRowDelete?: boolean
	onTagApply?: (selectedRows: TData[], addTags: string[], setTags: string[]) => void
	showTag?: boolean
	showReprocess?: boolean
	showRechart?: boolean
	title?: string
	isModal?: boolean
	onReprocessSelected?: (selectedRows: TData[], isReprocess: boolean) => void
	onRowClick?: (row: TData) => void
	onDismissSelected?: (references: TData[]) => void
}

// Helper function to get nested value using path string
const getNestedValue = (obj: any, path: string) => {
	return path.split(".").reduce((acc, part) => acc?.[part], obj)
}

export function DataTable<TData extends object, TValue>({
	columns,
	data,
	onRowSelection,
	onRowClick,
	children,
	height = "auto",
	showActions = true,
	renderRowSubComponent,
	onDeleteSelected,
	isDeleteSelectedLoading = false,
	initialFilters = {},
	enableRowDelete = false,
	onTagApply,
	showTag = false,
	showColumns = true,
	title,
	isModal = false,
}: DataTableProps<TData, TValue>) {
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(() => {
		const initialVisibility: VisibilityState = {}
		for (const column of columns) {
			const columnId = column.id as string
			if (column.hidden) {
				initialVisibility[columnId] = false
			} else if (["Inventors", "Assignee"].includes(column.header as string)) {
				initialVisibility[columnId] = false
			} else {
				initialVisibility[columnId] = true
			}
		}
		return initialVisibility
	})
	const [rowSelection, setRowSelection] = useState({})
	const selectedRowsCount = Object.keys(rowSelection).length
	const [multiSorting, setMultiSorting] = useState<SortingState>([])
	const [filters, setFilters] =
		useState<
			Record<string, { label: string; values: { label: string; value: string }[] }>
		>(initialFilters)
	// New state for tags
	const [tags, setTags] = useState<string[]>([])

	// Use useMemo to compute tags from data
	const computedTags = useMemo(() => {
		const tagSet = new Set<string>()
		for (const item of data) {
			if ("tags" in item && Array.isArray(item?.tags)) {
				for (const tag of item.tags) {
					tagSet.add(tag)
				}
			}
		}
		return Array.from(tagSet)
	}, [data])

	// Update tags state when computedTags changes
	useEffect(() => {
		setTags(computedTags)
	}, [computedTags])

	const prevRowSelectionRef = useRef<RowSelectionState>({})
	const [_sortConfig, _setSortConfig] = useState<{
		columnId: string
		desc: boolean
	} | null>(null)

	// Enhanced filter function that handles nested paths
	const includesSomeFilterFn: FilterFn<TData> = (row, columnId, filterValue) => {
		// Get the column definition to access accessorKey or accessorFn
		const column = columns.find((col) => col.id === columnId)

		let cellValue: any

		if ("accessorKey" in column) {
			// Handle dot notation paths
			const accessorKey = column.accessorKey as string
			cellValue = getNestedValue(row.original, accessorKey)
		} else if ("accessorFn" in column) {
			// Use accessor function with both required parameters
			cellValue = column.accessorFn(row.original, row.index)
		} else {
			// Fallback to direct getValue
			cellValue = row.getValue(columnId)
		}

		// Handle null/undefined
		if (cellValue == null) return false

		// Handle array values (e.g., tags)
		if (Array.isArray(cellValue)) {
			return filterValue.some((filter: any) =>
				cellValue.some((val: any) =>
					String(val).toLowerCase().includes(String(filter).toLowerCase()),
				),
			)
		}

		// Handle string values with case-insensitive comparison
		return filterValue.some((filter: any) =>
			String(cellValue).toLowerCase().includes(String(filter).toLowerCase()),
		)
	}

	// Enhanced sorting function that handles nested paths
	const nestedSortingFn = (
		rowA: Row<TData>,
		rowB: Row<TData>,
		columnId: string,
	): number => {
		const column = columns.find((col) => col.id === columnId)

		let valueA: any
		let valueB: any

		if ("accessorKey" in column) {
			const accessorKey = column.accessorKey as string
			valueA = getNestedValue(rowA.original, accessorKey)
			valueB = getNestedValue(rowB.original, accessorKey)
		} else if ("accessorFn" in column) {
			valueA = column.accessorFn(rowA.original, rowA.index)
			valueB = column.accessorFn(rowB.original, rowB.index)
		} else {
			valueA = rowA.getValue(columnId)
			valueB = rowB.getValue(columnId)
		}

		// Handle null/undefined values
		if (valueA == null) return 1
		if (valueB == null) return -1
		if (valueA == null && valueB == null) return 0

		// Use Intl.Collator for proper string comparison
		const collator = new Intl.Collator(["ko", "ja", "zh", "en"], {
			sensitivity: "base",
			numeric: true,
		})

		return collator.compare(String(valueA), String(valueB))
	}

	// Enhance columns with filter and sort functions
	const enhancedColumns = useMemo(() => {
		return columns.map((column) => {
			const enhanced = { ...column }

			// Add filter function if column has filters
			if (filters[column.id as string]) {
				enhanced.filterFn = includesSomeFilterFn
			}

			// Add sorting function for all sortable columns
			if (enhanced.enableSorting !== false) {
				enhanced.sortingFn = nestedSortingFn
			}

			return enhanced
		})
	}, [columns])

	const table = useReactTable({
		data,
		columns: enhancedColumns,
		state: {
			sorting: multiSorting,
			columnFilters,
			columnVisibility,
			rowSelection,
		},
		onSortingChange: setMultiSorting,
		onColumnFiltersChange: setColumnFilters,
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setRowSelection,
		filterFns: {
			includesSome: includesSomeFilterFn,
		},
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})

	useEffect(() => {
		if (
			onRowSelection &&
			JSON.stringify(rowSelection) !== JSON.stringify(prevRowSelectionRef.current)
		) {
			const selectedRows = table
				.getFilteredSelectedRowModel()
				.rows.map((row) => row.original)
			onRowSelection(selectedRows)
			prevRowSelectionRef.current = rowSelection
		}
	}, [rowSelection, table, onRowSelection])

	const handleDeleteSelected = () => {
		if (onDeleteSelected) {
			const selectedRows = table
				.getFilteredSelectedRowModel()
				.rows.map((row) => row.original)
			onDeleteSelected(selectedRows)
			// Reset row selection after delete action
			setRowSelection({})
		}
	}

	const handleTagSelected = (selectedTags: string[]) => {
		if (onTagApply) {
			const selectedRows = table
				.getFilteredSelectedRowModel()
				.rows.map((row) => row.original)
			onTagApply(selectedRows, selectedTags, null) // (rows, addTags, setTags)
		}
		// setRowSelection({})
	}

	useEffect(() => {
		setFilters((prevFilters) => ({
			...prevFilters,
			...(tags.length > 0 &&
				onTagApply && {
					tags: {
						label: "Tags",
						values: computedTags.map((tag) => ({ label: tag, value: tag })),
					},
				}),
		}))
	}, [computedTags, tags, onTagApply])

	return (
		<div className="w-full h-auto flex flex-col">
			{showActions && (
				<TableActionBar
					selectedRowsCount={selectedRowsCount}
					onDeleteSelected={handleDeleteSelected}
					enableRowDelete={enableRowDelete}
					handleTagUpdate={handleTagSelected}
					table={table}
					multiSorting={multiSorting}
					setMultiSorting={setMultiSorting}
					filters={filters}
					tags={tags}
					showTag={showTag}
					title={title}
					showColumns={showColumns}
					isLoading={isDeleteSelectedLoading}
				>
					<div className="flex flex-row justify-between w-full">
						<div className="flex gap-2">{children}</div>
					</div>
				</TableActionBar>
			)}
			<div className="flex-grow overflow-hidden px-2">
				<div
					className={cn(
						"border rounded-md",
						isModal
							? "h-full overflow-y-auto"
							: height === "auto"
								? "h-full overflow-y-auto"
								: "",
					)}
					style={height !== "auto" ? { maxHeight: height, overflowY: "auto" } : {}}
				>
					<Table className="min-w-full ">
						<TableHeader className="whitespace-nowrap">
							{table.getHeaderGroups().map((headerGroup) => (
								<TableRow key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										return (
											<TableHead key={header.id}>
												{header.isPlaceholder ? null : (
													<div
														{...{
															className: header.column.getCanSort()
																? "cursor-pointer select-none flex items-center"
																: "flex items-center",
															onClick: header.column.getToggleSortingHandler(),
														}}
													>
														{flexRender(header.column.columnDef.header, header.getContext())}
														{header.column.getCanSort() && (
															<span className="ml-1">
																{header.column.getIsSorted() === "asc" ? (
																	<ArrowUpIcon className="h-4 w-4" />
																) : header.column.getIsSorted() === "desc" ? (
																	<ArrowDownIcon className="h-4 w-4" />
																) : null}
															</span>
														)}
													</div>
												)}
											</TableHead>
										)
									})}
								</TableRow>
							))}
						</TableHeader>
						<TableBody>
							{table.getRowModel().rows?.length ? (
								table.getRowModel().rows.map((row) => (
									<React.Fragment key={row.id}>
										<TableRow
											data-state={row.getIsSelected() && "selected"}
											onClick={(e) => {
												// Check if the click originated from an interactive element or its descendant
												const isInteractiveElement = (element: HTMLElement | null): boolean => {
													if (!element) return false
													if (element === e.currentTarget) return false

													const interactiveTagNames = ["A", "BUTTON", "INPUT", "SELECT", "TEXTAREA"]
													const interactiveRoles = [
														"button",
														"link",
														"checkbox",
														"radio",
														"switch",
														"option",
													]

													return (
														interactiveTagNames.includes(element.tagName) ||
														interactiveRoles.includes(element.getAttribute("role") || "") ||
														element.classList.contains("clickable-element") ||
														isInteractiveElement(element.parentElement)
													)
												}

												if (!isInteractiveElement(e.target as HTMLElement) && onRowClick) {
													onRowClick(row.original)
												}
											}}
											className={onRowClick ? "cursor-pointer" : ""}
										>
											{row.getVisibleCells().map((cell) => (
												<TableCellCondensed
													key={cell.id}
													className={cell.column.id === "actions" ? "text-right pr-4" : ""}
												>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</TableCellCondensed>
											))}
										</TableRow>
										{renderRowSubComponent?.({
											row,
											rows: table.getRowModel().rows,
										})}
									</React.Fragment>
								))
							) : (
								<TableRow>
									<TableCellCondensed colSpan={columns.length} className="h-24 text-center">
										No results.
									</TableCellCondensed>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</div>
			</div>
		</div>
	)
}
