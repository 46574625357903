import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable"
import { DocumentContextProvider } from "@/context/DocumentContext"
import type React from "react"
import { useMemo } from "react"
import { useChartsContext } from "../../context/ChartsContext"
import DocumentPanel from "./DocumentPanel"

const InvalidityDocumentMode: React.FC = () => {
	const { chartData } = useChartsContext()

	const { docId1, docId2 } = useMemo(() => {
		const ids = chartData?.documentIds ?? []
		return {
			docId1: ids[0] || null,
			docId2: ids[1] || null,
		}
	}, [chartData])

	// If there's only 1 doc, it'll occupy the full area; if 2, split them 50/50, etc.
	return (
		<ResizablePanelGroup direction="horizontal" className="w-full h-full">
			<ResizablePanel className="flex flex-col h-full overflow-hidden">
				<DocumentContextProvider documentIds={[docId1]}>
					{docId1 && <DocumentPanel documentId={docId1} />}
				</DocumentContextProvider>
			</ResizablePanel>

			{docId2 && (
				<>
					<ResizableHandle orientation="vertical" />
					<ResizablePanel className="flex flex-col h-full overflow-hidden">
						<DocumentContextProvider documentIds={[docId2]}>
							<DocumentPanel documentId={docId2} />
						</DocumentContextProvider>
					</ResizablePanel>
				</>
			)}
		</ResizablePanelGroup>
	)
}

export default InvalidityDocumentMode
