import type React from "react"
import { useEffect, useRef, useState } from "react"

// Add type declaration for MathJax on window object
declare global {
	interface Window {
		MathJax: any
	}
}

interface EquationRendererProps {
	equation: string
	displayMode?: boolean
}

// Global reference to track if MathJax script is loaded
let isMathJaxLoaded = false
let isMathJaxConfigured = false

const EquationRenderer: React.FC<EquationRendererProps> = ({
	equation,
	displayMode = true,
}) => {
	const [renderError, setRenderError] = useState<string | null>(null)
	const containerRef = useRef<HTMLDivElement>(null)
	const [isProcessing, setIsProcessing] = useState(true)

	// Process the equation string to properly handle mixed content
	const processEquationString = () => {
		// Check for equation number at the end (e.g., "(11)")
		const equationNumberMatch = equation.match(/(.*?)(\s*\(\d+\)\s*)$/)
		const contentWithoutNumber = equationNumberMatch?.[1] || equation
		const equationNumber = equationNumberMatch?.[2]?.trim()

		let formattedEquation = contentWithoutNumber

		// Check if the content already has LaTeX delimiters
		const hasInlineDelimiters =
			contentWithoutNumber.includes("\\(") && contentWithoutNumber.includes("\\)")
		const hasDisplayDelimiters = contentWithoutNumber.includes("$$")

		if (!hasInlineDelimiters && !hasDisplayDelimiters) {
			// If no delimiters are present, wrap the entire content
			formattedEquation = displayMode
				? `$$${contentWithoutNumber}$$`
				: `\\(${contentWithoutNumber}\\)`
		} else if (displayMode && hasInlineDelimiters) {
			// Convert inline delimiters to display mode if needed
			formattedEquation = contentWithoutNumber.replace(/\\\((.*?)\\\)/g, "$$$1$$")
		} else if (!displayMode && hasDisplayDelimiters) {
			// Convert display delimiters to inline mode if needed
			formattedEquation = contentWithoutNumber.replace(/\$\$(.*?)\$\$/g, "\\($1\\)")
		}

		return { formattedEquation, equationNumber }
	}

	const { formattedEquation, equationNumber } = processEquationString()

	useEffect(() => {
		// Function to configure MathJax
		const configureMathJax = () => {
			if (isMathJaxConfigured) return

			window.MathJax = {
				tex: {
					inlineMath: [
						["\\(", "\\)"],
						["$", "$"],
					],
					displayMath: [["$$", "$$"]],
					processEscapes: true,
					processEnvironments: true,
					macros: {
						// Define any helpful macros here
					},
				},
				options: {
					skipHtmlTags: ["script", "noscript", "style", "textarea", "pre", "code"],
					ignoreHtmlClass: "tex2jax_ignore",
					processHtmlClass: "tex2jax_process",
				},
				startup: {
					typeset: false, // Don't perform initial typeset
				},
			}

			isMathJaxConfigured = true
		}

		// Function to load MathJax script
		const loadMathJax = () => {
			if (isMathJaxLoaded) return Promise.resolve()

			// Configure MathJax before loading the script
			configureMathJax()

			return new Promise<void>((resolve, reject) => {
				const script = document.createElement("script")
				// Using a specific version for better stability
				script.src = "https://cdn.jsdelivr.net/npm/mathjax@3.2.2/es5/tex-mml-chtml.js"
				script.async = true

				script.onload = () => {
					isMathJaxLoaded = true
					resolve()
				}

				script.onerror = (e) => {
					setRenderError("Failed to load MathJax")
					reject(e)
				}

				document.head.appendChild(script)
			})
		}

		// Process the equation once MathJax is loaded
		const processEquation = async () => {
			try {
				setIsProcessing(true)

				if (!isMathJaxLoaded) {
					await loadMathJax()
				}

				if (containerRef.current) {
					containerRef.current.innerHTML = formattedEquation

					// Access the global MathJax object
					const MathJax = (window as any).MathJax

					if (MathJax?.typesetPromise) {
						await MathJax.typesetPromise([containerRef.current])
						setRenderError(null)
					}
				}
			} catch (error) {
				setRenderError(
					`Failed to render equation: ${error instanceof Error ? error.message : String(error)}`,
				)
			} finally {
				setIsProcessing(false)
			}
		}

		processEquation()

		// Cleanup - nothing needed here as we want to keep MathJax loaded
	}, [formattedEquation])

	return (
		<div className="flex items-center justify-center my-4">
			{renderError ? (
				<div className="text-red-500 p-2 border border-red-300 rounded bg-red-50">
					<code>{equation}</code>
					<div className="text-sm mt-1">{renderError}</div>
				</div>
			) : (
				<>
					<div
						ref={containerRef}
						className={`${isProcessing ? "opacity-0" : "opacity-100"} transition-opacity duration-300`}
					/>
					{isProcessing && <div className="text-gray-400">Loading equation...</div>}
				</>
			)}
			{equationNumber && !renderError && !isProcessing && (
				<span className="ml-2 text-gray-500">{equationNumber}</span>
			)}
		</div>
	)
}

export default EquationRenderer
