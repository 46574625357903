import { api } from "@/api"
import { useProjectContext } from "@/context/ProjectContext"
import type { ClaimLimitation } from "@/types"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"
import useProjectSettings from "../../features/settings/hooks/useProjectSettings"

/**
 * @description Hook for handling generic (type-agnostic) project operations.
 * It now provides both the raw set of claims (allClaims) as well as
 * filtered project claims (projectClaims) if the asserted setting is enabled.
 */
const useClaims = (subjectId: string) => {
	const { projectId } = useProjectContext()
	const { settings } = useProjectSettings()

	const projectClaimsQuery = useQuery({
		queryKey: QUERY_KEYS.project.subject.claims(projectId, subjectId),
		queryFn: () => api.getSubjectClaims(subjectId as string, projectId as string),
		enabled: !!projectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	// Raw claims returned from the API.
	const allClaims = useMemo(() => {
		return projectClaimsQuery.data ?? []
	}, [projectClaimsQuery.data])

	// Determine whether to filter only for asserted claims.
	const showAssertedClaims = useMemo(() => {
		return settings?.defaultToAssertedClaims
	}, [settings])

	// Filter the project claims by 'asserted' if the setting is enabled.
	const filteredProjectClaims = useMemo(() => {
		if (showAssertedClaims) {
			return allClaims.filter((claim) => claim.isAsserted)
		}
		return allClaims
	}, [allClaims, showAssertedClaims])

	const claimLimitations = useMemo(() => {
		return filteredProjectClaims.flatMap((claim) => claim.collapsedLimitations) ?? []
	}, [filteredProjectClaims])

	const getCollapsedLimitationById = (id: string) => {
		return filteredProjectClaims
			.flatMap((claim) => claim.collapsedLimitations)
			.find((limitation) => limitation.id === id)
	}

	const getClaimIdFromLimitationId = (id: string) => {
		const limitation = getCollapsedLimitationById(id)
		return limitation?.claimId
	}

	const claimLimitationsGroups = useQuery({
		queryKey: QUERY_KEYS.project.subject.claimLimitationGroups(projectId, subjectId),
		queryFn: () => {
			return api.getClaimLimitationGroups(subjectId)
		},
		enabled: !!projectId && !!subjectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		// The complete set of claims as returned by the API.
		allClaims,
		// The project claims filtered by asserted (if enabled).
		subjectClaims: filteredProjectClaims,
		claimLimitations: claimLimitations as unknown as ClaimLimitation[],
		collapsedLimitations: claimLimitations,

		// Loading and error states.
		isLoadingClaims: projectClaimsQuery.isLoading,
		isErrorClaims: projectClaimsQuery.isError,

		// Refetch function.
		refetchClaims: projectClaimsQuery.refetch,

		// Utility functions.
		getClaimIdFromLimitationId,
		getCollapsedLimitationById,

		claimLimitationsGroups: claimLimitationsGroups.data,
	}
}

export default useClaims
