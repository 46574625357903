import { Loader } from "@/components"
import { AdminPageLayout } from "@/features/admin/components"
import { PageName } from "@/types/pages"
import AdminUsageCard from "../components/AdminUsageCard"
import OverviewCards from "../components/OverviewCards"
import { useSuperAdminOrganizations } from "./hooks/useSuperAdminOrganizations"
import { useSuperAdminOverview } from "./hooks/useSuperAdminOverview"

/**
 * @description Super admin page component
 */
function SuperAdminPage() {
	const { isLoading, overview } = useSuperAdminOverview()
	const { organizations } = useSuperAdminOrganizations()
	return (
		<AdminPageLayout isSuperAdmin={true} page={PageName.SuperAdminOverview}>
			<div className="flex flex-col gap-4">
				{isLoading ? <Loader /> : <OverviewCards overview={overview} isSuperAdmin={true} />}

				<AdminUsageCard isSuperAdmin={true} adminOrganizations={organizations} />
			</div>
		</AdminPageLayout>
	)
}

export default SuperAdminPage
