/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import { AdminPageLayout, BillingDetailsCard } from "@/features/admin/components"
import { useUserStore } from "@/store"
import { PageName } from "@/types/pages"
import { useAdminOverview } from "../hooks/useAdminOverview"

/**
 * @description Admin billing page component
 */
function AdminBillingPage() {
	const { organizationId } = useUserStore()
	const { adminOverview, isLoading } = useAdminOverview(organizationId)

	return (
		<AdminPageLayout isSuperAdmin={false} page={PageName.AdminBilling}>
			<div className="mt-3 space-y-8">
				{isLoading ? (
					<div className="flex justify-center items-center h-full">
						<Loader />
					</div>
				) : (
					<BillingDetailsCard overview={adminOverview} />
				)}
			</div>
		</AdminPageLayout>
	)
}

export default AdminBillingPage
