import { api } from "@/api"
import { useIsAddPriorArtRunning } from "@/store/processStore"
import { DocumentRole } from "@/types/documents"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router-dom"

export function useProjectInfringementDocuments() {
	const { projectId } = useParams()
	const isProcessing = useIsAddPriorArtRunning(projectId)

	const {
		data: infringementDocuments = [],
		isLoading,
		error,
	} = useQuery({
		queryKey: QUERY_KEYS.project.documents.infringement(projectId),
		queryFn: () =>
			api.getProjectDocumentMetadata(projectId, [DocumentRole.INFRINGEMENT], null, true),
		enabled: !!projectId,
		refetchInterval: isProcessing ? 10000 : 60000, // refetch every 10 seconds if processing new prior art
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		infringementDocuments: infringementDocuments,
		priorArtIds: infringementDocuments.map((document) => document.id),
		isLoading,
		error,
	}
}

export default useProjectInfringementDocuments
