import { api } from "@/api"
import { chartsApi } from "@/api/chartsApi"
import { useProjectContext } from "@/context/ProjectContext"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi } from "@/hooks"
import { invalidateSubjectChartsOverviewData } from "@/utils"
import { useMutation, useQueryClient } from "@tanstack/react-query"
/**
 * @description Updates the header and footer boilerplate for a chunk to a limitation
 */
export function useInvalidityChartUpdates() {
	const queryClient = useQueryClient()
	const { handleError } = useApi()
	const { projectId, subjectId } = useProjectContext()

	const addInvalidityChartMutation = useMutation({
		mutationFn: async ({
			name,
			notes,
			subjectId,
			documentIds,
			numberCitationsToCopy,
			useSuggestedCombinations,
		}: {
			name: string
			notes: string
			subjectId: string
			documentIds: string[]
			numberCitationsToCopy?: number
			useSuggestedCombinations?: boolean
		}) => {
			return chartsApi.createInvalidityChart(
				projectId,
				subjectId,
				name,
				notes,
				documentIds,
				numberCitationsToCopy,
				useSuggestedCombinations,
			)
		},
		onError: (error) => {
			handleError(error, "Error updating chunk to limitation boilerplate")
		},
		onSuccess: () => {
			invalidateSubjectChartsOverviewData(queryClient, projectId, subjectId)
		},
	})

	const updateInvalidityChartMutation = useMutation({
		mutationFn: async ({
			chartId,
			options,
		}: {
			chartId: string
			options: {
				name?: string
				notes?: string
				active?: boolean
				tags?: string[]
			}
		}) => {
			return api.updateInvalidityChart(chartId, options)
		},
		onError: (error) => {
			handleError(error, "Error updating invalidity chart")
		},
		onSuccess: () => {
			invalidateSubjectChartsOverviewData(queryClient, projectId, subjectId)
		},
	})

	return {
		addInvalidityChart: addInvalidityChartMutation.mutateAsync,
		isAddingInvalidityChart: addInvalidityChartMutation.isPending,

		updateInvalidityChart: updateInvalidityChartMutation.mutate,
		isUpdatingInvalidityChart: updateInvalidityChartMutation.isPending,
	}
}

export default useInvalidityChartUpdates
