import { H4 } from "@/components/ui/typography"
import type React from "react"

const NoReferencesMessage: React.FC = () => {
	return (
		<div className="flex justify-center items-center flex-col mt-12">
			<H4 className="text-center">This project has no references.</H4>
		</div>
	)
}

export default NoReferencesMessage
