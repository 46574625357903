import { useChatContext } from "@/context/ChatContext"
import { SHOW_AGENT_CHAT_FLAG } from "@/gating/gating"
import { useChat, useNavigationState } from "@/hooks"
import { useEffect } from "react"
import { ChatThread } from "./ChatThread"
import { PanelChatFooter } from "./components/PanelChatFooter"
import { PanelChatHeader } from "./components/PanelChatHeader"
import { ChatRow, ChatRowGlimmer } from "./components/PanelChatRow"

interface PanelChatProps {
	asPanel?: boolean
}

export const PanelChat = ({ asPanel = false }: PanelChatProps) => {
	const { isPanelChatOpen, setIsPanelChatOpen, activeChatId, setActiveChatId } =
		useChatContext()
	const { chatsMetadata, chatsMetadataLoading } = useChat()
	const { isExpandedChatPage } = useNavigationState()

	useEffect(() => {
		if (isExpandedChatPage && !isPanelChatOpen) {
			setIsPanelChatOpen(true)
		}
	}, [isExpandedChatPage, isPanelChatOpen, setIsPanelChatOpen])

	if (!SHOW_AGENT_CHAT_FLAG || isExpandedChatPage) {
		return null
	}

	if (!isPanelChatOpen && !asPanel) {
		return null
	}

	const chatContent = (
		<>
			<PanelChatHeader onClose={() => setIsPanelChatOpen(false)} />

			{activeChatId ? (
				<ChatThread onClose={() => setIsPanelChatOpen(false)} />
			) : (
				<>
					<div className="flex-1 overflow-y-auto">
						{chatsMetadataLoading ? (
							/* Loading glimmer */
							<div className="space-y-2 p-2">
								<ChatRowGlimmer />
								<ChatRowGlimmer />
								<ChatRowGlimmer />
								<ChatRowGlimmer />
								<ChatRowGlimmer />
							</div>
						) : chatsMetadata?.length === 0 ? (
							<div className="flex flex-col items-center justify-center h-40 space-y-2">
								<div className="flex items-center gap-2">
									<span className="text-muted-foreground text-sm">No chats yet</span>
								</div>
								<span className="text-xs text-muted-foreground/60">
									Start a new conversation below
								</span>
							</div>
						) : (
							<div className="space-y-2 mt-2">
								{chatsMetadata?.map((chat) => (
									<ChatRow
										key={chat.id}
										chatMetadata={chat}
										onClick={() => setActiveChatId(chat.id)}
									/>
								))}
							</div>
						)}
					</div>
					<PanelChatFooter mode="new-chat" onNewChat={() => setActiveChatId("new")} />
				</>
			)}
		</>
	)

	if (asPanel) {
		return <div className="flex flex-col h-full">{chatContent}</div>
	}

	return (
		<div className="z-20 fixed bottom-4 right-4 w-[400px] h-[600px] bg-background border rounded-md shadow-lg flex flex-col">
			{chatContent}
		</div>
	)
}
