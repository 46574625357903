/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
"use client"
import type * as React from "react"

import blueLogoImage from "@/assets/logomark_lightblue.png"
import navyLogoImage from "@/assets/logomark_navy.png"
import {
	Sidebar,
	SidebarContent,
	SidebarFooter,
	SidebarHeader,
	SidebarRail,
} from "@/components/ui/sidebar"
import useNavigationState from "@/hooks/useNavigationState"
import { useTheme } from "@/styled/ThemeProvider"
import { useNavigate } from "react-router-dom"
import { NavUser } from "./NavUser"
import NonProjectSidebarContent from "./NonProjectSidebarContent"
import { ProjectSidebarContent } from "./ProjectSidebarContent"
import { ProjectSidebarFooter } from "./ProjectSidebarFooter"
import { ProjectSidebarHeader } from "./ProjectSidebarHeader"
/**
 * @description App sidebar component
 */
export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
	const { theme } = useTheme()
	const navigate = useNavigate()
	const { isProjectPage } = useNavigationState()

	return (
		<Sidebar collapsible="icon" {...props}>
			<SidebarHeader>
				{isProjectPage ? (
					<ProjectSidebarHeader />
				) : (
					<div className="flex items-center justify-center pt-2">
						<div className="cursor-pointer" onClick={() => navigate("/home")}>
							<img
								src={theme === "light" ? navyLogoImage : blueLogoImage}
								alt="AndAI Logo"
								className="h-8 w-8"
							/>
						</div>
					</div>
				)}
			</SidebarHeader>
			<SidebarContent>
				{isProjectPage ? <ProjectSidebarContent /> : <NonProjectSidebarContent />}
			</SidebarContent>
			<SidebarFooter>
				{isProjectPage && <ProjectSidebarFooter />}
				<NavUser />
			</SidebarFooter>
			<SidebarRail />
		</Sidebar>
	)
}
