import { searchApi } from "@/api/searchApi"
import { useUnprocessedDocumentsContext } from "@/context/UnprocessedDocumentsContext"
import { useApi } from "@/hooks"
import { useAppStateStore } from "@/store"
import type { ApiResponse, ProductSearchRequest, SearchFilters } from "@/types"
import { DocumentRole, UnprocessedDocumentType } from "@/types"
import type { KeysToCamelCase } from "@/types/utils"
import { convertToUtcDateString } from "@/utils/dateUtils"
import { nanoid } from "nanoid"
import { useState } from "react"
import type { components } from "schema"

type ProductResult = KeysToCamelCase<components["schemas"]["ProductInfo"]>

/**
 * @description A custom hook for product searching
 */
const useProductSearch = () => {
	const { handleError } = useApi()
	const { setUnprocessedDocuments } = useUnprocessedDocumentsContext()
	const { addErrorMessage } = useAppStateStore()
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)

	// Constants for retry logic
	const MAX_RETRIES = 2
	const RETRY_DELAY = 5000

	const resetSearch = () => {
		setIsLoading(false)
		setIsError(false)
	}

	const searchProduct = async (
		projectId: string,
		subjectId: string,
		searchPayload: Partial<ProductSearchRequest> = {},
	): Promise<ApiResponse<ProductResult[]>> => {
		try {
			const productResults = await searchApi.getProductSearch(
				projectId,
				subjectId,
				searchPayload,
			)
			// Keep snake_case properties as expected by ProductResult type
			const mappedResults = (
				productResults as Array<{
					productName: string
					productUrl: string
					productId?: string
					specificationUrl?: string
					specifications?: Record<string, never>
					seller: string
				}>
			).map((product) => ({
				productName: product.productName,
				productUrl: product.productUrl,
				productId: product.productId,
				specificationUrl: product.specificationUrl,
				specifications: product.specifications || {},
				seller: product.seller,
			}))
			return { success: true, data: mappedResults }
		} catch (error) {
			console.error("Error searching for products:", error)
			return handleError(error, "Error searching for products")
		}
	}

	const handleSearch = async (
		projectId: string,
		subjectId: string,
		filters: SearchFilters,
		retryCount = 0,
	) => {
		setIsLoading(true)
		setIsError(false)

		const payload: Partial<ProductSearchRequest> = {
			subject_id: subjectId,
			...(filters.toDate || filters.fromDate
				? {
						to_date: filters.toDate ? convertToUtcDateString(filters.toDate) : null,
						from_date: filters.fromDate ? convertToUtcDateString(filters.fromDate) : null,
					}
				: {}),
			...(filters.whitelistedSites?.length > 0 && {
				whitelisted_sites: filters.whitelistedSites,
			}),
			...(filters.blacklistedSites?.length > 0 && {
				blacklisted_sites: filters.blacklistedSites,
			}),
		}

		try {
			const response = await searchProduct(projectId, subjectId, payload)

			if (!response.success) {
				if (retryCount < MAX_RETRIES) {
					setTimeout(() => {
						handleSearch(projectId, subjectId, filters, retryCount + 1)
					}, RETRY_DELAY)
					return
				}
				setIsError(true)
				addErrorMessage("Failed to search for product references")
				return
			}

			// Create unprocessed documents directly with productDetails
			const newUnprocessedDocuments = response.data.map((product) => {
				const productDetails = {
					productName: product.productName,
					productUrl: product.productUrl,
					specificationUrl: product.specificationUrl,
					specifications: {},
					seller: product.seller,
				}
				return {
					id: nanoid(),
					type: UnprocessedDocumentType.PRODUCT,
					role: DocumentRole.PRIOR_ART,
					productDetails,
					isNew: true,
				}
			})

			setUnprocessedDocuments(newUnprocessedDocuments)
		} catch (error) {
			console.error("Error in product search:", error)
			setIsError(true)
			addErrorMessage("An error occurred while searching for product references")
		} finally {
			setIsLoading(false)
		}
	}

	return {
		handleSearch,
		isLoading,
		isError,
		resetSearch,
	}
}

export default useProductSearch
