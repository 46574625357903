/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import { AdminPageLayout } from "@/features/admin/components"
import { OrganizationDetailsTable } from "@/features/admin/superadmin/components"
import { PageName } from "@/types/pages"
import { useNavigate } from "react-router-dom"
import { useSuperAdminOrganizations } from "./hooks/useSuperAdminOrganizations"

/**
 * SuperAdminOrganizationPage component handles the display of organization management
 * for super administrators.
 */
function SuperAdminOrganizationPage() {
	const navigate = useNavigate()
	const { isLoading, organizations } = useSuperAdminOrganizations()

	const onOrgSelect = (orgId: string) => {
		navigate(`/superadmin/organizations/${orgId}`)
	}

	const renderContent = () => {
		if (isLoading) return <Loader />
		if (!organizations) return <div>No organizations found</div>

		return (
			<div className="space-y-8 mt-2">
				<OrganizationDetailsTable organizations={organizations} onOrgSelect={onOrgSelect} />
			</div>
		)
	}

	return (
		<AdminPageLayout isSuperAdmin={true} page={PageName.SuperAdminOverview}>
			{renderContent()}
		</AdminPageLayout>
	)
}

export default SuperAdminOrganizationPage
