import { Node, mergeAttributes } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"
import VariablePill from "./VariablePill"

export interface VariableNodeAttributes {
	id: string
	label: string
}

declare module "@tiptap/core" {
	interface Commands<ReturnType> {
		variable: {
			/**
			 * Add a variable node
			 */
			setVariable: (attributes: VariableNodeAttributes) => ReturnType
		}
	}
}

export const VariableNode = Node.create({
	name: "variable",

	group: "inline",

	inline: true,

	selectable: true,

	atom: true,

	draggable: true,

	addAttributes() {
		return {
			id: {
				default: null,
				parseHTML: (element) => element.getAttribute("data-variable-id"),
				renderHTML: (attributes) => {
					return {
						"data-variable-id": attributes.id,
					}
				},
			},
			label: {
				default: null,
				parseHTML: (element) => element.getAttribute("data-variable-label"),
				renderHTML: (attributes) => {
					return {
						"data-variable-label": attributes.label,
					}
				},
			},
		}
	},

	parseHTML() {
		return [
			{
				tag: "span[data-variable-id]",
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return ["span", mergeAttributes(HTMLAttributes, { class: "variable-node" })]
	},

	addNodeView() {
		return ReactNodeViewRenderer(VariablePill)
	},

	addCommands() {
		return {
			setVariable:
				(attributes) =>
				({ commands }) => {
					return commands.insertContent({
						type: this.name,
						attrs: attributes,
					})
				},
		}
	},
})
