import { useChatRelevantDocuments } from "@/hooks/chat/useChatRelevantDocuments"
import type { ProjectDocumentMetadata } from "@/types"
import type { DialogueResponse } from "@/types/chat"
import {
	getRelevantIdsFromMessage,
	isPromptUserDirectiveWithRelevantIds,
} from "@/utils/messageUtils"
import { useEffect } from "react"
import { ChatRelevantDocumentsList } from "./ChatRelevantDocumentsList"
import { DocumentToggleButton } from "./DocumentToggleButton"

interface PromptUserDocumentsProps {
	message: DialogueResponse
	isLastPromptUser: boolean
	fetchDocumentsMetadata: (ids: string[]) => Promise<ProjectDocumentMetadata[]>
	bottomRef?: React.RefObject<HTMLDivElement>
}

export const PromptUserDocuments: React.FC<PromptUserDocumentsProps> = ({
	message,
	isLastPromptUser,
	fetchDocumentsMetadata,
	bottomRef,
}) => {
	const {
		isLoading,
		documents,
		showDocuments,
		setShowDocuments,
		selectedDocuments,
		isApproving,
		fetchDocuments,
		handleDocumentSelected,
		approveDocuments,
		selectedDocumentsError,
	} = useChatRelevantDocuments(fetchDocumentsMetadata)

	// Auto-fetch documents for the last prompt_user message
	useEffect(() => {
		if (
			isLastPromptUser &&
			isPromptUserDirectiveWithRelevantIds(message) &&
			!documents.length &&
			!selectedDocumentsError
		) {
			fetchDocuments(getRelevantIdsFromMessage(message))
			setShowDocuments(true)
		}
	}, [isLastPromptUser, message, documents.length, fetchDocuments, setShowDocuments])

	// Scroll to bottom when documents are loaded
	useEffect(() => {
		if (isLastPromptUser && showDocuments && documents.length > 0 && !isLoading) {
			const timer = setTimeout(() => {
				bottomRef?.current?.scrollIntoView({ behavior: "smooth" })
			}, 100)
			return () => clearTimeout(timer)
		}
	}, [isLastPromptUser, showDocuments, documents.length, isLoading, bottomRef])

	return (
		<div className="mt-2">
			{!isLastPromptUser && (
				<DocumentToggleButton
					isLoading={isLoading}
					showDocuments={showDocuments}
					hasDocuments={documents.length > 0}
					onToggle={() => {
						if (documents.length) {
							setShowDocuments(!showDocuments)
						} else {
							fetchDocuments(getRelevantIdsFromMessage(message))
						}
					}}
				/>
			)}

			{showDocuments && documents.length > 0 && (
				<ChatRelevantDocumentsList
					documents={documents}
					selectedDocuments={selectedDocuments}
					onDocumentSelected={handleDocumentSelected}
					onApprove={approveDocuments}
					isApproving={isApproving}
					bottomRef={isLastPromptUser ? bottomRef : undefined}
				/>
			)}

			{isLastPromptUser && !showDocuments && <div ref={bottomRef} className="h-1" />}
		</div>
	)
}
