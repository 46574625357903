/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import { AdminPageLayout } from "@/features/admin/components"
import { useUserStore } from "@/store"
import { PageName } from "@/types/pages"
import AdminUsageCard from "../components/AdminUsageCard"
import OverviewCards from "../components/OverviewCards"
import { useAdminOverview } from "../hooks/useAdminOverview"

/**
 * @description Admin page component
 */
function AdminPage() {
	const { organizationId } = useUserStore()
	const { adminOverview, isLoading } = useAdminOverview(organizationId)
	if (isLoading) {
		return (
			<AdminPageLayout isSuperAdmin={false} page={PageName.AdminOverview}>
				<Loader />
			</AdminPageLayout>
		)
	}

	if (!adminOverview) {
		return (
			<AdminPageLayout isSuperAdmin={false} page={PageName.AdminOverview}>
				<p className="text-destructive">Error fetching organization details.</p>
			</AdminPageLayout>
		)
	}

	return (
		<AdminPageLayout isSuperAdmin={false} page={PageName.AdminOverview}>
			<div className="flex flex-col gap-4">
				<OverviewCards overview={adminOverview} isSuperAdmin={false} />
				<AdminUsageCard isSuperAdmin={false} />
			</div>
		</AdminPageLayout>
	)
}

export default AdminPage
