import { ScrollArea } from "@/components/ui/scroll-area"
import { useEffect } from "react"
import type React from "react"
import { useChartsContext } from "../../context/ChartsContext"
import { ClaimInvalidityCard } from "./ClaimInvalidityCard"

export interface ClaimInvalidityCardListProps {
	projectId: string
	documentIds: string[]
}

const ClaimInvalidityCardList: React.FC<ClaimInvalidityCardListProps> = ({
	projectId,
	documentIds = [],
}) => {
	const { activeLimitationId, filteredLimitations, selectedClaimRef } =
		useChartsContext()

	useEffect(() => {
		if (activeLimitationId && selectedClaimRef.current) {
			selectedClaimRef.current.scrollIntoView({
				behavior: "smooth",
				block: "center",
			})
		}
	}, [activeLimitationId, selectedClaimRef])

	return (
		<ScrollArea className="h-full px-2 pr-3">
			<div className="mt-2">
				{filteredLimitations.map((limitation) => {
					const isSelected = activeLimitationId === limitation.id
					return (
						<div
							key={limitation.id}
							ref={
								isSelected
									? (el) => {
											// If card is the selected limitation, store ref
											selectedClaimRef.current = el
										}
									: null
							}
							className="mb-2"
						>
							<ClaimInvalidityCard
								limitation={limitation}
								projectId={projectId}
								documents={documentIds}
							/>
						</div>
					)
				})}
			</div>
		</ScrollArea>
	)
}

export default ClaimInvalidityCardList
