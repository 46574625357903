import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import useClaims from "@/hooks/project/useClaims"
import useGroups from "@/hooks/project/useGroups"
import { PlusIcon } from "@radix-ui/react-icons"
import { useState } from "react"
import type React from "react"
import LimitationGroupCard from "./LimitationGroupCard"

interface LimitationGroupModalProps {
	open: boolean
	onClose: () => void
	subjectId: string
}

const LimitationGroupModal: React.FC<LimitationGroupModalProps> = ({
	open,
	onClose,
	subjectId,
}) => {
	const { claimLimitationsGroups = [] } = useClaims(subjectId)
	const { createGroup, updateGroup, deleteGroup } = useGroups()
	const [isCreating, setIsCreating] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const handleClose = () => {
		setIsCreating(false)
		onClose()
	}

	const handleCreateGroup = async ({
		_groupId,
		limitationIds,
		number,
		nickname,
	}: {
		_groupId?: string
		limitationIds: string[]
		number: number
		nickname: string
	}) => {
		setIsLoading(true)
		try {
			await createGroup({
				subjectId: subjectId,
				limitationIds: limitationIds,
				number: number,
				nickname: nickname,
			})
			setIsCreating(false)
		} catch (error) {
			console.error("Error creating group:", error)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<Dialog open={open} onOpenChange={handleClose}>
			<DialogContent className="max-w-3xl min-h-[50vh] flex flex-col justify-start">
				<DialogHeader className="flex flex-row items-center justify-between">
					<DialogTitle>Manage Limitation Groups</DialogTitle>
					<Button
						onClick={() => setIsCreating(true)}
						disabled={isLoading || isCreating}
						className="mr-6"
					>
						<PlusIcon className="h-4 w-4 mr-2" />
						New Group
					</Button>
				</DialogHeader>

				{/* Main content */}
				<div className="space-y-2 max-h-[60vh] overflow-y-auto">
					{!claimLimitationsGroups || claimLimitationsGroups.length === 0
						? !isCreating && (
								<div className="text-center text-muted-foreground py-4">
									No groups created yet
								</div>
							)
						: claimLimitationsGroups.map((group) => (
								<LimitationGroupCard
									key={group.id}
									subjectId={subjectId}
									group={group}
									onSave={updateGroup}
									onDelete={deleteGroup}
									isLoading={isLoading}
								/>
							))}
					{isCreating && (
						<LimitationGroupCard
							subjectId={subjectId}
							group={{
								id: "new",
								number: claimLimitationsGroups
									.map((g) => g.number)
									.sort((a, b) => a - b)
									.reduce(
										(smallest, index) => (smallest === index ? smallest + 1 : smallest),
										1,
									),
								nickname: "",
								limitations: [],
								createdAt: "",
								patentId: "",
								createdBy: "",
							}}
							onSave={handleCreateGroup}
							onDelete={() => setIsCreating(false)}
							isLoading={isLoading}
							isNew={true}
						/>
					)}
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default LimitationGroupModal
