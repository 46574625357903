import { api } from "@/api"
import { useProjectContext } from "@/context/ProjectContext"
import { useApi } from "@/hooks"
import type { DocumentContext } from "@/types"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import {
	handleOptimisticUpdate,
	revertOptimisticUpdates,
} from "@/utils/query/optimisticUpdates"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

/**
 * A hook for managing document context data within a project.
 *
 * This hook provides functionality to fetch and update document context information,
 * including optimistic updates for better UX. It uses React Query for data management
 * and caching.
 *
 * @param documentId - The ID of the document to fetch/manage context for
 * @returns {Object} An object containing:
 *   - context: The current document context data
 *   - isLoading: Loading states for different operations
 *   - isError: Error states for different operations
 *   - isFetching: Fetching states for different operations
 *   - isPending: Pending states for mutations
 *   - updateDocumentContext: Function to update the document context
 */
const useDocumentContextData = (documentId: string) => {
	const queryClient = useQueryClient()
	const { handleError } = useApi()
	const { projectId } = useProjectContext()

	// Queries
	const context = useQuery({
		queryKey: QUERY_KEYS.project.document.context(projectId, documentId),
		queryFn: () => api.getDocumentContext(documentId),
		enabled: !!projectId && !!documentId,
		...DEFAULT_QUERY_OPTIONS,
	})

	const updateContextMutation = useMutation({
		mutationKey: MUTATION_KEYS.document.context.update(),
		mutationFn: ({
			documentId,
			context,
		}: {
			documentId: string
			context: DocumentContext
		}) => api.updateDocumentContext(documentId, context),
		onMutate: async (variables) => {
			// Set up optimistic update
			const queryKey = QUERY_KEYS.project.document.context(projectId, variables.documentId)
			const ctx = await handleOptimisticUpdate({
				queryClient,
				queryKeys: [queryKey],
				updateFn: () => variables.context,
			})
			return ctx
		},
		onSuccess: (_, variables) => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.document.context(projectId, variables.documentId),
			})
			return { success: true }
		},
		onError: (error, _variables, context) => {
			// Revert optimistic update on error
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
			handleError(error, "Error saving document context")
		},
	})

	return {
		// Query data
		context: context.data,

		// Loading states
		isLoading: {
			documentContext: context.isLoading,
		},

		// Error states
		isError: {
			documentContext: context.isError,
		},

		// Fetching states
		isFetching: {
			documentContext: context.isFetching,
		},

		// Pending states for mutations
		isPending: {
			updateDocumentContext: updateContextMutation.isPending,
		},

		// Mutation functions with type safety
		updateDocumentContext: (documentId: string, context: DocumentContext) =>
			updateContextMutation.mutate({ documentId, context }),
	}
}

export default useDocumentContextData
