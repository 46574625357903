import { useChat, useNavigationState } from "@/hooks"
import type { ChatMetadata, DialogueResponse } from "@/types/chat"
import {
	type ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react"

import { useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

import { invalidateChatQueries } from "@/utils"
import { useProjectContext } from "./ProjectContext"

interface ChatContextType {
	isPanelChatOpen: boolean
	setIsPanelChatOpen: (open: boolean) => void
	isExpandedChatOpen: boolean
	setIsExpandedChatOpen: (open: boolean) => void
	selectedDocumentIds: string[]
	setSelectedDocumentIds: (ids: string[]) => void
	activeChatId: string | null
	setActiveChatId: (chatId: string | null) => void
	activeChatMetadata: ChatMetadata | null
	isChatListCollapsed: boolean
	setIsChatListCollapsed: (collapsed: boolean) => void
	isAndyResponding: boolean
	setIsAndyResponding: (isResponding: boolean) => void
	pollingSessionId: string | null
	setPollingSessionId: (sessionId: string | null) => void
	thinkingString: string
	setThinkingString: (thinkingString: string) => void
	pollingCountRef: React.MutableRefObject<number>
	pollingCountMax: number
	messages: DialogueResponse[]
	setMessages: React.Dispatch<React.SetStateAction<DialogueResponse[]>>
}

const ChatContext = createContext<ChatContextType | undefined>(undefined)

export function PanelChatProvider({ children }: { children: ReactNode }) {
	// Hooks
	const { chatsMetadata } = useChat()
	const { isExpandedChatPage } = useNavigationState()
	const navigate = useNavigate()
	const { projectId } = useProjectContext()
	const queryClient = useQueryClient()

	// State
	const [pollingSessionId, setPollingSessionId] = useState<string | null>(null)
	const [isAndyResponding, setIsAndyResponding] = useState(false)
	const [thinkingString, setThinkingString] = useState<string>("Thinking")
	const pollingCountRef = useRef(0)
	const pollingCountMax = 500
	const [messages, setMessages] = useState<DialogueResponse[]>([])

	const [isExpandedChatOpen, setIsExpandedChatOpen] =
		useState<boolean>(isExpandedChatPage)
	const [activeChatId, setActiveChatId] = useState<string | null>(null)
	const activeChatMetadata = chatsMetadata?.find((chat) => chat.id === activeChatId)

	const [isChatListCollapsed, setIsChatListCollapsed] = useState<boolean>(() => {
		const saved = localStorage.getItem("panelchat.isChatListCollapsed")
		return saved ? JSON.parse(saved) : false
	})

	const [isPanelChatOpen, setIsPanelChatOpen] = useState(() => {
		const saved = localStorage.getItem("panelchat.isPanelChatOpen")
		return saved ? JSON.parse(saved) : false
	})

	// State update handlers
	const handleSetIsPanelChatOpen = useCallback(
		(open: boolean) => {
			// Only update panel chat status and active chat if expanded chat is not open
			if (!isExpandedChatOpen) {
				setIsPanelChatOpen(open)

				// Invalidate chat queries when panel chat state changes
				if (projectId) {
					invalidateChatQueries(queryClient, projectId)
				}
			}
			if (open === false) {
				setActiveChatId(null)
			}
		},
		[isExpandedChatOpen, projectId, queryClient],
	)

	const handleSetIsExpandedChatOpen = useCallback(
		(open: boolean) => {
			if (open && projectId) {
				setIsExpandedChatOpen(open)
				navigate(`/project/${projectId}/chat`)
				setIsPanelChatOpen(false)
				setIsChatListCollapsed(false)
			} else if (open === false) {
				window.history.back()
				// Only update state on animation frame to make the navigation feel immediate
				requestAnimationFrame(() => {
					setIsExpandedChatOpen(false)
					setActiveChatId(null)
				})
			}
		},
		[navigate, projectId],
	)

	// UseEffects updating state
	useEffect(() => {
		setIsExpandedChatOpen(isExpandedChatPage)
	}, [isExpandedChatPage])

	const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>(() => {
		const saved = localStorage.getItem("panelchat.selectedDocumentIds")
		return saved ? JSON.parse(saved) : []
	})

	useEffect(() => {
		localStorage.setItem("panelchat.isPanelChatOpen", JSON.stringify(isPanelChatOpen))
	}, [isPanelChatOpen])

	useEffect(() => {
		localStorage.setItem(
			"panelchat.isChatListCollapsed",
			JSON.stringify(isChatListCollapsed),
		)
	}, [isChatListCollapsed])

	useEffect(() => {
		localStorage.setItem(
			"panelchat.selectedDocumentIds",
			JSON.stringify(selectedDocumentIds),
		)
	}, [selectedDocumentIds])

	// Add invalidation to setActiveChatId
	const handleSetActiveChatId = useCallback(
		(chatId: string | null) => {
			setActiveChatId(chatId)

			// Invalidate chat queries when active chat changes
			if (projectId) {
				invalidateChatQueries(queryClient, projectId)
			}
		},
		[projectId, queryClient],
	)

	return (
		<ChatContext.Provider
			value={{
				isPanelChatOpen,
				setIsPanelChatOpen: handleSetIsPanelChatOpen,
				isExpandedChatOpen,
				setIsExpandedChatOpen: handleSetIsExpandedChatOpen,
				selectedDocumentIds,
				setSelectedDocumentIds,
				activeChatId,
				setActiveChatId: handleSetActiveChatId,
				activeChatMetadata,
				isChatListCollapsed,
				setIsChatListCollapsed,
				isAndyResponding,
				setIsAndyResponding,
				pollingSessionId,
				setPollingSessionId,
				thinkingString,
				setThinkingString,
				pollingCountRef,
				pollingCountMax,
				messages,
				setMessages,
			}}
		>
			{children}
		</ChatContext.Provider>
	)
}

export function useChatContext() {
	const context = useContext(ChatContext)
	const pollingCountRef = useRef(0)
	if (context === undefined) {
		// Return mock interface instead of throwing
		return {
			isPanelChatOpen: false,
			setIsPanelChatOpen: (_open: boolean) => {},
			isExpandedChatOpen: false,
			setIsExpandedChatOpen: (_open: boolean) => {},
			selectedDocumentIds: [],
			setSelectedDocumentIds: (_ids: string[]) => {},
			activeChatId: null,
			setActiveChatId: (_chatId: string | null) => {},
			activeChatMetadata: null,
			isChatListCollapsed: false,
			setIsChatListCollapsed: (_collapsed: boolean) => {},
			isAndyResponding: false,
			setIsAndyResponding: (_isResponding: boolean) => {},
			pollingSessionId: null,
			setPollingSessionId: (_sessionId: string | null) => {},
			thinkingString: "Thinking",
			setThinkingString: (_thinkingString: string) => {},
			pollingCountRef: pollingCountRef,
			pollingCountMax: 100,
			messages: [],
			setMessages: (_messages: DialogueResponse[]) => {},
		}
	}
	return context
}

// Todo @chrissea: add a chat ui for outside project
export function MockChatWindow({ isPanelChatOpen }: { isPanelChatOpen: boolean }) {
	if (!isPanelChatOpen) return null
}
