/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { TooltipProvider } from "@/components/ui/tooltip"
import { H4, Paragraph } from "@/components/ui/typography"
// Admin Pages
import AdminBillingPage from "@/features/admin/admin/AdminBillingPage"
import AdminPage from "@/features/admin/admin/AdminPage"
import AdminUsersPage from "@/features/admin/admin/AdminUsersPage"
import SuperAdminBillingPage from "@/features/admin/superadmin/SuperAdminBillingPage"
import SuperAdminOrganizationDetailsPage from "@/features/admin/superadmin/SuperAdminOrganizationDetailsPage"
import SuperAdminOrganizationPage from "@/features/admin/superadmin/SuperAdminOrganizationPage"
import SuperAdminPage from "@/features/admin/superadmin/SuperAdminPage"
// Project Pages
import InvalidityChartsOverviewPage from "@/features/charts/InvalidityChartsOverviewPage"
import InvalidityChartsPage from "@/features/charts/InvalidityChartsPage"
import ChatPage from "@/features/chat/ChatPage"
// Create Pages
import CreateProjectPage from "@/features/create/CreateProjectPage"
import ProjectDocumentPage from "@/features/documents/ProjectDocumentPage"
import ProtectedRoute from "@/routes/ProtectedRoute"
import { ThemeProvider } from "@/styled/ThemeProvider"
import { AuthProvider } from "@propelauth/react"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { isMobile } from "react-device-detect"
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom"

import PageNotFound from "@/features/PageNotFound"
// Misc. Pages
import LogInPage from "@/features/auth/LogInPage"
import ProjectChartsMatrixPage from "@/features/charts/ProjectChartsMatrixPage"
import ProjectSearchPage from "@/features/documents/DocumentsExplorePage"
import DocumentsPage from "@/features/documents/DocumentsPage"
import HomePage from "@/features/home/HomePage"

import { ProjectRoute } from "@/routes/ProjectRoute"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import UnauthorizedPage from "./features/UnauthorizedPage"
import DocumentsSearchPage from "./features/search/DocumentsSearchPage"
import ProjectSettingsPage from "./features/settings/ProjectSettingsPage"
import { usePostHogIdentify } from "./hooks/integrations/usePostHogIdentity"
import { useSentryReplay } from "./hooks/integrations/useSentryReplay"
import { useSetupApiAuth } from "./hooks/useApiAuth"
import AdminRoute from "./routes/AdminRoute"
import { NonProjectRoute } from "./routes/NonProjectRoute"
import SuperAdminRoute from "./routes/SuperAdminRoute"
import DownPage from "./features/DownPage"

// Create QueryClient instance outside of component
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 1,
			refetchOnWindowFocus: false,
		},
	},
})

const AppContent: React.FC = () => {
	usePostHogIdentify()
	useSentryReplay()
	useSetupApiAuth()

	return (
		<Router>
			<Routes>
				{/* Auth Routes */}
				<Route path="/signin" element={<LogInPage />} />
				<Route path="/" element={<Navigate to="/signin" />} />
				<Route path="*" element={<Navigate to="/signin" />} />

				<Route element={<ProtectedRoute />}>
					<Route element={<NonProjectRoute />}>
						{/* Admin Routes */}
						<Route element={<AdminRoute />}>
							<Route path="/admin" element={<AdminPage />} />
							<Route path="/admin/users" element={<AdminUsersPage />} />
							<Route path="/admin/billing" element={<AdminBillingPage />} />
						</Route>

						{/* Super Admin Routes */}
						<Route element={<SuperAdminRoute />}>
							<Route path="/superadmin" element={<SuperAdminPage />} />
							<Route path="/superadmin/billing" element={<SuperAdminBillingPage />} />
							<Route
								path="/superadmin/organizations"
								element={<SuperAdminOrganizationPage />}
							/>
							<Route
								path="/superadmin/organizations/:organizationId"
								element={<SuperAdminOrganizationDetailsPage />}
							/>
						</Route>

						{/* Create Routes */}
						<Route path="/create-project" element={<CreateProjectPage />} />

						{/* Home Route */}
						<Route path="/home" element={<HomePage />} />

						<Route path="/maintenance" element={<DownPage />} />

						{/* Page Not Found Route */}
						<Route path="*" element={<PageNotFound />} />


					</Route>

					{/* Project Routes */}
					<Route element={<ProjectRoute />}>
						<Route path="/project/:projectId/documents" element={<DocumentsPage />} />

						<Route
							path="/project/:projectId/documents/:documentId"
							element={<ProjectDocumentPage />}
						/>
						<Route path="/project/:projectId/charts" element={<ProjectChartsMatrixPage />} />
						<Route
							path="/project/:projectId/charts/:subjectId"
							element={<InvalidityChartsOverviewPage />}
						/>
						<Route
							path="/project/:projectId/charts/:subjectId/:invalidityChartId"
							element={<InvalidityChartsPage />}
						/>
						<Route
							path="/project/:projectId/documents/explore"
							element={<ProjectSearchPage />}
						/>
						<Route
							path="/project/:projectId/documents/search"
							element={<DocumentsSearchPage />}
						/>
						<Route path="/project/:projectId/chat" element={<ChatPage />} />

						<Route path="/project/:projectId/settings" element={<ProjectSettingsPage />} />

						{/* Unauthorized Route */}
						<Route path="/project/:projectId/unauthorized" element={<UnauthorizedPage />} />
					</Route>
				</Route>
			</Routes>
		</Router>
	)
}

const App: React.FC = () => {
	if (isMobile) {
		return (
			<div
				style={{
					width: "100vw",
					padding: 20,
					textAlign: "center",
					marginTop: 50,
				}}
			>
				<H4>&AI</H4>
				<Paragraph>Mobile use is not supported.</Paragraph>
				<Paragraph>Please access on a computer.</Paragraph>
			</div>
		)
	}

	return (
		<AuthProvider authUrl={process.env.REACT_APP_AUTH_URL || ""}>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider defaultTheme="light" storageKey="ui-theme">
					<TooltipProvider>
						<AppContent />
					</TooltipProvider>
				</ThemeProvider>
				{process.env.NODE_ENV !== "production" && (
					<ReactQueryDevtools initialIsOpen={true} buttonPosition="bottom-left" />
				)}
			</QueryClientProvider>
		</AuthProvider>
	)
}

export default App
