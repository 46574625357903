/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import ClaimLimitationLabelPill from "@/components/claims/ClaimLimitationLabelPill"
import {} from "@/components/ui/tooltip"
import { useProjectContext } from "@/context/ProjectContext"
import { useFeedbackData } from "@/features/charts/hooks/useFeedbackData"
import { useProjectSettings } from "@/features/settings/hooks/useProjectSettings"
import useVariables from "@/hooks/useVariables"
import { cn } from "@/lib/utils"
import { ChartMode, type CollapsedLimitation } from "@/types"
import { parseHtml } from "@/utils/parseHtml"
import { useEffect, useMemo, useState } from "react"
import type React from "react"
import { useInView } from "react-intersection-observer"
import { useChartsContext } from "../../context/ChartsContext"
import { useChunkToLimitationUpdates, useClaimInvalidityData } from "../../hooks"
import CopyCitationsModal from "../modals/CopyCitationsModal"
import EditCellBoilerplateModal from "../modals/EditCellBoilerplateModal"
import InvalidityTableCellCollapsibleCitations from "./InvalidityTableCellCollapsibleCitations"
import InvalidityTableCitationCellTooltips from "./InvalidityTableCitationCellTooltips"
import InvalidityTableDocumentCitationGroup from "./InvalidityTableDocumentCitationGroup"

interface InvalidityTableLimitationCellProps {
	collapsedLimitation: CollapsedLimitation
}

/**
 * @description Div-based citation cell
 * contain language, header, footer, middle language,
 * +, copy
 * document blocks
 */
const InvalidityTableLimitationCell: React.FC<InvalidityTableLimitationCellProps> = ({
	collapsedLimitation,
}) => {
	const { projectId } = useProjectContext()
	const { findMoreCitationsForLimitation } = useChunkToLimitationUpdates()

	const {
		filterColors,
		activeLimitationId,
		setActiveLimitationId,
		selectedTableRowRef,
		chartData,
		setSelectedChartMode,
		invalidityChartId,
		isCombination,
		subjectId,
	} = useChartsContext()

	// Determine whether chartData is available.
	const hasChartData = chartData?.documentIds?.length > 0
	const { settings: projectSettings } = useProjectSettings()

	// Call useFeedbackData unconditionally.
	const { positiveFeedbackData, negativeFeedbackData } = useFeedbackData(
		projectId,
		invalidityChartId,
		collapsedLimitation.id,
	)

	// Use Intersection Observer with the viewport as the root.
	const { ref: inViewRef, inView } = useInView({
		root: null, // use the browser viewport
		rootMargin: "200px",
		triggerOnce: false,
	})

	const { limitationInvalidityData } = useClaimInvalidityData(
		invalidityChartId,
		subjectId,
		collapsedLimitation.id,
		{
			projectId: projectId ?? "",
			documents: [],
			colors: filterColors,
			enabled: Boolean(inView && projectId), // chartData is checked above
		},
	)

	// Use a safe default (empty object) in case limitationInvalidityData doesn't have the key.
	const dataForLimitation = useMemo(
		() => limitationInvalidityData?.[collapsedLimitation.id],
		[limitationInvalidityData, collapsedLimitation.id],
	)

	const docCitations = useMemo(() => dataForLimitation?.citations, [dataForLimitation])

	const rowMetadata = useMemo(() => dataForLimitation?.metadata, [dataForLimitation])

	const headerFormat = useMemo(
		() => rowMetadata?.header || projectSettings?.headerFormat,
		[rowMetadata, projectSettings],
	)
	const middleFormat = useMemo(
		() => rowMetadata?.middle || projectSettings?.middleFormat,
		[rowMetadata, projectSettings],
	)
	const footerFormat = useMemo(
		() => rowMetadata?.footer || projectSettings?.footerFormat,
		[rowMetadata, projectSettings],
	)

	const isSelected = useMemo(
		() => activeLimitationId === collapsedLimitation.id,
		[activeLimitationId, collapsedLimitation.id],
	)

	const [isInvalidityLoading, setIsInvalidityLoading] = useState(false)
	const [showCopyCitationsModal, setShowCopyCitationsModal] = useState(false)
	const [isHeaderFooterModalOpen, setIsHeaderFooterModalOpen] = useState(false)

	const removedCitations = useMemo(
		() =>
			Object.values(docCitations || {}).flatMap((citations) =>
				citations.filter((citation) => citation.removed === true),
			),
		[docCitations],
	)
	const verifiedCitations = useMemo(
		() =>
			Object.values(docCitations || {}).flatMap((citations) =>
				citations.filter((citation) => citation.verified === true),
			),
		[docCitations],
	)

	// At the top level of your component:
	const format = useVariables({
		documentIds: chartData?.documentIds,
		subjectId: chartData?.subjectId,
		claimLimitation: collapsedLimitation,
	})

	const findMoreCitations = async () => {
		setIsInvalidityLoading(true)
		try {
			const positiveExamples = positiveFeedbackData
				.map((citation) => citation.documentChunk?.text)
				.filter((text): text is string => Boolean(text))
			const negativeExamples = negativeFeedbackData
				.map((citation) => citation.documentChunk?.text)
				.filter((text): text is string => Boolean(text))
			await findMoreCitationsForLimitation({
				claimLimitationId: collapsedLimitation.id,
				documentId: chartData?.documentIds?.[0],
				positiveExamples,
				negativeExamples,
			})
		} finally {
			setIsInvalidityLoading(false)
		}
	}

	useEffect(() => {
		if (isSelected) {
			selectedTableRowRef.current?.scrollIntoView({
				behavior: "smooth",
				block: "start",
				inline: "nearest",
			})
		}
	}, [isSelected, selectedTableRowRef])

	// Early return is now done after calling all hooks.
	if (!hasChartData) {
		return <Loader message="Loading chart data..." />
	}

	return (
		<div
			className={cn(
				"w-full p-2 px-3 border rounded-lg cursor-pointer hover:bg-accent",
				isSelected ? "border-blue-500 bg-accent" : "",
			)}
		>
			<div ref={inViewRef}>
				<div className="mb-2">
					{isInvalidityLoading ? (
						<div className="mb-2">
							<Loader message="Retrieving invalidity..." />
						</div>
					) : (
						<div
							className="flex items-center justify-between"
							ref={isSelected ? selectedTableRowRef : null}
						>
							<ClaimLimitationLabelPill
								id={collapsedLimitation.id}
								label={collapsedLimitation.label}
							/>
							<InvalidityTableCitationCellTooltips
								isInvalidityLoading={isInvalidityLoading}
								// showNoMoreCitations={showNoMoreCitations}
								onFindMoreCitations={!isCombination && (() => findMoreCitations())}
								onAddFromDocument={() => {
									setSelectedChartMode(ChartMode.DOCUMENT)
									setActiveLimitationId(collapsedLimitation.id)
								}}
								onCopyEvidence={!isCombination && (() => setShowCopyCitationsModal(true))}
								onEditHeaderFooter={() => setIsHeaderFooterModalOpen(true)}
							/>
						</div>
					)}
				</div>
				{headerFormat && (
					<div className="text-sm text-foreground my-4">
						{parseHtml(format(headerFormat))}
					</div>
				)}
				<div className="flex flex-col gap-2 mb-2">
					{Object.entries(docCitations || {}).map(
						([documentIdEntry, citations], citationIndex, docArray) => (
							<>
								<InvalidityTableDocumentCitationGroup
									key={`document-${documentIdEntry}-${collapsedLimitation.id}-${citationIndex}`}
									documentId={documentIdEntry}
									claimLimitationId={collapsedLimitation.id}
									claimLimitationLabel={collapsedLimitation.label}
									citations={citations}
								/>
								{citationIndex === 0 &&
									middleFormat &&
									citationIndex !== docArray.length - 1 && (
										<div className="text-sm text-foreground my-4">
											{parseHtml(format(middleFormat))}
										</div>
									)}
							</>
						),
					)}
				</div>
				{footerFormat && (
					<div className="text-sm text-foreground my-4">
						{parseHtml(format(footerFormat))}
					</div>
				)}
				<InvalidityTableCellCollapsibleCitations
					positiveFeedbackData={positiveFeedbackData}
					negativeFeedbackData={negativeFeedbackData}
					removedCitations={removedCitations}
					verifiedCitations={verifiedCitations}
				/>
				{isHeaderFooterModalOpen && (
					<EditCellBoilerplateModal
						isOpen={true}
						onClose={() => setIsHeaderFooterModalOpen(false)}
						claimLimitation={collapsedLimitation}
						cellHeader={rowMetadata?.header ?? ""}
						cellFooter={rowMetadata?.footer ?? ""}
						cellMiddle={rowMetadata?.middle ?? ""}
					/>
				)}
				{chartData?.documentIds?.[0] && (
					<CopyCitationsModal
						open={showCopyCitationsModal}
						handleClose={() => setShowCopyCitationsModal(false)}
						documentId={chartData?.documentIds?.[0]}
						claimLimitationId={collapsedLimitation.id}
						claimLimitationLabel={collapsedLimitation.label}
					/>
				)}
			</div>
		</div>
	)
}

export default InvalidityTableLimitationCell
