import { useProjectContext } from "@/context/ProjectContext"
import { useAllProjectDocuments } from "@/features/documents/hooks/useAllProjectDocuments"
import type { Document } from "@/types/documents"

/**
 * useDocumentInfo is a custom hook that provides a getter function to retrieve
 * a field value from a document reference.
 *
 * The function will try to find the field in the following order:
 * 1. The Project-to-Document (PTD) level.
 * 2. The Document level (i.e. doc.document itself).
 * 3. The appropriate nested branch, which is determined by the document type.
 *    • For a PATENT document, it checks doc.document.patent.
 *    • For a REFERENCE document, it checks doc.document.npl.
 *    • For an OFFICE_ACTION document, it checks doc.document.officeAction.
 *    • For a STANDARD document, it checks doc.document.standard.
 * @returns An object with getDocumentField, which retrieves the specified field.
 */
const useDocumentInfo = () => {
	const { projectId } = useProjectContext()

	// Get the documents from their respective contexts
	const { allDocumentsMetadata: projectDocuments } = useAllProjectDocuments()

	/**
	 * Looks up a document reference by id from the project documents.
	 * @param id Document id to look up.
	 * @returns The document reference or undefined.
	 */
	const getDocumentById = (id: string): Document | undefined => {
		if (projectId) {
			return projectDocuments?.find((ref) => ref.id === id) as Document
		}
		return undefined
	}

	/**
	 * Retrieves a field value from a document via its id.
	 * It attempts to look up the document, then checks:
	 * 1. The top-level on the PTD reference.
	 * 2. The document-level (doc.document).
	 * 3. The nested branch corresponding to the document type.
	 *
	 * @param id Document id.
	 * @param field Field name to retrieve.
	 * @returns The field value, or undefined if not found.
	 */
	const getDocumentField = (id: string, field: string): any => {
		const doc = getDocumentById(id)
		if (!doc) return undefined

		// 1. Try at the PTD (top) level.
		if (Object.prototype.hasOwnProperty.call(doc, field)) {
			return (doc as any)[field]
		}

		// 2. Try on the Document level.
		if (doc && Object.prototype.hasOwnProperty.call(doc, field)) {
			return (doc as any)[field]
		}

		// 3. Depending on the document type, try the appropriate branch.
		if (doc) {
			let branch: any = null
			switch (doc.type) {
				case "PATENT":
					branch = doc.patent
					break
				case "NPL":
					branch = doc.npl
					break
				case "OFFICE_ACTION":
					branch = doc.officeAction
					break
				case "STANDARD":
					branch = doc.standard
					break
				default:
					break
			}
			if (branch && Object.prototype.hasOwnProperty.call(branch, field)) {
				return branch[field]
			}
		}

		return undefined
	}

	return { getDocumentField }
}

export default useDocumentInfo
