"use client"

import { ChartContainer, ChartTooltip } from "@/components/ui/chart"
import type React from "react"
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts"

export interface BaseChartProps<T> {
	data: T[]
	xKey: keyof T
	yKeys: (keyof T)[]
	/**
	 * Colors used for the lines.
	 */
	lineColors?: string[]
	xTickFormatter?: (value: any, index: number) => string
	yTickFormatter?: (value: any) => string
	/**
	 * The chart container's height in pixels.
	 */
	chartHeight?: number
	/**
	 * Margin around the chart. Default uses 12 (similar to UserSessionsChart)
	 */
	margin?: { top?: number; right?: number; bottom?: number; left?: number }
	tooltipContent?: React.ReactElement | ((props: any) => React.ReactNode)
	chartConfig: any
}

export function BaseLineChart<T>({
	data,
	xKey,
	yKeys,
	lineColors = ["#8884d8"],
	xTickFormatter,
	yTickFormatter,
	chartHeight = 250,
	margin = { top: 12, right: 12, bottom: 12, left: 12 },
	tooltipContent,
	chartConfig,
}: BaseChartProps<T>) {
	return (
		<ChartContainer
			className="w-full"
			style={{ height: chartHeight }}
			config={chartConfig}
		>
			<LineChart data={data} margin={margin}>
				<CartesianGrid vertical={false} />
				<XAxis
					dataKey={xKey as string}
					tickFormatter={xTickFormatter}
					tickLine={false}
					axisLine={false}
					tickMargin={8}
					minTickGap={32}
				/>
				<YAxis
					tickFormatter={yTickFormatter}
					tickLine={false}
					axisLine={false}
					tickMargin={8}
				/>
				{tooltipContent ? <ChartTooltip content={tooltipContent} /> : <ChartTooltip />}
				{yKeys.map((yKey, index) => (
					<Line
						key={yKey as string}
						type="monotone"
						dataKey={yKey as string}
						stroke={lineColors[index] || lineColors[0]}
						strokeWidth={2}
						dot={false}
					/>
				))}
			</LineChart>
		</ChartContainer>
	)
}

export default BaseLineChart
