import MultiDocumentSelect from "@/components/projects/MultiDocumentSelect"
import { CheckboxWithText } from "@/components/ui/checkbox"
import { Label } from "@/components/ui/label"
import useProjectSubjectDocuments from "@/features/documents/hooks/useProjectSubjectDocuments"
import { DocumentRole, UnprocessedDocumentType } from "@/types/documents"
import type React from "react"

interface ProcessChartingOptionsProps {
	documentRole: DocumentRole
	documentType: UnprocessedDocumentType
	selectedSubjectIds: string[]
	setSelectedSubjectIds: (ids: string[]) => void
	// For SUBJECT role
	selectedPriorArtIds?: string[]
	setSelectedPriorArtIds?: (ids: string[]) => void
	selectedInfringementIds?: string[]
	setSelectedInfringementIds?: (ids: string[]) => void
	priorArtDocuments?: any[]
	infringementDocuments?: any[]
	// Claims checkbox state
	useClaimsCheckboxChecked: boolean
	setUseClaimsCheckboxChecked: (checked: boolean) => void
	chartAgainstSelf: boolean
	setChartAgainstSelf: (checked: boolean) => void
}

const ProcessChartingOptions: React.FC<ProcessChartingOptionsProps> = ({
	documentRole,
	documentType,
	selectedSubjectIds,
	setSelectedSubjectIds,
	selectedPriorArtIds,
	setSelectedPriorArtIds,
	selectedInfringementIds,
	setSelectedInfringementIds,
	priorArtDocuments,
	infringementDocuments,
	useClaimsCheckboxChecked,
	setUseClaimsCheckboxChecked,
	chartAgainstSelf,
	setChartAgainstSelf,
}) => {
	const { subjectDocuments } = useProjectSubjectDocuments()
	return (
		<div>
			<Label className="text-sm mb-2">Charting Options</Label>
			<div className="grid grid-cols-1 gap-4">
				<div className="mb-2">
					{/* Render subject selection if the document role is PRIOR_ART or INFRINGEMENT */}
					{(documentRole === DocumentRole.PRIOR_ART ||
						documentRole === DocumentRole.INFRINGEMENT) && (
						<>
							<Label className="text-xs mb-2 text-muted-foreground">Subject Documents</Label>
							<MultiDocumentSelect
								selectedDocumentIds={selectedSubjectIds}
								documents={subjectDocuments}
								onChange={setSelectedSubjectIds}
								label="Subject Documents"
							/>
						</>
					)}

					{/* Render reference selection for SUBJECT */}
					{documentRole === DocumentRole.SUBJECT &&
						((priorArtDocuments && priorArtDocuments.length > 0) ||
							(infringementDocuments && infringementDocuments.length > 0)) && (
							<div className="flex flex-row gap-2">
								{priorArtDocuments && priorArtDocuments.length > 0 && (
									<div className="flex flex-col gap-1 w-full">
										<Label className="text-sm text-muted-foreground">Prior Art Documents</Label>
										<MultiDocumentSelect
											selectedDocumentIds={selectedPriorArtIds}
											documents={priorArtDocuments}
											onChange={setSelectedPriorArtIds}
										/>
									</div>
								)}
								{infringementDocuments && infringementDocuments.length > 0 && (
									<div className="flex flex-col gap-1 w-full">
										<Label className="text-sm text-muted-foreground">
											Infringement Documents
										</Label>
										<MultiDocumentSelect
											selectedDocumentIds={selectedInfringementIds}
											documents={infringementDocuments}
											onChange={setSelectedInfringementIds}
										/>
									</div>
								)}
							</div>
						)}
				</div>
				{/* Checkbox for including claims in charting and charting self if subject */}
				<div className="flex flex-row gap-4">
					{documentType === UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION && (
						<CheckboxWithText
							id="includeClaims"
							checked={useClaimsCheckboxChecked}
							onCheckedChange={(checked) => setUseClaimsCheckboxChecked(Boolean(checked))}
							label="Include claims as citations"
						/>
					)}
					{documentRole === DocumentRole.SUBJECT && (
						<CheckboxWithText
							id="chartAgainstSelf"
							checked={chartAgainstSelf}
							onCheckedChange={(checked) => setChartAgainstSelf(Boolean(checked))}
							label="Chart against self"
						/>
					)}
				</div>
			</div>
		</div>
	)
}

export default ProcessChartingOptions
