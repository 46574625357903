import { ConfirmRemoveChunkModal } from "@/components"
import FigureUrlsDisplay from "@/components/images/FigureUrlsDisplay"
import FiguresModal from "@/components/images/FiguresModal"
import { Button } from "@/components/ui/button"
import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuTrigger,
} from "@/components/ui/context-menu"
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import { CondensedParagraph } from "@/components/ui/typography"
import { DeleteCitationFromAllElementsModal } from "@/features/charts/components"
import useDocumentFigures from "@/features/documents/hooks/useDocumentFigures"
import { useDocumentChunk } from "@/hooks"
import { cn } from "@/lib/utils"
import { useTheme } from "@/styled/ThemeProvider"
import { BlockType, type CitationFormat, type DocumentChunk } from "@/types"
import { toSnakeCase } from "@/utils/dataUtils"
import { cleanDocumentChunkText } from "@/utils/formatStrings"
import { getSubCitationLocation } from "@/utils/getSelectedCitationLocation"
import { parseHtml } from "@/utils/parseHtml"
import { formatDocumentChunkLocation } from "@/utils/projectUtils"
import { MinusCircle, Pen } from "lucide-react"
import type React from "react"
import { useMemo, useState } from "react"
import DocumentChunkText from "./DocumentChunkText"
import { EditDocumentChunkDialog } from "./EditDocumentChunkDialog"
import EquationRenderer from "./EquationRenderer"

interface DocumentChunkProps {
	chunk: DocumentChunk
	exactMatchText?: string
	highlightedRef?: React.RefObject<HTMLDivElement>
	isTextHighlighted?: boolean
	onChunkUpdate?: (updatedChunk: DocumentChunk) => void
	figureChunks?: DocumentChunk[]
	editMode?: boolean
	showFigures?: boolean
	onFigureReferenceSelect?: (figureChunk: DocumentChunk) => void
	citationFormat?: CitationFormat
	selectedChunkId?: string
	onChunkSelect?: (chunkId: string) => void
	inBody?: boolean
	reason?: string
	showCitationReasons?: boolean
}

/**
 * Wrap matching text in a span with a highlight class.
 */
function highlightText(
	text: string,
	search: string,
	highlightClass = "bg-citation-highlight",
) {
	if (!search || !search.trim()) {
		return text
	}

	// Be careful to escape regex special chars if needed:
	const escaped = search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
	const regex = new RegExp(`(${escaped})`, "gi")

	return text.split(regex).map((part, i) => {
		// If part matches the search term, highlight it.
		if (part.toLowerCase() === search.toLowerCase()) {
			return (
				<span key={i} className={highlightClass}>
					{part}
				</span>
			)
		}
		return part
	})
}

export const DocumentChunkComponent: React.FC<DocumentChunkProps> = ({
	chunk,
	exactMatchText,
	highlightedRef,
	isTextHighlighted,
	onChunkUpdate,
	figureChunks,
	editMode,
	citationFormat,
	showFigures = false,
	onFigureReferenceSelect,
	selectedChunkId,
	onChunkSelect,
	inBody = false,
	reason,
	showCitationReasons = false,
}) => {
	const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
	const [showDeleteCitationFromAllModal, setShowDeleteCitationFromAllModal] =
		useState(false)
	const { updateDocumentChunk, updateDocumentChunkIsLoading } = useDocumentChunk()
	const [showFiguresModal, setShowFiguresModal] = useState(false)
	const { figureChunks: modalFigureChunks } = useDocumentFigures(chunk.documentId)
	const { deleteDocumentChunk } = useDocumentChunk()
	const [selectedFigureChunk, setSelectedFigureChunk] = useState<DocumentChunk | null>(
		null,
	)
	const [hasSelection, setHasSelection] = useState(false)
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

	const { theme } = useTheme()
	const isDarkMode = useMemo(() => theme === "dark", [theme])

	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text)
	}

	const handleFigureClick = (imageUrl: string) => {
		const matchingFigure = modalFigureChunks?.find((f) => f.figureUrl === imageUrl)
		setSelectedFigureChunk(matchingFigure || null)
		setShowFiguresModal(true)
	}

	const handleDocumentChunkUpdate = async (
		updatedChunk: DocumentChunk,
		originalChunk: DocumentChunk,
	) => {
		const updates: Record<string, unknown> = {
			documentChunkId: updatedChunk.id,
		}

		// Compare text
		if (updatedChunk.text !== originalChunk.text) {
			updates.text = updatedChunk.text ?? null
		}

		// Compare location
		if (
			JSON.stringify(updatedChunk.location) !== JSON.stringify(originalChunk.location)
		) {
			updates.location = updatedChunk.location ?? null
		}

		// Compare figureUrls
		if (
			JSON.stringify(updatedChunk.figureUrls) !== JSON.stringify(originalChunk.figureUrls)
		) {
			updates.figureUrls = updatedChunk.figureUrls ?? null
		}

		if (updatedChunk.type === BlockType.FIGURE) {
			if (updatedChunk.figureNumber !== originalChunk.figureNumber) {
				updates.figureNumber = updatedChunk.figureNumber ?? null
			}
			if (updatedChunk.figureRotation !== originalChunk.figureRotation) {
				updates.figureRotation = updatedChunk.figureRotation ?? null
			}
		}

		await updateDocumentChunk({
			documentId: updatedChunk.documentId,
			payload: toSnakeCase(updates),
		})

		onChunkUpdate?.(updatedChunk)
		setIsEditDialogOpen(false)
	}

	const renderContent = () => {
		// The base content for different chunk types
		const content = () => {
			// Check if the text contains equation markers
			if (
				chunk.originalText &&
				(chunk.originalText.includes("\\(") || chunk.originalText.includes("\\)"))
			) {
				return <EquationRenderer equation={chunk.originalText} />
			}

			// For all other cases
			switch (chunk.type) {
				case BlockType.TITLE:
				case BlockType.FIGURE:
				case BlockType.TEXT:
					return (
						<div className="flex gap-2">
							<div className={cn("flex-1 cursor-text")}>
								<CondensedParagraph>
									<DocumentChunkText
										chunk={chunk}
										figureChunks={figureChunks}
										citationText={exactMatchText}
										onFigureReferenceSelect={onFigureReferenceSelect}
									/>
								</CondensedParagraph>
							</div>
						</div>
					)
				default:
					return (
						<div className="flex gap-2">
							<div className={cn("flex-1 cursor-text")}>
								{chunk?.html ? (
									<div className="overflow-x-auto">
										{parseHtml(
											chunk.html,
											{},
											exactMatchText,
											figureChunks,
											onFigureReferenceSelect,
											BlockType[chunk.type as keyof typeof BlockType],
											isDarkMode,
										)}
									</div>
								) : (
									<CondensedParagraph>
										{exactMatchText ? highlightText(chunk.text, exactMatchText) : chunk.text}
									</CondensedParagraph>
								)}
							</div>
						</div>
					)
			}
		}

		// When inBody is true, render the citation location to the left of the main content.
		return inBody ? (
			<div className="flex items-start gap-2">
				{chunk.location && (
					<div className="w-[75px] shrink-0 text-sm text-gray-500 whitespace-nowrap">
						{formatDocumentChunkLocation(chunk.location, citationFormat).join(" ")}
					</div>
				)}
				<div>{content()}</div>
			</div>
		) : (
			// Default layout
			<div className="flex items-start gap-2">
				<div className="flex flex-col items-start">
					{chunk.location && (
						<div className="w-[75px] shrink-0 text-sm text-gray-500 whitespace-nowrap">
							{formatDocumentChunkLocation(chunk.location, citationFormat).join(" ")}
						</div>
					)}
					{content()}
				</div>
			</div>
		)
	}

	const renderReason = () => {
		if (!reason) return null
		return (
			<div className="mt-2 text-sm text-gray-600">
				<CondensedParagraph className="italic">{reason}</CondensedParagraph>
			</div>
		)
	}

	const handleCopy = (includeCitation = true) => {
		// Only allow partial citation for text chunks while using chunk.text
		if (includeCitation && hasSelection && chunk.type === BlockType.TEXT) {
			handleCopySelectedSubset()
		} else {
			let textWithCitation = `${cleanDocumentChunkText(chunk.text)}`
			if (includeCitation) {
				textWithCitation += `\n\n${formatDocumentChunkLocation(chunk.location)}`
			}
			navigator.clipboard.writeText(textWithCitation).catch(console.error)
		}
	}

	/**
	 * Example: copy selection with approximate citation location.
	 */
	const handleCopySelectedSubset = () => {
		const selection = window.getSelection()
		if (!selection?.rangeCount || !chunk?.text) return

		// Get the container element with the original text
		const container = selection.anchorNode?.parentElement
		const originalText = container
			?.closest("[data-original-text]")
			?.getAttribute("data-original-text")

		if (!originalText) return

		// Normalize texts by removing newlines and extra spaces
		const normalizedOriginal = originalText.replace(/\s+/g, " ").trim()
		const selectionText = selection.toString().replace(/\s+/g, " ").trim()
		// Find the selection within the normalized text
		const selectionStart = normalizedOriginal.indexOf(selectionText)

		const selectionEnd = selectionStart + selectionText.length
		if (selectionStart === -1 || selectionStart >= selectionEnd) return

		const subLoc = getSubCitationLocation(chunk, selectionStart, selectionEnd)
		const subLocList = formatDocumentChunkLocation(subLoc, citationFormat)
		const subLocString = subLocList.join("\n")

		let copyString = cleanDocumentChunkText(selectionText)
		if (subLocString) {
			copyString += `\n\n${subLocString}`
		}

		navigator.clipboard.writeText(copyString).catch(console.error)
	}

	const handleContextMenuOpen = () => {
		const selection = window.getSelection()?.toString().trim()
		setHasSelection(!!selection)
	}

	const handleDeleteClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		setIsDeleteDialogOpen(true)
	}

	return (
		<div data-chunk-id={chunk.id} className="document-chunk">
			<ContextMenu>
				<ContextMenuTrigger
					onContextMenu={(_e) => {
						handleContextMenuOpen()
						onChunkSelect?.(chunk.id)
					}}
				>
					<div
						ref={isTextHighlighted || selectedChunkId === chunk.id ? highlightedRef : null}
						data-chunk-id={chunk.id}
						className={`${
							selectedChunkId === chunk.id ? "bg-accent" : ""
						} group flex items-center hover:cursor-pointer hover:bg-accent/50`}
						onClick={!editMode ? () => onChunkSelect?.(chunk.id) : undefined}
					>
						<div className="flex flex-col items-start gap-2">
							{renderContent()}
							{showCitationReasons && renderReason()}
						</div>

						{editMode && (
							<div className="flex items-center gap-2">
								<TooltipProvider>
									<Tooltip>
										<TooltipTrigger asChild>
											<Button
												variant="ghost"
												size="icon"
												onClick={() => setIsEditDialogOpen(true)}
											>
												<Pen className="h-4 w-4" />
											</Button>
										</TooltipTrigger>
										<TooltipContent>Edit section</TooltipContent>
									</Tooltip>
								</TooltipProvider>
								<TooltipProvider>
									<Tooltip>
										<TooltipTrigger asChild>
											<Button variant="ghost" size="icon" onClick={handleDeleteClick}>
												<MinusCircle className="h-4 w-4" />
											</Button>
										</TooltipTrigger>
										<TooltipContent>Remove section</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							</div>
						)}
					</div>

					{showFigures && chunk.figureUrls && (
						<FigureUrlsDisplay
							figureUrls={chunk.figureUrls}
							onFigureClick={handleFigureClick}
						/>
					)}
				</ContextMenuTrigger>
				<ContextMenuContent>
					<ContextMenuItem onClick={() => handleCopy(false)}>Copy</ContextMenuItem>
					<ContextMenuItem onClick={() => handleCopy(true)}>
						Copy with Citation
						<span className="inline-flex items-center rounded-full bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800 ml-2">
							Beta
						</span>
					</ContextMenuItem>
					<ContextMenuItem onClick={() => setIsEditDialogOpen(true)}>Edit</ContextMenuItem>
					{/* {(mode === DocumentChunkMode.CHART || mode === DocumentChunkMode.CITATION) && (
						<>
							<ContextMenuItem onClick={() => setShowDeleteCitationFromAllModal(true)}>
								Remove all instances
							</ContextMenuItem>
							<ContextMenuItem onClick={() => setShowDocumentViewer(true)}>
								View in document
							</ContextMenuItem>
						</>
					)} */}
				</ContextMenuContent>
			</ContextMenu>

			<EditDocumentChunkDialog
				chunk={chunk}
				open={isEditDialogOpen}
				onOpenChange={setIsEditDialogOpen}
				onSave={(updatedChunk) => handleDocumentChunkUpdate(updatedChunk, chunk)}
				figureChunks={figureChunks}
				isSaving={updateDocumentChunkIsLoading}
			/>

			<DeleteCitationFromAllElementsModal
				open={showDeleteCitationFromAllModal}
				handleClose={() => setShowDeleteCitationFromAllModal(false)}
				chunk={chunk}
			/>

			<FiguresModal
				documentId={chunk.documentId}
				open={showFiguresModal}
				onOpenChange={setShowFiguresModal}
				selectedFigure={selectedFigureChunk}
			/>

			<ConfirmRemoveChunkModal
				open={isDeleteDialogOpen}
				onOpenChange={setIsDeleteDialogOpen}
				onConfirm={() => {
					deleteDocumentChunk?.({
						documentId: chunk.documentId,
						documentChunkId: chunk.id,
						isFigure: chunk.type === BlockType.FIGURE,
					})
					setIsDeleteDialogOpen(false)
				}}
			/>
		</div>
	)
}
