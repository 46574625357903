import type {
	CreateGroupRequest,
	DeleteGroupRequest,
	UpdateClaimsRequest,
	UpdateGroupRequest,
} from "@/types/request"
import { apiClient } from "./client"

export const claimApi = {
	async updateClaims(payload: UpdateClaimsRequest) {
		return await apiClient.post("post_update_claims", payload)
	},

	async createGroup(
		subjectId: string,
		limitationIds: string[],
		number: number,
		nickname?: string,
	) {
		const payload: CreateGroupRequest = {
			subject_id: subjectId,
			limitation_ids: limitationIds,
			number: number,
			nickname: nickname,
		}
		return await apiClient.post("post_create_group", payload)
	},

	async updateGroup(
		groupId: string,
		limitationIds?: string[],
		number?: number,
		nickname?: string,
	) {
		const payload: UpdateGroupRequest = {
			group_id: groupId,
			limitation_ids: limitationIds,
			number: number,
			nickname: nickname,
		}
		return await apiClient.post("post_update_group", payload)
	},

	async deleteGroup(groupId: string) {
		const payload: DeleteGroupRequest = {
			group_id: groupId,
		}
		return await apiClient.post("post_delete_group", payload)
	},
}
