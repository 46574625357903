/*
 * Copyright AndAI, Inc. 2025. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import type { UnprocessedDocument } from "@/types"
import { UnprocessedDocumentType } from "@/types"
import { FileIcon, Link2Icon } from "@radix-ui/react-icons"
import type React from "react"

interface UnprocessedReferenceProps {
	document: UnprocessedDocument
}

/**
 * A card component that displays detailed information of a document.
 *
 * @param {UnprocessedReferenceProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered component.
 */
const UnprocessedDocumentCard: React.FC<UnprocessedReferenceProps> = ({ document }) => {
	// For NPL documents, display title and URL if available
	if (document.type === UnprocessedDocumentType.NPL && document.nplDetails) {
		return (
			<div className="flex flex-col gap-2">
				<div className="flex items-center gap-2">
					<FileIcon className="text-primary h-5 w-5" />
					<p className="text-sm font-semibold">
						{document.nplDetails.title || document.file?.name}
					</p>
				</div>
				{document.nplDetails.url && (
					<div className="flex items-center gap-2 text-sm text-muted-foreground">
						<Link2Icon className="h-4 w-4" />
						<a
							href={document.nplDetails.url}
							target="_blank"
							rel="noopener noreferrer"
							className="hover:underline"
						>
							{document.nplDetails.url}
						</a>
					</div>
				)}
			</div>
		)
	}

	// For product documents, display product name and URL
	if (document.type === UnprocessedDocumentType.PRODUCT && document.productDetails) {
		return (
			<div className="flex flex-col gap-2">
				<div className="flex items-center gap-2">
					<FileIcon className="text-primary h-5 w-5" />
					<p className="text-sm font-semibold">{document.productDetails.productName}</p>
				</div>
				{document.productDetails.productUrl && (
					<div className="flex items-center gap-2 text-sm text-muted-foreground">
						<Link2Icon className="h-4 w-4" />
						<a
							href={document.productDetails.productUrl}
							target="_blank"
							rel="noopener noreferrer"
							className="hover:underline"
						>
							{document.productDetails.productUrl}
						</a>
					</div>
				)}
				{document.productDetails.seller && (
					<div className="text-sm text-muted-foreground">
						Seller: {document.productDetails.seller}
					</div>
				)}
			</div>
		)
	}

	// Default display for other document types
	return (
		<div className="flex items-center gap-4">
			<FileIcon className="text-primary h-5 w-5" />
			<p className="text-sm font-semibold">{document.file?.name}</p>
		</div>
	)
}

export default UnprocessedDocumentCard
