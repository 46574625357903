import { SettingsSection } from "@/components"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItemWithLabel } from "@/components/ui/radio-group"
import type React from "react"

interface ClaimSettingsProps {
	claimSettings: { defaultToAssertedClaims: boolean }
	onFieldChange: (field: string, value: any) => void
}

const ClaimSettings: React.FC<ClaimSettingsProps> = ({
	claimSettings,
	onFieldChange,
}) => {
	return (
		<SettingsSection
			label="Claim settings"
			description="Default claims displayed in charts and exports"
		>
			<div className="grid grid-cols-3 gap-4">
				<div>
					<Label className="text-sm">Default claims</Label>
					<RadioGroup
						value={claimSettings.defaultToAssertedClaims ? "asserted" : "all"}
						onValueChange={(value: string) => {
							onFieldChange("defaultToAssertedClaims", value === "asserted")
						}}
						className="flex gap-4"
					>
						<RadioGroupItemWithLabel value="all" id="all" label="All" />
						<RadioGroupItemWithLabel value="asserted" id="asserted" label="Asserted" />
					</RadioGroup>
				</div>
			</div>
		</SettingsSection>
	)
}

export default ClaimSettings
