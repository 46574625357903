import type React from "react"

interface SettingsSectionProps {
	label: string
	description: string
	children: React.ReactNode
}

const SettingsSection = ({ label, description, children }: SettingsSectionProps) => {
	return (
		<div className="mb-8">
			<div className="mb-2">
				<p className="font-medium mb-1">{label}</p>
				<p className="text-sm text-gray-500">{description}</p>
			</div>
			{children}
		</div>
	)
}

export default SettingsSection
