import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { cn } from "@/lib/utils"
import type { ChunkToLimitation } from "@/types"
import { pluralString } from "@/utils"
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@radix-ui/react-collapsible"
import type React from "react"
import { useState } from "react"
import InvalidityTableCitation from "./InvalidityTableCitation"

interface InvalidityTableCellCollapsibleCitationsProps {
	positiveFeedbackData: ChunkToLimitation[]
	negativeFeedbackData: ChunkToLimitation[]
	removedCitations: ChunkToLimitation[]
	verifiedCitations: ChunkToLimitation[]
}

// The standard button used to toggle each citation group
const CollapsibleButton: React.FC<{
	count: number
	label: string
	isOpen: boolean
	onClick: () => void
}> = ({ count, label, isOpen, onClick }) => (
	<CollapsibleTrigger asChild>
		<Button
			variant="ghost"
			size="sm"
			className={cn(
				"justify-between",
				"border border-gray-200 hover:border-gray-300",
				"transition-all duration-10",
				isOpen && "bg-accent",
			)}
			onClick={onClick}
		>
			<h4 className="text-sm font-semibold">
				{label} ({count})
			</h4>
		</Button>
	</CollapsibleTrigger>
)

const InvalidityTableCellCollapsibleCitations: React.FC<
	InvalidityTableCellCollapsibleCitationsProps
> = ({
	positiveFeedbackData,
	negativeFeedbackData,
	removedCitations,
	verifiedCitations,
}) => {
	// Individual state booleans for each group
	const [showPositiveCitations, setShowPositiveCitations] = useState(false)
	const [showNegativeCitations, setShowNegativeCitations] = useState(false)
	const [showRemovedCitations, setShowRemovedCitations] = useState(false)
	const [showVerifiedCitations, setShowVerifiedCitations] = useState(false)

	// Early-return if there is no citation to show.
	const totalCitations =
		positiveFeedbackData.length +
		negativeFeedbackData.length +
		removedCitations.length +
		verifiedCitations.length

	if (totalCitations === 0) return null

	// An array representing each citation group. Adjust the "citationProps" as needed.
	const citationGroups = [
		{
			key: "positive",
			data: positiveFeedbackData,
			show: showPositiveCitations,
			setShow: setShowPositiveCitations,
			label: pluralString(positiveFeedbackData.length, "Positive citation"),
			citationProps: {
				showVerify: false,
				showRemove: false,
				showFeedback: true,
				showColor: false,
			},
		},
		{
			key: "negative",
			data: negativeFeedbackData,
			show: showNegativeCitations,
			setShow: setShowNegativeCitations,
			label: pluralString(negativeFeedbackData.length, "Negative citation"),
			citationProps: {
				showVerify: false,
				showRemove: false,
				showFeedback: true,
				showColor: false,
			},
		},
		{
			key: "removed",
			data: removedCitations,
			show: showRemovedCitations,
			setShow: setShowRemovedCitations,
			label: "Removed citations",
			citationProps: {
				showVerify: false,
				showRemove: true,
				showFeedback: false,
				showColor: false,
			},
		},
		{
			key: "verified",
			data: verifiedCitations,
			show: showVerifiedCitations,
			setShow: setShowVerifiedCitations,
			label: "Verified citations",
			citationProps: {
				showVerify: false,
				showRemove: false,
				showFeedback: false,
				showColor: false,
			},
		},
	]

	// Reset all the group toggles if the overall collapsible is closed.
	const resetAllGroups = () => {
		setShowPositiveCitations(false)
		setShowNegativeCitations(false)
		setShowRemovedCitations(false)
		setShowVerifiedCitations(false)
	}

	return (
		<div className="flex pb-2">
			<Collapsible
				open={
					showPositiveCitations ||
					showNegativeCitations ||
					showRemovedCitations ||
					showVerifiedCitations
				}
				onOpenChange={(open) => {
					if (!open) resetAllGroups()
				}}
				className="space-y-2"
			>
				<div className="flex w-[250px] space-x-2">
					{citationGroups.map((group) =>
						// Only show the button if this particular group has data.
						group.data.length > 0 ? (
							<CollapsibleButton
								key={group.key}
								count={group.data.length}
								label={group.label}
								isOpen={group.show}
								onClick={() => group.setShow(!group.show)}
							/>
						) : null,
					)}
				</div>
				<CollapsibleContent className="space-y-2 pb-2">
					{citationGroups.map((group) =>
						// Render the content only if the group toggle is open.
						group.show && group.data.length > 0 ? (
							<Card key={group.key} className="p-4">
								{group.data.map((item) => (
									<InvalidityTableCitation
										key={`${group.key}-${item.id}`}
										citation={item}
										{...group.citationProps}
									/>
								))}
							</Card>
						) : null,
					)}
				</CollapsibleContent>
			</Collapsible>
		</div>
	)
}

export default InvalidityTableCellCollapsibleCitations
