/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Columns2Icon } from "lucide-react"
import TooltipButton from "../ui/tooltip-button"

interface TableColumnProps {
	table: any
	iconOnly?: boolean
}

/**
 * TableColumn component for toggling column visibility.
 *
 * When iconOnly is set to true, a TooltipButton with just the icon is shown.
 * Otherwise, a button with an icon and the text "Columns" is rendered.
 */
export function TableColumn({ table, iconOnly = false }: TableColumnProps) {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				{iconOnly ? (
					<div className="relative inline-block">
						<TooltipButton
							tooltipText="Columns"
							icon={<Columns2Icon className="h-4 w-4" />}
							variant="ghost"
							onClick={() => {}}
						/>
					</div>
				) : (
					<Button variant="outline" className="px-3" size="sm">
						<Columns2Icon className="h-4 w-4 md:mr-2" />
						<span className="hidden md:inline mr-2">Columns</span>
					</Button>
				)}
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end">
				{table
					.getAllColumns()
					.filter((column) => column.getCanHide() && !column.columnDef.hidden)
					.map((column) => (
						<DropdownMenuCheckboxItem
							key={column.id}
							className="capitalize"
							checked={column.getIsVisible()}
							onCheckedChange={(value) => column.toggleVisibility(!!value)}
						>
							{typeof column.columnDef.header === "string"
								? column.columnDef.header
								: column.id}
						</DropdownMenuCheckboxItem>
					))}
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
