import { Loader } from "@/components"
import RichVariableInput from "@/components/input/RichVariableInput"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import {
	type FormattedInvalidityAnalysis,
	type InvalidityChart,
	InvalidityType,
} from "@/types/invalidity"
import { parseHtml } from "@/utils"
import { Pencil1Icon } from "@radix-ui/react-icons"
import { RefreshCwIcon } from "lucide-react"
import { CheckIcon } from "lucide-react"
import type React from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import useCoverPleading from "../../hooks/useCoverPleading"

interface InvalidityAnalysisBaseProps {
	chart: InvalidityChart
	projectId: string
	subjectId: string
}

interface AnticipationProps extends InvalidityAnalysisBaseProps {
	invalidityType: InvalidityType.ANTICIPATION
	analysis: FormattedInvalidityAnalysis
	isGenerating102Analysis: boolean
	generate102Analysis: (params: { chartIds: string[] }) => Promise<boolean>
}

interface ObviousnessProps extends InvalidityAnalysisBaseProps {
	invalidityType: InvalidityType.OBVIOUSNESS
	analysis: FormattedInvalidityAnalysis
	isGenerating103Analysis: boolean
	generate103Analysis: (params: { chartIds: string[] }) => Promise<boolean>
}

interface SpecificationProps extends InvalidityAnalysisBaseProps {
	invalidityType: InvalidityType.SPECIFICATION
	analysis: FormattedInvalidityAnalysis
	isGenerating112Analysis: boolean
	generate112Analysis: () => Promise<boolean>
}

export type InvalidityAnalysisProps =
	| AnticipationProps
	| ObviousnessProps
	| SpecificationProps

const InvalidityAnalysisDisplay: React.FC<InvalidityAnalysisProps> = (props) => {
	const { chart, analysis, invalidityType, projectId, subjectId } = props
	const navigate = useNavigate()
	const { update102Analysis, update103Analysis, update112Analysis } = useCoverPleading()

	const isGeneratingAnalysis =
		invalidityType === InvalidityType.ANTICIPATION
			? (props as AnticipationProps).isGenerating102Analysis
			: invalidityType === InvalidityType.OBVIOUSNESS
				? (props as ObviousnessProps).isGenerating103Analysis
				: (props as SpecificationProps).isGenerating112Analysis

	const hasAnalysis = !!analysis

	const [isEditing, setIsEditing] = useState(false)
	const [editValue, setEditValue] = useState<string>(analysis?.analysis || "")

	const handleEditToggle = () => {
		if (isEditing) {
			handleSaveAnalysis(chart.id, editValue)
			setIsEditing(false)
		} else {
			setEditValue(analysis?.analysis || "")
			setIsEditing(true)
		}
	}

	const handleGenerateAnalysis = () => {
		if (invalidityType === InvalidityType.ANTICIPATION) {
			;(props as AnticipationProps).generate102Analysis({ chartIds: [chart.id] })
		} else if (invalidityType === InvalidityType.OBVIOUSNESS) {
			;(props as ObviousnessProps).generate103Analysis({ chartIds: [chart.id] })
		} else if (invalidityType === InvalidityType.SPECIFICATION) {
			;(props as SpecificationProps).generate112Analysis()
		}
	}

	const handleSaveAnalysis = (chartId: string, updatedAnalysis: string) => {
		if (invalidityType === InvalidityType.ANTICIPATION) {
			update102Analysis({ chartId, analysis: updatedAnalysis })
		} else if (invalidityType === InvalidityType.OBVIOUSNESS) {
			update103Analysis({ chartId, analysis: updatedAnalysis })
		} else if (invalidityType === InvalidityType.SPECIFICATION) {
			update112Analysis({ chartId, analysis: updatedAnalysis })
		}
	}

	const handleChartTitleClick = () => {
		navigate(`/project/${projectId}/charts/${subjectId}/${chart.id}`)
	}

	return (
		<div key={chart.id} className="px-4 py-3 border rounded-md">
			<div className="flex items-center justify-between gap-2">
				<div className="flex items-center gap-2">
					<Label
						className="mb-0 hover:cursor-pointer hover:text-blue-600"
						onClick={handleChartTitleClick}
					>
						{chart.name}
					</Label>

					<Button
						variant="ghost"
						size="icon"
						onClick={handleEditToggle}
						disabled={isGeneratingAnalysis}
					>
						{isEditing ? (
							<CheckIcon className="h-4 w-4" />
						) : (
							<Pencil1Icon className="h-4 w-4" />
						)}
					</Button>

					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant="ghost"
									size="icon"
									onClick={handleGenerateAnalysis}
									disabled={isGeneratingAnalysis || isEditing}
								>
									<RefreshCwIcon className="h-4 w-4" />
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								{hasAnalysis
									? `Regenerate ${invalidityType} Analysis`
									: `Generate ${invalidityType} Analysis`}
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
			</div>

			<div>
				{isGeneratingAnalysis ? (
					<Loader
						message={hasAnalysis ? "Regenerating analysis..." : "Generating analysis..."}
					/>
				) : isEditing ? (
					<RichVariableInput
						value={editValue}
						onChange={setEditValue}
						enableVariableMenu={false}
					/>
				) : (
					<>
						{!hasAnalysis && (
							<div className="text-sm text-gray-500 text-center items-center">
								<p>No analysis generated yet.</p>
							</div>
						)}

						{hasAnalysis && (
							<div className="text-sm whitespace-pre-wrap">
								{parseHtml(analysis?.analysis)}
							</div>
						)}
					</>
				)}
			</div>
		</div>
	)
}

export default InvalidityAnalysisDisplay
