import * as React from "react"
import { Button } from "@/components/ui/button"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { cn } from "@/lib/utils"

export interface IconButtonProps {
	icon: React.ReactNode
	onClick: () => void
	disabled?: boolean
	srText?: string
	size?: "icon" | "xsIcon" | "smIcon"
	variant?: "outline" | "default" | "secondary" | "ghost" | "link" | "destructive"
	iconClassName?: string
	isActive?: boolean
	activeColor?: string
	className?: string
	tooltipText?: string
	tooltipSide?: "top" | "right" | "bottom" | "left"
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
	(
		{
			icon,
			tooltipText,
			onClick,
			disabled = false,
			srText,
			size = "icon",
			variant = "default",
			iconClassName,
			isActive = false,
			activeColor,
			className,
			tooltipSide = "top",
			...rest
		},
		ref
	) => {
		const iconSizeMapping: Record<"icon" | "xsIcon" | "smIcon", string> = {
			icon: "h-4 w-4",
			xsIcon: "h-4 w-4",
			smIcon: "h-5 w-5",
		}

		const computedIconClass = iconClassName ?? iconSizeMapping[size]

		const button = (
			<Button
				{...rest}
				ref={ref}
				variant={variant}
				size={size}
				disabled={disabled}
				onClick={onClick}
				className={cn(className)}
			>
				<span
					className={cn(
						"inline-flex items-center justify-center",
						computedIconClass,
						isActive && activeColor
					)}
				>
					{icon}
				</span>
				{srText && <span className="sr-only">{srText}</span>}
			</Button>
		)

		if (tooltipText) {
			return (
				<Tooltip>
					<TooltipTrigger asChild>
						{button}
					</TooltipTrigger>
					<TooltipContent side={tooltipSide}>
						<p>{tooltipText}</p>
					</TooltipContent>
				</Tooltip>
			)
		}

		return button
	}
)

IconButton.displayName = "IconButton"

export default IconButton
