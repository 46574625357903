import { api } from "@/api"
import { DEFAULT_QUERY_OPTIONS, QUERY_KEYS } from "@/utils/query"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useQuery } from "@tanstack/react-query"

/**
 * useOrganizationDetails is a hook that fetches organization details for a given organization.
 *
 * @param orgId - The organization id.
 */
const useOrganizationDetails = (orgId: string) => {
	const organizationDetails = useQuery({
		queryKey: QUERY_KEYS.superAdmin.organizationDetails(orgId),
		queryFn: () => api.getOrganizationDetails(orgId),
		enabled: !!orgId,
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		organizationDetails: organizationDetails.data,
		isLoading: organizationDetails.isLoading,
		isFetching: organizationDetails.isFetching,
	}
}

export { useOrganizationDetails }
