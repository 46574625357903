/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import UnprocessedDocumentsList from "@/components/documents/unprocessed/UnprocessedDocumentsList"
import SearchFiltersComponent from "@/components/search/SearchFilters"
import { Alert, AlertDescription } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import { Spinner } from "@/components/ui/spinner"
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle"
import { useProjectContext } from "@/context/ProjectContext"
import { useUnprocessedDocumentsContext } from "@/context/UnprocessedDocumentsContext"
import {
	useProcessDocuments,
	useProjectSubjectDocuments,
} from "@/features/documents/hooks"
import { useProjectPriorArtDocuments } from "@/features/documents/hooks/useProjectPriorArtDocuments"
import useNPLSearch from "@/features/search/hooks/useNPLSearch"
import usePriorArtSearch, {
	useSearchFilters,
} from "@/features/search/hooks/usePriorArtSearch"
import useProductSearch from "@/features/search/hooks/useProductSearch"
import { useDocumentNaming, useProjectMetadata } from "@/hooks"
import { cn } from "@/lib/utils"
import {
	DocumentRating,
	DocumentRole,
	ProcessType,
	type UnprocessedDocument,
} from "@/types"
import { SearchMode } from "@/types/search-references"
import { ThumbsDownIcon, ThumbsUpIcon } from "lucide-react"
import type React from "react"
import { useEffect, useState } from "react"

/**
 * @description Project documents page
 */
const DocumentSearchComponent: React.FC = () => {
	/**
	 * Common references logic
	 */
	const {
		handleSearch: handlePriorArtSearch,
		handleRecommend,
		isLoading: isPriorArtLoading,
		isRecommendationLoading,
		isError: isPriorArtError,
		resetSearch: resetPriorArtSearch,
	} = usePriorArtSearch()

	const {
		handleSearch: handleNPLSearch,
		isLoading: isNPLLoading,
		isError: isNPLError,
		resetSearch: resetNPLSearch,
	} = useNPLSearch()

	const {
		handleSearch: handleProductSearch,
		isLoading: isProductLoading,
		isError: isProductError,
		resetSearch: resetProductSearch,
	} = useProductSearch()

	// Compute combined loading and error states
	const isLoading = isPriorArtLoading || isNPLLoading || isProductLoading
	const isError = isPriorArtError || isNPLError || isProductError

	const { projectId } = useProjectContext()
	const { processDocuments } = useProcessDocuments()
	const { subjectDocuments } = useProjectSubjectDocuments()
	const {
		unprocessedDocuments,
		removeUnprocessedDocument,
		updateSelectedKindCode,
		updateRating,
		resetAll,
	} = useUnprocessedDocumentsContext()
	const { filters, updateFilter, resetFilters } = useSearchFilters()
	const { getDocumentName } = useDocumentNaming()
	const { priorArtDocuments } = useProjectPriorArtDocuments()
	const { willBudgetBeExceeded, amountToBeSpentOverBudget } = useProjectMetadata()
	// Basic UI state
	const [hasSubmitted, setHasSubmitted] = useState(false)
	const [selectedReferences, setSelectedReferences] = useState<UnprocessedDocument[]>([])
	const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([])
	const [selectedSubjectIds, setSelectedSubjectIds] = useState<string[]>([])
	const [selectedPriorArtIds, setSelectedPriorArtIds] = useState<string[]>([])
	const [selectedInfringementIds, setSelectedInfringementIds] = useState<string[]>([])

	const [selected103Document, setSelected103Document] = useState<string | null>(null)
	const [documentRatings, setDocumentRatings] = useState({
		veryPositive: [],
		positive: [],
		negative: [],
	})
	const [previouslySearchedDocumentRatings, setPreviouslySearchedDocumentRatings] =
		useState({ veryPositive: [], positive: [], negative: [] })
	const [recommendationsAvailable, setRecommendationsAvailable] = useState(false)

	// Effect to keep recommendations up to date
	useEffect(() => {
		const newDocumentRatings = { veryPositive: [], positive: [], negative: [] }
		for (const document of unprocessedDocuments) {
			switch (document.rating) {
				case DocumentRating.VERY_POSITIVE:
					newDocumentRatings.veryPositive.push(document.patentDetails.number)
					break
				case DocumentRating.POSITIVE:
					newDocumentRatings.positive.push(document.patentDetails.number)
					break
				case DocumentRating.NEGATIVE:
					newDocumentRatings.negative.push(document.patentDetails.number)
					break
				default:
					break
			}
		}

		setDocumentRatings(newDocumentRatings)
		if (
			JSON.stringify(newDocumentRatings) !==
			JSON.stringify(previouslySearchedDocumentRatings)
		) {
			setRecommendationsAvailable(true)
		} else {
			setRecommendationsAvailable(false)
		}
	}, [unprocessedDocuments, previouslySearchedDocumentRatings])

	// Budget confirmation state for adding references
	const [isProcessingReferences, setIsProcessingReferences] = useState<boolean>(false)
	const [showBudgetConfirmation, setShowBudgetConfirmation] = useState<boolean>(false)
	const [budgetExceededAmount, setBudgetExceededAmount] = useState<number | null>(null)
	const [pendingReferences, setPendingReferences] = useState<UnprocessedDocument[]>([])
	const [searchMode, setSearchMode] = useState<SearchMode>(SearchMode.MODE_PATENT)
	const [resultsRole, setResultsRole] = useState<DocumentRole>(DocumentRole.PRIOR_ART)

	// Handle selection changes
	const handleSelectionChange = (documentId: string, isSelected: boolean) => {
		setSelectedDocumentIds((prev) =>
			isSelected ? [...prev, documentId] : prev.filter((id) => id !== documentId),
		)
	}

	// Reset modal state
	const resetModal = () => {
		setHasSubmitted(false)
		setSelectedReferences([])
		resetFilters()
		resetPriorArtSearch()
		resetNPLSearch()
		resetProductSearch()
		setSelectedDocumentIds([])
		resetAll()
		// Also reset any pending budget confirmation
		resetBudgetCheck()
		setSelected103Document(null)
	}

	// Resets the budget check confirmation state.
	const resetBudgetCheck = () => {
		setShowBudgetConfirmation(false)
		setBudgetExceededAmount(null)
		setPendingReferences([])
	}

	/**
	 * Add references to project with budget check.
	 *
	 * When called, this handler extracts the patent/reference numbers from the selected documents,
	 * calls getPatentDetails (which internally checks the remaining budget), and if any detail indicates
	 * the budget will be exceeded, pops up a confirmation step.
	 */
	const handleAddReferences = async (addAll = false) => {
		setIsProcessingReferences(true)
		const documentsToProcess: UnprocessedDocument[] = addAll
			? unprocessedDocuments
			: unprocessedDocuments.filter((doc) => selectedDocumentIds.includes(doc.id))

		if (documentsToProcess.length === 0) {
			setIsProcessingReferences(false)
			return
		}

		const budgetExceeded = willBudgetBeExceeded(documentsToProcess.length)
		if (budgetExceeded) {
			setBudgetExceededAmount(amountToBeSpentOverBudget(documentsToProcess.length))
			setShowBudgetConfirmation(true)
			setIsProcessingReferences(false)
			return
		}

		try {
			await processDocuments({
				documentRole: resultsRole,
				processType:
					resultsRole === DocumentRole.INFRINGEMENT
						? ProcessType.ADD_INFRINGEMENT
						: ProcessType.ADD_PRIOR_ART,
				documents: documentsToProcess,
				useClaimsCheckboxChecked: false,
				subjectIds: filters.subjectId ? [filters.subjectId] : [],
			})
		} catch (error) {
			console.error("Error during budget check:", error)
		} finally {
			setIsProcessingReferences(false)
		}
	}

	/**
	 * When the user confirms to proceed despite budget constraints,
	 * this function will process the pending references.
	 */
	const confirmAddReferences = async () => {
		setIsProcessingReferences(true)
		try {
			// Handle products and NPL differently from patents
			if (
				resultsRole === DocumentRole.INFRINGEMENT ||
				searchMode === SearchMode.MODE_PRODUCT ||
				searchMode === SearchMode.MODE_NPL
			) {
				await processDocuments({
					documentRole: DocumentRole.INFRINGEMENT,
					processType: ProcessType.ADD_INFRINGEMENT,
					documents: pendingReferences,
					useClaimsCheckboxChecked: false,
					subjectIds: filters.subjectId ? [filters.subjectId] : [],
				})
			} else {
				await processDocuments({
					documentRole: DocumentRole.PRIOR_ART,
					processType: ProcessType.ADD_PRIOR_ART,
					documents: pendingReferences,
					useClaimsCheckboxChecked: true,
					subjectIds: filters.subjectId ? [filters.subjectId] : [],
					priorArtIds: [],
					infringementIds: [],
				})
			}
		} catch (error) {
			console.error("Error processing references:", error)
		} finally {
			setIsProcessingReferences(false)
			resetBudgetCheck()
		}
	}

	// Update search click handler
	const handleSearchClick = async () => {
		setHasSubmitted(true)
		// If in infringement mode, always use product search
		if (resultsRole === DocumentRole.INFRINGEMENT) {
			await handleProductSearch(projectId, filters.subjectId, filters)
			return
		}
		// Otherwise use the selected search mode for prior art
		switch (searchMode) {
			case SearchMode.MODE_PATENT:
				if (selected103Document) {
					await handlePriorArtSearch(projectId, filters.subjectId, filters, {
						documentId: selected103Document,
						isPatch103: true,
					})
				} else {
					await handlePriorArtSearch(projectId, filters.subjectId, filters)
				}
				break
			case SearchMode.MODE_NPL:
				await handleNPLSearch(projectId, filters.subjectId, filters)
				break
			case SearchMode.MODE_PRODUCT:
				await handleProductSearch(projectId, filters.subjectId, filters)
				break
			default:
				await handlePriorArtSearch(projectId, filters.subjectId, filters)
		}
	}

	const handleRecommendClick = async () => {
		await handleRecommend(
			projectId,
			filters.subjectId,
			documentRatings.veryPositive,
			documentRatings.positive,
			documentRatings.negative,
			unprocessedDocuments
				.filter((doc) => doc.patentDetails)
				.map((doc) => doc.patentDetails.number),
			filters,
		)
		setPreviouslySearchedDocumentRatings(documentRatings)
	}

	const roles = [
		{
			role: DocumentRole.PRIOR_ART,
			label: "Prior Art",
		},
		{
			role: DocumentRole.INFRINGEMENT,
			label: "Infringement",
		},
	]

	return (
		<div className="p-4 border rounded-md h-full overflow-y-hidden">
			<div>
				<div className="flex justify-between items-center mb-4">
					<div className="flex flex-row gap-2">
						<div className="flex flex-col gap-1">
							<Label className="text-sm">Subject Document</Label>
							<Select
								value={filters.subjectId}
								onValueChange={(value) => updateFilter("subjectId", value)}
								disabled={hasSubmitted}
							>
								<SelectTrigger className="h-9 min-w-[200px]">
									<SelectValue placeholder="Subject document" />
								</SelectTrigger>
								<SelectContent>
									{subjectDocuments.map((doc) => (
										<SelectItem key={doc.id} value={doc.id}>
											{getDocumentName(doc.id)}
										</SelectItem>
									))}
								</SelectContent>
							</Select>
						</div>
						{process.env.REACT_APP_USE_PRODUCT_SEARCH === "true" && (
							<div className="flex flex-col gap-1">
								<Label className="text-sm">Search Type</Label>
								<Select
									value={resultsRole}
									onValueChange={(value) => setResultsRole(value as DocumentRole)}
									disabled={hasSubmitted}
								>
									<SelectTrigger className="h-9 min-w-[200px]">
										<SelectValue placeholder="Document role" />
									</SelectTrigger>
									<SelectContent>
										{roles.map((role) => (
											<SelectItem key={role.role} value={role.role}>
												{role.label}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							</div>
						)}
						{resultsRole === DocumentRole.PRIOR_ART && (
							<div className="flex flex-col gap-1">
								<Label className="text-sm">Results</Label>
								<ToggleGroup
									type="single"
									value={searchMode}
									onValueChange={(value) => {
										if (value) {
											setSearchMode(value as SearchMode)
											setSelectedDocumentIds([])
										}
									}}
									className="h-9 mr-8"
									disabled={hasSubmitted}
								>
									<ToggleGroupItem
										className={cn(
											"flex-1",
											searchMode === SearchMode.MODE_PATENT
												? "bg-tertiary text-primary-foreground"
												: "text-muted-foreground",
										)}
										value={SearchMode.MODE_PATENT}
										size="sm"
									>
										Patent
									</ToggleGroupItem>
									{process.env.REACT_APP_USE_NPL_SEARCH === "true" && (
										<ToggleGroupItem
											className={cn(
												"flex-1",
												searchMode === SearchMode.MODE_NPL
													? "bg-tertiary text-primary-foreground"
													: "text-muted-foreground",
											)}
											value={SearchMode.MODE_NPL}
											size="sm"
										>
											NPL
										</ToggleGroupItem>
									)}
									{process.env.REACT_APP_USE_PRODUCT_SEARCH === "true" && (
										<ToggleGroupItem
											className={cn(
												"flex-1",
												searchMode === SearchMode.MODE_PRODUCT
													? "bg-tertiary text-primary-foreground"
													: "text-muted-foreground",
											)}
											value={SearchMode.MODE_PRODUCT}
											size="sm"
										>
											Product
										</ToggleGroupItem>
									)}
								</ToggleGroup>
							</div>
						)}
					</div>
				</div>
			</div>

			{!hasSubmitted && (
				<div className="">
					<SearchFiltersComponent
						disabled={isLoading}
						filters={filters}
						updateFilter={updateFilter}
						hideSelectedClaimsOption={!!selected103Document}
						projectDocuments={priorArtDocuments}
						selectedDocumentId={selected103Document ? selected103Document || "" : ""}
						onDocumentSelect={(docId) => setSelected103Document(docId || null)}
						searchMode={
							resultsRole === DocumentRole.INFRINGEMENT ? SearchMode.MODE_PRODUCT : searchMode
						}
					/>
				</div>
			)}

			{/* ***********************************
					Results, Loader, or Budget Confirmation
					********************************** */}
			{hasSubmitted &&
				(isLoading || isProcessingReferences ? (
					<div className="flex items-center justify-center">
						<Loader message={isLoading ? "Searching..." : "Processing..."} />
					</div>
				) : showBudgetConfirmation ? (
					<div className="space-y-2">
						<Alert variant="destructive">
							<AlertDescription>
								This action will exceed this project's budget by $
								{budgetExceededAmount?.toLocaleString()}. Are you sure you want to continue?
							</AlertDescription>
						</Alert>
						{pendingReferences.length > 0 && (
							<div className="mt-4 border rounded p-4">
								<h3 className="text-sm font-medium mb-2">References to be added:</h3>
								<div className="space-y-3">
									{pendingReferences.map((doc) => (
										<div key={doc.id} className="text-sm">
											<div className="font-medium">{doc.patentDetails?.number || "Reference"}</div>
										</div>
									))}
								</div>
							</div>
						)}
						<div className="flex justify-end gap-2">
							<Button variant="outline" onClick={resetBudgetCheck} className="h-9">
								Cancel
							</Button>
							<Button variant="destructive" onClick={confirmAddReferences} className="h-9">
								Continue Anyway
							</Button>
						</div>
					</div>
				) : (
					<div>
						{unprocessedDocuments?.length > 0 ? (
							<div className="flex flex-col">
								<UnprocessedDocumentsList
									documents={unprocessedDocuments}
									onRemove={removeUnprocessedDocument}
									updateSelectedKindCode={updateSelectedKindCode}
									showActions={true}
									selectedDocuments={selectedDocumentIds}
									onSelectionChange={handleSelectionChange}
									hideRemoveButton={true}
									showRating={true}
									updateRating={updateRating}
									inSearchPage={true}
								>
									<div className="flex flex-wrap justify-between gap-2">
										<Button variant="outline" onClick={resetModal} className="h-9">
											Clear Results
										</Button>
										<Button
											variant="outline"
											className="h-9 flex flex-row"
											onClick={handleRecommendClick}
											disabled={isRecommendationLoading || !recommendationsAvailable}
										>
											{isRecommendationLoading ? (
												<>
													<Spinner className="h-5 text-white" />
													<span>Searching With Feedback...</span>
												</>
											) : (
												<>
													<span>Search With Feedback</span>
													{(documentRatings.veryPositive.length > 0 ||
														documentRatings.positive.length > 0 ||
														documentRatings.negative.length > 0) && (
														<div className="flex flex-row">
															<span className="ml-1">(</span>
															<div className="flex flex-row gap-1 items-center">
																{documentRatings.veryPositive.length > 0 && (
																	<>
																		<span className="grow">{documentRatings.veryPositive.length}</span>
																		<div className="flex flex-row">
																			<ThumbsUpIcon className={"h-3 w-3"} />
																			<ThumbsUpIcon className={"h-3 w-3"} />
																		</div>
																	</>
																)}
																{documentRatings.positive.length > 0 && (
																	<>
																		<span className="mr-1">{documentRatings.positive.length}</span>
																		<ThumbsUpIcon className={"h-3 w-3"} />
																	</>
																)}
																{documentRatings.negative.length > 0 && (
																	<>
																		<span className="mr-1">{documentRatings.negative.length}</span>
																		<ThumbsDownIcon className={"h-3 w-3"} />
																	</>
																)}
															</div>
															<span>)</span>
														</div>
													)}
												</>
											)}
										</Button>
										<div className="flex gap-2 items-center">
											<Button
												className="h-9"
												variant={selectedReferences.length === 0 ? "default" : "outline"}
												onClick={() => handleAddReferences(true)}
											>
												Add All ({unprocessedDocuments.length})
											</Button>
											<Button
												variant="default"
												className="h-9"
												onClick={() => handleAddReferences(false)}
												disabled={selectedDocumentIds.length === 0}
											>
												Add Selected ({selectedDocumentIds.length})
											</Button>
										</div>
									</div>
								</UnprocessedDocumentsList>
							</div>
						) : (
							<div className="flex flex-col items-center mt-8">
								<p className="mb-4">No references found. Try again.</p>
								<Button variant="outline" onClick={resetModal}>
									Search Again
								</Button>
							</div>
						)}
					</div>
				))}

			{isError && (
				<p className="text-red-500 mt-2">An error occurred. Please try again.</p>
			)}

			{!hasSubmitted && (
				<div className="flex justify-end mt-4 gap-2">
					<Button
						onClick={handleSearchClick}
						variant="default"
						disabled={filters.subjectId === ""}
					>
						Search
					</Button>
				</div>
			)}
		</div>
	)
}

export default DocumentSearchComponent
