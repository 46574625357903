import {} from "@/components/ui/dialog"
import { FloatingLabelInput } from "@/components/ui/floating-input"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { useUnprocessedDocumentsContext } from "@/context/UnprocessedDocumentsContext"
import { DocumentRole } from "@/types"
import { type ProductDetails, UnprocessedDocumentType } from "@/types/documents"
import { useEffect, useState } from "react"
import { BaseDocumentModal } from "./BaseDocumentModal"

interface AddProductModalProps {
	open: boolean
	handleClose: () => void
	documentRole?: DocumentRole
	isProcessing?: boolean
}

export function AddProductModal({
	open,
	handleClose,
	documentRole = DocumentRole.INFRINGEMENT,
	isProcessing,
}: AddProductModalProps) {
	const [productData, setProductData] = useState<ProductDetails>({
		productName: "",
		productUrl: "",
		productId: "",
		seller: "",
		specificationUrl: "",
		specifications: {},
	})
	const [specificationPdf, setSpecificationPdf] = useState<File | null>(null)
	const {
		unprocessedDocuments,
		addUnprocessedDocuments,
		resetAll: resetUnprocessedDocuments,
	} = useUnprocessedDocumentsContext()
	const [isAddDisabled, setIsAddDisabled] = useState(true)

	useEffect(() => {
		setIsAddDisabled(true)
		resetUnprocessedDocuments()
		if (
			productData.productName !== "" &&
			productData.productUrl !== "" &&
			productData.seller !== "" &&
			(specificationPdf !== null || productData.specificationUrl !== "")
		) {
			addUnprocessedDocuments(DocumentRole.INFRINGEMENT, UnprocessedDocumentType.PRODUCT, {
				files: specificationPdf !== null ? [specificationPdf] : [],
				productDetails: [productData],
			})
			setIsAddDisabled(false)
		}
	}, [productData, specificationPdf])

	return (
		<BaseDocumentModal
			open={open && !isProcessing}
			onClose={handleClose}
			documentRole={documentRole}
			title="Add Product"
			documentType={UnprocessedDocumentType.PRODUCT}
			isAddDisabled={unprocessedDocuments.length === 0 || isAddDisabled}
		>
			<form>
				<div className="flex flex-col gap-2 ">
					<FloatingLabelInput
						id="productName"
						label="Product Name *"
						value={productData.productName}
						onChange={(e) =>
							setProductData((prev) => ({ ...prev, productName: e.target.value }))
						}
						type="text"
						required
					/>
					<FloatingLabelInput
						id="productUrl"
						label="Product URL *"
						value={productData.productUrl}
						onChange={(e) =>
							setProductData((prev) => ({ ...prev, productUrl: e.target.value }))
						}
						type="url"
						required
					/>
					<FloatingLabelInput
						id="googleProductId"
						label="Google Product ID"
						value={productData.productId}
						onChange={(e) =>
							setProductData((prev) => ({ ...prev, productId: e.target.value }))
						}
						type="text"
					/>
					<FloatingLabelInput
						id="seller"
						label="Seller *"
						value={productData.seller}
						onChange={(e) => setProductData((prev) => ({ ...prev, seller: e.target.value }))}
					/>
					<div className="flex flex-col gap-1">
						<Label htmlFor="specFile" className="text-left text-sm">
							Specifications PDF
						</Label>
						<Input
							id="specFile"
							type="file"
							onChange={(e) => setSpecificationPdf(e.target.files[0])}
							accept=".pdf"
							disabled={productData.specificationUrl !== ""}
						/>
					</div>
					{/* <div className="flex flex-col gap-1">
						<Label htmlFor="specUrl" className="text-left text-sm">
							Specifications URL
						</Label>
						<Input
							id="specUrl"
							type="url"
							value={productData.specificationUrl}
							onChange={(e) =>
								setProductData((prev) => ({
									...prev,
									specificationUrl: e.target.value,
								}))
							}
							disabled={specificationPdf !== null}
							placeholder="URL to specifications PDF"
						/>
					</div> */}
				</div>

				{/* <DialogFooter className="mt-2">
						<Button type="button" variant="outline" onClick={handleCloseClick}>
							Cancel
						</Button>
						<Button type="submit">Add Product</Button>
					</DialogFooter> */}
			</form>
			{/* </DialogContent> */}
		</BaseDocumentModal>
	)
}
