"use client"

import BaseTable, { type Column } from "@/components/table/BaseTable"
import type { AdminUserUsageByDay } from "@/types"
import { Loader } from "lucide-react"
import { useUserSessions } from "../hooks/useUserSessions"
import type { FilterOptions } from "./UsageFilterSelects"

export function UserSessionsTable({
	isSuperAdmin,
	organizationId,
	filters,
}: {
	organizationId: string
	filters: FilterOptions
	isSuperAdmin: boolean
}) {
	const { sessionData, isLoading, isFetching } = useUserSessions(
		isSuperAdmin,
		organizationId,
		filters,
	)

	const columns: Column<AdminUserUsageByDay>[] = [
		{
			header: "Date",
			accessor: "date",
			render: (value: string) => {
				const date = new Date(value)
				return date.toLocaleDateString("en-US", {
					month: "short",
					day: "numeric",
					year: "numeric",
				})
			},
		},
		// {
		// 	header: "Name",
		// 	accessor: "name",
		// },
		{
			header: "Email",
			accessor: "email",
		},
		{
			header: "Minutes",
			accessor: "minutes",
			render: (value: number) => `${value} min`,
		},
	]

	if (isSuperAdmin) {
		columns.push({
			header: "Organization",
			accessor: "organizationName",
		})
	}

	if (isLoading || isFetching) {
		return <Loader />
	}

	return (
		<BaseTable
			columns={columns}
			data={sessionData}
			emptyText="No session data available"
		/>
	)
}

export default UserSessionsTable
