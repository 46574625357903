import { SidebarProvider } from "@/components/ui/sidebar"
import type React from "react"
import { Outlet } from "react-router-dom"

export const NonProjectRoute: React.FC = () => {
	return (
		<SidebarProvider defaultOpen={false}>
			<Outlet />
		</SidebarProvider>
	)
}
