import { accessApi } from "./accessApi"
import { adminApi } from "./adminApi"
import { chartsApi } from "./chartsApi"
import { chatApi } from "./chatApi"
import { claimApi } from "./claimApi"
import { coverApi } from "./coverApi"
import { documentApi } from "./documentApi"
import { llmApi } from "./llmApi"
import { processApi } from "./processApi"
import { projectApi } from "./projectApi"
import { searchApi } from "./searchApi"

// Combine all API methods into a single object
export const api = {
	...adminApi,
	...chartsApi,
	...documentApi,
	...llmApi,
	...processApi,
	...projectApi,
	...searchApi,
	...accessApi,
	...coverApi,
	...claimApi,
	...chatApi,
}

export {
	adminApi,
	chartsApi,
	documentApi,
	llmApi,
	processApi,
	projectApi,
	searchApi,
	accessApi,
	coverApi,
	claimApi,
	chatApi,
}
