/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import {} from "@/components"
import { ScrollArea } from "@/components/ui/scroll-area"
import { H4 } from "@/components/ui/typography"
import { useProjectContext } from "@/context/ProjectContext"
import DocumentSummary from "@/features/documents/components/DocumentSummary"
import { useProjectPriorArtDocuments } from "@/features/documents/hooks/useProjectPriorArtDocuments"
import { useFullDocument } from "@/hooks"
import { type InvalidityChart, InvalidityType } from "@/types/invalidity"
import { useMemo, useState } from "react"
import type React from "react"
import NoReferencesMessage from "../../../documents/components/NoReferencesMessage"
import useCoverPleading from "../../hooks/useCoverPleading"
import CoverPleadingExportSettingsModal from "./CoverPleadingExportSettingsModal"
import CoverTabs, { CoverTabKey } from "./CoverTabs"
import InvalidityAnalysisDisplay from "./InvalidityAnalysisDisplay"
import PitchSummaryExportSettingsModal from "./PitchSummaryExportSettingsModal"
import type { PitchSummaryExportSettings } from "./PitchSummaryExportSettingsModal"

// Define interface for tab configuration
interface TabConfig {
	generateFunction: () => Promise<boolean>
	isGenerating: boolean
	analysisData?: any[]
	analysisType?: InvalidityType
	emptyMessage: string
	generateButtonText: string
}

const ChartsContentionAnalysis: React.FC = () => {
	const { projectId, subjectId } = useProjectContext()
	const { document: projectSubject } = useFullDocument(projectId)
	const {
		coverSummaries,
		generateSummaries,
		getCoverExport,
		isGeneratingSummaries,
		generate102Analysis,
		isGenerating102Analysis,
		generate103Analysis,
		isGenerating103Analysis,
		generate112Analysis,
		isGenerating112Analysis,
		anticipationAnalysis,
		obviousnessAnalysis,
		specificationAnalysis,
		getSingleReferenceCharts,
		getCombinationCharts,
		getSelfCharts,
		getPitchSummaryExport,
	} = useCoverPleading()

	const { priorArtDocuments, isLoading: isLoadingPriorArt } =
		useProjectPriorArtDocuments()
	const { setDocumentViewerDocumentId, setDocumentViewerCitationText } =
		useProjectContext()
	// Filter out the subject patent from prior art documents
	const filteredPriorArtDocuments = useMemo(() => {
		if (!priorArtDocuments || !projectSubject) return priorArtDocuments || []

		// Filter out documents that have the same patent number as the subject
		return priorArtDocuments.filter(
			(doc) => doc?.patent?.number !== projectSubject?.patent?.number,
		)
	}, [priorArtDocuments, projectSubject])

	// Track current tab
	const [currentTab, setCurrentTab] = useState<CoverTabKey>(CoverTabKey.ANTICIPATION)
	// Track which charts are currently regenerating
	const [regeneratingChartIds, setRegeneratingChartIds] = useState<string[]>([])

	const isLoading =
		isLoadingPriorArt ||
		isGeneratingSummaries ||
		isGenerating102Analysis ||
		isGenerating103Analysis ||
		isGenerating112Analysis
	const allDocumentIds = filteredPriorArtDocuments?.map(
		(priorArtDocuments) => priorArtDocuments.id,
	)

	// Export settings modal state
	const [showExportModal, setShowExportModal] = useState(false)
	const [isExporting, setIsExporting] = useState(false)
	const [showExportPitchSummaryModal, setShowExportPitchSummaryModal] = useState(false)
	const [isExportingPitchSummary, setIsExportingPitchSummary] = useState(false)

	// Get eligible charts for 102 and 103 analysis
	const singleReferenceCharts = getSingleReferenceCharts()
	const combinationCharts = getCombinationCharts()
	const selfCharts = getSelfCharts()

	const handleExportCoverPleading = async (
		selectedDocumentIds: string[],
		selectedAnalysisTypes: InvalidityType[],
	) => {
		setIsExporting(true)
		try {
			await getCoverExport(subjectId, selectedDocumentIds, selectedAnalysisTypes)
			setShowExportModal(false)
		} finally {
			setIsExporting(false)
		}
	}

	const handleGenerateSummaries = async () => {
		await generateSummaries({
			documentIds: allDocumentIds.filter((id) => id !== undefined),
		})
		return true
	}

	const handleGenerate102Analysis = async (): Promise<boolean> => {
		const success = await generate102Analysis({
			chartIds: singleReferenceCharts.map((chart) => chart.id),
		})
		return success
	}

	const handleGenerate103Analysis = async (): Promise<boolean> => {
		const success = await generate103Analysis({
			chartIds: combinationCharts.map((chart) => chart.id),
		})
		return success
	}

	const handleGenerate112Analysis = async () => {
		await generate112Analysis()
		return true
	}

	const downloadDisabled = isLoading

	// Create tab configuration object
	const tabConfigs: Record<CoverTabKey, TabConfig> = {
		[CoverTabKey.SUMMARIES]: {
			generateFunction: handleGenerateSummaries,
			isGenerating: isGeneratingSummaries,
			emptyMessage: "No summaries have been generated yet.",
			generateButtonText: "Summaries",
		},
		[CoverTabKey.ANTICIPATION]: {
			generateFunction: handleGenerate102Analysis,
			isGenerating: isGenerating102Analysis,
			analysisData: anticipationAnalysis,
			analysisType: InvalidityType.ANTICIPATION,
			emptyMessage: "No anticipation analysis has been generated yet.",
			generateButtonText: "§ 102 Analysis",
		},
		[CoverTabKey.OBVIOUSNESS]: {
			generateFunction: handleGenerate103Analysis,
			isGenerating: isGenerating103Analysis,
			analysisData: obviousnessAnalysis,
			analysisType: InvalidityType.OBVIOUSNESS,
			emptyMessage: "No obviousness analysis has been generated yet.",
			generateButtonText: "§ 103 Analysis",
		},
		[CoverTabKey.SPECIFICATION]: {
			generateFunction: handleGenerate112Analysis,
			isGenerating: isGenerating112Analysis,
			analysisData: specificationAnalysis,
			analysisType: InvalidityType.SPECIFICATION,
			emptyMessage: "No specification analysis has been generated yet.",
			generateButtonText: "§ 112 Analysis",
		},
	}

	const noReferences = filteredPriorArtDocuments?.length === 0

	// Function to find a matching analysis for a chart
	const findAnalysisForChart = (
		chart: InvalidityChart,
		analysisType: InvalidityType,
	) => {
		if (!chart) return null

		switch (analysisType) {
			case InvalidityType.ANTICIPATION:
				return anticipationAnalysis?.find((analysis) => analysis.chartId === chart.id)
			case InvalidityType.OBVIOUSNESS:
				return obviousnessAnalysis?.find((analysis) => analysis.chartId === chart.id)
			case InvalidityType.SPECIFICATION:
				return specificationAnalysis?.find((analysis) => analysis.chartId === chart.id)
			default:
				return null
		}
	}

	// Reusable component for tab content
	const TabContent: React.FC<{
		tabKey: CoverTabKey
		config: TabConfig
	}> = ({ tabKey, config }) => {
		return (
			<div>
				{noReferences && tabKey !== CoverTabKey.SPECIFICATION ? (
					<NoReferencesMessage />
				) : (
					// Display tab content
					<div className="flex flex-col gap-2 ">
						{tabKey === CoverTabKey.ANTICIPATION &&
							(noReferences || singleReferenceCharts.length === 0 ? (
								<div className="flex justify-center items-center flex-col mt-12">
									<H4>
										{noReferences
											? "No references available for § 102 analysis."
											: "No single-reference charts available for § 102 analysis."}
									</H4>
								</div>
							) : (
								<ScrollArea className="h-[calc(100vh-105px)]">
									<div className="grid grid-cols-1 gap-2 px-2">
										{singleReferenceCharts.map((chart) => {
											// Find any existing analysis for this chart
											const analysis = findAnalysisForChart(chart, InvalidityType.ANTICIPATION)
											// Check if this specific chart is being regenerated
											const isChartRegenerating = regeneratingChartIds.includes(chart.id)

											return (
												<div key={chart.id} className="relative">
													<InvalidityAnalysisDisplay
														projectId={projectId}
														subjectId={subjectId}
														chart={chart}
														analysis={analysis}
														invalidityType={InvalidityType.ANTICIPATION}
														isGenerating102Analysis={isChartRegenerating}
														generate102Analysis={async (params) => {
															// Add this chart to regenerating list
															setRegeneratingChartIds((prev) => [...prev, ...params.chartIds])
															try {
																return await generate102Analysis(params)
															} finally {
																// Remove this chart from regenerating list when done
																setRegeneratingChartIds((prev) =>
																	prev.filter((id) => !params.chartIds.includes(id)),
																)
															}
														}}
													/>
												</div>
											)
										})}
									</div>
								</ScrollArea>
							))}

						{tabKey === CoverTabKey.OBVIOUSNESS &&
							(noReferences || combinationCharts.length === 0 ? (
								<div className="flex justify-center items-center flex-col mt-12">
									<H4>
										{noReferences
											? "No references available for 103 analysis."
											: "No combination charts available for 103 analysis."}
									</H4>
								</div>
							) : (
								<ScrollArea className="h-[calc(100vh-105px)]">
									<div className="grid grid-cols-1 gap-2 px-2">
										{combinationCharts.map((chart) => {
											// Find any existing analysis for this chart
											const analysis = findAnalysisForChart(chart, InvalidityType.OBVIOUSNESS)
											// Check if this specific chart is being regenerated
											const isChartRegenerating = regeneratingChartIds.includes(chart.id)

											return (
												<div key={chart.id} className="relative">
													<InvalidityAnalysisDisplay
														projectId={projectId}
														subjectId={subjectId}
														chart={chart}
														analysis={analysis}
														invalidityType={InvalidityType.OBVIOUSNESS}
														isGenerating103Analysis={isChartRegenerating}
														generate103Analysis={async (params) => {
															// Add this chart to regenerating list
															setRegeneratingChartIds((prev) => [...prev, ...params.chartIds])
															try {
																return await generate103Analysis(params)
															} finally {
																// Remove this chart from regenerating list when done
																setRegeneratingChartIds((prev) =>
																	prev.filter((id) => !params.chartIds.includes(id)),
																)
															}
														}}
													/>
												</div>
											)
										})}
									</div>
								</ScrollArea>
							))}

						{tabKey === CoverTabKey.SPECIFICATION &&
							(selfCharts.length === 0 ? (
								<div className="flex justify-center items-center flex-col mt-12">
									<H4>No charts available for § 112 analysis.</H4>
								</div>
							) : (
								<ScrollArea className="h-[calc(100vh-105px)]">
									<div className="grid grid-cols-1 gap-2 px-2">
										{selfCharts.map((chart) => {
											// Find any existing analysis for this chart
											const analysis = findAnalysisForChart(chart, InvalidityType.SPECIFICATION)
											// Check if this specific chart is being regenerated
											const isChartRegenerating = regeneratingChartIds.includes(chart.id)

											return (
												<div key={chart.id} className="relative">
													<InvalidityAnalysisDisplay
														projectId={projectId}
														subjectId={subjectId}
														chart={chart}
														analysis={analysis}
														invalidityType={InvalidityType.SPECIFICATION}
														isGenerating112Analysis={isChartRegenerating}
														generate112Analysis={async () => {
															// Add this chart to regenerating list
															setRegeneratingChartIds((prev) => [...prev, chart.id])
															try {
																return await generate112Analysis()
															} finally {
																// Remove this chart from regenerating list when done
																setRegeneratingChartIds((prev) => prev.filter((id) => id !== chart.id))
															}
														}}
													/>
												</div>
											)
										})}
									</div>
								</ScrollArea>
							))}

						{tabKey === CoverTabKey.SUMMARIES && (
							<ScrollArea className="h-[calc(100vh-105px)]">
								<div className="grid grid-cols-1 gap-2 px-2">
									{coverSummaries?.map((subjectToDocument) => (
										<DocumentSummary
											key={subjectToDocument.documentId}
											subjectToDocument={subjectToDocument}
											setDocumentViewerDocumentId={setDocumentViewerDocumentId}
											setDocumentViewerCitationText={setDocumentViewerCitationText}
										/>
									))}
								</div>
							</ScrollArea>
						)}
					</div>
				)}
			</div>
		)
	}

	// Function handling pitch summary export
	const handleExportPitchSummary = async (settings: PitchSummaryExportSettings) => {
		setIsExportingPitchSummary(true)
		try {
			await getPitchSummaryExport(
				settings.plaintiffName,
				settings.defendantName,
				settings.defendantProduct,
				settings.bragStatementPersonal,
				settings.bragStatementFirm,
				settings.closingStatement,
			)
		} catch (error) {
			console.error("Error exporting pitch summary:", error)
		} finally {
			setShowExportPitchSummaryModal(false)
			setIsExportingPitchSummary(false)
		}
	}

	return (
		<div>
			<div className="flex flex-col">
				<div className="px-2 pt-0 pb-0 relative">
					<div className="flex items-center justify-between py-1">
						<CoverTabs
							currentTab={currentTab}
							onTabChange={setCurrentTab}
							onExportClick={() => setShowExportModal(true)}
							onExportPitchSummary={() => setShowExportPitchSummaryModal(true)}
							exportDisabled={downloadDisabled}
							onGenerateClick={async () => {
								await tabConfigs[currentTab].generateFunction()
							}}
							isGenerating={tabConfigs[currentTab].isGenerating}
							generateButtonText={tabConfigs[currentTab].generateButtonText}
							hideGenerateButton={noReferences && currentTab !== CoverTabKey.SPECIFICATION}
						/>
					</div>
				</div>
				<TabContent tabKey={currentTab} config={tabConfigs[currentTab]} />
			</div>

			{/* Export Settings Modal */}
			<CoverPleadingExportSettingsModal
				open={showExportModal}
				onClose={() => setShowExportModal(false)}
				documents={filteredPriorArtDocuments.map((doc) => ({
					documentId: doc.id,
					name: doc.nickname || `Document ${doc.id}`,
				}))}
				onExport={handleExportCoverPleading}
				isExporting={isExporting}
			/>

			{/* Pitch Summary Export Settings Modal */}
			<PitchSummaryExportSettingsModal
				open={showExportPitchSummaryModal}
				onClose={() => setShowExportPitchSummaryModal(false)}
				onExport={handleExportPitchSummary}
				isExporting={isExportingPitchSummary}
			/>
		</div>
	)
}

export default ChartsContentionAnalysis
