import { searchApi } from "@/api/searchApi"
import { useUnprocessedDocumentsContext } from "@/context/UnprocessedDocumentsContext"
import { useApi } from "@/hooks"
import { useAppStateStore } from "@/store"
import type { ApiResponse, NPLSearchRequest, SearchFilters } from "@/types"
import { DocumentRole, UnprocessedDocumentType } from "@/types"
import { convertToUtcDateString } from "@/utils/dateUtils"
import { useState } from "react"

interface NPLSearchResult {
	file: File
	title: string
	url: string
}

/**
 * @description A custom hook for NPL searching
 */
const useNPLSearch = () => {
	const { handleError } = useApi()
	const { addUnprocessedDocuments, resetNewDocuments } = useUnprocessedDocumentsContext()
	const { addErrorMessage } = useAppStateStore()
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)

	// Constants for retry logic
	const MAX_RETRIES = 2
	const RETRY_DELAY = 5000

	const resetSearch = () => {
		setIsLoading(false)
		setIsError(false)
	}

	const searchNPL = async (
		projectId: string,
		subjectId: string,
		searchPayload: Partial<NPLSearchRequest> = {},
	): Promise<ApiResponse<NPLSearchResult[]>> => {
		try {
			const nplResults = await searchApi.getNPLSearch(projectId, subjectId, searchPayload)
			return { success: true, data: nplResults as NPLSearchResult[] }
		} catch (error) {
			console.error("Error searching for NPL:", error)
			return handleError(error, "Error searching for NPL")
		}
	}

	const handleSearch = async (
		projectId: string,
		subjectId: string,
		filters: SearchFilters,
		retryCount = 0,
	) => {
		setIsLoading(true)
		setIsError(false)

		const payload: Partial<NPLSearchRequest> = {
			subject_id: subjectId,
			priority_date: filters.toDate ? convertToUtcDateString(filters.toDate) : null,
			...(filters.fromDate
				? {
						to_date: filters.toDate ? convertToUtcDateString(filters.toDate) : null,
						from_date: filters.fromDate ? convertToUtcDateString(filters.fromDate) : null,
					}
				: {}),
			...(filters.whitelistedSites?.length > 0 && {
				whitelisted_sites: filters.whitelistedSites,
			}),
			...(filters.blacklistedSites?.length > 0 && {
				blacklisted_sites: filters.blacklistedSites,
			}),
		}

		try {
			const response = await searchNPL(projectId, subjectId, payload)

			if (!response.success) {
				if (retryCount < MAX_RETRIES) {
					setTimeout(() => {
						handleSearch(projectId, subjectId, filters, retryCount + 1)
					}, RETRY_DELAY)
					return
				}
				setIsError(true)
				addErrorMessage("Failed to search for NPL references")
				return
			}

			resetNewDocuments()
			await addUnprocessedDocuments(
				DocumentRole.PRIOR_ART,
				UnprocessedDocumentType.NPL,
				{
					files: response.data.map((result) => result.file),
					nplDetails: response.data.map((result) => ({
						title: result.title,
						url: result.url,
					})),
				},
				true,
			)
		} catch (error) {
			console.error("Error in NPL search:", error)
			setIsError(true)
			addErrorMessage("An error occurred while searching for NPL references")
		} finally {
			setIsLoading(false)
		}
	}

	return {
		handleSearch,
		isLoading,
		isError,
		resetSearch,
	}
}

export default useNPLSearch
