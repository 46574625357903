import { projectApi } from "@/api/projectApi"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useChatContext } from "@/context/ChatContext"
import { useProjectContext } from "@/context/ProjectContext"
import { useChat } from "@/hooks"
import type { ProjectDocumentMetadata } from "@/types"
import { isPromptUserDirectiveWithRelevantIds } from "@/utils/messageUtils"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { DialogueMessageRenderer } from "./components/DialogueMessageRenderer"
import { PanelChatFooter } from "./components/PanelChatFooter"

interface ChatThreadProps {
	onClose: () => void
}

export const ChatThread = ({ onClose }: ChatThreadProps) => {
	const containerRef = useRef<HTMLDivElement>(null)
	const bottomAnchorRef = useRef<HTMLDivElement>(null)
	const prevActiveChatIdRef = useRef<string | null>(null)
	const [inputValue, setInputValue] = useState("")
	const [isDocumentLoading, setIsDocumentLoading] = useState(false)
	const { projectId } = useProjectContext()

	const [retrievedDocuments, setRetrievedDocuments] = useState<
		ProjectDocumentMetadata[]
	>([])

	const { messages, sendMessage, resetChat, fullChatLoading, markChatAsSeen } = useChat()
	const { selectedDocumentIds, activeChatId, setIsAndyResponding } = useChatContext()

	// Reset chat when activeChatId changes to "new"
	useEffect(() => {
		if (activeChatId === "new") {
			resetChat()
		} else if (activeChatId && activeChatId !== prevActiveChatIdRef.current) {
			markChatAsSeen(activeChatId)
			prevActiveChatIdRef.current = activeChatId
		}
	}, [activeChatId, resetChat, markChatAsSeen])

	const handleSubmit = () => {
		// We need to mark isAndyResponding earlier than React re-renders a non-null pollingSessionId
		setIsAndyResponding(true)
		if (inputValue.trim()) {
			sendMessage(inputValue, selectedDocumentIds)
			setInputValue("")
		}
	}

	const isLastPromptUserMessage = (index: number) => {
		const promptUserIndices = messages
			.map((msg, idx) => {
				return isPromptUserDirectiveWithRelevantIds(msg) ? idx : -1
			})
			.filter((idx) => idx !== -1)

		return (
			promptUserIndices.length > 0 &&
			promptUserIndices[promptUserIndices.length - 1] === index
		)
	}

	useLayoutEffect(() => {
		bottomAnchorRef.current?.scrollIntoView({ behavior: "smooth" })
	}, [messages])

	const fetchDocumentsMetadata = useCallback(
		async (documentIds: string[]) => {
			setIsDocumentLoading(true)
			try {
				const result = await projectApi.getProjectDocumentMetadata(
					projectId,
					[],
					documentIds,
					false,
				)
				return result
			} catch (_error) {
				return []
			} finally {
				setIsDocumentLoading(false)
			}
		},
		[projectId],
	)

	return (
		<>
			<ScrollArea ref={containerRef} className="flex-1 px-4 text-sm">
				{!fullChatLoading && messages !== undefined ? (
					<div className="space-y-2 py-4">
						{messages.map((message, index) => (
							<DialogueMessageRenderer
								key={`${message.role}-${index}`}
								message={message}
								index={index}
								messages={messages}
								isLastPromptUser={isLastPromptUserMessage(index)}
								fetchDocumentsMetadata={fetchDocumentsMetadata}
								bottomRef={index === messages.length - 1 ? bottomAnchorRef : undefined}
							/>
						))}
						{/* <div ref={bottomAnchorRef} /> */}
					</div>
				) : (
					<div className="space-y-4 py-4">
						{/* Loading glimmer with user message and assistant message blocks */}
						<div className="flex justify-end">
							<div className="max-w-[80%] rounded-lg px-4 py-2 bg-primary text-primary-foreground opacity-50 animate-pulse h-12 w-48" />
						</div>
						<div className="flex justify-start">
							<div className="max-w-[80%] rounded-lg px-4 py-2 bg-muted opacity-50 animate-pulse h-12 w-64" />
						</div>
						<div ref={bottomAnchorRef} />
					</div>
				)}
			</ScrollArea>
			<PanelChatFooter
				inputValue={inputValue}
				onInputChange={setInputValue}
				onSend={handleSubmit}
				mode="chat"
			/>
		</>
	)
}
