import { DateTimePicker } from "@/components/ui/datetime-picker"
import { useUpdateDocuments } from "@/features/documents/hooks/useUpdateDocument"
import { convertToUtcDate, getUtcDateString } from "@/utils/dateUtils"
import { DateTime } from "luxon"
import { type FC, useState } from "react"

interface DocumentDatesProps {
	documentId: string
	publicationDate: string
	priorityDate: string
	isEditing: boolean
}

const DocumentDates: FC<DocumentDatesProps> = ({
	documentId,
	publicationDate,
	priorityDate,
	isEditing,
}) => {
	const { updateDocument } = useUpdateDocuments()

	const [selectedPriorityDate, setSelectedPriorityDate] = useState<DateTime>(() => {
		return priorityDate
			? DateTime.fromISO(priorityDate, { zone: "utc" })
			: DateTime.now().setZone("utc").startOf("day")
	})

	const handleDateChange = (date: Date | null) => {
		const newDate = date ? DateTime.fromJSDate(date, { zone: "utc" }) : null
		setSelectedPriorityDate(newDate)

		if (newDate) {
			try {
				const utcPriorityDate = convertToUtcDate(newDate)
				updateDocument({
					documentId,
					priorityDate: utcPriorityDate.toISO(),
				})
			} catch (error) {
				console.error(error)
			}
		}
	}

	return (
		<div className="flex gap-4">
			{publicationDate && (
				<div className="flex items-center gap-2">
					<span className="text-muted-foreground text-sm">Publication Date</span>
					<span className="text-foreground text-sm">
						{publicationDate ? getUtcDateString(publicationDate) : "—"}
					</span>
					<span className="text-muted-foreground">•</span>
				</div>
			)}

			<div className="flex items-center gap-2">
				{(priorityDate || isEditing) && (
					<span className="text-muted-foreground text-sm">Priority Date</span>
				)}
				{isEditing ? (
					<DateTimePicker
						value={selectedPriorityDate.toUTC().toJSDate()}
						onChange={handleDateChange}
					/>
				) : (
					priorityDate && (
						<span className="text-foreground text-sm">
							{priorityDate ? getUtcDateString(priorityDate) : "—"}
						</span>
					)
				)}
			</div>
		</div>
	)
}

export default DocumentDates
