import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"

interface ChartOptionsState {
	showCitationsInClaim: boolean
	showCommentaryInClaim: boolean
	showUpdatedInfo: boolean
	showReferencedFigures: boolean
	showFeedbackButtons: boolean
	showCitationReasons: boolean

	setShowCitationsInClaim: (value: boolean) => void
	setShowCommentaryInClaim: (value: boolean) => void
	setShowUpdatedInfo: (value: boolean) => void
	setShowReferencedFigures: (value: boolean) => void
	setShowFeedbackButtons: (value: boolean) => void
	setShowCitationReasons: (value: boolean) => void

	toggleShowCitationsInClaim: () => void
	toggleShowCommentaryInClaim: () => void
	toggleShowUpdatedInfo: () => void
	toggleShowReferencedFigures: () => void
	toggleShowFeedbackButtons: () => void
	toggleShowCitationReasons: () => void
}

const useChartOptionsStore = create<ChartOptionsState>()(
	persist(
		(set) => ({
			showCitationsInClaim: false,
			showCommentaryInClaim: false,
			showUpdatedInfo: false,
			showReferencedFigures: false,
			showFeedbackButtons: true,
			showCitationReasons: true,

			setShowCitationsInClaim: (value) => set({ showCitationsInClaim: value }),
			setShowCommentaryInClaim: (value) => set({ showCommentaryInClaim: value }),
			setShowUpdatedInfo: (value) => set({ showUpdatedInfo: value }),
			setShowReferencedFigures: (value) => set({ showReferencedFigures: value }),
			setShowFeedbackButtons: (value) => set({ showFeedbackButtons: value }),
			setShowCitationReasons: (value) => set({ showCitationReasons: value }),

			toggleShowCitationsInClaim: () =>
				set((state) => ({ showCitationsInClaim: !state.showCitationsInClaim })),
			toggleShowCommentaryInClaim: () =>
				set((state) => ({ showCommentaryInClaim: !state.showCommentaryInClaim })),
			toggleShowUpdatedInfo: () =>
				set((state) => ({ showUpdatedInfo: !state.showUpdatedInfo })),
			toggleShowReferencedFigures: () =>
				set((state) => ({ showReferencedFigures: !state.showReferencedFigures })),
			toggleShowFeedbackButtons: () =>
				set((state) => ({ showFeedbackButtons: !state.showFeedbackButtons })),
			toggleShowCitationReasons: () =>
				set((state) => ({ showCitationReasons: !state.showCitationReasons })),
		}),
		{
			name: "chart-options", // name of the item in localStorage
			storage: createJSONStorage(() => localStorage),
		},
	),
)

export default useChartOptionsStore
