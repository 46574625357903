import type { ProjectAddRemoveUserRequest, UserMetadata } from "@/types"
import { apiClient } from "./client"

export const accessApi = {
	async addUsersToProject(projectId: string, userIds: string[]) {
		const payload: ProjectAddRemoveUserRequest = {
			user_ids: userIds,
			project_id: projectId,
		}
		return await apiClient.post("post_add_users_to_project", payload)
	},

	async removeUsersFromProject(projectId: string, userIds: string[]) {
		const payload: ProjectAddRemoveUserRequest = {
			user_ids: userIds,
			project_id: projectId,
		}
		return await apiClient.post("post_remove_users_from_project", payload)
	},

	async getUserProjectAccessCheck(projectId: string): Promise<boolean> {
		return await apiClient.get("get_user_project_access_check", {
			project_id: projectId,
		})
	},

	async requestProjectAccess(
		projectId: string,
		userEmail: string,
		projectNickname: string,
	) {
		return await apiClient.get("get_request_project_access_email", {
			project_nickname: projectNickname,
			owner_email: userEmail,
			project_id: projectId,
		})
	},

	async getProjectUsers(id: string): Promise<UserMetadata[]> {
		return await apiClient.get("get_project_users", {
			project_id: id,
		})
	},

	async getShareableUsers(id: string): Promise<UserMetadata[]> {
		return await apiClient.get("get_project_shareable_users", {
			project_id: id,
		})
	},
}
