import { useProjectInfringementDocuments } from "@/features/documents/hooks/useProjectInfringementDocuments"
import { useProjectPriorArtDocuments } from "@/features/documents/hooks/useProjectPriorArtDocuments"
import { useProjectSubjectDocuments } from "@/features/documents/hooks/useProjectSubjectDocuments"
import type { ProjectDocumentMetadata } from "@/types"
import { useMemo } from "react"
import useProjectContextDocuments from "./useProjectContextDocuments"

export function useAllProjectDocuments() {
	const { priorArtDocuments, isLoading: isLoadingPriorArtDocuments } =
		useProjectPriorArtDocuments()
	const { subjectDocuments, isLoading: isLoadingSubjectDocuments } =
		useProjectSubjectDocuments()
	const { infringementDocuments, isLoading: isLoadingInfringementDocuments } =
		useProjectInfringementDocuments()
	const { contextDocuments, isLoading: isLoadingContextDocuments } =
		useProjectContextDocuments()
	// Create combined document metadata list
	const allDocumentsMetadata: ProjectDocumentMetadata[] = useMemo(
		() => [
			// Add subject documents
			...(subjectDocuments ?? []),
			// Add prior art documents
			...(priorArtDocuments ?? []),
			// Add infringement documents
			...(infringementDocuments ?? []),
			// Add context documents
			...(contextDocuments ?? []),
		],
		[priorArtDocuments, subjectDocuments, infringementDocuments, contextDocuments],
	)

	const priorArtIds = useMemo(
		() => priorArtDocuments?.map((doc) => doc.id),
		[priorArtDocuments],
	)
	const subjectIds = useMemo(
		() => subjectDocuments?.map((doc) => doc.id),
		[subjectDocuments],
	)
	const infringementIds = useMemo(
		() => infringementDocuments?.map((doc) => doc.id),
		[infringementDocuments],
	)
	const contextIds = useMemo(
		() => contextDocuments?.map((doc) => doc.id),
		[contextDocuments],
	)
	const chartedIds = useMemo(
		() => [...priorArtIds, ...infringementIds],
		[priorArtIds, infringementIds],
	)

	const chartedDocuments = useMemo(
		() => [...(priorArtDocuments ?? []), ...(infringementDocuments ?? [])],
		[priorArtDocuments, infringementDocuments],
	)

	return {
		allDocumentsMetadata,
		isLoading:
			isLoadingPriorArtDocuments ||
			isLoadingSubjectDocuments ||
			isLoadingInfringementDocuments ||
			isLoadingContextDocuments,
		subjectIds,
		priorArtIds,
		infringementIds,
		contextIds,
		chartedIds,
		chartedDocuments,
	}
}

export default useAllProjectDocuments

export function filterDocumentsBySubjectId(
	subjectId: string,
	documents: ProjectDocumentMetadata[],
): ProjectDocumentMetadata[] {
	return documents.filter(
		(doc) => Array.isArray(doc.subjectIds) && doc.subjectIds.includes(subjectId),
	)
}
