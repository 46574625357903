/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { H3 } from "@/components/ui/typography"
import usePlatformUtils from "@/hooks/usePlatformUtils"
import { MainContentBox, PageContainer } from "@/styled"
import type React from "react"

/**
 * @description Renders the page not found page
 */
const DownPage: React.FC = () => {
	const { usePageTitle } = usePlatformUtils()
	usePageTitle("Routine Mainten")

	return (
		<PageContainer>
		
				<MainContentBox>
					<div className="flex flex-col items-center justify-center p-10 mt-10">
						<H3 className="text-center mb-4">We'll be right back.</H3>

						<p className="text-center w-[800px]">
							Our app is temporarily unavailable while we perform maintenance. 
						</p>
                        <p className="text-center w-[800px]">
							We expect to be
							back online by 5:00 AM EST on April 3.
						</p>
                        <p className="text-center w-[800px]">
							Thanks for your patience—we’re working
							hard to improve your experience!
						</p>
                        <p className="text-center w-[800px]">
							If you need immediate assistance, please contact
							caleb@tryandai.com.
						</p>
					</div>
				</MainContentBox>
		
		</PageContainer>
	)
}

export default DownPage
