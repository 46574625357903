import { api } from "@/api"
import { DEFAULT_QUERY_OPTIONS, QUERY_KEYS } from "@/utils/query"
import { useQuery } from "@tanstack/react-query"

const useSuperAdminOverview = () => {
	const superAdminOverview = useQuery({
		queryKey: QUERY_KEYS.superAdmin.overview,
		queryFn: () => api.getSuperAdminOverview(),
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		overview: superAdminOverview.data,
		isLoading: superAdminOverview.isLoading,
		isFetching: superAdminOverview.isFetching,
	}
}

export { useSuperAdminOverview }
