/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, SidebarPage } from "@/components"
import useUserProjects from "@/hooks/useUserProjects"
import type { ProjectMetadata } from "@/types"
import { ProjectStatus } from "@/types"
import { PageName } from "@/types/pages"
import { useCallback, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { ProjectsTable } from "./components"

/**
 * @description Renders the home page
 */
function HomePage() {
	const navigate = useNavigate()
	const { activeProjects, isLoading, isError } = useUserProjects()

	const pageContentRef = useRef<HTMLDivElement>(null)

	// Handle project selection
	const handleClickProject = useCallback(
		async (project: ProjectMetadata) => {
			const id = project.id

			navigate(`/project/${id}/documents`)
		},
		[navigate],
	)

	return (
		<SidebarPage pageName={PageName.Home}>
			<div className="bg-background" ref={pageContentRef}>
				{isLoading.activeProjects ? (
					<Loader />
				) : isError.activeProjects ? (
					<div>Error</div>
				) : (
					<div className="m-3">
						<ProjectsTable
							tableType={ProjectStatus.ACTIVE}
							handleClickProject={handleClickProject}
							isInArchive={false}
							height="calc(100vh - 200px)"
							projects={activeProjects as ProjectMetadata[]}
						/>
					</div>
				)}
			</div>
		</SidebarPage>
	)
}

export default HomePage
