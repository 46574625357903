import { ScrollArea } from "@/components/ui/scroll-area"
import {
	DocumentSummary,
	ExpertKnowledge,
	ImportantFacts,
	TermsAndDefinitions,
} from "@/features/context/components/ContextSections"
import { useProjectMetadata } from "@/hooks"
interface DocumentContextComponentProps {
	context: any
	isEditing: boolean
	setContextData: (context: any) => void
}

/**
 * @description Context page
 */
const DocumentContextComponent: React.FC<DocumentContextComponentProps> = ({
	context,
	isEditing,
	setContextData,
}) => {
	const { projectType } = useProjectMetadata()

	return (
		<div className="px-2">
			<div className="flex flex-grow h-full overflow-hidden">
				{/* Left column with Terms, Definitions & Search Instructions */}
				<div className="w-1/2 h-full overflow-hidden">
					<ScrollArea className="pr-1 py-2 gap-2 h-full">
						<DocumentSummary
							summary={context?.summary}
							onChange={(value) => setContextData({ ...context!, summary: value })}
							isEditing={isEditing}
						/>

						<div className="mt-2">
							<TermsAndDefinitions
								terms={context?.termsAndDefinitions ?? []}
								onChange={(terms) =>
									setContextData({
										...context!,
										termsAndDefinitions: terms,
									})
								}
								isEditing={isEditing}
								searchTerm={() => {}}
								showSearchButton={false}
							/>
						</div>
					</ScrollArea>
				</div>
				{/* Right column with Important Facts & Expert Knowledge */}
				<div className="w-1/2 h-full overflow-hidden">
					<ScrollArea className="pl-1 py-2 h-full">
						<ImportantFacts
							contextData={context}
							setContextData={setContextData}
							isEditing={isEditing}
							searchTerm={() => {}}
							showSearchButton={false}
						/>
						<div className="mt-2">
							<ExpertKnowledge
								value={context?.expertKnowledge}
								onChange={(value) => setContextData({ ...context!, expertKnowledge: value })}
								isEditing={isEditing}
							/>
						</div>
					</ScrollArea>
				</div>
			</div>
		</div>
	)
}

export default DocumentContextComponent
