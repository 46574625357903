import { chartsApi } from "@/api/chartsApi"
import useProjectSettings from "@/features/settings/hooks/useProjectSettings"
import type { InvaliditySummaryColumn } from "@/types"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { type UseQueryOptions, useQuery } from "@tanstack/react-query"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

export const useChartSummaryData = (
	projectId: string,
	subjectId: string,
	invalidityChartId?: string,
	options?: Partial<UseQueryOptions<any, Error>>,
): {
	invaliditySummaryData: InvaliditySummaryColumn[]
	isLoading: boolean
	isError: boolean
} => {
	const { settings } = useProjectSettings()

	const summaryQuery = useQuery({
		queryKey: QUERY_KEYS.project.charts.chartSummaryData(
			projectId,
			subjectId,
			invalidityChartId,
		),
		queryFn: async () =>
			await chartsApi.getChartSummaries(
				projectId,
				subjectId,
				invalidityChartId,
				settings?.defaultToAssertedClaims,
			),
		enabled: Boolean(projectId) && (options?.enabled ?? true),
		...DEFAULT_QUERY_OPTIONS,
		...options,
	})

	return {
		invaliditySummaryData: summaryQuery.data,
		isLoading: summaryQuery.isLoading,
		isError: summaryQuery.isError,
	}
}

export default useChartSummaryData
