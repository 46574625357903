/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { formatPatentNumber } from "@/utils"
import { useParams } from "react-router-dom"
import useAllProjectDocuments from "../features/documents/hooks/useAllProjectDocuments"

const useDocumentNaming = () => {
	const { projectId } = useParams()
	const { allDocumentsMetadata } = useAllProjectDocuments()
	const getDocumentName = (id: string): string => {
		if (projectId) {
			const doc = allDocumentsMetadata?.find((ref) => ref.id === id)

			return (
				doc?.nickname || formatPatentNumber(doc?.patent?.number ?? "") || doc?.title || ""
			)
		}

		return ""
	}

	return { getDocumentName }
}

export default useDocumentNaming
