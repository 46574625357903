import type { ChatMetadata, DialogueResponse } from "@/types/chat"
import { jsonToArray } from "@/utils"

/**
 * Determines if a message is unread by comparing timestamps and checking if it's from the assistant
 */
export const isMessageUnread = (
	lastMessage?: DialogueResponse | null,
	seenAt?: string | null,
): boolean => {
	if (!lastMessage || !seenAt) return false

	return (
		new Date(lastMessage.createdAt).getTime() > new Date(seenAt).getTime() &&
		lastMessage.role === "ASSISTANT"
	)
}

/**
 * Checks if a chat contains any unread messages from the assistant
 */
export const isChatUnread = (chatMetadata: ChatMetadata): boolean => {
	return isMessageUnread(chatMetadata.lastMessage, chatMetadata.seenAt)
}

/**
 * Returns the most recently updated chat from a list of chats
 */
export const getMostRecentChat = (
	chatsMetadata?: ChatMetadata[],
): ChatMetadata | undefined => {
	if (!chatsMetadata?.length) return undefined

	return chatsMetadata.sort((a, b) => {
		const dateA = new Date(a.updatedAt || a.createdAt || 0).getTime()
		const dateB = new Date(b.updatedAt || b.createdAt || 0).getTime()
		return dateB - dateA // Sort in descending order (most recent first)
	})[0]
}

/**
 * Returns the text value from a message's task parameters
 */
export const getContentFromMessage = (message: DialogueResponse): string => {
	const text = "content" in message.task ? message.task.content : ""
	return typeof text === "string" ? text : ""
}

export const getDirectiveNameFromMessage = (message: DialogueResponse): string => {
	return "directiveName" in message.task ? message.task.directiveName : ""
}

export const getToolNameFromMessage = (message: DialogueResponse): string => {
	return "toolName" in message.task ? message.task.toolName : ""
}

export const isPromptUserDirectiveWithRelevantIds = (
	message: DialogueResponse,
): boolean => {
	return (
		getDirectiveNameFromMessage(message) === "prompt_user" &&
		message.task?.parameters?.some(
			(param) =>
				param.name === "relevant_ids" && param.value && jsonToArray(param.value).length > 0,
		)
	)
}

export const getRelevantIdsFromMessage = (message: DialogueResponse): string[] => {
	if (!message?.task?.parameters || !Array.isArray(message.task.parameters)) {
		return []
	}

	const relevantIdsParam = message.task.parameters.find(
		(param) => param.name === "relevant_ids",
	)
	if (!relevantIdsParam || !relevantIdsParam.value) {
		return []
	}

	try {
		return jsonToArray(relevantIdsParam.value)
	} catch (_error) {
		return []
	}
}

export const isCreatePlanDirective = (message: DialogueResponse): boolean => {
	return (
		getDirectiveNameFromMessage(message) === "create_plan" &&
		message.task?.parameters?.some(
			(param) =>
				param.name === "phases" && param.value && jsonToArray(param.value).length > 0,
		)
	)
}
