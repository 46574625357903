import { Loader } from "@/components"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { FloatingLabelInput } from "@/components/ui/floating-input"
import { useDocumentContext } from "@/context/DocumentContext"
import { cn } from "@/lib/utils"
import { SearchMode, SearchOrder } from "@/types"
import { Cross2Icon, MagnifyingGlassIcon } from "@radix-ui/react-icons"
import type React from "react"
import type { KeyboardEvent } from "react"
import { Input } from "../ui/input"

interface DocumentSearchInputProps {
	/** Remove documentIds, since we use the context now */
	placeholder?: string
	disabled?: boolean
	showStatusInComponent?: boolean
}

/**
 * SearchInput for Document level search (on document page and chart document panel)
 */
const DocumentSearchInput: React.FC<DocumentSearchInputProps> = ({
	placeholder = "Search document...",
	disabled = false,
	showStatusInComponent = false,
}) => {
	const {
		searchQuery,
		setSearchQuery,
		search,
		searchLoading,
		searchError,
		searchMode,
		setSearchMode,
		searchOrderBy,
		setSearchOrderBy,
		searchResultIds,
		setSearchResultIds,
		setSearchOrderedResults,
	} = useDocumentContext()

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		// Pressing Enter triggers search, unless you allow multiline with Shift+Enter
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault()
			search()
		}
	}

	const handleClear = () => {
		setSearchQuery("")
		setSearchResultIds(null)
		setSearchOrderedResults([])
	}

	return (
		<div className="w-full">
			<div className="flex items-center gap-2">
				<div className="relative flex-1">
					<Input
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						placeholder={placeholder}
						onKeyDown={handleKeyDown}
						disabled={disabled}
						className={"h-8 !pr-20 resize-none"}
					/>

					<div className={cn("absolute right-2 flex gap-1", "top-1/2 -translate-y-1/2")}>
						{searchQuery && (
							<Button
								size="icon"
								variant="ghost"
								className="h-8 w-8"
								onClick={handleClear}
								disabled={disabled}
							>
								<Cross2Icon className="h-4 w-4 text-muted-foreground" />
							</Button>
						)}

						<Button
							size="icon"
							variant="ghost"
							className="h-8 w-8"
							onClick={search}
							disabled={disabled || searchLoading}
						>
							<MagnifyingGlassIcon className="h-4 w-4 text-muted-foreground" />
						</Button>
					</div>
				</div>

				{/* Optionally, show mode + ordering toggles */}
				<div className="flex items-center gap-2">
					<Button
						size="sm"
						variant={searchMode === SearchMode.KEYWORD ? "selected" : "outline"}
						onClick={() =>
							setSearchMode(
								searchMode === SearchMode.KEYWORD ? SearchMode.SEMANTIC : SearchMode.KEYWORD,
							)
						}
						disabled={disabled}
					>
						Exact
					</Button>

					{searchMode === SearchMode.SEMANTIC && (
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<FloatingLabelInput
									id="orderBy"
									label="Order"
									value={searchOrderBy === SearchOrder.SCORE ? "Score" : "Location"}
									readOnly
									className="cursor-pointer py-1"
								/>
							</DropdownMenuTrigger>
							<DropdownMenuContent>
								<DropdownMenuItem onSelect={() => setSearchOrderBy(SearchOrder.SCORE)}>
									Score
								</DropdownMenuItem>
								<DropdownMenuItem onSelect={() => setSearchOrderBy(SearchOrder.LOCATION)}>
									Location
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					)}
				</div>
			</div>
			{/* Display the number of search results found, if any */}
			{showStatusInComponent && (
				<>
					{searchLoading && <Loader message="Searching document..." />}
					{searchError && (
						<span className="mt-1 text-sm text-red-500">Error searching document</span>
					)}
					{searchQuery && !searchLoading && searchResultIds && (
						<div className="mt-1 text-sm text-muted">
							{searchResultIds.size} result{searchResultIds.size !== 1 ? "s" : ""} found
						</div>
					)}
				</>
			)}
		</div>
	)
}

export default DocumentSearchInput
