/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import RichVariableInput from "@/components/input/RichVariableInput"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import { useProjectContext } from "@/context/ProjectContext"
import useCoverPleading from "@/features/charts/hooks/useCoverPleading"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import type { SubjectToDocument } from "@/types/documents"
import { parseHtml } from "@/utils/parseHtml"
import { Pencil1Icon } from "@radix-ui/react-icons"
import { CheckIcon, RefreshCwIcon } from "lucide-react"
import { type FC, useState } from "react"
import { useNavigate } from "react-router-dom"
interface DocumentSummaryProps {
	subjectToDocument: SubjectToDocument
	setDocumentViewerDocumentId: (documentId: string) => void
	setDocumentViewerCitationText: (citationText: string) => void
}

const DocumentSummary: FC<DocumentSummaryProps> = ({
	subjectToDocument,
	setDocumentViewerDocumentId,
	setDocumentViewerCitationText,
}) => {
	const { projectId } = useProjectContext()
	const navigate = useNavigate()
	const { generateSummaries, isGeneratingSummaries, updateCoverSummaries } =
		useCoverPleading()
	const { getDocumentName } = useDocumentNaming()

	const [isEditing, setIsEditing] = useState(false)
	const [editValue, setEditValue] = useState<string>(
		subjectToDocument.documentCover || "",
	)

	const handleEditToggle = async () => {
		if (isEditing) {
			await updateCoverSummaries({
				documentId: subjectToDocument.documentId,
				documentCover: editValue,
			})
			setIsEditing(false)
		} else {
			setIsEditing(true)
			setEditValue(subjectToDocument.documentCover || "")
		}
	}

	const handleCitationClick = async (strippedQuote: string) => {
		setDocumentViewerCitationText(strippedQuote)
		setDocumentViewerDocumentId(subjectToDocument.documentId)
	}

	const handleTitleClick = () => {
		navigate(`/project/${projectId}/references/${subjectToDocument.documentId}`)
	}

	const handleGenerateSummary = async () => {
		await generateSummaries({
			documentIds: [subjectToDocument.documentId],
		})
	}

	return (
		<div key={subjectToDocument.documentId} className="px-4 py-3 border rounded-md">
			<div className="flex items-center justify-between">
				<div className="flex items-center gap-2">
					<Label
						className="mb-0 hover:cursor-pointer hover:text-blue-600"
						onClick={handleTitleClick}
					>
						{getDocumentName(subjectToDocument.documentId)}
					</Label>
					<Button
						variant="ghost"
						size="icon"
						onClick={handleEditToggle}
						disabled={isGeneratingSummaries}
					>
						{isEditing ? (
							<CheckIcon className="h-4 w-4" />
						) : (
							<Pencil1Icon className="h-4 w-4" />
						)}
					</Button>
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant="ghost"
									size="icon"
									onClick={handleGenerateSummary}
									disabled={isGeneratingSummaries || isEditing}
								>
									<RefreshCwIcon className="h-4 w-4" />
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								{subjectToDocument.documentCover ? "Regenerate summary" : "Generate summary"}
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
			</div>

			{isGeneratingSummaries ? (
				<Loader message="Generating summary..." />
			) : isEditing ? (
				<RichVariableInput
					value={editValue}
					onChange={(newVal: string) => setEditValue(newVal)}
					enableVariableMenu={false}
				/>
			) : (
				<p className="text-sm mt-2">
					{subjectToDocument.documentCover
						? parseHtml(
								subjectToDocument.documentCover,
								{},
								undefined,
								undefined,
								undefined,
								undefined,
								false,
								handleCitationClick,
							)
						: ""}
				</p>
			)}
		</div>
	)
}

export default DocumentSummary
