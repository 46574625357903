import { api } from "@/api"
import type { ProjectDocumentMetadata } from "@/types"
import type { DocumentRole } from "@/types/documents"
import {
	getDocumentRoleQueryKey,
	invalidateProjectDocuments,
} from "@/utils/query/invalidation"
import { MUTATION_KEYS } from "@/utils/query/keys"
import {
	handleOptimisticUpdate,
	revertOptimisticUpdates,
} from "@/utils/query/optimisticUpdates"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useParams } from "react-router-dom"

/**
 * A helper function to remove deleted documents from the cache.
 */
function filterDocuments(oldData: unknown, documentIds: string[]): unknown {
	if (!oldData || !Array.isArray(oldData)) return oldData
	const typedData = oldData as ProjectDocumentMetadata[]
	return typedData.filter((ref) => !documentIds.includes(ref.id))
}

export function useDeleteAndArchiveDocuments() {
	const queryClient = useQueryClient()
	const { projectId } = useParams()

	const deleteDocumentsMutation = useMutation({
		mutationKey: MUTATION_KEYS.document.delete(),
		mutationFn: async ({
			documentIds,
			role,
		}: { documentIds: string[]; role: DocumentRole }) => {
			if (!projectId) {
				throw new Error("Project Id is required")
			}
			return api.deleteDocuments(projectId, documentIds)
		},
		onMutate: async ({
			documentIds,
			role,
		}: { documentIds: string[]; role: DocumentRole }) => {
			if (!projectId) return
			const queryKey = getDocumentRoleQueryKey(role, projectId)
			// Optimistically update the cache by removing any references to the deleted documents.
			const context = await handleOptimisticUpdate({
				queryClient,
				queryKeys: [queryKey],
				updateFn: (oldData) => filterDocuments(oldData, documentIds),
			})
			return context
		},
		onError: (_error, _variables, context) => {
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
		},
		onSuccess: (_, variables) => {
			if (!projectId) return
			invalidateProjectDocuments(queryClient, projectId)
		},
	})

	const archiveDocumentsMutation = useMutation({
		// mutationKey: MUTATION_KEYS.document.archive(),
		mutationFn: async ({
			documentIds,
			role,
			removed,
		}: { documentIds: string[]; role: DocumentRole; removed: boolean }) => {
			if (!projectId) {
				throw new Error("Project Id is required")
			}
			return api.toggleArchiveDocuments(projectId, documentIds, removed)
		},
		onMutate: async ({
			documentIds,
			role,
			removed,
		}: { documentIds: string[]; role: DocumentRole; removed: boolean }) => {
			if (!projectId) return
			const queryKey = getDocumentRoleQueryKey(role, projectId)
			// Optimistically update the cache by removing any references to the deleted documents.
			const context = await handleOptimisticUpdate({
				queryClient,
				queryKeys: [queryKey],
				updateFn: (oldData) => filterDocuments(oldData, documentIds),
			})
			return context
		},
		onError: (_error, _variables, context) => {
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
		},
		onSuccess: (_, variables) => {
			if (!projectId) return
			const queryKey = getDocumentRoleQueryKey(variables.role, projectId)
			queryClient.invalidateQueries({ queryKey })
		},
	})

	return {
		deleteDocuments: deleteDocumentsMutation.mutateAsync,
		deleteDocumentsLoading: deleteDocumentsMutation.isPending,
		archiveDocuments: archiveDocumentsMutation.mutateAsync,
		archiveDocumentsLoading: archiveDocumentsMutation.isPending,
	}
}

export default useDeleteAndArchiveDocuments
