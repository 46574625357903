import type { DocumentChunk } from "@/types"
import { cleanDocumentChunkText } from "@/utils/formatStrings"
import { normalizeTextToMatch } from "@/utils/normalizeTextToMatch"
import { parseFigureReferences } from "@/utils/parseFigureReferences"
import type React from "react"

interface DocumentChunkTextProps {
	chunk: DocumentChunk
	figureChunks?: DocumentChunk[]
	citationText?: string
	onFigureReferenceSelect?: (figureChunk: DocumentChunk) => void
	isDarkMode?: boolean
}

const DocumentChunkText: React.FC<DocumentChunkTextProps> = ({
	chunk,
	figureChunks,
	citationText,
	onFigureReferenceSelect,
	isDarkMode,
}) => {
	const text = cleanDocumentChunkText(chunk.text)
	if (!text?.trim()) return null

	// Store the original text in a data attribute for selection handling
	const wrapper = (content: React.ReactNode) => (
		<span data-original-text={text}>{content}</span>
	)

	let currentSegments: React.ReactNode[] = [text]

	// Parse out figure references if figureChunks is provided
	if (figureChunks?.length) {
		currentSegments = currentSegments.flatMap((segment) => {
			if (typeof segment === "string") {
				return parseFigureReferences(
					segment,
					figureChunks,
					onFigureReferenceSelect,
					isDarkMode,
				)
			}
			return segment
		})
	}

	// If citationText is provided, highlight the first occurrence
	if (citationText) {
		const normalizedSearch = normalizeTextToMatch(citationText)

		const splitOnCitation = (node: React.ReactNode): React.ReactNode[] => {
			if (typeof node !== "string") return [node]

			const textValue = node
			const normalizedText = normalizeTextToMatch(textValue)
			const index = normalizedText.indexOf(normalizedSearch)
			if (index === -1) {
				return [textValue]
			}
			const searchLength = citationText.length

			let originalIndex = 0
			let normalizedIndex = 0
			let highlightStart = -1
			while (originalIndex < textValue.length) {
				const char = textValue[originalIndex]
				const normalizedChar = normalizeTextToMatch(char)
				if (normalizedChar) {
					if (normalizedIndex === index) {
						highlightStart = originalIndex
						break
					}
					normalizedIndex++
				}
				originalIndex++
			}

			const highlightEnd = highlightStart + searchLength
			return [
				textValue.slice(0, highlightStart),
				<span key={crypto.randomUUID()} className="bg-yellow-200">
					{textValue.slice(highlightStart, highlightEnd)}
				</span>,
				textValue.slice(highlightEnd),
			]
		}

		currentSegments = currentSegments.flatMap((node) => splitOnCitation(node))
	}

	return wrapper(currentSegments)
}

export default DocumentChunkText
