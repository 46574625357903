import { Input } from "@/components/ui/input"
import TooltipButton from "@/components/ui/tooltip-button"
import { H4 } from "@/components/ui/typography"
import type { DocumentChunk } from "@/types/invalidity"
import { ArrowLeft, ArrowRight, RotateCcw, RotateCw, X } from "lucide-react"
import type { Dispatch, RefObject, SetStateAction } from "react"

interface FigureModalActionsBarProps {
	figureChunks: DocumentChunk[]
	selectedImageIndex: number
	setSelectedImageIndex: Dispatch<SetStateAction<number>>

	// Rotation events
	handleRotateLeft: () => void
	handleRotateRight: () => void

	// Figure Number Editing
	isEditingFigureNumber: boolean
	setIsEditingFigureNumber: Dispatch<SetStateAction<boolean>>
	figureNumberInputRef: RefObject<HTMLInputElement>
	tempFigureNumber: string
	setTempFigureNumber: Dispatch<SetStateAction<string>>
	handleFigureNumberChange: (newNumber: string) => void
	onHide?: () => void
}

export function FigureModalActionsBar({
	figureChunks,
	selectedImageIndex,
	setSelectedImageIndex,
	handleRotateLeft,
	handleRotateRight,
	isEditingFigureNumber,
	setIsEditingFigureNumber,
	figureNumberInputRef,
	tempFigureNumber,
	setTempFigureNumber,
	handleFigureNumberChange,
	onHide,
}: FigureModalActionsBarProps) {
	// Whenever the user presses Enter or loses focus, call handleFigureNumberChange
	const handleCommitFigureNumber = () => {
		if (tempFigureNumber.trim() !== "") {
			handleFigureNumberChange(tempFigureNumber)
		}
		setIsEditingFigureNumber(false)
	}

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			handleCommitFigureNumber()
		}
	}

	return (
		<div className="w-full flex relative px-4 py-2 z-10">
			{/* Left group - Rotate - Absolutely positioned */}
			<div className="absolute left-4 flex space-x-2">
				<TooltipButton
					tooltipText="Rotate left"
					variant="ghost"
					size="xsIcon"
					onClick={handleRotateLeft}
					aria-label="Rotate left"
					icon={<RotateCcw size={18} className="text-current" />}
					tooltipSide="bottom"
				/>
				<TooltipButton
					tooltipText="Rotate right"
					variant="ghost"
					size="xsIcon"
					onClick={handleRotateRight}
					aria-label="Rotate right"
					icon={<RotateCw size={18} className="text-current" />}
					tooltipSide="bottom"
				/>
			</div>

			{/* Figure info - Centered */}
			<div className="flex-1 flex justify-center">
				<div className="flex items-center gap-2">
					<>
						<TooltipButton
							tooltipText="Previous image"
							variant="ghost"
							size="xsIcon"
							onClick={() =>
								setSelectedImageIndex(
									(prev) => (prev - 1 + figureChunks.length) % figureChunks.length,
								)
							}
							disabled={figureChunks.length <= 1}
							aria-label="Previous image"
							tooltipSide="bottom"
							icon={<ArrowLeft size={18} className="text-current" />}
						/>
						{isEditingFigureNumber ? (
							<>
								<H4
									className="cursor-pointer hover:opacity-80"
									onClick={() => setIsEditingFigureNumber(true)}
								>
									Figure
								</H4>
								<Input
									ref={figureNumberInputRef}
									type="text"
									value={tempFigureNumber}
									onChange={(e) => setTempFigureNumber(e.target.value)}
									onBlur={handleCommitFigureNumber}
									onKeyDown={onKeyDown}
									className="w-20 px-2 py-1 text-lg font-semibold border rounded text-center"
									autoFocus
								/>
							</>
						) : (
							<div className="group flex items-center gap-1 cursor-pointer hover:opacity-80">
								<H4
									className="border-b border-dotted border-transparent group-hover:border-current"
									onClick={() => setIsEditingFigureNumber(true)}
								>
									Figure {figureChunks[selectedImageIndex]?.figureNumber}
								</H4>
							</div>
						)}
						<TooltipButton
							tooltipText="Next image"
							variant="ghost"
							size="xsIcon"
							onClick={() => setSelectedImageIndex((prev) => (prev + 1) % figureChunks.length)}
							disabled={figureChunks.length <= 1}
							aria-label="Next image"
							icon={<ArrowRight size={18} className="text-current" />}
							tooltipSide="bottom"
						/>
					</>
				</div>
			</div>

			{/* Add Close button - Absolutely positioned */}
			{onHide && (
				<div className="absolute right-4">
					<TooltipButton
						tooltipText="Close"
						variant="ghost"
						size="xsIcon"
						onClick={onHide}
						aria-label="Close"
						icon={<X size={18} className="text-current" />}
						tooltipSide="bottom"
					/>
				</div>
			)}
		</div>
	)
}
