import ExternalUrlDisplay from "@/components/info/externalUrlDisplay"
import type { Product } from "@/types/documents"
import { Badge } from "../ui/badge"

interface ProductMetadataProps {
	product: Product
}

const ProductMetadata: React.FC<ProductMetadataProps> = ({ product }) => {
	const metadataFields = [
		{
			key: "product_id",
			label: "Product ID",
			value: product.productId,
		},
		{
			key: "product_url",
			label: "Product Overview",
			value: product.productUrl,
		},
		{
			key: "seller",
			label: "Seller",
			value: product.seller,
		},
		{
			key: "specification_url",
			label: "Specification",
			value: product.specificationUrl,
		},
		{
			key: "licensed",
			label: "Licensed",
			value: product.licensed,
		},
	].filter((field) => field.value && field.value !== "")

	return (
		<div className="flex flex-wrap gap-2">
			{metadataFields.map((field, index) => (
				<div key={field.key} className="flex flex-wrap items-center gap-2">
					{field.key === "product_url" || field.key === "specification_url" ? (
						<ExternalUrlDisplay url={field.value as string} label={field.label} size="xs" />
					) : (
						<>
							<span className="text-muted-foreground text-sm whitespace-nowrap">
								{field.label}
							</span>
							<span className="text-foreground text-sm break-all">{field.value || "—"}</span>
						</>
					)}
					{index < metadataFields.length - 1 && (
						<span className="text-muted-foreground">•</span>
					)}
				</div>
			))}
			<Badge variant="outline">{product.licensed ? "Licensed" : "Unlicensed"}</Badge>
		</div>
	)
}

export default ProductMetadata
