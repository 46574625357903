import { useAuthInfo } from "@propelauth/react"
import posthog from "posthog-js"
import { useEffect } from "react"

export function usePostHogIdentify() {
	const { user, loading, orgHelper } = useAuthInfo()

	useEffect(() => {
		if (!loading && user) {
			posthog.identify(user.userId, {
				email: user.email,
				name: `${user.firstName} ${user.lastName}`,
			})

			posthog.capture("user_logged_in", {
				login_method: "PropelAuth",
				environment: process.env.ENVIRONMENT,
			})

			const orgs = orgHelper.getOrgs()
			if (orgs && orgs.length > 0) {
				const primaryOrg = orgs[0] // Assuming the first org is the primary one
				posthog.group("organization", primaryOrg.orgId, {
					name: primaryOrg.orgName,
				})
			}
		}
	}, [user, loading, orgHelper])
}
