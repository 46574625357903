import type { DocumentContext, UpdateDocumentContextRequest } from "@/types"
import { apiClient } from "./client"

/**
 * API functions for LLM-related operations
 */
export const llmApi = {
	/**
	 * Get document context
	 */
	getDocumentContext: async (documentId: string) => {
		return await apiClient.get("get_document_context", {
			document_id: documentId,
		})
	},
	/**
	 * Save document context
	 */
	updateDocumentContext: async (documentId: string, context: DocumentContext) => {
		const payload: UpdateDocumentContextRequest = {
			document_id: documentId,
			context: context,
		}
		return await apiClient.post("post_update_document_context", payload)
	},
}
