import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip"

import { useChartsContext } from "@/features/charts/context/ChartsContext"
import type { Group } from "@/types"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import type React from "react"
import styled from "styled-components"

const PillBox = styled.div<{ isSelected?: boolean }>`
  background-color: ${(props) => (props.isSelected ? "#E2E8F0" : "#F8FAFC")};
  border-radius: 8px;
  padding: 3px 8px;
  display: inline-block;
  color: #000;
  margin: 1px;
  min-width: 40px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  border: ${(props) => (props.isSelected ? "2px solid #3B82F6" : "none")};

  p {
    margin: 0;
    line-height: 1;
  }
`

interface GroupPillProps {
	group: Group
	handleClick: () => void
	isSelected?: boolean
}

const GroupPill: React.FC<GroupPillProps> = ({ group, handleClick, isSelected }) => {
	const { groupsToLimitationLabels } = useChartsContext()
	const limitations = groupsToLimitationLabels[group.id]
	const content = (
		<PillBox onClick={handleClick} isSelected={isSelected}>
			<p className="text-xs text-black">{group.nickname || `Group ${group.number}`}</p>
		</PillBox>
	)

	if (limitations?.length === 0) {
		return content
	}

	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>{content}</TooltipTrigger>
				<TooltipContent className="max-w-[300px]">
					<div className="flex gap-2 text-xs">
						{limitations?.map((limitation, index) => (
							<span key={limitation}>
								{index > 0 && "•"} {limitation}
							</span>
						))}
					</div>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}

export default GroupPill
