/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import { Textarea } from "@/components/ui/textarea"
import type React from "react"
import { useState } from "react"

interface PitchSummaryExportSettingsModalProps {
	open: boolean
	onClose: () => void
	onExport: (settings: PitchSummaryExportSettings) => void
	isExporting: boolean
}

export interface PitchSummaryExportSettings {
	plaintiffName: string
	defendantName: string
	defendantProduct: string
	bragStatementPersonal: string
	bragStatementFirm: string
	closingStatement: string
}

const PitchSummaryExportSettingsModal: React.FC<
	PitchSummaryExportSettingsModalProps
> = ({ open, onClose, onExport, isExporting }) => {
	const [settings, setSettings] = useState<PitchSummaryExportSettings>({
		plaintiffName: "PLAINTIFF NAME",
		defendantName: "DEFENDANT NAME",
		defendantProduct: "DEFENDANT PRODUCT",
		bragStatementPersonal: "INSERT PERSONAL BRAG STATEMENT",
		bragStatementFirm: "INSERT FIRM BRAG STATEMENT",
		closingStatement: "INSERT CLOSING STATEMENT",
	})

	const handleChange = (field: keyof PitchSummaryExportSettings, value: string) => {
		setSettings((prev) => ({
			...prev,
			[field]: value,
		}))
	}

	const handleExport = () => {
		onExport(settings)
	}

	return (
		<Dialog open={open} onOpenChange={(open) => !open && onClose()}>
			<DialogContent className="sm:max-w-[600px]">
				<DialogHeader>
					<DialogTitle>Pitch Summary Export Settings</DialogTitle>
					<DialogDescription>
						Customize the statements for your pitch summary memo.
					</DialogDescription>
				</DialogHeader>

				<div className="grid gap-4 py-4">
					<div className="grid gap-2">
						<Label htmlFor="plaintiffName">Plaintiff Name</Label>
						<Textarea
							id="plaintiffName"
							rows={2}
							value={settings.plaintiffName}
							onChange={(e) => handleChange("plaintiffName", e.target.value)}
						/>
					</div>

					<div className="grid gap-2">
						<Label htmlFor="defendantName">Defendant Name</Label>
						<Textarea
							id="defendantName"
							rows={2}
							value={settings.defendantName}
							onChange={(e) => handleChange("defendantName", e.target.value)}
						/>
					</div>

					<div className="grid gap-2">
						<Label htmlFor="defendantProduct">Defendant Product</Label>
						<Textarea
							id="defendantProduct"
							rows={2}
							value={settings.defendantProduct}
							onChange={(e) => handleChange("defendantProduct", e.target.value)}
						/>
					</div>

					<div className="grid gap-2">
						<Label htmlFor="bragStatementPersonal">Personal Brag Statement</Label>
						<Textarea
							id="bragStatementPersonal"
							rows={4}
							value={settings.bragStatementPersonal}
							onChange={(e) => handleChange("bragStatementPersonal", e.target.value)}
						/>
					</div>

					<div className="grid gap-2">
						<Label htmlFor="bragStatementFirm">Firm Brag Statement</Label>
						<Textarea
							id="bragStatementFirm"
							rows={4}
							value={settings.bragStatementFirm}
							onChange={(e) => handleChange("bragStatementFirm", e.target.value)}
						/>
					</div>

					<div className="grid gap-2">
						<Label htmlFor="closingStatement">Closing Statement</Label>
						<Textarea
							id="closingStatement"
							rows={4}
							value={settings.closingStatement}
							onChange={(e) => handleChange("closingStatement", e.target.value)}
						/>
					</div>
				</div>

				<DialogFooter>
					<Button variant="outline" onClick={onClose} disabled={isExporting}>
						Cancel
					</Button>
					<Button onClick={handleExport} disabled={isExporting}>
						{isExporting ? "Exporting..." : "Export"}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default PitchSummaryExportSettingsModal
