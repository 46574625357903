"use client"

import { Button } from "@/components/ui/button"
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import { cn } from "@/lib/utils"
import { Check } from "lucide-react"
import { useEffect, useState } from "react"
import type { DateRange } from "react-day-picker"
import DateRangeFilter from "./DateRangeFilter"

export type FilterOptions = {
	dateRange: DateRange | null
	users: string[]
	organization?: string
}

export type UsageFiltersProps = {
	isSuperAdmin?: boolean
	organizations?: string[]
	users?: string[]
	onFiltersChange?: (filters: FilterOptions) => void
}

export function UsageFilters({
	isSuperAdmin = false,
	organizations = [],
	users = [],
	onFiltersChange,
}: UsageFiltersProps) {
	const [dateRange, setDateRange] = useState<DateRange | null>(null)
	const [selectedUsers, setSelectedUsers] = useState<string[]>([])
	const [usersPopoverOpen, setUsersPopoverOpen] = useState(false)
	const [selectedOrganization, setSelectedOrganization] = useState<string>("")

	useEffect(() => {
		if (onFiltersChange) {
			onFiltersChange({
				dateRange,
				users: selectedUsers,
				organization: selectedOrganization,
			})
		}
	}, [dateRange, selectedUsers, selectedOrganization, onFiltersChange])

	function toggleUser(user: string) {
		setSelectedUsers((prev) =>
			prev.includes(user) ? prev.filter((u) => u !== user) : [...prev, user],
		)
	}

	const usersButtonText =
		selectedUsers.length === 0
			? "Filter by User"
			: selectedUsers.length <= 2
				? selectedUsers.join(", ")
				: `${selectedUsers.length} users selected`

	return (
		<div className="flex items-center gap-2">
			<DateRangeFilter onDateRangeChange={setDateRange} />

			{/* Users Multiselect */}
			<Popover open={usersPopoverOpen} onOpenChange={setUsersPopoverOpen}>
				<PopoverTrigger asChild>
					<Button variant="outline" size="default">
						{usersButtonText}
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-[300px] p-0">
					<Command>
						<CommandInput placeholder="Search users..." />
						<CommandList>
							<CommandEmpty>No users found.</CommandEmpty>
							<CommandGroup>
								{users.map((user) => (
									<CommandItem key={user} onSelect={() => toggleUser(user)}>
										<Check
											className={cn(
												"mr-2 h-4 w-4",
												selectedUsers.includes(user) ? "opacity-100" : "opacity-0",
											)}
										/>
										{user}
									</CommandItem>
								))}
							</CommandGroup>
						</CommandList>
					</Command>
				</PopoverContent>
			</Popover>

			{/* Organization Select (only when super admin) */}
			{isSuperAdmin && (
				<Select
					value={selectedOrganization || "All"}
					onValueChange={(value: string) => {
						setSelectedOrganization(value === "All" ? "" : value)
					}}
				>
					<SelectTrigger className="max-w-[300px] h-10">
						<SelectValue placeholder="Select organization" />
					</SelectTrigger>
					<SelectContent>
						<SelectItem key="all" value="All">
							All Organizations
						</SelectItem>
						{organizations.map((org) => (
							<SelectItem key={org} value={org}>
								{org}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
			)}
		</div>
	)
}

export default UsageFilters
