import useDocumentNaming from "@/hooks/useDocumentNaming"
import { DocumentRole, type ProjectDocumentMetadata } from "@/types"
import type { FilterData } from "@/types/table"
import {
	type ColumnDef,
	type ColumnFiltersState,
	type SortingState,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table"
import { useMemo, useState } from "react"

export function useDocumentsTable(
	documents: ProjectDocumentMetadata[],
	documentRole: DocumentRole,
	subjectIds?: string[],
	priorArtIds?: string[],
) {
	const { getDocumentName } = useDocumentNaming()

	const columns: ColumnDef<ProjectDocumentMetadata>[] = [
		{
			id: "subjectIds",
			accessorKey: "subjectIds",
			header: "Subjects",
			enableSorting: false,
			filterFn: (row, columnId, filterValue) => {
				const rowSubjectIds = row.getValue<string[]>(columnId)
				if (!Array.isArray(rowSubjectIds) || !Array.isArray(filterValue)) return false

				return filterValue.every((selectedSubjectId) =>
					rowSubjectIds.includes(selectedSubjectId),
				)
			},
		},
		{
			id: "priorArtIds",
			accessorKey: "priorArtIds",
			header: "Prior Arts",
			enableSorting: false,
			filterFn: (row, columnId, filterValue) => {
				const rowPriorArtIds = row.getValue<string[]>(columnId)
				if (!Array.isArray(rowPriorArtIds) || !Array.isArray(filterValue)) return false

				return filterValue.every((selectedPriorArtId) =>
					rowPriorArtIds.includes(selectedPriorArtId),
				)
			},
		},
		{
			id: "nickname",
			accessorKey: "nickname",
			header: "Nickname",
			enableSorting: true,
		},
		{
			id: "tags",
			header: "Tags",
			accessorKey: "tags",
			filterFn: (row, columnId, filterValue) => {
				const rowTags = row.getValue<string[]>(columnId)
				if (!Array.isArray(rowTags) || !Array.isArray(filterValue)) return false

				return filterValue.every((selectedTag) => rowTags.includes(selectedTag))
			},
		},
		{
			id: "number",
			accessorKey: "number",
			header: "Number",
			enableSorting: true,
		},
		{
			id: "title",
			accessorKey: "title",
			header: "Title",
			enableSorting: true,
		},
		{
			id: "publicationDate",
			accessorKey: "publicationDate",
			header: "Publication Date",
			enableSorting: true,
		},
		{
			id: "priorityDate",
			accessorKey: "priorityDate",
			header: "Priority Date",
			enableSorting: true,
			sortingFn: (rowA, rowB) => {
				const dateA =
					rowA.original.patent?.originalPriorityDate ||
					rowA.original.patent?.filingDate ||
					""
				const dateB =
					rowB.original.patent?.originalPriorityDate ||
					rowB.original.patent?.filingDate ||
					""
				return dateA.localeCompare(dateB)
			},
		},
		{
			id: "createdBy",
			accessorKey: "createdBy",
			header: "Created By",
			enableSorting: true,
		},
		{
			id: "type",
			accessorKey: "type",
			enableHiding: true,
			enableSorting: true,
			header: "Type",
		},
		{
			id: "note",
			accessorKey: "note",
			header: "Note",
			enableSorting: false,
			enableHiding: true,
		},
	]

	// Build filters conditionally based on documentRole.
	const filters: Record<string, FilterData> = useMemo(() => {
		const tagValues = Array.from(
			new Set(documents.flatMap((doc) => (Array.isArray(doc.tags) ? doc.tags : []))),
		).map((tag) => ({ label: tag, value: tag }))

		const subjectValues = Array.from(
			subjectIds?.map((subjectId) => ({
				label: getDocumentName(subjectId),
				value: subjectId,
			})) ?? [],
		)

		const priorArtValues = Array.from(
			priorArtIds?.map((priorArtId) => ({
				label: getDocumentName(priorArtId),
				value: priorArtId,
			})) ?? [],
		)

		// Base filters (always available)
		const baseFilters: Record<string, FilterData> = {
			tags: {
				label: "Tags",
				values: tagValues,
			},
			type: {
				label: "Type",
				values: [
					{ label: "Patent", value: "PATENT" },
					{ label: "Non-Patent", value: "NPL" },
				],
			},
		}

		// If the document role is subject, add a prior art filter.
		if (documentRole === DocumentRole.SUBJECT) {
			baseFilters.priorArtIds = {
				label: "Charted Documents",
				values: priorArtValues,
			}
		}

		// If the document role is prior art or infringement, add a subjects filter.
		if (
			documentRole === DocumentRole.PRIOR_ART ||
			documentRole === DocumentRole.INFRINGEMENT
		) {
			baseFilters.subjectIds = {
				label: "Subjects",
				values: subjectValues,
			}
		}

		return baseFilters
	}, [documents, documentRole, getDocumentName])

	const [multiSorting, setMultiSorting] = useState<SortingState>([])
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

	const table = useReactTable({
		data: documents as ProjectDocumentMetadata[],
		columns,
		state: {
			sorting: multiSorting,
			columnFilters,
		},
		onSortingChange: setMultiSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
	})

	return {
		table,
		filters,
		multiSorting,
		setMultiSorting,
		columnFilters,
		setColumnFilters,
	}
}
