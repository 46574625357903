export const QUERY_KEYS = {
	// Admin related keys
	admin: {
		all: ["admin"] as const,
		users: (orgId?: string) => ["admin", orgId, "users"] as const,
		overview: (orgId?: string) => ["admin", orgId, "overview"] as const,
		usage: (orgId?: string) => ["admin", orgId, "usage"] as const,
		billing: (orgId?: string) => ["admin", orgId, "billing"] as const,
		sessions: (orgId?: string) => ["admin", orgId, "sessions"] as const,
	},

	// Super admin related keys
	superAdmin: {
		all: ["superAdmin"] as const,
		overview: ["superAdmin", "overview"] as const,
		organizations: ["superAdmin", "organizations"] as const,
		billing: ["superAdmin", "billing"] as const,
		sessions: ["superAdmin", "sessions"] as const,
		usage: ["superAdmin", "usage"] as const,
		organizationDetails: (orgId?: string) =>
			["superAdmin", "organizationDetails", orgId] as const,
	},

	// Project related keys
	project: {
		all: ["project"] as const,
		metadata: (projectId: string) => ["project", projectId, "metadata"],
		usage: (projectId: string) => ["project", projectId, "usage"],
		settings: (projectId: string) => ["project", projectId, "settings"],
		sharing: {
			sharedUsers: (projectId: string) => ["project", projectId, "sharing", "sharedUsers"],
			shareableUsers: (projectId: string) => [
				"project",
				projectId,
				"sharing",
				"shareableUsers",
			],
		},
		documents: {
			all: (projectId: string) => ["project", projectId, "documents"],
			subject: (projectId: string) => ["project", projectId, "subject"],
			priorArt: (projectId: string) => ["project", projectId, "priorArt"],
			officeActions: (projectId: string) => ["project", projectId, "officeActions"],
			context: (projectId: string) => ["project", projectId, "context"],
			infringement: (projectId: string) => ["project", projectId, "infringement"],
		},
		charts: {
			matrix: (projectId: string) => ["project", projectId, "matrix"] as const,
			active: (projectId: string, subjectId: string) => [
				"project",
				projectId,
				"subject",
				subjectId,
				"charts",
				"active",
			],
			inactive: (projectId: string, subjectId: string) => [
				"project",
				projectId,
				"subject",
				subjectId,
				"charts",
				"archive",
			],
			suggestedCombinationCharts: (projectId: string, subjectId: string) =>
				[
					"project",
					projectId,
					"subject",
					subjectId,
					"suggested_103_combination_charts",
				] as const,
			chartSummaryData: (projectId: string, subjectId: string, chartId?: string) =>
				["project", projectId, "subject", subjectId, "charts", "summary", chartId] as const,
			chartMetadata: (projectId: string, subjectId: string, chartId: string) =>
				["project", projectId, "subject", subjectId, "chart", chartId] as const,
			limitationChartData: (
				projectId: string,
				subjectId: string,
				chartId: string,
				limitationId: string,
			) =>
				[
					"project",
					projectId,
					"subject",
					subjectId,
					"chart",
					chartId,
					"limitationChartData",
					limitationId,
				] as const,
		},

		document: {
			full: (projectId: string, documentId: string) =>
				["project", projectId, "document", documentId, "full"] as const,
			figures: (projectId: string, documentId: string) =>
				["project", projectId, "document", documentId, "figures"] as const,
			pdf: (projectId: string, documentId: string) =>
				["project", projectId, "document", documentId, "pdf"] as const,
			context: (projectId: string, documentId: string) =>
				["project", projectId, "document", documentId, "context"] as const,
		},

		subject: {
			claims: (projectId: string, subjectId: string) =>
				["project", projectId, "subject", subjectId, "claims"] as const,
			claimStrengths: (projectId: string, subjectId: string) =>
				["project", projectId, "subject", subjectId, "claimStrengths"] as const,
			claimLimitationGroups: (projectId: string, subjectId: string) =>
				["project", projectId, "subject", subjectId, "claimLimitationGroups"] as const,
		},
		chat: {
			metadata: (projectId: string | null) => ["chat", "metadata", projectId],
			full: (chatId: string) => ["chat", chatId],
			polling: (chatId: string) => ["chat", "polling", chatId],
		},
		cover: {
			summaries: (projectId: string, subjectId: string) =>
				["project", projectId, "cover", subjectId, "summaries"] as const,
			documentChunks: (projectId: string, documentId: string) =>
				["project", projectId, "cover", "documentChunks", documentId] as const,
			analysis102: (projectId: string, subjectId: string) =>
				["project", projectId, "cover", subjectId, "analysis102"] as const,
			analysis103: (projectId: string, subjectId: string) =>
				["project", projectId, "cover", subjectId, "analysis103"] as const,
			analysis112: (projectId: string, subjectId: string) =>
				["project", projectId, "cover", subjectId, "analysis112"] as const,
		},
	},

	projects: {
		active: () => ["projects", "active"],
		archived: () => ["projects", "archived"],
	},
} as const

export const PARTIAL_INVALIDITY_KEYS = {
	projectChartsData: (projectId: string) => ["project", projectId, "charts"] as const,
	projectDocuments: (projectId: string) => ["project", projectId, "documents"] as const,
	project: (projectId: string) => ["project", projectId] as const,
}

// TODO set up mutation keys later, not necessaery in short term
export const MUTATION_KEYS = {
	admin: {
		user: {
			create: () => ["admin", "user", "create"] as const,
			update: () => ["admin", "user", "update"] as const,
			delete: () => ["admin", "user", "delete"] as const,
			toggleStatus: () => ["admin", "user", "toggleStatus"] as const,
		},
	},

	document: {
		update: () => ["document", "update"] as const,
		chunk: {
			update: () => ["document", "chunk", "update"] as const,
			add: () => ["document", "chunk", "add"] as const,
			delete: () => ["document", "chunk", "delete"] as const,
		},
		metadata: {
			update: () => ["document", "metadata", "update"] as const,
		},
		delete: () => ["document", "delete"] as const,
		prune: () => ["document", "prune"] as const,
		context: {
			update: () => ["project", "context", "update"] as const,
		},
	},

	project: {
		create: () => ["project", "create"] as const,
		update: (projectId?: string) => ["project", "update", projectId] as const,
		updateDetails: (projectId?: string) =>
			["project", "updateDetails", projectId] as const,
		updateStatus: (projectId?: string) => ["project", "updateStatus", projectId] as const,
		rename: (projectId?: string) => ["project", "rename", projectId] as const,
		claims: {
			update: () => ["project", "claims", "update"] as const,
			groups: {
				create: () => ["project", "claims", "groups", "create"] as const,
				update: () => ["project", "claims", "groups", "update"] as const,
				delete: () => ["project", "claims", "groups", "delete"] as const,
			},
		},
		settings: {
			update: () => ["project", "settings", "update"] as const,
		},
		users: {
			add: () => ["project", "users", "add"] as const,
			remove: () => ["project", "users", "remove"] as const,
		},
		delete: () => ["project", "delete"] as const,
	},

	organization: {
		create: () => ["organization", "create"] as const,
		update: () => ["organization", "update"] as const,
		delete: () => ["organization", "delete"] as const,
	},

	cover: {
		generateSummaries: () => ["cover", "generateSummaries"] as const,
		generate102Analysis: () => ["cover", "generate102Analysis"] as const,
		generate103Analysis: () => ["cover", "generate103Analysis"] as const,
		generate112Analysis: () => ["cover", "generate112Analysis"] as const,
	},
} as const
