import { api } from "@/api"
import type { FilterOptions } from "@/features/admin/components/UsageFilterSelects"
import type { AdminUsage } from "@/types"
import type { AdminDocumentSummary, AdminProjectSummary } from "@/types/admin"
import { DEFAULT_QUERY_OPTIONS, QUERY_KEYS } from "@/utils/query"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"

/**
 * Hook to fetch and filter the admin usage data.
 *
 * @param orgId - The organization id.
 * @param filters - The filters object containing `dateRange` and `users`.
 * @returns An object with the filtered admin usage data, as well as loading flags.
 */
const useAdminUsage = (
	isSuperAdmin: boolean,
	orgId: string,
	filters?: Pick<FilterOptions, "dateRange" | "users">,
) => {
	const queryKey = isSuperAdmin
		? QUERY_KEYS.superAdmin.usage
		: QUERY_KEYS.admin.usage(orgId)

	const queryFn = () => {
		if (isSuperAdmin) {
			return api.getSuperAdminUsage(orgId)
		}
		return api.getAdminUsage(orgId)
	}

	const adminUsage = useQuery<AdminUsage>({
		queryKey: queryKey,
		queryFn: queryFn,
		enabled: !!orgId,
		...DEFAULT_QUERY_OPTIONS,
	})

	// Filter projects
	const filteredProjects = useMemo(() => {
		if (!adminUsage.data) return []
		const projects = adminUsage.data.projects as AdminProjectSummary[]
		if (!projects) return []
		// If no filters are selected then return all projects.
		if ((!filters?.users || filters.users.length === 0) && !filters?.dateRange) {
			return projects
		}

		if (Array.isArray(projects)) {
			return projects.filter((project) => {
				// Check date range filter.
				if (filters?.dateRange) {
					if (
						!project.createdAt ||
						new Date(project.createdAt) < filters.dateRange.from ||
						new Date(project.createdAt) > filters.dateRange.to
					) {
						return false
					}
				}
				// Check users filter.
				if (filters?.users && filters.users.length > 0) {
					if (!filters.users.includes(project.createdBy || "Unknown")) {
						return false
					}
				}
				return true
			})
		}
		return projects
	}, [adminUsage.data, filters])

	// Filter documents (1st element)
	const filteredDocuments = useMemo(() => {
		if (!adminUsage.data) return []
		const documents = adminUsage.data.documents
		// If no filters are selected then return all documents.
		if ((!filters?.users || filters.users.length === 0) && !filters?.dateRange) {
			return documents
		}
		return documents.filter((document) => {
			// Check date range filter.
			if (filters?.dateRange) {
				if (
					!document.createdAt ||
					new Date(document.createdAt) < filters.dateRange.from ||
					new Date(document.createdAt) > filters.dateRange.to
				) {
					return false
				}
			}
			// Check users filter.
			if (filters?.users && filters.users.length > 0) {
				if (!filters.users.includes(document.createdBy || "Unknown")) {
					return false
				}
			}
			return true
		})
	}, [adminUsage.data, filters])

	return {
		projects: filteredProjects as AdminProjectSummary[],
		documents: filteredDocuments as AdminDocumentSummary[],
		isLoading: adminUsage.isLoading,
		isFetching: adminUsage.isFetching,
		isLoadingUsage: adminUsage.isLoading || adminUsage.isFetching,
		isError: adminUsage.isError,
	}
}

export { useAdminUsage }
