/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import MultiDocumentSelect from "@/components/documents/MultiDocumentSelect"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { LoadingButton } from "@/components/ui/loading-button"
import { useChunkToLimitationUpdates } from "@/features/charts/hooks"
import { ChartColor, type ProjectDocumentMetadata } from "@/types"
import type React from "react"
import { useState } from "react"
import ColorSelect from "../selects/ColorSelect"

interface PruneInvalidityColorModalProps {
	open: boolean
	onOpenChange: (open: boolean) => void
	documents: ProjectDocumentMetadata[]
}

/**
 * @description Prune invalidity charts by color
 * @param {boolean} open - Whether the modal is open.
 * @param {function} onOpenChange - Function to close the modal.
 * @param {function} fetchElementChartPageData - Function to fetch the element chart page data.
 */
const PruneInvalidityColorModal: React.FC<PruneInvalidityColorModalProps> = ({
	open,
	onOpenChange,
	documents,
}) => {
	const { pruneChunkToLimitationByColor } = useChunkToLimitationUpdates()
	const [selectedColors, setSelectedColors] = useState<string[]>([
		ChartColor.YELLOW,
		ChartColor.RED,
	])
	const [selectedReferences, setSelectedReferences] = useState<string[]>([])
	const [isPruneLoading, setIsPruneLoading] = useState<boolean>(false)

	const resetModal = () => {
		setSelectedColors([ChartColor.YELLOW, ChartColor.RED])
		setSelectedReferences([])
	}

	const handleConfirmPrune = async () => {
		try {
			setIsPruneLoading(true)

			await pruneChunkToLimitationByColor({
				documentIds: selectedReferences,
				colors: selectedColors,
			})
		} catch (error) {
			console.error("Error pruning invalidity citations by color:", error)
		} finally {
			setIsPruneLoading(false)
			onClose()
		}
	}

	const onClose = () => {
		resetModal()
		onOpenChange(false)
	}

	return (
		<Dialog open={open} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[600px]">
				<DialogHeader>
					<DialogTitle>Prune citations by color</DialogTitle>
					<DialogDescription>
						Remove all citations of the selected color from the selected reference(s). This
						action cannot be undone.
					</DialogDescription>
				</DialogHeader>

				<div className="flex space-x-2">
					<ColorSelect
						colors={[ChartColor.YELLOW, ChartColor.RED]}
						selectedColors={selectedColors}
						setSelectedColors={setSelectedColors}
						disabled={isPruneLoading}
					/>
					<MultiDocumentSelect
						handleChange={setSelectedReferences}
						disabled={isPruneLoading}
						documents={documents}
					/>
				</div>

				<DialogFooter>
					<div className="flex items-center justify-end gap-2">
						<Button
							variant="outline"
							onClick={() => onOpenChange(false)}
							disabled={isPruneLoading}
						>
							Cancel
						</Button>
						<LoadingButton
							variant="destructive"
							disabled={!selectedColors.length || isPruneLoading}
							onClick={handleConfirmPrune}
							loading={isPruneLoading}
						>
							{isPruneLoading ? "Pruning..." : "Prune"}
						</LoadingButton>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default PruneInvalidityColorModal
