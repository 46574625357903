import { CitationFormat } from "@/types"
import { create } from "zustand"

interface ProjectStore {
	showAssertedClaims: boolean
	draftingClaimID: string | null

	selectedCitationFormat: CitationFormat

	// citation format
	updateSelectedCitationFormat: (citationFormat: CitationFormat) => void
	// drafting claim
	updateDraftingClaimID: (claimID: string | null) => void
	updateShowAssertedClaims: (showAssertedClaims: boolean) => void
	resetProjectStore: () => void
}

const initialState: Omit<
	ProjectStore,
	| "updateDraftingClaimID"
	| "updateSelectedCitationFormat"
	| "updateShowAssertedClaims"
	| "resetProjectStore"
> = {
	draftingClaimID: null,
	showAssertedClaims: false,
	selectedCitationFormat: CitationFormat.DEFAULT,
}

const useProjectStore = create<ProjectStore>((set) => ({
	...initialState,

	updateSelectedCitationFormat: (citationFormat: CitationFormat) =>
		set(() => ({ selectedCitationFormat: citationFormat })),

	updateDraftingClaimID: (claimID: string | null) =>
		set(() => ({ draftingClaimID: claimID })),

	updateShowAssertedClaims: (showAssertedClaims: boolean) =>
		set(() => ({ showAssertedClaims })),

	resetProjectStore: () => set(() => ({ ...initialState })),
}))

export default useProjectStore
