import { useChatContext } from "@/context/ChatContext"
import { isChatUnread } from "@/utils/messageUtils"
import { invalidateChatQueries } from "@/utils/query/invalidation"
import { useQueryClient } from "@tanstack/react-query"
import { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { useChat } from "./useChat"

/**
 * Type definition for the hook's return value
 */
interface ChatNotificationsResult {
	unreadMessagesCount: number
}

/**
 * Hook that provides information about unread chat messages
 * and other notification-related data for the chat system
 */
export function useChatNotifications(): ChatNotificationsResult {
	const { chatsMetadata } = useChat()
	const { activeChatId, isPanelChatOpen } = useChatContext()
	const queryClient = useQueryClient()
	const { projectId } = useParams()

	// Set up an interval to refresh chat metadata
	useEffect(() => {
		// Refresh chat metadata every 10 seconds
		const intervalId = setInterval(() => {
			if (projectId) {
				invalidateChatQueries(queryClient, projectId)
			}
		}, 10000)

		// Clean up on unmount
		return () => clearInterval(intervalId)
	}, [queryClient, projectId])

	// Calculate unread messages count using memoization
	const unreadMessagesCount = useMemo(() => {
		return (
			chatsMetadata?.filter((chat) => {
				// If this is the active chat and it's open, don't count it as unread
				if (chat.id === activeChatId && isPanelChatOpen) return false

				return isChatUnread(chat)
			}).length || 0
		)
	}, [chatsMetadata, activeChatId, isPanelChatOpen])

	return {
		unreadMessagesCount,
	}
}

export default useChatNotifications
