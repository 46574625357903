import FigureUrlsDisplay from "@/components/images/FigureUrlsDisplay"
import FiguresModal from "@/components/images/FiguresModal"
import HighlightedText from "@/components/info/HighlightedText"
import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import {
	Table,
	TableBody,
	TableCellCondensed,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { useProjectContext } from "@/context/ProjectContext"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import { cn } from "@/lib/utils"
import { CitationFormat } from "@/types"
import type { CitationSearchResult } from "@/types/invalidity"
import { formatDocumentChunkLocation, parseHtml } from "@/utils"
import { EyeOpenIcon } from "@radix-ui/react-icons"
import { useState } from "react"

interface SearchResultsTableProps {
	searchResults: CitationSearchResult[]
	sentSearchQuery: string
	height?: string
}

/**
 * @description Search results table component
 */
export default function SearchResultsTable({
	searchResults,
	sentSearchQuery,
	height,
}: SearchResultsTableProps) {
	const { getDocumentName } = useDocumentNaming()
	const { setDocumentViewerDocumentId, setDocumentViewerChunkId } = useProjectContext()
	// local states for figure modal
	const [showFiguresModal, setShowFiguresModal] = useState(false)
	const [selectedDocumentId, setSelectedDocumentId] = useState<string>("")
	const [selectedFigureUrl, setSelectedFigureUrl] = useState<string | null>(null)

	const handleFigureClick = (imageUrl: string, documentId: string) => {
		// Make sure we're passing a valid string for documentId
		if (documentId) {
			setSelectedDocumentId(documentId)
			setSelectedFigureUrl(imageUrl)
			setShowFiguresModal(true)
		}
	}

	const handlePreviewClick = async (documentId: string, citationId: string) => {
		setDocumentViewerDocumentId(documentId)
		setDocumentViewerChunkId(citationId)
	}

	return (
		<>
			{searchResults.length > 0 ? (
				<ScrollArea
					className={cn("flex-grow border rounded-md mx-2 overflow-x-auto", height)}
				>
					<Table className="px-4">
						<TableHeader>
							<TableRow>
								<TableHead>Document</TableHead>
								<TableHead>Location</TableHead>
								<TableHead>Text</TableHead>
								{/* <TableHead>Actions</TableHead> */}
							</TableRow>
						</TableHeader>
						<TableBody>
							{searchResults.map((result, _) => {
								return (
									<TableRow key={result.id}>
										<TableCellCondensed>{getDocumentName(result.documentId)}</TableCellCondensed>
										<TableCellCondensed className="whitespace-nowrap">
											<div className="w-[75px] shrink-0 text-sm whitespace-pre-line">
												{formatDocumentChunkLocation(result.location, CitationFormat.FULL).map(
													(line) => (
														<div key={result.id}>{line}</div>
													),
												)}
											</div>
										</TableCellCondensed>
										<TableCellCondensed>
											<div className="flex items-center">
												<div
													className="w-4 flex-shrink-0 mr-3 self-stretch min-h-[45px] rounded"
													style={{
														backgroundColor: result.color || "lightgray",
													}}
												/>
												<div>
													<div>
														{result.html ? (
															<div className="overflow-x-auto">
																{result.html ? (
																	<div>{parseHtml(result.html, {}, sentSearchQuery)}</div>
																) : (
																	<HighlightedText
																		text={result.text}
																		highlight={sentSearchQuery}
																		highlightColor="#e1e9ff"
																	/>
																)}
															</div>
														) : (
															<HighlightedText
																text={result.text}
																highlight={sentSearchQuery}
																highlightColor="#e1e9ff"
															/>
														)}
													</div>
													{result.figureUrls && (
														<FigureUrlsDisplay
															figureUrls={result.figureUrls}
															onFigureClick={(url) => handleFigureClick(url, result.documentId)}
														/>
													)}
												</div>
											</div>
										</TableCellCondensed>
										<TableCellCondensed>
											<div className="flex items-center space-x-2">
												{/* <Tooltip>
													<TooltipTrigger asChild>
														<CopyToClipboardButton text={result.text} />
													</TooltipTrigger>
													<TooltipContent>
														<p>Copy to Clipboard</p>
													</TooltipContent>
												</Tooltip> */}
												<Tooltip>
													<TooltipTrigger asChild>
														<Button
															variant="ghost"
															size="icon"
															onClick={() => handlePreviewClick(result.documentId, result.id)}
														>
															<EyeOpenIcon className="h-4 w-4" />
														</Button>
													</TooltipTrigger>
													<TooltipContent>
														<p>View in Document</p>
													</TooltipContent>
												</Tooltip>
											</div>
										</TableCellCondensed>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</ScrollArea>
			) : (
				<div className="text-center p-8">
					<h3 className="text-lg font-semibold mb-2">No results found.</h3>
					<p>Expand your search to include more sources or try a different query.</p>
				</div>
			)}

			<FiguresModal
				documentId={selectedDocumentId}
				open={showFiguresModal}
				onOpenChange={(open) => setShowFiguresModal(open)}
				selectedFigureUrl={selectedFigureUrl}
			/>
		</>
	)
}
