/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { ProjectPage } from "@/components"
import DocumentContent from "@/components/documents/DocumentContent"
import { useDocumentNaming } from "@/hooks"
import { PAGE_DISPLAY_NAMES, PageName } from "@/types/pages"
import type React from "react"
import { useParams } from "react-router-dom"

/**
 * @description User document page, displays selected document details
 */
const ProjectDocumentPage: React.FC = () => {
	const { documentId } = useParams()
	const { getDocumentName } = useDocumentNaming()
	return (
		<ProjectPage
			pageName={`${PAGE_DISPLAY_NAMES[PageName.ProjectDocuments]} - ${getDocumentName(documentId)}`}
		>
			<div className="w-full h-full overflow-hidden flex flex-col">
				<DocumentContent documentId={documentId} />
			</div>
		</ProjectPage>
	)
}

export default ProjectDocumentPage
