import { api } from "@/api"
import { chatApi } from "@/api"
import { useChatContext } from "@/context/ChatContext"
import { useProjectSubjectDocuments } from "@/features/documents/hooks/useProjectSubjectDocuments"
import type { DialogueResponse } from "@/types"
import {
	type Chat,
	type ChatMetadata,
	type ChatSessionStatusSchema,
	DialogueRole,
} from "@/types/chat"
import { QUERY_KEYS, invalidateChatQueries } from "@/utils"
import { getDirectiveNameFromMessage } from "@/utils/messageUtils"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"

export function useChat() {
	const POLLING_FREQUENCY = 1000 // 1 second
	const { projectId } = useParams()
	const {
		activeChatId,
		setActiveChatId,
		isPanelChatOpen,
		setIsAndyResponding,
		pollingSessionId,
		setPollingSessionId,
		thinkingString,
		setThinkingString,
		pollingCountRef,
		pollingCountMax,
		messages,
		setMessages,
	} = useChatContext()
	const abortControllerRef = useRef<AbortController | null>(null)
	const queryClient = useQueryClient()
	const { subjectDocuments } = useProjectSubjectDocuments()
	const [isInitialLoad, setIsInitialLoad] = useState(true)

	// We need to mark isAndyResponding earlier than React re-renders a non-null pollingSessionId
	useEffect(() => {
		if (!pollingSessionId) {
			setIsAndyResponding(false)
			setThinkingString("Thinking")
		}
	}, [pollingSessionId])

	// Helper function to mark chat as seen
	const markChatAsSeen = (chatId: string) => {
		if (chatId && chatId !== "new") {
			chatApi.postUpdateChat(chatId, null, null, true, false)
		}
	}

	useEffect(() => {
		return () => {
			// Reset polling state when component unmounts
			setPollingSessionId(null)
			pollingCountRef.current = 0
		}
	}, [])

	const chatsMetadataQuery = useQuery({
		queryKey: QUERY_KEYS.project.chat.metadata(projectId),
		queryFn: () => api.postChatsMetadata({ project_id: projectId }),
		enabled: !!projectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	const fullChatQuery = useQuery({
		queryKey: QUERY_KEYS.project.chat.full(activeChatId),
		queryFn: () => api.postFullChat(projectId, activeChatId),
		enabled: Boolean(projectId) && Boolean(activeChatId) && activeChatId !== "new",
		...DEFAULT_QUERY_OPTIONS,
	})

	// Load chat history when fullChatQuery data changes or activeChatId changes
	useEffect(() => {
		if (fullChatQuery.data && activeChatId !== "new") {
			const chatData = fullChatQuery.data as Chat

			setMessages(chatData.messages)

			setIsInitialLoad(false)
		} else if (activeChatId === "new" && isInitialLoad) {
			// Clear messages when starting a new chat
			setMessages([])
			setIsInitialLoad(false)
		}
	}, [fullChatQuery.data, activeChatId, isInitialLoad])

	const updateChatSessionMutation = useMutation({
		mutationFn: ({
			chatId,
			name,
			status,
		}: { chatId: string; name?: string; status?: ChatSessionStatusSchema }) =>
			chatApi.postUpdateChat(chatId, name ?? null, status ?? "ACTIVE", null, true),
		onSuccess: (_, { chatId }) => {
			invalidateChatQueries(queryClient, projectId, chatId)
		},
		onError: (error) => {
			console.error("Failed to update chat name:", error)
		},
	})

	const _responsePollingQuery = useQuery({
		queryKey: QUERY_KEYS.project.chat.polling(pollingSessionId || ""),
		queryFn: async () => {
			pollingCountRef.current += 1

			try {
				const response: DialogueResponse[] =
					await chatApi.pollUnseenMessages(pollingSessionId)
				if (response && response.length > 0) {
					// Use the function form of setState to avoid stale closure issues
					setMessages((prevMessages) => {
						const newMessages = [
							...prevMessages,
							...response.map((msg) => ({
								...msg,
							})),
						]
						return newMessages
					})
					// messages state is asynchronously updated, so get the last message from the response
					const lastTask = response[response.length - 1].task

					if ("toolVerb" in lastTask) {
						const capitalizedAction =
							lastTask.toolVerb.charAt(0).toUpperCase() + lastTask.toolVerb.slice(1)
						setThinkingString(capitalizedAction)
					}

					const isActiveChatSession = activeChatId === pollingSessionId

					// If this is the active chat session and the chat is open, mark as seen
					if (isActiveChatSession && isPanelChatOpen) {
						markChatAsSeen(pollingSessionId)
					}
				}

				// If we've reached the max polling count or received a valid response, stop polling
				if (
					pollingCountRef.current >= pollingCountMax ||
					(response &&
						response.length > 0 &&
						response.some(
							(message) => getDirectiveNameFromMessage(message) === "prompt_user",
						))
				) {
					setPollingSessionId(null)
				}

				return response
			} catch (error) {
				console.error("Error in polling query function:", error)
				setPollingSessionId(null)
				throw error
			}
		},
		enabled: !!pollingSessionId,
		refetchInterval: () => {
			// Only continue polling if we haven't reached the max count and are still responding
			if (pollingCountRef.current >= pollingCountMax) {
				return false
			}
			return POLLING_FREQUENCY // 1 seconds between polls
		},
		refetchIntervalInBackground: true,
		staleTime: 0,
		gcTime: 5 * 60 * 1000,
		retry: 0,
		retryDelay: 0,
	})

	const sendMessage = async (message: string, selectedDocumentIds: string[]) => {
		if (message.trim()) {
			// Reset polling state
			pollingCountRef.current = 0
			setPollingSessionId(null)

			// Setup abort controller
			abortControllerRef.current = new AbortController()
			const signal = abortControllerRef.current.signal

			const subjectId = subjectDocuments[0].id

			try {
				const payload = {
					query: message,
					project_id: projectId,
					subject_id: subjectId,
					document_ids: selectedDocumentIds,
					...(activeChatId && activeChatId !== "new" ? { session_id: activeChatId } : {}),
				}

				// Add user message
				setMessages((prevMessages: DialogueResponse[]) => [
					...prevMessages,
					{
						id: "",
						role: DialogueRole.USER,
						loading: false,
						createdAt: Date.now().toString(),
						task: {
							directiveName: "prompt_assistant",
							parameters: [],
							content: message,
						},
					} as DialogueResponse,
				])

				// Start streaming phase
				let newSessionId: string | null = null
				let streamingComplete = false

				// 1. Stream the message until it's done
				await chatApi.streamAndyResponse(
					payload,
					signal,
					// Session ID handler
					(sessionId) => {
						if (!sessionId) {
							console.error("Received empty session ID")
							return
						}

						newSessionId = sessionId

						// Always update the activeChatId with the new session ID
						setActiveChatId(sessionId)
						queryClient.invalidateQueries({
							queryKey: QUERY_KEYS.project.chat.metadata(projectId),
						})
					},
					// Text chunk handler
					(text) => {
						setMessages((prev) => {
							if (!prev || prev.length === 0) return prev

							const newMessages = [...prev]
							const lastMessage = newMessages[newMessages.length - 1]
							if (lastMessage?.role === DialogueRole.ASSISTANT) {
								if ("content" in lastMessage.task) {
									lastMessage.task.content = text || ""
								}
							}
							return newMessages
						})
					},
					// Completion handler
					() => {
						streamingComplete = true

						// Mark the message as no longer loading
						setMessages((prev) => {
							if (!prev || prev.length === 0) return prev

							const newMessages = [...prev]
							return newMessages
						})

						// 2. Only start polling after streaming is complete
						const sessionIdToUse =
							newSessionId || (activeChatId !== "new" ? activeChatId : null)

						if (sessionIdToUse) {
							setPollingSessionId(sessionIdToUse)
							pollingCountRef.current = 0

							// Make sure activeChatId is updated with the final session ID
							if (newSessionId && activeChatId !== newSessionId) {
								setActiveChatId(newSessionId)
							}
						} else {
							console.error("No valid session ID for polling")
						}
					},
				)

				// If streaming didn't complete normally, make sure we clean up
				if (!streamingComplete) {
					setPollingSessionId(null)
				}
			} catch (error: any) {
				if (error.name !== "AbortError") {
					console.error("Error fetching response:", error)

					// Update the AI response message to show the error
					setMessages((prev) => {
						if (!prev || prev.length === 0) return prev

						const lastMessage = prev[prev.length - 1]
						if (lastMessage?.role === DialogueRole.ASSISTANT) {
							return [
								...prev.slice(0, -1),
								{
									...lastMessage,
									content: "Sorry, there was an error processing your request.",
								},
							]
						}
						return prev
					})
				}

				// Clean up state
				setPollingSessionId(null)
			}
		}
	}

	// Function to reset the chat state (for new chats)
	const resetChat = () => {
		setMessages([])
		setIsInitialLoad(true)
	}

	return {
		messages,
		setMessages,
		sendMessage,
		chatsMetadata: chatsMetadataQuery.data as ChatMetadata[],
		chatsMetadataLoading: chatsMetadataQuery.isLoading,
		fullChat: fullChatQuery.data as Chat,
		fullChatLoading: fullChatQuery.isLoading,
		updateChatSession: updateChatSessionMutation.mutate,
		updateChatPending: updateChatSessionMutation.isPending,
		markChatAsSeen,
		resetChat,
		thinkingString,
		setThinkingString,
		pollingSessionId,
	}
}

export default useChat
