import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItemWithLabel } from "@/components/ui/radio-group"
import { XIcon } from "lucide-react"
import { useState } from "react"

interface SiteListManagerProps {
	whitelistedSites: string[]
	blacklistedSites: string[]
	onWhitelistedSitesChange: (sites: string[]) => void
	onBlacklistedSitesChange: (sites: string[]) => void
	disabled?: boolean
}

const SiteListManager: React.FC<SiteListManagerProps> = ({
	whitelistedSites,
	blacklistedSites,
	onWhitelistedSitesChange,
	onBlacklistedSitesChange,
	disabled = false,
}) => {
	const [newSite, setNewSite] = useState("")
	const [listType, setListType] = useState<"whitelist" | "blacklist">("whitelist")

	const handleAddSite = () => {
		if (!newSite.trim()) return

		if (listType === "whitelist") {
			if (!whitelistedSites.includes(newSite)) {
				onWhitelistedSitesChange([...whitelistedSites, newSite.trim()])
				// Remove from blacklist if present
				if (blacklistedSites.includes(newSite)) {
					onBlacklistedSitesChange(blacklistedSites.filter((site) => site !== newSite))
				}
			}
		} else {
			if (!blacklistedSites.includes(newSite)) {
				onBlacklistedSitesChange([...blacklistedSites, newSite.trim()])
				// Remove from whitelist if present
				if (whitelistedSites.includes(newSite)) {
					onWhitelistedSitesChange(whitelistedSites.filter((site) => site !== newSite))
				}
			}
		}
		setNewSite("")
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			e.preventDefault()
			handleAddSite()
		}
	}

	const handleRemoveWhitelistedSite = (site: string) => {
		onWhitelistedSitesChange(whitelistedSites.filter((s) => s !== site))
	}

	const handleRemoveBlacklistedSite = (site: string) => {
		onBlacklistedSitesChange(blacklistedSites.filter((s) => s !== site))
	}

	return (
		<div className="space-y-4">
			<div className="space-y-1">
				<Label className="text-sm">Site List Type</Label>
				<RadioGroup
					value={listType}
					onValueChange={(value: "whitelist" | "blacklist") => setListType(value)}
					className="flex flex-row gap-4"
					disabled={disabled}
				>
					<RadioGroupItemWithLabel value="whitelist" label="Whitelist" />
					<RadioGroupItemWithLabel value="blacklist" label="Blacklist" />
				</RadioGroup>
			</div>

			<div className="flex gap-2">
				<Input
					value={newSite}
					onChange={(e) => setNewSite(e.target.value)}
					onKeyDown={handleKeyDown}
					placeholder="Enter site domain (e.g., example.com)"
					disabled={disabled}
					className="flex-1"
				/>
				<Button
					onClick={handleAddSite}
					disabled={disabled || !newSite.trim()}
					variant="outline"
				>
					Add
				</Button>
			</div>

			{whitelistedSites.length > 0 && (
				<div className="space-y-2">
					<Label>Whitelisted Sites</Label>
					<div className="space-y-2">
						{whitelistedSites.map((site) => (
							<div
								key={site}
								className="flex items-center justify-between bg-secondary/50 px-3 py-2 rounded-md"
							>
								<span>{site}</span>
								<Button
									variant="ghost"
									size="icon"
									onClick={() => handleRemoveWhitelistedSite(site)}
									disabled={disabled}
								>
									<XIcon className="h-4 w-4" />
								</Button>
							</div>
						))}
					</div>
				</div>
			)}

			{blacklistedSites.length > 0 && (
				<div className="space-y-2">
					<Label>Blacklisted Sites</Label>
					<div className="space-y-2">
						{blacklistedSites.map((site) => (
							<div
								key={site}
								className="flex items-center justify-between bg-secondary/50 px-3 py-2 rounded-md"
							>
								<span>{site}</span>
								<Button
									variant="ghost"
									size="icon"
									onClick={() => handleRemoveBlacklistedSite(site)}
									disabled={disabled}
								>
									<XIcon className="h-4 w-4" />
								</Button>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	)
}

export default SiteListManager
