import { Button } from "@/components/ui/button"
import { useChatContext } from "@/context/ChatContext"
import { useChat } from "@/hooks"
import { SendHorizontal } from "lucide-react"
import { ChatFooterLoadTab } from "./ChatFooterLoadTab"

interface PanelChatFooterProps {
	inputValue?: string
	onInputChange?: (value: string) => void
	onSend?: () => void
	mode?: "chat" | "new-chat"
	onNewChat?: () => void
}

export const PanelChatFooter = ({
	inputValue = "",
	onInputChange = () => {},
	onSend = () => {},
	mode = "chat",
	onNewChat = () => {},
}: PanelChatFooterProps) => {
	const { isExpandedChatOpen, isAndyResponding } = useChatContext()
	const { pollingSessionId, thinkingString } = useChat()

	return (
		<>
			<div className="px-3 flex flex-col">
				{/* Chat mode content */}
				{mode === "chat" ? (
					<>
						<ChatFooterLoadTab />
						{/* Input field with send button*/}
						<div className="flex w-full relative mb-3">
							<div className="w-full relative border rounded-md focus-within:ring-2 focus-within:ring-primary bg-background">
								<div className="w-full pr-6">
									{" "}
									{/* This ensures text wraps before reaching the button */}
									<textarea
										value={inputValue}
										onChange={(e) => onInputChange(e.target.value)}
										onKeyDown={(e) => {
											if (e.key === "Enter" && !e.shiftKey) {
												e.preventDefault()
												onSend()
											}
										}}
										placeholder="Message Andy"
										className="w-full min-h-[32px] max-h-[120px] px-3 py-1 text-sm bg-background border-none focus:outline-none resize-none overflow-auto"
										rows={1}
										ref={(element) => {
											if (element) {
												element.style.height = "auto"
												element.style.height = `${Math.min(element.scrollHeight, 120)}px`
											}
										}}
									/>
								</div>
								<Button
									size="icon"
									variant="ghost"
									className="h-6 w-6 absolute right-2 top-[50%] transform -translate-y-1/2 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
									onClick={onSend}
									disabled={!!pollingSessionId}
								>
									<SendHorizontal className="h-4 w-4" />
								</Button>
							</div>
						</div>
					</>
				) : (
					/* New conversation button */
					<div className="h-8 mb-3">
						<Button className="w-full h-full" onClick={onNewChat}>
							New Conversation
						</Button>
					</div>
				)}
			</div>

			{/* Disclaimer at bottom - unchanged */}
			{isExpandedChatOpen && mode === "chat" && (
				<div className="text-xs text-muted-foreground/70 text-center py-3 px-4">
					<p>
						Andy interacts with public language models. Do not share confidential information.
					</p>
					<p className="mt-1">
						Language models may not be accurate or up-to-date. Consider reviewing generated
						content.
					</p>
				</div>
			)}
		</>
	)
}
