/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { ProjectPage } from "@/components"
import { UnprocessedDocumentsProvider } from "@/context/UnprocessedDocumentsContext"
import { PageName } from "@/types/pages"
import type React from "react"
import DocumentSearchComponent from "./components/DocumentSearchComponent"

/**
 * @description Project documents page
 */
const DocumentsSearchPage: React.FC = () => {
	return (
		<ProjectPage pageName={PageName.ProjectDocuments}>
			<div className="p-2">
				<UnprocessedDocumentsProvider>
					<DocumentSearchComponent />
				</UnprocessedDocumentsProvider>
			</div>
		</ProjectPage>
	)
}

export default DocumentsSearchPage
