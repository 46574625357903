import {
	Table,
	TableBody,
	TableCellCondensed,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import type React from "react"

export interface Column<T> {
	header: string
	accessor: keyof T | ((row: T) => React.ReactNode)
	className?: string
	render?: (value: any, row: T) => React.ReactNode
}

export interface BaseTableProps<T> {
	columns: Column<T>[]
	data: T[]
	emptyText?: string
}

export function BaseTable<T>({
	columns,
	data,
	emptyText = "No data available",
}: BaseTableProps<T>) {
	return (
		<Table>
			<TableHeader>
				<TableRow>
					{columns.map((col) => (
						<TableHead key={col.header} className={col.className || ""}>
							{col.header}
						</TableHead>
					))}
				</TableRow>
			</TableHeader>
			<TableBody>
				{data.length > 0 ? (
					data.map((row, rowIdx) => (
						<TableRow key={rowIdx}>
							{columns.map((col) => {
								let value: any
								if (typeof col.accessor === "function") {
									value = col.accessor(row)
								} else {
									value = row[col.accessor]
								}
								return (
									<TableCellCondensed key={col.header} className={col.className || ""}>
										{col.render ? col.render(value, row) : value}
									</TableCellCondensed>
								)
							})}
						</TableRow>
					))
				) : (
					<TableRow>
						<TableCellCondensed
							colSpan={columns.length}
							className="text-center text-gray-500"
						>
							{emptyText}
						</TableCellCondensed>
					</TableRow>
				)}
			</TableBody>
		</Table>
	)
}

export default BaseTable
