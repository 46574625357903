import type { CommitChatResponseToSessionRequest } from "@/types"
import type {
	Chat,
	ChatMetadata,
	ChatSessionStatusSchema,
	DialogueResponse,
} from "@/types/chat"
import { apiClient } from "./client"

interface ChatRequestPayload {
	project_id: string
	subject_id: string
	document_ids: string[]
	query: string
	session_id?: string
}
interface ChatMetadataPayload {
	project_id: string | null
}

/**
 * Handles streaming chat responses from the API
 */
export const chatApi = {
	commitChatResponseToSession: async (sessionId: string, response: string) => {
		if (!response || !response.trim()) {
			return
		}
		const payload: CommitChatResponseToSessionRequest = {
			session_id: sessionId,
			response: response,
		}
		const res = await apiClient.post("post_commit_response_to_session", payload)
		return res
	},

	postUpdateChat: async (
		sessionId: string,
		name: string | null,
		status: ChatSessionStatusSchema | null,
		seenAt: boolean | null,
		updatedAt: boolean | null,
	) => {
		return await apiClient.post("post_update_chat_session", {
			session_id: sessionId,
			name: name,
			status: status,
			seen_at: seenAt,
			updated_at: updatedAt,
		})
	},

	postChatsMetadata: async (payload: ChatMetadataPayload) => {
		return await apiClient.post<ChatMetadata[]>("post_chats_metadata", payload)
	},

	postFullChat: async (projectId: string | null, chatId: string) => {
		return apiClient.post<Chat>("post_full_chat", {
			project_id: projectId,
			chat_id: chatId,
		})
	},
	pollUnseenMessages: async (sessionId: string): Promise<DialogueResponse[]> => {
		if (!sessionId) {
			throw new Error("Session ID is required for polling")
		}

		try {
			const response: DialogueResponse[] = await apiClient.post(
				`poll_unseen_messages/${sessionId}`,
			)
			// Assuming the API returns the current content and a completion status
			return response
		} catch (error) {
			console.error("Polling error:", error)
			throw error
		}
	},

	streamAndyResponse: async (
		payload: ChatRequestPayload,
		signal: AbortSignal,
		onSessionId: (sessionId: string) => void,
		onChunk: (text: string) => void,
		onComplete: () => void,
	) => {
		let accumulatedText = ""
		let sessionCaptured = false
		let capturedSessionId = ""
		const sessionIdRegex = /<SESSION_ID>([0-9a-f-]{36})<\/SESSION_ID>/i

		try {
			await apiClient.stream(
				"post_query_andy",
				payload,
				{}, // params
				{ signal }, // fetch config (to allow abort)
				(rawChunk) => {
					// If user has stopped streaming, abort.
					if (signal.aborted) return

					let chunk = rawChunk

					// Capture session ID if present (only once)
					if (!sessionCaptured) {
						const match = sessionIdRegex.exec(chunk as string)
						if (match) {
							capturedSessionId = match[1]
							onSessionId(capturedSessionId)
							sessionCaptured = true
							// Remove session ID tag from our visible text
							chunk = (chunk as string).replace(sessionIdRegex, "")
						}
					}

					// Accumulate text so the UI sees the entire response so far
					accumulatedText += chunk

					onChunk(accumulatedText as string)
				},
			)

			if (capturedSessionId) {
				await chatApi.commitChatResponseToSession(capturedSessionId, accumulatedText)
			}

			// Once the stream completes, invoke onComplete
			onComplete()
		} catch (error) {
			// If the request wasn't manually aborted, log & rethrow
			if (!signal.aborted) {
				console.error("Streaming error:", error)
				throw error
			}
		}
	},

	streamChatResponse: async (
		payload: ChatRequestPayload,
		signal: AbortSignal,
		onSessionId: (sessionId: string) => void,
		onChunk: (text: string) => void,
		onComplete: () => void,
	) => {
		let accumulatedText = ""
		let sessionCaptured = false
		const sessionIdRegex = /<SESSION_ID>([0-9a-f-]{36})<\/SESSION_ID>/i

		try {
			await apiClient.stream(
				"post_query_chatbot",
				payload,
				{}, // params
				{ signal }, // fetch config (to allow abort)
				(rawChunk) => {
					// If user has stopped streaming, abort.
					if (signal.aborted) return

					let chunk = rawChunk

					// Capture session ID if present (only once)
					if (!sessionCaptured) {
						const match = sessionIdRegex.exec(chunk as string)
						if (match) {
							onSessionId(match[1])
							sessionCaptured = true
							// Remove session ID tag from our visible text
							chunk = (chunk as string).replace(sessionIdRegex, "")
						}
					}

					// Accumulate text so the UI sees the entire response so far
					accumulatedText += chunk

					onChunk(accumulatedText as string)
				},
			)

			// Once the stream completes, invoke onComplete
			onComplete()
		} catch (error) {
			// If the request wasn't manually aborted, log & rethrow
			if (!signal.aborted) {
				console.error("Streaming error:", error)
				throw error
			}
		}
	},
}
