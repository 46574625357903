"use client"

import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import { Separator } from "@/components/ui/separator"
import type { Organization } from "@/types/admin"
import { format } from "date-fns"
import { CalendarIcon } from "lucide-react"
import { useState } from "react"

// Define the Organization type
type BillingFrequency = "monthly" | "quarterly" | "annually"

interface OrganizationDetailsProps {
	organization: Organization
	onSave: (organizationId: string, updatedOrganization: Organization) => void
}

export default function OrganizationDetails({
	organization,
	onSave,
}: OrganizationDetailsProps) {
	const [isEditing, setIsEditing] = useState(false)
	const [formData, setFormData] = useState<Organization>(organization)

	const handleInputChange = (field: keyof Organization, value: any) => {
		setFormData((prev) => ({
			...prev,
			[field]: value,
		}))
	}

	const handleSave = () => {
		onSave(organization.id, formData)
		setIsEditing(false)
	}

	const handleCancel = () => {
		setFormData(organization)
		setIsEditing(false)
	}

	const formatDate = (dateString: string | null | undefined) => {
		if (!dateString) return "Not set"
		try {
			return format(new Date(dateString), "PPP")
		} catch (_error) {
			return "Invalid date"
		}
	}

	const formatCurrency = (amount: number | null | undefined) => {
		if (amount === null || amount === undefined) return "Not set"
		return new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
		}).format(amount)
	}

	return (
		<Card className="w-full max-w-4xl mx-auto">
			<CardHeader className="flex flex-row items-center justify-between">
				{/* <CardTitle>Organization Details</CardTitle> */}
				{!isEditing ? (
					<Button variant="outline" onClick={() => setIsEditing(true)}>
						Edit
					</Button>
				) : (
					<div className="flex gap-2">
						<Button variant="outline" onClick={handleCancel}>
							Cancel
						</Button>
						<Button onClick={handleSave}>Save Changes</Button>
					</div>
				)}
			</CardHeader>
			<CardContent className="space-y-6">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
					{/* Organization ID */}
					<div className="space-y-2">
						<Label htmlFor="id">Organization ID</Label>
						{isEditing ? (
							<Input id="id" value={formData.id} readOnly className="bg-muted" />
						) : (
							<p id="id">{formData.id}</p>
						)}
					</div>

					{/* Organization Name */}
					<div className="space-y-2">
						<Label htmlFor="name">Organization Name</Label>
						{isEditing ? (
							<Input id="name" value={formData.name} readOnly className="bg-muted" />
						) : (
							<p id="name">{formData.name}</p>
						)}
					</div>

					{/* Created At */}
					<div className="space-y-2">
						<Label htmlFor="created_at">Created At</Label>
						{isEditing ? (
							<Input
								id="created_at"
								value={formatDate(formData.createdAt)}
								readOnly
								className="bg-muted"
							/>
						) : (
							<p id="created_at">{formatDate(formData.createdAt)}</p>
						)}
					</div>

					{/* Updated At */}
					<div className="space-y-2">
						<Label htmlFor="updated_at">Updated At</Label>
						{isEditing ? (
							<Input
								id="updated_at"
								value={formData.updatedAt ? formatDate(formData.updatedAt) : "Not updated yet"}
								readOnly
								className="bg-muted"
							/>
						) : (
							<p id="updated_at">
								{formData.updatedAt ? formatDate(formData.updatedAt) : "Not updated yet"}
							</p>
						)}
					</div>

					{/* Contract Start Date */}
					<div className="space-y-2">
						<Label htmlFor="contract_start">Contract Start Date</Label>
						{isEditing ? (
							<Popover>
								<PopoverTrigger asChild>
									<Button
										variant="outline"
										className="w-full justify-start text-left font-normal"
									>
										{formData.contractStart ? formatDate(formData.contractStart) : "Select date"}
										<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
									</Button>
								</PopoverTrigger>
								<PopoverContent className="w-auto p-0" align="start">
									<Calendar
										mode="single"
										selected={
											formData.contractStart ? new Date(formData.contractStart) : undefined
										}
										onSelect={(date) => handleInputChange("contractStart", date?.toISOString())}
										initialFocus
									/>
								</PopoverContent>
							</Popover>
						) : (
							<p id="contract_start">
								{formData.contractStart ? formatDate(formData.contractStart) : "Not set"}
							</p>
						)}
					</div>

					{/* Contract End Date */}
					<div className="space-y-2">
						<Label htmlFor="contract_end">Contract End Date</Label>
						{isEditing ? (
							<Popover>
								<PopoverTrigger asChild>
									<Button
										variant="outline"
										className="w-full justify-start text-left font-normal"
									>
										{formData.contractEnd ? formatDate(formData.contractEnd) : "Select date"}
										<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
									</Button>
								</PopoverTrigger>
								<PopoverContent className="w-auto p-0" align="start">
									<Calendar
										mode="single"
										selected={formData.contractEnd ? new Date(formData.contractEnd) : undefined}
										onSelect={(date) => handleInputChange("contractEnd", date?.toISOString())}
										initialFocus
									/>
								</PopoverContent>
							</Popover>
						) : (
							<p id="contract_end">
								{formData.contractEnd ? formatDate(formData.contractEnd) : "Not set"}
							</p>
						)}
					</div>
				</div>

				<Separator />

				<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
					{/* Document Process Fee */}
					<div className="space-y-2">
						<Label htmlFor="document_process_fee">Document Process Fee</Label>
						{isEditing ? (
							<Input
								id="document_process_fee"
								type="number"
								step="0.01"
								value={formData.documentProcessFee || ""}
								onChange={(e) =>
									handleInputChange(
										"documentProcessFee",
										Number.parseFloat(e.target.value) || null,
									)
								}
							/>
						) : (
							<p id="document_process_fee">
								{formData.documentProcessFee !== null
									? formatCurrency(formData.documentProcessFee)
									: "Not set"}
							</p>
						)}
					</div>

					{/* Document Export Fee */}
					<div className="space-y-2">
						<Label htmlFor="document_export_fee">Document Export Fee</Label>
						{isEditing ? (
							<Input
								id="document_export_fee"
								type="number"
								step="0.01"
								value={formData.documentExportFee || ""}
								onChange={(e) =>
									handleInputChange(
										"documentExportFee",
										Number.parseFloat(e.target.value) || null,
									)
								}
							/>
						) : (
							<p id="document_export_fee">
								{formData.documentExportFee !== null
									? formatCurrency(formData.documentExportFee)
									: "Not set"}
							</p>
						)}
					</div>

					{/* Platform Fee */}
					<div className="space-y-2">
						<Label htmlFor="platform_fee">Platform Fee</Label>
						{isEditing ? (
							<Input
								id="platform_fee"
								type="number"
								step="0.01"
								value={formData.platformFee || ""}
								onChange={(e) =>
									handleInputChange("platformFee", Number.parseFloat(e.target.value) || null)
								}
							/>
						) : (
							<p id="platform_fee">
								{formData.platformFee !== null
									? formatCurrency(formData.platformFee)
									: "Not set"}
							</p>
						)}
					</div>

					{/* Seat Fee */}
					<div className="space-y-2">
						<Label htmlFor="seat_fee">Seat Fee</Label>
						{isEditing ? (
							<Input
								id="seat_fee"
								type="number"
								step="0.01"
								value={formData.seatFee || ""}
								onChange={(e) =>
									handleInputChange("seatFee", Number.parseFloat(e.target.value) || null)
								}
							/>
						) : (
							<p id="seat_fee">
								{formData.seatFee !== null ? formatCurrency(formData.seatFee) : "Not set"}
							</p>
						)}
					</div>

					{/* Default Budget */}
					<div className="space-y-2">
						<Label htmlFor="default_budget">Default Budget</Label>
						{isEditing ? (
							<Input
								id="default_budget"
								type="number"
								step="0.01"
								value={formData.defaultBudget || ""}
								onChange={(e) =>
									handleInputChange("defaultBudget", Number.parseFloat(e.target.value) || null)
								}
							/>
						) : (
							<p id="default_budget">
								{formData.defaultBudget !== null
									? formatCurrency(formData.defaultBudget)
									: "Not set"}
							</p>
						)}
					</div>

					{/* Domain */}
					<div className="space-y-2">
						<Label htmlFor="domain">Domain</Label>
						{isEditing ? (
							<Input
								id="domain"
								value={formData.domain || ""}
								onChange={(e) => handleInputChange("domain", e.target.value)}
							/>
						) : (
							<p id="domain">{formData.domain || "Not set"}</p>
						)}
					</div>
					{/* PostHog Cohort ID */}
					<div className="space-y-2">
						<Label htmlFor="posthogCohortId">PostHog Cohort ID</Label>
						{isEditing ? (
							<Input
								id="posthogCohortId"
								value={formData.posthogCohortId || ""}
								onChange={(e) => handleInputChange("posthogCohortId", e.target.value)}
							/>
						) : (
							<p id="posthogCohortId">{formData.posthogCohortId || "Not set"}</p>
						)}
					</div>

					{/* Billing Frequency */}
					<div className="space-y-2">
						<Label htmlFor="billing_frequency">Billing Frequency</Label>
						{isEditing ? (
							<Select
								value={formData.billingFrequency || ""}
								onValueChange={(value) =>
									handleInputChange("billingFrequency", value as BillingFrequency)
								}
							>
								<SelectTrigger id="billing_frequency">
									<SelectValue placeholder="Select billing frequency" />
								</SelectTrigger>
								<SelectContent>
									<SelectItem value="monthly">Monthly</SelectItem>
									<SelectItem value="quarterly">Quarterly</SelectItem>
									<SelectItem value="annually">Annually</SelectItem>
								</SelectContent>
							</Select>
						) : (
							<p id="billing_frequency">
								{formData.billingFrequency
									? formData.billingFrequency.charAt(0).toUpperCase() +
										formData.billingFrequency.slice(1)
									: "Not set"}
							</p>
						)}
					</div>
				</div>
			</CardContent>
		</Card>
	)
}
