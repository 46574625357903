import type {
	AddDocumentChunkRequest,
	ChunkLocation,
	ClaimStrength,
	DeleteDocumentChunkRequest,
	DeleteDocumentsRequest,
	DocumentChunk,
	DocumentToggleArchiveRequest,
	GetClaimStrengthsRequest,
	GetDocumentFiguresRequest,
	GetOfficeActionsRequest,
	GetPatentDetailsRequest,
	PatentNumberOption,
	SearchMode,
	SemanticSearchDocumentsRequest,
	UpdateDocumentChunkRequest,
	UpdateDocumentMetadataRequest,
	UpdateDocumentRequest,
} from "@/types"
import { S3Operation } from "@/types"
import type { DocumentCoverSentence, FullDocument } from "@/types/documents"
import type { CitationSearchResult } from "@/types/invalidity"
import { apiClient } from "./client"

export const documentApi = {
	async deleteDocuments(projectId: string, documentIds: string[]) {
		const payload: DeleteDocumentsRequest = {
			project_id: projectId,
			document_ids: documentIds,
		}

		return await apiClient.post("post_delete_documents", payload)
	},

	async toggleArchiveDocuments(
		projectId: string,
		documentIds: string[],
		removed: boolean,
	) {
		const payload: DocumentToggleArchiveRequest = {
			project_id: projectId,
			document_ids: documentIds,
			removed: removed,
		}

		return await apiClient.post("post_toggle_archive_documents", payload)
	},

	async semanticSearchDocuments(
		projectId: string,
		searchQuery: string,
		documentIds: string[],
		searchMode: SearchMode,
	): Promise<CitationSearchResult[]> {
		const params: SemanticSearchDocumentsRequest = {
			project_id: projectId,
			text: searchQuery,
			documents_ids: documentIds,
			results: 50,
			search_mode: searchMode,
		}

		return await apiClient.get("get_semantic_search_documents", params)
	},

	async getFullDocument(documentId: string): Promise<FullDocument> {
		const params = {
			document_id: documentId,
		}
		return await apiClient.get("get_full_document", params)
	},

	async getDocumentFigures(documentId: string): Promise<DocumentChunk[]> {
		const params: GetDocumentFiguresRequest = {
			document_id: documentId,
		}
		return await apiClient.get("get_document_figures", params)
	},

	async getClaimStrengths(
		projectId: string,
		subjectId: string,
	): Promise<ClaimStrength[]> {
		const params: GetClaimStrengthsRequest = {
			project_id: projectId,
			subject_id: subjectId,
		}
		return await apiClient.get("get_claim_strengths", params)
	},

	async getPatentDetails(
		patentNumbers: string[],
		isEarlyApplication: boolean,
	): Promise<PatentNumberOption[]> {
		const params: GetPatentDetailsRequest = {
			patent_numbers: patentNumbers,
			is_early_application: isEarlyApplication,
		}
		return await apiClient.get("get_patent_details", params)
	},

	async updateDocument(
		documentId: string,
		{ title, priority_date }: { title: string | null; priority_date: string | null },
	) {
		const payload: UpdateDocumentRequest = {
			document_id: documentId,
			...(title && { title }),
			...(priority_date && { priority_date }),
		}
		return await apiClient.post("post_update_document_details", payload)
	},

	async updateDocumentChunk({
		updateDocumentChunkRequest,
	}: {
		updateDocumentChunkRequest: UpdateDocumentChunkRequest
	}) {
		const payload: UpdateDocumentChunkRequest = {
			...updateDocumentChunkRequest,
		}
		return await apiClient.post("post_update_document_chunk", payload)
	},

	async addTextDocumentChunk(
		documentId: string,
		text: string,
		location: ChunkLocation,
		figureUrls: string[],
		// type: BlockType,
	) {
		const payload: AddDocumentChunkRequest = {
			document_id: documentId,
			text,
			location: location as Record<string, never>,
			figure_urls: figureUrls,
			type: "TEXT",
		}
		return await apiClient.post("post_add_document_chunk", payload)
	},

	async deleteDocumentChunk(documentChunkId: string, isFigure: boolean) {
		const payload: DeleteDocumentChunkRequest = {
			document_chunk_id: documentChunkId,
			is_figure: isFigure,
		}
		return await apiClient.post("post_delete_document_chunk", payload)
	},

	async updateDocumentMetadata(
		projectId: string | null,
		documentIds: string[],
		options: {
			notes?: string | null
			addTags?: string[] | null
			setTags?: string[] | null
			nickname?: string | null
			documentCover?: DocumentCoverSentence[] | null
			updateCreatedBy?: boolean | null
		},
	) {
		const payload: UpdateDocumentMetadataRequest = {
			...(projectId && { project_id: projectId ?? "" }),
			document_ids: documentIds,
			...(options.notes && { notes: options.notes }),
			...(options.addTags && { add_tags: options.addTags }),
			...(options.setTags && { set_tags: options.setTags }),
			...(options.nickname && { nickname: options.nickname }),
			...(options.updateCreatedBy && { update_created_by: options.updateCreatedBy }),
			...(options.documentCover && {
				document_cover: options.documentCover.map((sentence) => ({
					sentence: sentence.sentence.map((part) => {
						if ("chunkId" in part) {
							return {
								text: part.text,
								chunk_id: part.chunkId,
								start_index: part.startIndex,
								end_index: part.endIndex,
							}
						}
						return part
					}),
				})),
			}),
		}
		return await apiClient.post("post_update_document_metadata", payload)
	},

	async getOfficeActions(projectId: string) {
		const params: GetOfficeActionsRequest = {
			project_id: projectId,
		}
		return await apiClient.get("get_office_actions", params)
	},

	async getSignedUrlForFile(
		s3Key: string,
		operation: S3Operation = S3Operation.GET,
		contentType = "application/pdf",
	) {
		const data = await apiClient.get("get_signed_url_for_file", {
			file_id: s3Key,
			operation: operation,
			content_type: contentType,
		})
		return data
	},

	async getPdfFile(url: string): Promise<{ data: Blob; status: number }> {
		return await apiClient.getBinary("get_pdf_file", { url })
	},
}
