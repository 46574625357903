import RichVariableInput, {
	type RichVariableInputRef,
} from "@/components/input/RichVariableInput"
import { VariableMenuItems } from "@/components/input/VariableMenu"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Label } from "@/components/ui/label"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import type { CollapsedLimitation } from "@/types/claim"
import type { InvalidityChart } from "@/types/invalidity"
import type { Variable, VariableInputType } from "@/utils/variables"
import { VARIABLES_BY_TYPE } from "@/utils/variables"
import { Variable as VariableIcon } from "lucide-react"
import type React from "react"
import { useCallback, useRef } from "react"

interface VariableInputProps {
	label: string
	value: string
	onChange: (value: string) => void
	type: VariableInputType
	placeholder?: string
	multiline?: boolean
	previewReplacements?: Record<string, string>
	showMultiReferenceOptions?: boolean
	documentIds?: string[]
	onBlur?: () => void
	chartData?: InvalidityChart
	claimLimitation?: CollapsedLimitation
}

/**
 * VariableInput
 * Renders a dropdown of variables (and variants) that can be inserted into a rich-text component.
 */
const VariableInput: React.FC<VariableInputProps> = ({
	label,
	value,
	onChange,
	type,
	placeholder,
	multiline = false,
	previewReplacements,
	documentIds,
	onBlur,
	chartData,
	claimLimitation,
}) => {
	const variables = VARIABLES_BY_TYPE[type]

	// Collect valid keys from both "id" and "variant.id"
	const validKeys = new Set<string>()
	for (const v of variables) {
		validKeys.add(v.id)
		if (v.variants) {
			for (const vt of v.variants) {
				validKeys.add(vt.id)
			}
		}
	}

	// Container holding our RichVariableInput, to check if it has focus.
	const richInputContainerRef = useRef<HTMLDivElement>(null)
	const richInputRef = useRef<RichVariableInputRef>(null)

	/**
	 * Insert the top-level variable (no variant).
	 */
	const insertVariable = useCallback((variable: Variable) => {
		try {
			if (richInputRef.current) {
				richInputRef.current.insertVariable(variable)
			}
		} catch (error) {
			console.error(error)
		}
	}, [])

	/**
	 * Insert a particular variant of the variable.
	 */
	const insertVariantVariable = useCallback((variable: Variable, variantId: string) => {
		try {
			if (richInputRef.current) {
				richInputRef.current.insertVariableVariant(variable, variantId)
			}
		} catch (error) {
			console.error(error)
		}
	}, [])

	/**
	 * Handle changes from the RichVariableInput (plaintext tokens).
	 */
	const handleRichChange = useCallback(
		(newValue: string) => {
			onChange(newValue)
		},
		[onChange],
	)

	return (
		<div className="space-y-1">
			<Label className="text-sm">{label}</Label>
			<div className="relative" ref={richInputContainerRef}>
				<RichVariableInput
					ref={richInputRef}
					value={value}
					onChange={handleRichChange}
					placeholder={placeholder}
					className={multiline ? "pr-12" : "pr-12 min-h-0 h-10"}
					previewReplacements={previewReplacements}
					onBlur={onBlur}
					type={type}
					chartData={chartData}
					claimLimitation={claimLimitation}
				/>
				<DropdownMenu>
					<Tooltip>
						<TooltipTrigger asChild>
							<DropdownMenuTrigger asChild>
								<Button
									type="button"
									size="icon"
									variant="ghost"
									className="h-8 w-8 absolute right-2 top-1"
								>
									<VariableIcon className="h-5 w-5" />
								</Button>
							</DropdownMenuTrigger>
						</TooltipTrigger>
						<TooltipContent>Insert variable</TooltipContent>
					</Tooltip>
					<DropdownMenuContent className="z-50">
						<VariableMenuItems
							variables={variables}
							onSelect={insertVariable}
							onSelectVariant={insertVariantVariable}
							documentIds={documentIds}
						/>
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
		</div>
	)
}

export default VariableInput
