/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import {
	Table,
	TableBody,
	TableCellCondensed,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import { H4 } from "@/components/ui/typography"
import { OrganizationModal } from "@/features/admin/superadmin/components"
import { cn } from "@/lib/utils"
import { PlusIcon } from "@radix-ui/react-icons"
import { useState } from "react"

/**
 * @description Organization details table
 */
interface OrganizationDetailsTableProps {
	organizations: {
		name: string
		id: string
		projects: number
		documentsAddedCount: number
		seats: number
		amountDue: number
		amountSpent: number
	}[]
	onOrgSelect: (orgId: string) => void
}

export function OrganizationDetailsTable({
	organizations,
	onOrgSelect,
}: OrganizationDetailsTableProps) {
	const [isAddOrgModalOpen, setAddOrgModalOpen] = useState(false)

	const columns = [
		{ key: "name", label: "Name" },
		{ key: "seats", label: "Seats" },
		{ key: "projects", label: "Projects" },
		{ key: "documentsAddedCount", label: "Documents Added" },
		{ key: "amountDue", label: "Amount Due" },
	]

	return (
		<Card>
			<CardHeader>
				<div className="flex justify-between items-center">
					<H4>Organizations</H4>
					<Button size="icon" onClick={() => setAddOrgModalOpen(true)}>
						<PlusIcon />
					</Button>
				</div>
			</CardHeader>
			<CardContent>
				<Table>
					<TableHeader>
						<TableRow>
							{columns.map((col) => (
								<TableHead key={col.key}>{col.label}</TableHead>
							))}
						</TableRow>
					</TableHeader>
					<TableBody>
						{organizations.map((org) => (
							<TableRow
								key={org.id}
								onClick={() => onOrgSelect(org.id)}
								className={cn("cursor-pointer hover:bg-muted/50")}
							>
								{columns.map((col) =>
									org[col.key] !== null ? (
										<TableCellCondensed key={`${org.id}-${col.key}`}>
											{col.key.includes("Date")
												? new Date(org[col.key]).toLocaleDateString()
												: org[col.key]}
										</TableCellCondensed>
									) : (
										<TableCellCondensed key={`${org.id}-${col.key}`}>-</TableCellCondensed>
									),
								)}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>

			<OrganizationModal
				open={isAddOrgModalOpen}
				onClose={() => setAddOrgModalOpen(false)}
			/>
		</Card>
	)
}
