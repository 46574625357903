/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
"use client"
import {
	SidebarGroup,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
	useSidebar,
} from "@/components/ui/sidebar"
import { useNavigationState } from "@/hooks"
import { useUserStore } from "@/store"
import { RoleType } from "@/types"
import { House, Lock, Plus, User } from "lucide-react"
import type React from "react"
import { useNavigate } from "react-router-dom"

/**
 * @description Non-project sidebar content
 */
const NonProjectSidebarContent: React.FC = () => {
	const navigate = useNavigate()
	const { isHomePage, isAdminPage, isSuperAdminPage, isCreateProjectPage } =
		useNavigationState()
	const { role } = useUserStore()
	const { open } = useSidebar()

	const menuItems = [
		{
			icon: House,
			url: "/home",
			isActive: isHomePage,
			title: "Home",
			show: true,
		},
		{
			icon: User,
			url: "/admin",
			isActive: isAdminPage,
			title: "Admin",
			show: role === RoleType.ADMIN || role === RoleType.OWNER,
		},
		{
			icon: Lock,
			url: "/superadmin",
			isActive: isSuperAdminPage,
			title: "Super Admin",
			show: role === RoleType.OWNER,
		},
		{
			icon: Plus,
			url: "/create-project",
			isActive: isCreateProjectPage,
			title: "New Project",
			show: true,
		},
	]

	return (
		<SidebarGroup>
			<SidebarMenu>
				{menuItems
					.filter((item) => item.show)
					.map((item) => (
						<SidebarMenuItem key={item.title}>
							<SidebarMenuButton
								onClick={() => navigate(item.url)}
								isActive={item.isActive}
								tooltip={item.title}
								asChild
							>
								{open ? (
									<span className="flex items-center w-full">
										{item.icon && <item.icon className="h-5 w-5" />}
										<span className="min-w-0 truncate">{item.title}</span>
									</span>
								) : (
									<span className="flex items-center">
										{item.icon && <item.icon className="nav-icon" />}
									</span>
								)}
							</SidebarMenuButton>
						</SidebarMenuItem>
					))}
			</SidebarMenu>
		</SidebarGroup>
	)
}

export default NonProjectSidebarContent
