import { api } from "@/api"
import { useProjectContext } from "@/context/ProjectContext"
import type {
	DocumentCoverSentence,
	DocumentRole,
	ProjectDocumentMetadata,
} from "@/types"
import {
	getDocumentRoleQueryKey,
	invalidateDocumentRelatedQueries,
} from "@/utils/query/invalidation"
import { MUTATION_KEYS } from "@/utils/query/keys"
import { type QueryClient, useMutation, useQueryClient } from "@tanstack/react-query"

type UpdateMetadataPayload = {
	notes?: string
	nickname?: string
	addTags?: string[]
	setTags?: string[]
	documentCover?: DocumentCoverSentence[]
	updateCreatedBy?: boolean
	[key: string]: any // for future metadata fields
}

function handleOptimisticUpdate(
	queryClient: QueryClient,
	projectId: string,
	documentIds: string[],
	documentRole: DocumentRole,
	updateFn: (ref: ProjectDocumentMetadata) => ProjectDocumentMetadata,
) {
	const queryKey = getDocumentRoleQueryKey(documentRole, projectId)

	const oldData = queryClient.getQueryData<ProjectDocumentMetadata[]>(queryKey)

	if (projectId) {
		queryClient.setQueryData<ProjectDocumentMetadata[] | undefined>(
			queryKey,
			(oldData) => {
				if (!oldData) return oldData
				return oldData.map((doc) =>
					documentIds.includes(doc.id ?? "") ? updateFn(doc) : doc,
				)
			},
		)
	}

	return { oldData }
}

function handleOptimisticRollback(
	queryClient: QueryClient,
	projectId: string,
	documentRole: DocumentRole,
	oldData?: ProjectDocumentMetadata[],
) {
	if (projectId && oldData) {
		queryClient.setQueryData(getDocumentRoleQueryKey(documentRole, projectId), oldData)
	}
}

export const useUpdateDocumentMetadata = () => {
	const queryClient = useQueryClient()
	const { projectId } = useProjectContext()

	const updateDocumentMetadata = useMutation({
		mutationKey: MUTATION_KEYS.document.metadata.update(),
		mutationFn: async ({
			documentIds,
			documentRole,
			updatePayload,
		}: {
			documentIds: string[]
			documentRole: DocumentRole
			updatePayload: UpdateMetadataPayload
		}) => {
			return api.updateDocumentMetadata(projectId, documentIds, updatePayload)
		},
		onMutate: async ({
			documentIds,
			documentRole,
			updatePayload,
		}: {
			documentIds: string[]
			documentRole: DocumentRole
			updatePayload: UpdateMetadataPayload
		}) => {
			return handleOptimisticUpdate(
				queryClient,
				projectId,
				documentIds,
				documentRole,
				(ref) => {
					const updatedDoc = { ...ref }
					if (updatePayload.addTags) {
						updatedDoc.tags = [...(ref.tags || []), ...updatePayload.addTags]
					} else if (updatePayload.setTags) {
						updatedDoc.tags = updatePayload.setTags
					}
					return { ...updatedDoc, ...updatePayload }
				},
			)
		},
		onError: (_error, variables, context) => {
			const { documentRole } = variables
			handleOptimisticRollback(queryClient, projectId, documentRole, context?.oldData)
		},
		onSuccess: (_, variables) => {
			const { documentIds, documentRole } = variables
			invalidateDocumentRelatedQueries(queryClient, documentIds, documentRole, projectId)
		},
	})

	return {
		updateDocumentMetadata: updateDocumentMetadata.mutate,
	}
}

export default useUpdateDocumentMetadata
