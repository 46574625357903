import type { ChatMetadata } from "@/types/chat"
import { formatChatTimestamp } from "@/utils/dateUtils"
import { getContentFromMessage, isChatUnread } from "@/utils/messageUtils"
import type { MouseEvent } from "react"

interface ChatRowProps {
	chatMetadata: ChatMetadata
	onClick: (e: MouseEvent<HTMLButtonElement>) => void
}

const ChatRow = ({ chatMetadata, onClick }: ChatRowProps) => {
	const isUnread = isChatUnread(chatMetadata)

	return (
		<button
			type="button"
			onClick={onClick}
			className="w-full h-16 flex items-center px-2 focus:outline-none"
		>
			<div
				className={`flex items-start gap-3 w-full py-3 rounded-lg px-3 hover:bg-accent/5 transition-colors h-16
				${isUnread ? "bg-muted/90" : "bg-muted/30"}`}
			>
				<div className="flex-1 overflow-hidden flex flex-col justify-start text-left min-w-0">
					<h4 className="font-medium text-sm leading-none text-left truncate">
						{chatMetadata?.name ||
							`Chat at ${formatChatTimestamp(chatMetadata?.updatedAt ?? chatMetadata?.createdAt)}`}
					</h4>
					<p className="text-xs text-muted-foreground mt-2 leading-none truncate text-left flex items-center">
						{getContentFromMessage(chatMetadata.lastMessage) ?? ""}
					</p>
				</div>
				{/* <span className="text-xs text-muted-foreground shrink-0 mt-1 ml-2 hidden sm:block">
					{formatChatTimestamp(chatMetadata.updatedAt ?? chatMetadata.createdAt)}
				</span> */}
			</div>
		</button>
	)
}

const ChatRowGlimmer = () => (
	<div className="w-full h-16 px-2">
		<div className="flex items-start gap-3 w-full py-3 bg-muted/50 rounded-lg px-3 animate-pulse">
			<div className="flex-1 overflow-hidden flex flex-col justify-start gap-2">
				<div className="h-4 bg-muted rounded w-1/3" />
				<div className="h-3 bg-muted rounded w-2/3" />
			</div>
			<div className="h-3 bg-muted rounded w-16 shrink-0 mt-1" />
		</div>
	</div>
)

export { ChatRow, ChatRowGlimmer }
