import { usePatentDetails } from "@/hooks"
import { useFileUtils } from "@/hooks"
import {
	type DocumentRating,
	DocumentRole,
	type NplDetails,
	type PatentNumberOption,
	type ProductDetails,
	type UnprocessedDocument,
	UnprocessedDocumentType,
	type UnpublishedPatentEntry,
} from "@/types"
import { nanoid } from "nanoid"
import { useCallback, useMemo, useState } from "react"

const useUnprocessedDocuments = () => {
	const [unprocessedDocuments, setUnprocessedDocuments] = useState<
		UnprocessedDocument[]
	>([])

	// Patent details handling
	const [isLoadingPatentDetails, setIsLoadingPatentDetails] = useState(false)
	const { getPatentDetails } = usePatentDetails()
	const { getUploadedFileKeysAndUrls } = useFileUtils()

	const [isUnprocessedDocumentsLoading, setIsUnprocessedDocumentsLoading] =
		useState(false)

	const addUnprocessedDocumentToList = useCallback((document: UnprocessedDocument) => {
		setUnprocessedDocuments((prev) => {
			const newDocs = [...prev, document]
			return newDocs
		})
	}, [])

	const removeUnprocessedDocument = (documentId: string) => {
		setUnprocessedDocuments((prev) => prev.filter((doc) => doc.id !== documentId))
	}

	// Function to handle adding documents based on type
	const addUnprocessedDocuments = useCallback(
		async (
			documentRole: DocumentRole,
			documentType: UnprocessedDocumentType,
			options: {
				files?: File[]
				patentDetails?: PatentNumberOption[]
				unpublishedPatent?: UnpublishedPatentEntry
				patentNumbers?: string[]
				nplDetails?: NplDetails[]
				productDetails?: ProductDetails[]
			} = {},
			setNew = false,
		) => {
			const {
				files,
				patentDetails,
				unpublishedPatent,
				patentNumbers,
				nplDetails,
				productDetails,
			} = options
			try {
				const createUnprocessedDocument = async (
					type: UnprocessedDocumentType,
					content: File | PatentNumberOption | UnpublishedPatentEntry,
					nplDetails?: NplDetails,
					productDetails?: ProductDetails,
					setNew = false,
				): Promise<UnprocessedDocument> => {
					// If the document is a published patent/application, initialize selectedKindCode
					if (
						type === UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION &&
						"details" in content
					) {
						// Create a shallow copy in case you want to avoid mutating the original content object
						const contentCopy = { ...content }
						const kindCodes = Object.keys(contentCopy.details)
						if (kindCodes.length > 0) {
							contentCopy.selectedKindCode = kindCodes[0]
						}
						// Use the updated content
						return {
							id: nanoid(),
							type,
							role: documentRole,
							patentDetails: contentCopy,
							...(nplDetails && { nplDetails: nplDetails }),
							isNew: setNew,
						}
					}

					// For NPL documents, upload the file if provided
					if (type === UnprocessedDocumentType.NPL && nplDetails) {
						const file = content as File
						if (file) {
							const fileKeys = await getUploadedFileKeysAndUrls([file])
							const s3Key = fileKeys[file.name].s3_key
							return {
								id: nanoid(),
								type,
								role: documentRole,
								s3_key: s3Key,
								file,
								nplDetails: nplDetails,
								isNew: setNew,
							}
						}
						return {
							id: nanoid(),
							type,
							role: documentRole,
							file: content as File,
							nplDetails: nplDetails,
							isNew: setNew,
						}
					}

					// For product documents, upload the specifications PDF if available
					if (type === UnprocessedDocumentType.PRODUCT && productDetails) {
						const file = content as File
						if (file) {
							const fileKeys = await getUploadedFileKeysAndUrls([file])
							const s3Key = fileKeys[file.name].s3_key
							return {
								id: nanoid(),
								type,
								role: documentRole,
								s3_key: s3Key,
								file,
								productDetails: productDetails,
								isNew: setNew,
							}
						}
						return {
							id: nanoid(),
							type,
							role: documentRole,
							file: content as File,
							productDetails: productDetails,
							isNew: setNew,
						}
					}

					return {
						id: nanoid(),
						type,
						role: documentRole,
						...(type === UnprocessedDocumentType.UNPUBLISHED_PATENT
							? { unpublishedPatent: content as UnpublishedPatentEntry }
							: { file: content as File }),
						...(nplDetails && { nplDetails: nplDetails }),
						...(productDetails && { productDetails: productDetails }),
						isNew: setNew,
					}
				}

				setIsUnprocessedDocumentsLoading(true)

				if (files) {
					const newDocs = await Promise.all(
						files.map((file, index) =>
							createUnprocessedDocument(
								documentType,
								file,
								nplDetails?.[index],
								productDetails?.[index],
								setNew,
							),
						),
					)
					newDocs.forEach(addUnprocessedDocumentToList)
				}

				if (patentDetails) {
					for (const detail of patentDetails) {
						const doc = await createUnprocessedDocument(
							documentType,
							detail,
							undefined,
							undefined,
							setNew,
						)
						addUnprocessedDocumentToList(doc)
					}
				}

				if (unpublishedPatent) {
					const doc = await createUnprocessedDocument(
						documentType,
						unpublishedPatent,
						undefined,
						undefined,
						setNew,
					)
					addUnprocessedDocumentToList(doc)
				}

				if (patentNumbers) {
					const details = await getPatentDetails(patentNumbers)
					for (const detail of details) {
						const doc = await createUnprocessedDocument(
							documentType,
							detail,
							undefined,
							undefined,
							setNew,
						)
						addUnprocessedDocumentToList(doc)
					}
				}

				setIsUnprocessedDocumentsLoading(false)
			} catch (error) {
				console.error("Error adding unprocessed documents:", error)
				setIsUnprocessedDocumentsLoading(false)
			}
		},
		[addUnprocessedDocumentToList, getPatentDetails, getUploadedFileKeysAndUrls],
	)

	// Reset all state
	const resetAll = () => {
		setUnprocessedDocuments([])
		setIsLoadingPatentDetails(false)
		setIsUnprocessedDocumentsLoading(false)
	}

	const unprocessedPriorArtDocuments = useMemo(() => {
		return unprocessedDocuments.filter((doc) => doc.role === DocumentRole.PRIOR_ART)
	}, [unprocessedDocuments])

	const unprocessedProductDocuments = useMemo(() => {
		return unprocessedDocuments.filter((doc) => doc.role === DocumentRole.INFRINGEMENT)
	}, [unprocessedDocuments])

	const unprocessedSubjectDocuments = useMemo(() => {
		return unprocessedDocuments.filter((doc) => doc.role === DocumentRole.SUBJECT)
	}, [unprocessedDocuments])

	const unprocessedContextDocuments = useMemo(() => {
		return unprocessedDocuments.filter((doc) => doc.role === DocumentRole.CONTEXT)
	}, [unprocessedDocuments])

	const unprocessedOfficeActionDocuments = useMemo(() => {
		return unprocessedDocuments.filter((doc) => doc.role === DocumentRole.OFFICE_ACTION)
	}, [unprocessedDocuments])

	// Update selectedKindCode on UnprocessedDocument
	const updateSelectedKindCode = (documentId: string, kindCode: string) => {
		setUnprocessedDocuments((prevDocs) =>
			prevDocs.map((doc) => {
				if (doc.id !== documentId) return doc

				// If the document has patentDetails, update the nested selectedKindCode.
				if (doc.patentDetails) {
					return {
						...doc,
						patentDetails: {
							...doc.patentDetails,
							selectedKindCode: kindCode,
						},
					}
				}
			}),
		)
	}

	// Update rating of UnprocessedDocument
	const updateRating = (documentId: string, rating: DocumentRating) => {
		setUnprocessedDocuments((prevDocs) => {
			const newDocs = prevDocs.map((doc) => {
				const updated = doc.id === documentId ? { ...doc, rating: rating } : doc
				return updated
			})
			return newDocs
		})
	}

	// Update rating of UnprocessedDocument
	const resetNewDocuments = () => {
		setUnprocessedDocuments((prevDocs) => {
			const newDocs = prevDocs.map((doc) => {
				const updated = { ...doc, isNew: false }
				return updated
			})
			return newDocs
		})
	}

	return {
		unprocessedDocuments,
		setUnprocessedDocuments,
		addUnprocessedDocuments,
		removeUnprocessedDocument,
		isLoadingPatentDetails,
		isUnprocessedDocumentsLoading,
		resetAll,

		// Document groups
		unprocessedPriorArtDocuments,
		unprocessedSubjectDocuments,
		unprocessedContextDocuments,
		unprocessedOfficeActionDocuments,
		unprocessedProductDocuments,

		// Update details on unprocessed document (kind codes, dates)
		updateSelectedKindCode,
		updateRating,
		resetNewDocuments,
	}
}

export default useUnprocessedDocuments
