import { Loader } from "@/components"
import DocumentSearchInput from "@/components/search/DocumentSearchInput"
import { Button } from "@/components/ui/button"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Label } from "@/components/ui/label"
import { useDocumentContext } from "@/context/DocumentContext"
import { scrollToCenter } from "@/features/charts/components/scrollHelpers"
import { useDocumentChunk } from "@/hooks"
import { useProjectStore } from "@/store"
import {
	BlockType,
	CitationFormat,
	type DocumentChunk,
	PatentType,
	SearchMode,
	SearchOrder,
} from "@/types"
import type { FullDocument } from "@/types/documents"
import { normalizeTextToMatch } from "@/utils/normalizeTextToMatch"
import { getDefaultCitationFormat } from "@/utils/projectUtils"
import Fuse from "fuse.js"
import { Plus } from "lucide-react"
import React, { useCallback } from "react"
import { useEffect, useMemo, useRef, useState } from "react"
import { DocumentChunkComponent } from "./DocumentChunk"
import { DocumentChunkLocationFormatSelect } from "./DocumentChunkLocationFormatSelect"
import { EditDocumentChunkDialog } from "./EditDocumentChunkDialog"

interface DocumentBodyProps {
	document: FullDocument
	body: DocumentChunk[]
	figureChunks: DocumentChunk[]
	citationText?: string
	highlightedRef?: React.RefObject<HTMLDivElement>
	editMode?: boolean
	onFigureReferenceSelect?: (figureChunk: DocumentChunk) => void
	filterIds?: string[]
	selectedChunkRef?: React.MutableRefObject<HTMLDivElement | null>
	showChartableOnly?: boolean
	onToggleChartableOnly?: () => void
}

const DocumentBody: React.FC<DocumentBodyProps> = ({
	document,
	body,
	figureChunks,
	citationText,
	highlightedRef,
	editMode,
	onFigureReferenceSelect,
	filterIds,
	selectedChunkRef,
	showChartableOnly = false,
	onToggleChartableOnly,
}) => {
	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
	const bodyRef = useRef<HTMLDivElement>(null)
	const { addDocumentChunk, addDocumentChunkIsLoading } = useDocumentChunk()
	const { selectedCitationFormat, updateSelectedCitationFormat } = useProjectStore()
	const {
		selectedChunkId,
		setSelectedChunkId,
		searchResultIds,
		searchQuery,
		searchMode,
		searchOrderBy,
		searchLoading,
		searchError,
	} = useDocumentContext()

	const appliedCitationFormat = selectedCitationFormat || CitationFormat.DEFAULT

	const documentId = useMemo(() => document.id, [document])

	useEffect(() => {
		if (selectedCitationFormat === CitationFormat.DEFAULT) {
			updateSelectedCitationFormat(getDefaultCitationFormat(document))
		}
	}, [selectedCitationFormat, updateSelectedCitationFormat, document])

	// Define the callback ref
	const measuredRef = useCallback(
		(node: HTMLDivElement | null) => {
			if (selectedChunkRef) {
				selectedChunkRef.current = node
			}
			if (node !== null) {
				// Scroll immediately when the ref is attached
				scrollToCenter(node)
			}
		},
		[selectedChunkRef],
	)

	const isHighlighted = (plainText?: string, searchText?: string): boolean => {
		if (!plainText || !searchText) return false

		// Split the plain text into sentences for more granular matching
		const sentences = plainText.split(/[.!?]+/).map((s) => s.trim())

		const normalizedSearchText = normalizeTextToMatch(searchText)

		// Check each sentence for a match
		for (const sentence of sentences) {
			const normalizedSentence = normalizeTextToMatch(sentence)
			if (normalizedSentence.includes(normalizedSearchText)) {
				return true
			}
		}

		// If no direct match found, try fuzzy search on each sentence
		const fuse = new Fuse(sentences.map(normalizeTextToMatch), {
			includeScore: true,
			threshold: 0.2,
			distance: 1000,
			minMatchCharLength: 10,
		})

		const result = fuse.search(normalizedSearchText)
		return result.length > 0 && (result[0].score || 1) < 0.3
	}

	const handleAddChunk = () => {
		setIsAddDialogOpen(true)
	}

	const handleSaveNewChunk = (chunk: DocumentChunk) => {
		addDocumentChunk({
			documentId,
			text: chunk.text,
			location: chunk.location,
			figureUrls: chunk.figureUrls || [],
		})
		setIsAddDialogOpen(false)
	}

	// Filter chunks based on showChartableOnly
	const displayChunks = useMemo(() => {
		if (!showChartableOnly) return body
		return body.filter((chunk) => chunk.chartable)
	}, [body, showChartableOnly])

	useEffect(() => {
		if (!citationText) return

		const highlightedChunk = displayChunks.find((chunk) =>
			isHighlighted(chunk.text, citationText),
		)

		if (highlightedChunk) {
			setSelectedChunkId(highlightedChunk.id)
		}
	}, [citationText, displayChunks, setSelectedChunkId])

	const renderBody = () => {
		const filteredChunks = displayChunks.filter((chunk, index) => {
			if (!chunk) return false
			if (
				chunk.type === BlockType.CAPTION ||
				chunk.type === BlockType.FIGURE ||
				chunk.type === BlockType.UNKNOWN
			) {
				return false
			}
			if (chunk.text.trim() === "" && index === 0) {
				return false // hide empty text if first chunk
			}
			if (searchResultIds !== null && !searchResultIds.has(chunk.id)) {
				return false
			}
			if (filterIds && !filterIds.includes(chunk.id)) {
				return false
			}
			return true
		})

		if (
			searchMode === SearchMode.SEMANTIC &&
			searchOrderBy === SearchOrder.SCORE &&
			searchResultIds
		) {
			const indexMap = new Map(Array.from(searchResultIds).map((id, index) => [id, index]))
			filteredChunks.sort((a, b) => {
				const indexA = indexMap.get(a.id) ?? Number.POSITIVE_INFINITY
				const indexB = indexMap.get(b.id) ?? Number.POSITIVE_INFINITY
				return indexA - indexB
			})
		}

		if (searchResultIds !== null && filteredChunks.length === 0) {
			return <p className="text-center text-muted">No results found</p>
		}

		return filteredChunks.map((chunk) => {
			return (
				<React.Fragment key={chunk.id}>
					<div
						// Use the callback ref only for the selected chunk
						ref={chunk.id === selectedChunkId ? measuredRef : null}
						data-chunk-id={chunk.id}
						className="group flex items-center hover:cursor-pointer hover:bg-accent/50 mb-2"
						onClick={!editMode ? () => setSelectedChunkId(chunk.id) : undefined}
					>
						<DocumentChunkComponent
							chunk={chunk}
							exactMatchText={searchQuery || citationText}
							citationFormat={appliedCitationFormat}
							highlightedRef={highlightedRef}
							isTextHighlighted={isHighlighted(chunk.text, citationText)}
							figureChunks={figureChunks}
							editMode={editMode}
							onFigureReferenceSelect={onFigureReferenceSelect}
							selectedChunkId={selectedChunkId}
							onChunkSelect={setSelectedChunkId}
							inBody={true}
						/>
					</div>
				</React.Fragment>
			)
		})
	}

	return (
		<div className="mb-4 relative" ref={bodyRef}>
			<div className="flex flex-col gap-4 mb-4">
				<div className="flex items-center justify-between">
					<div className="flex items-center gap-2">
						<Label className="pb-0">Body</Label>
						{editMode && (
							<Button variant="ghost" size="icon" onClick={handleAddChunk}>
								<Plus className="h-4 w-4" />
							</Button>
						)}
					</div>
					<div className="flex items-center gap-2">
						<Button
							variant="outline"
							size="sm"
							onClick={() => {
								if (onToggleChartableOnly) {
									onToggleChartableOnly()
								}
							}}
						>
							{showChartableOnly ? "Show All" : "Chartable Only"}
						</Button>

						<DocumentChunkLocationFormatSelect
							citationFormat={appliedCitationFormat}
							onFormatChange={updateSelectedCitationFormat}
							isPatentApplication={document.patent?.type === PatentType.PUBLISHED_APPLICATION}
						/>
					</div>
				</div>
				<div className="flex flex-col gap-1 w-full">
					<div className="relative">
						<DocumentSearchInput />
					</div>
					<div className="flex items-center justify-center">
						{searchLoading && <Loader message="Searching document..." />}
						{searchError && <span className="text-red-500">Error</span>}
					</div>
				</div>
			</div>

			<div className="px-2">{body?.length > 0 ? renderBody() : null}</div>

			<EditDocumentChunkDialog
				chunk={
					{
						id: "",
						text: "",
						location: {
							pages: [null, null],
							paragraphs: [null, null],
							columns: [null, null],
							lines: [null, null],
							section: null,
						},
						figureUrls: [],
					} as DocumentChunk
				}
				open={isAddDialogOpen}
				onOpenChange={setIsAddDialogOpen}
				onSave={handleSaveNewChunk}
				figureChunks={figureChunks}
				isSaving={addDocumentChunkIsLoading}
			/>
		</div>
	)
}

export default DocumentBody
