import type {
	Claim,
	ClaimLimitation,
	CollapsedClaim,
	CollapsedLimitation,
} from "@/types"
/**
 * Converts a list of collapsed claims with collapsed limitations to a list of claims with limitations
 */
export function expandCollapsedClaims(collapsedClaims: CollapsedClaim[]): Claim[] {
	const claims: Claim[] = []
	for (const collapsedClaim of collapsedClaims) {
		const claim: Claim = {
			id: collapsedClaim.id,
			number: collapsedClaim.number,
			isAsserted: collapsedClaim.isAsserted,
			isIndependent: collapsedClaim.isIndependent,
			patentId: collapsedClaim.patentId ?? "",
			limitations: [],
		}
		const claimLimitations: ClaimLimitation[] = []
		if (collapsedClaim.collapsedLimitations) {
			for (const group of collapsedClaim.collapsedLimitations) {
				for (const lim of group.limitations) {
					claimLimitations.push(lim)
				}
			}
		}
		claim.limitations = claimLimitations
		claims.push(claim)
	}

	return claims
}

/**
 * Converts a list of collapsed limitations to a list of claim limitations
 */
export function getFlattenedLimitationsFromCollapsed(
	collapsedLimitations: CollapsedLimitation[],
): ClaimLimitation[] {
	return collapsedLimitations.map((cl) => ({
		id: cl.id,
		label: cl.label,
		text: cl.limitations.map((lim) => lim.text).join(" "),
		html: cl.limitations.map((lim) => lim.html).join(" "),
		groups: cl.limitations.flatMap((lim) => lim.groups),
		commentary:
			cl.limitations
				.map((lim) => lim.commentary)
				.filter(Boolean)
				.join("; ") || null,
		claimId: cl.claimId,
	}))
}

export function isCollapsedClaim(
	claim: Claim | CollapsedClaim,
): claim is CollapsedClaim {
	return "collapsedLimitations" in claim
}

export function getFullClaimText(claim: Claim | CollapsedClaim): string {
	return isCollapsedClaim(claim)
		? (claim.collapsedLimitations || [])
				.map((l) => l.limitations.map((l) => l.text).join(" "))
				.join(" ")
		: (claim.limitations || []).map((l) => l.text).join(" ")
}

/**
 * Sorts claims by claim number and sorts limitations within each claim by their label
 * Labels like "1", "10.2", "1.4.3" are sorted numerically by each segment
 */
export function sortClaims(claims: Claim[]): Claim[] {
	// First sort claims by number
	const sortedClaims = [...claims].sort((a, b) => {
		return (a.number || 0) - (b.number || 0)
	})

	// Then sort limitations within each claim by label
	for (const claim of sortedClaims) {
		if (claim.limitations) {
			claim.limitations.sort((a, b) => {
				const aLabel = a.label || ""
				const bLabel = b.label || ""

				const aParts = aLabel.split(".").map(Number)
				const bParts = bLabel.split(".").map(Number)

				// Compare each segment
				for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
					if (aParts[i] !== bParts[i]) {
						return aParts[i] - bParts[i]
					}
				}

				// If all segments so far are equal, shorter label comes first
				return aParts.length - bParts.length
			})
		}
	}

	return sortedClaims
}
