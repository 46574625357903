export const DEFAULT_SCROLL_TIMEOUT = 100
export const DEFAULT_HIGHLIGHT_DURATION = 1000

/**
 * Smoothly scrolls an element into view within its container and temporarily highlights it.
 *
 * @param element - The element to scroll into view.
 * @param timeout - Delay before starting the scroll (default is 100ms).
 * @param highlightDuration - Duration for which the highlight is shown (default is 1000ms).
 */
export function scrollToElement(
	element: HTMLElement,
	timeout = DEFAULT_SCROLL_TIMEOUT,
	highlightDuration = DEFAULT_HIGHLIGHT_DURATION,
) {
	setTimeout(() => {
		try {
			const container = element.closest(".overflow-y-auto") as HTMLElement
			if (container) {
				const containerRect = container.getBoundingClientRect()
				const elementRect = element.getBoundingClientRect()
				const relativeTop = elementRect.top - containerRect.top
				const scrollPosition =
					container.scrollTop +
					relativeTop -
					containerRect.height / 2 +
					elementRect.height / 2
				container.scrollTo({
					top: scrollPosition,
					behavior: "smooth",
				})
				element.classList.add("bg-blue-100")
				setTimeout(() => element.classList.remove("bg-blue-100"), highlightDuration)
			}
		} catch (error) {
			console.error("Error during scroll:", error)
		}
	}, timeout)
}

/**
 * Scrolls the given element so that it is centered within its nearest scrollable parent.
 * If you need to handle horizontal scrolling, just adapt the same logic with "left" and "width".
 *
 * @param element       The DOM element to scroll into view.
 * @param scrollOptions Optional: { behavior: "smooth" | "auto" }
 */
export function scrollToCenter(
	element: HTMLElement,
	scrollOptions: ScrollToOptions = { behavior: "smooth" },
): void {
	if (!element) return

	// Find the nearest scrollable parent (or bail if none).
	const parent = getScrollableParent(element)
	if (!parent) return

	const parentRect = parent.getBoundingClientRect()
	const elemRect = element.getBoundingClientRect()

	// Distance from the parent's top edge to the element's top edge
	const offsetTop = elemRect.top - parentRect.top

	// The parent's current scroll offset
	const currentScrollTop = parent.scrollTop

	// The center position for the element:
	// "offsetTop" (where it starts) plus half the element's height,
	// minus half the parent's visible height.
	const desiredScrollTop =
		currentScrollTop + offsetTop + elemRect.height / 2 - parentRect.height / 2

	// Perform scroll
	parent.scrollTo({
		top: desiredScrollTop,
		...scrollOptions,
	})
}

/**
 * Scrolls the given element so that its top aligns with the top of its nearest scrollable parent.
 *
 * @param element       The DOM element to scroll into view.
 * @param scrollOptions Optional: { behavior: "smooth" | "auto" }
 */
export function scrollToElementTop(
	element: HTMLElement,
	scrollOptions: ScrollToOptions = { behavior: "smooth" },
): void {
	if (!element) return

	// Find the nearest scrollable parent (or bail if none).
	const parent = getScrollableParent(element)
	if (!parent) return

	const parentRect = parent.getBoundingClientRect()
	const elemRect = element.getBoundingClientRect()

	// Calculate the vertical offset from the parent's top edge to the element's top edge.
	const offsetTop = elemRect.top - parentRect.top

	// New scroll position is the parent's current scroll plus the offset.
	const newScrollTop = parent.scrollTop + offsetTop

	parent.scrollTo({
		top: newScrollTop,
		...scrollOptions,
	})
}

/**
 * Traverses upward until it finds a scrollable parent (one with "overflow: auto" or "overflow: scroll").
 */
function getScrollableParent(el: HTMLElement | null): HTMLElement | null {
	if (!el) return null
	let parent = el.parentElement
	while (parent) {
		const style = window.getComputedStyle(parent)
		const overflowY = style.overflowY
		if (overflowY === "auto" || overflowY === "scroll") {
			return parent
		}
		parent = parent.parentElement
	}
	return null
}

export function scrollElementTopToCenter(element: HTMLElement) {
	// Find the closest scrollable container. We search upward until we find one
	// whose scroll height is larger than its client height.
	let container: HTMLElement | null = element.parentElement
	while (container && container.scrollHeight <= container.clientHeight) {
		container = container.parentElement
	}
	if (!container) {
		return
	}

	// Compute the element's offset relative to the container by using
	// getBoundingClientRect along with container.scrollTop
	const containerRect = container.getBoundingClientRect()
	const elementRect = element.getBoundingClientRect()
	const offsetY = elementRect.top - containerRect.top + container.scrollTop

	// Set the target scroll so that the element's top is at the vertical center.
	const targetScrollTop = offsetY - container.clientHeight / 2

	container.scrollTo({
		top: targetScrollTop,
		behavior: "smooth",
	})
}
