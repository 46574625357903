import VariableInput from "@/components/input/VariableInput"
import { Button } from "@/components/ui/button"
import { CheckboxWithText } from "@/components/ui/checkbox"
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { LoadingButton } from "@/components/ui/loading-button"
import { useClaims, useProjectSettings } from "@/hooks"
import type { CollapsedLimitation } from "@/types"
import { VariableType } from "@/utils/variables"
import type React from "react"
import { useCallback, useMemo, useState } from "react"
import { useChartsContext } from "../../context/ChartsContext"
import { useChunkToLimitationUpdates } from "../../hooks"
interface EditCellBoilerplateModalProps {
	isOpen: boolean
	onClose: () => void
	applyToAllLimitations?: boolean
	claimLimitation?: CollapsedLimitation
	documentIds?: string[]
	cellHeader?: string
	cellFooter?: string
	cellMiddle?: string
	atCellLevel?: boolean
}

const EditCellBoilerplateModal: React.FC<EditCellBoilerplateModalProps> = ({
	isOpen,
	onClose,
	applyToAllLimitations = false,
	claimLimitation,
	documentIds,
	cellHeader,
	cellFooter,
	cellMiddle,
	atCellLevel = false,
}) => {
	const [isSaving, setIsSaving] = useState(false)
	const { updateCellBoilerplate } = useChunkToLimitationUpdates()
	const { settings } = useProjectSettings()
	const { subjectId } = useChartsContext()
	const { claimLimitations } = useClaims(subjectId)
	const { chartData } = useChartsContext()

	const claimLimitationId = claimLimitation?.id

	const allClaimLimitationIds = useMemo(
		() => claimLimitations.map((limitation) => limitation.id) ?? [],
		[claimLimitations],
	)

	const [header, setHeader] = useState<string>(
		() => cellHeader ?? settings.headerFormat ?? "",
	)
	const [footer, setFooter] = useState<string>(
		() => cellFooter ?? settings.footerFormat ?? "",
	)
	const [middle, setMiddle] = useState<string>(() => cellMiddle ?? "")
	const [overwrite, setOverwrite] = useState<boolean>(false)

	const handleSave = useCallback(async () => {
		setIsSaving(true)
		try {
			// Pass the raw placeholders as-is to the backend;
			// they will later be rendered (replaced) only when displayed.
			const payload = {
				patentId: subjectId,
				documentId: documentIds?.[0] ?? "",
				header,
				footer,
				middle,
				overwrite,
				claimLimitationIds: applyToAllLimitations
					? allClaimLimitationIds
					: [claimLimitationId],
			}

			await updateCellBoilerplate(payload)
		} catch (error) {
			console.error("Error updating invalidity boilerplate:", error)
		} finally {
			setIsSaving(false)
			setOverwrite(false)
			onClose()
		}
	}, [
		header,
		footer,
		middle,
		applyToAllLimitations,
		claimLimitationId,
		documentIds,
		updateCellBoilerplate,
		onClose,
		overwrite,
		allClaimLimitationIds,
	])

	const cellBoilerplateOptions = [
		{
			id: "header",
			label: "Header",
			value: header,
			onChange: setHeader,
		},
		{
			id: "middle",
			label: "Middle",
			value: middle,
			onChange: setMiddle,
		},
		{
			id: "footer",
			label: "Footer",
			value: footer,
			onChange: setFooter,
		},
	]

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[800px]">
				<DialogHeader>
					<DialogTitle>Edit {atCellLevel ? "Cell" : "Table Cell"} Language</DialogTitle>
				</DialogHeader>

				<div className="space-y-4 pb-4">
					{cellBoilerplateOptions.map((option) => (
						<div key={option.id} className="space-y-2">
							<VariableInput
								label={option.label}
								value={option.value}
								onChange={option.onChange}
								type={VariableType.cellBoilerplate}
								multiline={true}
								documentIds={documentIds}
								chartData={chartData}
								claimLimitation={claimLimitation}
							/>
						</div>
					))}
					{!atCellLevel && (
						<div>
							<CheckboxWithText
								id="overwrite"
								checked={overwrite}
								onCheckedChange={(checked) => setOverwrite(checked as boolean)}
								label="Overwrite existing settings"
							/>
						</div>
					)}
				</div>

				<DialogFooter>
					<Button variant="outline" onClick={onClose}>
						Cancel
					</Button>
					<LoadingButton loading={isSaving} type="button" onClick={handleSave}>
						Save changes
					</LoadingButton>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default EditCellBoilerplateModal
