import { DocumentNamePill } from "@/components/documents/DocumentNamePill"
import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import type { Suggested103Combination } from "@/types"
import { SparklesIcon } from "lucide-react"
import type React from "react"
import { useState } from "react"

interface SuggestedCombinationsTooltipProps {
	suggestedCombinations: Suggested103Combination[]
	onSelectCombination: (documentIds: string[]) => void
}

const SuggestedCombinationsTooltip: React.FC<SuggestedCombinationsTooltipProps> = ({
	suggestedCombinations,
	onSelectCombination,
}) => {
	const { getDocumentName } = useDocumentNaming()
	const [isOpen, setIsOpen] = useState(false)

	if (!suggestedCombinations || suggestedCombinations.length === 0) {
		return null
	}

	// Transform combinations to display format
	const formattedCombinations = suggestedCombinations.map((combo) => {
		return {
			documentIds: [combo.documentId, combo.bestMatchDocumentId],
			documents: [
				{ id: combo.documentId, name: getDocumentName(combo.documentId) },
				{ id: combo.bestMatchDocumentId, name: getDocumentName(combo.bestMatchDocumentId) },
			],
		}
	})

	return (
		<Popover open={isOpen} onOpenChange={setIsOpen}>
			<PopoverTrigger asChild>
				<Button variant="ghost" size="icon" className="h-6 w-6 p-0">
					<SparklesIcon className="h-4 w-4 text-yellow-600" />
				</Button>
			</PopoverTrigger>
			<PopoverContent
				side="bottom"
				align="start"
				className="w-[400px] p-4 bg-white border border-gray-200 shadow-md text-black"
				sideOffset={5}
				forceInline
			>
				<div className="flex flex-col gap-3">
					<h4 className="font-semibold text-black">Suggested Combination Charts</h4>
					<div className="max-h-60 overflow-y-auto">
						{formattedCombinations.map((chart) => (
							<div
								key={`${chart.documentIds[0]}-${chart.documentIds[1]}`}
								className="border rounded p-2 mb-2"
							>
								<div className="flex justify-between items-center gap-2">
									<div className="flex flex-wrap gap-2 items-center">
										<DocumentNamePill doc={chart.documents[0]} disabled={true} />
										<span>+</span>
										<DocumentNamePill doc={chart.documents[1]} disabled={true} />
									</div>
									<Button
										variant="outline"
										size="sm"
										onClick={() => onSelectCombination(chart.documentIds)}
										className="text-muted-foreground whitespace-nowrap ml-2"
									>
										Use
									</Button>
								</div>
							</div>
						))}
					</div>
				</div>
			</PopoverContent>
		</Popover>
	)
}

export default SuggestedCombinationsTooltip
