import { useFileUtils } from "@/hooks"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import {
	DocumentRole,
	type DocumentToProcess,
	DocumentType,
	PatentType,
	ProcessType,
	type UnprocessedDocument,
	UnprocessedDocumentType,
} from "@/types"
import { useCallback } from "react"

export const docRoleToProcessType = {
	[DocumentRole.PRIOR_ART]: ProcessType.ADD_PRIOR_ART,
	[DocumentRole.SUBJECT]: ProcessType.ADD_SUBJECT,
} as const

/**
 * @description Hook for handling document processing and reprocessing
 */
const useConvertToDocumentsToProcess = () => {
	const { getUploadedFileKeysAndUrls } = useFileUtils()

	const mapUnprocessedDocumentTypeToDocumentType = (
		inputType: UnprocessedDocumentType,
	): DocumentType => {
		switch (inputType) {
			case UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION:
			case UnprocessedDocumentType.UNPUBLISHED_PATENT:
				return DocumentType.PATENT
			case UnprocessedDocumentType.STANDARD:
				return DocumentType.STANDARD
			case UnprocessedDocumentType.NPL:
				return DocumentType.NPL
			case UnprocessedDocumentType.OFFICE_ACTION:
				return DocumentType.OFFICE_ACTION
			case UnprocessedDocumentType.PRODUCT:
				return DocumentType.PRODUCT
		}
	}

	const convertToDocumentsToProcess = useCallback(
		async (documents: UnprocessedDocument[]): Promise<DocumentToProcess[]> => {
			const processedDocs = await Promise.all(
				documents.map(async (doc) => {
					// Create base document with required fields
					const baseDocument: DocumentToProcess = {
						doc_type: mapUnprocessedDocumentTypeToDocumentType(doc.type),
						doc_role: doc.role as DocumentRole,
					}
					// Add additional fields based on document type
					switch (doc.type) {
						case UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION: {
							const selectedKindCode = doc.patentDetails?.selectedKindCode
							if (!selectedKindCode) {
								return
							}
							// find the matching option that has the kind code and use its number.
							let publicationNumber = doc.patentDetails?.number
							if (selectedKindCode && doc.patentDetails?.details?.[selectedKindCode]?.number) {
								publicationNumber = doc.patentDetails.details[selectedKindCode].number
							}
							return {
								...baseDocument,
								patent_details: {
									publication_number: publicationNumber,
									patent_type: doc.patentDetails?.details?.[selectedKindCode]?.patentType,
								},
							}
						}
						case UnprocessedDocumentType.UNPUBLISHED_PATENT:
							return {
								...baseDocument,
								patent_details: {
									unpublished_patent: {
										...doc.unpublishedPatent,
										claims:
											doc.unpublishedPatent?.claims?.map((claim) => ({
												text: claim,
											})) ?? [],
									},
									patent_type: PatentType.SUBMITTED_APPLICATION,
								},
							}

						case UnprocessedDocumentType.STANDARD:
						case UnprocessedDocumentType.NPL:
						case UnprocessedDocumentType.OFFICE_ACTION:
							if (doc.file) {
								// Get signed URL and upload to S3
								const fileKeys = await getUploadedFileKeysAndUrls([doc.file])
								const s3Key = fileKeys[doc.file.name].s3_key

								return {
									...baseDocument,
									s3_key: s3Key,
									file_name: doc.file.name,
								}
							}
							return baseDocument
						case UnprocessedDocumentType.PRODUCT:
							if (doc.productDetails) {
								let s3Key = undefined
								let fileName = undefined

								// First try to get file from doc.file
								if (doc.file) {
									const fileKeys = await getUploadedFileKeysAndUrls([doc.file])
									s3Key = fileKeys[doc.file.name].s3_key
									fileName = doc.file.name
								}
								// If no file but have URLs, download and upload PDF
								else if (doc.productDetails.specificationUrl) {
									const url = doc.productDetails.specificationUrl
									try {
										const defaultFileName = `${doc.productDetails.productName.replace(/\s+/g, "_")}.pdf`
										const response = await fetch(url)
										const blob = await response.blob()
										const pdfFile = new File([blob], defaultFileName, {
											type: "application/pdf",
										})

										const fileKeys = await getUploadedFileKeysAndUrls([pdfFile])
										s3Key = fileKeys[pdfFile.name].s3_key
										fileName = pdfFile.name
									} catch (error) {
										console.error("Error downloading product specification URL as PDF:", error)
									}
								}

								return {
									...baseDocument,
									...(s3Key && { s3_key: s3Key }),
									...(fileName && { file_name: fileName }),
									product_details: {
										product_name: doc.productDetails.productName,
										product_url: doc.productDetails.productUrl,
										seller: doc.productDetails.seller,
										product_id: doc.productDetails.productId,
										specifications_url: doc.productDetails.specificationUrl,
									},
								}
							}
							return baseDocument
						default:
							return baseDocument
					}
				}),
			)

			return processedDocs as DocumentToProcess[]
		},
		[getUploadedFileKeysAndUrls],
	)

	return {
		convertToDocumentsToProcess,
	}
}

export default useConvertToDocumentsToProcess
