import type { Claim } from "@/types"
import type { components } from "schema"
import type { KeysToCamelCase } from "./utils"

export type Location = KeysToCamelCase<components["schemas"]["Location"]>
export interface Bbox {
	top: number // Percentage from the top of the page
	left: number // Percentage from the left of the page
	width: number // Percentage of page width
	height: number // Percentage of page height
	page: number
}
export type DocumentChunk = Omit<
	KeysToCamelCase<components["schemas"]["DocumentChunk"]>,
	"location"
> & {
	location: Location
}

export type InvalidityChart = KeysToCamelCase<components["schemas"]["InvalidityChart"]>

export type ChunkToLimitation = Omit<
	KeysToCamelCase<components["schemas"]["ChunkToLimitation"]>,
	"documentChunk" | "claim_citation"
> & {
	documentChunk: DocumentChunk
	claimCitation: Claim
	feedback?: "positive" | "negative" | null
}

export type DocumentCitations = {
	[documentId: string]: ChunkToLimitation[]
}

export type LimitationToChart = KeysToCamelCase<
	components["schemas"]["LimitationToChart"]
>

export type ClaimLimitations = {
	[limitationId: string]: DocumentCitations
}

export type Claims = {
	[claimId: string]: ClaimLimitations
}

export type ClaimCitation = KeysToCamelCase<components["schemas"]["Claim"]>

export type InvalidityChartDataRow = {
	citations: DocumentCitations
	metadata: LimitationToChart
}

export type InvalidityChartData = {
	[limitationId: string]: InvalidityChartDataRow
}

export type DocumentInvalidityData = {
	[documentId: string]: ChunkToLimitation[]
}

export type InvalidityClaimRowData = {
	[key: string]: {
		// limitationId
		[key: string]: ChunkToLimitation[] // documentId
	}
}

export type InvalidityLimitationRowData = {
	[key: string]: ChunkToLimitation[] // documentId
}

export type CitationSearchResult = KeysToCamelCase<
	components["schemas"]["CitationSearchResult"]
>

export type ClaimStrength = KeysToCamelCase<components["schemas"]["ClaimStrength"]>

export type InvaliditySummaryColumn = KeysToCamelCase<
	components["schemas"]["InvaliditySummary"]
>
export type InvaliditySummaryEntry = KeysToCamelCase<
	components["schemas"]["InvaliditySummaryEntry"]
>

export type ClaimTypesSchema = components["schemas"]["ClaimTypes"]
export const ClaimTypes = {
	SELECTED_CLAIMS: "Selected Claims",
	INDEPENDENT_CLAIMS: "Independent Claims",
	ALL_CLAIMS: "All Claims",
	ASSERTED_CLAIMS: "Asserted Claims",
} as const satisfies Record<string, ClaimTypesSchema>
export type ClaimTypes = (typeof ClaimTypes)[keyof typeof ClaimTypes]

export enum CitationType {
	CLAIM = "claim",
	CHUNK = "chunk", // figures are chunks
}

export enum ChartMode {
	TABLE = "table",
	DOCUMENT = "document",
}

export type Suggested103Combination = KeysToCamelCase<
	components["schemas"]["Suggested103Combination"]
>

export type FormattedInvalidityAnalysis = KeysToCamelCase<
	components["schemas"]["FormattedInvalidityAnalysis"]
>

export enum InvalidityType {
	ANTICIPATION = "102",
	OBVIOUSNESS = "103",
	SPECIFICATION = "112",
}

export type GenerateInvalidityAnalysisResponse = KeysToCamelCase<
	components["schemas"]["GenerateInvalidityAnalysisResponse"]
>

export type GenerateInvalidityAnalysisChartStatus = KeysToCamelCase<
	components["schemas"]["GenerateInvalidityAnalysisChartStatus"]
>
