/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { InfoPopover } from "@/components"
import { AutosizeTextarea } from "@/components/ui/autosize-textarea"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import { Table, TableBody, TableCellCondensed, TableRow } from "@/components/ui/table"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { Paragraph } from "@/components/ui/typography"
import type { DocumentContext } from "@/types"
import type { ImportantFact, SearchContext, TermDefinition } from "@/types/project"
import { MagnifyingGlassIcon, PlusIcon, TrashIcon } from "@radix-ui/react-icons"
import type React from "react"
import { useCallback, useState } from "react"
import { BulkAddModal } from "./ContextModals"

const ContextSubSectionLabel: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => <span className="text-sm font-semibold text-muted-foreground">{children}</span>

const ContextSection: React.FC<{
	label: string
	infoContent?: string
	children: React.ReactNode
}> = ({ label, infoContent, children }) => {
	return (
		<div className="space-y-2 border border-gray-200 rounded-md px-4 py-3">
			<div className="flex items-center justify-between">
				<div className="flex items-center gap-2">
					<Label className="mb-0">{label}</Label>
					{infoContent && <InfoPopover content={infoContent} />}
				</div>
			</div>
			{children}
		</div>
	)
}

interface ExpertKnowledgeData {
	fieldDescription: string
	requiredEducation: string
	yearsExperience: number
	technicalSkills: string[]
}

const defaultExpertKnowledge: ExpertKnowledgeData = {
	fieldDescription: "",
	requiredEducation: "",
	yearsExperience: 0,
	technicalSkills: [],
}

/**
 * @description Expert knowledge section for context
 * @param {string} value - The value of the expert knowledge.
 * @param {function} onChange - Function to change the value of the expert knowledge.
 * @param {boolean} isEditing - Whether the user is editing the expert knowledge.
 */
export const ExpertKnowledge: React.FC<{
	value?: ExpertKnowledgeData | null
	onChange: (value: ExpertKnowledgeData) => void
	isEditing: boolean
}> = ({ value, onChange, isEditing }) => {
	const expertData = value ?? defaultExpertKnowledge
	const infoContent =
		"The level of skill that a person of ordinary skill in the art (POSITA) would have in the relevant field."

	// Generic handler for updating a field
	const handleFieldChange = useCallback(
		(field: keyof ExpertKnowledgeData, fieldValue: any) => {
			onChange({
				...expertData,
				[field]: fieldValue,
			})
		},
		[expertData, onChange],
	)

	// Specific handler for updating technical skills by splitting on newlines and trimming each skill
	const handleTechnicalSkillsChange = useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			const skills = e.target.value
				.split("\n")
				.map((skill) => skill.trim())
				.filter(Boolean)
			handleFieldChange("technicalSkills", skills)
		},
		[handleFieldChange],
	)

	const renderValue = useCallback(
		() => (
			<div className="space-y-2">
				<div>
					<ContextSubSectionLabel>Field Description</ContextSubSectionLabel>
					<Paragraph className="text-sm whitespace-pre-wrap">
						{expertData.fieldDescription}
					</Paragraph>
				</div>
				<div>
					<ContextSubSectionLabel>Required Education</ContextSubSectionLabel>
					<Paragraph className="text-sm">{expertData.requiredEducation}</Paragraph>
				</div>
				<div>
					<ContextSubSectionLabel>Years of Experience:</ContextSubSectionLabel>
					<Paragraph className="text-sm">{expertData.yearsExperience}</Paragraph>
				</div>
				<div>
					<ContextSubSectionLabel>Technical Skills</ContextSubSectionLabel>
					<ul className="list-disc pl-5">
						{expertData.technicalSkills.map((skill, index) => (
							<li key={index} className="text-sm">
								{skill}
							</li>
						))}
					</ul>
				</div>
			</div>
		),
		[expertData],
	)

	return (
		<ContextSection label="Expert Knowledge" infoContent={infoContent}>
			{isEditing ? (
				<div className="space-y-4">
					<div>
						<ContextSubSectionLabel>Field Description</ContextSubSectionLabel>
						<AutosizeTextarea
							value={expertData.fieldDescription}
							onChange={(e) => handleFieldChange("fieldDescription", e.target.value)}
							placeholder="Describe the field of the invention..."
							className="mt-1"
						/>
					</div>
					<div>
						<ContextSubSectionLabel>Required Education</ContextSubSectionLabel>
						<AutosizeTextarea
							value={expertData.requiredEducation}
							onChange={(e) => handleFieldChange("requiredEducation", e.target.value)}
							placeholder="Required education level..."
							className="mt-1"
						/>
					</div>
					<div>
						<ContextSubSectionLabel>Years of Experience</ContextSubSectionLabel>
						<Input
							type="number"
							value={expertData.yearsExperience}
							onChange={(e) => handleFieldChange("yearsExperience", Number(e.target.value))}
							placeholder="Required years of experience..."
							className="mt-1"
						/>
					</div>
					<div>
						<ContextSubSectionLabel>Technical Skills</ContextSubSectionLabel>
						<AutosizeTextarea
							value={expertData.technicalSkills.join("\n")}
							onChange={handleTechnicalSkillsChange}
							placeholder="Enter technical skills (one per line)..."
							className="mt-1"
						/>
					</div>
				</div>
			) : (
				<>
					{expertData.fieldDescription ? (
						renderValue()
					) : (
						<Paragraph className="text-sm text-muted-foreground">
							No expert knowledge added.
						</Paragraph>
					)}
				</>
			)}
		</ContextSection>
	)
}

interface TermsAndDefinitionsProps {
	terms: TermDefinition[]
	onChange: (terms: TermDefinition[]) => void
	isEditing: boolean
	searchTerm: (query: string) => void
	showSearchButton: boolean
}

/**
 * @description Terms and definitions section for context
 * Bulk Add logic has been moved here.
 */
export const TermsAndDefinitions: React.FC<TermsAndDefinitionsProps> = ({
	terms,
	onChange,
	isEditing,
	searchTerm,
	showSearchButton,
}) => {
	const infoContent =
		"Important terms and their definitions relevant to the subject matter."

	// Local bulk add modal logic
	const [isBulkAddModalOpen, setIsBulkAddModalOpen] = useState(false)
	const [bulkAddContent, setBulkAddContent] = useState("")

	const handleBulkAddConfirm = () => {
		const items = bulkAddContent
			.split("\n")
			.map((line) => line.trim())
			.filter((line) => line.length > 0)

		const newTerms = items
			.map((line) => {
				const [maybeTerm, maybeDef] = line.split(":")
				const term = maybeTerm?.trim() ?? ""
				const definition = maybeDef?.trim() ?? ""
				if (!term || !definition) {
					return null
				}
				return { term, definition }
			})
			.filter(Boolean)

		onChange([...terms, ...(newTerms as TermDefinition[])])
		setBulkAddContent("")
		setIsBulkAddModalOpen(false)
	}

	// Update the term at the given index
	const handleTermChange = (index: number, newTerm: string) => {
		const newTerms = [...terms]
		newTerms[index] = { ...newTerms[index], term: newTerm }
		onChange(newTerms)
	}

	// Update the definition at the given index
	const handleDefinitionChange = (index: number, newDefinition: string) => {
		const newTerms = [...terms]
		newTerms[index] = { ...newTerms[index], definition: newDefinition }
		onChange(newTerms)
	}

	const addNewTerm = () => {
		onChange([...terms, { term: `New Term ${terms.length + 1}`, definition: "" }])
	}

	const deleteTerm = (index: number) => {
		const newTerms = terms.filter((_, i) => i !== index)
		onChange(newTerms)
	}

	return (
		<ContextSection label="Terms and Definitions" infoContent={infoContent}>
			{terms.length === 0 && !isEditing && (
				<Paragraph className="text-sm text-muted-foreground">
					No terms or definitions added.
				</Paragraph>
			)}

			{terms.length > 0 && (
				<Table className="mt-2">
					<TableBody>
						{terms.map((item, index) => (
							<TableRow key={index}>
								<TableCellCondensed className="w-[15%] min-w-[150px]">
									{isEditing ? (
										<AutosizeTextarea
											value={item.term}
											onChange={(e) => handleTermChange(index, e.target.value)}
											placeholder="Term"
											className="mt-1"
										/>
									) : (
										item.term
									)}
								</TableCellCondensed>
								<TableCellCondensed className="w-[80%]">
									{isEditing ? (
										<AutosizeTextarea
											value={item.definition}
											onChange={(e) => handleDefinitionChange(index, e.target.value)}
											placeholder="Definition"
											className="mt-1"
										/>
									) : (
										item.definition
									)}
								</TableCellCondensed>
								<TableCellCondensed className="w-[5%]">
									{isEditing ? (
										<Button size="icon" variant="outline" onClick={() => deleteTerm(index)}>
											<TrashIcon />
										</Button>
									) : (
										showSearchButton && (
											<Tooltip>
												<TooltipTrigger>
													<Button
														size="icon"
														variant="ghost"
														onClick={() => searchTerm(`${item.term}: ${item.definition}`)}
													>
														<MagnifyingGlassIcon />
													</Button>
												</TooltipTrigger>
												<TooltipContent>Search in subject</TooltipContent>
											</Tooltip>
										)
									)}
								</TableCellCondensed>
							</TableRow>
						))}
					</TableBody>
				</Table>
			)}

			{/* Bottom, centered controls */}
			{isEditing && (
				<div className="flex justify-center gap-2 mt-2">
					<Button variant="outline" onClick={() => setIsBulkAddModalOpen(true)}>
						Bulk Add
					</Button>
					<Button size="icon" onClick={addNewTerm}>
						<PlusIcon />
					</Button>
				</div>
			)}

			{/* Bulk Add Modal: now contained within TermsAndDefinitions */}
			<BulkAddModal
				isOpen={isBulkAddModalOpen}
				onOpenChange={setIsBulkAddModalOpen}
				bulkAddType="terms"
				bulkAddContent={bulkAddContent}
				setBulkAddContent={setBulkAddContent}
				onConfirm={handleBulkAddConfirm}
			/>
		</ContextSection>
	)
}

interface ImportantFactsProps {
	contextData: DocumentContext
	setContextData: React.Dispatch<React.SetStateAction<DocumentContext>>
	isEditing: boolean
	searchTerm: (query: string) => void
	showSearchButton: boolean
}

/**
 * @description Important facts section for context
 * Bulk Add logic has been moved here.
 */
export const ImportantFacts: React.FC<ImportantFactsProps> = ({
	contextData,
	setContextData,
	isEditing,
	searchTerm,
	showSearchButton,
}) => {
	const infoContent = "Key facts or points related to the subject matter."
	// Use contextData.importantFacts or an empty array if undefined
	const facts = contextData.importantFacts || []

	// Local bulk add modal logic
	const [isBulkAddModalOpen, setIsBulkAddModalOpen] = useState(false)
	const [bulkAddContent, setBulkAddContent] = useState("")

	const handleBulkAddConfirm = () => {
		const items = bulkAddContent
			.split("\n")
			.map((line) => line.trim())
			.filter((line) => line.length > 0)

		const newFacts = items.map((desc) => ({
			category: "None" as const,
			description: desc,
		}))
		onChangeImportantFacts([...facts, ...newFacts])
		setBulkAddContent("")
		setIsBulkAddModalOpen(false)
	}

	const onChangeImportantFacts = (newFacts: ImportantFact[]) => {
		setContextData({
			...contextData,
			importantFacts: newFacts,
		})
	}

	return (
		<ContextSection label="Key Facts" infoContent={infoContent}>
			{facts.length === 0 && !isEditing && (
				<Paragraph className="text-sm text-muted-foreground">No key facts added.</Paragraph>
			)}

			{facts.length > 0 && (
				<Table className="mt-2">
					<TableBody>
						{facts.map((fact, index) => (
							<TableRow key={index}>
								<TableCellCondensed className="w-[95%]">
									{isEditing ? (
										<AutosizeTextarea
											value={fact.description}
											onChange={(e) => {
												const updatedFacts = [...facts]
												updatedFacts[index] = {
													...updatedFacts[index],
													description: e.target.value,
												}
												onChangeImportantFacts(updatedFacts)
											}}
											placeholder="Fact"
											className="mt-1"
										/>
									) : (
										fact.description
									)}
								</TableCellCondensed>
								<TableCellCondensed className="w-[5%]">
									{isEditing ? (
										<Button
											size="icon"
											variant="outline"
											onClick={() => onChangeImportantFacts(facts.filter((_, i) => i !== index))}
										>
											<TrashIcon />
										</Button>
									) : (
										showSearchButton && (
											<Tooltip>
												<TooltipTrigger>
													<Button
														size="icon"
														variant="ghost"
														onClick={() => searchTerm(fact.description)}
													>
														<MagnifyingGlassIcon />
													</Button>
												</TooltipTrigger>
												<TooltipContent>Search in subject</TooltipContent>
											</Tooltip>
										)
									)}
								</TableCellCondensed>
							</TableRow>
						))}
					</TableBody>
				</Table>
			)}

			{/* Bottom, centered controls */}
			{isEditing && (
				<div className="flex justify-center gap-2 mt-2">
					<Button variant="outline" onClick={() => setIsBulkAddModalOpen(true)}>
						Bulk Add
					</Button>
					<Button
						size="icon"
						onClick={() =>
							onChangeImportantFacts([...facts, { category: "None", description: "" }])
						}
					>
						<PlusIcon />
					</Button>
				</div>
			)}

			{/* Bulk Add Modal: now contained within ImportantFacts */}
			<BulkAddModal
				isOpen={isBulkAddModalOpen}
				onOpenChange={setIsBulkAddModalOpen}
				bulkAddType="facts"
				bulkAddContent={bulkAddContent}
				setBulkAddContent={setBulkAddContent}
				onConfirm={handleBulkAddConfirm}
			/>
		</ContextSection>
	)
}

/**
 * @description Prior art search instructions section for context
 * @param {string} instructions - The instructions for conducting a prior art search.
 * @param {function} onChange - Function to change the instructions for conducting a prior art search.
 * @param {boolean} isEditing - Whether the user is editing the instructions for conducting a prior art search.
 */
export const PriorArtSearchInstructions: React.FC<{
	instructions: string
	onChange: (instructions: string) => void
	isEditing: boolean
}> = ({ instructions, onChange, isEditing }) => {
	const infoContent = "Instructions for conducting a prior art search."
	const [selected, setSelected] = useState(instructions)

	const handleSelectChange = (value: string) => {
		setSelected(value)
		onChange(value)
	}

	const renderInstructions = (text: string) => {
		if (!text) return null
		const lines = text.split("\n").filter((line) => line.trim())

		return (
			<Table className="mt-2">
				<TableBody>
					{lines.map((line, index) => (
						<TableRow key={index}>
							<TableCellCondensed>{line}</TableCellCondensed>
						</TableRow>
					))}
				</TableBody>
			</Table>
		)
	}

	return (
		<ContextSection label="Prior Art Search Instructions" infoContent={infoContent}>
			{isEditing ? (
				<>
					{instructions && renderInstructions(instructions)}
					<Select onValueChange={handleSelectChange} value={selected}>
						<SelectTrigger>
							<SelectValue placeholder="Select search instructions" />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value="all-claims">Search all claims</SelectItem>
							<SelectItem value="independent-claims">Prioritize independent claims</SelectItem>
							<SelectItem value="custom">Custom instructions</SelectItem>
						</SelectContent>
					</Select>
					{selected === "custom" && (
						<AutosizeTextarea
							id="search-instructions"
							value={instructions}
							onChange={(e) => onChange(e.target.value)}
							placeholder="Enter custom search instructions..."
							className="h-32"
						/>
					)}
				</>
			) : (
				<>
					{instructions ? (
						instructions === "all-claims" ? (
							<Paragraph className="text-sm mt-2 whitespace-pre-wrap">All Claims</Paragraph>
						) : instructions === "independent-claims" ? (
							<Paragraph className="text-sm mt-2 whitespace-pre-wrap">
								Independent Claims
							</Paragraph>
						) : (
							renderInstructions(instructions)
						)
					) : (
						<Paragraph className="text-sm text-muted-foreground">
							No search instructions added.
						</Paragraph>
					)}
				</>
			)}
		</ContextSection>
	)
}

/**
 * @description Prior art filter instructions section for context
 * @param {string} instructions - The instructions for filtering and ranking prior art results.
 * @param {function} onChange - Function to change the instructions for filtering and ranking prior art results.
 * @param {boolean} isEditing - Whether the user is editing the instructions for filtering and ranking prior art results.
 */
export const PriorArtFilterInstructions: React.FC<{
	instructions: string
	onChange: (instructions: string) => void
	isEditing: boolean
}> = ({ instructions, onChange, isEditing }) => {
	const infoContent = "Instructions for filtering and ranking prior art results."
	return (
		<ContextSection label="Prior Art Filter Instructions" infoContent={infoContent}>
			{isEditing ? (
				<AutosizeTextarea
					id="filter-instructions"
					value={instructions}
					onChange={(e) => onChange(e.target.value)}
					placeholder="Enter instructions for filtering and reranking prior art..."
				/>
			) : (
				<>
					{instructions ? (
						<Paragraph className="text-sm">{instructions}</Paragraph>
					) : (
						<Paragraph className="text-sm text-muted-foreground">
							No filter instructions added.
						</Paragraph>
					)}
				</>
			)}
		</ContextSection>
	)
}

/**
 * @description Prior art filter instructions section for context
 * @param {string} instructions - The instructions for filtering and ranking prior art results.
 * @param {function} onChange - Function to change the instructions for filtering and ranking prior art results.
 * @param {boolean} isEditing - Whether the user is editing the instructions for filtering and ranking prior art results.
 */
export const DocumentSummary: React.FC<{
	summary: string
	onChange: (summary: string) => void
	isEditing: boolean
}> = ({ summary, onChange, isEditing }) => {
	return (
		<ContextSection label="Document Summary">
			{isEditing ? (
				<AutosizeTextarea
					id="filter-instructions"
					value={summary}
					onChange={(e) => onChange(e.target.value)}
					placeholder="Enter document summary..."
				/>
			) : (
				<>
					{summary ? (
						<Paragraph className="text-sm">{summary}</Paragraph>
					) : (
						<Paragraph className="text-sm text-muted-foreground">
							No document summary added.
						</Paragraph>
					)}
				</>
			)}
		</ContextSection>
	)
}

export const RelevantDocuments: React.FC<{
	documents: string[]
	onChange: (documents: string[]) => void
	isEditing: boolean
}> = ({ documents, onChange, isEditing }) => {
	const [_open, setOpen] = useState(false)
	const infoContent = "Relevant documents or URLs for reference (max 3)"
	return (
		<ContextSection label="Relevant Documents" infoContent={infoContent}>
			{documents.length < 3 && isEditing && (
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button size="icon" onClick={() => onChange([...documents, ""])}>
							<PlusIcon />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent>
						<DropdownMenuItem onClick={() => setOpen(true)}>Upload file</DropdownMenuItem>
						<DropdownMenuItem onClick={() => onChange([...documents, ""])}>
							Add URL
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			)}

			{documents.length === 0 && !isEditing && (
				<Paragraph className="text-sm text-muted-foreground">
					No relevant documents added.
				</Paragraph>
			)}

			{documents.length > 0 && (
				<Table className="mt-2">
					<TableBody>
						{documents.map((doc, index) => (
							<TableRow key={index}>
								<TableCellCondensed className="w-[95%]">
									{isEditing ? (
										<Input
											value={doc}
											onChange={(e) => {
												const newDocs = [...documents]
												newDocs[index] = e.target.value
												onChange(newDocs)
											}}
											placeholder="URL"
										/>
									) : (
										doc
									)}
								</TableCellCondensed>
								{isEditing && (
									<TableCellCondensed className="w-[5%]">
										<Button
											size="icon"
											variant="outline"
											onClick={() => onChange(documents.filter((_, i) => i !== index))}
										>
											<TrashIcon />
										</Button>
									</TableCellCondensed>
								)}
							</TableRow>
						))}
					</TableBody>
				</Table>
			)}
		</ContextSection>
	)
}

/**
 * @description Combines search (queryInstructions) and filter (filterSteps) instructions into a single component.
 */
export const SearchInstructions: React.FC<{
	value?: SearchContext
	onChange: (value: SearchContext) => void
	isEditing: boolean
}> = ({ value, onChange, isEditing }) => {
	const infoContent = "Instructions for searching and filtering patents or prior art."
	const queryInstructions = value?.queryInstructions || []
	const filterSteps = value?.filterSteps || []

	// ---- Query instructions handlers ----
	const handleQueryChange = useCallback(
		(index: number, newValue: string) => {
			const updated = [...queryInstructions]
			updated[index] = newValue
			onChange({
				...value,
				queryInstructions: updated,
			})
		},
		[onChange, queryInstructions, value],
	)

	const handleAddQuery = useCallback(() => {
		onChange({
			...value,
			queryInstructions: [...queryInstructions, ""],
		})
	}, [onChange, queryInstructions, value])

	const handleDeleteQuery = useCallback(
		(index: number) => {
			const updated = queryInstructions.filter((_, i) => i !== index)
			onChange({
				...value,
				queryInstructions: updated,
			})
		},
		[onChange, queryInstructions, value],
	)

	// ---- Filter steps handlers ----
	const handleFilterChange = useCallback(
		(index: number, newValue: string) => {
			const updated = [...filterSteps]
			updated[index] = newValue
			onChange({
				...value,
				filterSteps: updated,
			})
		},
		[onChange, filterSteps, value],
	)

	const handleAddFilter = useCallback(() => {
		onChange({
			...value,
			filterSteps: [...filterSteps, ""],
		})
	}, [onChange, filterSteps, value])

	const handleDeleteFilter = useCallback(
		(index: number) => {
			const updated = filterSteps.filter((_, i) => i !== index)
			onChange({
				...value,
				filterSteps: updated,
			})
		},
		[onChange, filterSteps, value],
	)

	// ---- Rendering helpers ----
	const renderList = (
		items: string[],
		onItemChange: any,
		onItemDelete: any,
		isQuerySection = true,
	) => {
		if (items.length === 0 && !isEditing) {
			return (
				<Paragraph className="text-sm text-muted-foreground">
					No {isQuerySection ? "query instructions" : "filter steps"} added.
				</Paragraph>
			)
		}

		return (
			<Table className="mt-2">
				<TableBody>
					{items.map((text, index) => (
						<TableRow key={index}>
							<TableCellCondensed className="w-full">
								{isEditing ? (
									<AutosizeTextarea
										value={text}
										onChange={(e) => onItemChange(index, e.target.value)}
										placeholder={`Enter ${isQuerySection ? "query instruction" : "filter step"}`}
										className="mt-1 w-full"
									/>
								) : (
									text
								)}
							</TableCellCondensed>
							{isEditing && (
								<TableCellCondensed className="w-[5%]">
									<Button size="icon" variant="outline" onClick={() => onItemDelete(index)}>
										<TrashIcon />
									</Button>
								</TableCellCondensed>
							)}
						</TableRow>
					))}
				</TableBody>
			</Table>
		)
	}

	return (
		<ContextSection label="Prior Art Search Instructions" infoContent={infoContent}>
			<div className="space-y-6">
				{/* Query instructions */}
				<div>
					<ContextSubSectionLabel>Search Notes</ContextSubSectionLabel>
					{renderList(queryInstructions, handleQueryChange, handleDeleteQuery, true)}
					{isEditing && (
						<div className="flex justify-center">
							<Button size="icon" onClick={handleAddQuery}>
								<PlusIcon />
							</Button>
						</div>
					)}
				</div>

				{/* Filter steps */}
				<div>
					<ContextSubSectionLabel>Filter Steps</ContextSubSectionLabel>
					{renderList(filterSteps, handleFilterChange, handleDeleteFilter, false)}
					{isEditing && (
						<div className="flex justify-center">
							<Button size="icon" onClick={handleAddFilter}>
								<PlusIcon />
							</Button>
						</div>
					)}
				</div>
			</div>
		</ContextSection>
	)
}
