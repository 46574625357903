/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

import { useChartsContext } from "@/features/charts/context/ChartsContext"
import type { Group } from "@/types"
import type React from "react"
import GroupPill from "./GroupPill"

interface GroupSelectProps {
	groups: Group[]
}

const GroupSelect: React.FC<GroupSelectProps> = ({ groups }) => {
	const { selectedGroupId, setSelectedGroupId } = useChartsContext()
	return (
		<div className="flex flex-row items-center gap-2 pb-2">
			<h3 className="text-xs text-muted-foreground m-0">Groups</h3>
			<div className="flex flex-row gap-2 flex-wrap">
				{groups.map((group) => (
					<GroupPill
						key={group.id}
						group={group}
						handleClick={() =>
							setSelectedGroupId(selectedGroupId === group.id ? undefined : group.id)
						}
						isSelected={selectedGroupId === group.id}
					/>
				))}
			</div>
		</div>
	)
}

export default GroupSelect
