import DualDocumentSelect from "@/components/documents/DualDocumentSelect"
import { AutosizeTextarea } from "@/components/ui/autosize-textarea"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { FloatingLabelInput } from "@/components/ui/floating-input"
import { LoadingButton } from "@/components/ui/loading-button"
import { useInvalidityChartUpdates } from "@/features/charts/hooks/mutations/useInvalidityChartUpdates"
import useSuggested103Combinations from "@/hooks/useSuggested103Combinations"
import type React from "react"
import { useState } from "react"

// Import the new radio group components.
import { RadioGroup, RadioGroupItemWithLabel } from "@/components/ui/radio-group"
import { useProjectContext } from "@/context/ProjectContext"
import SuggestedCombinationsTooltip from "@/features/charts/components/SuggestedCombinationsTooltip"
import {
	filterDocumentsBySubjectId,
	useAllProjectDocuments,
} from "@/features/documents/hooks/useAllProjectDocuments"
import { useAppStateStore } from "@/store"
import { nanoid } from "nanoid"

interface NewChartModalProps {
	open: boolean
	handleClose: () => void
}

/**
 * @description Prune invalidity charts by citation score
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {function} fetchElementChartPageData - Function to fetch the element chart page data.
 */
const NewChartModal: React.FC<NewChartModalProps> = ({ open, handleClose }) => {
	const { chartedDocuments } = useAllProjectDocuments()
	const { addInvalidityChart } = useInvalidityChartUpdates()
	const { subjectId } = useProjectContext()

	// Global state from store
	const { addErrorMessage, addLoadingMessage, removeLoadingMessage, addSuccessMessage } =
		useAppStateStore()

	const [isLoading, setIsLoading] = useState(false)
	const [documentIds, setDocumentIds] = useState<string[]>([])
	const [chartName, setChartName] = useState("")
	const [chartNotes, setChartNotes] = useState("")

	// New state for handling citations copy option.
	// "all" means copy all citations, "top" means copy only the top N citations.
	const [citationCopyOption, setCitationCopyOption] = useState<
		"all" | "top" | "suggested"
	>("all")
	const [numberCitationsToCopy, setNumberCitationsToCopy] = useState(5)
	const { suggested103CombinationCharts } = useSuggested103Combinations()
	// Determines whether the form is valid.
	// (If "top" is chosen, the number of citations to copy must be > 0).
	const isFormValid =
		chartName.trim().length > 0 &&
		documentIds.length > 0 &&
		(citationCopyOption === "all" ||
			citationCopyOption === "suggested" ||
			(citationCopyOption === "top" && numberCitationsToCopy > 0))

	const resetModal = () => {
		setDocumentIds([])
		setChartName("")
		setChartNotes("")
		setCitationCopyOption("all")
		setNumberCitationsToCopy(0)
		setIsLoading(false)
	}

	const onClose = () => {
		resetModal()
		handleClose()
	}

	const handleSave = async () => {
		// Extra check in case the button is somehow triggered when the form is not valid.
		if (!isFormValid) {
			alert(
				"Please provide a chart name, select at least one document, and specify a valid number if copying top citations.",
			)
			return
		}

		setIsLoading(true)
		const key = nanoid()
		addLoadingMessage(`Creating chart ${chartName}...`, key)
		onClose()
		try {
			await addInvalidityChart({
				name: chartName,
				notes: chartNotes,
				subjectId,
				documentIds,
				// Pass the new citation option data: If "all" is selected, send a flag; if "top" is chosen, pass the number.
				...(citationCopyOption === "top" && { numberCitationsToCopy }),
				...(citationCopyOption === "suggested" && { useSuggestedCombinations: true }),
			})
			removeLoadingMessage(key)
			addSuccessMessage(`Chart "${chartName}" created successfully`)
		} catch (error) {
			console.error("Error creating chart:", error)
			removeLoadingMessage(key)
			addErrorMessage("Error creating chart. Please try again.")
		} finally {
			setIsLoading(false)
			onClose()
		}
	}

	// Function to handle selecting a suggested combination
	const handleSelectSuggestedCombination = (documentIds: string[]) => {
		setDocumentIds(documentIds)
		// Optionally set the citation option to "suggested"
		setCitationCopyOption("suggested")
	}

	return (
		<Dialog open={open} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[600px]">
				<DialogHeader>
					<DialogTitle>New Chart</DialogTitle>
					<DialogDescription>Create a new chart by selecting documents.</DialogDescription>
				</DialogHeader>

				<div className="flex flex-col gap-4">
					{/* Document selection with suggested combinations */}
					<div className="flex items-start gap-2">
						{suggested103CombinationCharts && suggested103CombinationCharts.length > 0 && (
							<div className="mt-1">
								<SuggestedCombinationsTooltip
									suggestedCombinations={suggested103CombinationCharts}
									onSelectCombination={handleSelectSuggestedCombination}
								/>
							</div>
						)}
						<div className="flex-1">
							<DualDocumentSelect
								handleDocumentSelect={setDocumentIds}
								documents={filterDocumentsBySubjectId(subjectId, chartedDocuments)}
								selectedDocumentIds={documentIds}
							/>
						</div>
					</div>

					{/* Name field with required indicator */}
					<div className="flex flex-col">
						<FloatingLabelInput
							id="chartName"
							type="text"
							value={chartName}
							onChange={(e) => setChartName(e.target.value)}
							className="px-3 py-2 border rounded-md"
							label="Chart Name *"
						/>
					</div>

					{/* Notes field */}
					<div className="flex flex-col">
						<AutosizeTextarea
							id="chartNotes"
							value={chartNotes}
							onChange={(e) => setChartNotes(e.target.value)}
							className="px-3 py-2 border rounded-md"
							rows={4}
							placeholder="Add optional notes..."
						/>
					</div>

					{/* Citations Copy Option using the custom RadioGroup */}
					<div className="flex flex-col gap-2">
						<div className="flex items-center gap-2">
							<span className="font-medium">Citations</span>
						</div>

						<RadioGroup
							value={citationCopyOption}
							onValueChange={(value) =>
								setCitationCopyOption(value as "all" | "top" | "suggested")
							}
							className="flex gap-4 items-center"
						>
							<RadioGroupItemWithLabel id="citation-all" value="all" label="Copy All" />
							<div className="flex items-center gap-2">
								<RadioGroupItemWithLabel id="citation-top" value="top" label="Copy Top" />
								<input
									type="number"
									min="1"
									disabled={citationCopyOption !== "top"}
									value={citationCopyOption === "top" ? numberCitationsToCopy || "" : ""}
									onChange={(e) => setNumberCitationsToCopy(Number(e.target.value))}
									className="w-20 border rounded-md px-2 py-1"
								/>
							</div>
							{documentIds.length === 2 && (
								<RadioGroupItemWithLabel
									id="citation-suggested"
									value="suggested"
									label="Smart Suggestions"
								/>
							)}
						</RadioGroup>
					</div>
				</div>

				<DialogFooter>
					<div className="flex items-center justify-end gap-2">
						<Button variant="outline" onClick={() => onClose()} disabled={isLoading}>
							Cancel
						</Button>
						<LoadingButton
							variant="default"
							onClick={handleSave}
							loading={isLoading}
							disabled={isLoading || !isFormValid}
						>
							{isLoading ? "Creating..." : "Create"}
						</LoadingButton>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default NewChartModal
