"use client"

import * as React from "react"
import { format } from "date-fns"
import { CalendarIcon } from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

export function DateTimePicker({ value, onChange, placeholder = "Pick a date" }: { value?: Date, onChange?: (date: Date) => void, placeholder?: string }) {
  const [date, setDate] = React.useState<Date | undefined>(value)

  // Update internal state when value prop changes
  React.useEffect(() => {
    // Ensure value is a valid Date object before setting it
    setDate(value instanceof Date && !isNaN(value.getTime()) ? value : undefined);
  }, [value]);

  // Handle date selection
  const handleSelect = (newDate: Date | undefined) => {
    setDate(newDate);
    if (newDate && onChange) {
      onChange(newDate);
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[240px] justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date && !isNaN(date.getTime())
            ? format(date, "PPP")
            : <span>{placeholder}</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start" forceInline>
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleSelect}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}

export default DateTimePicker
