import { useProjectContext } from "@/context/ProjectContext"
import { useApi, useConvertToDocumentsToProcess, useProjectMetadata } from "@/hooks"
import { useAppStateStore, useProcessStore } from "@/store"
import {
	CitationPolicy,
	type DocumentRole,
	DocumentStatus,
	type ProcessType,
	StatusType,
	type UnprocessedDocument,
} from "@/types"
import { nanoid } from "nanoid"
import useProcessMutation, { type ProcessMutationInput } from "./useProcessMutation"

export type ProcessDocumentsInput = {
	documentRole: DocumentRole
	processType: ProcessType
	documents: UnprocessedDocument[]
	useClaimsCheckboxChecked: boolean
	chartAgainstSelf?: boolean
	subjectIds?: string[]
	priorArtIds?: string[]
	infringementIds?: string[]
}
const docStatusMapping = {
	PROCESSED: StatusType.SUCCESS,
	RECHART: StatusType.ERROR,
	RELOAD: StatusType.ERROR,
	UPLOADED: StatusType.SUCCESS,
	ERROR: StatusType.ERROR,
	ALREADY_PROCESSED: StatusType.WARNING,
}

const useProcessDocuments = () => {
	const { handleError } = useApi()
	const { projectId } = useProjectContext()
	const { projectName } = useProjectMetadata()
	const { updateLoadingGroupItem, addLoadingGroupItem, addLoadingGroup } =
		useAppStateStore()
	const { addProcess, removeProcess } = useProcessStore()
	const { convertToDocumentsToProcess } = useConvertToDocumentsToProcess()

	// Get the lower-level mutation hook.
	const { mutateAsync, isPending, ...mutation } = useProcessMutation()

	const processDocuments = async (variables: ProcessDocumentsInput) => {
		const { processType, documents, documentRole, useClaimsCheckboxChecked } = variables

		// Convert raw documents to the required API format.
		const documentsToProcess = await convertToDocumentsToProcess(documents)

		// Create default settings.
		const settings = {
			chart: true,
			claims_in_charting: useClaimsCheckboxChecked,
			citation_policy: CitationPolicy.APPEND,
			reparse: false,
			overwrite: false,
			chart_method: null,
		}

		// Optimistically add loading group and process indicator.
		const processId = nanoid()
		addLoadingGroup(processId, projectName, processType)
		addProcess({
			id: processId,
			type: processType,
			projectIds: [projectId],
		})

		for (const doc of documentsToProcess) {
			const publication_number =
				doc.patent_details && "publication_number" in doc.patent_details
					? doc.patent_details.publication_number
					: null
			const documentKey = doc.s3_key ?? publication_number ?? doc.file_name
			addLoadingGroupItem(
				processId,
				processType,
				documentKey,
				StatusType.PROCESSING,
				"",
				publication_number ?? doc.file_name,
			)
		}

		try {
			// Call the low-level mutation. Notice we pass in the already converted
			// documents and the settings.
			const response = await mutateAsync({
				documentRole,
				processType,
				documentsToProcess,
				settings,
				subjectIds: variables.subjectIds,
				priorArtIds: variables.priorArtIds,
				infringementIds: variables.infringementIds,
				chartAgainstSelf: variables.chartAgainstSelf ?? false,
			} as ProcessMutationInput)

			// On success, loading groups.
			for (const document of response) {
				if (
					document.status === DocumentStatus.PROCESSED &&
					document.statusReason === "Document already exists in project."
				) {
					updateLoadingGroupItem(
						processId,
						processType,
						document.key,
						StatusType.WARNING,
						"Skipped. Document already exists in project.",
					)
				} else {
					updateLoadingGroupItem(
						processId,
						processType,
						document.key,
						docStatusMapping[document.status],
						"",
					)
				}
			}
			removeProcess(processId)

			return response
		} catch (err) {
			// Handle error state in the UI.
			for (const doc of documentsToProcess) {
				const publication_number =
					doc.patent_details && "publication_number" in doc.patent_details
						? doc.patent_details.publication_number
						: null
				const documentKey = doc.s3_key ?? publication_number ?? doc.file_name
				updateLoadingGroupItem(processId, processType, documentKey, StatusType.ERROR, "")
			}
			removeProcess(processId)
			return handleError(err, "Failed to process documents")
		}
	}

	return {
		processDocuments,
		isProcessingPending: isPending,
		...mutation,
	}
}

export default useProcessDocuments
