import { SettingsSection } from "@/components"
import { Input } from "@/components/ui/input"
import type React from "react"
import { useEffect, useState } from "react"

interface BudgetSettingsProps {
	budget: number | null
	onFieldChange: (field: string, value: any) => void
}

const BudgetSettings: React.FC<BudgetSettingsProps> = ({ budget, onFieldChange }) => {
	const [localBudget, setLocalBudget] = useState(budget)

	// Sync local state if the projectMetadata prop changes.
	useEffect(() => {
		setLocalBudget(localBudget)
	}, [localBudget])

	return (
		<SettingsSection
			label="Budget"
			description="The maximum amount of money that can be spent on this project"
		>
			<div className="flex items-center gap-3">
				<Input
					type="text"
					className="w-[150px] h-9"
					placeholder="No Budget"
					value={localBudget ? `$${localBudget.toLocaleString()}` : ""}
					onChange={(e) => {
						const value = e.target.value.replace(/[^0-9]/g, "")
						const newBudget = value ? Number(value) : 0
						setLocalBudget(newBudget)
					}}
					onBlur={() => onFieldChange("budget", localBudget)}
				/>
			</div>
		</SettingsSection>
	)
}

export default BudgetSettings
