import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { useProjectContext } from "@/context/ProjectContext"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import { cn } from "@/lib/utils"

interface DocumentRef {
	id: string
	name: string
}

interface DocumentNamePillProps {
	doc?: DocumentRef
	documentId?: string
	size?: "sm" | "lg"
	className?: string
	disabled?: boolean
}

export const DocumentNamePill = ({
	doc,
	documentId,
	size = "sm",
	className,
	disabled = false,
}: DocumentNamePillProps) => {
	const { setDocumentViewerDocumentId } = useProjectContext()
	const { getDocumentName } = useDocumentNaming()
	const effectiveDocumentId = documentId || doc?.id

	const documentName = getDocumentName(effectiveDocumentId)

	if (!documentName || documentName === "") {
		return null
	}

	return (
		<>
			{disabled ? (
				<div
					className={cn(
						"inline-block rounded-full border border-blue-300 bg-blue-50 text-black",
						"dark:border-blue-700 dark:bg-blue-950 dark:text-white whitespace-nowrap",
						size === "sm" ? "px-2 py-0.5 text-xs" : "px-3 py-1 text-sm",
						className,
					)}
				>
					{documentName}
				</div>
			) : (
				<Tooltip>
					<TooltipTrigger>
						<div
							className={cn(
								"inline-block rounded-full border border-blue-300 bg-blue-50 text-black",
								"dark:border-blue-700 dark:bg-blue-950 dark:text-white whitespace-nowrap",
								size === "sm" ? "px-2 py-0.5 text-xs" : "px-3 py-1 text-sm",
								className,
							)}
							onClick={() => {
								setDocumentViewerDocumentId(effectiveDocumentId)
							}}
						>
							{documentName}
						</div>
					</TooltipTrigger>
					<TooltipContent>View Document</TooltipContent>
				</Tooltip>
			)}
		</>
	)
}

export default DocumentNamePill
