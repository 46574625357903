"use client"
import BaseBarChart from "@/components/charts/BaseBarChart"
import BaseLineChart from "@/components/charts/BaseLineChart"
import { ChartContainer } from "@/components/ui/chart"
import type { AdminChartDataPortfolioProject, AdminProjectSummary } from "@/types/admin"
import { formatTimestamp } from "@/utils/dateUtils"
import * as React from "react"

// Chart configuration for the two lines/bars
const chartConfig = {
	projects: {
		label: "Projects",
		color: "#7FB2FF",
	},
}

// A custom tooltip that renders the date and counts for both types
const CustomTooltip = ({ active, payload, label }: any) => {
	if (active && payload && payload.length) {
		const data = payload[0].payload
		if (data.date) {
			return (
				<div className="bg-background border p-2 shadow">
					<p className="font-bold">
						{new Date(label).toLocaleDateString("en-US", {
							month: "short",
							day: "numeric",
							year: "numeric",
						})}
					</p>
					<p className="mt-1">Projects: {data.projectCount}</p>
				</div>
			)
		}
		if (data.user || data.organization) {
			return (
				<div className="bg-background border p-2 shadow">
					<p className="font-bold">{data.user || data.organization}</p>
					<p className="mt-1">Projects: {data.projectCount}</p>
				</div>
			)
		}
	}
	return null
}

export function ProjectsUsageChart({
	projects,
	isBarChart = false,
	groupBy = "date",
}: {
	projects: AdminProjectSummary[]
	isBarChart?: boolean
	groupBy?: "date" | "user" | "organization"
}) {
	// Determine effective grouping: always "date" for line charts.
	const effectiveGroupBy = isBarChart ? groupBy : "date"

	// Group the data based on the effective grouping
	const chartData = React.useMemo(() => {
		if (effectiveGroupBy === "date") {
			const groups: Record<
				string,
				{
					date: string
					portfolioCount: number
					projectCount: number
					portfolioBreakdown: Record<string, number>
					projectBreakdown: Record<string, number>
				}
			> = {}

			for (const item of projects) {
				if (!item.createdAt) continue
				const dateKey = formatTimestamp(new Date(item.createdAt).toISOString())

				if (!groups[dateKey]) {
					groups[dateKey] = {
						date: dateKey,
						portfolioCount: 0,
						projectCount: 0,
						portfolioBreakdown: {},
						projectBreakdown: {},
					}
				}

				const user = item.createdBy || "Unknown"

				if (item.type === "Portfolio") {
					groups[dateKey].portfolioCount++
					groups[dateKey].portfolioBreakdown[user] =
						(groups[dateKey].portfolioBreakdown[user] || 0) + 1

					if ("children" in item && Array.isArray(item.children)) {
						groups[dateKey].projectCount += item.children.length
						for (const child of item.children) {
							const childUser = child.createdBy || user
							groups[dateKey].projectBreakdown[childUser] =
								(groups[dateKey].projectBreakdown[childUser] || 0) + 1
						}
					}
				} else {
					groups[dateKey].projectCount++
					groups[dateKey].projectBreakdown[user] =
						(groups[dateKey].projectBreakdown[user] || 0) + 1
				}
			}

			return Object.values(groups).sort(
				(a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
			)
		}
		// Group by user or organization
		const groups: Record<
			string,
			{
				user?: string
				organization?: string
				portfolioCount: number
				projectCount: number
				portfolioBreakdown: Record<string, number>
				projectBreakdown: Record<string, number>
			}
		> = {}

		for (const item of projects) {
			const groupKey =
				effectiveGroupBy === "user"
					? item.createdBy || "Unknown"
					: item.organizationName || "Unknown"

			if (!groups[groupKey]) {
				groups[groupKey] = {
					portfolioCount: 0,
					projectCount: 0,
					portfolioBreakdown: {},
					projectBreakdown: {},
				}

				if (effectiveGroupBy === "user") {
					groups[groupKey].user = groupKey
				} else {
					groups[groupKey].organization = groupKey
				}
			}

			if (item.type === "Portfolio") {
				groups[groupKey].portfolioCount++
				if ("children" in item && Array.isArray(item.children)) {
					groups[groupKey].projectCount += item.children.length
				}
			} else {
				groups[groupKey].projectCount++
			}
		}

		return Object.values(groups).sort((a, b) => {
			const aKey = effectiveGroupBy === "user" ? a.user || "" : a.organization || ""
			const bKey = effectiveGroupBy === "user" ? b.user || "" : b.organization || ""
			return aKey.localeCompare(bKey)
		})
	}, [projects, effectiveGroupBy])

	if (isBarChart) {
		return (
			<BaseBarChart
				data={chartData as AdminChartDataPortfolioProject[]}
				xKey={effectiveGroupBy as keyof AdminChartDataPortfolioProject}
				yKeys={["portfolioCount", "projectCount"]}
				barColors={[chartConfig.projects.color]}
				chartHeight={250}
				margin={{ top: 20, right: 12, bottom: 20, left: 12 }}
				tooltipContent={<CustomTooltip />}
				chartConfig={chartConfig}
				showLabels={true}
			/>
		)
	}

	return (
		<ChartContainer config={chartConfig} className="aspect-auto h-[250px] w-full">
			<BaseLineChart
				data={chartData as AdminChartDataPortfolioProject[]}
				xKey={effectiveGroupBy as keyof AdminChartDataPortfolioProject}
				yKeys={["portfolioCount", "projectCount"]}
				lineColors={[chartConfig.projects.color]}
				chartHeight={250}
				margin={{ top: 20, right: 12, bottom: 20, left: 12 }}
				xTickFormatter={(value) => {
					if (effectiveGroupBy === "date") {
						const date = new Date(value)
						return date.toLocaleDateString("en-US", {
							month: "short",
							day: "numeric",
						})
					}
					return value
				}}
				tooltipContent={<CustomTooltip />}
				chartConfig={chartConfig}
			/>
		</ChartContainer>
	)
}

export default ProjectsUsageChart
