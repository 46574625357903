import { DocumentChunkComponent } from "@/components/documents/sections/body/DocumentChunk"
import type { DocumentChunk } from "@/types"
import { BlockType } from "@/types"
import type React from "react"
import DocumentChunkActions from "./DocumentChunkActions"
import { FigureComponent } from "./FigureComponent"

export interface DocumentChunkCitationProps {
	chunk: DocumentChunk
	showFigures?: boolean
	onAddCitation?: (chunk: DocumentChunk) => void
	activeLimitationId?: string
	exactMatchText?: string
}

export const DocumentChunkCitation: React.FC<DocumentChunkCitationProps> = ({
	chunk,
	showFigures = false,
	onAddCitation,
	activeLimitationId,
	exactMatchText,
}) => {
	return (
		<DocumentChunkActions
			chunk={chunk}
			onAddCitation={onAddCitation}
			activeLimitationId={activeLimitationId}
		>
			{chunk.type === BlockType.FIGURE ? (
				<FigureComponent chunk={chunk} />
			) : (
				<DocumentChunkComponent
					chunk={chunk}
					showFigures={showFigures}
					exactMatchText={exactMatchText}
				/>
			)}
		</DocumentChunkActions>
	)
}

export default DocumentChunkCitation
