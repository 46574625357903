import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import useClaims from "@/hooks/project/useClaims"
import type { Group } from "@/types"
import { CheckIcon, Pencil1Icon, TrashIcon } from "@radix-ui/react-icons"
import type React from "react"
import { useState } from "react"
import ClaimMultiSelect from "./ClaimMultiSelect"
import ElementPill from "./ElementPill"

interface LimitationGroupCardProps {
	subjectId: string
	group: Group
	onSave: ({
		subjectId,
		groupId,
		limitationIds,
		number,
		nickname,
	}: {
		subjectId: string
		groupId: string
		limitationIds: string[]
		number: number
		nickname: string
	}) => void
	onDelete: ({ subjectId, groupId }: { subjectId: string; groupId: string }) => void
	isLoading?: boolean
	isNew?: boolean
}

const LimitationGroupCard: React.FC<LimitationGroupCardProps> = ({
	subjectId,
	group,
	onSave,
	onDelete,
	isLoading = false,
	isNew = false,
}) => {
	const limitations = group.limitations.map((l) => l.claimLimitation)
	const { subjectClaims } = useClaims(subjectId)
	const [isEditing, setIsEditing] = useState(isNew)
	const [editedNumber, setEditedNumber] = useState(group.number)
	const [editedNickname, setEditedNickname] = useState(group.nickname || "")
	const [selectedLimitations, setSelectedLimitations] = useState<string[]>(
		limitations.map((l) => l.id),
	)
	const [error, setError] = useState<string | null>(null)

	const handleSave = () => {
		setError(null)

		if (selectedLimitations.length === 0) {
			setError("Please select at least one limitation")
			return
		}

		onSave({
			subjectId: subjectId,
			groupId: group.id,
			limitationIds: selectedLimitations,
			number: editedNumber,
			nickname: editedNickname,
		})
		if (!isNew) {
			setIsEditing(false)
		}
	}

	return (
		<article className="border border-gray-200 rounded-lg px-4 py-2">
			<div className="flex items-center justify-between">
				<header className="flex items-center gap-4 flex-wrap flex-1">
					<div className="w-24">
						{isEditing ? (
							<Input
								type="number"
								value={editedNumber}
								onChange={(e) => setEditedNumber(Number(e.target.value))}
							/>
						) : (
							<span className="font-medium">Group {group.number}</span>
						)}
					</div>
					<div className="w-48">
						{isEditing ? (
							<Input
								value={editedNickname}
								onChange={(e) => setEditedNickname(e.target.value)}
								placeholder="Nickname"
							/>
						) : (
							group.nickname && <span className="text-muted-foreground">{group.nickname}</span>
						)}
					</div>
					<div className="flex-1">
						{isEditing ? (
							<ClaimMultiSelect
								claims={subjectClaims}
								onClaimSelect={(selected) => setSelectedLimitations(selected)}
								startingClaims={limitations.map((l) => l.id)}
								onlyLimitations={true}
								abbreviated={false}
							/>
						) : (
							<div className="flex flex-wrap gap-2">
								{limitations.map((limitation) => (
									<ElementPill
										key={limitation.id}
										color="#F0F0F0"
										label={limitation.label}
										handleClick={() => {}}
									/>
								))}
							</div>
						)}
					</div>
				</header>

				<div className="flex items-center gap-2">
					<Button
						variant="ghost"
						size="icon"
						onClick={isEditing ? handleSave : () => setIsEditing(true)}
						disabled={isLoading}
					>
						{isEditing ? (
							<CheckIcon className="h-4 w-4" />
						) : (
							<Pencil1Icon className="h-4 w-4" />
						)}
					</Button>
					<Button
						variant="ghost"
						size="icon"
						onClick={() => {
							onDelete({ subjectId: subjectId, groupId: group.id })
						}}
						disabled={isLoading}
					>
						<TrashIcon className="h-4 w-4" />
					</Button>
				</div>
			</div>
			{error && <p className="text-sm text-red-500 mt-2">{error}</p>}
		</article>
	)
}

export default LimitationGroupCard
