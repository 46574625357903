import type { DialogueResponse } from "@/types/chat"
import { jsonToArray } from "@/utils"
import {
	getContentFromMessage,
	getDirectiveNameFromMessage,
} from "@/utils/messageUtils"
import { PhaseProgress, PhaseProgressIcon } from "./PhaseProgressIcons"

interface PlanPhasesDisplayProps {
	message: DialogueResponse
	messages: DialogueResponse[]
	index: number
}

export const PlanPhasesDisplay: React.FC<PlanPhasesDisplayProps> = ({
	message,
	messages,
	index,
}) => {
	const planSteps = getPlanStepsFromMessage(message)
	const hasPromptUser = hasPromptUserDirective(messages, index)

	return (
		<div className="flex flex-col space-y-4 mb-3">
			<div className="flex justify-start">
				<div className="max-w-[80%] rounded-lg px-4 py-2 bg-muted">
					{getContentFromMessage(message) ?? ""}
				</div>
			</div>
			<div className="pl-2">
				<ul className={"space-y-2"}>
					{planSteps.map((step: string, stepIndex: number) => (
						<li key={stepIndex + step} className="flex items-center gap-3">
							<PhaseProgressIcon
								state={
									hasPromptUser
										? PhaseProgress.COMPLETE
										: determinePhaseState(messages, index, planSteps, stepIndex)
								}
							/>
							<span>{step}</span>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}

function hasPromptUserDirective(
	messages: DialogueResponse[],
	currentIndex: number,
): boolean {
	return messages
		.slice(currentIndex + 1)
		.some((msg) => getDirectiveNameFromMessage(msg) === "prompt_user")
}

function getPlanStepsFromMessage(message: DialogueResponse): string[] {
	const phasesParam = message.task?.parameters?.find((param) => param.name === "phases")
	return phasesParam ? jsonToArray(phasesParam.value) : []
}

function getTasksForPhase(
	messages: DialogueResponse[],
	currentIndex: number,
	phase: string,
) {
	return messages
		.slice(currentIndex + 1)
		.filter((msg) => "phase" in msg.task && msg.task?.phase === phase)
}

function determinePhaseState(
	messages: DialogueResponse[],
	currentIndex: number,
	planSteps: string[],
	phaseIndex: number,
): PhaseProgress {
	const currentPhase = planSteps[phaseIndex]
	const nextPhase = planSteps[phaseIndex + 1]

	const currentPhaseTasks = getTasksForPhase(messages, currentIndex, currentPhase)
	const nextPhaseTasks = nextPhase
		? getTasksForPhase(messages, currentIndex, nextPhase)
		: []

	if (nextPhaseTasks.length > 0) return PhaseProgress.COMPLETE
	if (currentPhaseTasks.length > 0) return PhaseProgress.LOADING
	return PhaseProgress.PLANNED
}
