import type { Bbox, ChunkLocation, DocumentChunk } from "@/types"
import { cleanAndMapDocumentChunkText } from "./cleanAndMapDocumentChunkText"

/**
 * Computes a sub-citation location based on a user's highlighted selection
 * (from selectionStart to selectionEnd) within the chunk's text.
 */
export function getSubCitationLocationFromCleaned(
	chunk: DocumentChunk,
	selectionStart: number, // original selection start offset in chunk.text
	selectionEnd: number, // original selection end offset in chunk.text
): ChunkLocation | null {
	// Step 1: Validate input.
	if (!chunk.text || !chunk.text.length) return null
	if (selectionStart < 0 || selectionEnd < 0 || selectionStart > selectionEnd) {
		return null
	}

	// Step 2: Sort bounding boxes in reading order (by page, then left, then top).
	const sortedBBoxes = (chunk.bboxes as Bbox[]).sort((a, b) => {
		if (a.page !== b.page) return a.page - b.page
		if (a.left !== b.left) return a.left - b.left
		return a.top - b.top
	})

	const hasMultipleBBoxes = sortedBBoxes.length > 1

	// Step 3: Split the text into lines and assign each line to its corresponding bounding box.
	const allRawLines: string[] = []
	const lineToBboxIndex: number[] = []

	if (hasMultipleBBoxes) {
		// For multiple bounding boxes, split using 3+ newlines to separate each box’s content.
		const subChunks = chunk.text.split(/\n{3,}/)
		const boxCount = sortedBBoxes.length

		for (let i = 0; i < subChunks.length; i++) {
			const subLines = subChunks[i].split(/\r\n|\r|\n/g)
			for (const line of subLines) {
				// Skip empty lines.
				if (line.trim() !== "") {
					allRawLines.push(line)
					// Use Math.min to cap the bbox index if there are more sub-chunks than bounding boxes.
					lineToBboxIndex.push(Math.min(i, boxCount - 1))
				}
			}
		}
	} else {
		// For a single bounding box, treat the entire text as one block.
		const singleLines = chunk.text.split(/\r\n|\r|\n/g)
		for (const line of singleLines) {
			if (line.trim() !== "") {
				allRawLines.push(line)
				lineToBboxIndex.push(0)
			}
		}
	}

	// Step 4: Create an array of character offset ranges for each line.
	const lineRanges: Array<{ start: number; end: number }> = []
	{
		let charPos = 0
		for (let i = 0; i < allRawLines.length; i++) {
			const length = allRawLines[i].length
			lineRanges.push({ start: charPos, end: charPos + length })
			charPos += length + 1 // +1 for the newline character.
		}
	}

	// Step 5: Map the selection offsets to their corresponding line indices.
	const selectionStartLineIdx = findLineIndex(lineRanges, selectionStart)
	const selectionEndLineIdx = findLineIndex(lineRanges, selectionEnd)
	if (selectionStartLineIdx === -1 || selectionEndLineIdx === -1) {
		return null
	}

	// Step 6: Build a mapping from lines to location details using bounding box indices.
	const bboxLineMaps = buildBboxLineMapsExplicit(
		sortedBBoxes,
		lineToBboxIndex,
		chunk.location,
	)

	// Step 7: Convert the user’s start and end line indices into location details (page, column, line).
	const startLoc = mapLineIndexToLocation(
		selectionStartLineIdx,
		bboxLineMaps,
		chunk.location,
	)
	const endLoc = mapLineIndexToLocation(
		selectionEndLineIdx,
		bboxLineMaps,
		chunk.location,
	)
	if (!startLoc || !endLoc) return null

	// Adjust line numbers based on the bounding box selections.
	const startBox = bboxLineMaps.find(
		(r) =>
			selectionStartLineIdx >= r.rawStartLine && selectionStartLineIdx <= r.rawEndLine,
	)
	const endBox = bboxLineMaps.find(
		(r) => selectionEndLineIdx >= r.rawStartLine && selectionEndLineIdx <= r.rawEndLine,
	)

	if (startBox && endBox) {
		const firstBox = sortedBBoxes[startBox.boxIndex]
		const secondBox = sortedBBoxes[endBox.boxIndex]

		const isSelectionInSameBox = startBox.boxIndex === endBox.boxIndex
		const isSelectionSeparatePageBboxes = firstBox.page < secondBox.page

		if (isSelectionInSameBox) {
			const isSeparatePageMultipleBboxes =
				sortedBBoxes.length > 1 &&
				sortedBBoxes[0].page < sortedBBoxes[sortedBBoxes.length - 1].page

			if (isSeparatePageMultipleBboxes) {
				endLoc.line++
			} else {
				if (isSelectionSeparatePageBboxes) {
					startLoc.line++
				}
			}
		}
	}

	const chunkLocation: ChunkLocation = {
		lines: [startLoc.line, endLoc.line],
		columns: [startLoc.column, endLoc.column],
	}
	return chunkLocation
}

/**
 * Constructs a mapping from bounding boxes to their associated line ranges.
 * Each bounding box covers exactly the lines where lineToBboxIndex equals its index.
 */
interface BboxLineMap {
	boxIndex: number
	rawStartLine: number
	rawEndLine: number
	locStartLine: number
	locEndLine: number
	page: number
}

function buildBboxLineMapsExplicit(
	sortedBBoxes: Bbox[],
	lineToBboxIndex: number[],
	location: ChunkLocation,
): BboxLineMap[] {
	const [locStart, locEnd] = location.lines ?? [0, 0]
	const results: BboxLineMap[] = []

	sortedBBoxes.forEach((bbox, i) => {
		// Identify the first and last line indices associated with bounding box i.
		let firstLine = -1
		let lastLine = -1
		for (let lineIdx = 0; lineIdx < lineToBboxIndex.length; lineIdx++) {
			if (lineToBboxIndex[lineIdx] === i) {
				if (firstLine === -1) firstLine = lineIdx
				lastLine = lineIdx
			}
		}
		if (firstLine === -1 || lastLine === -1) {
			// Skip if no lines map to this bounding box.
			return
		}

		// Calculate the total number of raw lines for this bounding box.
		const linesForThisBox = lastLine - firstLine + 1

		let locStartLine: number
		let locEndLine: number
		if (sortedBBoxes.length > 1) {
			// For multiple bounding boxes:
			if (i === 0) {
				// For the first bounding box, use the provided start line and extend by the number of lines.
				locStartLine = locStart
				locEndLine = locStartLine + linesForThisBox - 1
			} else {
				// For subsequent bounding boxes, reset the start line to 1.
				locStartLine = 1
				locEndLine = locEnd
			}
		} else {
			// For a single bounding box, use the complete location range.
			locStartLine = locStart
			locEndLine = locEnd
		}

		results.push({
			boxIndex: i,
			rawStartLine: firstLine,
			rawEndLine: lastLine,
			locStartLine: locStartLine,
			locEndLine: locEndLine,
			page: bbox.page,
		})
	})

	return results
}

/**
 * Maps a raw line index to its corresponding location details (page, column, line)
 * using the provided bounding box mapping.
 */
function mapLineIndexToLocation(
	rawLineIndex: number,
	bboxLineMaps: BboxLineMap[],
	location: ChunkLocation,
) {
	// Step 1: Find the bounding box map that includes the raw line index.
	const match = bboxLineMaps.find(
		(r) => rawLineIndex >= r.rawStartLine && rawLineIndex <= r.rawEndLine,
	)
	if (!match) return null

	const { boxIndex, rawStartLine, rawEndLine, locStartLine, locEndLine, page } = match

	// Step 2: Compute the total number of lines and the offset within the bounding box.
	const totalLinesInBox = rawEndLine - rawStartLine + 1
	const offsetInBox = rawLineIndex - rawStartLine
	const locRange = Math.max(locEndLine - locStartLine, 0)

	// Step 3: Interpolate to determine the final location line.
	let line = locStartLine
	if (totalLinesInBox > 1) {
		const ratio = offsetInBox / (totalLinesInBox - 1)
		line = locStartLine + Math.round(ratio * locRange)
	}

	// Step 4: Map the bounding box index to determine the corresponding column.
	const col = mapColumn(boxIndex, location.columns, bboxLineMaps.length)

	return { column: col, line, page }
}

/**
 * Maps a bounding box index to a column number.
 *
 * If the columns field is a two-element array [c1, c2], the value is
 * interpolated across the bounding boxes.
 */
function mapColumn(
	boxIndex: number,
	columnsField: number[] | number[][],
	totalBoxes: number,
): number {
	if (!columnsField || !Array.isArray(columnsField)) return 0
	// Assume columnsField is a two-element array [c1, c2].
	let [c1, c2] = columnsField as [number, number]
	if (c1 > c2) [c1, c2] = [c2, c1]
	if (totalBoxes <= 1) return c1
	// Interpolate the column value based on the bounding box index.
	const t = boxIndex / (totalBoxes - 1)
	return Math.round(c1 + t * (c2 - c1))
}

/**
 * Finds the index of the line that includes the character at the given offset.
 */
function findLineIndex(
	lineRanges: Array<{ start: number; end: number }>,
	offset: number,
): number {
	for (let i = 0; i < lineRanges.length; i++) {
		const { start, end } = lineRanges[i]
		if (offset >= start && offset <= end) {
			return i
		}
	}
	return -1
}

/**
 * Maps the user's selection in the cleaned text back to the original text,
 * then computes the sub-citation location.
 */
export function getSubCitationLocation(
	chunk: DocumentChunk,
	cleanedSelectionStart: number,
	cleanedSelectionEnd: number,
): ChunkLocation | null {
	// Step 1: Validate the chunk text and selection boundaries.
	if (!chunk.text || !chunk.text.length) return null
	if (cleanedSelectionStart < 0 || cleanedSelectionEnd < 0) return null
	if (cleanedSelectionStart > cleanedSelectionEnd) return null

	// Step 2: Generate the cleaned text and position map.
	const { cleanedText, positionMap } = cleanAndMapDocumentChunkText(chunk.text)

	// Step 3: Ensure the selection falls within the bounds of the cleaned text.
	if (
		cleanedSelectionStart >= cleanedText.length ||
		cleanedSelectionEnd > cleanedText.length
	) {
		return null
	}

	// Step 4: Convert cleaned offsets back to the original offsets.
	const originalSelectionStart = positionMap[cleanedSelectionStart]
	const originalSelectionEnd = positionMap[cleanedSelectionEnd - 1]

	return getSubCitationLocationFromCleaned(
		chunk,
		originalSelectionStart,
		originalSelectionEnd,
	)
}
