import type {
	AddChunkToLimitationRequest,
	ChunkLocation,
	CopyChunkToLimitationsRequest,
	CreateInvalidityChartRequest,
	FindMoreCitationsForLimitationRequest,
	GetChartDataRequest,
	GetProjectChartsMatrixRequest,
	GetProjectExportRequest,
	GetSuggested103CombinationChartsRequest,
	InvalidityChartData,
	InvaliditySummaryColumn,
	ProjectMatrixRow,
	PruneChunkToLimitationByColorRequest,
	Suggested103Combination,
	ToggleRemoveAllDocumentChunkCitationsRequest,
	ToggleVerifyAllDocumentCitationsRequest,
	UpdateCellBoilerplateRequest,
	UpdateChunkToLimitationRequest,
} from "@/types"
import type { InvalidityChart } from "@/types/invalidity"
import { apiClient } from "./client"

export const chartsApi = {
	async getSubjectCharts(
		projectId: string,
		subjectId: string,
		isActive = true,
	): Promise<InvalidityChart[]> {
		const payload = {
			project_id: projectId,
			subject_id: subjectId,
			is_active: isActive,
		}
		return await apiClient.get("get_charts", payload)
	},

	async getChartSummaries(
		projectId: string,
		subjectId?: string,
		invalidityChartId?: string,
		onlyAssertedClaims?: boolean,
	): Promise<InvaliditySummaryColumn[]> {
		const params: {
			project_id: string
			subject_ids?: string[]
			only_asserted_claims?: boolean
			chart_ids?: string[]
		} = {
			project_id: projectId,
			...(subjectId && { subject_ids: [subjectId] }),
			...(invalidityChartId && { chart_ids: [invalidityChartId] }),
			...(onlyAssertedClaims !== undefined && {
				only_asserted_claims: onlyAssertedClaims,
			}),
		}

		return await apiClient.get("get_chart_summaries", params)
	},

	async createInvalidityChart(
		projectId: string,
		subjectId: string,
		name: string,
		notes: string,
		documentIds: string[],
		numberCitationsToCopy?: number,
		useSuggestedCombinations = false,
	) {
		const payload: CreateInvalidityChartRequest = {
			project_id: projectId,
			subject_id: subjectId,
			document_ids: documentIds,
			name: name,
			notes: notes,
			use_suggested_combinations: useSuggestedCombinations,
			...(numberCitationsToCopy !== undefined && {
				number_citations_to_copy: numberCitationsToCopy,
			}),
		}
		return await apiClient.post("create_project_chart", payload)
	},

	async updateInvalidityChart(
		invalidityChartId: string,
		options: {
			name?: string
			notes?: string
			active?: boolean
			tags?: string[]
		} = {},
	) {
		const payload = {
			chart_id: invalidityChartId,
			...options,
		}

		return await apiClient.post("update_invalidity_chart", payload)
	},

	async getChartData(
		projectId: string,
		subjectId: string,
		limitationIds: string[],
		chartId: string,
		colors: string[],
	): Promise<InvalidityChartData> {
		const payload: GetChartDataRequest = {
			project_id: projectId,
			subject_id: subjectId,
			limitation_ids: limitationIds,
			chart_id: chartId,
			colors: colors,
		}
		return await apiClient.get("get_chart_data", payload)
	},

	async getInvalidityChart(chartId: string): Promise<InvalidityChart> {
		const payload = {
			chart_id: chartId,
		}
		return await apiClient.get("get_project_chart", payload)
	},

	async updateChunkToLimitation(
		invalidityChartId: string,
		claimLimitationIds: string[],
		options: {
			score?: number
			color?: string
			removed?: boolean
			verified?: boolean
		},
		documentChunkId?: string,
		claimCitationId?: string,
	) {
		const payload: UpdateChunkToLimitationRequest = {
			invalidity_chart_id: invalidityChartId,
			document_chunk_id: documentChunkId,
			claim_citation_id: claimCitationId,
			claim_limitation_ids: claimLimitationIds,
			...(options.score !== undefined && { score: options.score }),
			...(options.color !== undefined && { color: options.color }),
			...(options.removed !== undefined && { removed: options.removed }),
			...(options.verified !== undefined && { verified: options.verified }),
		}
		return await apiClient.post("post_update_chunk_to_limitation", payload)
	},

	async createChunkToLimitation(
		claimLimitationIds: string[],
		color: string,
		projectId: string,
		invalidityChartId: string,
		documentChunkId?: string,
		claimCitationId?: string,
		documentId?: string,
	) {
		const payload: AddChunkToLimitationRequest = {
			claim_limitation_ids: claimLimitationIds,
			project_id: projectId,
			color: color,
			document_chunk_id: documentChunkId,
			claim_citation_id: claimCitationId,
			document_id: documentId ?? "",
			invalidity_chart_id: invalidityChartId,
		}
		return await apiClient.post("post_create_chunk_to_limitation", payload)
	},

	async toggleRemoveAllDocumentChunkCitations(
		documentChunkId?: string,
		claimCitationId?: string,
	) {
		const payload: ToggleRemoveAllDocumentChunkCitationsRequest = {
			document_chunk_id: documentChunkId,
			claim_citation_id: claimCitationId,
		}
		return await apiClient.post(
			"post_toggle_remove_all_document_chunk_citations",
			payload,
		)
	},

	async toggleVerifyAllDocumentCitations(documentId: string, verified: boolean) {
		const payload: ToggleVerifyAllDocumentCitationsRequest = {
			document_id: documentId,
			verified: verified,
		}
		return await apiClient.post("post_toggle_verify_all_document_citations", payload)
	},

	async findMoreCitationsForLimitation(
		invalidityChartId: string,
		claimLimitationId: string,
		documentId: string,
		positiveExamples: string[],
		negativeExamples: string[],
	) {
		const payload: FindMoreCitationsForLimitationRequest = {
			document_ids: [documentId],
			invalidity_chart_id: invalidityChartId,
			claim_limitation_id: claimLimitationId,
			positive: positiveExamples,
			negative: negativeExamples,
		}
		return await apiClient.post("post_find_more_limitation_citations", payload)
	},

	async pruneChunkToLimitationByColor(documentIds: string[], colors: string[]) {
		const payload: PruneChunkToLimitationByColorRequest = {
			document_ids: documentIds,
			colors: colors,
		}
		return await apiClient.post("post_prune_chunk_to_limitation_by_color", payload)
	},

	async updateCellBoilerplate(
		invalidityChartId: string,
		header: string,
		footer: string,
		middle: string,
		overwrite: boolean,
		claimLimitationIds?: string[],
	) {
		const payload: UpdateCellBoilerplateRequest = {
			invalidity_chart_id: invalidityChartId,
			header: header,
			footer: footer,
			middle: middle,
			claim_limitation_ids: claimLimitationIds,
			overwrite: overwrite,
		}
		return await apiClient.post("post_update_cell_boilerplate", payload)
	},

	async getProjectExport(
		projectId: string,
		subjectId: string,
		exportType: string,
		includeClaims: boolean,
		includeSummary: boolean,
		introductionLanguage: string,
		chartIds: string[],
		colorSubset: string[],
		limitationIds: string[],
		isCitationBefore: boolean,
		patentCitationFormat: string,
		applicationCitationFormat: string,
		documentCitationFormat: string,
		foreignDocumentCitationFormat: string,
		figureFormat: string,
		figurePlacement: string,
		titleFormat: string,
	) {
		const params: GetProjectExportRequest = {
			project_id: projectId,
			subject_id: subjectId,
			export_type: exportType,
			include_claims: includeClaims,
			include_summary: includeSummary,
			introduction_language: introductionLanguage,
			chart_ids: chartIds,
			color_subset: colorSubset,
			limitation_ids: limitationIds,
			is_citation_before: isCitationBefore,
			patent_citation_format: patentCitationFormat,
			application_citation_format: applicationCitationFormat,
			document_citation_format: documentCitationFormat,
			foreign_document_citation_format: foreignDocumentCitationFormat,
			figure_citation_format: figureFormat,
			figure_placement: figurePlacement,
			title_format: titleFormat,
		}
		return await apiClient.get("get_invalidity_chart_export", params)
	},

	async toggleChartCitationVerification(
		projectId: string,
		referenceId: string,
		verified: boolean,
	) {
		const payload = {
			project_id: projectId,
			reference_id: referenceId,
			verified: verified,
		}
		return await apiClient.post("post_toggle_chart_citation_verification", payload)
	},

	async copyChunkToLimitations(
		invalidityChartId: string,
		documentId: string,
		sourceLimitationId: string,
		targetLimitationId: string,
		replace: boolean,
	) {
		const payload: CopyChunkToLimitationsRequest = {
			invalidity_chart_id: invalidityChartId,
			document_id: documentId,
			source_limitation_id: sourceLimitationId,
			target_limitation_id: targetLimitationId,
			replace: replace,
		}
		return await apiClient.post("post_copy_chunk_to_limitations", payload)
	},

	// Helper method to clean location object
	cleanLocation(location: ChunkLocation): ChunkLocation {
		const cleanLocation: ChunkLocation = {}

		if (location.paragraphs) {
			cleanLocation.paragraphs = location.paragraphs
		}

		if (
			location.pages &&
			location.pages.filter((page) => page !== null && !Number.isNaN(page)).length > 0
		) {
			cleanLocation.pages = location.pages.filter(
				(page) => page !== null && !Number.isNaN(page),
			)
		}

		if (
			location.lines &&
			location.lines.filter((line) => line !== null && !Number.isNaN(line)).length > 0
		) {
			cleanLocation.lines = location.lines.filter(
				(line) => line !== null && !Number.isNaN(line),
			)
		}

		if (
			location.columns &&
			location.columns.filter((column) => column !== null && !Number.isNaN(column))
				.length > 0
		) {
			cleanLocation.columns = location.columns.filter(
				(column) => column !== null && !Number.isNaN(column),
			)
		}

		return cleanLocation
	},

	async getSuggested103CombinationCharts(
		subjectId: string,
	): Promise<Suggested103Combination[]> {
		const params: GetSuggested103CombinationChartsRequest = {
			subject_id: subjectId,
		}
		return await apiClient.get("get_suggested_103_combination_charts", params)
	},

	async getProjectChartsMatrix(projectId: string): Promise<ProjectMatrixRow[]> {
		const params: GetProjectChartsMatrixRequest = {
			project_id: projectId,
		}
		return await apiClient.get("get_project_charts_matrix", params)
	},
}
