"use client";

import { ChevronDown } from "lucide-react";
import * as React from "react";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

interface FancyMultiSelectProps {
  items: { value: string; label: string }[];
  selected: string[];
  onChange: (selected: string[]) => void;
  placeholder?: string;
  /** When provided, an "All ..." option is rendered with this type label.
      For example, if set to "charts", the option will display as "All charts". */
  optionType?: string;
}

export function FancyMultiSelect({
  items,
  selected,
  onChange,
  placeholder = "Select",
  optionType,
}: FancyMultiSelectProps) {
  const [open, setOpen] = React.useState(false);

  // Create an array of all item values
  const allValues = items.map((item) => item.value);
  // Determine if all items are selected (if there are items)
  const isAllSelected = items.length > 0 && selected.length === items.length;

  // Default to all if nothing is selected on initial mount.
  // (Similar to ClaimMultiSelect's useEffect default behavior)
  const isFirstRender = React.useRef(true);
  React.useEffect(() => {
    if (isFirstRender.current && items.length > 0 && selected.length === 0) {
      onChange(allValues);
    }
    isFirstRender.current = false;
  }, [items]);

  // Render the button label based on the current selection.
  // If every item is selected and an optionType is provided, show "All optionType".
  // Otherwise, if some items are selected, render "N selected".
  // If nothing is selected, show the placeholder.
  const renderButtonLabel = () => {
    if (items.length > 0 && selected.length === items.length) {
      return <span>All {optionType || "selected"}</span>;
    } else if (selected.length > 0) {
      return <span>{selected.length} selected</span>;
    }
    return <span>{placeholder}</span>;
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" className="w-[200px] justify-between">
          {renderButtonLabel()}
          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent forceInline className="w-[200px] p-0">
        <Command>
          <CommandList>
            <CommandGroup>
              {/* Render the "All" option only if optionType is provided */}
              {optionType && (
                <CommandItem
                  onSelect={() => {
                    if (isAllSelected) {
                      onChange([]);
                    } else {
                      onChange(allValues);
                    }
                  }}
                  className="flex items-center space-x-2"
                >
                  <Checkbox checked={isAllSelected} />
                  <span>All {optionType}</span>
                </CommandItem>
              )}
              {items.map((item) => {
                const isSelected = selected.includes(item.value);
                return (
                  <CommandItem
                    key={item.value}
                    onSelect={() => {
                      if (isSelected) {
                        onChange(selected.filter((s) => s !== item.value));
                      } else {
                        onChange([...selected, item.value]);
                      }
                    }}
                    className="flex items-center space-x-2"
                  >
                    <Checkbox checked={isSelected} />
                    <span>{item.label}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
