/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Label } from "@/components/ui/label"
import { ProjectType } from "@/types"
import type React from "react"
import { useCallback } from "react"
import { useCreateProjectContext } from "../CreateProjectContext"
import ProjectDetailsForm from "./ProjectDetailsForm"
import { ProjectTypeCards } from "./ProjectTypeCards"
/**
 * @description Project details step displayed in create project flow
import ProjectDetailsForm from "./ProjectDetailsForm";
 */
const DetailsStep: React.FC = () => {
	const { projectType, updateProjectType } = useCreateProjectContext()

	const handleTypeSelect = useCallback(
		(type: ProjectType) => {
			if (type === ProjectType.LITIGATION) {
				updateProjectType(type)
			}
		},
		[updateProjectType],
	)

	return (
		<div className="space-y-6">
			<ProjectDetailsForm />

			<div>
				<div className="flex items-center justify-start mb-2">
					<Label className="mr-1 flex items-center">Project Type</Label>
				</div>
				<ProjectTypeCards
					selectedType={projectType}
					onTypeSelect={(type) => handleTypeSelect(type)}
				/>
			</div>
		</div>
	)
}

export default DetailsStep
