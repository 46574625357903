import { chartsApi } from "@/api/chartsApi"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"

export function useProjectChartsData(projectId: string, subjectId: string) {
	const {
		data: activeCharts,
		isLoading: activeChartsLoading,
		isError: activeChartsError,
	} = useQuery({
		queryKey: QUERY_KEYS.project.charts.active(projectId, subjectId),
		queryFn: async () => {
			if (!projectId) return null
			return chartsApi.getSubjectCharts(projectId, subjectId)
		},
		enabled: !!projectId && !!subjectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	const {
		data: archivedCharts,
		isLoading: archivedChartsLoading,
		isError: archivedChartsError,
	} = useQuery({
		queryKey: QUERY_KEYS.project.charts.inactive(projectId, subjectId),
		queryFn: async () => {
			if (!projectId) return null
			return chartsApi.getSubjectCharts(projectId, subjectId, false)
		},
		enabled: !!projectId && !!subjectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		activeCharts,
		archivedCharts,
		activeChartsLoading,
		activeChartsError,
		archivedChartsLoading,
		archivedChartsError,
	}
}

export default useProjectChartsData
