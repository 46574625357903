import { DateTime } from "luxon"
import type { DateRange } from "react-day-picker"

type DateInput = Date | string | DateTime

export const getUtcDateNow = () => {
	return DateTime.now().setZone("utc").startOf("day")
}

export const formatChatTimestamp = (date?: DateInput): string => {
	const dt = DateTime.fromISO(date.toString(), { zone: "utc" }).setZone("local")
	const now = DateTime.now()

	if (dt.hasSame(now, "day")) {
		return dt.toFormat("h:mm a") // Today: "3:45 PM"
	}
	if (dt.hasSame(now, "week")) {
		return dt.toFormat("ccc h:mm a") // This week: "Mon 3:45 PM"
	}
	if (dt.hasSame(now, "year")) {
		return dt.toFormat("MMM d") // This year: "Mar 15"
	}
	return dt.toFormat("MM/dd/yy") // Different year: "03/15/23"
}

// Helper function to extract the day, month, and year from a Date object
const extractDayMonthYearFromDate = (date: DateInput) => {
	let jsDate: Date

	if (date instanceof DateTime) {
		jsDate = date.toJSDate()
	} else if (typeof date === "string") {
		jsDate = new Date(date)
	} else {
		jsDate = date
	}

	const day = jsDate.getDate()
	const month = jsDate.getMonth() + 1 // months are 0-indexed
	const year = jsDate.getFullYear()
	return { day, month, year }
}

export const getUtcDateString = (date: DateInput) => {
	if (date instanceof DateTime) {
		return date.toFormat("MM/dd/yyyy")
	}
	if (typeof date === "string") {
		return DateTime.fromISO(date, { zone: "utc" }).toFormat("MM/dd/yyyy")
	}
	return DateTime.fromJSDate(date, { zone: "utc" }).toFormat("MM/dd/yyyy")
}

export const convertToUtcDateString = (date: DateInput) => {
	if (!date) return ""
	const { day, month, year } = extractDayMonthYearFromDate(date)
	// Set hour=12
	return DateTime.utc(year, month, day, 12).toFormat("MM/dd/yyyy")
}

export const convertToUtcDateTimeString = (date: DateInput) => {
	const { day, month, year } = extractDayMonthYearFromDate(date)
	// Set hour=12
	return DateTime.utc(year, month, day, 12).toISO()
}

export const convertToUtcDate = (
	date: DateInput,
	hour = 12,
	minute = 0,
	second = 0,
) => {
	const { day, month, year } = extractDayMonthYearFromDate(date)
	// Set hour=12
	return DateTime.utc(year, month, day, hour, minute, second)
}

export const convertDateRangeToUtcDate = (dateRange: DateRange) => {
	const { from, to } = dateRange
	return {
		from: convertToUtcDate(from, 0, 0, 0),
		to: convertToUtcDate(to, 23, 59, 59),
	}
}

/**
 * Converts a UTC date input into a Luxon DateTime object in the user's local time zone.
 * You can also pass an optional `format` string (using Luxon tokens) to return a formatted string.
 *
 * @param date - The UTC date input (Date, ISO string, or DateTime).
 * @param format - (Optional) A Luxon format string (e.g., "MM/dd/yyyy HH:mm").
 * @returns The converted DateTime adjusted to the local time zone, or a formatted string if `format` is provided.
 *
 * Example:
 *   convertUtcToLocal("2025-02-02T22:45:46.446000Z", "MM/dd/yyyy hh:mm a")
 */
export const convertUtcToLocal = (
	date: DateInput,
	format?: string,
): DateTime | string => {
	let dt: DateTime

	if (date instanceof DateTime) {
		dt = date
	} else if (typeof date === "string") {
		dt = DateTime.fromISO(date, { zone: "utc" })
	} else {
		dt = DateTime.fromJSDate(date, { zone: "utc" })
	}

	// Convert to local time zone
	dt = dt.setZone("local")

	return format ? dt.toFormat(format) : dt
}

export const formatTimestamp = (timestamp: number | string | null): string => {
	if (!timestamp) return "-"
	// Check if timestamp is a numeric seconds value. If not, assume it's a valid date string.
	const numTimestamp = Number(timestamp)
	const date = !Number.isNaN(numTimestamp)
		? new Date(numTimestamp * 1000)
		: new Date(timestamp)
	if (Number.isNaN(date.getTime())) return "-" // fail-safe in case of invalid date

	const today = new Date()
	const yesterday = new Date(today)
	yesterday.setDate(yesterday.getDate() - 1)

	const dateFormat = (date: Date) => {
		return date
			.toLocaleDateString("en-US", {
				day: "2-digit",
				month: "2-digit",
				year: "2-digit",
			})
			.replace(/\//g, ".")
	}

	if (date.toDateString() === today.toDateString()) {
		return "Today"
	}
	if (date.toDateString() === yesterday.toDateString()) {
		return "Yesterday"
	}
	return dateFormat(date)
}

/**
 * Formats a given UTC date input as a local project timestamp string.
 *
 * If the date is today, returns "Today at {time}".
 * If the date is yesterday, returns "Yesterday at {time}".
 * Otherwise, returns a formatted date string as "Mon d, yyyy".
 *
 * @param date - The date input (Date, ISO string, or DateTime).
 * @returns The formatted timestamp string.
 *
 * Example:
 *   formatProjectTimestamp("2025-02-02T22:45:46.446000Z")
 *     // "Today at 10:45 PM" (if today) or "Feb 2, 2025" otherwise.
 */
export const formatTimestampInLocal = (
	date?: DateInput,
	includeTime = false,
): string => {
	if (!date) return "-"

	// Convert the UTC date to a local DateTime
	const dt = convertUtcToLocal(date) as DateTime
	const now = DateTime.local()

	// Get the formatted time (e.g., "3:45 PM")
	const formattedTime = dt.toFormat("h:mm a")

	// Compare the ISO date strings to detect if dt is today or yesterday.
	if (includeTime) {
		if (dt.toISODate() === now.toISODate()) {
			return `Today at ${formattedTime}`
		}
		if (dt.toISODate() === now.minus({ days: 1 }).toISODate()) {
			return `Yesterday at ${formattedTime}`
		}
	}
	return dt.toFormat("MM/dd/yyyy")
}
