import { useDocumentSearch } from "@/hooks/useDocumentSearch"
import type { SearchMode, SearchOrder } from "@/types"
import type React from "react"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { useProjectContext } from "./ProjectContext"

// Define a merged context type that includes both DocumentSearch and DocumentSelection states.
export interface DocumentContextValue {
	// DocumentSearchContext values
	searchQuery: string
	setSearchQuery: (v: string) => void
	searchResultIds: Set<string> | null
	setSearchResultIds: (ids: Set<string> | null) => void
	searchLoading: boolean
	searchError: boolean
	search: () => Promise<void>
	searchMode: SearchMode
	setSearchMode: (m: SearchMode) => void
	searchOrderBy: SearchOrder
	setSearchOrderBy: (o: SearchOrder) => void
	searchOrderedResults: any[]
	setSearchOrderedResults: (results: any[]) => void

	// DocumentSelectionContext values
	selectedChunkId: string | null
	setSelectedChunkId: (id: string | null) => void
}

// Create the context with an initial undefined value.
const DocumentContext = createContext<DocumentContextValue | undefined>(undefined)

// Provider component that merges the two contexts.
export const DocumentContextProvider: React.FC<{
	documentIds: string[]
	defaultSelectedChunkId?: string
	children: React.ReactNode
}> = ({ documentIds, defaultSelectedChunkId, children }) => {
	// Get the search state from the custom hook.
	const searchState = useDocumentSearch(documentIds)
	const { documentViewerChunkId } = useProjectContext()

	useEffect(() => {
		if (documentViewerChunkId) {
			setSelectedChunkId(documentViewerChunkId)
		}
	}, [documentViewerChunkId])

	// Manage your document selection state.
	const [selectedChunkId, setSelectedChunkId] = useState<string | null>(
		defaultSelectedChunkId ?? null,
	)

	// Combine both state objects in one value.
	const value = useMemo(
		() => ({
			...searchState,
			selectedChunkId,
			setSelectedChunkId,
		}),
		[searchState, selectedChunkId],
	)

	return <DocumentContext.Provider value={value}>{children}</DocumentContext.Provider>
}

// Consumer hook to easily use the merged context.
export function useDocumentContext() {
	const context = useContext(DocumentContext)
	if (!context) {
		throw new Error("useDocumentContext must be used within a DocumentContextProvider.")
	}
	return context
}
