import { api } from "@/api"
import { useProjectContext } from "@/context/ProjectContext"
import type { FullDocument } from "@/types/documents"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { type QueryObserverResult, useQuery } from "@tanstack/react-query"

const useFullDocument = (documentId: string) => {
	const { projectId } = useProjectContext()

	const documentQuery: QueryObserverResult<FullDocument> = useQuery({
		queryKey: QUERY_KEYS.project.document.full(projectId, documentId),
		queryFn: async () => await api.getFullDocument(documentId),
		enabled: Boolean(documentId && projectId),
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		document: documentQuery.data as FullDocument,
		isLoading: documentQuery.isLoading,
		isError: documentQuery.isError,
		refetch: documentQuery.refetch,
	}
}

export default useFullDocument
