/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useSharing } from "@/hooks"
import { useAppStateStore } from "@/store"
import type { UserMetadata } from "@/types"
import { useAuthInfo } from "@propelauth/react"
import { MoreVertical, X } from "lucide-react"
import { useState } from "react"

interface ShareProjectModalProps {
	handleClose: () => void
}

/**
 * @description Share project modal
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {string} projectTitle - The title of the project.
 */
const ShareProjectSettings: React.FC<ShareProjectModalProps> = () => {
	// Global state from store
	const { user } = useAuthInfo()
	const { projectUsers, shareableUsers, addUsers, removeUsers } = useSharing()
	const { addErrorMessage, addSuccessMessage } = useAppStateStore()

	// Local state set up
	const [selectedUsers, setSelectedUsers] = useState<UserMetadata[]>([])
	const [searchTerm, setSearchTerm] = useState("")

	// Add selected users to project
	const addSelectedUsers = async () => {
		const selectedIds = selectedUsers.map((user) => user.id)

		try {
			await addUsers({ userIds: selectedIds })
			addSuccessMessage("User added successfully.")
		} catch (_error) {
			addErrorMessage("An error occurred while adding user. Try again later.")
		}
	}

	const handleRemoveUser = async (userItem: UserMetadata) => {
		try {
			await removeUsers({ userIds: [userItem.id] })
			addSuccessMessage(`User ${userItem.email} removed successfully.`)
		} catch (_error) {
			addErrorMessage("An error occurred while removing user. Try again later.")
		}
	}

	const filteredOrgUsers =
		shareableUsers?.filter(
			(user) =>
				user?.email?.toLowerCase().includes(searchTerm?.toLowerCase() || "") &&
				!projectUsers?.some((projectUser) => projectUser?.id === user?.id),
		) || []

	return (
		<div>
			<h4 className="mb-2 font-medium dark:text-white">Project sharing</h4>
			<div className="rounded-lg border shadow-md">
				<Input
					placeholder="Add people"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					className="border-none focus:outline-none dark:bg-gray-700 dark:text-white"
				/>
				<div className="max-h-[200px] overflow-y-auto">
					<div>
						{filteredOrgUsers.length > 0 ? (
							filteredOrgUsers.map((user) => (
								<div
									key={user.id}
									className="flex items-center justify-between p-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
									onClick={() => {
										setSelectedUsers((prev) =>
											prev.some((u) => u.id === user.id)
												? prev.filter((u) => u.id !== user.id)
												: [...prev, user],
										)
									}}
								>
									<span className="dark:text-white">{user.email}</span>
									{selectedUsers.some((u) => u.id === user.id) && (
										<Badge variant="outline">Selected</Badge>
									)}
								</div>
							))
						) : (
							<div className="py-6 text-center text-sm">No users found</div>
						)}
					</div>
				</div>
			</div>
			<div className="flex flex-wrap gap-2 mt-1 overflow-auto">
				{selectedUsers?.map((user) => (
					<div
						key={user.id}
						className="text-secondary-foreground px-2 py-1 m-1 rounded border dark:border-gray-600 dark:text-gray-300"
					>
						{user.email}
						<Button
							variant="ghost"
							size="sm"
							className="ml-1 h-auto p-0 dark:text-gray-300"
							onClick={() => setSelectedUsers((prev) => prev.filter((u) => u.id !== user.id))}
						>
							<X className="h-3 w-3" />
						</Button>
					</div>
				))}
			</div>
			<Button
				variant="default"
				onClick={addSelectedUsers}
				disabled={selectedUsers.length === 0}
			>
				Invite users
			</Button>
			{projectUsers?.length > 0 && (
				<div className="mt-4">
					<h4 className="mb-2 font-medium dark:text-white">People with access</h4>
					<ScrollArea className="max-h-[200px]">
						<div className="pb-4">
							{projectUsers.map((userItem) => (
								<div key={userItem.id} className="flex items-center justify-between py-2">
									<span className="dark:text-white">
										{userItem.email === user?.email ? `${userItem.email} (You)` : userItem.email}
									</span>
									{userItem.email !== user?.email && (
										<DropdownMenu>
											<DropdownMenuTrigger asChild>
												<Button variant="ghost" size="sm" className="dark:text-gray-300">
													<MoreVertical className="h-4 w-4" />
												</Button>
											</DropdownMenuTrigger>
											<DropdownMenuContent className="dark:bg-gray-800 dark:border-gray-700">
												<DropdownMenuItem
													onClick={() => handleRemoveUser(userItem)}
													className="dark:text-white dark:focus:bg-gray-700"
												>
													Remove user
												</DropdownMenuItem>
											</DropdownMenuContent>
										</DropdownMenu>
									)}
								</div>
							))}
						</div>
					</ScrollArea>
				</div>
			)}
		</div>
	)
}

export default ShareProjectSettings
