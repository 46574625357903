import { api } from "@/api"
import { useProjectContext } from "@/context/ProjectContext"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi } from "@/hooks"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import { useAppStateStore } from "@/store"
import { QUERY_KEYS, invalidateSubjectChartRelatedQueries } from "@/utils"
import {
	handleOptimisticUpdate,
	revertOptimisticUpdates,
} from "@/utils/query/optimisticUpdates"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
export function useChunkToLimitationUpdates() {
	const queryClient = useQueryClient()
	const { handleError } = useApi()
	const { projectId, subjectId } = useProjectContext()
	const { addErrorMessage } = useAppStateStore()
	const { getDocumentName } = useDocumentNaming()

	const { invalidityChartId } = useParams()
	/**
	 * @description Updates the header and footer boilerplate for a chunk to a limitation
	 */
	const updateChunkToLimitationBoilerplateMutation = useMutation({
		mutationFn: async ({
			header,
			footer,
			middle,
			claimLimitationIds,
			updateAllElements,
		}: {
			header: string
			footer: string
			middle: string
			claimLimitationIds?: string[]
			updateAllElements?: boolean
		}) => {
			return api.updateCellBoilerplate(
				invalidityChartId,
				header,
				footer,
				middle,
				updateAllElements,
				claimLimitationIds,
			)
		},
		onMutate: async (variables) => {
			if (!variables.claimLimitationIds || variables.claimLimitationIds.length === 0) {
				// Without specific limitations, we can't do targeted optimistic updates
				return
			}

			// Prepare query keys for each affected limitation
			const queryKeys = variables.claimLimitationIds.map((limitationId) =>
				QUERY_KEYS.project.charts.limitationChartData(
					projectId,
					subjectId,
					invalidityChartId,
					limitationId,
				),
			)
			// Perform optimistic update
			const context = await handleOptimisticUpdate({
				queryClient,
				queryKeys,
				updateFn: (oldData) => {
					if (!oldData) return oldData

					const newData = { ...(oldData as Record<string, any>) }

					// Update boilerplate data
					if (!newData.boilerplate) {
						newData.boilerplate = {}
					}

					if (variables.header !== undefined) {
						newData.boilerplate.header = variables.header
					}

					if (variables.footer !== undefined) {
						newData.boilerplate.footer = variables.footer
					}

					if (variables.middle !== undefined) {
						newData.boilerplate.middle = variables.middle
					}

					return newData
				},
			})
			return context
		},
		onError: (error, _variables, context) => {
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
			handleError(error, "Error updating chunk to limitation boilerplate")
		},
		onSuccess: (_, variables) => {
			invalidateSubjectChartRelatedQueries(
				queryClient,
				projectId,
				subjectId,
				invalidityChartId,
				variables.claimLimitationIds,
			)
		},
	})

	/**
	 * @description Updates the language of a reference citation
	 * @param {string} citationId - The id of the citation to update the language for
	 * @param {object} options - Additional options to pass to the request
	 */
	const updateChunkToLimitationMutation = useMutation({
		mutationFn: async ({
			claimLimitationIds,
			documentChunkId,
			claimCitationId,
			options,
		}: {
			claimLimitationIds: string[]
			documentChunkId?: string
			claimCitationId?: string
			options: {
				verified?: boolean
				removed?: boolean
				score?: number
				color?: string
			}
		}) => {
			return api.updateChunkToLimitation(
				invalidityChartId,
				claimLimitationIds,
				options,
				documentChunkId,
				claimCitationId,
			)
		},
		onMutate: async (variables) => {
			// Prepare query keys for each affected limitation
			const queryKeys = variables.claimLimitationIds.map((limitationId) =>
				QUERY_KEYS.project.charts.limitationChartData(
					projectId,
					subjectId,
					invalidityChartId,
					limitationId,
				),
			)
			// Perform optimistic update
			const context = await handleOptimisticUpdate({
				queryClient,
				queryKeys,
				updateFn: (oldData) => {
					if (!oldData) return oldData
					// Find and update the specific citation in the data
					const newData = { ...(oldData as Record<string, any>) }
					// If we have citation and chunk data
					if (newData.citations && variables.claimCitationId) {
						const citationIndex = newData.citations.findIndex(
							(citation) => citation.id === variables.claimCitationId,
						)
						if (citationIndex !== -1) {
							newData.citations[citationIndex] = {
								...newData.citations[citationIndex],
								...variables.options,
							}
						}
					}
					return newData
				},
			})
			return context
		},
		onError: (error, _variables, context) => {
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
			handleError(error, "Error updating document chunk to claim limitation")
		},
		onSuccess: (_, variables) => {
			invalidateSubjectChartRelatedQueries(
				queryClient,
				projectId,
				subjectId,
				invalidityChartId,
				variables.claimLimitationIds,
			)
		},
	})

	/**
	 * @description Adds a reference citation to the project
	 * @param {string} invalidityId - The id of the invalidity to add the citation to
	 * @param {string} referenceId - The id of the reference to add the citation to
	 * @param {string} color - The color of the citation to add
	 * @param {string} text - The text of the citation to add
	 * @param {ChunkLocation} location - The location details of the citation
	 * @param {string} projectId - The id of the project
	 * @param {boolean} isFeature - Whether this is a feature citation
	 * @param {string[]} figureUrls - The URLs of the figures to add
	 * @param {string[]} figureRefs - The references of the figures to add
	 * @param {string} claimNumber - The claim number for the citation
	 */
	const addChunkToLimitationMutation = useMutation({
		mutationFn: async ({
			documentChunkId,
			claimCitationId,
			claimLimitationIds,
			documentId,
			color,
		}: {
			documentChunkId?: string
			claimCitationId?: string
			claimLimitationIds: string[]
			documentId: string
			color: string
		}) => {
			const response = await api.createChunkToLimitation(
				claimLimitationIds,
				color,
				projectId,
				invalidityChartId,
				documentChunkId,
				claimCitationId,
				documentId,
			)
			return response
		},
		onMutate: async (variables) => {
			// Prepare query keys for each affected limitation
			const queryKeys = variables.claimLimitationIds.map((limitationId) =>
				QUERY_KEYS.project.charts.limitationChartData(
					projectId,
					subjectId,
					invalidityChartId,
					limitationId,
				),
			)

			// Perform optimistic update
			const context = await handleOptimisticUpdate({
				queryClient,
				queryKeys,
				updateFn: (oldData) => {
					if (!oldData) return oldData

					const newData = { ...(oldData as Record<string, any>) }

					// Create a temporary optimistic citation object
					const optimisticCitation = {
						id: variables.claimCitationId || `temp-citation-${Date.now()}`,
						documentId: variables.documentId,
						color: variables.color,
						documentChunkId: variables.documentChunkId,
						verified: false,
						removed: false,
						score: 0,
						// Add other required fields with placeholder values
						text: "Loading...",
						location: { page: 0, top: 0, left: 0, width: 0, height: 0 },
						isTemporary: true, // Flag to identify optimistic entries
					}

					// Add the new citation to the list
					if (!newData.citations) {
						newData.citations = []
					}

					newData.citations.push(optimisticCitation)

					return newData
				},
			})

			return context
		},
		onError: (error, _variables, context) => {
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
			handleError(error, "Error adding reference citation")
		},
		onSuccess: (_, variables) =>
			invalidateSubjectChartRelatedQueries(
				queryClient,
				projectId,
				subjectId,
				invalidityChartId,
				variables.claimLimitationIds,
			),
	})

	/**
	 * @description Deletes a reference citation from a project
	 * @param {string} invalidityCitationId - The id of the invalidity citation to delete
	 */
	const toggleRemoveAllDocumentChunkCitations = useMutation({
		mutationFn: async (documentChunkId: string) => {
			return api.toggleRemoveAllDocumentChunkCitations(documentChunkId)
		},
		onError: (error) => {
			handleError(error, "Error deleting reference citation")
		},
		onSuccess: () =>
			invalidateSubjectChartRelatedQueries(
				queryClient,
				projectId,
				subjectId,
				invalidityChartId,
			),
	})

	/**
	 * @description Mass toggle verified on all citations in a reference
	 */
	const toggleVerifyAllCitations = useMutation({
		mutationFn: async ({
			documentId,
			verified,
		}: {
			documentId: string
			verified: boolean
		}) => {
			return api.toggleVerifyAllDocumentCitations(documentId, verified)
		},
		onError: (_error, variables) => {
			addErrorMessage(
				`Error updating all chart citations for ${getDocumentName(variables.documentId)}`,
			)
		},
		onSuccess: () => {
			invalidateSubjectChartRelatedQueries(
				queryClient,
				projectId,
				subjectId,
				invalidityChartId,
			)
		},
	})

	/**
	 * @description Find more citations for a limitation using AI
	 */
	const findMoreCitationsForLimitationMutation = useMutation({
		mutationFn: async ({
			claimLimitationId,
			documentId,
			positiveExamples,
			negativeExamples,
		}: {
			claimLimitationId: string
			documentId: string
			positiveExamples: string[]
			negativeExamples: string[]
		}) => {
			return api.findMoreCitationsForLimitation(
				invalidityChartId,
				claimLimitationId,
				documentId,
				positiveExamples,
				negativeExamples,
			)
		},
		onMutate: async (variables) => {
			// Prepare query key for the affected limitation
			const queryKey = QUERY_KEYS.project.charts.limitationChartData(
				projectId,
				subjectId,
				invalidityChartId,
				variables.claimLimitationId,
			)

			// Perform optimistic update
			const context = await handleOptimisticUpdate({
				queryClient,
				queryKeys: [queryKey],
				updateFn: (oldData) => {
					if (!oldData) return oldData

					const newData = { ...(oldData as Record<string, any>) }

					// Add a loading indicator or status flag
					if (!newData.metadata) {
						newData.metadata = {}
					}

					newData.metadata.isFindingMoreCitations = true
					newData.metadata.lastSearchedDocumentId = variables.documentId

					return newData
				},
			})

			return context
		},
		onError: (error, _variables, context) => {
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
			handleError(error, "Error finding more citations for limitation")
		},
		onSuccess: (_, variables) => {
			invalidateSubjectChartRelatedQueries(
				queryClient,
				projectId,
				subjectId,
				invalidityChartId,
				[variables.claimLimitationId],
			)
		},
	})

	/**
	 * @description Prune invalidities by color
	 */
	const pruneChunkToLimitationByColor = useMutation({
		mutationFn: async ({
			documentIds,
			colors,
		}: {
			documentIds: string[]
			colors: string[]
		}) => {
			return api.pruneChunkToLimitationByColor(documentIds, colors)
		},
		onError: (error) => {
			handleError(error, "Error pruning invalidities by color")
		},
		onSuccess: (_) => {
			invalidateSubjectChartRelatedQueries(
				queryClient,
				projectId,
				subjectId,
				invalidityChartId,
			)
		},
	})

	/**
	 * @description Copies invalidities from one element to another
	 */
	const copyChunkToLimitations = useMutation({
		mutationFn: async ({
			documentId,
			sourceLimitationId,
			targetLimitationId,
			replace,
		}: {
			documentId: string
			sourceLimitationId: string
			targetLimitationId: string
			replace: boolean
		}) => {
			return api.copyChunkToLimitations(
				invalidityChartId,
				documentId,
				sourceLimitationId,
				targetLimitationId,
				replace,
			)
		},
		onMutate: async (variables) => {
			// Prepare query keys for source and target limitations
			const sourceQueryKey = QUERY_KEYS.project.charts.limitationChartData(
				projectId,
				subjectId,
				invalidityChartId,
				variables.sourceLimitationId,
			)

			const targetQueryKey = QUERY_KEYS.project.charts.limitationChartData(
				projectId,
				subjectId,
				invalidityChartId,
				variables.targetLimitationId,
			)

			// Perform optimistic update
			const context = await handleOptimisticUpdate({
				queryClient,
				queryKeys: [sourceQueryKey, targetQueryKey],
				updateFn: (oldData) => {
					if (!oldData) return oldData

					// Different handling for source and target
					const limitationID = Object.keys(oldData)[0]

					// If this is the source data
					if (limitationID === variables.sourceLimitationId) {
						// No changes needed for source
						return oldData
					}

					// If this is the target data
					if (limitationID === variables.targetLimitationId) {
						const newData = { ...(oldData as Record<string, any>) }
						const sourceData = queryClient.getQueryData(sourceQueryKey) as any

						if (sourceData?.citations) {
							// If replace is true, remove existing citations for this document
							if (variables.replace && newData.citations) {
								newData.citations = newData.citations.filter(
									(citation) => citation.documentId !== variables.documentId,
								)
							}

							// Get citations from source for this document
							const sourceCitations = sourceData.citations.filter(
								(citation) => citation.documentId === variables.documentId,
							)

							// Add temporary ID to identify these as optimistic
							const optimisticCitations = sourceCitations.map((citation) => ({
								...citation,
								id: `temp-copy-${citation.id}-${Date.now()}`,
								isTemporary: true,
							}))

							if (!newData.citations) {
								newData.citations = []
							}

							// Add source citations to target
							newData.citations = [...newData.citations, ...optimisticCitations]
						}

						return newData
					}

					return oldData
				},
			})

			return context
		},
		onError: (error, _variables, context) => {
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
			handleError(error, "Error copying invalidities from one element to another")
		},
		onSuccess: (_, variables) => {
			invalidateSubjectChartRelatedQueries(
				queryClient,
				projectId,
				subjectId,
				invalidityChartId,
				[variables.sourceLimitationId, variables.targetLimitationId],
			)
		},
	})

	return {
		findMoreCitationsForLimitation: findMoreCitationsForLimitationMutation.mutateAsync,
		isFindingMoreCitations: findMoreCitationsForLimitationMutation.isPending,

		addCitation: addChunkToLimitationMutation.mutate,
		isAddingCitation: addChunkToLimitationMutation.isPending,

		toggleVerifyAllCitations: toggleVerifyAllCitations.mutate,
		isVerifyingAllCitations: toggleVerifyAllCitations.isPending,

		toggleRemoveAllDocumentChunkCitations:
			toggleRemoveAllDocumentChunkCitations.mutateAsync, // need to await to close modal
		isDeletingAllDocumentChunkCitations: toggleRemoveAllDocumentChunkCitations.isPending,

		pruneChunkToLimitationByColor: pruneChunkToLimitationByColor.mutateAsync,
		isPruningChunkToLimitationByColor: pruneChunkToLimitationByColor.isPending,

		// Use for individual citation updates to removed, verified, or color
		updateCitation: updateChunkToLimitationMutation.mutate,
		isUpdatingCitation: updateChunkToLimitationMutation.isPending,

		copyChunkToLimitations: copyChunkToLimitations.mutateAsync,
		isCopyingChunkToLimitations: copyChunkToLimitations.isPending,

		updateCellBoilerplate: updateChunkToLimitationBoilerplateMutation.mutateAsync,
		isUpdatingChunkToLimitationBoilerplate:
			updateChunkToLimitationBoilerplateMutation.isPending,
	}
}

export default useChunkToLimitationUpdates
