import type { components } from "schema"
import type { User } from "./admin"
import type { UpdateSettingsRequest } from "./request"
import type { KeysToCamelCase } from "./utils"

export const PATENT_TYPES = ["Application", "Issued Asset", "International"]
export const PATENT_STATUSES = ["Active", "Inactive"]
export const SUPPORTED_JURISDICTIONS = ["US", "EP", "KR", "JP", "CN", "WO", "DE"]

export type Project = KeysToCamelCase<components["schemas"]["Project"]>

export type ProjectTypeSchema = components["schemas"]["ProjectType"]
export const ProjectType = {
	LITIGATION: "LITIGATION",
	PROSECUTION: "PROSECUTION",
} as const satisfies Record<string, ProjectTypeSchema>
export type ProjectType = (typeof ProjectType)[keyof typeof ProjectType]

export type ProjectStatusSchema = components["schemas"]["ProjectStatus"]
export const ProjectStatus = {
	ACTIVE: "ACTIVE",
	ARCHIVED: "ARCHIVED",
} as const satisfies Record<string, ProjectStatusSchema>
export type ProjectStatus = (typeof ProjectStatus)[keyof typeof ProjectStatus]

export type FigurePlacementSchema = components["schemas"]["FigurePlacement"]
export const FigurePlacement = {
	EMBEDDED: "EMBEDDED",
	GROUPED: "GROUPED",
	OMITTED: "OMITTED",
} as const satisfies Record<string, FigurePlacementSchema>
export type FigurePlacement = (typeof FigurePlacement)[keyof typeof FigurePlacement]

export type ProjectSettings = KeysToCamelCase<components["schemas"]["ProjectSettings"]>

export type ProjectMetadata = KeysToCamelCase<components["schemas"]["ProjectMetadata"]>

export type ImportantFact = KeysToCamelCase<components["schemas"]["ImportantFact"]>
export type TermDefinition = KeysToCamelCase<components["schemas"]["TermDefinition"]>
export type ExpertKnowledge = KeysToCamelCase<components["schemas"]["ExpertKnowledge"]>
export type SearchContext = KeysToCamelCase<components["schemas"]["SearchContext"]>

export interface ChunkLocation {
	paragraphs?: number[] | null
	pages?: number[] | null
	lines?: number[] | null
	columns?: number[] | null
	claimSection?: string | null
	section?: string | null
}

export interface Element {
	color: string
	label: string
	language: string
}

export interface ProjectUser {
	userId: string
	user: User
	projectId: string
	project: Project
}

export enum ChartColor {
	GREEN = "#C1E1A6",
	YELLOW = "#FFDE7D",
	RED = "#F5A19B",
	GRAY = "#D3D3D3",
}

export enum CreateProjectStep {
	DETAILS = "DETAILS",
	SUBJECT = "SUBJECT",
	PRIOR_ART = "PRIOR_ART",
	CONTEXT = "CONTEXT",
	REVIEW = "REVIEW",
	OFFICE_ACTION = "OFFICE_ACTION",
}

export type ProjectMatrixRow = KeysToCamelCase<
	components["schemas"]["ProjectMatrixRow"]
>
export type ProjectMatrixChartCell = KeysToCamelCase<
	components["schemas"]["ProjectMatrixChartCell"]
>

export type ProjectSettingsUpdate = KeysToCamelCase<
	Omit<UpdateSettingsRequest, "projectId">
>

export type ProjectUsage = KeysToCamelCase<components["schemas"]["ProjectUsage"]>
