import { useProjectContext } from "@/context/ProjectContext"
import useSearchDocuments from "@/hooks/useSemanticSearch"
import { SearchMode, SearchOrder } from "@/types"
import { useCallback, useState } from "react"

export const useDocumentSearch = (documentIds: string[]) => {
	const [searchQuery, setSearchQuery] = useState<string | null>(null)
	const [searchResultIds, setSearchResultIds] = useState<Set<string> | null>(null)
	const [searchLoading, setSearchLoading] = useState<boolean>(false)
	const [searchError, setSearchError] = useState<boolean>(false)
	const [searchMode, setSearchMode] = useState<SearchMode>(SearchMode.SEMANTIC)
	const [searchOrderBy, setSearchOrderBy] = useState<SearchOrder>(SearchOrder.SCORE)
	const [searchOrderedResults, setSearchOrderedResults] = useState<any[]>([])
	const { semanticSearchDocuments } = useSearchDocuments()
	const { projectId } = useProjectContext()

	const search = useCallback(async () => {
		const trimmedQuery = searchQuery?.trim()

		// If there's no query, clear the results and exit early.
		if (trimmedQuery === "") {
			setSearchResultIds(null)
			setSearchOrderedResults([])
			return
		}

		setSearchLoading(true)
		setSearchError(false)

		try {
			// -- Single call for both KEYWORD and SEMANTIC modes --
			const response = await semanticSearchDocuments(
				projectId,
				trimmedQuery,
				documentIds,
				searchMode, // pass the mode
			)

			if (response) {
				// Order results by score in descending order
				const sortedResponse = [...response].sort((a, b) => b.score - a.score)
				const ids = new Set(sortedResponse.map((result) => result.id))
				setSearchResultIds(ids.size > 0 ? ids : null)
			} else {
				setSearchError(true)
				setSearchResultIds(null)
				setSearchOrderedResults([])
			}
		} catch (err) {
			console.error("Document search error:", err)
			setSearchError(true)
			setSearchResultIds(null)
			setSearchOrderedResults([])
		} finally {
			setSearchLoading(false)
		}
	}, [searchQuery, searchMode, documentIds, projectId, semanticSearchDocuments])

	return {
		searchQuery,
		setSearchQuery,
		searchResultIds,
		setSearchResultIds,
		searchLoading,
		searchError,
		search,
		searchMode,
		setSearchMode,
		searchOrderBy,
		setSearchOrderBy,
		searchOrderedResults,
		setSearchOrderedResults,
	}
}
