import { processApi } from "@/api/processApi"
import { useConvertToDocumentsToProcess } from "@/hooks"
import { useAppStateStore } from "@/store"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import type { ProjectType, UnprocessedDocumentType } from "@/types"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
/**
 * @description Hook for handling project creation
 * @todo add back OA handling
 */
const useCreateProject = ({ unprocessedDocuments }) => {
	const navigate = useNavigate()
	const { convertToDocumentsToProcess } = useConvertToDocumentsToProcess()
	const { addErrorMessage } = useAppStateStore()
	// Project details
	const [projectName, setProjectName] = useState<string>("")
	const [clientNumber, setClientNumber] = useState<string>("")
	const [projectType, setProjectType] = useState<ProjectType>(undefined) // LITIGATION, PROSECUTION
	const [spinnerText, setSpinnerText] = useState<string>("")
	const [selectedDocumentType, setSelectedDocumentType] =
		useState<UnprocessedDocumentType | null>(null)
	// New state for project budget
	const [defaultBudget, setDefaultBudget] = useState<number>(0)
	const [chartAgainstSelf, setChartAgainstSelf] = useState<boolean>(false)
	// Reset function
	const resetCreateProjectState = () => {
		setProjectName("")
		setClientNumber("")
		setProjectType(undefined)
		setSpinnerText("")
		setSelectedDocumentType(null)
		setDefaultBudget(0)
		createProjectMutation.reset()
	}

	// Update functions
	const updateProjectName = (name: string) => setProjectName(name)
	const updateClientNumber = (number: string) => setClientNumber(number)
	const updateProjectType = (type: ProjectType) => setProjectType(type)
	const updateDefaultBudget = (budget: number) => setDefaultBudget(budget)
	const updateChartAgainstSelf = (chartAgainstSelf: boolean) =>
		setChartAgainstSelf(chartAgainstSelf)
	const queryClient = useQueryClient()

	const createProjectMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.create(),
		mutationFn: async () => {
			const documentsToProcess = await convertToDocumentsToProcess(unprocessedDocuments)
			// Pass defaultBudget to the API call
			return processApi.createProject(
				projectName,
				projectType,
				clientNumber,
				documentsToProcess,
				defaultBudget,
				chartAgainstSelf,
			)
		},
		onMutate: () => {
			setSpinnerText("Creating project...")
		},
		onSuccess: (rProjectId) => {
			// Invalidate active projects
			queryClient.invalidateQueries({ queryKey: QUERY_KEYS.projects.active() })

			navigate(`/project/${rProjectId}/documents`)
		},
		onError: () => {
			addErrorMessage("Error creating project from number")
		},
	})

	return {
		// Project details
		projectName,
		updateProjectName,
		clientNumber,
		updateClientNumber,
		projectType,
		updateProjectType,
		defaultBudget,
		updateDefaultBudget,
		chartAgainstSelf,
		updateChartAgainstSelf,

		// Project errors
		projectError: createProjectMutation.isError,

		// Project creation
		isProjectCreationInProgress: createProjectMutation.isPending,
		spinnerText,
		resetCreateProjectState,
		createProject: createProjectMutation.mutateAsync,

		// Selected document type
		setSelectedDocumentType,
		selectedDocumentType,
	}
}

export default useCreateProject
