import { Loader } from "@/components"
import ProcessChartingOptions from "@/components/documents/unprocessed/ProcessChartingOptions"
import UnprocessedDocumentsList from "@/components/documents/unprocessed/UnprocessedDocumentsList"
import { Alert, AlertDescription } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { useUnprocessedDocumentsContext } from "@/context/UnprocessedDocumentsContext"
import { docRoleToProcessType } from "@/hooks/useConvertToDocumentsToProcess"
import { ProcessType } from "@/types"
import { DocumentRole, type UnprocessedDocumentType } from "@/types/documents"
import type React from "react"
import type { ReactNode } from "react"
import { useEffect, useState } from "react"
import { useProcessDocuments } from "../hooks"
import { useProjectInfringementDocuments } from "../hooks/useProjectInfringementDocuments"
import { useProjectPriorArtDocuments } from "../hooks/useProjectPriorArtDocuments"
import { useProjectSubjectDocuments } from "../hooks/useProjectSubjectDocuments"

interface BaseDocumentModalProps {
	open: boolean
	onClose: () => void
	documentRole: DocumentRole
	documentType: UnprocessedDocumentType
	title?: string
	children?: ReactNode
	isAddDisabled?: boolean
}

export const BaseDocumentModal: React.FC<BaseDocumentModalProps> = ({
	open,
	onClose,
	documentRole,
	documentType,
	title = "Add Documents",
	children,
	isAddDisabled = false,
}) => {
	const {
		unprocessedDocuments,
		removeUnprocessedDocument,
		updateSelectedKindCode,
		resetAll: resetUnprocessedDocuments,
		isUnprocessedDocumentsLoading,
	} = useUnprocessedDocumentsContext()

	const handleProcessClick = () => {
		handleProcess()
		handleClose()
	}

	// subject documents from project
	const { subjectDocuments } = useProjectSubjectDocuments()
	const { priorArtDocuments } = useProjectPriorArtDocuments()
	const { infringementDocuments } = useProjectInfringementDocuments()

	// budget states (if needed)
	const [isBudgetExceeded, setIsBudgetExceeded] = useState<boolean>(false)
	const [budgetExceededAmount, setBudgetExceededAmount] = useState<number>(0)

	// claims checkbox
	const [useClaimsCheckboxChecked, setUseClaimsCheckboxChecked] = useState<boolean>(true)
	const [chartAgainstSelf, setChartAgainstSelf] = useState<boolean>(false)
	const [selectedSubjectIds, setSelectedSubjectIds] = useState<string[]>(
		subjectDocuments.map((s) => s.id),
	)
	const [selectedPriorArtIds, setSelectedPriorArtIds] = useState<string[]>(
		priorArtDocuments.map((s) => s.id),
	)
	const [selectedInfringementIds, setSelectedInfringementIds] = useState<string[]>(
		infringementDocuments.map((s) => s.id),
	)

	useEffect(() => {
		setSelectedPriorArtIds(priorArtDocuments.map((s) => s.id))
		setSelectedInfringementIds(infringementDocuments.map((s) => s.id))
		setSelectedSubjectIds(subjectDocuments.map((s) => s.id))
	}, [priorArtDocuments, infringementDocuments, subjectDocuments])

	// process documents
	const { processDocuments } = useProcessDocuments()

	const handleReset = () => {
		setUseClaimsCheckboxChecked(true)
		setIsBudgetExceeded(false)
		setBudgetExceededAmount(0)
		resetUnprocessedDocuments()
		setSelectedSubjectIds(subjectDocuments.map((s) => s.id))
		setSelectedPriorArtIds(priorArtDocuments.map((s) => s.id))
		setSelectedInfringementIds(infringementDocuments.map((s) => s.id))
		setChartAgainstSelf(false)
	}

	const handleClose = () => {
		handleReset()
		onClose?.()
	}

	const handleProcess = async () => {
		const processType = docRoleToProcessType[documentRole] ?? ProcessType.ADD_PRIOR_ART

		await processDocuments({
			documentRole,
			processType,
			documents: unprocessedDocuments,
			useClaimsCheckboxChecked,
			chartAgainstSelf,
			subjectIds: documentRole === DocumentRole.PRIOR_ART ? selectedSubjectIds : [],
			priorArtIds: selectedPriorArtIds,
			infringementIds: selectedInfringementIds,
		})

		// After successful processing, reset
		handleReset()
	}

	const handleConfirmOverBudget = async () => {
		await handleProcess()
	}

	const handleRemoveDocument = (docId: string) => {
		removeUnprocessedDocument(docId)
	}

	return (
		<Dialog open={open} onOpenChange={handleClose}>
			<DialogContent className="max-w-2xl">
				<DialogHeader>
					<DialogTitle>{title}</DialogTitle>
				</DialogHeader>

				{/* Render additional children if provided (e.g., PatentNumberInput) */}
				{children && <div className="mb-4">{children}</div>}

				{/* Show the list of unprocessed documents */}
				{unprocessedDocuments.length > 0 ? (
					<div className="mb-4">
						<UnprocessedDocumentsList
							documents={unprocessedDocuments}
							onRemove={handleRemoveDocument}
							updateSelectedKindCode={updateSelectedKindCode}
						/>
					</div>
				) : isUnprocessedDocumentsLoading ? (
					<div className="mb-4">
						<Loader message={"Loading documents..."} />
					</div>
				) : (
					<></>
				)}

				{documentRole !== DocumentRole.CONTEXT && (
					<ProcessChartingOptions
						documentRole={documentRole}
						documentType={documentType}
						selectedSubjectIds={selectedSubjectIds}
						setSelectedSubjectIds={setSelectedSubjectIds}
						selectedPriorArtIds={selectedPriorArtIds}
						setSelectedPriorArtIds={setSelectedPriorArtIds}
						selectedInfringementIds={selectedInfringementIds}
						setSelectedInfringementIds={setSelectedInfringementIds}
						useClaimsCheckboxChecked={useClaimsCheckboxChecked}
						setUseClaimsCheckboxChecked={setUseClaimsCheckboxChecked}
						priorArtDocuments={priorArtDocuments}
						infringementDocuments={infringementDocuments}
						chartAgainstSelf={chartAgainstSelf}
						setChartAgainstSelf={setChartAgainstSelf}
					/>
				)}

				{/* Display a budget alert if over budget */}
				{isBudgetExceeded && (
					<Alert variant="destructive" className="mb-4">
						<AlertDescription>
							Warning: This action will exceed the project's budget by $
							{budgetExceededAmount.toLocaleString()}
						</AlertDescription>
					</Alert>
				)}

				<DialogFooter className="mt-6">
					<Button variant="outline" onClick={handleClose}>
						Cancel
					</Button>
					{isBudgetExceeded ? (
						<Button variant="destructive" onClick={handleConfirmOverBudget}>
							Proceed Anyway
						</Button>
					) : (
						<Button onClick={handleProcessClick} disabled={isAddDisabled}>
							Add
						</Button>
					)}
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
