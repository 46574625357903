import { useUpdateDocumentMetadata } from "@/features/documents/hooks"
import type { ProjectDocumentMetadata } from "@/types"
import { DocumentRole } from "@/types/documents"
import { useAuthInfo } from "@propelauth/react"
import { useCallback, useEffect, useState } from "react"

export function useChatRelevantDocuments(
	fetchDocumentsMetadata: (ids: string[]) => Promise<ProjectDocumentMetadata[]>,
) {
	const [isLoading, setIsLoading] = useState(false)
	const [documents, setDocuments] = useState<ProjectDocumentMetadata[]>([])
	const [showDocuments, setShowDocuments] = useState(false)
	const [selectedDocuments, setSelectedDocuments] = useState<Record<string, boolean>>({})
	const [isApproving, setIsApproving] = useState(false)
	const [hasError, setHasError] = useState(false)

	const authInfo = useAuthInfo()
	const { updateDocumentMetadata } = useUpdateDocumentMetadata()

	// Reset selected documents when document list changes
	useEffect(() => {
		setSelectedDocuments({})
	}, [documents])

	const fetchDocuments = async (relevantIds: string[]) => {
		if (isLoading || !relevantIds || relevantIds.length === 0) return

		setIsLoading(true)
		setHasError(false)
		try {
			const fetchedDocuments = await fetchDocumentsMetadata(relevantIds)
			setDocuments(fetchedDocuments)
			return fetchedDocuments
		} catch (error) {
			setHasError(true)
			console.error("Error fetching documents:", error)
			return []
		} finally {
			setIsLoading(false)
		}
	}

	const handleDocumentSelected = useCallback((documentId: string, selected: boolean) => {
		setSelectedDocuments((prev) => ({
			...prev,
			[documentId]: selected,
		}))
	}, [])

	const approveDocuments = async () => {
		const documentIds = Object.entries(selectedDocuments)
			.filter(([_, isSelected]) => isSelected)
			.map(([docId]) => docId)

		if (documentIds.length === 0) {
			return // No documents selected
		}

		setIsApproving(true)
		try {
			// Call the API to update document metadata
			await updateDocumentMetadata({
				documentIds: documentIds,
				documentRole: DocumentRole.PRIOR_ART,
				updatePayload: {
					updateCreatedBy: true,
				},
			})

			// Update local documents array with the new creator ID
			setDocuments((prevDocuments) =>
				prevDocuments.map((doc) => {
					if (documentIds.includes(doc.id)) {
						return {
							...doc,
							createdBy: authInfo.user?.userId,
						}
					}
					return doc
				}),
			)

			// Clear selection for the approved documents
			const newSelectedDocuments = { ...selectedDocuments }
			for (const id of documentIds) {
				newSelectedDocuments[id] = false
			}
			setSelectedDocuments(newSelectedDocuments)
		} catch (error) {
			console.error("Error approving documents:", error)
		} finally {
			setIsApproving(false)
		}
	}

	return {
		isLoading,
		documents,
		showDocuments,
		setShowDocuments,
		selectedDocuments,
		isApproving,
		fetchDocuments,
		handleDocumentSelected,
		approveDocuments,
		selectedDocumentsError: hasError,
	}
}
