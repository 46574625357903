import { usePlatformUtils } from "@/hooks"
import { PAGE_DISPLAY_NAMES, type Pages } from "@/types/pages"
import type React from "react"
import type { ReactNode } from "react"
import AlertMessages from "../alerts/AlertMessages"
import { AppSidebar } from "../sidebar/AppSidebar"
import { SidebarInset } from "../ui/sidebar"

/**
 * @description The sidebar page component
 * @param {ReactNode} children - The children of the component
 */
interface SidebarPageProps {
	children: ReactNode
	pageName: Pages | string
}

const SidebarPage: React.FC<SidebarPageProps> = ({ children, pageName }) => {
	const { usePageTitle } = usePlatformUtils()
	usePageTitle(PAGE_DISPLAY_NAMES[pageName] || pageName)
	return (
		<div className="relative h-screen w-full flex">
			<AppSidebar />
			<SidebarInset className="flex-1 overflow-hidden">{children}</SidebarInset>

			<AlertMessages />
		</div>
	)
}

export default SidebarPage
