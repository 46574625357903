/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, ProjectPage } from "@/components"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useProjectSettings } from "@/features/settings/hooks/useProjectSettings"
import { useProjectMetadata } from "@/hooks/project/useProjectMetadata"
import { PageName } from "@/types/pages"
import type { ProjectSettingsUpdate } from "@/types/project"
import { useState } from "react"
import type React from "react"
import BudgetSettings from "./components/BudgetSettings"
import ClaimSettings from "./components/ClaimSettings"
import ExportSettings from "./components/ExportSettings"
import { SettingsSidebarNav } from "./components/SettingsSidebarNav"
import ShareProjectSettings from "./components/ShareProjectSettings"

/**
 * @description Project settings page.
 *
 * In this version we no longer maintain a local state for project settings.
 * Instead, the current settings (from React Query) are used as the single source of truth,
 * and each field's onBlur (or onChange) handler calls saveSettings immediately with the updated field.
 */
const ProjectSettingsPage: React.FC = () => {
	const { projectMetadata } = useProjectMetadata()
	const { isLoading, settings, saveSettings } = useProjectSettings()

	const sidebarNavItems = [
		{ title: "Export settings" },
		{ title: "Claim settings" },
		{ title: "Billing" },
		{ title: "Project sharing" },
	]
	const [selectedSidebarItem, setSelectedSidebarItem] = useState<string>(
		sidebarNavItems[0].title,
	)

	/**
	 * This helper accepts a changed field (and its new value) then immediately
	 * saves the change by merging it with the current settings retrieved from React Query.
	 * This effectively removes the need for local state.
	 */
	const handleFieldChange = async (field: keyof ProjectSettingsUpdate, value: any) => {
		if (!settings) return
		const updatedSettings: ProjectSettingsUpdate = {
			...settings,
			[field]: value,
			// Guarantee that projectId is included.
			projectId: projectMetadata?.id || "",
		}
		await saveSettings(updatedSettings)
	}

	return (
		<ProjectPage pageName={PageName.ProjectSettings}>
			<div className="w-full h-screen flex flex-col">
				{isLoading || !settings ? (
					<div className="flex-1 flex items-center justify-center">
						<Loader />
					</div>
				) : (
					<div className="flex flex-row flex-1 gap-4  h-screen ">
						<div className="mt-4 pl-4">
							<SettingsSidebarNav
								selectedItem={selectedSidebarItem}
								setSelectedItem={setSelectedSidebarItem}
								items={sidebarNavItems}
								className="w-[200px]"
							/>
						</div>
						<ScrollArea className="grid gap-4 py-2 px-4  flex-1">
							<div className="mt-4 mx-2">
								{selectedSidebarItem === "Billing" && (
									<BudgetSettings
										budget={projectMetadata?.budget}
										onFieldChange={handleFieldChange}
									/>
								)}

								{selectedSidebarItem === "Claim settings" && (
									<ClaimSettings
										claimSettings={{
											defaultToAssertedClaims: settings.defaultToAssertedClaims,
										}}
										onFieldChange={handleFieldChange}
									/>
								)}

								{selectedSidebarItem === "Export settings" && (
									<ExportSettings
										exportSettings={{
											headerFormat: settings.headerFormat,
											footerFormat: settings.footerFormat,
											middleFormat: settings.middleFormat,
											usPatentCitationFormat: settings.usPatentCitationFormat,
											usApplicationCitationFormat: settings.usApplicationCitationFormat,
											uploadedDocumentCitationFormat: settings.uploadedDocumentCitationFormat,
											nonUsPatentCitationFormat: settings.nonUsPatentCitationFormat,
											isCitationBefore: settings.isCitationBefore,
											figurePlacement: settings.figurePlacement,
											wordIntroductionLanguage: settings.wordIntroductionLanguage,
											wordClaimChartTitleFormat: settings.wordClaimChartTitleFormat,
										}}
										onFieldChange={handleFieldChange}
									/>
								)}

								{selectedSidebarItem === "Project sharing" && (
									<ShareProjectSettings handleClose={() => {}} />
								)}
							</div>
						</ScrollArea>
					</div>
				)}
			</div>
		</ProjectPage>
	)
}

export default ProjectSettingsPage
