import { ScrollArea } from "@/components/ui/scroll-area"
import type React from "react"
import { useChartsContext } from "../../context/ChartsContext"

import InvalidityTableLimitationCell from "./InvalidityTableLimitationCell"

const InvalidityTableMode: React.FC = () => {
	const { filteredLimitations } = useChartsContext()

	return (
		<ScrollArea className="w-full h-full">
			<div className="flex flex-col gap-2 px-3 mt-2">
				<div className="flex flex-col gap-2">
					{filteredLimitations.map((limitation) => (
						<InvalidityTableLimitationCell
							key={limitation.id}
							collapsedLimitation={limitation}
						/>
					))}
				</div>
			</div>
		</ScrollArea>
	)
}

export default InvalidityTableMode
